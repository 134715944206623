import { MatDialog } from '@angular/material/dialog';
import { UserState } from './../../redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { DataService } from './../../shared/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ShareDialogComponent } from 'src/app/shared/components/dialogs/share-dialog/share-dialog.component';
import { PublicAlumno } from 'src/app/shared/interfaces/IAlumno';
import { AlumnoService } from 'src/app/shared/services/alumno.service';

@Component({
  selector: 'app-student-credential',
  templateUrl: './student-credential.component.html',
  styleUrls: ['./student-credential.component.scss'],
})
export class StudentCredentialComponent implements OnInit {
  public studentId: string;
  public student: PublicAlumno;
  public webUrl = environment.webUrl;
  @Input() currentUser;

  public loading = true;
  public error = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private dataService: DataService,
    private alumnoService: AlumnoService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.currentUser) {
      this.student = {
        nombre_alumno: this.currentUser.nombre,
        legajo_alumno: this.currentUser.legajo,
        correo_alumno: this.currentUser.email,
        telefono_alumno: this.currentUser.movil,
        foto_perfil: this.currentUser.foto,
        foto_perfil_base64: this.currentUser.foto64,
        carera_principal: {
          nombre_carrera: `${this.currentUser.carreras[0].carrera} | ${this.currentUser.carreras[0].resolucion}`,
          modalidad:
            this.currentUser.carreras[0].modalidad === 'Online'
              ? 'o'
              : this.currentUser.carreras[0].modalidad === 'Presencial'
              ? 'p'
              : null,
        },
      };
    } else {
      this.activeRoute.paramMap.subscribe((params) => {
        this.studentId = params.get('studentId');

        if (this.studentId) {
          this.getStudent();
        }
      });
    }
  }

  getStudent(): void {
    this.loading = true;
    this.alumnoService.getPublicAlumno(this.studentId).subscribe({
      next: (res) => {
        console.log(res);
        this.student = res;
        this.loading = false;
      },
      error: (err) => {
        this.error = true;
        this.loading = false;
      },
    });
  }

  cleanNumber() {
    return this.student.telefono_alumno.replace(/-|\(|\)|\s|\*|\#/g, '');
  }

  openShare(): void {
    const dialogRef = this.dialog.open(ShareDialogComponent, {
      width: '400px',
      maxWidth: '90vw',
      data: {
        title: '¡Comparte tu credencial!',
        link: `${this.webUrl}/estudiante/${this.student.legajo_alumno}`,
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
