import { TourService } from 'ngx-ui-tour-md-menu';
import { UntypedFormControl } from '@angular/forms';
import { DetalleBloqueo } from '../../../interfaces/IBloqueo';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { DataService } from '../../../services/data.service';
import * as reduxUser from '../../../../redux/actions/user.action';
import { MediaMatcher } from '@angular/cdk/layout';
import { NgxHotjarService } from 'ngx-hotjar';
import { navigationTour } from 'src/app/shared/data/tours';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnDestroy, OnInit {
  @Input()
  showFooter: boolean;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  public expandedSidenav = new UntypedFormControl(false);
  estadoBloqueos: DetalleBloqueo[] = [];

  public user$: Observable<any>;
  constructor(
    private alertService: AlertService,
    private alumnoService: AlumnoService,
    private authService: AuthService,
    private store: Store<UserState>,
    private specViewService: SpecViewService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private readonly tourService: TourService,
    public hjService: NgxHotjarService
  ) {
    this.user$ = store.select('user');
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  public user = {
    nombre: '',
    apellido: '',
    rol: {
      denominacion: '',
    },
    imagen: '',
  };

  isHandset = true;

  ngOnInit(): void {
    this.tourService.initialize(navigationTour, {
      route: 'home',
      disablePageScrolling: true,
      enableBackdrop: false,
    });

    this.specViewService.currentSharedSpecMode.subscribe(res => this.getUser())

    if (!localStorage.getItem('finishTour') && !this.mobileQuery.matches) {
      this.tourService.start();
    }

    this.tourService.end$.subscribe((red) => {
      localStorage.setItem('finishTour', 'true');
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  getUser(): void {
    this.alumnoService.getAlumno().subscribe({
      next: (res) => {
        /* CHEQUEAR SESIÓN */

        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.email === '' || !res.email || res.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }

        this.hjService.hj('identify', res.legajo, {
          'Último login': new Date().toISOString(), // Signup date in ISO-8601 format.
          'Email académico': res.email, // User's email address.
          'Email personal': res.email_personal, // User's email address.
          'Nombre completo': res.name, // User's full name.
          Movil: res.movil, // User's phone number.
        });

        localStorage.setItem('estado_bloqueo', res.estado_bloqueo);

        if (res.estado_bloqueo != null) {
          res.carreras.forEach((carrera) => {
            if (
              carrera.estado_bloqueo !== '' &&
              carrera.estado_bloqueo !== 'Graduado'
            ) {
              this.estadoBloqueos.push({
                carrera: carrera.carrera,
                estado_bloqueo: carrera.estado_bloqueo,
              });
            }
          });
          localStorage.setItem(
            'estadoBloqueos',
            JSON.stringify(this.estadoBloqueos)
          );
        }

        localStorage.setItem(
          'userData',
          JSON.stringify({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
            token: null,
          })
        );

        this.store.dispatch(
          new reduxUser.GetUser({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
          })
        );
      },
      error: (err) => {
        this.alertService.openAlert(
          '¡Sesión vencida!',
          'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
          'Ok'
        );
        setTimeout(() => {
          this.authService.logout();
        }, 5000);
      },
    });
  }

  startTour() {
    this.tourService.start();

    this.tourService.end$.subscribe((red) => {
      localStorage.setItem('finishTour', 'true');
    });
  }

  onSignOut(): void {
    this.authService.clearSis_id();
    this.authService.clearToken();
    this.authService.clear_estado_bloqueo();
    window.location.reload();
  }

  /* setContentMargin(event) {
    this.expandedSidenav.setValue(event);
  } */
}