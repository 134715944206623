<div class="sticky-header">
  <app-header [title]="'Cursos nivelatorios'"></app-header>
</div>
<div class="container mt-5">
  <div class="notfound-container main-shadow mb-5" *ngIf="loading">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
  <div
    class="notfound-container main-shadow mb-5"
    *ngIf="!loading && this.cursos.length === 0"
  >
    <img src="../../../assets/images/content/happy-student.png" alt="" />
    <h2>
      {{ error ? "¡Próximamente!" : "No pudimos encontrar cursos abiertos" }}
    </h2>
    <p>
      {{
        error
          ? "Muy pronto podrás ver aquí los cursos nivelatorios de ADENU."
          : "Parece que aún no hay cursos nivelatorios en el sistema, si crees que es un error no dudes en contactar a tu Academic Advisor o vuelve a intentar más tarde."
      }}
    </p>
    <div class="button-container" *ngIf="!error">
      <button mat-stroked-button color="primary" routerLink="/academic-advisor">
        Contactar Academic Advisor
      </button>
      <button mat-flat-button color="primary" (click)="getCursos()">
        Reintentar
      </button>
    </div>
  </div>

  <div
    *ngIf="!loading && this.cursos && !error"
    class="main-radius main-shadow main-container"
  >
    <div id="buscador" class="buscador" *ngIf="!isLoading && !isError">
      <div class="form-field">
        <mat-icon class="material-icons-round">search</mat-icon>
        <input
          matInput
          type="search"
          placeholder="Buscar"
          (keyup)="search($event)"
          #input
          [(ngModel)]="searchValue"
        />

        <div class="suffix">
          <button
            *ngIf="searchValue"
            mat-icon-button
            color="primary"
            aria-label="Clear"
            (click)="cleanSearch()"
          >
            <mat-icon class="material-icons-round">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="course-grid">
      <ng-container
        *ngFor="let curso of cursos | filter : searchValue; let index = index"
      >
        <app-card-curso-abierto
          index="index"
          [curso]="curso"
        ></app-card-curso-abierto>
      </ng-container>
    </div>
  </div>
</div>
