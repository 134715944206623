import { NavigationService } from './../../../services/navigation.service';
import { Component, Input, OnInit } from '@angular/core';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { DataService } from 'src/app/shared/services/data.service';
import { TabConfigService } from 'src/app/shared/services/tab-config.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string;
  isSpecMode = false;
  specUserName = '';
  specUserPhoto = '';
  @Input() context!: string;
  tabs: { label: string; route: string }[] = []; 
  tabSelectedIndex = 0;
  private routeSub!: Subscription;
  constructor(
    private navigation: NavigationService,
    private tabConfigService: TabConfigService,
    private router: Router,
    private specViewService: SpecViewService,
    private dataService: DataService) {}

  back(): void {
    this.navigation.goBack();
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }


  
  ngOnInit() {
    this.tabs = this.tabConfigService.getTabsForContext(this.context);
    this.tabSelectedIndex = this.getSelectedTabIndex();
    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.tabSelectedIndex = this.getSelectedTabIndex();
      }
    });
    this.specViewService.currentSharedSpecMode.subscribe(data => {
        this.isSpecMode = data.isSpecMode;
        this.specUserName  = data.name;
        this.specUserPhoto = data.photo;
    })
  }
  getSelectedTabIndex(){
    const currentUrl = this.router.url;
    return this.tabs.findIndex(tab => currentUrl.includes(tab.route));
  }
  onTabChange(event: any) {
    const selectedTab = this.tabs[event.index];
    console.log(this.context)
    if (selectedTab && this.context !== 'formalities') {
      console.log('Navigating33 to:', selectedTab.route);
      this.router.navigateByUrl(this.context + selectedTab.route);
    }else if(this.context === 'formalities'){
      this.router.navigate(['/tramites'+selectedTab.route], {
        queryParams: {},
        relativeTo: null,
      });
      
    }
  }
}
