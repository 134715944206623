<div class="main">
    <div class="portrait" (click)="clickVideo()">
      <img class="img-portrait" [src]="videoData.image_url" alt="">
      <svg xmlns="http://www.w3.org/2000/svg" class="play-icon animate__rotateInDownLeft" viewBox="0 0 16 16"><path fill="currentColor" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/></svg>
      <div class="overlay"></div>
    </div>
  <div class="bottom-content">
    <div *ngIf="videoData.tags.length" class="tags-container">
      <div
      *ngFor="let tag of videoData.tags.length <= 3 ? 
      videoData.tags : 
      videoData.tags.slice(0, 3)" class="tag"> {{tag + '   '}} </div>
    </div>
    <div class="info">
        <div class="title">{{videoData.publication_name}}</div>
        <div class="author">{{videoData.acropolis_author}}</div>
    </div>
    <div *ngIf="videoData" class="buttons-back">
      <div class="buttons-container">
        <label class="container">
          <input [checked]="videoData.favorito" (click)="setFav(); toggleFavorite();" type="checkbox">
          <div class="checkmark">
            <svg class="share-icon" viewBox="0 0 256 256">
            <rect fill="none" height="256" width="256"></rect>
            <path d="M224.6,51.9a59.5,59.5,0,0,0-43-19.9,60.5,60.5,0,0,0-44,17.6L128,59.1l-7.5-7.4C97.2,28.3,59.2,26.3,35.9,47.4a59.9,59.9,0,0,0-2.3,87l83.1,83.1a15.9,15.9,0,0,0,22.6,0l81-81C243.7,113.2,245.6,75.2,224.6,51.9Z" stroke-width="20px" stroke="#5A5A5A" fill="none"></path></svg>
          </div>
        </label>
        <iconify-icon (click)="openShareDialog()" width="1.7rem" height="1.7rem" class="share-icon" icon="material-symbols:share-outline"></iconify-icon>
      </div>
    </div>
  </div>
</div>
