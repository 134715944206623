<div *ngIf="!finishedUpload" id="upload-container">
  <div class="description">
    <div class="title">Carga de archivos</div>
    <div class="detail">
      <p>En esta segunda etapa deberás adjuntar los siguientes archivos:</p>
      <ul>
        <li>
          Propuesta Técnica (Descarga el formato haciendo click
          <a
            href="../../../../../assets/documents/Propuesta-Tecnica.docx"
            target="_blank"
            rel="noopener noreferrer"
            >aquí</a
          >)
        </li>
        <li>Video de presentación (Máximo 1 minuto)</li>
        <li>Carta de postulación (¿Por qué elegirnos?)</li>
        <li>
          Compromiso de participación firmado por todos los integrantes del
          equipo (Descarga el formato haciendo click
          <a
            href="../../../../../assets/documents/Compromiso de participación en A&E - Adenu 2021.docx"
            target="_blank"
            rel="noopener noreferrer"
            >aquí</a
          >)
        </li>
      </ul>
      <p>
        Una vez que hayas seleccionado los archivos y hagas click en "Enviar
        archivos" no podrás modificarlos.
      </p>
    </div>
  </div>

  <div class="drop-area" appDnd (fileDropped)="onFileDropped($event)">
    <input
      type="file"
      #fileDropRef
      id="fileDropRef"
      multiple
      (change)="fileBrowseHandler($event.target.files)"
    />
    <div class="icon">
      <span class="material-icons-round"> file_present </span>
    </div>
    <div class="title">Arrastra tus archivos hasta aquí</div>
    <p>o</p>
    <label for="fileDropRef" hidden>Elige los archivos</label>
    <button for="fileDropRef" mat-flat-button color="primary">
      Elige los archivos
    </button>
  </div>

  <div class="files-list">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <div class="file-icon" style="width: 50px">
        <span class="material-icons-round"> insert_drive_file </span>
      </div>
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <div class="detail">
          <div class="size-progress">
            <p class="size">
              {{ formatBytes(file?.size) }}
            </p>
            <div class="progress-cont">
              <div class="progress" [style.width]="file?.progress + '%'"></div>
            </div>
          </div>
          <div class="delete" (click)="deleteFile(i)">
            <span class="material-icons-round"> delete </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="button-container">
    <button
      mat-flat-button
      color="accent"
      id="enviarArchivos"
      [disabled]="files && files.length < 4"
      (click)="sendFiles()"
    >
      <span id="send-text">Enviar archivos</span>
      <div id="loader" class="lds-ring no-display">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </button>
  </div>
</div>

<div class="center" *ngIf="finishedUpload">
  <img src="../../../../../assets/images/content/Calendar-finish.svg" alt="" />
  <div class="text blue">¡Etapa finalizada!</div>
  <div class="subtext orange mt-3">Muchas gracias por participar</div>
</div>

<div *ngIf="this.isWaiting" class="absolute" id="absolute-message">
  <div class="soonContainer">
    <div class="soonMessage spinner">
      <mat-spinner></mat-spinner>
      <h2>Enviando..</h2>
      <p>Por favor no cierres esta pestaña</p>
    </div>
  </div>
</div>
