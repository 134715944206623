<div>
  <div class="dialog-header">
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
  <div class="dialog-container">
    <div mat-dialog-content>
      <div class="social-text">
        <div class="title">{{ data.title }}</div>
        <div class="subtitle" *ngIf="data.subtitle">{{data.subtitle}}</div>
      </div>
      <ul class="example-2">
        <li class="icon-content">
          <a target="_blank"
            [href]="'https://www.facebook.com/sharer/sharer.php?u=' + data.link"
            aria-label="Facebook"
            data-social="facebook"
          >
            <div class="filled"></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95"/></svg>
          </a>
          <div class="tooltip">Facebook</div>
        </li>
        <li class="icon-content">
          <a target="_blank"
            [href]="'https://twitter.com/share?url=' + data.link"
            aria-label="Twitter"
            data-social="twitter"
          >
            <div class="filled"></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" d="M9.294 6.928L14.357 1h-1.2L8.762 6.147L5.25 1H1.2l5.31 7.784L1.2 15h1.2l4.642-5.436L10.751 15h4.05zM7.651 8.852l-.538-.775L2.832 1.91h1.843l3.454 4.977l.538.775l4.491 6.47h-1.843z"/></svg>
          </a>
          <div class="tooltip">Twitter</div>
        </li>
        <li class="icon-content">
          <a target="_blank"
            [href]="'whatsapp://send?text=' + data.link | safeUrl"
            aria-label="whatsapp"
            data-social="whatsapp"
          >
            <div class="filled"></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28"/></svg>
          </a>
          <div class="tooltip">WhatsApp</div>
        </li>
        <li class="icon-content">
          <a target="_blank"
            [href]="'https://www.linkedin.com/sharing/share-offsite/?url=' + data.link"
            aria-label="Linkedin"
            data-social="linkedin"
          >
            <div class="filled"></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"/></svg>
          </a>
          <div class="tooltip">Linkedin</div>
        </li>
      </ul>
      
      <div class="link-wrapper">
        <div class="link-div">
          <input type="text" [value]="data.link" />
          <button
            mat-button
            color="primary"
            #tooltip="matTooltip"
            matTooltip="¡Copiado!"
            matTooltipPosition="below"
            [matTooltipDisabled]="disabledTooltip"
            (click)="copyLink(); tooltip.toggle()"
          >
            Copiar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
