import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit {

  file: string;

  constructor() { }

  ngOnInit(): void {
    this.file = localStorage.getItem('viewer_file_url')
  }
}


