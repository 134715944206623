<div class="card-home">
  <div class="img-container">
    <img class="img" [src]="item.img" alt="img" />
  </div>
    <div class="title">{{ item.title }}</div>
    <div class="description">{{ item.description }}</div>
    <div (click)="goTo()" class="btn">
      Ver condiciones
    </div>
</div>
  
  