import { Component, OnInit } from '@angular/core';
import { Evento } from 'src/app/shared/interfaces/IEvento';
import { EventosService } from 'src/app/shared/services/eventos.service';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss'],
})
export class EventosComponent implements OnInit {
  title = 'Eventos';

  public events: Evento[] = [];
  public pastEvents = [];
  public nextEvents = [];

  public currentDate = new Date();

  public isLoading = false;

  public selectedTab = 0;

  constructor(private eventosService: EventosService) {}

  ngOnInit(): void {
    this.getAllEventos();
  }

  getAllEventos(): void {
    this.isLoading = true;
    this.eventosService.getEventos().subscribe({
      next: (res) => {
        this.events = res.response_data;

        this.nextEvents = this.events.filter(
          (event) =>
            new Date(event.date_end).valueOf() >= this.currentDate.valueOf()
        );
        this.nextEvents.sort(
          (a, b) =>
            new Date(a.date_begin).getTime() - new Date(b.date_begin).getTime()
        );

        this.pastEvents = this.events.filter(
          (event) =>
            new Date(event.date_end).valueOf() < this.currentDate.valueOf()
        );
        this.pastEvents.sort(
          (a, b) =>
            new Date(b.date_begin).getTime() - new Date(a.date_begin).getTime()
        );

        this.isLoading = false;
        if (this.nextEvents.length > 0) {
          this.selectedTab = 0;
        } else {
          this.selectedTab = 1;
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
