<div class="main">
    <div class="card-container">
        <div class="img-container">
            <img class="first-img" src="../../../assets/images/logos/ceaden-logo.png" alt="">
        </div>
        <div class="title">Sobre CEADEN</div>
            <div class="text">
                En este centro de emprendimiento, que pertenece al Grupo Educativo ADEN, podrás recibir contribución en tu formación de competencias emprendedoras, garantizando relación con un ecosistema emprendedor en Panamá y LATAM.
            </div>
        <div class="button-container">
            <button (click)="navigateTo('ceaden')">Ver más</button>
        </div>
    </div>
    <div class="card-container">
        <div class="img-container">
            <img src="../../../assets/images/logos/impulso-logo.png" alt="">
        </div>
        <div class="title">Programa IMPULSO</div>
            <div class="text">
                IMPULSO es un programa especialmente diseñado para apoyarte en el desarrollo de tu emprendimiento. Si eres seleccionado, tendrás la oportunidad de planificar o implementar mejoras en tu proyecto, mientras aplicas y refuerzas lo aprendido en ADEN University (ADENU). 
            </div>
        <div class="button-container">
            <button (click)="navigateTo('ceaden/programa-impulso')">Ver más</button>
        </div>
    </div>
    <div class="card-container">
        <div class="img-container">
            <img src="../../../assets/images/logos/emprende-logo.png" alt="">
        </div>
        <div class="title">Reto APRENDE Y EMPRENDE</div>
            <div class="text">
                Este reto es tu oportunidad para unirte al grupo de estudiantes de alto desempeño de ADENU. La Universidad te ayudará a gestionar tus ideas de negocio, pero el objetivo principal es formarte como persona a través del trabajo en equipo y emprendimientos reales.
            </div>
        <div class="button-container">
            <button (click)="navigateTo('ceaden/reto-aprende-y-emprende')">Ver más</button>
        </div>
    </div>
</div>