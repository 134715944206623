<div id="plan-estudio">
  <div class="sticky-header"><app-header [title]="title"></app-header></div>

  <div class="container flex" *ngIf="carreras">
    <mat-tab-group mat-align-tabs="start" class="main-radius main-shadow">
      <mat-tab
        *ngFor="let carrera of carreras; let index = index"
        [label]="carrera.carrera"
      >
        <div class="content">
          <div class="heading main-radius mb-4">
            <div class="text-content">
              <h1>{{ carrera.carrera }}</h1>
              <h3><strong>RESOLUCIÓN: </strong>{{ carrera.resolucion }}</h3>
              <h3><strong>MODALIDAD: </strong>{{ carrera.modalidad }}</h3>
            </div>
            <button
              mat-flat-button
              color="primary"
              (click)="generatePdf(carrera)"
            >
              <mat-icon class="material-icons-round">download</mat-icon>
              Descargar
              <mat-progress-spinner
                diameter="20"
                mode="indeterminate"
                [color]="'#ffffff'"
                *ngIf="isLoading"
              ></mat-progress-spinner>
            </button>
          </div>
          <app-plan-table [planEstudio]="carrera.plan"></app-plan-table>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
