import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss'],
})
export class ProgramCardComponent {
  @Input() program: any;

  public moment = moment;
  public today = new Date();

  constructor() {}

  getDate(dateString) {
    return new Date(dateString);
  }

  getDays(dateString) {
    return Math.abs(
      Math.trunc(
        (this.today.getTime() - this.getDate(dateString).getTime()) /
          (1000 * 60 * 60 * 24)
      )
    );
  }
}
