import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/shared/services/course.service';
import { Observable } from 'rxjs';
import { ICourse, IResource } from 'src/app/shared/interfaces/ICourse';
import { Store } from '@ngrx/store';
import { CourseState } from 'src/app/redux/reducers/courses.reducer';
import { LoadCourses } from 'src/app/redux/actions/courses.action';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  courseList$: Observable<ICourse>;
  courseStore$: Observable<IResource[]>
  list = [];
  allCoursesList = [];
  inProgressList = [];
  pendingList = [];
  isError = false;
  endedList = [];
  isLoading = false;

  constructor(
    private courseService: CourseService,
    private snackbar: MatSnackBar,
    private store: Store<CourseState>,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initGetResources();
  }
  initGetResources = () => {
    this.isLoading = true;
    this.courseStore$ = this.store.select('coursesList');
    this.courseStore$.subscribe(data => {
      if(!data){
        this.courseService.getAllCourses().subscribe({
          next: course => {
            this.store.dispatch(new LoadCourses(course))
            this.allCoursesList = course;
            this.list = course;
          },
          error: err => {
            console.log(err)
            this.isError = true;
            this.isLoading = false;
          }
        })
      }else{
        this.list = data;
      }
      this.isLoading = false;
    })
    this.getRegisterCourse()
  }
  goToVideo = (id) => {
    this.router.navigateByUrl('course/' + id)
  }
  createEnrollment(){
    // this.courseService.createEnrollment().subscribe(data => console.log(data))
  }
  getRegisterCourse(){
    const repoId = localStorage.getItem('repo_id');
    this.isLoading = true;
    this.courseService.getRegisterCourse(repoId).subscribe(data => {
      this.isLoading = false;
      const typeOaList = data.response_data.enrollments.filter(item => item.type === 'oa');
      this.list = this.list.filter(item => !typeOaList.some(oaItem => oaItem.detail.id === item.id));
      this.list.push(...typeOaList);
      this.allCoursesList.forEach(course => {
        const matchingOaItem = typeOaList.find(oaItem => oaItem.detail.id === course.id);
        if (matchingOaItem) {
          matchingOaItem.duracion = course.duracion;
          matchingOaItem.duracion_unidad_tiempo = course.duracion_unidad_tiempo;
        }
      });
      this.getFilteredCourses();
    });
  }
  getFilteredCourses(){
    this.endedList = this.list.filter(course =>  course.detail && course.detail.progress === 100)
    this.pendingList = this.list.filter(course => course.detail && course.detail.progress === 0)
    this.inProgressList = this.list.filter(course => course.detail && course.detail.progress > 0 && course.detail.progress < 100)
  }
  onRegisterCourse = (resourceId) => {
    this.courseService.registerCourse(resourceId).subscribe({
      next: data => {
        this.snackbar.open('Curso registrado con exito', 'OK', {
          duration: 5000,
          panelClass: ['success-snackbar'],
        });
        this.initGetResources();
        this.goToVideo(resourceId)
      },
      error: err => {
        this.snackbar.open('Hubo un problema al registrar el curso', 'OK', {
          duration: 5000,
          panelClass: ['error-snackbar'],
        });
        console.log(err)
      }
    })
  }
}