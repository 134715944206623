<div class="sticky-header">
    <app-header [title]="'Vista de estudiante'"></app-header>
</div>

<div class="main">
    <div class="title">
        <h1>Buscar alumno</h1>
    </div>
    <div class="search-container">
        <div id="buscador" class="buscador">
            <div class="form-field">
                <mat-icon class="material-icons-round">search</mat-icon>
                <input matInput type="search" placeholder="Ingresa el email del alumno" #input [(ngModel)]="searchValue" />
        
                <div class="suffix">
                    <button *ngIf="searchValue" mat-icon-button color="primary" aria-label="Clear" (click)="cleanSearch()">
                        <mat-icon class="material-icons-round">close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div click class="search-btn" (click)="search(searchValue)">Buscar</div>
    </div>
    <div class="warnign">
        <div [inlineSVG]="'../../../assets/svgs/Group.svg'"></div>
        <span>¡Recuerda! </span> <b class="nowrap">La vista de estudiante </b>  únicamente te permitirá visualizar la plataforma como un estudiante en particular, sin
        posibilidad de realizar cambios.
    </div>
    <div class="img-container">
        <img src="../../../assets/images/content/spec-view.png" class="img">
    </div>
</div>