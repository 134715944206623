<div class="dialog-header">
  <div mat-dialog-title>
    <h1>{{ data.course.name }}</h1>
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
</div>
<div class="dialog-container">
  <div mat-dialog-content>
    <section class="container center-body left">
      <p>{{ data.course.description }}</p>
    </section>
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title> Duración </mat-panel-title>
        </mat-expansion-panel-header>
        <p>{{ data.course.duration }}</p>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title> Objetivos </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <h3><span>Generales</span></h3>
          <p>{{ data.course.goals.general }}</p>
        </div>
        <div class="row">
          <h3><span>Específicos</span></h3>
          <p>{{ data.course.goals.specific }}</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title> Contenido </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let week of data.course.weeks">
          <div class="row">
            <h3>
              <span>{{ week.name }}</span>
            </h3>
            <ol>
              <ng-container *ngFor="let line of week.content">
                <li>{{ line.item }}</li>
                <ul *ngIf="line.subcontent">
                  <ng-container *ngFor="let subLine of line.subcontent">
                    <li>{{ subLine }}</li>
                  </ng-container>
                </ul>
              </ng-container>
            </ol>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
