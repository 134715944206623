import { Requisito } from 'src/app/shared/interfaces/IRequisito';
import { Criterio } from '../../../../shared/interfaces/ICriteria';
import { Component } from '@angular/core';

@Component({
  selector: 'app-sobre-internacional',
  templateUrl: './sobre-internacional.component.html',
  styleUrls: ['./sobre-internacional.component.scss'],
})
export class SobreInternacionalComponent {
  public pasos: Requisito[] = [
    {
      img: '../../../../../assets/images/content/Equipo.png',
      id: 1,
      title: 'Postúlate online y carga los documentos',
      description: [
        'Curriculum Vitae',
        'Carta de motivación dirigida a RRII - ADENU',
        'Pasaporte escaneado vigente',
        'Certificado de Idioma',
      ],
    },
    {
      img: '../../../../../assets/images/content/Propuesta.png',
      id: 2,
      title: 'Si fuiste seleccionado, asiste a tu entrevista con RRII',
      description: [
        'Entrevista personal',
        'Comunicación de tu universidad de destino',
        'Explicación de documentos a completar',
      ],
    },
    {
      img: '../../../../../assets/images/content/Video.png',
      id: 3,
      title:
        'Postulación a la universidad destino y presentación de documentación',
      description: ['Acuerdo de materias', 'Reglamento de Movilidad'],
    },
    {
      img: '../../../../../assets/images/content/Presentar.png',
      id: 4,
      title: 'Aceptación y organización de tu viaje',
      description: [
        'Carta de aceptación de la universidad de destino',
        'Visa de estudiante',
        'Pasajes',
        'Seguro médico',
        'Alojamiento',
      ],
    },
    {
      img: '../../../../../assets/images/content/Live.png',
      id: 5,
      title: 'Vive tu experiencia internacional',
      description: [
        'Cursado en la universidad de destino',
        'Presentación de exámenes finales',
        'Pasaporte escaneado vigente',
        'Regreso a Panamá',
      ],
    },
  ];

  public requisitos: Criterio[] = [
    {
      index: 1,
      title: 'Ser alumno regular',
      description: 'Debes ser alumno regular de una carrera de grado en ADENU',
    },
    {
      index: 2,
      title: 'Promedio Académico',
      description:
        'Debes contar con un promedio académico igual o superior a 85',
    },
    {
      index: 3,
      title: 'Tiempo cursado',
      description:
        'Debes contar con hasta el sexto cuatrimestre cursado y aprobado o su equivalente, es decir, tener el primero y segundo año completo (cursado y aprobado).',
    },
    {
      index: 4,
      title: 'Pasaporte vigente',
      description:
        'Tus pasaporte debe contar con un mínimo de 6 meses después de concluir la movilidad en el exterior',
    },
    {
      index: 5,
      title: 'Certificado de Idioma',
      description:
        'Nivel B1-intermedio (mínimo). La universidad de destino es la que determina el tipo de certificado que debes presentar para acreditar tu  nivel de idioma extranjero. Este puede ser internacional o de ADENU.',
    },
  ];

  constructor() {}
}
