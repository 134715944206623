import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-buscojobs',
  templateUrl: './buscojobs.component.html',
  styleUrls: ['./buscojobs.component.scss']
})
export class BuscojobsComponent {

  constructor() { }

}
