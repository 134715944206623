<div class="main-container">
  <div id="buscador" class="buscador" *ngIf="!isLoading">
    <div class="form-field">
      <mat-icon class="material-icons-round">search</mat-icon>
      <input
        matInput
        type="search"
        placeholder="Buscar"
        (keyup)="search($event)"
        #input
        [(ngModel)]="searchValue"
      />
  
      <div class="suffix">
        <button
          *ngIf="searchValue"
          mat-icon-button
          color="primary"
          aria-label="Clear"
          (click)="cleanSearch()"
        >
          <mat-icon class="material-icons-round">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-tab-group mat-stretch-tabs="true" class="remove-border-bottom" *ngIf="list && !isError">
  <mat-tab label="TODOS"> 
      <div *ngIf="!isLoading" class="card-list">
        <app-resource-card  
        [resourceData]="resource" 
        class="card" 
        *ngFor="let resource of list | filter : searchValue" 
        [goToVideo]="goToVideo"
        [onRegisterCourse]="onRegisterCourse"></app-resource-card>
      </div>
    </mat-tab>
    <mat-tab  label="EN CURSO">
      <div class="card-list">
        <app-resource-card  
        [resourceData]="resource" 
        class="card" 
        *ngFor="let resource of inProgressList | filter : searchValue" 
        [goToVideo]="goToVideo"
        [onRegisterCourse]="onRegisterCourse"></app-resource-card>
      </div>
    </mat-tab>
    <!-- <mat-tab label="PENDIENTES">
      <div class="card-list">
        <app-resource-card  
        [resourceData]="resource" 
        class="card" 
        *ngFor="let resource of pendingList | filter : searchValue" 
        [goToVideo]="goToVideo"
        [onRegisterCourse]="onRegisterCourse"></app-resource-card>
      </div>
    </mat-tab> -->
    <mat-tab label="FINALIZADOS">
      <div class="card-list">
        <app-resource-card  
        [resourceData]="resource" 
        class="card" 
        *ngFor="let resource of endedList | filter : searchValue" 
        [goToVideo]="goToVideo"
        [onRegisterCourse]="onRegisterCourse"></app-resource-card>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="isError && !isLoading" class="error-section">
    <div class="nf-container">
      <img class="not-found-img" src="../../../assets/images/content/Error-pana.svg" alt="" />
      <h1 class="">Ups.. ha ocurrido un problema. Intente mas tarde</h1>
      <button mat-flat-button color="primary" routerLink="/home">Volver al inicio</button>
    </div>
  </div>
  <div class="notfound-container main-shadow mb-5" *ngIf="isLoading">
    <mat-spinner class="spinner"></mat-spinner>
  </div>