import { Action } from '@ngrx/store';
import { types } from '../types/types';

export const GET_GRADES = types.GET_GRADES;

export class GetGrades implements Action {
  readonly type = GET_GRADES;
  constructor(public payload: any) {}
}

export type GradesActions = GetGrades;
