<div class="container-bg">
  <div class="container">
    <div class="heading">
      <div class="title primary">¡Conoce a los últimos ganadores!</div>
    </div>

    <div class="grid-premios">
      <div class="card-body">
        <div class="puesto primary">1º PUESTO</div>
        <img
          src="../../../../assets/images/content/ganadores/PrimerLugar.png"
        />
        <div class="card-title secondary">Inclui2</div>
      </div>
      <div class="card-body">
        <div class="puesto primary">2º PUESTO</div>
        <img
          src="../../../../assets/images/content/ganadores/SegundoLugar.png"
        />
        <div class="card-title secondary">UNIT 1</div>
      </div>
      <div class="card-body">
        <div class="puesto primary">3º PUESTO</div>
        <div class="child-card">
          <div class="card-body">
            <img
              src="../../../../assets/images/content/ganadores/TercerLugar3.png"
            />
            <div class="card-title secondary">O'Clock</div>
          </div>
          <div class="card-body">
            <img
              src="../../../../assets/images/content/ganadores/TercerLugar2.png"
            />
            <div class="card-title secondary">NEONICOS</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
