import { warn } from "console";
import { Credit, CreditFormat } from "./interfaces/ICredit";

interface CreditStrategy {
  formatCredit(credit: Credit, config: any): boolean;
}

class RegularCreditStrategy implements CreditStrategy {
  formatCredit(credit: Credit, config: any): boolean {
      credit.name = `Regular (${credit.namesCarreras[0]})`;
      credit.general_type = 0;
      credit.type = 'asignatura'
      console.log(credit)
      return true
    }
  }
  
  class DualDegreeCreditStrategy implements CreditStrategy {
    formatCredit(credit: Credit, config: any): boolean {
      if(config.dual.type){
        credit.name = `Dual Degree (${credit.namesCarreras[1]})`;
        credit.type = 'asignatura' 
        credit.general_type = 1;
        return true
      }
      return false
    }
  }
  
  class ProfesionalPracticeStrategy implements CreditStrategy {
    formatCredit(credit: Credit, config: any): boolean {
      if(config.professionalPractice.type){
        credit.name = `Práctica Profesional`;
        credit.type = 'practica'
        credit.general_type = 2;
        return true
      }
  return false
  }
}

class SocialPracticeStrategy implements CreditStrategy {
  formatCredit(credit: Credit, config: any): boolean {
    if(config.socialPractice.type){
      credit.name = 'Práctica social';
      credit.type = 'practica' 
      credit.general_type = 3;
      return true
    }
    return false
  }
}

class TitleOptionStrategy implements CreditStrategy {
  formatCredit(credit: Credit, config: any): boolean {
    if(config.gradeOpMaestria.type){
      credit.name = 'Opción de titulación';
      credit.type = 'practica'
      credit.general_type = 4;
      return true
    }
    return false
  }
}

class TesisStrategy implements CreditStrategy {
  formatCredit(credit: Credit, config: any): boolean {
    if(config.gradeOpTesis.type){
      credit.name = 'Tesis';
      credit.type = 'practica'
      credit.general_type = 5;
      return true
    }
    return false
  }
}

class BusinessStrategy implements CreditStrategy {
  formatCredit(credit: Credit, config: any): boolean {
    if(config.gradeOpBusiness.type){
      credit.name = 'Business';
      credit.type = 'practica'
      credit.general_type = 6;
      return true;
    }
    return false
  }
}

class MateriaOnlineStrategy implements CreditStrategy {
  formatCredit(credit: Credit, config: any): boolean {
    if(config.matOnlPre.type){
      credit.name = 'Materia Online';
      credit.type = 'asignatura'
      credit.general_type = 7;
      return true;
    }
    return false
  }
}

export class CreditStrategyFactory {
  createStrategy(creditType: number, modalidad: string): CreditStrategy {
    if(modalidad === 'presencial'){
      switch (creditType) {
        case 15:
          return new RegularCreditStrategy();
        case 19:
          return new DualDegreeCreditStrategy();
        case 17:
          return new SocialPracticeStrategy();
        case 16:
          return new ProfesionalPracticeStrategy();
        case 18:
          return new TitleOptionStrategy();
        case 20:
          return new TesisStrategy();
        case 21:
          return new BusinessStrategy();
        case 10:
          return new MateriaOnlineStrategy();  
        // TODO: Agregar casos para otros tipos de crédito
        default:
          return null;
      }
    }
    if(modalidad === 'online'){
      switch (creditType) {
        case 1:
          return new RegularCreditStrategy();
        case 7:
          return new DualDegreeCreditStrategy();
        case 3:
          return new SocialPracticeStrategy();
        case 2:
          return new ProfesionalPracticeStrategy();
        case 4:
          return new TitleOptionStrategy();
        case 6:
          return new TesisStrategy();
        case 5:
          return new BusinessStrategy();
        case 10:
          return new MateriaOnlineStrategy();  
        // TODO: Agregar casos para otros tipos de crédito
        default:
          return null;
      }
    }

  }
}