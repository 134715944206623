<div class="card-body">
    <div class="graph">
        <img src="../../../../../../assets/images/bg/Patron-emprende-azul-md.png" alt="">
    </div>
    <div class="index">
        {{criterio.index}}
    </div>
    <div class="content">
        <div class="title">{{criterio.title}}</div>
        <div class="descripcion">{{criterio.description}}</div>
    </div>
</div>
