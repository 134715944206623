<div id="content-slide">
  <div
    id="grid-container"
    [style.background-image]="
      'url(' + cmsUrl + data.attributes.image.data.attributes.url + ')'
    "
    *ngIf="
      data.attributes.slide_type === 'Imagen y contenido' &&
      data.attributes.slide_order === 'Contenido a la derecha'
    "
    (click)="goTo()"
  >
    <div class="image"></div>
    <div class="body right">
      <div class="clip-bg right"></div>
      <div
        class="title"
        *ngIf="data.attributes.show_slide_title && data.attributes.slide_title"
      >
        <h1>
          {{ data.attributes.slide_title }}
        </h1>
      </div>

      <p
        *ngIf="
          data.attributes.show_slide_description &&
          data.attributes.slide_description
        "
      >
        {{ data.attributes.slide_description }}
      </p>
      <div class="cta" *ngIf="data.attributes.show_CTA">
        {{
          data.attributes.CTA_title !== ""
            ? data.attributes.CTA_title
            : "Ver más"
        }}
        <mat-icon class="material-icons-round">arrow_forward</mat-icon>
      </div>
    </div>
  </div>

  <div
    id="grid-container"
    class="inverted"
    *ngIf="
      data.attributes.slide_type === 'Imagen y contenido' &&
      data.attributes.slide_order === 'Contenido a la izquierda'
    "
    [style.background-image]="
      'url(' + cmsUrl + data.attributes.image.data.attributes.url + ')'
    "
    (click)="goTo()"
  >
    <div class="body left">
      <div class="clip-bg left"></div>
      <div
        class="title"
        *ngIf="data.attributes.show_slide_title && data.attributes.slide_title"
      >
        <h1>
          {{ data.attributes.slide_title }}
        </h1>
      </div>
      <p
        *ngIf="
          data.attributes.show_slide_description &&
          data.attributes.slide_description !== ''
        "
      >
        {{ data.attributes.slide_description }}
      </p>
      <div class="cta" *ngIf="data.attributes.show_CTA">
        {{
          data.attributes.CTA_title !== ""
            ? data.attributes.CTA_title
            : "Ver más"
        }}
        <mat-icon class="material-icons-round">arrow_forward</mat-icon>
      </div>
    </div>
    <div class="image"></div>
  </div>
  <div
    id="image-container"
    *ngIf="data.attributes.slide_type === 'Solo imagen'"
  >
    <div
      class="image"
      [style.background-image]="
        'url(' + cmsUrl + data.attributes.image.data.attributes.url + ')'
      "
      (click)="goTo()"
    ></div>
  </div>
</div>
