import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-banner',
  templateUrl: './slide-banner.component.html',
  styleUrls: ['./slide-banner.component.scss'],
})
export class SlideBannerComponent {
  @Input() data: any;

  constructor(public router: Router) {}

  goTo() {
    if (this.data.CTA_link !== null && this.data.CTA_link !== '') {
      if (this.data.CTA_link.includes('http')) {
        window.open(this.data.CTA_link, '_blank');
      } else {
        this.router.navigateByUrl(this.data.CTA_link);
      }
    }
  }
}
