<!-- <div class="sticky-header"><app-header [title]="'Horarios'"></app-header></div> -->
<div class="container mt-5">
  <div class="notfound-container main-shadow mb-5" *ngIf="loading">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
  <div
    class="notfound-container main-shadow mb-5"
    *ngIf="!loading && this.horarios.length === 0"
  >
    <img src="../../../assets/images/content/Horarios.svg" alt="" />
    <h2>
      {{ error ? "¡Próximamente!" : "No pudimos encontrar tus horarios" }}
    </h2>
    <p>
      {{
        error
          ? "Muy pronto podrás ver aquí los horarios de tus clases por periodo."
          : "Parece que aún no te han asignado clases en el sistema, si crees que es un error no dudes en contactar a tu Academic Advisor o vuelve a intentar más tarde."
      }}
    </p>
    <div class="button-container" *ngIf="!error">
      <button mat-stroked-button color="primary" routerLink="/academic-advisor">
        Contactar Academic Advisor
      </button>
      <button mat-flat-button color="primary" (click)="getHorarios()">
        Reintentar
      </button>
    </div>
  </div>

  <mat-tab-group
    mat-align-tabs="start"
    *ngIf="!loading && this.horarios && !error"
    [selectedIndex]="selectedTab"
    class="main-radius main-shadow"
  >
    <mat-tab
      *ngFor="let horario of horarios; let index = index"
      [label]="horario.codigo"
    >
      <div class="spinner" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>
      <div class="content" *ngIf="!loading && horario.horarios.length === 0">
        <ng-container>
          <app-empty-state
            [icono]="'event'"
            [mensaje]="'Aún no hay horarios disponibles para este periodo'"
          >
          </app-empty-state>
        </ng-container>
      </div>
      <ng-template matTabContent>
        <app-tabla-horarios [tableData]="horario.horarios"></app-tabla-horarios>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
