<mat-tab-group *ngIf="carreras != []" mat-align-tabs="start" class="main-shadow">
    <mat-tab
      *ngFor="let carrera of carreras; let index = index"
      [label]="carrera.carrera"
    >
<div class="progress-grid">
    <div class="progress-column">
      <div class="state-title">Estado <br/>de la carrera</div>
      <div class="circle-progress">
        <div class="current-value">
          <h1>{{ carrera.estado_carrera.progreso }}%</h1>
          <!-- <p>
            <strong>{{ carrera.estado_carrera.aprobadas }}</strong
            >/{{ carrera.estado_carrera.total }}
            ASIGNATURAS
          </p> -->
        </div>
        <div class="round-container">
          <round-progress
            [current]="carrera.estado_carrera.progreso"
            [max]="100"
            [color]="'url(#gradient)'"
            [gradient]="true"
            [background]="'#f1f1f1'"
            [radius]="100"
            [stroke]="20"
            [rounded]="true"
            [clockwise]="true"
            [responsive]="true"
            [duration]="800"
            [animation]="'easeInOutQuart'"
            [animationDelay]="0"
          ></round-progress>
        </div>

        <svg style="position: absolute">
          <!-- <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
        <stop offset="0%"  stop-color="#5a0030" />
        <stop offset="20%"  stop-color="#b31d15" />
        <stop offset="100%" stop-color="#fe66ac" />
      </linearGradient> -->
          <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
            <!-- <stop offset="0%" stop-color="#26A682" /> -->
            <stop offset="0%" stop-color="#35D0A5" />
            <stop offset="100%" stop-color="#59D9B5" />
          </linearGradient>
        </svg>
      </div>
    </div>

    <div class="progress-container" *ngIf="carrera.estado_carrera">
      <div class="subtitle-container">
        <div class="subject-count"><span>{{carrera.estado_carrera.cursando}}</span>/{{carrera.estado_carrera.total}}</div>
        <div class="subtitle-subject-count">ASIGNATURAS INICIADAS</div>
      </div>
      <app-data-display-card
        [type]="'icon'"
        [icon]="'done'"
        [value]="carrera.estado_carrera.cursando"
        [label]="'Cursando'"
      ></app-data-display-card>
      <app-data-display-card
        [type]="'icon'"
        [icon]="'schedule'"
        [value]="carrera.estado_carrera.pendientes"
        [label]="'Pendientes'"
      ></app-data-display-card>
      <app-data-display-card
        [type]="'icon'"
        [icon]="'menu_book'"
        [value]="carrera.estado_carrera.aprobadas"
        [label]="'Aprobadas'"
      ></app-data-display-card>
      <app-data-display-card
        [type]="'icon'"
        [icon]="'close'"
        [value]="carrera.estado_carrera.desaprobadas"
        [label]="'Desaprobadas'"
      ></app-data-display-card>
    </div>
  </div>

    </mat-tab>
    </mat-tab-group>