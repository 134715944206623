/* GENERAL */
export const materias = [
  {
    tag: 'pasantia-responsabilidad-social',
    image: 'https://i.postimg.cc/wTtzqbK3/pexels-fauxels-3184465.jpg',
    name: 'Pasantía de Responsabilidad Social Comunitaria',
    description:
      'Integra los conocimientos del aula a las necesidades reales de la comunidad a través de Proyectos Sociales, buscando darles respuestas donde se involucren desde los puntos de vista de la Investigación o Intervención Social',
  },
  {
    tag: 'pasantia-extension-profesional',
    image: 'https://i.postimg.cc/QNQ4pQpS/pexels-andrea-piacquadio-3756678.jpg',
    name: 'Pasantía de Extensión Profesional',
    description:
      'Es el instrumentos de vinculación entre la Universidad y los sectores productivos, empresarios y gubernamentales, y permite que estas organizaciones incorporen estudiantes de ADEN University en calidad de practicantes, sumando así recursos humanos capacitados para desempeñar distintos roles en la organización',
  },
  {
    tag: 'opcion-titulacion',
    image:
      'https://i.postimg.cc/0yTXYcHW/reference-books-diploma-and-graduate-cap-wc8291o3gkxjr210.jpg',
    name: 'Opción de Titulación',
    description:
      'Chequea las distintas opciones de titulación que te ofrece ADEN University para finalizar tu carrera',
  },
];

export const periodos = [
  { tag: 'QI', name: 'Primer cuatrimestre', init: 'Inicio Enero' },
  { tag: 'QII', name: 'Segundo cuatrimestre', init: 'Inicio Mayo' },
  { tag: 'QIII', name: 'Tercer cuatrimestre', init: 'Inicio Septiembre' },
];
/* PASANTÍA PROFESIONAL */

export const alianzas = [
  'https://i.postimg.cc/X7xbwjdQ/acp-logo.png',
  'https://i.postimg.cc/K8HhH09T/cocacola-logo.png',
  'https://i.postimg.cc/Bn6GprCs/copa-airlineslogo.png',
  'https://i.postimg.cc/Hn2f4zcV/dell-logo.png',
  'https://i.postimg.cc/SQfFhSTk/estee-lauder-logo.png',
  'https://i.postimg.cc/5tQWd8GR/halliburton-logo.png',
  'https://i.postimg.cc/MTk2WvJK/loreal-logo.png',
  'https://i.postimg.cc/d18MDwJR/nestle-logo.png',
  'https://i.postimg.cc/d3vbyp0b/p-g-logo.png',
  'https://i.postimg.cc/tCR0RKwP/philips-logo.png',
  'https://i.postimg.cc/J4pfsTCF/trade-alliance-logo.png',
];

export const faqsPasProf = [
  {
    q: '¿Puedo hacer menos del tiempo establecido para la práctica profesional si trabajo horas extras?',
    a: 'No es posible hacer menos del tiempo estipulado, la práctica se cuenta en meses exactos, por ejemplo, si iniciaste el 01 de marzo, debes culminar el 01 de septiembre. Se pudiera culminar después de la fecha si se han tenido percances médicos o de fuerza mayor que han impedido laborar durante cierto tiempo, por lo cual se recupera el tiempo al final y se extiende la práctica. Todo esto debe estar debidamente notificado y aprobado por la universidad y la empresa.',
  },
  {
    q: 'Tengo ya tiempo trabajando en la empresa ¿puedo considerar este tiempo dentro de la empresa para hacer menos tiempo o para una exoneración?',
    a: 'La práctica no posee exoneración, todos deben cursarla, y dicho cursado inicia cuando la materia es matriculada, todo tiempo anterior no se considera, por lo tanto no posee retroactividad.',
  },
  {
    q: '¿Puedo trabajar en un negocio familiar mi práctica profesional?',
    a: 'Efectivamente, las empresas familiares son permitidas, siempre que el alumno pueda desarrollar funciones relacionadas con su área de estudio y mantenga un tutor que le oriente y asigne funciones, y que mantenga idoneidad (es decir, que tenga un diploma del mismo nivel que el alumno va a obtener o mayor, por ejemplo, si el alumno cursa una carrera de licenciaturas, el tutor debería poseer un nivel similar o mayor (especialización en adelante).',
  },
  {
    q: 'Estoy fuera de Panamá en la actualidad ¿la universidad puede buscarme una práctica en el extranjero?',
    a: 'Las prácticas fuera de Panamá se aceptan, siempre que haya un tutor idóneo, y la empresa asigne al estudiante al área vinculada con su carrera de estudios y asigne funciones en esta área Sin embargo, ADEN no se hace responsable por la búsqueda de empresas fuera de Panamá. Aquellos alumnos que están fuera del país (Panamá) deben proponer a la Dirección de Prácticas Profesionales empresas donde practicar, y donde ya hayan mantenido contacto y puedan ser aceptados.',
  },
  {
    q: '¿Cuál puede ser mi jornada de trabajo?',
    a: '5 días a la semana, en el turno completo que la empresa defina, siendo mínimo de 6 horas y máximo de 8 horas. Se puede laborar turnos rotativos de igual forma.',
  },
  {
    q: '¿Cuál es la nota aprobatoria de la práctica profesional?',
    a: 'Al ser una materia como cualquier otra tiene una nota mínima aprobatoria de 71 puntos. La nota se compone de una parte de la empresa y otra parte de la universidad, por lo tanto el alumno debe siempre cumplir con ambas partes para estar al día.',
  },
  {
    q: '¿Qué es una carta de postulación? ',
    a: 'Es la carta que entrega la universidad a la empresa, una vez que el alumno ha sido aprobado para la actividad y la empresa ha sido validada. Esta carta es fundamental ya que sin la misma, el alumno no tiene autorización de parte de la universidad para iniciar la actividad en la empresa.',
  },
  {
    q: '¿Qué es una carta de aceptación?',
    a: 'Es la carta que hace la empresa una vez recibe de parte de la universidad la carta de postulación, y donde certifica que acepta al estudiante.',
  },
];

export const testimoniesPasProf = [
  {
    image: 'https://i.postimg.cc/7hz5FWGM/saddyth-galeano.jpg',
    name: 'Saddyth Galeano Arias',
    description:
      'La universidad te da muchas oportunidades de prácticas en empresas multinacionales y muy importantes en Panamá. Gracias a eso, estuve en Loreal, y durante los meses de práctica siempre tuve un excelente apoyo tanto de mis managers como de la profesora Lorena. La experiencia en mi pasantía fue excelente, aprendí muchísimo y no lo sentí como una materia más que tenía que aprobar, sino una oportunidad para crecer profesionalmente. Las prácticas profesionales son muy importantes para descubrir que quisieras hacer y dónde te gustaría estar; y al final, es una ventaja poder trabajar y aprender mientras eres estudiante, para así, tener ese conocimiento básico al momento de obtener un trabajo fijo cuando te gradúas. L’Oreal me dio la oportunidad de estar un año en la empresa y me abrió las puertas para seguir creciendo en otras multinacionales. Actualmente estoy en Adidas.',
    company: 'L’Oreal',
    gradYear: '2022',
  },
  {
    image: 'https://i.postimg.cc/RCJJJNs6/Nicole-Kronfel.jpg',
    name: 'Nicole Kronfel',
    description:
      'Mi experiencia en la práctica profesional fue todo un reto. Desde noviembre de 2021 hasta noviembre de 2022 trabajé como pasante en el departamento de Rewards LATAM en Philips y fue una experiencia increíble y llena de retos. Gracias a esta pasantía aprendí mucho en el ámbito laboral y por el constante apoyo de mis jefes también mejoré en lo personal. El haber hecho práctica profesional, me ayudó a mejorar mi CV y demostrarle al mundo mis capacidades. Gracias a esta práctica y todo mi esfuerzo, me convertí en empleada regular de la empresa en un nuevo departamento.',
    company: 'Philips',
    gradYear: '2022',
  },
  {
    image: 'https://i.postimg.cc/SKZYC8b8/Melanie-Narvaez.jpg',
    name: 'Melanie Narváez',
    description:
      'Mi experiencia con las prácticas profesionales en Aden University fue sumamente enriquecedora, ya que, en ambas empresas en las cuales cursé esta materia aprendí diferentes temas que me han ayudado a crecer tanto personalmente como profesionalmente. Los meses de práctica profesional fueron meses llenos de retos. En mi caso, yo nunca había tenido alguna experiencia laboral anteriormente, por lo tanto, no sabía qué esperar de las prácticas profesionales, sin embargo, me encantaron ambas experiencias. Estoy muy contenta actualmente con lo que he logrado gracias a esas prácticas y por supuesto a todos los conocimientos que me ofreció la universidad desde el día uno. Para mí, el valor de esta práctica profesional fue que todos los conocimientos teóricos que tienes, los llevas al plano de la realidad. Yo durante mis prácticas presencié temas que había estudiado durante mi carrera, pero esta vez llevados a la realidad, con objetivos que cumplir y demás. Por último, para mí es importante la práctica profesional porque, aparte de todo lo que mencioné, me ayudó también a rodearme de personas expertas en sus áreas de trabajo que me han enseñado un poco de todo. Yo estoy muy agradecida tanto con la universidad, como con las organizaciones que me dieron la oportunidad de cursar mis prácticas profesionales. Es una experiencia que sin dudarlo volvería a repetir.',
    company: 'Selina y ABB (3 meses en cada una)',
    gradYear: '2022',
  },
  {
    image: 'https://i.postimg.cc/rm3dYkmw/Sebas-Ramos.jpg',
    name: 'Sebastián Ramos',
    description:
      'Mi experiencia en la materia de prácticas profesionales comenzó en la empresa multinacional Procter & Gamble, en el área de Data Strategy. Todo inició con reuniones en las que participaban representantes de la universidad, de la empresa y mi persona. Estas reuniones sirvieron para explicar mejor al estudiante y a la empresa las áreas que se iban a estar evaluando en esta materia. En general la experiencia fue bastante buena ya que recibí el apoyo adecuado tanto de la empresa como de la universidad para lograr culminar con éxito la materia. El valor que ofrece participar en este tipo de materias es que te brindan una oportunidad para aplicar lo que has aprendido en la universidad en un entorno real y relevante. Además, también te ayudan a desarrollar habilidades blandas, técnicas y el trabajo en equipo. También puedes establecer contactos profesionales que pueden ser útiles en el futuro para tu carrera. En el caso específico de Procter & Gamble, se logra aprender mucho sobre marketing y los mercados latinoamericanos, que en cualquier empresa son datos e información relevante. Todo este proceso me sirvió para ser contratado formalmente en la empresa como Brand Manager',
    company: 'P&G',
    gradYear: '2022',
  },
];

/* PASANTÍA SOCIAL */

export const faqsPasSocial = [
  {
    q: '¿Puedo realizar mi labor social de forma individual?',
    a: 'La labor social es realizable de forma colaborativa, en equipo de trabajo. La intención es compartir saberes y generar valor desde la sinergia del trabajo en equipo.',
  },
  {
    q: '¿Tengo que realizar mi labor social en la práctica o solo es un material teórico?',
    a: 'El curso propone que superes una proporción del material de forma teórica y una práctica. Si debes realizar las actividades que propones desde la teoría y documentar tus actividades en campo en el informe final.',
  },
  {
    q: '¿Qué pasa si mis compañeros no pertenecen a mi carrera o tienen un huso horario distinto al mío?',
    a: 'Puedes integrarte en un equipo de trabajo que mejor se alinee a tus intereses en relación con tus valores y cambio social que quieren realizar. La virtualidad les permitirá realizar su trabajo social desde el país que se encuentren siempre que se planifiquen y coordinen entre sí.',
  },
  {
    q: '¿Debo completar las actividades que propone la plataforma antes de iniciar mi trabajo de campo?',
    a: 'En efecto sí. Cada actividad es calificable y está diseñada para preparar al alumno en su práctica de campo.',
  },
  {
    q: '¿Puedo convalidar mis horas o trabajo que he realizado o vengo realizando en una ONG de mi localidad?',
    a: 'No. Desafortunadamente la pasantía social requiere que tu proyecto de labor social esté alineada a 5 factores: valores de ADEN U, valores de ODS, habilidades de los integrantes del equipo, trabajo en equipo donde todos sus integrantes participen bajo ese mismo objetivo y por último, deben estar alineados a la temáticas sugeridas por la universidad.',
  },
];

/* OPCION DE GRADO */

export const faqsTesis = [
  {
    q: '¿Qué tiempo se dispone para completar el desarrollo del trabajo final de grado?',
    a: 'Se dispone de 5 meses para el desarrollo del proyecto y adicional 15 días para realizar la sustentación y defensa del mismo.',
  },
  {
    q: '¿Qué opciones puedo tener para desarrollar el proyecto de investigación?',
    a: 'El proyecto puede desarrollarse mediante la presentación de una tesis de investigación, un estudio de mercado o un plan de negocio.',
  },
  {
    q: '¿La elección de tutor cómo se realiza?',
    a: 'La Vicerrectoría Académica, en conjunto con el Decano de Investigación designan los tutores, en función del área de desarrollo del tema elegido.',
  },
  {
    q: '¿Se puede tener la posibilidad de contar con tutores externos?',
    a: 'Sí, siempre y cuando éste sea remitido a Vicerrectoría Académica y el Decanato de Investigación para su aprobación.',
  },
  {
    q: '¿En caso de presentar inquietudes, a quién debo dirigirme?',
    a: 'Al Academic Advisor, quien mantendrá comunicación con Vicerrectoría Académica para brindarle la atención que sea necesaria.',
  },
];

export const faqsMaestria = [
  {
    q: '¿Hay encuentros virtuales programados durante las semanas de cursado?',
    a: 'Si, se tendrán encuentros programados al inicio de cada unidad de cursado.',
  },
  {
    q: '¿Tendremos clases únicamente online o también podrán ser presenciales?',
    a: 'Las clases actualmente son en modalidad online, pero si se logra habilitar la opción presencial podrían participar.',
  },
  {
    q: '¿Las actividades se pueden desarrollar en grupo?',
    a: 'Las actividades son individuales.',
  },
  {
    q: '¿Cuántas oportunidades se tienen en las actividades de evaluación?',
    a: 'Se cuentan con dos oportunidades por cada actividad.',
  },
];

export const materiasMaestria = [
  {
    image: 'https://i.postimg.cc/Y9HGcCb0/pexels-fauxels-3184292.jpg',
    name: 'Estrategia corporativa y desarrollo de negocios',
    description:
      'Este curso o asignatura forma parte del Programa de Dirección Gerencial, y es transversal a todos los programas de MBA y en algunas Maestrías especializadas',
    duration: '4 semanas',
    goals: {
      general:
        'Al finalizar el curso, de manera general el estudiante será capaz de analizar las decisiones corporativas de una empresa con el fin de proponer acciones para asegurar la ejecución del diseño estratégico y así lograr una implementación efectiva; reconocer el impacto sobre los negocios en la actualidad de las macro tendencias tecnológicas, socio-culturales y ambientales y podrá definir nuevos modelos de negocios en entornos analógicos y digitales.',
      specific:
        'De manera específica el alumno será capaz de aprender la metodología para diseñar una planificación estratégica a mediano y largo plazo; realizar un análisis profundo del entorno, mercado, competencia y empresa; comprender los distintos tipos de estrategia, su alineamiento y los aspectos claves para una implementación efectiva; aprender a establecer la misión, visión, valores y objetivos que marcan el rumbo a seguir por todos en la organización; entender los diferentes modelos de análisis de estrategias y seleccionar el criterio de evaluación más adecuado; analizar el impacto de las macro tendencias tecnológicas, socioculturales y ambientales en los negocios y comprender la genética de los nuevos negocios.',
    },
    weeks: [
      {
        name: 'SEMANA I - DIRECCIÓN ESTRATÉGICA',
        content: [
          { item: 'Introducción a la Estrategia', subcontent: null },
          {
            item: 'Punto De Partida',
            subcontent: [
              'Agenda de trabajo',
              'Estrategia corporativa como un proceso simple',
            ],
          },
          {
            item: 'Desarrollo de una visión estratégica',
            subcontent: [
              'Los tres elementos de una visión estratégica',
              'Comunicación de la visión estratégica',
            ],
          },
          {
            item: 'Evaluación de la fuerza competitiva de la industria',
            subcontent: [
              'Compentencias y fuerzas competitivas de la industria',
              'Los métodos de análisis competitivo y de la industria',
              'El modelo de las cinco fuerzas de la competencia',
              'Análisis del Macro Entorno. Pest',
            ],
          },
          {
            item: 'Evaluación de la fuerza competitiva de la empresa',
            subcontent: [
              'La naturaleza de la ventaja competitiva',
              'Identificación de las fortalezas y capacidades de recursos de la compañía',
              'Identificación de las debilidades y deficiencias de recursos de la compañía',
              'Identificación de las amenazas para la rentabilidad futura de la empresa',
            ],
          },
          {
            item: 'Desarrollo de una misión estratégica',
            subcontent: [
              'La misión no es obtener utilidades',
              'Incluir los aspectos de qué, quién y cómo en la definición del negocio',
            ],
          },
          {
            item: 'La propuesta de valor',
            subcontent: [
              'Las estrategias competitivas',
              'Decisiones claves para crecer',
            ],
          },
          {
            item: 'La matriz estratégica',
            subcontent: [
              'El análisis FODA (Fortalezas, Oportunidades, Debilidades y Amenazas',
              'El verdadero valor del análisis FODA',
              'Management training (entrenamiento de gestión)',
            ],
          },
          { item: 'Simulador: conciencia estratégica', subcontent: null },
        ],
      },
      {
        name: 'Semana II - Genética de los negocios',
        content: [
          {
            item: 'Evolución de la sociedad, paradigmas centrales',
            subcontent: [
              'Relación con el medioambiente',
              'Respeto por la diversidad y la inclusión',
              'Cómo nos comunicamos e informamos',
              'Visualización del trabajo',
              'Rol de las empresas',
            ],
          },
          {
            item: 'Evolución de la concepción de las empresas',
            subcontent: [
              'Punto de partida: los orígenes, libertad con ética',
              'Los años del libertinaje empresarial',
              'Legalidad, marcos regulatorios',
              'Filantropía corporativa',
              'Responsabilidad Social Empresarial',
              'Estrategias de valor compartida',
              'Responsabilidad Social Directiva',
              'Compliance',
              'Empresas B',
            ],
          },
          {
            item: 'Consecuencias',
            subcontent: [
              'Nuevos consumidores y ecosistemas de consumo',
              'Economía colaborativa y economía circular',
              'El valor de la reputación corporativa',
            ],
          },
          {
            item: 'Desarrollo del Sistema B',
            subcontent: [
              'Parámetros conceptuales',
              'Modelo de negocios',
              'Evolución y ejemplos',
              'El estándar P5',
            ],
          },
          {
            item: 'Cambio de paradigmas',
            subcontent: [
              'Del daño ambiental a la reparación ambiental',
              'De depredadores corporativos a generadores del bienestar',
              'De la prosperidad de los accionistas a la properidad de los stakeholders',
            ],
          },
          {
            item: 'Gran salto transversal: de la evasión/cumplimiento de la ley al fomento de la ética',
            subcontent: null,
          },
          {
            item: 'Empresarios del siglo XXI',
            subcontent: null,
          },
        ],
      },
    ],
  },
  {
    image: 'https://i.postimg.cc/4yFx3fbz/pexels-fauxels-3184298.jpg',
    name: 'Liderazgo para la transformación',
    description:
      'Este curso o asignatura forma parte del Programa de Habilidades Directivas, y es transversal a todos los programas de MBA y de algunas Maestrías especializadas',
    duration: '4 semanas',
    goals: {
      general:
        'Al finalizar el curso, de manera general el estudiante será capaz de comprender las características del liderazgo, así como manejar las herramientas para la construcción de un ambiente con valores que permitan alcanzar la sustentabilidad de los resultados en el tiempo.',
      specific:
        'De manera específica el alumno será capaz de distinguir las diferencias existentes entre el liderazgo transformacional y el liderazgo personal; identificar los diversos roles que debe asumir un líder empresarial; reconocer el impacto que tienen los líderes sobre el equipo de colaboradores de la organización y sus efectos en la calidad del producto o servicio; comprender los requisitos para construir un liderazgo personal, que pueda incorporar en la vida diaria de forma permanente; facilitar herramientas para mejorar el impacto del líder en su ámbito de referencia; y reconocer las principales características de las inteligencias múltiples.',
    },

    weeks: [
      {
        name: 'Semana I - Liderazgo Transformacional',
        content: [
          {
            item: 'La nueva realidad necesita nuevos líderes.',
            subcontent: null,
          },
          {
            item: 'El fenómeno del liderazgo como concepto relacional, grupal y de co-construcción',
            subcontent: null,
          },
          {
            item: 'La díada líder-liderado.',
            subcontent: null,
          },
          {
            item: 'Los roles de un líder en momentos de disrupción digital.',
            subcontent: null,
          },
          {
            item: 'El líder como mediador de sentido.',
            subcontent: null,
          },
          {
            item: 'La construcción de propósito.',
            subcontent: null,
          },
          {
            item: 'El líder como gestor del talento humano.',
            subcontent: null,
          },
          {
            item: 'El líder como coach.',
            subcontent: null,
          },
          {
            item: 'De la presión, a la impulsión y el desarrollo.',
            subcontent: null,
          },
          {
            item: 'Los distintos momentos en el ciclo de vida de un colaborador: cómo apalancarse desde un liderazgo inspirador.',
            subcontent: null,
          },
          {
            item: 'El líder como armonizador y aglutinador de talentos.',
            subcontent: null,
          },
          {
            item: 'Afiliación y pertenencia.',
            subcontent: null,
          },
          {
            item: 'El líder como servidor.',
            subcontent: null,
          },
          {
            item: 'La Teoría de los Stewardship.',
            subcontent: null,
          },
          {
            item: 'Promoviendo la motivación y el bienestar del equipo.',
            subcontent: null,
          },
          {
            item: 'El líder como arquitecto organizacional.',
            subcontent: null,
          },
          {
            item: 'De la definición de la visión al Diseño Cultural.',
            subcontent: null,
          },
          {
            item: 'De la jerarquía a las redarquías.',
            subcontent: null,
          },
          {
            item: 'Liderazgo y ética en entornos de crisis.',
            subcontent: null,
          },
        ],
      },
      {
        name: 'Semana II - Liderazgo Personal',
        content: [
          {
            item: 'El desafío de la autogestión personal en épocas de incertidumbre.',
            subcontent: null,
          },
          {
            item: 'La vida como devenir, o la vida como construcción personal.',
            subcontent: null,
          },
          {
            item: 'Los instrumentos del Líder Personal: El Plan Personal de Vida (PPV).',
            subcontent: null,
          },
          {
            item: 'Las cuatro columnas del PPV: Valores, Misión Personal, Objetivos y Plan de Acción.',
            subcontent: null,
          },
          {
            item: 'El Plan de Desarrollo Profesional (PDP).',
            subcontent: null,
          },
          {
            item: 'PDP: la autogestión de la Carrera Profesional.',
            subcontent: null,
          },
          {
            item: 'Los conocimientos como base del éxito.',
            subcontent: null,
          },
          {
            item: 'Competencias 2021: qué se necesita para triunfar en entornos VUCA.',
            subcontent: null,
          },
          {
            item: 'Los requisitos para construir liderazgo personal.',
            subcontent: null,
          },
          {
            item: 'El autoconocimiento: el diagnóstico personal como punto de partida.',
            subcontent: null,
          },
          {
            item: 'La mejora continua, kaizen personal para desarrollarse y crecer.',
            subcontent: null,
          },
          {
            item: 'Voluntad y disciplina, palancas del éxito.',
            subcontent: null,
          },
          {
            item: 'El ejercicio del libre albedrío.',
            subcontent: null,
          },
          {
            item: 'Accountability personal, ser protagonistas de nuestra vida.',
            subcontent: null,
          },
          {
            item: 'La Marca Personal.',
            subcontent: null,
          },
          {
            item: 'Instancia de auto-conocimiento mediante el Test CTPI-R.',
            subcontent: null,
          },
        ],
      },
      {
        name: 'Semana III - Inteligencias Múltiples',
        content: [
          {
            item: 'Inteligencias múltiples para un mundo dinámico, complejo y ambiguo.',
            subcontent: null,
          },
          {
            item: 'El líder y el manejo emocional.',
            subcontent: null,
          },
          {
            item: 'Las neurociencias y su aporte a la comprensión integral del individuo.',
            subcontent: null,
          },
          {
            item: 'Más allá del coeficiente intelectual.',
            subcontent: null,
          },
          {
            item: 'Las inteligencias múltiples.',
            subcontent: null,
          },
          {
            item: 'La inteligencia emocional y la auto-regulación emocional.',
            subcontent: null,
          },
          {
            item: 'Habilidad emocional y bienestar humano.',
            subcontent: null,
          },
          {
            item: 'La psicología positiva, la resiliencia y el manejo de la adversidad en entornos complejos y de alta incertidumbre.',
            subcontent: null,
          },
          {
            item: 'Los estados mentales.',
            subcontent: null,
          },
          {
            item: 'La inteligencia intrapersonal y la inteligencia interpersonal.',
            subcontent: null,
          },
          {
            item: 'Comportamientos aprendidos y canalización de las emociones, técnicas de control.',
            subcontent: null,
          },
          {
            item: 'Los pilares de la motivación humana.',
            subcontent: null,
          },
          {
            item: 'Instancia de auto-conocimiento mediante el EMOTION TEST',
            subcontent: null,
          },
        ],
      },
      {
        name: 'Semana VI - Integradora',
        content: [
          {
            item: 'Armonía',
            subcontent: null,
          },
          {
            item: 'Trabajo en equipo',
            subcontent: null,
          },
          {
            item: 'Gestión del talento',
            subcontent: null,
          },
          {
            item: 'Binestar Organizacional',
            subcontent: null,
          },
          {
            item: 'Gestión del cambio',
            subcontent: null,
          },
          {
            item: 'Liderazgo transformacional en tiempos BANI',
            subcontent: null,
          },
          {
            item: 'Empleabilidad sostenible',
            subcontent: null,
          },
        ],
      },
    ],
  },
];
