<div class="dialog-header">
  <div mat-dialog-title>
    <h1>Mi carrito</h1>
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
</div>
<div class="dialog-container" *ngIf="editableUnits <= 0 && !sending">
  <div class="notfound-container">
    <img src="../../../assets/images/content/cart-pay.svg" alt="" />
    <h2>¡Tu carrito está vacío!</h2>
    <p>Añade un producto ahora</p>
    <div class="button-container">
      <button mat-flat-button color="primary" (click)="onClose()">
        Emprezar a comprar
      </button>
    </div>
  </div>
</div>
<div class="dialog-container" *ngIf="sending">
  <div class="notfound-container">
    <mat-spinner class="spinner"></mat-spinner>
    <p>Generando link de pago...</p>
  </div>
</div>
<div class="dialog-container" *ngIf="editableUnits > 0 && !sending">
  <div mat-dialog-content>
    <div class="product-list">
      <ng-container style="width: 100%" *ngFor="let producto of editableCart">
        <app-cart-item-card
          [product]="producto"
          [actions]="true"
          [updateProduct]="updateProduct"
          [removeProduct]="removeProduct"
        ></app-cart-item-card>
      </ng-container>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="footer">
      <div class="list-item">
        <div class="title-1">TOTAL</div>
        <div class="price">
          <div class="unit">US$</div>
          <div class="number">{{ editableTotal | number: "1.2-2" }}</div>
        </div>
      </div>
      <!-- <div class="list-item">
            <div class="title-2">Cant. cuotas</div>
            <div class="price">
              <div class="number">{{ cantCuotas }}</div>
            </div>
          </div> -->
      <!-- <div class="list-item">
            <div class="title-2">
              Valor cuota{{ formaPago !== "online" ? " (a transferir)" : "" }}
            </div>
            <div class="price">
              <div class="unit">US$</div>
              <div class="number">
                {{ editableTotal / cantCuotas | number: "1.2-2" }}
              </div>
            </div>
          </div> -->

      <button
        mat-flat-button
        style="width: 100%"
        color="primary"
        id="btn-confirm-link"
        [disabled]="
          (formaPago !== 'online' && nroComprobante === '') ||
          sending ||
          editableUnits <= 0
        "
        (click)="finishPurchase()"
      >
        {{
          formaPago === "online" ? "Generar link de pago" : "Finalizar compra"
        }}
      </button>
      <button
        mat-button
        style="width: 100%"
        color="primary"
        (click)="onClose()"
      >
        Seguir comprando
      </button>
    </div>
  </div>
</div>
