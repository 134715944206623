import { MatDialog } from '@angular/material/dialog';
import { UserDoc } from './../../../../shared/interfaces/IUserDoc';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileDescriptionDialogComponent } from 'src/app/shared/components/dialogs/file-description-dialog/file-description-dialog.component';
import { DocumentsService } from 'src/app/shared/services/documents.service';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { MultiDocsModalComponent } from '../multi-docs-modal/multi-docs-modal.component';
import { AlertDialogComponent } from 'src/app/shared/components/alerts/alert-dialog/alert-dialog.component';
import { FileModeComponent } from 'src/app/shared/components/dialogs/file-mode/file-mode.component';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent implements OnInit{
  @Input() userDoc: UserDoc;
  @Input() isSpecMode: boolean;
  @Input() changeDocState: (docId, state) => void;
  @Input() refreshDocs: () => void;

  isUploading = false;

  constructor(
    private alertService: AlertService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private documentsService: DocumentsService
  ) {}

  ngOnInit(){}

  openSpecAlert(){
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '400px',
      data: {
        title: 'No puedes realizar cambios',
        description: 'Recuerda que únicamente puedes ver la plataforma, sin la posibilidad de hacer modificaciones.',
        action: 'Entendido',
        img: '../../../../../assets/images/content/stop.png' 
      },
      panelClass: 'plain-alert',
    });
  }


  openFileModeSelect(){
    console.log(this.userDoc)
    const dialogRef = this.dialog.open(FileModeComponent, {
      width: '33rem',
      height: '34rem',
      data:{
        userDoc: this.userDoc
      }
    })
    dialogRef.afterClosed().subscribe((data) => {
      if(data.refresh){
        this.refreshDocs();
      }
    })
  }

  openMotivo(): void {
    const dialogRef = this.dialog.open(FileDescriptionDialogComponent, {
      minWidth: '450px',
      maxWidth: '90vw',
      data: {
        title: this.userDoc.nombre,
        description: this.userDoc.motivo,
        status: this.userDoc.estado,
        update:
          this.userDoc.estado.toLowerCase() !== 'pendiente' &&
          this.userDoc.estado.toLowerCase() !== 'aprobado' &&
          this.userDoc.id !== 0,
      },
    });

    dialogRef.afterClosed().subscribe((file) => {
      if (file) {
        this.openFileModeSelect()
      }
    });
  }
  

}
