<div id="estado-financiero">
  <div class="container">
    <div class="error">
      <img src="../../../assets/images/content/alert-bloqueo.png" alt="" class="img-error">
      <h1>Esta sección está en mantenimiento, intenta más tarde.</h1>
    </div>
    <!-- <div class="notfound-container main-shadow mb-5" *ngIf="isLoading">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
    <div
      class="notfound-container main-shadow mb-5"
      *ngIf="!isLoading && (!estadoFinanciero || isError)"
    >
      <img src="../../../assets/images/content/Estado-Financiero.svg" alt="" />
      <h2>No pudimos encontrar tu estado financiero</h2>
      <p>
        Parece que aún no tienes facturas en el sistema, si crees que es un
        error no dudes en contactar a tu Academic Advisor o vuelve a intentar
        más tarde.
      </p>
      <div class="button-container">
        <button
          mat-stroked-button
          color="primary"
          routerLink="/academic-advisor"
        >
          Contactar Academic Advisor
        </button>
        <button mat-flat-button color="primary" (click)="getEstadoFinanciero()">
          Reintentar
        </button>
      </div>
    </div>

    <div class="heading" *ngIf="!isLoading && estadoFinanciero">
      <div class="user-profile main-shadow main-radius">
        <div class="avatar">
          <img
            src="{{
              (user$ | async)?.foto64
                ? (user$ | async)?.foto64
                : 'assets/images/logos/avatar.png'
            }}"
            alt="profile-image"
          />
        </div>
        <div class="details">
          <div class="name">{{ (user$ | async)?.nombre }}</div>
          <div class="status" *ngIf="estadoFinanciero">
            <div
              class="chip red-bg"
              *ngIf="estadoFinanciero.total_adeudado > 0"
            >
              EN DEUDA
            </div>
            <div
              class="chip green-bg"
              *ngIf="estadoFinanciero.total_adeudado <= 0"
            >
              AL DÍA
            </div>
          </div>
        </div>
      </div>
      <div class="balance-grid">
        <app-generic-data-card
          [label]="'Total facturado'"
          [value]="'USD ' + estadoFinanciero.total_facturado.toFixed(2)"
          [icon]="'attach_money'"
          [color]="'#35D0A5'"
        ></app-generic-data-card>
        <app-generic-data-card
          [label]="'Total adeudado'"
          [value]="'USD ' + estadoFinanciero.total_adeudado.toFixed(2)"
          [icon]="'schedule'"
          [color]="'#e0bd28'"
        ></app-generic-data-card>
        <app-generic-data-card
          [label]="'Total Vencido'"
          [value]="'USD ' + estadoFinanciero.total_vencido.toFixed(2)"
          [icon]="'event_busy'"
          [color]="'#cc1f2f'"
        ></app-generic-data-card>
      </div>
    </div>

    <mat-tab-group
      mat-align-tabs="start"
      class="main-radius main-shadow"
      *ngIf="!isLoading && this.facturas"
    >
      <mat-tab label="Mis comprobantes">
        <div class="content">
          <app-payment-table
            [facturas]="facturas"
            [userName]="(user$ | async)?.nombre"
            [totalVencido]="estadoFinanciero.total_vencido"
            [totalAdeudado]="estadoFinanciero.total_adeudado"
            [totalFacturado]="estadoFinanciero.total_facturado"
          ></app-payment-table>
        </div>
      </mat-tab>
    </mat-tab-group> -->
  </div>
</div>

<!-- <div
  class="soonContainer"
  *ngIf="
    !this.isLoading &&
    (!estadoFinanciero || estadoFinanciero.resultado !== 'OK' || isError)
  "
>
  <div class="soonMessage">
    <img src="../../../assets/images/content/waiting.svg" alt="Proximamente" />
    <h2>¡Próximamente!</h2>
    <p>Aún no tenemos disponible tu estado financiero</p>
    <a mat-button class="bg-primary-aden text-white" href="/home">
      Volver al inicio
    </a>
  </div>
</div>

<div class="soonContainer" *ngIf="this.isLoading">
  <div class="soonMessage spinner">
    <mat-spinner></mat-spinner>
  </div>
</div> -->
