import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';
import { AlertService } from '../services/alerts.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private dataService: DataService, private router: Router, private authService: AuthService, private alertService: AlertService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    const isExp = localStorage.getItem('inactivity_exp');
    if (isExp) {
      const expDate = new Date(isExp);
      const now = new Date();
      if (now >= expDate) {
        console.log('Token expirado');
        localStorage.removeItem('token');
        localStorage.removeItem('qr_token');
        localStorage.removeItem('sis_id');
        localStorage.removeItem('rol');
        localStorage.removeItem('repo_id');
        localStorage.removeItem('exp');
        localStorage.removeItem('inactivity_exp');
        localStorage.setItem('origin', state.url);
        this.router.navigateByUrl('login');
        this.alertService.openAlert('Sesion Expirada', 'Su sesión ha expirado, por favor inicie sesión nuevamente', 'Cerrar');
        return false;
      }
    }
    if (this.authService.isAuthorizated()) {
      this.authService.refreshExpTime();
      return true;
    } else {
      localStorage.setItem('origin', state.url);
      console.log('Access denied');
      this.authService.clearToken();
      this.authService.clearSis_id();
      this.router.navigateByUrl('login');
      return false;
    }
  }
}
