import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'modal-confirm',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ModalConfirm {
  credits = 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  sis_id = '';
  materias = [];
  materiasName = [];
  materiasPermitidas = 0;
  menu = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ModalConfirm>
  ) {
    this.credits = data.materias.length;
    this.materiasName = data.materias;
    this.sis_id = data.sis_id;
    this.materias = data.materiasService;
  }

  close(): void {
    this.dialogRef.close();
  }

  send(): void {
    this.dialogRef.close('ok');
  }
}
