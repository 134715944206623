<div mat-dialog-title class="titulo">Acepta los términos del contrato para continuar</div>
<div mat-dialog-content>
    <iframe
      src="https://drive.google.com/file/d/12uM-37Xqu1zlsWJN4HwJ3vdJDQD7qiZV/preview"
      frameborder="0"
      width="640"
      height="480"
    ></iframe>
</div>
<div mat-dialog-actions>
  <mat-checkbox
    class="checkbox-container"
    color="primary"
    [checked]="acceptedContract"
    (change)="changeCheck()"
  >
    <div class="text">
      He leído y acepto los términos y condiciones del contrato
    </div>
  </mat-checkbox>
  <button
    mat-flat-button
    color="primary"
    class="continue-button"
    [mat-dialog-close]="acceptedContract"
    [disabled]="!acceptedContract"
  >
    Continuar
  </button>
</div>
