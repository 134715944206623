<div *ngIf="materia" class="item-materia">
  <div class="item-body">
    <div class="left-body">
      <div class="left-contain">
        <div class="nombre">
          {{ materia.nombre }}        
        </div>
        <div
        matRipple
        class="btn-info"
        *ngIf="
        (materia.descripcion && materia.descripcion !== undefined) ||
        materia.horarios?.length > 0 ||
        materia.modelo
        "
        (click)="this.openDescription(materia, course.carrera.id, electivePensum)"
        >
        <span class="material-icons-round"> info </span>
        <div class="text">Más informacion</div>
      </div>
      <div *ngIf="materia.horario" class="schedule">{{materia.horario ? materia.horario : ''}}</div>
      </div>
      <p class="tag" *ngIf="materia.tipo_credito_id === 10">Materia online</p>
      <div class="dificultad-container">
        <p>Categoría</p>
        <div
          class="dificultad"
          [ngClass]="{
            a: materia.modelo === 'a',
            b: materia.modelo === 'b',
            c: materia.modelo === 'c'
          }"
        >
          {{
            materia.modelo === "a"
              ? "Platón"
              : materia.modelo === "b"
              ? "Sócrates"
              : materia.modelo === "c"
              ? "Aristóteles"
              : null
          }}
        </div>
      </div>
    </div>
    <div class="right-body">
      <!-- REMOVE BUTTON -->
      <button
        id="{{ materia.subject_id }}"
        *ngIf="
          (materia.estado_alumno === 'No Inscripto' ||
            materia.estado_alumno === 'Cancelado' ) &&
          materia.isSelected
        "
        (click)="removeMateria(materia.subject_id, course.carrera.id, materia.periodo_id, materia.isSecond)"
        mat-button
        class="text-white bg-disabled add-btn blue-status"
      >
        <mat-icon class="material-icons-round">remove</mat-icon>
      </button>
      <!-- ADD BUTTON -->
      <button
        id="{{ materia.subject_id }}"
        *ngIf="
          ((materia.estado_alumno === 'No Inscripto' ||
            materia.estado_alumno === 'Cancelado' || !materia.estado_alumno) &&
            !materia.isSelected &&
            materia.inscriptos >= materia.maxima) ||
          ((materia.tipo === 'to_elected' ||
            materia.tipo === 'to_elect_grade' ||
            materia.tipo === 'to_elected_english') &&
            electiveSelected &&
            !materia.isSelected) && !materia.passed
        "
        [disabled]="(isMastery && thesisSelected) || (isThesis && masterySelected) || disabledToSelect"
        mat-flat-button
        class="add-btn"
      >
        <span class="material-icons-round"> add </span>
      </button>
      <i *ngIf="materia.passed" class="fa-light fa-check check-icon"></i>
      <button
        id="{{ materia.subject_id }}"
        *ngIf="
          (materia.estado_alumno === 'No Inscripto' ||
            materia.estado_alumno === 'Cancelado') &&
          !materia.isSelected &&
          materia.inscriptos < materia.maxima &&
          electiveSelected !== true && 
          !materia.passed
        "
        (click)="checkAdd()"
        mat-flat-button
        [disabled]="(isMastery && thesisSelected) || (isThesis && masterySelected) || disabledToSelect"
        color="primary"
        class="add-btn"
      >
        <span class="material-icons-round"> add </span>
      </button>
    </div>

    <!-- ESTADOS INSCRIPTO / PRE INSCRIPTO / CERRADO -->
    <div
      class="icon absolute"
      *ngIf="
        materia.estado_alumno === 'Inscripto' ||
        materia.estado_alumno === 'Pre Inscripto' ||
        materia.estado_alumno === 'cerrada'
      "
    >
      <mat-icon
        class="material-icons-round"
        [ngStyle]="{
          color:
            materia.estado_alumno === 'Inscripto'
              ? 'rgba(53, 208, 165, 1)'
              : materia.estado_alumno === 'Pre Inscripto'
              ? '#ffc107'
              : '#f32f5a'
        }"
        >{{
          materia.estado_alumno === "Inscripto"
            ? "check"
            : materia.estado_alumno === "Pre Inscripto"
            ? "schedule"
            : "block"
        }}</mat-icon
      >
    </div>
  </div>
  <!-- <div
    class="period-footer text-muted"
    *ngIf="
      (materia.estado_alumno === 'No Inscripto' ||
        materia.estado_alumno === 'Cancelado') &&
      materia.inscriptos < materia.maxima
    "
  >
    <div class="chip" *ngIf="materia?.periodo">
      Bimestre: {{ materia.periodo }}
    </div> -->
    <!-- {{ materia.inscriptos }} / {{ materia.maxima }} inscriptos
  </div> --> 
  <div
    class="item-footer text-muted"
    *ngIf="
      materia.estado_alumno === 'No Inscripto' &&
      materia.inscriptos >= materia.maxima
    "
  >
    CUPOS COMPLETOS
  </div>
  <div
    class="period-footer bgl-success text-success fs"
    *ngIf="materia.estado_alumno === 'Inscripto'"
  >
    ¡YA ESTÁS INSCRIPTO!
    <div
      matRipple
      class="btn-info green"
      *ngIf="checkOpcionesReemplazo(materia.periodo_id)"
      (click)="
        manageInscription() &&
          materia.tipo !== 'to_elected' &&
          materia.tipo !== 'to_elected_english'
      "
    >
      <div class="text">RETIRAR</div>
    </div>
  </div>
  <div
    class="period-footer bgl-warning text-warning fs"
    *ngIf="materia.estado_alumno === 'Pre Inscripto'"
  >
    ¡ESTÁS PREINSCRIPTO!
    <div>

    </div>
    <div matRipple 
    class="btn-info yellow" 
    *ngIf="materia.tipo !== 'to_elected' && 
    !(course.carrera.modalidad === 'p' && course.asignaturas.length <= 4) && 
    (manageOnlineCancel())" 
    (click)="manageInscription()">
      <div class="text">CANCELAR</div>
    </div>
  </div>
  <div
    class="item-footer bgl-danger text-danger fs"
    *ngIf="materia.estado_alumno === 'cerrada'"
  >
    ASIGNATURA NO HABILITADA
  </div>
</div>
