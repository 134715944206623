<div id="adenu-international">
  <div class="container-bg">
    <img
      src="../../../../assets/images/logos/adenupa-logo-white.png"
      alt="Logo ADENUPA"
      class="logo"
    />
    <app-formulario-docentes
      [makeContact]="openHelpDialog"
    ></app-formulario-docentes>
  </div>
  <div class="action-button shake" (click)="openHelpDialog()">
    ¿Tienes dudas?
  </div>
</div>
