import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  open = false;

  constructor() {}

  triggerSocials(): void {
    this.open = !this.open;
    const button = document.getElementById('mobile-show');
    if (this.open) {
      document.getElementById('hidden-container').classList.add('show');
      button.classList.add('no-bg');
      button.innerHTML = "<span class='material-icons white-icon'>close</span>";
    } else {
      document.getElementById('hidden-container').classList.remove('show');
      button.classList.remove('no-bg');
      button.innerHTML = '';
    }
  }
}
