<div class="dialog-header">
  <div mat-dialog-title>
    <h1>Orden #{{ data.order.id }}</h1>
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
</div>
<div class="dialog-container">
  <div mat-dialog-content>
    <div class="product-list">
      <div class="details-grid">
        <div class="detail">
          <h4>Fecha emisión</h4>
          <p>
            {{ moment(data.order.fecha_emision).locale("es").format("LL") }}
          </p>
        </div>
        <div class="detail">
          <h4>Estado pago</h4>
          <div
            class="chip-estado"
            [ngClass]="{
              pending: data.order.estado === 'pending',
              paid: data.order.estado === 'paid',
              cancel: data.order.estado === 'cancel'
            }"
          >
            {{
              data.order.estado === "pending"
                ? "Pendiente"
                : data.order.estado === "paid"
                ? "Pagado"
                : "Cancelado"
            }}
          </div>
        </div>
      </div>
      <h2>Detalle de orden</h2>
      <ng-container
        style="width: 100%"
        *ngFor="let producto of data.order.detalle"
      >
        <app-cart-item-card
          [product]="producto"
          [actions]="false"
        ></app-cart-item-card>
      </ng-container>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="footer">
      <div class="list-item">
        <div class="title-1">TOTAL</div>
        <div class="price">
          <div class="unit">US$</div>
          <div class="number">{{ data.order.total | number: "1.2-2" }}</div>
        </div>
      </div>

      <button
        mat-flat-button
        style="width: 100%"
        color="primary"
        id="btn-confirm-link"
        [disabled]="
          !data.order.link_pago ||
          data.order.link_pago === '' ||
          data.order.estado !== 'pending'
        "
        (click)="openLink()"
      >
        Pagar
      </button>
      <button
        mat-button
        style="width: 100%"
        color="accent"
        [disabled]="
          !data.order.link_pago ||
          data.order.link_pago === '' ||
          data.order.estado !== 'pending'
        "
        (click)="onCancelOrder()"
      >
        Cancelar orden
      </button>
    </div>
  </div>
</div>
