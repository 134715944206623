import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { PostCredits } from '../interfaces/ICredit';

@Injectable({providedIn: 'root'})
export class FinancialCreditsService {

    url = environment.baseUrl;
    
    constructor(
        private http: HttpClient,
        private authService: AuthService
        ) {}

    getCreditPrice(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.get(this.url + '/educat/credits_cost', { headers });
    }
    
    postCredits(oportunidad: PostCredits): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

        return this.http.post(this.url + '/educat/v1/credits', oportunidad, {
            headers,
        });
    }
    
    getCreditTypes(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    
        return this.http.get(this.url + '/educat/credit_types', {
            headers,
        });
    }
    
    getOportunidades(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    
        return this.http.get(this.url + '/educat/v1/oportunidades', {
            headers,
        });
    }
    
    getOportunidadesByID(id): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    
        return this.http.get(this.url + '/educat/oportunidades/' + id, {
            headers,
        });
    }
    
    updateOportunidad(idOportunidad, oportunidad: PostCredits): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.put(
            this.url + '/educat/v1/oportunidades/' + idOportunidad,
            oportunidad,
            {
            headers,
            }
        );
    }
    getCreditosAcademicos(carreraId: number): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        const body = {
            id_student_career: carreraId,
        };
    
        return this.http.post(this.url + '/educat/generate_excel', body, {
            headers,
        });
    }
}


