<!-- <div class="sticky-header"><app-header [title]="title"></app-header></div> -->

<div class="container">
  <mat-tab-group class="main-shadow main-radius">
    <mat-tab label="Nueva solicitud">
      <div class="body-wrapper">
        <app-nuevo-ticket></app-nuevo-ticket>
      </div>
    </mat-tab>
    <mat-tab label="Consultar estado de solicitud">
      <div class="body-wrapper">
        <app-estado-ticket></app-estado-ticket>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
