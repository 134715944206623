<div id="registerContainer">
  <div id="formContainer">
    <div class="app-logo">
      <img src="../../../assets/images/logos/ADENU-Color.png" />
    </div>
    <div class="wrap-login" *ngIf="!loading && !error">
      <div class="user-card main-shadow main-border main-radius">
        <div class="avatar">
          <img
            src="../../../assets/images/icons/student-icon.png"
            alt="Student Avatar"
          />
        </div>
        <div class="body">
          <h1>{{ partner.name }}</h1>
          <h3>{{ partner.personal_email }}</h3>
        </div>
        <p>¡Genera la contraseña de tu cuenta!</p>
      </div>
      <form
        novalidate
        [formGroup]="passFormGroup"
        id="loginForm"
        action="/home"
        action="https://fa.aden.org/oauth2/authorize"
        method="post"
      >
        <mat-form-field class="pb-2 w-100">
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            name="password"
            required
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            placeholder="Ingrese su contraseña."
            autocomplete="off"
          />
          <mat-icon
            class="material-icons-round"
            matSuffix
            (click)="hide = !hide"
          >
            {{ hide ? "visibility_off" : "visibility" }}</mat-icon
          >
          <mat-error
            *ngIf="passFormGroup.get('password').hasError('minlength')"
          >
            La contraseña debe tener como mínimo <strong>8</strong> caracteres
          </mat-error>
          <mat-error *ngIf="passFormGroup.get('password').hasError('required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="pb-2 w-100">
          <mat-label>Confirmar Contraseña</mat-label>
          <input
            matInput
            name="passwordConfirm"
            required
            [type]="hide ? 'password' : 'text'"
            formControlName="passwordConfirm"
            placeholder="Vuelva a ingresar su contraseña"
            autocomplete="off"
            [errorStateMatcher]="matcher"
          />
          <mat-icon
            class="material-icons-round"
            matSuffix
            (click)="hide = !hide"
          >
            {{ hide ? "visibility_off" : "visibility" }}</mat-icon
          >
          <mat-error
            *ngIf="passFormGroup.get('passwordConfirm').hasError('required')"
          >
            Este campo es <strong>requerido</strong>
          </mat-error>
          <mat-error *ngIf="passFormGroup.hasError('notSame')">
            ¡Las contraseñas no coinciden!
          </mat-error>
        </mat-form-field>
        <button
          mat-flat-button
          id="btn-login"
          color="primary"
          class="w-100 mt-2"
          [disabled]="passFormGroup.invalid"
          (click)="generatePassword()"
        >
          Guardar contraseña
        </button>
      </form>
    </div>
    <div class="wrap-login" *ngIf="loading">
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div class="social-icons">
      <a
        class="mx-1"
        target="_blank"
        href="https://www.facebook.com/ADENUniversityPanama/"
      >
        <img src="../../../../assets/images/icons/facebook.png" alt="" />
      </a>
      <a class="mx-1" target="_blank" href="https://twitter.com/aden_pa">
        <img src="../../../../assets/images/icons/twitter.png" alt="" />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.instagram.com/adenupa/?hl=es-la"
      >
        <img src="../../../../assets/images/icons/instagram.png" alt="" />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.linkedin.com/company/universidadaltadirecci%C3%B3n"
      >
        <img src="../../../../assets/images/icons/linkedin.png" alt="" />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.youtube.com/channel/UCpTcEFxdU19ZHrkELRNOBmg/videos"
      >
        <img src="../../../../assets/images/icons/youtube.png" alt="" />
      </a>
    </div>
    <div class="mt-1">
      <a class="web-adenupa">www.adenuniversity.edu.pa</a>
    </div>
  </div>
  <div id="image"></div>
</div>
