<div class="container-bg">
  <div class="container">
    <div class="heading">
      <div class="title white">Premios</div>
    </div>

    <div class="grid-premios">
      <div class="card-body">
        <div class="puesto">1º PUESTO</div>
        <div class="beca">BECA</div>
        <div class="porcentaje">100%</div>
        <div class="descripcion">
          en la asignatura de Aprende y Emprende para todos los miembros del
          equipo
        </div>
      </div>
      <div class="card-body">
        <div class="puesto">2º PUESTO</div>
        <div class="beca">BECA</div>
        <div class="porcentaje">75%</div>
        <div class="descripcion">
          en la asignatura de Aprende y Emprende para todos los miembros del
          equipo
        </div>
      </div>
      <div class="card-body">
        <div class="puesto">3º PUESTO</div>
        <div class="beca">BECA</div>
        <div class="porcentaje">50%</div>
        <div class="descripcion">
          en la asignatura de Aprende y Emprende para todos los miembros del
          equipo 
        </div>
      </div>
    </div>
  </div>
</div>
