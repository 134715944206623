<div class="dialog-header">
  <div mat-dialog-title>
    <h1>Resumen de compra #{{ data.oportunidad.id }}</h1>
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
</div>
<div class="dialog-container">
  <div>
    <div class="step-container">
      <div class="body b">
        <h1>Información de la orden</h1>
        <div class="card-detalles">
          <div class="icon">
            <mat-icon class="material-icons-round">event</mat-icon>
          </div>
          <div class="texto">
            <div class="card-title">FECHA CREACIÓN</div>
            <div class="card-description">
              {{
                moment(data.oportunidad.pagos[0].fecha_creacion).format(
                  "L hh:ss"
                )
              }}
            </div>
          </div>
        </div>
        <div class="card-detalles">
          <div class="icon">
            <mat-icon class="material-icons-round">info</mat-icon>
          </div>
          <div class="texto">
            <div class="card-title">ESTADO</div>
            <div class="card-description">
              {{
                data.oportunidad.stage === "Inscripto"
                  ? "Pendiente"
                  : data.oportunidad.stage
              }}
            </div>
          </div>
        </div>
        <div class="card-detalles">
          <div class="icon">
            <mat-icon class="material-icons-round">{{
              data.oportunidad.pagos[0].forma_pago
                .toLowerCase()
                .includes("online")
                ? "credit_card"
                : "account_balance"
            }}</mat-icon>
          </div>
          <div class="texto">
            <div class="card-title">FORMA DE PAGO</div>
            <div class="card-description">
              {{ data.oportunidad.pagos[0].forma_pago }}
            </div>
          </div>
        </div>
        <div
          class="card-detalles"
          *ngIf="data.oportunidad.pagos[0].forma_pago !== 'Transferencia'"
        >
          <div class="icon">
            <mat-icon class="material-icons-round">watch_later</mat-icon>
          </div>
          <div class="texto">
            <div class="card-title">PAGO DIFERIDO</div>
            <div class="card-description">
              {{
                data.oportunidad.pagos[0].diferir_pago_a_mes_proximo &&
                validDiffer
                  ? "Si"
                  : data.oportunidad.pagos[0].diferir_pago_a_mes_proximo &&
                    !validDiffer
                  ? "Plazo vencido"
                  : "No"
              }}
            </div>
          </div>
        </div>

        <div
          class="pago-transferencia"
          *ngIf="data.oportunidad.pagos[0].forma_pago === 'Transferencia'"
        >
          <h1>Datos bancarios</h1>
          <p>
            Para esta compra elegiste pago por transferencia, si aún no la
            realizaste, deberás transferir el valor correspondiente a UNA (1)
            cuota en la siguiente cuenta bancaria.
          </p>
          <div class="datos-transferencia">
            <div class="bank-data">
              <div class="item">
                <label>Banco</label>
                <p>{{ getBankData().banco }}</p>
              </div>
              <div class="item">
                <label>Tipo y Nº de cuenta</label>
                <p>
                  {{ getBankData().cuenta }}
                </p>
              </div>
              <div class="item">
                <label>Nombre</label>
                <p>
                  {{ getBankData().nombre }}
                </p>
              </div>
            </div>
            <div class="aviso">
              <p class="title">¡IMPORTANTE!</p>
              <p>
                Al realizar la transferencia, recuerda colocar como asunto o
                descripción de la misma tu ID de estudiante
              </p>
              <div class="chip">ID: {{ data.user.legajo }}</div>
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="
            !data.oportunidad.pagos[0].link_pago &&
            data.oportunidad.pagos[0].forma_pago !== 'Transferencia'
          "
        >
          <div class="text-muted">
            ¡No encontramos un link de pago para tu compra!<br />
            Por favor, edítala para generar un nuevo link de pago.
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            data.oportunidad.pagos[0].diferir_pago_a_mes_proximo &&
            !validDiffer &&
            data.oportunidad.pagos[0].link_pago
          "
        >
          <div class="text-muted">
            El plazo asignado para tu pago diferido se venció, es necesario que
            edites tu compra para continuar
          </div>
        </ng-container>
        <div class="text-muted">
          Si decides editar tu compra, ten en cuenta que el precio de las
          asignaturas puede haber sufrido modificaciones.
        </div>
      </div>
      <div class="cart" id="cart">
        <div class="top">
          <div class="title">Detalles de compra</div>
          <div class="product-list2">
            <ng-container style="width: 100%" *ngFor="let producto of cart">
              <app-simple-product-card
                [product]="producto"
                [actions]="false"
                [updateProduct]="updateProduct"
                [removeProduct]="removeProduct"
              ></app-simple-product-card>
            </ng-container>
          </div>
        </div>
        <div class="footer" tourAnchor="finalizarCompra">
          <div class="subtotals" *ngIf="fixedCosts.length > 0">
            <div
              class="list-item"
              *ngIf="data.oportunidad.modalidad === 'Presencial'"
            >
              <div class="title-2">Costo Matrícula</div>
              <div class="price-2">
                <div class="unit">US$</div>
                <div class="number">
                  {{ getFixedCostByID(9) | number : "1.2-2" }}
                </div>
              </div>
            </div>
            <div
              class="list-item"
              *ngIf="data.oportunidad.modalidad === 'Presencial'"
            >
              <div class="title-2">Costo Seguro</div>
              <div class="price-2">
                <div class="unit">US$</div>
                <div class="number">
                  {{ getFixedCostByID(8) | number : "1.2-2" }}
                </div>
              </div>
            </div>
            <div class="list-item">
              <div class="title-2">Subtotal asignaturas</div>
              <div class="price-2">
                <div class="unit">US$</div>
                <div class="number">
                  {{ subTotalAsignaturas | number : "1.2-2" }}
                </div>
              </div>
            </div>
          </div>
          <div class="list-item">
            <div class="title-1">TOTAL</div>
            <div class="price">
              <div class="unit">US$</div>
              <div class="number">
                {{ data.oportunidad.pagos[0].importe | number : "1.2-2" }}
              </div>
            </div>
          </div>
          <div class="pay-plan">
            <p class="tag">PLAN DE PAGO</p>
            <div class="list-item">
              <div class="title-2">Cant. cuotas</div>
              <div class="price-2">
                <div class="number">
                  {{ data.oportunidad.pagos[0].cuotas }}
                </div>
              </div>
            </div>
            <div class="list-item">
              <div class="title-2">Valor cuota</div>
              <div class="price-2">
                <div class="unit">US$</div>
                <div class="number">
                  {{
                    data.oportunidad.pagos[0].cuotas === 1
                      ? (data.oportunidad.pagos[0].importe | number : "1.2-2")
                      : (data.oportunidad.pagos[0].importe /
                          data.oportunidad.pagos[0].cuotas | number : "1.2-2")
                  }}
                </div>
              </div>
            </div>
            <div
              class="list-item"
              *ngIf="
                data.oportunidad.pagos[0].dia_pago_diferido > 0 &&
                data.oportunidad.pagos[0].diferir_pago_a_mes_proximo &&
                data.oportunidad.pagos[0].cuotas <= 1 &&
                validDiffer
              "
            >
              <div class="title-2">Día de pago</div>
              <div class="price-2">
                <div class="number">
                  {{
                    data.oportunidad.pagos[0].diferir_pago_a_mes_proximo
                      ? data.oportunidad.pagos[0].dia_pago_diferido
                      : moment(currentDate).format("L")
                  }}
                </div>
              </div>
            </div>
            <div class="list-item" *ngIf="data.oportunidad.pagos[0].cuotas > 1">
              <div class="title-2">Día de pago</div>
              <div class="price-2">
                <div class="number">
                  {{
                    data.oportunidad.pagos[0].dia_pago_diferido > 0
                      ? data.oportunidad.pagos[0].dia_pago_diferido
                      : moment(currentDate).format("D")
                  }}
                  de cada mes
                </div>
              </div>
            </div>
          </div>
          <button
            mat-flat-button
            style="width: 100%"
            color="primary"
            id="btn-confirm-link"
            [disabled]="!data.oportunidad.pagos[0].link_pago"
            *ngIf="data.oportunidad.pagos[0].link_pago && validDiffer && data.oportunidad.pagos[0].forma_pago !== 'Transferencia'"
            (click)="openLink(data.oportunidad.pagos[0].link_pago)"
          >
            Pagar
          </button>
          <button
            mat-flat-button
            style="width: 100%"
            color="primary"
            id="btn-confirm-link"
            *ngIf="data.oportunidad.pagos[0].forma_pago === 'Transferencia'"
            (click)="sendEmail()"
          >
            Adjuntar comprobante de pago
          </button>
          <button
            mat-button
            style="width: 100%"
            color="primary"
            [mat-dialog-close]="cart"
            [disabled]="data.costoAsignatura <= 0"
          >
            Editar compra
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="soonContainer" *ngIf="sending">
  <div class="soonMessage spinner">
    <mat-spinner></mat-spinner>
  </div>
</div> -->
