<div class="card-container" [ngClass]="{ selected: selected }">
  <div class="icon" *ngIf="selected">
    <mat-icon class="material-icons-rounded">check_circle</mat-icon>
  </div>
  <img
    [src]="cmsUrl + candidate.attributes.avatar.data.attributes.url"
    alt=""
    *ngIf="candidate.attributes.avatar.data"
  />
  <img
    src="../../../../../assets/images/logos/avatar.png"
    alt=""
    *ngIf="!candidate.attributes.avatar.data"
  />
  <h2>{{ candidate.attributes.full_name }}</h2>
  <h3>{{ candidate.attributes.career }}</h3>
  <p>"{{ candidate.attributes.reasons_to_apply }}"</p>
</div>
