import { environment } from './../../../../../environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-winner-card',
  templateUrl: './winner-card.component.html',
  styleUrls: ['./winner-card.component.scss'],
})
export class WinnerCardComponent {
  @Input() candidate;
  @Input() showShadow = true;
  @Input() showVotes = true;
  cmsUrl = environment.cmsUrl;

  constructor() {}
}
