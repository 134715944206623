import { Component } from '@angular/core';

@Component({
  selector: 'app-tramites',
  templateUrl: './tramites.component.html',
  styleUrls: ['./tramites.component.scss'],
})
export class TramitesComponent  {
  titleDropdown = 'Opciones de trámites';
  title = 'Trámites';
  procedure = 0;

  constructor() {}
  selectProcedure(id): void {
    this.procedure = id;
    switch (id) {
      case 0:
        this.titleDropdown = 'Cambio de carrera';
        break;
      /* case 1:
        this.titleDropdown = 'Retiro de carrera';
        break; */
      case 2:
        this.titleDropdown = 'Doble titulación';
        break;
      case 3:
        this.titleDropdown = 'Solicitud de Certificaciones Académicas';
        break;
      case 4:
        this.titleDropdown = 'Solicitud de créditos';
        break;
      case 5:
        this.titleDropdown = 'Cambio de modalidad de cursado';
        break;
      default:
        break;
    }
  }
}
