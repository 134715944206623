<div class="main-container main-shadow">
  <p class="chip">¡NUEVO!</p>
  <h1 class="title">Descarga de Créditos Académicos (NO OFICIALES)</h1>

  <div class="flex-container">
    <div *ngFor="let carrera of (user$ | async)?.carreras">
      <!-- <button
        mat-flat-button
        color="primary"
        (click)="getExcelData(carrera.carrera_estudiante)"
      >
        {{ carrera.carrera.replace("Licenciatura", "Lic.") }}
        <span class="material-icons ml-1"> download </span>
      </button> -->
      <div
        class="download-card"
        (click)="getExcelData(carrera.carrera_estudiante)"
      >
        <div class="body">
          <p>{{ carrera.carrera.replace("Licenciatura", "Lic.") }}</p>
        </div>
        <mat-icon class="material-icons-round">download</mat-icon>
      </div>
    </div>
  </div>

  <div id="loading" *ngIf="isLoading">
    <div class="container">
      <mat-spinner></mat-spinner>
      <div class="mt-4">Estamos generando tu reporte...</div>
    </div>
  </div>
</div>
