import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<QuestionsComponent>
  ) { }

  ngOnInit(): void {
    console.log('ngoninit');
  }

  close(){
    console.log('close');
    this.dialogRef.close();
  }

}
