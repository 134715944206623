import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
})
export class DropDownMenuComponent {
  @Input() menuName: any;
  @Input() menuIcon: any;
  @Input() showLabel: boolean = false;
  @Input() isOpen: any;
  @Input() hoverControl: any;
  @Input() matchMobile: any;
  @Input() openSidenav: boolean;
  constructor() {}
}
