<div class="container-bg">
  <div class="heading">
    <div class="title primary">Proceso de inscripción</div>
    <div class="button-container">
      <a
        mat-flat-button
        color="accent"
        target="_blank"
        rel="noopener noreferrer"
        href="../../../../assets/documents/Reglamento-ADEN-University-Salientes2.pdf"
        >Reglamento del programa
        <span class="material-icons"> download </span></a
      >
    </div>
  </div>

  <div class="container" *ngIf="isLoading">
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div class="container" *ngIf="!isLoading && !abledUser">
    <div class="center">
      <div class="text">
        Actualmente tenemos problemas con nuestro servidor
      </div>
      <div class="text">
        Puedes continuar con la inscripcion enviando un mail a este correo
        <a href="mailto:ori@adenuniversity.edu.pa">ori@adenuniversity.edu.pa</a>
        o utilizar el formulario de contacto rápido
      </div>
      <a
        mat-flat-button
        color="accent"
        class="mt-3"
        href="/adenu-internacional#contacto"
        >Contactar</a
      >
    </div>
  </div>

  <div class="container" *ngIf="!isLoading && abledUser">
    <mat-stepper
      #stepper
      linear="true"
      [orientation]="(stepperOrientation | async)!"
    >
      <mat-step
        [stepControl]="personalDataFormGroup"
        errorMessage="Campos requeridos"
      >
        <form [formGroup]="personalDataFormGroup">
          <ng-template matStepLabel>Datos personales</ng-template>
          <div class="title">Datos personales</div>
          <div class="columns three">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Nombre (como figura en tu pasaporte)</mat-label>
              <input
                matInput
                placeholder="Nombre"
                formControlName="name"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup.get('name').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Apellido (como figura en tu pasaporte)</mat-label>
              <input
                matInput
                placeholder="Apellido"
                formControlName="last_name"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('last_name')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Fecha de nacimiento</mat-label>
              <input
                matInput
                type="date"
                placeholder="Selecciona tu fecha de nacimiento"
                formControlName="date_of_birth"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('date_of_birth')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>

          <div class="columns three">
            <mat-form-field
              *ngIf="countries !== undefined; else disabledNationality"
              class="full-width"
              appearance="outline"
            >
              <mat-label>Nacionalidad</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="nationality"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let country of countries.paises"
                  [value]="country.name"
                >
                  {{ country.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('nationality')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>

            <ng-template #disabledNationality>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Nacionalidad</mat-label>
                <input matInput placeholder="Cargando" disabled />
                <div matSuffix class="sk-fading-circle">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>
                <mat-hint>Cargando países</mat-hint>
              </mat-form-field>
            </ng-template>

            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Número de Pasaporte (vigente)</mat-label>
              <input
                matInput
                placeholder="458799512"
                formControlName="passport"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('passport')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Fecha de vencimiento del Pasaporte</mat-label>
              <input
                matInput
                type="date"
                placeholder="Selecciona tu fecha de nacimiento"
                formControlName="passport_expiration"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('passport_expiration')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="title">Datos de contacto</div>

          <div class="columns two" formGroupName="contact">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Correo electrónico</mat-label>
              <input
                matInput
                placeholder="example@example.com"
                formControlName="email"
                type="email"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('contact')
                    .get('email')
                    .hasError('email')
                "
                >Ingresa una dirección de correo válida</mat-error
              >
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('contact')
                    .get('email')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Celular</mat-label>
              <lib-ngx-mat-tel-input
                [defaultCountry]="'PA'"
                formControlName="phone"
                required
              >
              </lib-ngx-mat-tel-input>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('contact')
                    .get('phone')
                    .hasError('required')
                "
              >
                Este campo es <strong>requerido</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('contact')
                    .get('phone')
                    .hasError('format')
                "
              >
                El formato del número es <strong>invalido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="columns three" formGroupName="address">
            <mat-form-field
              *ngIf="countries !== undefined; else disabledCounty"
              class="full-width"
              appearance="outline"
            >
              <mat-label>País</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="country"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let country of countries.paises"
                  [value]="country.name"
                >
                  {{ country.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('address')
                    .get('country')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>

            <ng-template #disabledCountry>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>País</mat-label>
                <input matInput placeholder="Cargando" disabled />
                <div matSuffix class="sk-fading-circle">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>
                <mat-hint>Cargando países</mat-hint>
              </mat-form-field>
            </ng-template>

            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Localidad</mat-label>
              <input
                matInput
                placeholder="Tu localidad"
                formControlName="location"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('address')
                    .get('location')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Domicilio actual</mat-label>
              <input
                matInput
                placeholder="San Justo 2546, Barrio X"
                formControlName="current_address"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('address')
                    .get('current_address')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>

          <div class="title">Contacto de emergencia</div>

          <div class="columns two" formGroupName="emergency_contact">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Nombre completo</mat-label>
              <input
                matInput
                placeholder="Jane Doe"
                formControlName="full_name"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('emergency_contact')
                    .get('full_name')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Correo electrónico</mat-label>
              <input
                matInput
                placeholder="example@example.com"
                formControlName="email"
                type="email"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('emergency_contact')
                    .get('email')
                    .hasError('email')
                "
                >Ingresa una dirección de correo válida</mat-error
              >
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('emergency_contact')
                    .get('email')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="columns two" formGroupName="emergency_contact">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Celular</mat-label>
              <lib-ngx-mat-tel-input
                [defaultCountry]="'PA'"
                formControlName="phone"
                required
              >
              </lib-ngx-mat-tel-input>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('emergency_contact')
                    .get('phone')
                    .hasError('required')
                "
              >
                Este campo es <strong>requerido</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('emergency_contact')
                    .get('phone')
                    .hasError('format')
                "
              >
                El formato del número es <strong>invalido</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Parentesco</mat-label>
              <input
                matInput
                placeholder="Padre, Madre, Tutor, etc."
                formControlName="relationship"
                required
              />
              <mat-error
                *ngIf="
                  this.personalDataFormGroup
                    .get('emergency_contact')
                    .get('relationship')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>

          <div class="button-container">
            <div></div>
            <button mat-flat-button color="primary" matStepperNext>
              Siguiente
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="academicDataFormGroup"
        errorMessage="Campos requeridos"
      >
        <form [formGroup]="academicDataFormGroup">
          <ng-template matStepLabel>Datos académicos</ng-template>
          <div class="title">Tu carrera en ADENU</div>

          <div class="columns two">
            <mat-form-field
              *ngIf="
                this.student !== undefined &&
                this.student.carreras !== undefined
              "
              class="full-width"
              appearance="outline"
            >
              <mat-label>Selecciona tu carrera en ADENU</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="career"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let career of student.carreras"
                  [value]="career.carrera"
                >
                  {{ career.carrera }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.academicDataFormGroup.get('career').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Año</mat-label>
              <input
                matInput
                placeholder="1"
                formControlName="year"
                type="number"
                min="1"
                max="5"
                required
              />
              <mat-error
                *ngIf="
                  this.academicDataFormGroup.get('year').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
              <mat-hint
                >Ingresa el año que cursas actualmente (Ej.: 1)</mat-hint
              >
            </mat-form-field>
          </div>

          <div class="title">Universidad Destino (Primera Elección)</div>

          <div formGroupName="first_university">
            <mat-form-field
              *ngIf="this.universities !== undefined"
              class="full-width"
              appearance="outline"
            >
              <mat-label>Selecciona una universidad destino</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="name"
                (selectionChange)="setFirstUniversity()"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let university of universities"
                  [value]="university.name"
                >
                  <div class="uni-option">
                    <img
                      [src]="
                        '../../../../assets/images/content/paises/' +
                        university.img +
                        '.png'
                      "
                      [alt]="university.name"
                    />
                    <div class="text-body">
                      <div class="uni-name">{{ university.name }}</div>
                      <div>|</div>
                      <div class="country">{{ university.country }}</div>
                    </div>
                  </div></mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.academicDataFormGroup
                    .get('first_university')
                    .get('name')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <!-- <mat-form-field
              *ngIf="this.firstSelectedUniversity != undefined"
              class="full-width"
              appearance="outline"
            >
              <mat-label>Selecciona una carrera</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="selected_career"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let career of this.firstSelectedUniversity.careers"
                  [value]="career"
                >
                  {{ career }}</mat-option
                >
              </mat-select>

              <mat-error
                *ngIf="
                  this.academicDataFormGroup
                    .get('first_university')
                    .get('selected_career')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field> -->
          </div>

          <div class="title">Universidad Destino (Segunda Elección)</div>

          <div formGroupName="second_university">
            <mat-form-field
              *ngIf="this.universities !== undefined"
              class="full-width"
              appearance="outline"
            >
              <mat-label>Selecciona una universidad destino</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="name"
                (selectionChange)="setSecondUniversity()"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let university of universities"
                  [value]="university.name"
                >
                  <div class="uni-option">
                    <img
                      [src]="
                        '../../../../assets/images/content/paises/' +
                        university.img +
                        '.png'
                      "
                      [alt]="university.name"
                    />
                    <div class="text-body">
                      <div class="uni-name">{{ university.name }}</div>
                      <div>|</div>
                      <div class="country">{{ university.country }}</div>
                    </div>
                  </div></mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.academicDataFormGroup
                    .get('second_university')
                    .get('name')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <!-- <mat-form-field
              *ngIf="this.secondSelectedUniversity != undefined"
              class="full-width"
              appearance="outline"
            >
              <mat-label>Selecciona una carrera</mat-label>
              <mat-select
                required
                matNativeControl
                formControlName="selected_career"
                [compareWith]="compareOptions"
              >
                <mat-option
                  *ngFor="let career of this.secondSelectedUniversity.careers"
                  [value]="career"
                >
                  {{ career }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.academicDataFormGroup
                    .get('second_university')
                    .get('selected_career')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field> -->
          </div>

          <div class="button-container">
            <button mat-stroked-button color="primary" matStepperPrevious>
              Anterior
            </button>
            <button mat-flat-button color="primary" matStepperNext>
              Siguiente
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="languagesDataFormGroup"
        errorMessage="Campos requeridos"
      >
        <form [formGroup]="languagesDataFormGroup">
          <ng-template matStepLabel>Idiomas</ng-template>
          <div class="language-row-check">
            <mat-checkbox formControlName="languaje"
              >¿Tienes conocimientos en lenguas extranjeras?
            </mat-checkbox>
          </div>
          <button
            [disabled]="!this.languagesDataFormGroup.get('languaje').value"
            mat-flat-button
            color="accent"
            (click)="addLanguage()"
          >
            Añadir idioma <span class="material-icons"> add </span>
          </button>
          <ng-container formArrayName="list">
            <ng-container
              *ngFor="let languageForm of list.controls; let i = index"
            >
              <div class="language-row" [formGroup]="languageForm">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Idioma</mat-label>
                  <input
                    matInput
                    required
                    formControlName="name"
                    placeholder="Inglés"
                  />
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Nivel</mat-label>
                  <mat-select
                    formControlName="level"
                    placeholder="Tu nivel"
                    required
                  >
                    <mat-option value="Básico">Básico</mat-option>
                    <mat-option value="Intermedio">Intermedio</mat-option>
                    <mat-option value="Avanzado">Avanzado</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="checks">
                  <mat-checkbox formControlName="national_certificate"
                    >Poseo Certificado Nacional
                  </mat-checkbox>
                  <mat-checkbox formControlName="international_certificate"
                    >Poseo Certificado Internacional
                  </mat-checkbox>
                </div>

                <button mat-fab color="primary" (click)="deleteLanguage(i)">
                  <mat-icon class="material-icons-round">delete</mat-icon>
                </button>
              </div>
            </ng-container>
          </ng-container>
          <div class="button-container">
            <button mat-stroked-button color="primary" matStepperPrevious>
              Anterior
            </button>
            <button mat-flat-button color="primary" matStepperNext>
              Siguiente
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="filesDataFormGroup"
        errorMessage="Faltan documentos"
      >
        <form [formGroup]="filesDataFormGroup">
          <ng-template matStepLabel>Documentación requerida</ng-template>
          <div class="description">
            <div class="title primary">Carga de documentación</div>
            <div class="detail">
              <p>Deberás presentar la siguiente documentación:</p>
              <ul>
                <li>Curriculum Vitae</li>
                <li>Carta de motivación dirigida a RRII - ADENU</li>
                <li>Pasaporte escaneado vigente</li>
                <li>
                  Certificado de Idioma - OPCIONAL (Si presentas más de un
                  certificado, debes enviarlos en un archivo comprimido .RAR o
                  .ZIP)
                </li>
              </ul>
              <p>
                Una vez que hayas enviado tu postulación, no podrás modificar la
                documentación por este medio. Por favor, chequea la validez de
                los documentos presentados.
              </p>
            </div>
          </div>
          <app-upload-internacional
            (filesEvent)="setFiles($event)"
          ></app-upload-internacional>
          <div class="button-container">
            <button mat-stroked-button color="primary" matStepperPrevious>
              Anterior
            </button>
            <button
              [disabled]="this.files.length < 3"
              mat-flat-button
              color="primary"
              matStepperNext
              (click)="createFormData()"
            >
              Siguiente
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Enviar</ng-template>

        <div class="button-container">
          <div class="title primary">¡Chequea tu ficha antes de enviar!</div>
        </div>
        <div *ngIf="this.student !== undefined && this.formData !== undefined">
          <app-ficha-inscripcion
            [user]="this.student"
            [formData]="this.formData"
            [filesArray]="this.files"
          ></app-ficha-inscripcion>
        </div>

        <div class="button-container">
          <button mat-stroked-button color="primary" matStepperPrevious>
            Anterior
          </button>
          <button
            mat-flat-button
            color="primary"
            id="completeInscription"
            (click)="completeInscription()"
          >
            <span id="send-text">Completar inscripción</span>
            <div id="loader" class="lds-ring no-display">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>

<div *ngIf="this.isWaiting" class="absolute" id="absolute-message">
  <div class="soonContainer">
    <div class="soonMessage spinner">
      <mat-spinner></mat-spinner>
      <h2 class="mt-3">Enviando..</h2>
      <p>Por favor no cierres esta pestaña</p>
    </div>
  </div>
</div>
