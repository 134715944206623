<div class="main-container main-shadow main-radius mt-5 mb-5">
  <div class="buscador">
    <div class="form-field">
      <mat-icon class="material-icons-round">search</mat-icon>
      <input
        matInput
        type="search"
        placeholder="Buscar"
        [(ngModel)]="searchValue"
      />

      <div class="suffix">
        <button
          *ngIf="searchValue"
          mat-icon-button
          color="primary"
          aria-label="Clear"
          (click)="cleanSearch()"
        >
          <mat-icon class="material-icons-round">close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="notfound-container" *ngIf="loading">
    <mat-spinner class="spinner"></mat-spinner>
    <p>Buscando órdenes...</p>
  </div>

  <div class="notfound-container" *ngIf="!orders || orders.length === 0">
    <img src="../../../assets/images/content/cart-pay.svg" alt="" />
    <h2>Aún no tienes órdenes</h2>
    <p>
      Cuando hagas una compra de
      Trámites Académicos verás
      aquí tus órdenes y podrás seguir el estado de pago
    </p>
    <div class="button-container">
      <button mat-flat-button color="primary" (click)="onClose()">
        Ir a Trámites Académicos
      </button>
    </div>
  </div>

  <div class="orders-grid" *ngIf="!loading && orders.length > 0">
    <ng-container *ngFor="let order of orders | filter : searchValue">
      <app-card-orden
        [order]="order"
        [cancelOrder]="cancelOrder"
        [openDetailsDialog]="openDetailDialog"
      ></app-card-orden>
    </ng-container>
  </div>
</div>
