import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getHour',
})
export class GetHourPipe implements PipeTransform {
  transform(hour): number {
    return parseInt(hour.substring(0, 2), 10);
  }
}
