import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ISpec } from '../interfaces/ISpec';
import { environment } from 'src/environments/environment';
import { SpecViewService } from './spec-view.service';

@Injectable({providedIn: 'root'})
export class HorariosService {

    specView: ISpec;
    url = environment.baseUrl;


    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private specViewService: SpecViewService
        ) { this.getSpecView();}
        
    getSpecView(){
        this.specViewService.currentSharedSpecMode.subscribe(data => this.specView = data)
    }
    getHorarios(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        if(this.specView.isSpecMode){
          return this.http.get(this.url + '/educat/sessions?student_sis_id=' + this.specView.sis_id, { headers });
        }
        return this.http.get(this.url + '/educat/sessions', { headers });
      }
}

