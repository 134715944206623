import { PostCredits } from '../../../../shared/interfaces/ICredit';
import { DataService } from 'src/app/shared/services/data.service';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

export interface DialogData {
  user: any;
  nro_pago: number;
  ifharu: string;
  total: number;
  oportunidad: any;
}

export interface DialogData {
  cantCreditos: number;
  cantCuotas: number;
  formaPago: string;
  precioTotal: number;
  precioCuota: number;
  costoAsignatura: number;
  idOportunidad: number;
}

@Component({
  selector: 'app-transfer-dialog',
  templateUrl: './transfer-dialog.component.html',
  styleUrls: ['./transfer-dialog.component.scss'],
})
export class TransferDialogComponent {
  public disabledTooltip = true;
  constructor(
    public dialogRef: MatDialogRef<TransferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  sendEmail() {
    const mail1 = 'cobros@adenuniversity.edu.pa';
    const mail2 = 'gestorrecaudo@adenuniversity.edu.pa';
    window.open(
      `mailto:${mail1}?subject=Comprobante+de+pago+%7C+${this.data.user.nombre}+%7C+ID+Estudiante%3A+${this.data.user.legajo}&cc=${mail2}&body=Completa los siguientes datos antes de enviar:%0A%0ANOMBRE COMPLETO: ${this.data.user.nombre}%0AID DE ESTUDIANTE: ${this.data.user.legajo}%0AMONTO TRANSFERIDO:%0AFECHA DE TRANSFERENCIA:%0ANÚMERO DE OPERACIÓN:
      %0A%0A¡Recurda adjuntar el comprobante como imagen o PDF!`,
      '_blank'
    );
  }

  /* goToPay(): void {
    window.open(this.data.link, '_blank');
    this.onClose();
  } */
}
