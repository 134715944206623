import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { ISpec } from '../interfaces/ISpec';
import { SpecViewService } from './spec-view.service';

@Injectable({providedIn: 'root'})
export class AlumnoService {

  url = environment.baseUrl;
  specView: ISpec;

    constructor(
      private http: HttpClient,
      private authService: AuthService,
      private specViewService: SpecViewService
      ) { this.getSpecView() }

      getSpecView(){
        this.specViewService.currentSharedSpecMode.subscribe(data => this.specView = data)
      }

    getAllAlumnos(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.get(this.url + '/educat/get_alumnos', { headers });
      }
    
      getPublicAlumno(user_id): Observable<any> {
        return this.http.get(
          this.url + '/educat/credencial_alumno?sis_id=' + user_id,
          {}
        );
      }

      matricularAlumno(data): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.post(
          this.url + '/educat/matricular_alumno_asignaturas',
          data,
          { headers }
        );
      }
    
      getAlumno(goSpec = false): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        if(goSpec || !this.specView.isSpecMode){
          return this.http.get(this.url + '/educat/alumno', { headers }); 
        }
        return this.http.get(this.url + '/educat/alumno?student_sis_id=' + this.specView.sis_id , { headers });
      }
    
      postAlumno(data): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    
        const dataFormated = {
          cargo: '',
          celular: data.phone,
          direccion: '',
          email: data.mailAcademic,
          email_personal: data.mailPersonal,
          escuela: data.school,
          escuela_graduacion: data.yearEgress,
          pais_escuela: data.country,
          facebook: data.facebook,
          fecha_nacimiento: data.birthDate,
          grupo_sanguineo: data.blood,
          linkedin: data.linkedin,
          instagram: data.instagram,
          movil: data.phone,
          numero_documento: data.numberDocument,
          pais: data.nationality,
          skype: data.skype,
          telefono_principal: '',
          tipo_documento: data.typeDocument,
          vencimiento_documento: data.dateDocument,
          nombre_emergencia: data.nameRelationship,
          movil_emergencia: data.phoneRelationship,
          parentesco_emergencia: data.relationship,
          genero: data.gender,
          cargo_laboral: data.workload,
          sector_laboral: data.sectorLaboral,
        };
        return this.http
          .post(this.url + '/educat/alumno', dataFormated, { headers })
          .pipe(map((d) => d));
      }
      postFoto(image: File): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        // const foto = {
        //   img: fotoNueva
        // }
        // console.log(foto)
        // return this.http.post( this.url + '/educat/alumno/foto', foto, {headers}).pipe(map(data => data));
        const formData = new FormData();
    
        formData.append('img', image);
        return this.http.post(this.url + '/educat/alumno/foto', formData, {
          headers,
        });
      }
      
      getSisIdByEmail(email: string){
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token});
        return this.http.get(this.url + '/educat/student/sis_id?email=' + email, { headers });
      }

      getAdvisor(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.get(this.url + '/educat/contacto', { headers });
      }

      getCursos(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.get(this.url + '/cursos', { headers });
      }

      getNotas(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        if(this.specView.isSpecMode){
          return this.http.get(this.url  + '/educat/notas?student_sis_id=' + this.specView.sis_id ,{ headers });
        }
        return this.http.get(this.url + '/educat/notas', { headers });
      }

      getPaises(): Observable<any> {
        return this.http.get(this.url + '/educat/paises');
      }

      getLaboralSector(): Observable<any> {
        return this.http.get(this.url + '/educat/sector_laboral');
      }


    
}