import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-data-card',
  templateUrl: './generic-data-card.component.html',
  styleUrls: ['./generic-data-card.component.scss'],
})
export class GenericDataCardComponent {
  @Input() label: string;
  @Input() description: string;
  @Input() value: any;
  @Input() icon: string;
  @Input() type: string = 'plain';
  @Input() color: string;

  constructor() {}
}
