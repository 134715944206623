import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-peticiones',
  templateUrl: './peticiones.component.html',
  styleUrls: ['./peticiones.component.scss'],
})
export class PeticionesComponent {
  title = 'Formulario de Solicitudes';

  constructor() {}
}
