import { OrderSucessDialogComponent } from './../order-sucess-dialog/order-sucess-dialog.component';
import { PaymentLinkDialogComponent } from './../../../matriculaciones/components/payment-link-dialog/payment-link-dialog.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CartProduct } from './../../../../shared/interfaces/ICart';
import { Component, Inject, OnInit } from '@angular/core';
import moment from 'moment';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { AlertDialogComponent } from 'src/app/shared/components/alerts/alert-dialog/alert-dialog.component';
import { DerechosService } from 'src/app/shared/services/derechos.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

export interface DialogData {
  units: number;
  total: number;
  cart: CartProduct[];
  limitCuotas: number;
  student: any;
}

@Component({
  selector: 'app-products-cart',
  templateUrl: './products-cart.component.html',
  styleUrls: ['./products-cart.component.scss'],
})
export class ProductsCartComponent implements OnInit {
  public currentDate = moment();
  public editableCart;
  public editableUnits;
  isSpecMode = false;
  public editableTotal;

  /* FORMA PAGO */

  public formaPago = 'online';
  public cantCuotas = 1;
  public limitCuotas =
    this.data.limitCuotas != null && this.data.limitCuotas > 0
      ? this.data.limitCuotas
      : 1;

  public nroComprobante = '';
  public sending = false;

  constructor(
    public dialogRef: MatDialogRef<ProductsCartComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private specViewService: SpecViewService,
    private derechosService: DerechosService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.specViewService.currentSharedSpecMode.subscribe(data => this.isSpecMode = data.isSpecMode)
    this.formaPago = 'online';
    this.editableCart = this.data.cart;
    this.editableUnits = this.data.units;
    this.editableTotal = this.data.total;
    this.updateProduct = this.updateProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
  }

  /* ACTUALIZAR CARRITO */

  public updateProduct(product, quantity: number): void {
    this.editableCart = this.editableCart.map((item) => {
      if (
        item.certificacion_id === product.certificacion_id &&
        item.carrera_id === product.carrera_id
      ) {
        this.editableTotal =
          this.editableTotal - item.subtotal + product.valor_usd * quantity;
        this.editableUnits = this.editableUnits - item.quantity + quantity;
        item.quantity = quantity;
        item.subtotal = product.valor_usd * quantity;
      }
      return item;
    });
  }

  public removeProduct(product): void {
    const index = this.editableCart.findIndex(
      (item) =>
        item.certificacion_id === product.certificacion_id &&
        item.carrera_id === product.carrera_id &&
        item.carrera === product.carrera
    );
    this.editableCart.splice(index, 1);
    this.editableTotal = this.editableTotal - product.subtotal;
    this.editableUnits = this.editableUnits - product.quantity;
  }

  /* CUOTAS */

  removeOneCuota(): void {
    if (this.cantCuotas > 1) {
      this.cantCuotas--;
    }
  }

  addOneCuota(): void {
    if (this.cantCuotas < this.limitCuotas) {
      this.cantCuotas++;
    }
  }

  /* FINALIZAR COMPRA */
  finishPurchase() {
    if(!this.isSpecMode){
      this.sending = true;
      this.derechosService
        .postDerechosPecuniarios({
          products: this.editableCart,
          student_id: this.data.student.legajo,
        })
        .subscribe({
          next: (res) => {
            window.open(res.link_pago, '_blank');
            this.dialogRef.close({
              finished: true,
              link: res.link_pago,
              orden: res.orden_compra_id,
            });
            this.sending = false;
          },
          error: (err) => {
            this.alertService.openAlert(
              'Algo salió mal',
              'Por favor, intenta de nuevo más tarde',
              'Ok'
            );
            this.sending = false;
          },
        });
    }else{
      const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '400px',
      data: {
        title: 'No puedes realizar cambios',
        description: 'Recuerda que únicamente puedes ver la plataforma, sin la posibilidad de hacer modificaciones.',
        action: 'Entendido',
        img: '../../../../../assets/images/content/stop.png' 
      },
      panelClass: 'plain-alert',
    });
    }
  }

  /* EMAIL */

  sendEmail() {
    const mail1 = 'cobros@adenuniversity.edu.pa';
    const mail2 = 'gestorrecaudo@adenuniversity.edu.pa';
    window.open(
      `mailto:${mail1}?subjet=Comprobante%20de%20pago&cc=${mail2}`,
      '_blank'
    );
  }

  /* DIALOG ACTIONS */
  onClose(): void {
    this.dialogRef.close({
      cart: this.editableCart,
      total: this.editableTotal,
      units: this.editableUnits,
      finished: false,
    });
  }
}
