<div class="sticky-header"><app-header [title]="title"></app-header></div>
<div class="container mt-5 mb-5">
  <app-cardprofile></app-cardprofile>

  <div class="main-container flex">
    <mat-tab-group
      mat-align-tabs="start"
      [selectedIndex]="selectedTab.value"
      (selectedIndexChange)="
        selectedTab.setValue($event);
        goTo(selectedTab.value === 0 ? 'data' : 'docs')
      "
    >
      <mat-tab label="Mis datos">
        <div class="content">
          <app-form></app-form>
        </div>
      </mat-tab>
      <!-- *ngIf="user && allowedIds.includes(user.legajo)" -->
      <mat-tab label="Mi documentación">
        <div class="content">
          <div class="container" *ngIf="isLoading && !noDocs">
            <div class="center">
              <mat-spinner></mat-spinner>
            </div>
          </div>
          <div class="container" *ngIf="refreshLoading">
            <div class="center">
              <mat-spinner></mat-spinner>
            </div>
          </div>
          <div class="container" *ngIf="!isLoading && noDocs">
            <div class="center">
              <img
                src="../../../assets/images/content/sin-resultados.svg"
                alt=""
              />
              <div class="text">
                No pudimos encontrar el listado de documentos
              </div>
              <button mat-flat-button color="primary" (click)="getDocs()">
                Reintentar
              </button>
            </div>
          </div>
          <!--  -->

          <div class="main-grid" *ngIf="!isLoading && !noDocs">
            <div class="body">
              <h2>Legajo Digital</h2>
              <p>
                En esta sección deberás entregar cada uno de los documentos que
                conforman tu legajo digital. Una vez que todos sean cargados y
                aprobados, podrás proceder al envío de tu legajo físico.
              </p>
              <p>
                Para más información chequea el apartado Documentación del
                <a
                  href="https://drive.google.com/file/d/1Wv3Czftsroc5byjKNCLRf4vPSYLYzI3Q/view"
                  target="_blank"
                  alt="manual del estudiante"
                  >Manual del Estudiante</a
                >
                o contacta a tu Academic Advisor.
              </p>
              <div class="banner-doc-info">
                <p><strong>Formatos aceptados:</strong> PDF, JPG, JPEG o PNG</p>
                <p><strong>Tamaño máximo:</strong> 10MB por archivo</p>
              </div>
              <mat-accordion class="mat-elevation-z0">
                <ng-container
                  *ngFor="
                    let item of docsGroupByArea | keyvalue;
                    let i = index;
                    trackBy: docIdentifyer
                  "
                >
                  <mat-expansion-panel
                    (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false"
                    class="mat-elevation-z0"
                    [expanded]="i === 0"
                    *ngIf="item.key !== ''"
                  >
                    <mat-expansion-panel-header>
                      <div class="header">
                        <div class="title">
                          {{ item.key === "" ? "General" : item.key }}
                        </div>
                        <div class="progress">
                          <mat-progress-spinner
                            class="example-margin"
                            color=""
                            mode="determinate"
                            diameter="48"
                            strokeWidth="4"
                            [value]="getPorcentaje(item.value)"
                          >
                          </mat-progress-spinner>
                          <div class="text">
                            {{ getPorcentaje(item.value) | number : "1.0-2" }}%
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="docs-grid" *ngIf="!isLoading && !noDocs && !refreshLoading">
                      <ng-container *ngFor="let doc of item.value">
                        <app-document-card
                          *ngIf="
                            !doc.solo_extranjeros ||
                            (doc.solo_extranjeros &&
                              user.escuela_pais.toLowerCase() !== 'panama' &&
                              user.escuela_pais.toLowerCase() !== 'panamá')
                          "
                          [isSpecMode]="isSpecMode"
                          [userDoc]="doc"
                          [refreshDocs]="refreshDocs"
                          [changeDocState]="changeDocState"
                        ></app-document-card>
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>

            <!-- <div class="sidebar">
              <h2>Legajo Físico</h2>
              <div
                class="legajo-fisico"
                [ngStyle]="{
                  'background-color': user.leg_foliados
                    ? '#35d0a51A'
                    : '#c29f431A'
                }"
              >
                <mat-icon
                  class="material-icons-round"
                  [ngStyle]="{
                    color: user.leg_foliados ? '#35d0a5' : '#c29f43'
                  }"
                  >{{
                    user.leg_foliados ? "check_circle_outline" : "schedule"
                  }}</mat-icon
                >
                <h3
                  [ngStyle]="{
                    color: user.leg_foliados ? '#35d0a5' : '#c29f43'
                  }"
                >
                  {{
                    user.leg_foliados
                      ? "Legajo entregado en Sede Panamá"
                      : "Pendiente de entrega en Sede Panamá"
                  }}
                </h3>
                <p>
                  {{
                    user.leg_foliados
                      ? "La documentación fue recibida en la sede."
                      : "La documentación aún no fue recibida en la sede."
                  }}
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
