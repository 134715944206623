<div class="card-main-contain">
  <label *ngIf="selectMode === 'markMode' || selectMode === 'selectAll'" class="container">
    <input required type="checkbox"
    [checked]="allSelected" 
    name="checkbox"
    [disabled]="notification.is_open && !allSelected"
    (change)="updateSelectedIds($event.target.checked, notification.id)"
    >
    <span class="checkmark"></span>
 </label>
  <div
    class="card-wrapper"
    [ngClass]="{ new: !notification.is_open }"
  >
    <div class="heading">
      <div class="title">
        {{ notification.title }}
      </div>
      <div (click)="deleteNotification(notification.id)" class="delete">
        Eliminar
      </div>
      <div class="date">
        {{ moment.utc(notification.date).local().locale("es").toNow(true) }}
      </div>
    </div>
    <p class="content">
      {{ notification.message }}
    </p>
  </div>
</div>
