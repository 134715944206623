import { ContactDialogComponent } from './../contact-dialog/contact-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-movilidad-entrantes',
  templateUrl: './movilidad-entrantes.component.html',
  styleUrls: ['./movilidad-entrantes.component.scss'],
})
export class MovilidadEntrantesComponent implements OnInit {
  constructor(public dialog: MatDialog, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('ADENU | Movilidad Internacional');
  }

  openHelpDialog(): void {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: 'auto',
      data: {
        title: 'ALUMNOS ENTRANTES | Movilidad Internacional',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
