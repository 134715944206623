import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AcademicState } from 'src/app/redux/reducers/academicAd.reducer';
import * as reduxAcademic from '../../../../redux/actions/academicAd.action';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cardadvisor',
  templateUrl: './cardadvisor.component.html',
  styleUrls: ['./cardadvisor.component.scss'],
})
export class CardadvisorComponent implements OnInit {
  public academic;
  public academic$;

  constructor(
    private store: Store<AcademicState>,
    private router: Router,
    private alumnoService: AlumnoService,
  ) {}

  ngOnInit(): void {
    this.academic$ = this.store.select('academic');
    this.academic$.subscribe((store) => {
      if (store == null) {
        this.getAcademic();
      } else {
        this.academic = store;
      }
    });
  }

  navigateTo(){}

  getAcademic(): void {
    this.alumnoService.getAdvisor().subscribe({
      next: (data) => {
        this.store.dispatch(
          new reduxAcademic.GetAcademic({
            ...data,
            foto: `data:image/png;base64,${data.foto}`,
          })
        );
        this.academic = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  sendWhatsapp(): void {
    const cleanNumber = this.academic.celular.replace(/-|\(|\)|\s|\*|\#/g, '');
    window.open(`https://wa.me/${cleanNumber}?lang=es`, '_blank');
  }

  sendMail(): void {
    window.open(`mailto:${this.academic.correo}`, '_blank');
  }
}
