import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-viewer-dialog',
  templateUrl: './file-viewer-dialog.component.html',
  styleUrls: ['./file-viewer-dialog.component.scss']
})
export class FileViewerDialogComponent implements OnInit {
 
  constructor(
  @Inject(MAT_DIALOG_DATA) public data: any, 
  public dialogRef: MatDialogRef<FileViewerDialogComponent>) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
