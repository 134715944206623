import { OpcionGradoComponent } from './pages/graduacion/opcion-grado/opcion-grado.component';
import { PracticaProfesionalComponent } from './pages/graduacion/practica-profesional/practica-profesional.component';
import { PracticaSocialComponent } from './pages/graduacion/practica-social/practica-social.component';
import { CursosAbiertosComponent } from './pages/cursos-abiertos/cursos-abiertos.component';
import { OrdenesCompraComponent } from './pages/ordenes-compra/ordenes-compra.component';
import { StudentCredentialComponent } from './pages/student-credential/student-credential.component';
import { DerechosPecuniariosComponent } from './pages/derechos-pecuniarios/derechos-pecuniarios.component';
import { DracmaScoringComponent } from './pages/dracma-scoring/dracma-scoring.component';
import { EleccionesAdenpolisComponent } from './pages/comite-estudiantil/elecciones/elecciones-adenpolis.component';
import { PostulacionesAdenpolisComponent } from './pages/comite-estudiantil/postulaciones/postulaciones-adenpolis.component';
import { ComiteEstudiantilComponent } from './pages/comite-estudiantil/sobre-adenpolis/comite-estudiantil.component';
import { HorariosComponent } from './pages/horarios/horarios.component';
import { ProgramaImpulsoComponent } from './pages/ceaden/programa-impulso/programa-impulso.component';
import { CeadenComponent } from './pages/ceaden/ceaden.component';
import { CheckBlockingGuard } from './shared/guards/check-blocking.guard';
import { MovilidadDocentesComponent } from './pages/adenu-internacional/movilidad-docentes/movilidad-docentes.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { ConcursoComponent } from './pages/ceaden/aprende-emprende/concurso.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { NotasComponent } from './pages/notas/notas.component';
import { PlanesEstudioComponent } from './pages/planes-estudio/planes-estudio.component';
import { AcademicAdvisorComponent } from './pages/academic-advisor/academic-advisor.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { HomeLayoutComponent } from './shared/layouts/home-layaot.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout.component';
import { MatriculacionesComponent } from './pages/matriculaciones/matriculaciones.component';
import { EstadoFinancieroComponent } from './pages/estado-financiero/estado-financiero.component';
import { AdenuInternacionalComponent } from './pages/adenu-internacional/movilidad-salientes/adenu-internacional.component';
import { MovilidadEntrantesComponent } from './pages/adenu-internacional/movilidad-entrantes/movilidad-entrantes.component';
import { RegisterComponent } from './pages/register/register.component';
import { OrdersLinksGuard } from './shared/guards/orders-links.guard';
import { PeticionesComponent } from './pages/peticiones/peticiones.component';
import { GraduacionComponent } from './pages/graduacion/graduacion.component';
import { FileViewComponent } from './pages/file-view/file-view.component';
import { SpecViewComponent } from './pages/spec-view/spec-view.component';
import { SpecGuard } from './shared/guards/spec.guard';
import { LibraryComponent } from './pages/library/library.component';
import { VideoPlayerComponent } from './pages/library/components/video-player/video-player.component';
import { CourseComponent } from './pages/course/course.component';
import { CourseViewerComponent } from './pages/course/course-viewer/course-viewer.component';
import { BuscojobsComponent } from './pages/buscojobs/buscojobs.component';
import { UploadQrComponent } from './pages/upload-qr/upload-qr.component';
import { UploadGuard } from './shared/guards/upload.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      /* {
        path: 'eventos',
        component: EventosComponent,
        canActivate: [AuthGuard],
      }, */

      {
        path: 'adenu-internacional',
        component: AdenuInternacionalComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'plan-estudio',
        component: PlanesEstudioComponent,
      },
      {
        path: 'perfil',
        component: PerfilComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'notas',
        component: NotasComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'spec-view',
        component: SpecViewComponent,
        canActivate: [AuthGuard, SpecGuard],
      },
      {
        path: 'horarios',
        component: HorariosComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'matriculaciones',
        component: MatriculacionesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'estado-financiero',
        component: EstadoFinancieroComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'file',
        component: FileViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'academic-advisor',
        component: AcademicAdvisorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'buscojobs',
        component: BuscojobsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ceaden',
        component: CeadenComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ceaden/reto-aprende-y-emprende',
        component: ConcursoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'ceaden/programa-impulso',
        component: ProgramaImpulsoComponent,
        canActivate: [AuthGuard],
      },
      /* {
        path: 'anuncios',
        component: AnunciosComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'anuncios/:slug',
        component: DetalleAnuncioComponent,
        canActivate: [AuthGuard],
      }, */
      {
        path: 'comite-estudiantil',
        component: ComiteEstudiantilComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'comite-estudiantil/postulaciones',
        component: PostulacionesAdenpolisComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'comite-estudiantil/elecciones',
        component: EleccionesAdenpolisComponent,
        canActivate: [AuthGuard],
      },
      /* {
        path: 'puntuacion-dracma',
        component: DracmaScoringComponent,
        canActivate: [AuthGuard],
      }, */
      {
        path: 'tramites-academicos',
        component: DerechosPecuniariosComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'mis-ordenes/:tag',
        component: OrdenesCompraComponent,
        canActivate: [AuthGuard, OrdersLinksGuard],
      },
      {
        path: 'mis-ordenes',
        redirectTo: 'mis-ordenes/tramites-academicos',
        pathMatch: 'full',
      },
      {
        path: 'cursos-nivelatorios',
        component: CursosAbiertosComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'library',
        component: LibraryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'library/:id',
        component: VideoPlayerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'course',
        component: CourseComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'course/:id',
        component: CourseViewerComponent,
        canActivate: [AuthGuard],
      },
      /* {
        path: 'peticiones-reclamos',
        component: PeticionesComponent,
        canActivate: [AuthGuard],
      }, */
      {
        path: 'graduacion',
        component: GraduacionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'graduacion/pasantia-responsabilidad-social',
        component: PracticaSocialComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'graduacion/pasantia-extension-profesional',
        component: PracticaProfesionalComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'graduacion/opcion-titulacion',
        component: OpcionGradoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: '',
    component: LoginLayoutComponent,

    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [CheckBlockingGuard],
      },
      {
        path: 'register/:partnerId',
        component: RegisterComponent,
        canActivate: [CheckBlockingGuard],
      },
      {
        path: 'movilidad-alumnos-entrantes',
        component: MovilidadEntrantesComponent,
      },
      {
        path: 'upload/:token/:action',
        component: UploadQrComponent,
        canActivate: [UploadGuard]
      },
      {
        path: 'movilidad-docentes',
        component: MovilidadDocentesComponent,
      },
      {
        path: 'estudiante/:studentId',
        component: StudentCredentialComponent,
      },
    ],
  },      

  // { path: '', pathMatch: 'full', redirectTo: 'login'},
  {
    path: '404',
    component: NotFoundComponent,
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 240],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
