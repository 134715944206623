import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DocumentsService } from '../services/documents.service';

@Injectable({
  providedIn: 'root'
})
export class UploadGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // const refreshToken = route.params?.refresh;
    const userEncryptedToken = route.params?.token;
    if(userEncryptedToken){
      console.log('ENTRA EN IF')
      this.authService.validateQrToken(userEncryptedToken).subscribe({
        next:(res:any) => {
          // const userToken = this.authService.decryptToken(userEncryptedToken, 'your-secret-key');
          // this.authService.setTemporalToken(userToken);
          localStorage.setItem('userEncryptedToken', userEncryptedToken);
          return true;
        },
        error: (err) => {
          // this.authService.clearToken();
          // this.authService.clearSis_id();
          console.log(err);
          this.router.navigateByUrl('login');
          // return false;
        }
      })
    }else{
      return false;
    }
  }

  
}
