import { Observable } from 'rxjs';
import { UserState } from './../../redux/reducers/user.reducer';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataService } from 'src/app/shared/services/data.service';
import { EstadoFinanciero, Factura } from 'src/app/shared/interfaces/IEstadoFinanciero';
import { FinancialStateService } from 'src/app/shared/services/financial-state.service';

@Component({
  selector: 'app-estado-financiero',
  templateUrl: './estado-financiero.component.html',
  styleUrls: ['./estado-financiero.component.scss'],
})
export class EstadoFinancieroComponent implements OnInit {
  title = 'Mi estado financiero';

  public user$: Observable<any>;

  public estadoFinanciero: EstadoFinanciero;
  public facturas: Factura[];

  isLoading = true;
  isError = false;

  constructor(private store: Store<UserState>, private financialStateService: FinancialStateService) {
    this.user$ = store.select('user');
  }

  ngOnInit(): void {
    this.getEstadoFinanciero();
  }

  getEstadoFinanciero(): void {
    this.isLoading = true;
    this.financialStateService.getEstadoFinanciero().subscribe({
      next: (res) => {
        this.estadoFinanciero = res;
        this.facturas = res.factura;
        this.isLoading = false;
        this.isError = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.isError = true;
      },
    });
  }
}
