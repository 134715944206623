import { Action } from '@ngrx/store';
// import * as reduxUser from '../actions/user.action'
import * as reduxUser from '../actions/user.action';

export interface UserState {
  user: object;
}

export const initialUserState = {
  user: null,
};

export function UserReducer(
  userState: UserState = initialUserState,
  action: reduxUser.UserActions
): any {
  //console.log(userState)
  switch (action.type) {
    case reduxUser.GET_USER:
      return { ...action.payload };

    case reduxUser.POST_PHOTO_USER:
      return {
        ...userState,
        foto64: action.payload,
      };
    default:
      break;
  }
  return userState;
}
