<div class="card-aden mb-5">
  <h2 class="title">
    <strong> Formato de Solicitud de Retiro de Carrera ADENU </strong>
  </h2>

  <h4 class="text-muted">
    Diligenciar correctamente todos los campos para que se realice
  </h4>

  <div class="ml-3 mt-5">
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Nombre completo</label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].nombre"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="formulario['controls'].nombre.hasError('required')"
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Identificación </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].identificacion"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="
                  formulario['controls'].identificacion.hasError('required')
                "
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Correo electronico </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].correoElectronico"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="
                  formulario['controls'].correoElectronico.hasError('email') &&
                  !formulario['controls'].correoElectronico.hasError('required')
                "
              >
                Por favor ingrese un correo electronico válido
              </mat-error>
              <mat-error
                *ngIf="
                  formulario['controls'].correoElectronico.hasError('required')
                "
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Periodo de tu ingreso en ADENU </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <mat-label>Elige</mat-label>
              <mat-select [formControl]="formulario['controls'].periodoIngreso">
                <mat-option *ngFor="let p of periodo" [value]="p.value">
                  {{ p.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Año de ingreso en la primera Lic </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <mat-label>Elige</mat-label>
              <mat-select [formControl]="formulario['controls'].anioIngreso">
                <mat-option *ngFor="let i of ingreso" [value]="i">
                  {{ i }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Seleccione la licenciatura que cursaba </label>
        </div>
        <div class="row">
          <form class="example-form" ngf="licenciaturaState">
            <mat-form-field class="full-width">
              <mat-label>Elige carrera</mat-label>

              <mat-select
                [disabled]="licenciatura.length === 0"
                [formControl]="formulario['controls'].licenciatura"
              >
                <mat-option
                  *ngFor="let i of licenciatura"
                  [value]="i.modalidad + ' | ' + i.carrera"
                >
                  {{ i.modalidad }} | {{ i.carrera }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Motivo de la solicitud de retiro </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].motivoCambio"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="formulario['controls'].motivoCambio.hasError('required')"
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-12">
        <div class="row">
          <mat-slide-toggle color="primary" (click)="changeCheck()"
            >¿Requiere devolución?</mat-slide-toggle
          >
        </div>
        <div class="row mt-4">
          <form class="full-width" *ngIf="isChecked">
            <label>Forma de pago</label>
            <mat-form-field class="full-width">
              <mat-label>Elige una forma de pago</mat-label>
              <mat-select [formControl]="formulario['controls'].formaPago">
                <mat-option *ngFor="let f of formaPago" [value]="f.value">
                  {{ f.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div
              *ngIf="formulario.controls['formaPago'].value === 'Transferencia'"
            >
              <!-- <div class="header-transfer">
                En caso de haber seleccionado "Transferencia" por favor
                completar la siguiente información
              </div> -->
              <div class="box-transfer">
                <div class="row">
                  <div class="col">
                    <label>Entidad financiera</label>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Tu respuesta"
                        [formControl]="formulario['controls'].entidadFinanciera"
                        [errorStateMatcher]="matcher"
                      />
                      <mat-error
                        *ngIf="
                          formulario['controls'].entidadFinanciera.hasError(
                            'required'
                          )
                        "
                      >
                        Esta pregunta es <strong>obligatoria</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <label>Numero cuenta</label>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Tu respuesta"
                        [formControl]="formulario['controls'].numeroCuenta"
                        [errorStateMatcher]="matcher"
                      />
                      <mat-error
                        *ngIf="
                          formulario['controls'].numeroCuenta.hasError(
                            'required'
                          )
                        "
                      >
                        Esta pregunta es <strong>obligatoria</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label>Tipo de cuenta</label>
                    <mat-form-field class="full-width">
                      <mat-label>Elige un tipo de cuenta</mat-label>
                      <mat-select
                        [formControl]="formulario['controls'].tipoCuenta"
                      >
                        <mat-option
                          *ngFor="let t of tipoCuenta"
                          [value]="t.value"
                        >
                          {{ t.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <label>Titular de cuenta</label>
                    <mat-form-field class="full-width">
                      <input
                        matInput
                        placeholder="Tu respuesta"
                        [formControl]="formulario['controls'].titularCuenta"
                        [errorStateMatcher]="matcher"
                      />
                      <mat-error
                        *ngIf="
                          formulario['controls'].titularCuenta.hasError(
                            'required'
                          )
                        "
                      >
                        Esta pregunta es <strong>obligatoria</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div>
      <button
        [disabled]="!formulario.valid"
        mat-button
        class="bg-primary-aden text-white"
        (click)="enviarFormulario()"
      >
        Enviar
      </button>
    </div>
  </div>
</div>
