<div class="dialog-container">
  <div class="button-container">
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round"> close </mat-icon>
    </button>
  </div>

  <img src="../../../../../assets/images/content/Success.svg" alt="success" />
  <h3>El ticket: </h3>
  <h1>{{ data.category }}</h1>
  <h3>
    Ha sido creado exitosamente, en breve será atendido por un agente
  </h3>
</div>
