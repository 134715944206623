// videos.action.ts
import { Action } from '@ngrx/store';
import { IResource } from 'src/app/shared/interfaces/ICourse';

export enum CoursesActionTypes {
  LOAD_COURSES = '[Courses] Load Courses'
}

export class LoadCourses implements Action {
  readonly type = CoursesActionTypes.LOAD_COURSES;
  constructor(public payload: IResource[]) {}
}

export type CourseAction = LoadCourses ;
