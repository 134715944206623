import { MatSnackBar } from '@angular/material/snack-bar';
import { ContestMember } from '../../../../shared/interfaces/IContest';
import { ContestService } from '../../../../shared/services/contest.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-contacto-internacional',
  templateUrl: './contacto-internacional.component.html',
  styleUrls: ['./contacto-internacional.component.scss'],
})
export class ContactoInternacionalComponent {
  @Input() user: ContestMember;
  messageCtrl = new UntypedFormControl('');

  matcher = new MyErrorStateMatcher();

  constructor(
    private contestService: ContestService,
    private _snackBar: MatSnackBar
  ) {}

  sendMessage(): void {
    if (this.messageCtrl.value !== null || this.messageCtrl.value !== '') {
      const data = {
        student: this.user.name,
        subject: 'Consulta Programa de Movilidad Internacional 2022',
        message: this.messageCtrl.value,
        student_email: this.user.mail,
        email_api: 'ori@adenuniversity.edu.pa',
        title: 'PROGRAMA DE MOVILIDAD INTERNACIONAL - ADENU',
      };

      const button = document.getElementById('send-button');
      button.innerHTML = 'Enviando...';
      this.contestService.send_email(data).subscribe({
        next: (res) => {
          button.innerHTML = 'Enviar';
          this._snackBar.open('¡Mensaje enviado con éxito!', 'OK', {
            duration: 2000,
            panelClass: ['indigo-snackbar'],
          });
        },
        error: (err) => {
          this._snackBar.open(
            'Ocurrió un error, por favor intenta de nuevo más tarde',
            'OK',
            {
              duration: 2000,
              panelClass: ['red-snackbar'],
            }
          );
        },
      });
    }
  }
}
