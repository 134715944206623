import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DocumentsService } from 'src/app/shared/services/documents.service';

@Component({
  selector: 'app-upload-qr',
  templateUrl: './upload-qr.component.html',
  styleUrls: ['./upload-qr.component.scss']
})
export class UploadQrComponent implements OnInit {

  constructor(
    private documentsService: DocumentsService,
    private alertService: AlertService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }
  isUploading;
  actionSelected;
  docStatus;
  token;
  docId;
  sis_id;
  isLoading;
  isMulti;

  ngOnInit(): void {
    console.log('ENTRA EN UPLOAD QR')
    this.route.params.subscribe(params => {
      this.actionSelected = params.action;
      this.authService.validateQrToken(params.token).subscribe({  
        next:(res:any) => {
          this.docStatus = res.document_status
          this.docId = res.document_id
          this.isMulti = res.multiple_load
        },
        error: (err) => {
          console.log(err);
          this.alertService.openAlert(
            '¡Token inválido!',
            'Tu token ha expirado o no es válido, por favor inicia sesión nuevamente',
            'Aceptar',
            null,
            'error',
            'error'
          );
        }
      })
      this.authService.getSisIdByToken(params.token).subscribe({
        next: (res: any) => {
          console.log(res)
          this.sis_id = res.sis_id;
        },
        error: (err) => {
          console.log(err)
        }
      })
    })
  }

  onFileSelected(event, isMulti){
    this.isUploading = true;
    if(isMulti){
      this.checkMultiFiles(event.target.files)
    }else{
      const file: File = event.target.files[0];
      this.checkFile(file);
    }
    console.log(event.target.files)
  }

  checkMultiFiles(filesObj){
    if(filesObj.length > 10){
      this.alertService.openAlert(
        '¡Son demasiados archivos!',
        'Sólo puedes subir hasta 10 archivos a la vez',
        'Aceptar',
        null, 
        'error',
        'error'
      );
    }else{
      const filesArr = Object.values(filesObj);
      const acceptedFiles = ['jpg', 'jpeg', 'png', 'pdf'];
      const validateFileSize = filesArr.find((file: any) => file.size > 10000000);
      if(!validateFileSize){
        this.postMultiDocs(filesObj) 
      }else{
        this.isUploading = false;
        this.alertService.openAlert(
          '¡Tu archivo es muy pesado!',
          'El archivo no debe exceder los 10MB, reduce su peso e intenta nuevamente',
          'Aceptar',
          null,
          'error',
          'error'
        );
      } 
    }
  }

  checkFile(file, isMulti = false) {
    const fileSize = file.size / 2024 / 2024
    const fileType = file.name.split('.').pop();
    const acceptedFiles = ['jpg', 'jpeg', 'png', 'pdf'];

    if (file && fileSize <= 10) {
      if (acceptedFiles.includes(fileType)) {
        if(isMulti){
          this.updateDoc(file, isMulti)
        }
        this.docStatus.toLowerCase() === 'pendiente'
          ? this.postDoc(file)
          : this.updateDoc(file);
      } else {
        this.isUploading = false;
        this.alertService.openAlert(
          'No aceptamos este tipo de archivo',
          'Sólo puedes subir tus documentos como PDF o en formato de imagen: JPG, JPEG o PNG',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    } else {
      this.isUploading = false;
      this.alertService.openAlert(
        '¡Tu archivo es muy pesado!',
        'El archivo no debe exceder los 20MB, reduce su peso e intenta nuevamente',
        'Aceptar',
        null,
        'error',
        'error'
      );
    }
  }

  postMultiDocs(files){
    this.isLoading = true;
    this.documentsService.postMultiDocs(files, this.docId, true).subscribe(
      (res) => {
    this.isLoading = false;

        this.isUploading = false;
        this.alertService.openAlert(
          '¡Documentos cargados con éxito!',
          'Ya recibimos tus documentos, una vez que sean revisados verás el estado de la entrega aquí.',
          'Aceptar',
          null,
          'success',
          'check_circle'
        );
        // this.getDocs();
      },
      (error) => {
        this.isUploading = false;
    this.isLoading = false;

        this.alertService.openAlert(
          'No pudimos cargar el documento',
          'Intenta de nuevo más tarde',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    );
  }

  postDoc(file) {
    this.isLoading = true;

    this.documentsService.postQrDocument(file, this.docId, this.sis_id).subscribe(
      (res) => {
        this.isUploading = false;
    this.isLoading = false;

        this.alertService.openAlert(
          '¡Documento cargado con éxito!',
          'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí.',
          'Aceptar',
          null,
          'success',
          'check_circle'
        );
        // this.getDocs();
      },
      (error) => {
        this.isUploading = false;
    this.isLoading = false;

        this.alertService.openAlert(
          'No pudimos cargar el documento',
          'Intenta de nuevo más tarde',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    );
  }

  updateDoc(file, isMulti = false) {
    if(isMulti){
      this.documentsService.updateMultiDocs(file,this.docId, true).subscribe(
        (res) => {
          this.isUploading = false;
          this.alertService.openAlert(
            '¡Documento actualizado con éxito!',
            'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí',
            'Aceptar',
            null,
            'success',
            'check_circle'
          );
          /* this.getDocs(); */
        },
        (error) => {
          this.isUploading = false;
          this.openSnackBar(
            'No pudimos actualizar el documento, intenta de nuevo más tarde',
            'OK',
            'error'
          );
        }
      )
    }else{
      this.documentsService.updateQrDocument(file, this.docId, this.sis_id).subscribe(
        (res) => {
          this.isUploading = false;
          this.alertService.openAlert(
            '¡Documento actualizado con éxito!',
            'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí',
            'Aceptar',
            null,
            'success',
            'check_circle'
          );
          /* this.getDocs(); */
          // this.changeDocState(this.userDoc.category_id, 'En revisión');
        },
        (error) => {
          this.isUploading = false;
          this.openSnackBar(
            'No pudimos actualizar el documento, intenta de nuevo más tarde',
            'OK',
            'error'
          );
        }
      );
    }
  }

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: [`${type}-snackbar`],
    });
  }



}
