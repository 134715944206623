import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { ICourse, IResource } from '../interfaces/ICourse';

@Injectable({providedIn: 'root'})
export class CourseService {
    private readonly apiUrl = environment.repoUrl
    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    getAllCourses(): Observable<IResource[]>{
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.get<IResource[]>(`${this.apiUrl}/educat/obj_aprendizaje_all`, { headers })
      }
    getRegisterCourse(repo_id): any{
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.get<IResource[]>(`${this.apiUrl}/enrollment/${repo_id}`, { headers })
      }
    registerCourse(id){
        const staticPayload = {
          type: "oa",
          assignment: id,
          project100: "true"
      }
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.post(`${this.apiUrl}/enrollment/enroll/${localStorage.getItem('repo_id')}`,staticPayload, { headers })
    }
    getResourceProgress(id){
      const token = this.authService.leerToken();
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
      return this.http.get<IResource[]>(`${this.apiUrl}/enrollment/${localStorage.getItem('repo_id')}?=${id}`, { headers })
    }
    
}