import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-cronograma-internacional',
  templateUrl: './card-cronograma-internacional.component.html',
  styleUrls: ['./card-cronograma-internacional.component.scss'],
})
export class CardCronogramaInternacionalComponent {
  @Input() position: string;
  @Input() item;

  constructor() {}
}
