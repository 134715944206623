<mat-tab-group
  *ngIf="filteredCarreras.length > 0"
  class="nav-career pb-5"
  animationDuration="300ms"
  style="min-height: 100px"
  tourAnchor="tabAsignaturas"
>
  <div *ngFor="let course of asignaturasPorPeriodo; let i = index">
    <div *ngIf="course.carrera.habilitado">
      <mat-tab>
        <div id="body-asignaturas">
          
          <ng-template mat-tab-label>
            <div class="custom-label">
              <div class="career-label">
                {{
                  i > 0 &&
                  filteredCarreras[i - 1].carrera.modalidad ===
                    course.carrera.modalidad
                    ? "Segunda carrera"
                    : "Carrera Principal"
                }}
              </div>
              <div>{{ course.carrera.nombre }}</div>
            </div>
          </ng-template>
          
          <div class="buscador">
            <div class="form-field">
              <mat-icon class="material-icons-round search"> search </mat-icon>
              <input
                matInput
                type="text"
                placeholder="Buscar por nombre"
                [(ngModel)]="searchValue"
              />
              <div class="suffix">
                <button
                  *ngIf="searchValue"
                  mat-icon-button
                  color="primary"
                  aria-label="Clear"
                  (click)="cleanSearch()"
                >
                  <mat-icon class="material-icons-round">close</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="filteredCarreras.length > 1" class="warnign">
            <div [inlineSVG]="'../../../assets/svgs/Group.svg'"></div>
            <span>¡Recuerda! </span> Debes seleccionar al menos una asignatura por mes dentro de la carrera principal, antes de continuar con la segunda carrera.
          </div>
          <!-- MATERIAS NORMALES -->
          <div class="materias-container" *ngFor="let periodo of course.asignaturasPorPeriodo">
            <div class="materias-container">
            <!-- <div
              class="materias-container"
              [tourAnchor]="
                i > 0 &&
                filteredCarreras[i - 1].carrera.modalidad ===
                  course.carrera.modalidad
                  ? 'asignaturasRegulares2'
                  : 'asignaturasRegulares1'
              "
            > -->
            <div
            class="dificutltad-badge"
          >
            {{ modalidadSelected === "presencial" ? "Cuatrimestre: " : "Mes: "
            }}{{ periodo.name }}
          </div>
              <ng-container
                *ngFor="
                  let materia of periodo.asignaturasPorPeriodo| filter : searchValue;
                  let indice = index
                "
              >
                <div
                  *ngIf="
                    ((materia.estado_asignatura === 'Abierta' &&
                      materia.inscriptos >= materia.maxima &&
                      materia.estado_alumno !== 'No Inscripto') ||
                      (materia.estado_asignatura === 'Abierta' &&
                        materia.inscriptos < materia.maxima)) &&
                    materia.tipo !== 'elective' &&
                    materia.tipo !== 'grade_option' &&
                    materia.tipo !== 'elective_english'
                  "
                >
                  <app-card-asignatura
                    [materia]="materia"
                    [materiaPeriodo]="periodo.id"
                    [course]="course"
                    [periodos]="periodos"
                    [openDescription]="openDescription"
                    [removeMateria]="removeMateria"
                    [addMateria]="addMateria"
                    [getUser]="getUser"
                    [career_id]="course.carrera.career_id"
                    [index]="indice"
                    [modalidadSelected]="modalidadSelected"
                  ></app-card-asignatura>
                </div>
              </ng-container>
            </div>


            <div
              id="select-especialidad"
              class="main-border main-radius main-shadow"
              *ngIf="
                course.carrera.especialidad === false &&
                checkEspecialidadSelection(course.asignaturas)
              "
            >
              <h2>¡Selecciona tu especialidad!</h2>
              <p>
                ¡Llegó el momento de elegir la especialidad de tu carrera! Echa un
                vistazo a las asignaturas de cada especialidad y elige una para
                cursar en este periodo.
              </p>
  
              <div
                class="grid-especialidades"
                *ngIf="this.allEspecialidades.length > 0"
              >
                <ng-container
                  *ngFor="
                    let especialidad of getEspecialidadById(
                      course.carrera.student_career_id
                    )
                  "
                >
                  <div
                    class="card-especialidad"
                    [ngStyle]="{
                      'background-color':
                        especialidadSelected === especialidad.especialidad_id
                          ? '#EDFFF9'
                          : '#ffffff',
                      padding:
                        especialidadSelected === especialidad.especialidad_id
                          ? '1rem 1rem 1rem 1.5rem'
                          : '1rem 1.5rem'
                    }"
                    (click)="
                      setSelectedEspecialidad(especialidad.especialidad_id)
                    "
                  >
                    <p>{{ especialidad.nombre_especialidad }}</p>
                    <mat-icon
                      *ngIf="
                        especialidadSelected === especialidad.especialidad_id
                      "
                      >check_circle</mat-icon
                    >
                  </div>
                </ng-container>
              </div>
  
              <div class="asig-wrapper" *ngIf="especialidadSelected">
                <ng-container
                  *ngFor="
                    let materia of periodo.asignaturasPorPeriodo| filter : searchValue
                  "
                >
                  <div
                    class="elective-container"
                    *ngIf="
                      ((materia.estado_asignatura === 'Abierta' &&
                        materia.inscriptos >= materia.maxima &&
                        materia.estado_alumno !== 'No Inscripto') ||
                        (materia.estado_asignatura === 'Abierta' &&
                          materia.inscriptos < materia.maxima)) &&
                      materia.tipo === 'elective' &&
                      existenElectivasPorEspecialidad(
                        materia.elegir,
                        especialidadSelected
                      )
                    "
                  >
                    <div class="float-title">{{ materia.nombre }}</div>
                    <div
                      class="check-selected"
                      *ngIf="
                        materia.isSelected &&
                        pensumElectiveIds.indexOf(materia.pensum_id) > -1
                      "
                    >
                      <mat-icon class="material-icons-round">done</mat-icon>
                    </div>
                    <div class="desc">Elige una de las siguientes opciones:</div>
                    <div class="materias-container">
                      <ng-container
                        *ngFor="
                          let electiva of materia.elegir | filter : searchValue;
                          let indice = index
                        "
                      >
                        <div
                          *ngIf="
                            ((electiva.estado_asignatura === 'Abierta' &&
                              electiva.inscriptos >= electiva.maxima &&
                              electiva.estado_alumno !== 'No Inscripto') ||
                              (electiva.estado_asignatura === 'Abierta' &&
                                electiva.inscriptos < electiva.maxima)) &&
                            electiva.tipo === 'to_elected' &&
                            electiva.especialidad_id === especialidadSelected
                          "
                        >
                          <app-card-asignatura
                            [materia]="electiva"
                            [course]="course"
                            [periodos]="periodos"
                            [openDescription]="openDescription"
                            [removeMateria]="removeMateria"
                            [addMateria]="addMateria"
                            [electiveSelected]="materia.isSelected"
                            [electivePensum]="materia.pensum_id"
                            [getUser]="getUser"
                            [career_id]="course.carrera.career_id"
                            [index]="electiva.pensum_id + indice"
                            [modalidadSelected]="modalidadSelected"
                          ></app-card-asignatura>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
            <!-- ASIGNATURAS ELECTIVAS -->
            <div
              class="column-wrap"
              *ngIf="
                course.carrera.especialidad !== false ||
                course.carrera.especialidad === 'CSE' ||
                course.carrera.especialidad === 'NSE'
              "
            >
              <ng-container
                *ngFor="let materia of periodo.asignaturasPorPeriodo |filter : searchValue"
              >
                <div
                  class="elective-container"
                  *ngIf="
                    ((materia.estado_asignatura === 'Abierta' &&
                      materia.inscriptos >= materia.maxima &&
                      materia.estado_alumno !== 'No Inscripto') ||
                      (materia.estado_asignatura === 'Abierta' &&
                        materia.inscriptos < materia.maxima)) &&
                    materia.tipo === 'elective' &&
                    existenElectivasPorEspecialidad(
                      materia.elegir,
                      course.carrera.especialidad_id,
                      course.carrera.especialidad
                    )
                  "
                >
                  <div class="float-title">{{ materia.nombre }}</div>
                  <div
                    class="check-selected"
                    *ngIf="
                      materia.isSelected &&
                      pensumElectiveIds.indexOf(materia.pensum_id) > -1
                    "
                  >
                    <mat-icon class="material-icons-round">done</mat-icon>
                  </div>
                  <div class="desc">Elige una de las siguientes opciones:</div>
                  <div class="materias-container">
                    <ng-container
                      *ngFor="
                        let electiva of materia.elegir | filter : searchValue;
                        let indice = index
                      "
                    >
                      <div
                        *ngIf="
                          ((electiva.estado_asignatura === 'Abierta' &&
                            electiva.inscriptos >= electiva.maxima &&
                            electiva.estado_alumno !== 'No Inscripto') ||
                            (electiva.estado_asignatura === 'Abierta' &&
                              electiva.inscriptos < electiva.maxima)) &&
                          electiva.tipo === 'to_elected'
                        "
                      >
                        <app-card-asignatura
                          [materia]="electiva"
                          [course]="course"
                          [periodos]="periodos"
                          [openDescription]="openDescription"
                          [removeMateria]="removeMateria"
                          [addMateria]="addMateria"
                          [electiveSelected]="materia.isSelected"
                          [electivePensum]="materia.pensum_id"
                          [getUser]="getUser"
                          [career_id]="course.carrera.career_id"
                          [index]="electiva.pensum_id + indice"
                          [modalidadSelected]="modalidadSelected"
                        ></app-card-asignatura>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>


            <div class="column-wrap">
              <ng-container
                *ngFor="let materia of periodo.asignaturasPorPeriodo | filter : searchValue"
              >
                <div
                  class="elective-container"
                  *ngIf="
                    ((materia.estado_asignatura === 'Abierta' &&
                      materia.inscriptos >= materia.maxima &&
                      materia.estado_alumno !== 'No Inscripto') ||
                      (materia.estado_asignatura === 'Abierta' &&
                        materia.inscriptos < materia.maxima)) &&
                    materia.tipo === 'elective_english'
                  "
                >
                  <div class="float-title">{{ materia.nombre }}</div>
                  <div
                    class="check-selected"
                    *ngIf="
                      materia.isSelected &&
                      pensumElectiveIds.indexOf(materia.pensum_id) > -1
                    "
                  >
                    <mat-icon class="material-icons-round">done</mat-icon>
                  </div>
                  <div class="desc">Elige una de las siguientes opciones:</div>
                  <div class="materias-container">
                    <ng-container
                      *ngFor="
                        let electiva of materia.elegir | filter : searchValue;
                        let indice = index
                      "
                    >
                      <div
                        *ngIf="
                          ((electiva.estado_asignatura === 'Abierta' &&
                            electiva.inscriptos >= electiva.maxima &&
                            electiva.estado_alumno !== 'No Inscripto') ||
                            (electiva.estado_asignatura === 'Abierta' &&
                              electiva.inscriptos < electiva.maxima)) &&
                          electiva.tipo === 'to_elected_english'
                        "
                      >
  
                        <app-card-asignatura
                          [materia]="electiva"
                          [course]="course"
                          [periodos]="periodos"
                          [openDescription]="openDescription"
                          [removeMateria]="removeMateria"
                          [addMateria]="addMateria"
                          [electiveSelected]="materia.isSelected"
                          [electivePensum]="materia.pensum_id"
                          [getUser]="getUser"
                          [career_id]="course.carrera.career_id"
                          [index]="electiva.pensum_id + indice"
                          [modalidadSelected]="modalidadSelected"
                        ></app-card-asignatura>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- GRADE OPTION -->
            <div class="column-wrap">
              <ng-container
                *ngFor="let materia of periodo.asignaturasPorPeriodo | filter : searchValue"
              >
                <div
                  class="elective-container"
                  *ngIf="
                    ((materia.estado_asignatura === 'Abierta' &&
                      materia.inscriptos >= materia.maxima &&
                      materia.estado_alumno !== 'No Inscripto') ||
                      (materia.estado_asignatura === 'Abierta' &&
                        materia.inscriptos < materia.maxima)) &&
                    materia.tipo === 'grade_option'
                  "
                >
                  <div class="float-title">{{ materia.nombre }}</div>
                  <div
                    class="check-selected"
                    *ngIf="
                      materia.isSelected &&
                      pensumElectiveIds.indexOf(materia.pensum_id) > -1
                    "
                  >
                    <mat-icon class="material-icons-round">done</mat-icon>
                  </div>
                  <div class="desc">Elige una de las siguientes :</div>
                  <div class="materias-container">
                    <ng-container
                      *ngFor="
                        let electiva of materia.elegir | filter : searchValue;
                        let indice = index
                      "
                    >
                      <div
                        *ngIf="
                          ((electiva.estado_asignatura === 'Abierta' &&
                            electiva.inscriptos >= electiva.maxima &&
                            electiva.estado_alumno !== 'No Inscripto') ||
                            (electiva.estado_asignatura === 'Abierta' &&
                              electiva.inscriptos < electiva.maxima)) &&
                          electiva.tipo === 'to_elect_grade'
                        "
                      >
                        <app-card-asignatura
                          [materia]="electiva"
                          [course]="course"
                          [periodos]="periodos"
                          [openDescription]="openDescription"
                          [removeMateria]="removeMateria"
                          [addMateria]="addMateria"
                          [electiveSelected]="materia.isSelected"
                          [electivePensum]="materia.pensum_id"
                          [getUser]="getUser"
                          [career_id]="course.carrera.career_id"
                          [index]="electiva.pensum_id + indice"
                          [modalidadSelected]="modalidadSelected"
                        ></app-card-asignatura>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <div class="period-footer"></div>
            </div>
          </div>
        </div>
      </mat-tab>
    </div>
  </div>
</mat-tab-group>
