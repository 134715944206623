import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: any, field: string, order: 'asc' | 'des'): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      return order === 'asc'
        ? a.attributes[field] < b.attributes[field]
          ? -1
          : a.attributes[field] > b.attributes[field]
          ? 1
          : 0
        : a.attributes[field] < b.attributes[field]
        ? 1
        : a.attributes[field] > b.attributes[field]
        ? -1
        : 0;
    });

    return array;
  }
}
