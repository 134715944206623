<mat-card class="border-none shadow">
  <span class="badge bg-primary-aden">{{ badge }}</span>
  <mat-card-content class="mt-4">
    <h3>
      <b>{{ title }}</b>
    </h3>
  </mat-card-content>
  <mat-card-actions class="d-flex justify-content-center mt-3">
    <div *ngFor="let button of buttons">
      <a
        style="text-decoration: none"
        href="../../../../../assets/documents/{{ namePdf }}.pdf"
        target="_blank"
        mat-stroked-button
        class="btn-aden btn-primary-inline m-1"
      >
        {{ button }}
      </a>
    </div>
  </mat-card-actions>
</mat-card>
