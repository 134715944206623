
import { DataService } from '../../../../shared/services/data.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import moment from 'moment';
import { Oportunidad } from 'src/app/shared/interfaces/IEstadoFinanciero';
import { Product } from 'src/app/shared/interfaces/IProduct';
import { CartProduct } from 'src/app/shared/interfaces/ICart';

export interface DialogData {
  costoAsignatura: number;
  oportunidad: Oportunidad;
  products: Product[];
  modalidad: string;
  ifharu: boolean;
  user: any;
}

@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EditPaymentComponent implements OnInit {
  public currentDate = new Date();

  public cart: CartProduct[] = [];
  public fixedCosts = [];

  public opportunityDate;
  public differDays;
  public validDiffer = true;

  public subTotalAsignaturas = 0;

  public moment = moment;

  /* DATOS BANCARIOS */

  public bankData = {
    online: {
      banco: 'Banco Benistmo',
      cuenta: 'Cuenta Corriente Nº 0114535215',
      nombre: 'Universite de Management de Suisse',
    },
    ifharu: {
      banco: 'Banco Benistmo',
      cuenta: 'Cuenta Corriente Nº 101087584',
      nombre: 'Alta Dirección S.A.',
    },
    presencial: {
      banco: 'Bac International Bank',
      cuenta: 'Cuenta Corriente Nº 104174321',
      nombre: 'Alta Dirección S.A.',
    },
  };

  constructor(
    public dialogRef: MatDialogRef<EditPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private service: DataService
  ) {}

  ngOnInit(): void {
    this.fillCart();
    this.opportunityDate = new Date(
      this.data.oportunidad.pagos[0].fecha_creacion
    );
    this.differDays = this.data.oportunidad.pagos[0].dia_pago_diferido;
    const nextMonth = this.opportunityDate.getMonth() + 1;

    if (
      (nextMonth === this.currentDate.getMonth() &&
        this.currentDate.getDate() > 13) ||
      (this.opportunityDate.getMonth() === this.currentDate.getMonth() &&
        this.differDays <= this.currentDate.getDate() &&
        this.differDays > 13) ||
      (this.opportunityDate.getMonth() < this.currentDate.getMonth() &&
        nextMonth < this.currentDate.getMonth())
    ) {
      this.validDiffer = false;
    }
    console.log(this.fixedCosts)
  }

  onClose(): void {
    this.dialogRef.close();
  }

  getBankData() {
    return this.data.ifharu
      ? this.bankData.ifharu
      : this.data.modalidad === 'presencial'
      ? this.bankData.presencial
      : this.bankData.online;
  }

  openLink(link: string): void {
    window.open(link, '80', '100');
  }

  fillCart(): void {
    this.data.oportunidad.productos.forEach((cartItem) => {
      this.data.products.forEach((producto) => {
        if (cartItem.educat_credit_type_id === producto.id) {
          this.cart.push({
            id: producto.id,
            type: producto.type,
            name: producto.name,
            price: producto.price,
            tipo_certificado: producto.tipo_certificado,
            limit: producto.limit,
            quantity: cartItem.cantidad,
            subtotal: cartItem.price_total,
          });
          this.subTotalAsignaturas += cartItem.price_total;
        }
      });
    });
    console.log(this.data.oportunidad)
    if (this.data.oportunidad.modalidad === 'Presencial') {
      this.data.oportunidad.productos.forEach((item) => {
        if (
          item.educat_credit_type_id === 8 ||
          item.educat_credit_type_id === 9
        ) {
          this.fixedCosts.push(item);
        }
      });
    }
  }

  getFixedCostByID(id): number {
    console.log(this.fixedCosts)
    console.log(id)
    return this.fixedCosts.find((item) => item.educat_credit_type_id === id)
      .price_total;
  }

  sendEmail() {
    const mail1 = 'cobros@adenuniversity.edu.pa';
    const mail2 = 'gestorrecaudo@adenuniversity.edu.pa';
    window.open(
      `mailto:${mail1}?subject=Comprobante+de+pago+%7C+${this.data.user.nombre}+%7C+ID+Estudiante%3A+${this.data.user.legajo}&cc=${mail2}&body=Completa los siguientes datos antes de enviar:%0A%0ANOMBRE COMPLETO: ${this.data.user.nombre}%0AID DE ESTUDIANTE: ${this.data.user.legajo}%0AMONTO TRANSFERIDO:%0AFECHA DE TRANSFERENCIA:%0ANÚMERO DE OPERACIÓN:
      %0A%0A¡Recurda adjuntar el comprobante como imagen o PDF!`,
      '_blank'
    );
  }
}
