<!-- <div class="card-container" [routerLink]="'/anuncios/' + post.attributes.slug">
  <div class="line"></div>
  <h2>{{ post.attributes.Title }}</h2>
  <p>{{ post.attributes.Caption }}</p>
  <div class="date">
    {{ moment(post.attributes.publishedAt).locale("es").format("LLL") }}
  </div>
</div> -->

<div class="card-container" (click)="goTo(post.attributes.link)">
  <div class="line"></div>
  <div class="category">{{ post.attributes.category }}</div>
  <h2>{{ post.attributes.title }}</h2>
  <p>{{ post.attributes.preview }}</p>
  <!-- <div class="date">
    {{ moment(post.attributes.publishedAt).locale("es").format("LLL") }}
  </div> -->
</div>
