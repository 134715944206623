import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DocPracticesService {
  apiUrl = environment.baseUrl;
  documentTypeId = environment.documentTypeId;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getDocument(student_career_id: number){
    const token = this.authService.leerToken();
    const headers = { Authorization: 'Bearer ' + token };
    return this.http.get(`${this.apiUrl}/educat/documento/${this.documentTypeId}/carrera/${student_career_id}`, { headers });
  }
}
