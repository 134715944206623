import { VideoDialogComponent } from 'src/app/shared/components/dialogs/video-dialog/video-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from './../../../../../environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-candidato',
  templateUrl: './card-candidato.component.html',
  styleUrls: ['./card-candidato.component.scss'],
})
export class CardCandidatoComponent implements OnInit{
  @Input() candidate;
  cmsUrl = environment.cmsUrl;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    // console.log(this.candidate);
  }

  openSpeech(): void {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      width: '700px',
      data: {
        title: `Discruso de ${this.candidate.attributes.full_name}`,
        videoLink: this.candidate.attributes.link_video_campaign,
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
