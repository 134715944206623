import { Component } from '@angular/core';

@Component({
  selector: 'app-pasos-internacional',
  templateUrl: './pasos-internacional.component.html',
  styleUrls: ['./pasos-internacional.component.scss'],
})
export class PasosInternacionalComponent {
  constructor() {}
}
