<div id="comite-alumnos">
  <div class="sticky-header">
    <app-image-header
      [logo]="'/images/content/adenpolis/logo-adenpolis-color.png'"
      [imgURL]="'/images/content/adenpolis/header-adenpolis.png'"
      [titleColor]="'#b31d15'"
      [descriptionColor]="'#222222'"
      [buttonColor]="'#b31d15'"
      [title]="'Adenpólis | Comité Estudiantil ADEN University'"
      [showTitle]="false"
    ></app-image-header>
    <app-onepage-nav
      [links]="links"
      [activeLink]="activeLink"
      [urlBase]="'comite-estudiantil'"
    ></app-onepage-nav>
  </div>

  <section class="container" id="sobre-nosotros">
    <div class="body">
      <div class="content">
        <h1>Nuestro propósito</h1>
        <p>
          El propósito del comité estudiantil ADENPÓLIS es dar a el estudiantado
          la oportunidad de desarrollar su liderazgo organizando y llevando a
          cabo actividades extracurriculares y proyectos de servicio. Además de
          planificar eventos que contribuyan al espíritu universitario y
          complementar la vida académica con su formación integral.
        </p>
        <p>
          El comité estudiantil será el principal foro reconocido para la
          opinión de los estudiantes y su objetivo es representar los intereses
          y preocupaciones de los estudiantes ante la universidad, a la vez que
          proporcionar actividades y servicios que se consideren útiles para los
          estudiantes.
        </p>
      </div>
      <div class="image" *ngIf="videoUrl">
        <iframe
          width="560"
          height="315"
          class="main-radius"
          [src]="videoUrl"
          title="Conoce nuestro centro de emprendimiento"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </section>
  <div class="white-bg">
    <div class="container sobre-nosotros">
      <div class="body">
        <div class="image">
          <img
            class="full-width"
            src="../../../assets/images/content/adenpolis/objetivos-adenpolis-3.png"
            alt=""
          />
        </div>
        <div class="content">
          <h1>Visión</h1>
          <p>
            Convertir el alumnado de ADEN University mediante el comité
            estudiantil, en un
            <strong>brazo de apoyo para la toma de decisiones</strong> de la
            universidad. Al igual que
            <strong>crear un vínculo profundo</strong> entre el alumnado y su
            universidad, ayudándolo a crecer como ser humano y profesional.
          </p>
          <h1>Misión</h1>

          <p>
            <strong>Promover la integración</strong> entre los estudiantes de
            ADEN University Panamá, para
            <strong>incentivar el intercambio de experiencias</strong> y
            actividades que promuevan el avance social, político y cultural de
            los mismos y sus pares. Ayudando a crear vínculos, atender posibles
            propuestas, o sugerencias que puedan presentarse entre el alumnado.
          </p>
          <h1>Valores</h1>
          <p>
            Democracia y participación, solidaridad, responsabilidad, respeto,
            honestidad, empatía, transparencia, ética y equidad.
          </p>
        </div>
      </div>
    </div>
  </div>

  <section class="container sobre-nosotros" id="estructura">
    <div class="body">
      <div class="content">
        <h1>Comité estudiantil</h1>
        <p>
          El comité estudiantil estará conformado por hasta 22 alumnos,
          divididos en dos secciones: <strong>presencial y virtual</strong>, que
          tendrán hasta 11 alumnos cada una. Estas obraran independientemente,
          sin embargo, se unirán en ciertas ocasiones para tomar decisiones en
          conjunto.
        </p>
        <p>
          <strong>Cada miembro del comité tendrá 2 cargos:</strong> uno externo
          el cual será ser representante de su carrera en su respectiva
          modalidad, y el interno que puede ser un cargo de gestion o líder de
          proyecto. No existe jerarquía en los cargos,
          <strong
            >todos los miembros del comité tienen el mismo factor decisivo y
            todos los miembros son equivalentes</strong
          >.
        </p>
        <p>
          Cada miembro comunica al comité las posibles propuestas o sugerencias
          del alumnado de la licenciatura que representan al igual que cumplir
          con las funciones de su cargo. Los miembros deben presentar un resumen
          por escrito de cada reunión de su casa o licenciatura a la que
          asistan, con el fin de mantener un registro y facilitar el
          seguimiento. Los resúmenes deben ser entregados al secretario y
          guardados en un archivo.
        </p>
        <app-info-note-card
          [title]="'¡IMPORTANTE!'"
          [description]="
            '<p>Durante la primera entrega del comité, la alumna <strong>Amarr Chauchar</strong> va a ser designada como <strong>Representante ADEN U Online</strong>. Esto con motivo de facilitar la creación de las bases internas de este, al igual que, la comunicación entre la universidad y el comité.</p>'
          "
          [icon]="'lightbulb'"
          [color]="'#B31D15'"
        ></app-info-note-card>
      </div>
      <div class="content">
        <div class="card-item">
          <h2>Cargos de gestión</h2>
          <p>
            Creados con el fin de tener una buena organización tanto interna
            como externa del comité.
          </p>
          <ul>
            <li>
              <strong>Representante ADEN U:</strong> Portavoz del comité
              estudiantil ante el consejo académico y viceversa.
            </li>
            <li>
              <strong>Secretario:</strong> redacta y registra las actas y temas
              de debate de cada reunión, prepara las agendas y da aviso de los
              próximos encuentro a los miembros y participantes externos.
            </li>
            <li>
              <strong>Tesorero:</strong> responsable de las tareas
              administrativas relacionadas con la planificación presupuestaria,
              la ejecución financiera, y la recaudación de fondos. Es quien
              maneja todo el dinero del comité estudiantil y proporciona la
              documentación de cualquier inversión que se haga con este. Sirve
              como representante del comité ante la administración de la
              universidad sobre cualquier situación financiera que se presente.
            </li>
          </ul>
        </div>
        <div class="card-item">
          <h2>Líderes de proyecto</h2>
          <p>
            Encargados de la organización de proyectos relacionados con el cargo
            asignado, como podrían ser eventos virtuales o presenciales,
            convocatorias, etc.
          </p>
          <ol>
            <li>
              <strong>Encargado/a</strong> de proyectos relacionados con
              <strong>actividades deportivas y atléticas</strong>
            </li>
            <li>
              <strong>Encargado/a</strong> de proyectos relacionados con
              <strong>actividades artísticas y culturales</strong>
            </li>
            <li>
              <strong>Encargado/a</strong> de proyectos relacionados con
              <strong>actividades académicas y de crecimiento integral</strong>
            </li>
            <li>
              <strong>Encargado/a</strong> de proyectos relacionados con
              <strong>actividades de responsabilidad social</strong>
            </li>
            <li>
              <strong>Encargado/a</strong> de proyectos relacionados con el
              <strong>Programa de Estudios en el Extranjero</strong>
            </li>
            <li>
              <strong>Encargado/a de divulgación e información</strong>
              difunde la información del comité a el alumnado, manejando las
              redes, y brindando información a cualquiera que la solicite.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <section id="casas">
    <div class="container">
      <h1>Casas ADENPÓLIS</h1>
      <p>
        ADÉNPOLIS tiene en total 4 casas, cada una liderada por los
        representantes electos de las licenciaturas que la conforman. En caso de
        que alguna licenciatura no elija un representante, podrá apoyarse en los
        demás líderes de su casa para hacer sus propuestas y sugerencias.
      </p>

      <div class="card-container">
        <ng-container *ngFor="let item of houses">
          <app-house-card [house]="item"></app-house-card>
        </ng-container>
      </div>

      <p>
        Se propone el uso de casas con el fin de crear un
        <strong>mayor sentido de pertenencia</strong> y una
        <strong>mayor integración alumno-universidad y alumno-alumno</strong>,
        no solo van a tener a sus lideres de casa siendo parte del comité y
        sirviendo como portavoces de sus preocupaciones y sugerencias, sino que
        también van a poder llevar con orgullo su casa participando en
        diferentes actividades recreativas, deportivas, culturales y académicas
        llevadas a cabo por el mismo.
      </p>
      <p>
        Es responsabilidad de los líderes <strong>recibir y guiar</strong> a los
        nuevos miembros de sus casas en su trayecto universitario, dando
        consejos, escuchando, y ofreciendo
        <strong>una mano de igual a igual</strong> siempre que se requiera.
      </p>
    </div>
  </section>

  <section id="postulaciones">
    <div class="container">
      <h1>Postulaciones y Elecciones</h1>
      <div class="grid-container">
        <div class="card-item">
          <img
            src="../../../assets/images/content/adenpolis/candidato.svg"
            alt=""
          />
          <h2>Etapa de postulación</h2>
          <p>
            Una vez al año, todos los alumnos que cumplan con los
            <a routerLink="/comite-estudiantil/postulaciones">requisitos</a>
            podrán postularse para ser candidatos a líder de su carrera
          </p>
        </div>
        <div class="card-item">
          <img
            src="../../../assets/images/content/adenpolis/eleccion.svg"
            alt=""
          />
          <h2>Elecciones</h2>
          <p>
            Cada alumno, de forma anónima, votará al candidato a líder de su
            carrera. Los alumnos que más votos acumulen para cada carrera
            resultarán seleccionados, habiendo un seleccionado por carrera por
            modalidad.
          </p>
        </div>
        <div class="card-item">
          <img
            src="../../../assets/images/content/adenpolis/comite.svg"
            alt=""
          />
          <h2>Constitución del comité</h2>
          <p>
            Los seleccionados serán los líderes de su correspondiente casa y
            miembros constituyentes del Comité Estudiantil de cada modalidad.
          </p>
        </div>
      </div>
      <app-slide-banner
        class="slide"
        *ngIf="configs"
        [data]="postulationBanner"
      ></app-slide-banner>
    </div>
  </section>
  <section id="cronograma">
    <div class="container">
      <h1>Cronograma {{ configs && configs.year }}</h1>
      <div [class]="cronogram.length === 2 ? 'grid-container center' : 'grid-container'" *ngIf="cronogram.length > 0">
        <ng-container *ngFor="let item of cronogram">
          <div class="card-item">
            <div class="date">
              <mat-icon class="material-icons-rounded">event</mat-icon>
              {{
                moment(item.attributes.date_from).locale("es").format("MMM") +
                  " " +
                  moment(item.attributes.date_from).format("DD")
              }}
              {{
                item.attributes.date_to
                  ? " - " +
                    moment(item.attributes.date_to).locale("es").format("MMM") +
                    " " +
                    moment(item.attributes.date_to).format("DD")
                  : null
              }}
            </div>
            <h2>{{ item.attributes.title }}</h2>
            <p>{{ item.attributes.description }}</p>
          </div>
        </ng-container>
      </div>
      <h1 *ngIf="documentsCronogram.length > 0">Documentacion</h1>
      <div 
      [class]="documentsCronogram.length === 2 ? 
      'grid-container center' : 'grid-container'" 
      *ngIf="documentsCronogram.length > 0"
      >     
        <ng-container *ngFor="let item of documentsCronogram">
          <div class="card-item padding">
            <div class="date">
              <mat-icon class="material-icons-rounded">event</mat-icon>
              {{
                moment(item.attributes.date_from).locale("es").format("MMM") +
                  " " +
                  moment(item.attributes.date_from).format("DD")
              }}
              {{
                item.attributes.date_to
                  ? " - " +
                    moment(item.attributes.date_to).locale("es").format("MMM") +
                    " " +
                    moment(item.attributes.date_to).format("DD")
                  : null
              }}
            </div>
            <h2>{{ item.attributes.title }}</h2>
            <p class="card-description">{{ item.attributes.description }}</p>
            <div (click)="navigate(item.attributes.link)" class="btn-doc">Ver documentacion</div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
  <section id="contacto">
    <div class="container">
      <div class="grid-container">
        <div class="content">
          <h2>¿Te has quedado con dudas?</h2>
          <p>
            Envíanos tu consulta y en breve será respondida a tu correo
            electrónico
          </p>
          <form class="form-contact">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Escribe tu consulta</mat-label>
              <textarea
                matInput
                #message
                maxlength="800"
                [formControl]="messageCtrl"
                required
              ></textarea>
              <mat-error *ngIf="messageCtrl.hasError('required')">
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-hint align="end"
                >{{ message.value?.length || 0 }}/800
              </mat-hint>
            </mat-form-field>

            <button
              mat-flat-button
              color="primary"
              [disabled]="messageCtrl.hasError('required') || sendingMessage"
              (click)="sendMessage()"
            >
              <div class="submit-button">
                {{ sendingMessage ? "Enviando" : "Enviar consulta" }}
                <div class="small-loader" *ngIf="sendingMessage"></div>
              </div>
            </button>
          </form>
        </div>
        <div class="image">
          <img
            src="../../../../assets/images/content/adenpolis/dudas.svg"
            alt="Ayuda"
          />
        </div>
      </div>
    </div>
  </section>
</div>
