<div class="dialog-header">
  <div mat-dialog-title>
    <h1>Comprar asignaturas</h1>
    <button mat-button *ngIf="this.data.products.length > 0" (click)="startTour()">
      Iniciar tour
    </button>
    <button class="close" mat-icon-button aria-label="Close" (click)="onClose()">
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
</div>
<div class="dialog-container">
  <div>
    <app-custom-stepper #stepper>
      <!-- SELECCIONA EL PRODUCTO -->
      <cdk-step>
        <div class="step-container">
          <div class="body a">
            <div class="product-list" tourAnchor="productosDisponibles">
              <ng-container *ngFor="let producto of products; let i = index">
                <app-product-card [isGraduando]="this.data.isGraduando" [limitThisProduct]="cartLimit" [product]="producto" [addProduct]="addProduct" [isInCart]="isInCart" [cart]="cart"
                  [isInCartLimit]="isInCartLimit" [isGradOption]="isGradOption" [index]="i" [gradOpp]="gradOpp"
                  [regularOpp]="regularOpp" *ngIf="producto.price > 0 && producto.show"
                  ></app-product-card>
              </ng-container>
            </div>
          </div>
          <div class="cart" id="cart">
            <div class="top">
              <div class="title">Mi carrito</div>
              <div class="product-list" *ngIf="cart.length > 0; else emptyCart">
                <div class="mensaje-carrito gray" *ngIf="units < cartLimit">
                  Puedes añadir hasta
                  <strong> {{ cartLimit }} </strong> produtos
                </div>
                <div class="mensaje-carrito green" *ngIf="units === cartLimit">
                  ¡Has llenado tu carrito!
                </div>
                <ng-container style="width: 100%" *ngFor="let producto of cart">
                  <app-simple-product-card [product]="producto" [actions]="true" [updateProduct]="updateProduct"
                    [removeProduct]="removeProduct"></app-simple-product-card>
                </ng-container>
              </div>
              <ng-template #emptyCart>
                <div class="empty-cart">
                  <mat-icon class="material-icons-round">add_shopping_cart</mat-icon>
                  <h1>¡Agrega productos a tu carrito!</h1>
                </div>
              </ng-template>
            </div>
            <div class="footer">
              <div class="subtotals">
                <div class="list-item" *ngIf="
                    data.modalidad === 'presencial' && !data.dont_pay_fixed_cost
                  ">
                  <div class="title-2">Costo Administrativo</div>
                  <div class="price-2">
                    <div class="unit">US$</div>
                    <div class="number">
                      {{getFixedCostByID(14) | number : "1.2-2" }}
                    </div>
                  </div>
                </div>
                <div class="list-item" *ngIf="
                    data.modalidad === 'presencial' && !data.dont_pay_fixed_cost
                  ">
                  <div class="title-2">Costo Seguro</div>
                  <div class="price-2">
                    <div class="unit">US$</div>
                    <div class="number">
                      {{ getFixedCostByID(8) | number : "1.2-2" }}
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <div class="title-2">Subtotal asignaturas</div>
                  <div class="price-2">
                    <div class="unit">US$</div>
                    <div class="number">
                      {{ subTotalAsignaturas | number : "1.2-2" }}
                    </div>
                  </div>
                </div>
                <ng-container *ngFor="let item of data.becas">
                  <div
                    class="list-item red"
                    *ngIf="
                      (item.aplica_pasantias_grado && subTotalGrad > 0) ||
                      subTotalNormal > 0
                    "
                  >
                    <div class="title-2">{{ item.nombre_beca }}</div>
                    <div class="price-2">
                      <div class="unit">- US$</div>
                      <div class="number">
                        {{
                          getPercentageDiscount(item)
                        }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="list-item">
                <div class="title-1">TOTAL</div>
                <div class="price">
                  <div class="unit">US$</div>
                  <div class="number">
                    {{ getTotalDiscount() }}
                  </div>
                </div>
              </div>
              <div class="warn-purchase" *ngIf="this.normalQuantityUnits < this.data.minAsigments">* Debes comprar al menos {{this.data.minAsigments}} materias para continuar</div>
              <button tourAnchor="continuarCompra" class="continue-purchase" mat-flat-button style="width: 100%" color="primary"
                [disabled]="cart.length === 0 ||  this.normalQuantityUnits < this.data.minAsigments" (click)="showAlertMoneda()" cdkStepperNext>
                Continuar
              </button>
            </div>
          </div>
        </div>
        <!-- <button cdkStepperPrevious>Prev</button
        ><button cdkStepperNext>Next</button> -->
      </cdk-step>
      <!-- SELECCIONA LA FORMA DE PAGO -->
      <cdk-step>
        <div class="step-container">
          <div class="body b">
            <h3 class="mb-3">Selecciona la forma de pago</h3>
            <p class="info">
              * Si realizas tus pagos en moneda diferente a Dólar, debes
              seleccionar la opción de transferencia y contactarte con tu
              <a [routerLink]="['/academic-advisor']" routerLinkActive="router-link-active">Academic Advisor</a>.
            </p>
            <mat-radio-group tourAnchor="formaPago" color="primary" aria-label="Forma de pago" [(ngModel)]="formaPago">
              <div class="radio-container">
                <mat-radio-button tourAnchor="pagoTransferencia" value="transferencia/cheque" [matTooltip]="
                    !data.ifharu
                      ? 'Esta opcion puede demorar tu proceso de matriculación ya que requiere de un proceso de validación, recomendamos el pago ONLINE'
                      : 'Única opcion de pago para alumnos en modalidad Online con convenio IFHARU, por cualquier duda contacta a tu AA'
                  " matTooltipClass="tooltip-style" aria-label="Forma de pago deshabilitada"
                  #tooltipPresencial="matTooltip" (click)="tooltipPresencial.toggle()">Transferencia /
                  ACH</mat-radio-button>
                <mat-radio-button tourAnchor="pagoOnline" *ngIf="
                    !data.ifharu ||
                    (data.modalidad === 'presencial' && data.ifharu)
                  " value="online" matTooltipClass="tooltip-style"
                  matTooltip="Esta opción es la mejor alternativa para acelerar tu proceso de matriculación, te recomendamos seguir por este proceso"
                  #tooltipOnline="matTooltip" (click)="tooltipOnline.toggle()">Online</mat-radio-button>
              </div>
            </mat-radio-group>
            <ng-container *ngIf="formaPago === 'online'">
              <div class="payment-container">
                <h3>¿En cuántas cuotas deseas realizar el pago?</h3>
                <div class="contador" tourAnchor="seleccionCuotasOnline">
                  <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="removeOneCuota()"
                    [disabled]="cantCuotas <= 1">
                    <mat-icon class="material-icons-round">remove</mat-icon>
                  </button>
                  <div class="cantidad notranslate">{{ cantCuotas }}</div>
                  <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="addOneCuota()"
                    [disabled]="cantCuotas >= limitCuotas">
                    <mat-icon class="material-icons-round">add</mat-icon>
                  </button>
                </div>
                <h3>¿Deseas diferir tu pago?</h3>
                <mat-radio-group tourAnchor="pagoDiferido" aria-label="Selecciona una opcion" class="differ-container"
                  color="primary" [formControl]="differPayControl">
                  <mat-radio-button [value]="false">Quiero pagar desde hoy</mat-radio-button>
                  <mat-radio-button [disabled]="
                      cantDaysDiffer === undefined || cantDaysDiffer <= 0
                    " [value]="true">Deseo diferir el pago en otras fechas</mat-radio-button>
                </mat-radio-group>
                <ng-container *ngIf="!differPayControl.value">
                  <div class="differ-message mt-3">
                    Se tomará la fecha de hoy ({{
                    moment(currentDate).format("DD/MM/YYYY")
                    }}) como fecha de inicio de pago
                  </div>
                </ng-container>
                <ng-container *ngIf="differPayControl.value" id="differPay">
                  <div class="mt-3 differ-message">
                    Puedes seleccionar cualquier fecha entre mañana y el
                    {{ moment(maxDate).format("DD/MM/YYYY") }}
                  </div>
                  <mat-form-field class="full-width mt-3" appearance="outline">
                    <mat-label>Selecciona una fecha</mat-label>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" [formControl]="dateControl"
                      placeholder="dd/mm/aaaa" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="
                        dateControl.invalid ||
                        dateControl.value > maxDate ||
                        dateControl.value < minDate
                      ">Debes seleccionar una fecha válida para
                      continuar</mat-error>
                  </mat-form-field>
                </ng-container>
                <div id="differPay"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="formaPago !== 'online'">
              <div class="payment-container">
                <h3>¿En cuántas cuotas deseas realizar el pago?</h3>
                <div class="contador" tourAnchor="seleccionCuotasTransferencia">
                  <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="removeOneCuota()"
                    [disabled]="cantCuotas <= 1">
                    <mat-icon class="material-icons-round">remove</mat-icon>
                  </button>
                  <div class="cantidad notranslate">{{ cantCuotas }}</div>
                  <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="addOneCuota()"
                    [disabled]="cantCuotas >= limitCuotas">
                    <mat-icon class="material-icons-round">add</mat-icon>
                  </button>
                </div>
                <h3>¿Deseas diferir tu pago?</h3>
                <mat-radio-group tourAnchor="pagoDiferido" aria-label="Selecciona una opcion" class="differ-container"
                  color="primary" [formControl]="differPayControl">
                  <mat-radio-button [value]="false">Quiero pagar desde hoy</mat-radio-button>
                  <mat-radio-button [disabled]="
                      cantDaysDiffer === undefined || cantDaysDiffer <= 0
                    " [value]="true">Deseo diferir el pago en otras fechas</mat-radio-button>
                </mat-radio-group>
                <ng-container *ngIf="!differPayControl.value">
                  <div class="differ-message mt-3">
                    Se tomará la fecha de hoy ({{
                    moment(currentDate).format("DD/MM/YYYY")
                    }}) como fecha de inicio de pago
                  </div>
                </ng-container>
                <ng-container *ngIf="differPayControl.value" id="differPay">
                  <div class="mt-3 differ-message">
                    Puedes seleccionar cualquier fecha entre mañana y el
                    {{ moment(maxDate).format("DD/MM/YYYY") }}
                  </div>
                  <mat-form-field class="mt-3" appearance="outline">
                    <mat-label>Selecciona una fecha</mat-label>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" [formControl]="dateControl"
                      placeholder="dd/mm/aaaa" />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="
                        dateControl.invalid ||
                        dateControl.value > maxDate ||
                        dateControl.value < minDate
                      ">Debes seleccionar una fecha válida para
                      continuar</mat-error>
                  </mat-form-field>
                </ng-container>
                <div id="differPay"></div>
                <h3>1. Realiza la transferencia</h3>
                <p>
                  Deberás transferir el valor correspondiente a la primera
                  cuota.
                </p>
                <div class="datos-transferencia" tourAnchor="realizaTransferencia">
                  <div class="bank-data">
                    <div class="item">
                      <label>Banco</label>
                      <p>{{ getBankData().banco }}</p>
                    </div>
                    <div class="item">
                      <label>Tipo y Nº de cuenta</label>
                      <p>
                        {{ getBankData().cuenta }}
                      </p>
                    </div>
                    <div class="item">
                      <label>Nombre</label>
                      <p>
                        {{ getBankData().nombre }}
                      </p>
                    </div>
                  </div>
                  <div class="aviso">
                    <p class="title">¡IMPORTANTE!</p>
                    <p>
                      Al realizar la transferencia, recuerda colocar como asunto
                      o descripción de la misma tu ID de estudiante
                    </p>
                    <div class="chip">ID: {{ data.user.legajo }}</div>
                  </div>
                </div>
                <h3 class="mt-3">
                  2. Indica el número de operación de la transferencia
                </h3>
                <p>
                  Indica aquí debajo el número de la operación o transferencia
                  que aparece en tu comprobante de pago (debe coincidir con el
                  número de operación del comprobante que adjuntes, esto nos
                  permitirá validar tu pago más rapidamente)
                </p>
                <mat-form-field tourAnchor="numeroOperacion">
                  <mat-label>Nro. de Operación</mat-label>
                  <input matInput type="search" placeholder="Ingresa el número de operación"
                    [(ngModel)]="nroComprobante" />
                </mat-form-field>
                <h3 class="mt-3">3. Envíanos tu comprobante de pago</h3>
                <div class="button-container" tourAnchor="envioComprobante">
                  <div class="email-button" (click)="sendEmail('cobros@adenuniversity.edu.pa')">
                    <div class="body">
                      <p>
                        Deberás enviar el comprobante de la operación vía mail a
                        las direcciones
                        <strong>gestorrecaudo@adenuniversity.edu.pa</strong> y
                        <strong>cobros@adenuniversity.edu.pa</strong> indicando
                        tu ID de estudiante como asunto del mensaje.
                      </p>
                      <button mat-flat-button color="primary">
                        Adjuntar comprobante ahora
                      </button>
                    </div>
                  </div>
                </div>
                <div class="text-muted p-4">
                  Al finalizar la compra se iniciará el proceso de verificación
                  de pago, el mismo puede demorar entre <b>1 y 3 días</b> para
                  pagos por transferencia. Una vez que hayamos verificado tu
                  pago, se acreditarán en el Campus las asignaturas adquiridas.
                </div>
              </div>
            </ng-container>
          </div>
          <div class="cart">
            <div class="top">
              <div class="title">Resumen de compra</div>
              <div class="product-list2">
                <ng-container style="width: 100%" *ngFor="let producto of cart">
                  <app-simple-product-card [product]="producto" [actions]="false" [updateProduct]="updateProduct"
                    [removeProduct]="removeProduct"></app-simple-product-card>
                </ng-container>
              </div>
            </div>
            <div class="footer" tourAnchor="finalizarCompra">
              <div class="subtotals">
                <div class="list-item" *ngIf="
                    data.modalidad === 'presencial' && !data.dont_pay_fixed_cost
                  ">
                  <div class="title-2">Costo Administrativo</div>
                  <div class="price-2">
                    <div class="unit">US$</div>
                    <div class="number">
                      {{ getFixedCostByID(14) | number : "1.2-2" }}
                    </div>
                  </div>
                </div>
                <div class="list-item" *ngIf="
                    data.modalidad === 'presencial' && !data.dont_pay_fixed_cost
                  ">
                  <div class="title-2">Costo Seguro</div>
                  <div class="price-2">
                    <div class="unit">US$</div>
                    <div class="number">
                      {{ getFixedCostByID(8) | number : "1.2-2" }}
                    </div>
                  </div>
                </div>
                <div class="list-item">
                  <div class="title-2">Subtotal asignaturas</div>
                  <div class="price-2">
                    <div class="unit">US$</div>
                    <div class="number">
                      {{ subTotalAsignaturas | number : "1.2-2" }}
                    </div>
                  </div>
                </div>
                <!-- DESCUENTO -->
                <div class="list-item red" *ngIf="!data.ifharu && normalQuantityUnits >= 4 && cantCuotas === 1">
                  <div class="title-2">5% OFF (Pago contado)</div>
                  <div class="price-2">
                    <div class="unit">- US$</div>
                    <div class="number">
                      {{ getDiscount() | number : "1.2-2" }}
                    </div>
                  </div>
                </div>

                <ng-container *ngFor="let item of data.becas">
                  <div
                    class="list-item red"
                    *ngIf="
                      (item.aplica_pasantias_grado && subTotalGrad > 0) ||
                      subTotalNormal > 0
                    "
                  >
                    <div class="title-2">{{item.descuento_real}}% OFF {{ item.nombre_beca }}</div>
                    <div class="price-2">
                      <div class="unit">- US$</div>
                      <div class="number">
                        {{
                          getPercentageDiscount(item)
                        }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="list-item">
                <div class="title-1">TOTAL</div>
                <div class="price">
                  <div class="unit">US$</div>
                  <div class="number">
                    <!-- DESCUENTO -->
                    {{
                    !data.ifharu && units >= 4 && cantCuotas === 1
                    ? (getContadoDiscount())
                    : (data.becas.length ? getTotalDiscount() : totalCompra)
                    }}
                  </div>
                </div>
              </div>
              <div class="pay-plan">
                <p class="tag">PLAN DE PAGO</p>
                <div class="list-item">
                  <div class="title-2">Cant. cuotas</div>
                  <div class="price-2">
                    <div class="number">{{ cantCuotas }}</div>
                  </div>
                </div>
                <div class="list-item">
                  <div class="title-2">
                    Valor cuota{{
                    formaPago !== "online" ? " (a transferir)" : ""
                    }}
                  </div>
                  <div class="price-2">
                    <div class="unit">US$</div>
                    <div class="number">
                      <!-- DESCUENTO -->
                      {{
                      !data.ifharu && units >= 4 && cantCuotas === 1 || data.becas.length
                      ? (getContadoDiscount() / cantCuotas)
                      : (totalCompra / cantCuotas | number : "1.2-2")
                      }}
                    </div>
                  </div>
                </div>
                <div class="list-item" *ngIf="differPayControl.value && cantCuotas <= 1">
                  <div class="title-2">Día de pago</div>
                  <div class="price-2">
                    <div class="number">
                      {{ dateControl.value | date : "dd/MM/yyyy" }}
                    </div>
                  </div>
                </div>
                <div class="list-item" *ngIf="cantCuotas > 1">
                  <div class="title-2">Día de pago</div>
                  <div class="price-2">
                    <div class="number">
                      {{
                      differPayControl.value
                      ? (dateControl.value | date : "d")
                      : moment(currentDate).format("D")
                      }}
                      de cada mes
                    </div>
                  </div>
                </div>
              </div>
              <section class="terms-container">
                <mat-checkbox [checked]="termCheck" (change)="termsChange($event)" class="terms-check"></mat-checkbox>
                <div>Acepto los <span (click)="showTerms()">terminos y condiciones</span></div>
              </section>
              <button mat-flat-button style="width: 100%" color="primary" id="btn-confirm-link" [disabled]="
                  (differPayControl.value &&
                    (dateControl?.value > maxDate ||
                      dateControl?.value < minDate ||
                      dateControl.invalid)) ||
                  sending || !termCheck
                " *ngIf="formaPago === 'online'" (click)="finishPurchase()">
                Generar link de pago
              </button>
              <button mat-flat-button id="btn-confirm-transfer" style="width: 100%" color="primary"
                [disabled]="nroComprobante === '' || sending" *ngIf="formaPago !== 'online'" (click)="finishPurchase()">
                Finalizar compra
              </button>
              <button mat-stroked-button style="width: 100%" color="primary" cdkStepperPrevious>
                Volver al carrito
              </button>
            </div>
          </div>
        </div>
      </cdk-step>
    </app-custom-stepper>
  </div>
</div>
<div class="soonContainer" *ngIf="sending">
  <div class="soonMessage spinner">
    <img src="../../../../../assets/images/logos/ICONOS.gif" alt="">
  </div>
</div>