import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-your-progress',
  templateUrl: './your-progress.component.html',
  styleUrls: ['./your-progress.component.scss']
})
export class YourProgressComponent implements OnInit {

  @Input() carreras: any;
  initializedSubjects = 0;

  constructor() { }

  ngOnInit(): void {
    console.log('carreras =>' , this.carreras);
  }
}
