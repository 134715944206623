import { Observable, map } from 'rxjs';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/shared/services/data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsCartComponent } from './components/products-cart/products-cart.component';
import { Component, OnInit } from '@angular/core';
import { OrderSucessDialogComponent } from './components/order-sucess-dialog/order-sucess-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatriculacionesService } from 'src/app/shared/services/matriculaciones.service';
import { ModalidadDialogComponent } from '../matriculaciones/components/modalidad-dialog/modalidad-dialog.component';
import moment from 'moment';
import { CarreraPrincipal } from 'src/app/shared/interfaces/ICarrera';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DerechosService } from 'src/app/shared/services/derechos.service';

@Component({
  selector: 'app-derechos-pecuniarios',
  templateUrl: './derechos-pecuniarios.component.html',
  styleUrls: ['./derechos-pecuniarios.component.scss'],
})
export class DerechosPecuniariosComponent implements OnInit {
  loading = false;
  today = moment();
  fechasInscripcion = null;
  dateInit = moment();
  dateEnd = moment();
  timeLeft: number;
  isBloq = false;
  moment = moment;
  carreras: CarreraPrincipal[] = [];

  error = false;
  productos = [];
  searchValue: string;
  isDoc = false;
  modalidad = '';
  cart = [];
  isSomeSelected = false;
  disabled = true;
  total = 0;
  units = 0;
  modalidadBase: string = null;
  modalidadSelected: string = null;

  public user$: Observable<any>;
  public user;

  constructor(
    private service: DataService,
    private _snackBar: MatSnackBar,
    private matricService: MatriculacionesService,
    private dialog: MatDialog,
    private derechosService: DerechosService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private alumnoService: AlumnoService,
    private store: Store<UserState>
  ) {
    this.user$ = store.select('user');
  }


  checkIndexDefault(){
    if(this.modalidadSelected){
      return this.modalidadSelected.toLowerCase() === 'presencial' ? 0 : 1;
    }
  }

  isDifferentModalidad(){
    return this.user$.pipe(
      map(data => {
        if (data.carreras.length > 1) {
          return data.carreras[0].modalidad.toLowerCase() !== data.carreras[1].modalidad.toLowerCase();
        } else {
          return false;
        }
      })
    );
  }
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.modalidadSelected =
        params.mod != null && params.mod != undefined ? params.mod : null;
    });


    this.alumnoService.getAlumno().subscribe(res => {

      this.loading = false;
      if(res.estado_documentacion === 'completo' || res.estado_documentacion === 'regular'){
        this.isDoc = true;
        this.carreras = res.carreras.filter(
          (carrera) => carrera.estado_bloqueo === ''
        );
        if(res.carreras[0].estado_bloqueo != null && 
          res.carreras[0].estado_bloqueo != "" &&
          res.carreras[0].estado_bloqueo != 'Baja' &&
          res.carreras[0].estado_bloqueo != 'Graduado')
        {
          this.isBloq = true;
          document.getElementById('tramites-academicos').classList.add('blur');
        }
        this.fechasInscripcion = res.fechas_insc;
        this.modalidadBase =
        this.carreras.some((carrera) => carrera.modalidad === 'Online') &&
        this.carreras.some((carrera) => carrera.modalidad === 'Presencial') &&
        this.fechasInscripcion.ol.fecha_inicio_insc != null &&
        this.fechasInscripcion.ol.fecha_inicio_insc != '' &&
        this.fechasInscripcion.pr.fecha_inicio_insc != null &&
        this.fechasInscripcion.pr.fecha_inicio_insc != ''
          ? 'mixta'
          : this.carreras.some((carrera) => carrera.modalidad === 'Online') &&
            this.carreras.some(
              (carrera) => carrera.modalidad === 'Presencial'
            ) &&
            this.fechasInscripcion.ol.fecha_inicio_insc !== null &&
            this.fechasInscripcion.ol.fecha_inicio_insc !== '' &&
            (this.fechasInscripcion.pr.fecha_inicio_insc === null ||
              this.fechasInscripcion.pr.fecha_inicio_insc === '')
          ? 'online'
          : this.carreras.some((carrera) => carrera.modalidad === 'Online') &&
            this.carreras.some(
              (carrera) => carrera.modalidad === 'Presencial'
            ) &&
            this.fechasInscripcion.pr.fecha_inicio_insc !== null &&
            this.fechasInscripcion.pr.fecha_inicio_insc !== '' &&
            (this.fechasInscripcion.ol.fecha_inicio_insc === null ||
              this.fechasInscripcion.ol.fecha_inicio_insc === '')
          ? 'presencial'
          : this.carreras[0].modalidad.toLowerCase();
    
      /* console.log('Modalidad base', this.modalidadBase); */
    
      switch (this.modalidadBase) {
        case 'mixta':
          if (this.isDifferentModalidad()) {
            this.openSelectModalidad();
          } else {
            if (this.modalidadSelected === 'online') {
              this.dateEnd = this.fechasInscripcion.ol.fecha_fin_insc
                ? moment(
                    this.fechasInscripcion.ol.fecha_fin_insc + ' 23:50',
                    'YYYY-MM-DD HH:mm'
                  )
                : null;
              this.dateInit = this.fechasInscripcion.ol.fecha_inicio_insc
                ? moment(
                    this.fechasInscripcion.ol.fecha_inicio_insc + ' 00:00',
                    'YYYY-MM-DD HH:mm'
                  )
                : null;
              this.timeLeft =
                this.dateInit != null && this.dateEnd != null
                  ? this.dateInit.valueOf() / 1000 -
                      this.today.valueOf() / 1000 >
                    86400
                    ? this.dateInit.valueOf() / 1000 -
                      this.today.valueOf() / 1000 -
                      86400
                    : this.dateInit.valueOf() / 1000 -
                      this.today.valueOf() / 1000
                  : 0;
            } else {
              this.dateEnd = this.fechasInscripcion.pr.fecha_fin_insc
                ? moment(
                    this.fechasInscripcion.pr.fecha_fin_insc + ' 23:59',
                    'YYYY-MM-DD HH:mm'
                  )
                : null;
              this.dateInit = this.fechasInscripcion.pr.fecha_inicio_insc
                ? moment(
                    this.fechasInscripcion.pr.fecha_inicio_insc + ' 00:00',
                    'YYYY-MM-DD HH:mm'
                  )
                : null;
              this.timeLeft =
                this.dateInit != null && this.dateEnd != null
                  ? this.dateInit.valueOf() / 1000 -
                      this.today.valueOf() / 1000 >
                    86400
                    ? this.dateInit.valueOf() / 1000 -
                      this.today.valueOf() / 1000 -
                      86400
                    : this.dateInit.valueOf() / 1000 -
                      this.today.valueOf() / 1000
                  : 0;
            }
          }
    
          break;
    
        case 'presencial':
          this.dateEnd = this.fechasInscripcion.pr.fecha_fin_insc
            ? moment(
                this.fechasInscripcion.pr.fecha_fin_insc + ' 23:59',
                'YYYY-MM-DD HH:mm'
              )
            : null;
          this.dateInit = this.fechasInscripcion.pr.fecha_inicio_insc
            ? moment(
                this.fechasInscripcion.pr.fecha_inicio_insc + ' 00:00',
                'YYYY-MM-DD HH:mm'
              )
            : null;
          this.timeLeft =
            this.dateInit != null && this.dateEnd != null
              ? this.dateInit.valueOf() / 1000 - this.today.valueOf() / 1000 >
                86400
                ? this.dateInit.valueOf() / 1000 -
                  this.today.valueOf() / 1000 -
                  86400
                : this.dateInit.valueOf() / 1000 - this.today.valueOf() / 1000
              : 0;
          break;
    
        case 'online':
          this.dateEnd = this.fechasInscripcion.ol.fecha_fin_insc
            ? moment(
                this.fechasInscripcion.ol.fecha_fin_insc + ' 23:50',
                'YYYY-MM-DD HH:mm'
              )
            : null;
          this.dateInit = this.fechasInscripcion.ol.fecha_inicio_insc
            ? moment(
                this.fechasInscripcion.ol.fecha_inicio_insc + ' 00:00',
                'YYYY-MM-DD HH:mm'
              )
            : null;
          this.timeLeft =
            this.dateInit != null && this.dateEnd != null
              ? this.dateInit.valueOf() / 1000 - this.today.valueOf() / 1000 >
                86400
                ? this.dateInit.valueOf() / 1000 -
                  this.today.valueOf() / 1000 -
                  86400
                : this.dateInit.valueOf() / 1000 - this.today.valueOf() / 1000
              : 0;
          break;
    
        default:
          this.dateInit = null;
          this.dateEnd = null;
          this.timeLeft = 0;
          break;
      }
      }
    })
    this.getProducts();
    this.addProduct = this.addProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.activeRoute.queryParams.subscribe((params) => {
      this.modalidadSelected =
        params.mod != null && params.mod != undefined ? params.mod : null;
    });
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
        this.modalidad =
          this.user.carreras.some((item) => item.modalidad === 'Presencial') &&
          this.user.carreras.some((item) => item.modalidad === 'Online') &&
          this.user.carreras[0].modalidad === 'Presencial'
            ? 'Presencial'
            : this.user.carreras.some(
                (item) => item.modalidad === 'Presencial'
              ) &&
              this.user.carreras.some((item) => item.modalidad === 'Online') &&
              this.user.carreras[0].modalidad === 'Online'
            ? 'Online'
            : this.user.carreras[0].modalidad;

            if(this.user.pago_atrasado){
              console.log('PAGO ATRASADO')
              document.getElementById('tramites-academicos').classList.add('blur');
            }
      }
    });
  }

  getProducts() {
    this.loading = true;
    this.derechosService.getDerechosPecuniarios().subscribe({
      next: (res) => {
        res.forEach((productList) => {
          this.productos = [...this.productos, ...productList];
        });
      },
      error: (err) => {
        this.error = true;
        console.log(err);
        this.loading = false
      }
    });
  }

  openSelectModalidad(): void {
    const dialogRef = this.dialog.open(ModalidadDialogComponent, {
      width: '400px',
      maxWidth: '90vw',
      panelClass: 'plain-alert',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((modalidad) => this.goToModalidadd(modalidad));
  }

  goToModalidadd(mod = 'opuesta') {
    // document.getElementById('matriculaciones').classList.remove('blur');
    if (mod === 'opuesta') {
      this.router.navigate(['/tramites-academicos'], {
        queryParams: {
          mod: this.modalidadSelected === 'online' ? 'presencial' : 'online',
        },
      });
    } else {
      this.router.navigate(['/tramites-academicos/'], {
        queryParams: {
          mod: mod,
        },
      });
  }
}

  search(evt) {
    evt.preventDefault();
  }

  cleanSearch(): void {
    this.searchValue = '';
  }

  /* OPERACIONES CARRITO */
  public async addProduct(product, quantity = 1, certification = false) {
    if (!this.isInCart(product.certificacion_id, product.carrera_id)) {
      if(!certification){
        this.cart.push({
          certificacion_id: product.certificacion_id,
          certificacion: product.certificacion,
          tipo_certificado: product.tipo_certificado,
          valor_usd: product.valor_usd,
          quantity: 1,
          subtotal: product.valor_usd * 1,
          carrera: product.carrera,
          carrera_id: product.carrera_id,
        });
        this.total = this.total + product.valor_usd * quantity;
        this.units = this.units + quantity;
      }
    } else {
      this.cart = this.cart.map((item) => {
        if (item.certificacion_id === product.certificacion_id) {
          if(certification){
            item.tipo_certificado  = product.tipo_certificado
          }else{
            if (item.quantity === product.limit) {
              return item;
            } else {
              item.quantity += quantity;
              item.subtotal += product.valor_usd * quantity;
              this.total = this.total + product.valor_usd * quantity;
              this.units = this.units + quantity;
            }
          }
        }
        return item;
      });
    }

    /* this.openCartDialog(); */
  }

  public updateProduct(product, quantity: number): void {
    this.cart = this.cart.map((item) => {
      if (
        item.certificacion_id === product.certificacion_id &&
        item.carrera_id === product.carrera_id
      ) {
        this.total = this.total - item.subtotal + product.valor_usd * quantity;
        this.units = this.units - item.quantity + quantity;
        item.quantity = quantity;
        item.subtotal = product.valor_usd * quantity;
      }
      return item;
    });
  }

  public removeProduct(product): void {
    const index = this.cart.findIndex(
      (item) =>
        item.certificacion_id === product.certificacion_id &&
        item.carrera_id === product.carrera_id &&
        item.carrera === product.carrera
    );
    this.cart.splice(index, 1);
    this.total = this.total - product.subtotal;
    this.units = this.units - product.quantity;
  }

  public isInCart(product_id, carrera_id): boolean {
    return (
      this.cart.find(
        (item) =>
          item.certificacion_id === product_id && item.carrera_id === carrera_id
      ) !== undefined
    );
  }
  public getItemInCart(product_id, carrera_id): boolean {
    return this.cart.find(
      (item) =>
        item.certificacion_id === product_id && item.carrera_id === carrera_id
    );
  }

  openCartDialog(): void {
    const dialogRef = this.dialog.open(ProductsCartComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: true,
      data: {
        title: 'title',
        cart: this.cart,
        total: this.total,
        units: this.units,
        student: this.user,
      },
      panelClass: 'generic-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result.finished) {
        this.cart = result.cart;
        this.units = result.units;
        this.total = result.total;
      }

      if (result.finished) {
        this.cart = [];
        this.units = 0;
        this.total = 0;
        console.log(`Link de pago: ${result.link} - Orden: ${result.orden}`);
        this.openLink(result.link, result.orden);
      }
    });
  }


  goToModalidad(mod) {
    this.modalidadSelected = mod === 'presencial' ? 'presencial' : 'online'
  }
  openLink(link, orden): void {
    const dialogRef2 = this.dialog.open(OrderSucessDialogComponent, {
      width: '600px',
      data: {
        link,
        orden,
      },
      panelClass: 'plain-alert',
      disableClose: true,
    });

    dialogRef2.afterClosed().subscribe((cart) => {
      window.location.reload();
    });
  }

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: [`${type}-snackbar`],
    });
  }
  navigate(url: string){
    this.router.navigate([url])
  }
}
