import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ToggleFavorite } from 'src/app/redux/actions/videos.action';
import { ShareDialogComponent } from 'src/app/shared/components/dialogs/share-dialog/share-dialog.component';
import { IVideo } from 'src/app/shared/interfaces/IVideo';
import { LibraryService } from 'src/app/shared/services/library.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit {

  @Input() videoData: IVideo;
  @Input() goToVideo;
  webUrl = environment.webUrl
  isChecked = false;

  constructor(
    private libraryService: LibraryService,
    private store: Store<any>,
    private dialog: MatDialog
    ) { }

  ngOnInit(): void {
    if(this.videoData.favorito)
    console.log(this.videoData)
    this.store.subscribe()
    this.isChecked = false;
  }

  clickVideo(){
    this.goToVideo(this.videoData.repo_id)
  }

  setFav(){
    if(!this.videoData.favorito){
      this.libraryService.postFavVideo(32940, this.videoData.repo_id)
      .subscribe(res => {
        console.log(res)
      })
    }else{
      this.libraryService.deleteFavVideo(32940, this.videoData.repo_id)
      .subscribe(res => {
        console.log(res)
      })
    }
  }

  toggleFavorite() {
    const action = new ToggleFavorite({ videoId: this.videoData.repo_id, isFavorite: !this.videoData.favorito });
    this.store.dispatch(action);
  }

  openShareDialog(){
    this.dialog.open(ShareDialogComponent, {
      data: {
        title: '¿Te gusto el contenido?',
        subtitle: '¡Compartelo!',
        link: `${this.webUrl}/library/${this.videoData.repo_id}`,
      },
      panelClass: 'generic-alert',
    });
  }

}
