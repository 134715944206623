import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  url = environment.cmsApiUrl;
  sisApiUrl = environment.baseUrl;

  constructor(private http: HttpClient, private dataService: DataService, private authService: AuthService) {}

  getShortPosts(query = ''): Observable<any> {
    return this.http.get(`${this.url}/short-posts?${query}`);
  }

  getShortPostBySlug(slug): Observable<any> {
    return this.http.get(`${this.url}/short-posts?filters[slug][$eq]=${slug}`);
  }

  getShortPostById(post_id): Observable<any> {
    return this.http.get(`${this.url}/short-posts/${post_id}`);
  }

  /* SLIDES */

  getSlides(): Observable<any> {
    return this.http.get(`${this.url}/slides?populate=*&sort=publishedAt:desc`);
  }

  /* POPUP */

  getNewsPopupByModality(filterModality, page): Observable<any> {
    return this.http.get(
      `${this.url}/news-popup-items?populate=*&sort=publishedAt:desc&filters[$and][0][modality][$ne]=${filterModality}&filters[$and][1][page][$eq]=${page}`
    );
  }

  getAllNewsPopupByPage(page): Observable<any> {
    return this.http.get(
      `${this.url}/news-popup-items?populate=*&sort=publishedAt:desc&filter[page][$eq]=${page}`
    );
  }

  /* BLOG ADENU */

  getNewsItems(query = ''): Observable<any> {
    return this.http.get(`${this.url}/newsitems?${query}`);
  }

  getNewsItemById(post_id): Observable<any> {
    return this.http.get(`${this.url}/newsitems/${post_id}`);
  }

  /* COMITE ESTUDIANTIL */

  applyToCommittee(data, file): Observable<any> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('files.avatar', file);
    return this.http.post(
      `${this.url}/inscripciones-centro-estudiantes`,
      formData
    );
  }

  voteCandidate(candidate_id): Observable<any> {
    return this.http.put(
      `${this.url}/inscripciones-centro-estudiantes/vote/${candidate_id}`,
      {}
    );
  }

  updateVoteState(votacion_comite: boolean): Observable<any> {
    const token = this.authService.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    const body = {
      votacion_comite,
    };
    return this.http.post(
      `${this.sisApiUrl}/educat/actualizar_votacion`,
      body,
      {
        headers,
      }
    );
  }

  getAllInscriptions(): Observable<any> {
    return this.http.get(`${this.url}/inscripciones-centro-estudiantes`);
  }

  getAllCandidates(modality): Observable<any> {
    return this.http.get(
      `${this.url}/inscripciones-centro-estudiantes?filters[is_candidate][$eq]=true&populate=*`
    );
  }

  getAllCandidatesByCareer(career, modality): Observable<any> {
    return this.http.get(
      `${this.url}/inscripciones-centro-estudiantes?filters[is_candidate][$eq]=true&filters[career][$eq]=${career}&filters[modality][$eq]=${modality}&populate=*`
    );
  }

  getAllWinners(): Observable<any> {
    return this.http.get(
      `${this.url}/inscripciones-centro-estudiantes?filters[is_winner][$eq]=true&populate=*`
    );
  }

  getWinnersByModality(modality): Observable<any> {
    return this.http.get(
      `${this.url}/inscripciones-centro-estudiantes?filters[is_winner][$eq]=true&filters[modality][$eq]=${modality}&populate=*`
    );
  }

  checkInscription(userId): Observable<any> {
    return this.http.get(
      `${this.url}/inscripciones-centro-estudiantes?filters[sis_id][$eq]=${userId}`
    );
  }

  getComiteConfig(): Observable<any> {
    return this.http.get(
      `${this.url}/configuracion-comite-estudiantil?populate=*`
    );
  }

  sendEmail(data): Observable<any> {
    return this.http.post(`${this.url}/email/consult-student-center`, data);
  }

  /* CURSOS ABIERTOS */

  getOpenCourses(): Observable<any> {
    return this.http.get(`${this.url}/cursos-abiertos?populate=*`);
  }

  getOpenCoursesByModality(filterModality): Observable<any> {
    return this.http.get(
      `${this.url}/cursos-abiertos?populate=*&filters[modality][$ne]=${filterModality}`
    );
  }

  /* GRADUACION */
  sendGradConsult(data): Observable<any> {
    return this.http.post(`${this.url}/email/consult-graduation`, data);
  }

  /* CONSULTAS EMAIL */

  sendConsult(data): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + environment.cmsToken,
    });
    return this.http.post(`${this.url}/email/consult`, data);
  }
}
