<div class="soonContainer" *ngIf="leftTime > 0 && today.isBefore(dates.init)">
  <div class="soonMessage">
    <h2>¡El proceso inicia pronto!</h2>
    <p>
      El próximo proceso de rematricula
      <strong> para modalidad {{ modalidadSelected }}</strong> se habilitará
      desde el
      <strong
        >{{ dates.init.locale("es").format("LLL") + "hs" }} ({{
          moment.tz.guess().replaceAll("_", " ")
        }})</strong
      >
      hasta el
      <strong>
        {{ dates.end.locale("es").format("LLL") + "hs" }} ({{
          moment.tz.guess().replaceAll("_", " ")
        }})
      </strong>
    </p>
    <div class="contador">
      <h2>FALTAN</h2>
      <countdown [config]="getConfigs()"></countdown>
      <div class="detalle">
        <p *ngIf="this.leftTime >= 86400">Días</p>
        <p *ngIf="this.leftTime > 3600">Horas</p>
        <p>Min</p>
        <p>Seg</p>
      </div>
    </div>
    <div class="button-container">
      <button
        mat-flat-button
        color="primary"
        [matMenuTriggerFor]="menu"
        (click)="getEvent()"
      >
        Agregar a mi calendario
      </button>
      <button mat-button color="primary" [routerLink]="'/home'">
        Volver al inicio
      </button>
    </div>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let item of links">
        <a mat-menu-item [href]="item.url" target="_blank">{{ item.name }}</a>
      </ng-container>
    </mat-menu>
  </div>
</div>

<div class="soonContainer" *ngIf="!this.showMatriculacion && this.bloqueo">
  <div class="soonMessage">
    <img src="../../../assets/images/content/bloqueo.png" alt="Proximamente" />
    <h2 class="text-blocked">¡Esta sección se encuentra bloqueada!</h2>
    <p>
      Para obtener más información contacta a tu Academic Advisor.
    </p>
    <a mat-button (click)="navigate('home')" class="bg-blocked text-white">
      Volver al inicio
    </a>
  </div>
</div>

<div
  class="soonContainer"
  *ngIf="
    (
      modalidadBase &&
      dates && dates.end && today.isAfter(dates.end)) ||
    (dates.end === null && dates.init === null)
  "
>
  <div class="soonMessage">
    <img src="../../../assets/images/content/waiting.svg" alt="Proximamente" />
    <h2>¡Proceso cerrado!</h2>
    <p>Nos vemos en la próxima etapa de matriculación</p>
    <a mat-button class="bg-primary-aden text-white" routerLink="/home">
      Volver al inicio
    </a>
  </div>
</div>

<div
  class="soonContainer"
  *ngIf="
    !this.contrato &&
    (!this.matricula || !this.showMatriculacion) &&
    !this.isLoading &&
    today.isBetween(dates.init, dates.end)
  "
>
  <div class="soonMessage">
    <img src="../../../assets/images/content/waiting.svg" alt="Proximamente" />
    <h2>¡Próximamente!</h2>
    <p>Muy pronto esta opción estará disponible para ti</p>
    <a mat-button class="bg-primary-aden text-white" href="/home">
      Volver al inicio
    </a>
  </div>
</div>

<div
  class="soonContainer"
  *ngIf="
    !this.matricula &&
    this.contrato &&
    !this.isLoading &&
    !this.bloqueo &&
    today.isBetween(dates.init, dates.end)
  "
>
  <div class="soonMessage">
    <img src="../../../assets/images/content/waiting.svg" alt="Proximamente" />
    <h2>¡Próximamente!</h2>
    <p>Las matriculaciones aún no están disponibles para ti</p>
    <a mat-button class="bg-primary-aden text-white" href="/home">
      Volver al inicio
    </a>
  </div>
</div>

<div
  class="soonContainer"
  *ngIf="
    !this.contrato &&
    this.matricula &&
    !this.showMatriculacion &&
    !this.isLoading &&
    !this.bloqueo &&
    today.isBetween(dates.init, dates.end)
  "
>
  <div class="soonMessage">
    <img
      src="../../../assets/images/content/contract.svg"
      alt="Firma contrato"
    />
    <h2>¡Acepta el contrato!</h2>
    <p>
      Para poder ingresar a matriculaciones es necesario que aceptes los
      términos y condiciones del contrato
    </p>
    <div class="button-container">
      <button
        mat-button
        class="bg-primary-aden text-white"
        (click)="openContractDialog()"
      >
        Ver contrato
      </button>
      <a mat-button color="primary" href="/home"> Volver al inicio </a>
    </div>
  </div>
</div>

<!-- <div class="soonContainer" *ngIf="this.isLoading || this.isWaiting">
  <div class="soonMessage spinner">
    <mat-spinner></mat-spinner>
  </div>
</div> -->