import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MatriculacionesService {
  public sharedDisabled = new ReplaySubject<boolean>(1);
  public isLoading = new ReplaySubject<boolean>(1);
  public dateInit = moment(); 
  public dateEnd = moment();
  public timeLeft: number;

  public namesCarreras: string[] = [];
  public periodos: any[] = [];
  public socialPractice: boolean = false;
  public professionalPractice: boolean = false;
  public matOnlPre: number = 0;
  public gradeOpMaestria: boolean = false;
  public gradeOpTesis: boolean = false;
  public gradeOpBusiness: boolean = false;
  public primeraMatriculacion: boolean = true;
  public firstCarreerPeriods = [];
  public noMinLimit = false;
  url = environment.baseUrl;
  
  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private http: HttpClient) {}

  updateDisabled(disabled: boolean) {
    this.sharedDisabled.next(disabled);
  }
  updateLoading(loading: boolean){
    this.isLoading.next(loading);
  }

  validatePeriodo(modalidadSelected, contPeriodos, user, selectMaterias){
    let periodoValido;
    const isOnline = modalidadSelected === 'online';

    const hasMinPeriods = isOnline
      ? this.primeraMatriculacion && this.periodos.length > 1 && contPeriodos.length >= 4
      : this.primeraMatriculacion && selectMaterias.length >= 4;
    
    const hasNoLimit = isOnline
      ? user.carreras.find((carrera) => carrera.modalidad === 'Online')?.no_limite_minimo
      : user.carreras.find((carrera) => carrera.modalidad === 'Presencial')?.no_limite_minimo;
    
    return periodoValido = hasMinPeriods || hasNoLimit || !this.primeraMatriculacion;

  }
  
  modalidadManager(fechasInscripcion, carreras){
    return carreras.some((carrera) => carrera.modalidad === 'Online') &&
      carreras.some((carrera) => carrera.modalidad === 'Presencial') &&
      fechasInscripcion.ol.fecha_inicio_insc != null &&
      fechasInscripcion.ol.fecha_inicio_insc != '' &&
      fechasInscripcion.pr.fecha_inicio_insc != null &&
      fechasInscripcion.pr.fecha_inicio_insc != ''
        ? 'mixta'
        : carreras.some((carrera) => carrera.modalidad === 'Online') &&
          carreras.some(
            (carrera) => carrera.modalidad === 'Presencial'
          ) &&
          fechasInscripcion.ol.fecha_inicio_insc !== null &&
          fechasInscripcion.ol.fecha_inicio_insc !== '' &&
          (fechasInscripcion.pr.fecha_inicio_insc === null ||
            fechasInscripcion.pr.fecha_inicio_insc === '')
        ? 'online'
        : carreras.some((carrera) => carrera.modalidad === 'Online') &&
          carreras.some(
            (carrera) => carrera.modalidad === 'Presencial'
          ) &&
          fechasInscripcion.pr.fecha_inicio_insc !== null &&
          fechasInscripcion.pr.fecha_inicio_insc !== '' &&
          (fechasInscripcion.ol.fecha_inicio_insc === null ||
            fechasInscripcion.ol.fecha_inicio_insc === '')
        ? 'presencial'
        : carreras[0].modalidad.toLowerCase();
  }



   getMixtaDates(fechasInscripcion, modalidadSelected, today) {
      if (modalidadSelected === 'online') {
        this.dateEnd = fechasInscripcion.ol.fecha_fin_insc
          ? moment(
              fechasInscripcion.ol.fecha_fin_insc + ' 23:50',
              'YYYY-MM-DD HH:mm'
            )
          : null;
        this.dateInit = fechasInscripcion.ol.fecha_inicio_insc
          ? moment(
              fechasInscripcion.ol.fecha_inicio_insc + ' 00:00',
              'YYYY-MM-DD HH:mm'
            )
          : null;
        this.timeLeft =
          this.dateInit != null && this.dateEnd != null
            ? this.dateInit.valueOf() / 1000 -
                today.valueOf() / 1000 >
              86400
              ? this.dateInit.valueOf() / 1000 -
                today.valueOf() / 1000 -
                86400
              : this.dateInit.valueOf() / 1000 -
                today.valueOf() / 1000
            : 0;
      } else {
        this.dateEnd = fechasInscripcion.pr.fecha_fin_insc
          ? moment(
              fechasInscripcion.pr.fecha_fin_insc + ' 23:59',
              'YYYY-MM-DD HH:mm'
            )
          : null;
        this.dateInit = fechasInscripcion.pr.fecha_inicio_insc
          ? moment(
              fechasInscripcion.pr.fecha_inicio_insc + ' 00:00',
              'YYYY-MM-DD HH:mm'
            )
          : null;
        this.timeLeft =
          this.dateInit != null && this.dateEnd != null
            ? this.dateInit.valueOf() / 1000 -
                today.valueOf() / 1000 >
              86400
              ? this.dateInit.valueOf() / 1000 -
                today.valueOf() / 1000 -
                86400
              : this.dateInit.valueOf() / 1000 -
                today.valueOf() / 1000
            : 0;
    }
  }

  checkPeriodosArr( periodoSelected, allPeriodos, periodosWithInscriptions) {
    allPeriodos.push(...periodosWithInscriptions);
    return periodoSelected.every(element => allPeriodos.includes(element));
  }

   getPresencialDates(fechasInscripcion, today) {
    const fechaFinInsc = fechasInscripcion.pr.fecha_fin_insc;
    const fechaInicioInsc = fechasInscripcion.pr.fecha_inicio_insc;
  
    this.dateEnd = fechaFinInsc
      ? moment(fechaFinInsc + ' 23:59', 'YYYY-MM-DD HH:mm')
      : null;
  
    this.dateInit = fechaInicioInsc
      ? moment(fechaInicioInsc + ' 00:00', 'YYYY-MM-DD HH:mm')
      : null;
  
    this.timeLeft =
      this.dateInit != null && this.dateEnd != null
        ? this.dateInit.valueOf() / 1000 - today.valueOf() / 1000 > 86400
          ? this.dateInit.valueOf() / 1000 - today.valueOf() / 1000 - 86400
          : this.dateInit.valueOf() / 1000 - today.valueOf() / 1000
        : 0;
  }

   getOnlineDates(fechasInscripcion, today) {
    const fechaFinInsc = fechasInscripcion.ol.fecha_fin_insc;
    const fechaInicioInsc = fechasInscripcion.ol.fecha_inicio_insc;
  
     this.dateEnd = fechaFinInsc
      ? moment(fechaFinInsc + ' 23:50', 'YYYY-MM-DD HH:mm')
      : null;
  
     this.dateInit = fechaInicioInsc
      ? moment(fechaInicioInsc + ' 00:00', 'YYYY-MM-DD HH:mm')
      : null;
  
     this.timeLeft =
      this.dateInit != null && this.dateEnd != null
        ? this.dateInit.valueOf() / 1000 - today.valueOf() / 1000 > 86400
          ? this.dateInit.valueOf() / 1000 - today.valueOf() / 1000 - 86400
          : this.dateInit.valueOf() / 1000 - today.valueOf() / 1000
        : 0;
  }
  

   getDefaultDates() {
      this.dateInit = null
      this.dateEnd = null
      this.timeLeft = 0
    }

    procesarAsignaturas(filteredCarreras: any[]): void {
      this.resetCredits();
      filteredCarreras.forEach((menuItem) => {
        this.namesCarreras.push(menuItem.carrera.nombre);
        menuItem.asignaturas.forEach((asignatura) => {
          this.procesarAsignatura(asignatura);
        });
  
        if (
          this.primeraMatriculacion &&
          this.periodos.length > 1 &&
          menuItem.asignaturas.some(
            (materia) =>
              materia.periodo_id === this.periodos[0].id &&
              materia.estado_alumno !== 'No Inscripto' &&
              materia.estado_alumno !== 'Cancelado'
          ) &&
          menuItem.asignaturas.some(
            (materia) =>
              materia.periodo_id === this.periodos[1].id &&
              materia.estado_alumno !== 'No Inscripto' &&
              materia.estado_alumno !== 'Cancelado'
          )
        ) {
          this.primeraMatriculacion = false;
        }
      });
    }
  
    private procesarAsignatura(asignatura: any): void {
      if (!this.periodos.some((item) => item.id === asignatura.periodo_id)) {
        this.periodos.push({
          id: asignatura.periodo_id,
          nombre: asignatura.periodo,
        });
      }
      if(!this.firstCarreerPeriods.some((item) => item.id === asignatura.periodo_id) && asignatura.tipo_credito_id === 1){
        this.firstCarreerPeriods.push({
          id: asignatura.periodo_id
        })
      }
      if (asignatura.tipo_credito_id === 3) {
        this.socialPractice = true;
      }
      if (asignatura.tipo_credito_id === 2) {
        this.professionalPractice = true;
      }
  
      if (asignatura.tipo_credito_id === 10) {
        this.matOnlPre += 1;
      }
  
      if (asignatura.elegir.length > 0) {
        asignatura.elegir.forEach((opcion) => {
          if (opcion.tipo_credito_id === 10) {
            this.matOnlPre += 1;
          }
        });
      }
      if (asignatura.tipo === 'grade_option') {
        this.procesarGradeOption(asignatura);
      }
    }
  
    private procesarGradeOption(asignatura: any): void {
      asignatura.elegir.forEach((option) => {
        if (option.tipo_credito_id === 4) {
          this.gradeOpMaestria = true;
        }
        if (option.tipo_credito_id === 6) {
          this.gradeOpTesis = true;
        }
        if (option.tipo_credito_id === 5) {
          this.gradeOpBusiness = true;
        }
      });
    }

  getEspecialidades(student_carrer_id): Observable<any> {
      const token = this.authService.leerToken();
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
  
      const data = new FormData();
      data.append('student_career_id', student_carrer_id);
  
      return this.http.get(
        this.url +
          '/educat/especialidades?student_career_id=' +
          student_carrer_id,
        { headers }
      );
  }
  private resetCredits(){
    this.namesCarreras = [];
    this.socialPractice = false;
    this.professionalPractice = false;
    this.matOnlPre = 0;
    this.gradeOpMaestria = false;
    this.gradeOpTesis = false;
    this.gradeOpBusiness = false;
    this.primeraMatriculacion = true;
  }
}
