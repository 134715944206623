<div class="chip-container r-bg" *ngIf="status === 'rechazado'">
  <mat-icon class="material-icons-round">cancel</mat-icon>
  Rechazado
</div>

<div class="chip-container i-bg" *ngIf="status === 'en revisión'">
  <mat-icon class="material-icons-round">access_time</mat-icon>
  En revisión
</div>

<div class="chip-container y-bg" *ngIf="status === 'pendiente'">
  <mat-icon class="material-icons-round">access_time</mat-icon>
  Pendiente
</div>

<div class="chip-container g-bg" *ngIf="status === 'aprobado'">
  <mat-icon class="material-icons-round">check</mat-icon>
  Aprobado
</div>
