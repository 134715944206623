<div>
  <h1>¡Bienvenido a nuestro Formulario de Solicitudes!</h1>
  <h4>
    A través de este formulario podrás realizar tus solicitudes de trámites.
    Completa el formulario, envíalo y recibe tu ticket de seguimiento.
  </h4>
  <form *ngIf="options !== null && !error && !loading" class="">
    <div class="row">
      <!-- <div class="col-6">
        <mat-form-field
          style="width: 100%"
          class="example-full-width"
          appearance="outline"
        >
          <mat-label>Tipo de caso</mat-label>
          <mat-select
            (ngModelChange)="getCategory()"
            [formControl]="formulario['controls'].type"
            matNativeControl
            required
          >
            <mat-option
              *ngFor="let type of options.kind_of_cases"
              [value]="type"
              hidden
            >
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div class="col-12">
        <mat-form-field
          style="width: 100%"
          class="example-full-width"
          appearance="outline"
        >
          <mat-label>Categoría</mat-label>
          <!-- <mat-select
            [formControl]="formulario['controls'].category"
            matNativeControl
            [disabled]="formulario.get('type').value === null"
            required
          >
            <mat-option
              *ngFor="let category of this.kindOfCases.categories"
              [value]="category"
            >
              {{ category.name }}
            </mat-option>
          </mat-select> -->
          <input
            type="text"
            placeholder="Elige una o escribe para encontrarla"
            aria-label="Categoria"
            matInput
            required
            [formControl]="formulario.get('category')"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayCategory"
          >
            <mat-option
              *ngFor="let category of filteredCategories | async"
              [value]="category"
            >
              {{ category.name }}
            </mat-option>
          </mat-autocomplete>

          <mat-error *ngIf="formulario.get('category').hasError('required')">
            Este campo es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field
      style="width: 100%"
      class="example-full-width"
      appearance="outline"
    >
      <mat-label>Asunto</mat-label>
      <input
        matInput
        placeholder=""
        value=""
        [formControl]="formulario.get('subject')"
        required
      />
      <mat-error *ngIf="formulario.get('subject').hasError('required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Descripción</mat-label>
      <textarea
        matInput
        #descripcion
        placeholder=""
        [formControl]="formulario.get('description')"
        required
      ></textarea>
      <mat-hint align="end">{{ descripcion.value.length }} / 800</mat-hint>
      <mat-error *ngIf="formulario.get('description').hasError('required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
    <div class="footer">
      <button
        mat-stroked-button
        color="primary"
        style="border: #b31d15 solid 1px !important"
        (click)="newForm()"
      >
        Resetear
      </button>
      &nbsp;&nbsp;
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!formulario.valid || sending === true"
        (click)="sendForm()"
      >
        <span id="send-text">{{
          sending ? "Generando ticket" : "Generar ticket"
        }}</span>
        <div *ngIf="sending" id="loader" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>
    </div>
  </form>
  <div class="charging" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="error" *ngIf="error && !loading">
    <mat-icon class="material-icons-round">update</mat-icon>
    <p>
      No pudimos cargar el formulario, inténtalo de nuevo dentro de unos
      segundos
    </p>
    <button mat-flat-button color="primary" (click)="getSelects()">
      Volver a cargar
    </button>
  </div>
</div>
