export const adenuCareers = [
  {
    name: 'Licenciatura en Administración de Empresas',
    modes: ['Presencial', 'Online'],
  },
  {
    name: 'Ingeniería Comercial',
    modes: ['Presencial', 'Online'],
  },
  {
    name: 'Licenciatura en Ingeniería Industrial',
    modes: ['Presencial', 'Online'],
  },
  {
    name: 'Licenciatura en Logística y Comercio Internacional',
    modes: ['Presencial', 'Online'],
  },
  {
    name: 'Licenciatura en Marketing y Comunicación Digital',
    modes: ['Presencial', 'Online'],
  },
  {
    name: 'Licenciatura en Business Analytics',
    modes: ['Presencial', 'Online'],
  },
  {
    name: 'Licenciatura en Negocios Digitales, Emprendimiento e Innovación',
    modes: ['Presencial', 'Online'],
  },
  {
    name: 'Licenciatura en Dirección de Finanzas',
    modes: ['Presencial', 'Online'],
  },
  {
    name: 'Licenciatura en Recursos Humanos y Desarrollo Organizacional',
    modes: ['Presencial', 'Online'],
  },
];
