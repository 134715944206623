import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/tooltip';

export interface DialogData {
  link: string;
  nro_pago: number;
}

export const copyTooltip: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-payment-link-dialog',
  templateUrl: './payment-link-dialog.component.html',
  styleUrls: ['./payment-link-dialog.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: copyTooltip }],
})
export class PaymentLinkDialogComponent {
  public disabledTooltip = true;
  constructor(
    public dialogRef: MatDialogRef<PaymentLinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  goToPay(): void {
    window.open(this.data.link, '_blank');
    this.onClose();
  }

  copyLink(): void {
    this._snackBar.open('¡Enlace copiado!', 'Cerrar', {
      duration: 3000,
    });
    this.disabledTooltip = false;
  }
}
