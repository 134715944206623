import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

export interface DialogData {
  course: any;
}

@Component({
  selector: 'app-course-summary-dialog',
  templateUrl: './course-summary-dialog.component.html',
  styleUrls: [
    './course-summary-dialog.component.scss',
  ],
})
export class CourseSummaryDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CourseSummaryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
