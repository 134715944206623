import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadVideos } from 'src/app/redux/actions/videos.action';
import { IMicrocontentsRes, IVideo } from 'src/app/shared/interfaces/IVideo';
import { LibraryService } from 'src/app/shared/services/library.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  videosList: Array<IVideo> = [];
  favList: Array<IVideo> = [];
  secondCategoryList: Array<IVideo> = [];
  isLoading = false;
  isError = false;
  public searchValue = '';

  constructor(
    private libraryService: LibraryService,
    private store: Store<any>,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.checkVideos();
  }

  search(evt) {
    evt.preventDefault();
  }

  cleanSearch(): void {
    this.searchValue = '';
  }

  getFavoriteList(){
    this.favList = this.videosList.filter(video => video.favorito === true);
  }

  getSecondCategoryList(){
    this.secondCategoryList = this.videosList.filter(video => video.categoria != 1);
  }

  goToVideo = (videoId: string) => {
    this.router.navigateByUrl('library/' + videoId);
  }

  checkVideos(){
    this.store.subscribe(data => {
      if(data.library.videosList.length){
        this.videosList = data.library.videosList;
        this.getFavoriteList();
        this.getSecondCategoryList();
      }else{
        this.getAllVideos();
      }
    })
  }
  
  getAllVideos(){
    this.isLoading = true;
    this.libraryService.getLibraryVideos().subscribe({
      next:(data: IMicrocontentsRes)=>{
        this.store.dispatch(new LoadVideos(data.response_data));
        this.isLoading = false;
      },
      error:(err)=>{
        this.isLoading = false;
        this.isError = true;
      }
    })
  }

}