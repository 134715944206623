import * as reduxCountries from '../actions/countries.action';

export interface CountriesState {
  countries: object;
}

export const initialcountriesState = null;

export function CountriesReducer(
  countriesState: CountriesState = initialcountriesState,
  action: reduxCountries.CountriesActions
): CountriesState {
  // console.log(countriesState)
  switch (action.type) {
    case reduxCountries.GET_COUNTRIES:
      return { ...action.payload };

    default:
      break;
  }
  return countriesState;
}
