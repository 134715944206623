<div class="card-advisor main-shadow main-radius">
  <div class="content-image">
    <div class="image">
      <img
        src="{{ (academic$ | async)?.foto ? (academic$ | async)?.foto : '' }}"
        alt=""
      />
    </div>
  </div>
  <div class="data">
    <div class="text-body">
      <div class="title">
        {{
          (academic$ | async)?.nombre
            ? (academic$ | async)?.nombre
            : "No posee datos"
        }}
      </div>
      <div class="subtitle">TITULO PROFESIONAL</div>
    </div>
    <div class="button-container">
      <button
        [ngClass]="{
          'social-button':
            (academic$ | async)?.celular || (academic$ | async)?.celular !== 0,
          'disabled-button':
            !(academic$ | async)?.celular || (academic$ | async)?.celular === 0
        }"
        (click)="sendWhatsapp()"
        [disabled]="
          !(academic$ | async)?.celular || (academic$ | async)?.celular === 0
        "
      >
        <mat-icon class="material-icons-round">whatsapp</mat-icon>
        <div class="text">
          {{
            (academic$ | async)?.celular
              ? (academic$ | async)?.celular
              : "No posee datos"
          }}
        </div>
        <mat-icon class="material-icons-round" id="arrow"
          >arrow_forward</mat-icon
        >
      </button>
      <button
        [ngClass]="{
          'social-button':
            (academic$ | async)?.correo || (academic$ | async)?.correo !== '',
          'disabled-button':
            !(academic$ | async)?.correo || (academic$ | async)?.correo === ''
        }"
        [disabled]="
          !(academic$ | async)?.correo || (academic$ | async)?.correo === ''
        "
        (click)="sendMail()"
      >
        <mat-icon class="material-icons-round">alternate_email</mat-icon>
        <div class="text">
          {{
            (academic$ | async)?.correo
              ? (academic$ | async)?.correo
              : "No posee datos"
          }}
        </div>
        <mat-icon class="material-icons-round" id="arrow"
          >arrow_forward</mat-icon
        >
      </button>
      <!-- 
      <div class="social-button">
        <mat-icon class="fab fa-linkedin icon-social fa-xl"></mat-icon>
        <div class="text">
          {{
            (academic$ | async)?.linkedin
              ? (academic$ | async)?.linkedin
              : "No posee datos"
          }}
        </div>
        <mat-icon class="material-icons-round" id="arrow"
          >arrow_forward</mat-icon
        >
      </div> -->
    </div>
  </div>
</div>
