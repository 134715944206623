import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class EventosService {
    
    url = environment.baseUrl;

    constructor(
        private http: HttpClient,
        private authService: AuthService) {}

    getEventos(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.get(this.url + '/v1/acropolis/events?is_adenupa=true', {
            headers,
        });
    }

    registerEvento(data): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.post(
            this.url + '/v1/acropolis/events/register-event',
            data,
            {
                headers,
            }
        );
    }
}


