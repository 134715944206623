<div class="card-container main-border main-radius shadow-200">
  <div class="card-content">
    <div class="header">
      <div class="product-title">
        <span *ngIf="!actions">{{ product.quantity }} x </span>{{ product.name }} 
      </div>
      <div class="price">
        <div class="unit">US$</div>
        <div class="number">{{ fixTotal() }}</div>
      </div>
    </div>
  </div>
  <div class="card-actions" *ngIf="actions">
    <div class="counter">
      <button
        mat-icon-button
        color="accent"
        aria-label="Remover unidades"
        [disabled]="product.quantity <= 1"
        (click)="onRemove()"
      >
        <mat-icon class="material-icons-round">remove</mat-icon>
      </button>
      <div>{{ product.quantity }}</div>
      <button
        mat-icon-button
        color="accent"
        aria-label="Añadir unidades"
        [disabled]="product.quantity >= limitThisProduct"
        (click)="onAdd()"
      >
        <mat-icon class="material-icons-round">add</mat-icon>
      </button>
    </div>
    <button
      mat-icon-button
      color="accent"
      aria-label="Eliminar"
      (click)="onDelete()"
    >
      <mat-icon class="material-icons-round">delete</mat-icon>
    </button>
  </div>
</div>
