<div class="backdrop" id="backdrop" (click)="activateTab()"></div>
<div class="alert shake" id="alert">
  <div class="tab" id="tab" (click)="activateTab()">
    CUENTA BLOQUEADA
    <button mat-icon-button class="close-btn">
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
  <div class="details" id="details">
    <div class="description">
      <img src="../../../../assets/images/content/bloqueo.png" alt="bloqueo" />
      ¡Tu cuenta ha sido bloqueada!
      <div class="first">
        Ponte en contacto con tu
        <a href="/academic-advisor">Academic Advisor</a> para regularizar tu
        situación.
      </div>
      <div class="second">
        <strong>Mientras tu cuenta se encuentre bloqueada, no podrás:</strong>
        <div>• Acceder a tu cursado en Canvas</div>
        <div>
          • Acceder al proceso de Matriculación de las carreras afectadas
        </div>
      </div>
    </div>
    <ng-container *ngFor="let item of estadoBloqueos">
      <div class="item-bloqueo">
        <div class="motivo">
          <strong>Motivo:</strong>
          <div class="chip">{{ item.estado_bloqueo }}</div>
        </div>
        <div class="motivo">
          <strong>Carrera:</strong>
          <div class="carrera">{{ item.carrera }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
