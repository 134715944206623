import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  FormGroupDirective,
  NgForm,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { SendFormService } from 'src/app/shared/services/send-form.service';
import Swal from 'sweetalert2';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-creditos',
  templateUrl: './creditos.component.html',
  styleUrls: ['./creditos.component.scss'],
})
export class CreditosComponent implements OnInit {
  formulario: UntypedFormGroup;

  carrera = [
    { nombre: 'Lic. Administración de empresas' },
    { nombre: 'Lic. Marketing y Comunicación Digital' },
    { nombre: 'Lic. Comercio y logística internacional' },
    { nombre: 'Lic. Dirección de Proyecto' },
    { nombre: 'Lic. Ingeniería Industrial' },
    { nombre: 'Lic. Ingeniería Comercial' },
    { nombre: 'Lic. Business Analytics' },
    { nombre: 'Otro: ' },
  ];

  ingreso = [];

  matcher = new MyErrorStateMatcher();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private alumnoService: AlumnoService,
    private sendFormService: SendFormService
  ) {}

  ngOnInit(): void {
    this.formulario = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      correoElectronico: ['', [Validators.required, Validators.email]],
      ingreso: ['', Validators.required],
      carrera: ['', Validators.required],
      carreraOtro: [''],
      creditoOfic: ['', Validators.required],
    });
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    const hoy = new Date().getFullYear();
    for (let index = hoy; index >= hoy - 10; index--) {
      this.ingreso.push(index.toString());
    }
    this.alumnoService.getAlumno().subscribe((res) => {
      this.formulario = this.formBuilder.group({
        nombre: [res.nombre, Validators.required],
        apellido: [res.apellido, Validators.required],
        correoElectronico: [res.email, [Validators.required, Validators.email]],
        ingreso: ['', Validators.required],
        carrera: ['', Validators.required],
        carreraOtro: [''],
        creditoOfic: ['', Validators.required],
      });
    });
  }

  enviarFormulario(): void {
    let carrera: string;
    if (this.formulario.controls.nombre.value !== 'Otro: ') {
      carrera = this.formulario.controls.nombre.value;
    } else {
      carrera = this.formulario.controls.nombreOtro.value;
    }
    this.enviarMail(carrera);
  }

  enviarMail(carrera: string): void {
    const data = {
      tipoFormulario: 'creditos',
      nombre: this.formulario.value.nombre,
      apellido: this.formulario.value.apellido,
      correo: this.formulario.value.correoElectronico,
      anioIngreso: this.formulario.value.ingreso,
      carrera,
      oficial: this.formulario.value.creditoOfic,
    };
    this.sendFormService.enviarSolicitud(data).subscribe({
      next: (res) => {
        this.alertService.openAlert(
          '¡Perfecto!',
          'La solicitud fue enviada con éxito',
          'Ok'
        );
        this.formulario.reset();
      },
      error: (error) => {
        this.alertService.openAlert(
          '¡Ups!',
          'Ha ocurrido un error, por favor intenta de nuevo más tarde',
          'Ok'
        );
        console.log(error);
      },
    });
  }

  // imprimir() {
  //   console.log('this.formulario :', this.formulario);
  // }
}
