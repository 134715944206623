import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-note-card',
  templateUrl: './info-note-card.component.html',
  styleUrls: ['./info-note-card.component.scss'],
})
export class InfoNoteCardComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() color: string;

  constructor() {}
}
