<div class="wrapper" [routerLink]="['./']" [fragment]="link">
  <div class="text-body">
    <h4>Opción {{ index }}</h4>
    <h2>{{ name }}</h2>
    <p>{{ description }}</p>
  </div>

  <div class="arrow">
    <mat-icon class="material-icons-rounded">arrow_forward</mat-icon>
  </div>
</div>
