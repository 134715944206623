<div>
  <div>
    <div class="dialog-header">
      <div class="title">{{ this.data.title }}</div>
      <button
        class="close"
        mat-icon-button
        aria-label="Close"
        (click)="onClose()"
      >
        <mat-icon class="material-icons-round">close</mat-icon>
      </button>
    </div>
    <div class="dialog-container">
      <div mat-dialog-content>
        <app-status-chip [status]="data.status.toLowerCase()"></app-status-chip>
        <div class="description">
          {{ this.data.description }}
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="primary" (click)="onClose()">
      Cerrar
    </button>
    <!-- <input type="file" accept=".jpg, .jpeg, .png, .pdf" (change)="onFileSelected($event)" #fileUpload hidden /> -->
    <button
      mat-flat-button
      color="primary"
      *ngIf="data.update"
      (click)="onFileSelected()"
    >
      Actualizar documento
    </button>
  </div>
</div>
