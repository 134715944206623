<app-image-header
  [logo]="'/images/content/adenpolis/logo-adenpolis-color.png'"
  [title]="configs ? 'Postulaciones ' + configs.year : 'Postulaciones'"
  [description]="
    '¿Crees que tienes lo necesario para formar parte del comité y ser el líder de tu carrera? Chequea los requisitos, completa el formulario y postúlate.'
  "
  [imgURL]="'/images/content/adenpolis/header-adenpolis-postulacion.png'"
  [titleColor]="'#b31d15'"
></app-image-header>
<section id="postulaciones">
  <div class="container">
    <div class="grid-container">
      <div class="card-item">
        <div id="requisitos" class="content">
          <h2>Requisitos</h2>
          <ul>
            <li>
              Tener matrícula vigente en un programa académico de pregrado.
            </li>
            <li>
              No haber sido objeto de sanción disciplinaria de acuerdo con los
              reglamentos de la institución
            </li>
            <li>
              Ser alumno regular con todos los procesos de inscripción
              completados y formalizados (legajo completado).
            </li>
            <li>
              Ser un alumno que busque el bien común, esté dispuesto a innovar y
              brindar su apoyo al estudiantado y a sus pares.
            </li>
            <li>
              Formar parte del comité por un mínimo de 6 meses e informar con 1
              mes de antelación de su partida
            </li>
          </ul>
          <h2>Se tendrá en cuenta</h2>
          <ul>
            <li>
              Alumnos proactivos con gran sentido del liderazgo, con excelentes
              competencias comunicativas y colaborativas.
            </li>
            <li>Responsables y comprometidos a su labor.</li>
            <li>
              Respetuosos y profesionales, que cuenten con grandes habilidades
              organizacionales y buen comportamiento.
            </li>
          </ul>
        </div>
      </div>

      <div
        id="postulation-form"
        class="card-item"
        *ngIf="currentPostulation && !applied"
      >
        <div class="content">
          <h2>¡Completa el formulario!</h2>
          <form [formGroup]="mainForm">
            <label>Datos personales</label>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Nombre completo</mat-label>
              <input
                matInput
                placeholder="Jane Doe"
                formControlName="full_name"
                required
              />
              <mat-error
                *ngIf="this.mainForm.get('full_name').hasError('required')"
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <div class="flex-row">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>E-mail</mat-label>
                <input
                  matInput
                  placeholder="example@example.com"
                  formControlName="email"
                  required
                />
                <mat-error
                  *ngIf="this.mainForm.get('email').hasError('required')"
                  >Este campo es requerido</mat-error
                >
                <mat-error *ngIf="this.mainForm.get('email').hasError('email')"
                  >La dirección de email ingresada es inválida</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Móvil</mat-label>
                <lib-ngx-mat-tel-input formControlName="phone" required>
                </lib-ngx-mat-tel-input>
                <mat-error
                  *ngIf="this.mainForm.get('phone').hasError('required')"
                >
                  Este campo es <strong>requerido</strong>
                </mat-error>
                <mat-error
                  *ngIf="this.mainForm.get('phone').hasError('format')"
                >
                  El formato del número es <strong>invalido</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex-row">
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Carrera</mat-label>
                <input matInput formControlName="career" required />
                <mat-error
                  *ngIf="this.mainForm.get('career').hasError('required')"
                  >Este campo es requerido</mat-error
                >
              </mat-form-field>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Modalidad</mat-label>
                <input matInput formControlName="modality" required />
                <mat-error
                  *ngIf="this.mainForm.get('modality').hasError('required')"
                  >Este campo es requerido</mat-error
                >
              </mat-form-field>
            </div>

            <div>
              <label>Foto de perfil</label>
              <div class="file-input mt-2">
                <button
                  mat-flat-button
                  color="primary"
                  (click)="fileInput.click()"
                >
                  Seleccionar
                </button>
                <p>
                  {{
                    fileInput.files[0]
                      ? fileInput.files[0].name
                      : "Elige un archivo"
                  }}
                </p>
              </div>
              <input
                hidden
                [formControl]="avatarCtrl"
                (change)="onFileChange($event)"
                #fileInput
                type="file"
                id="file"
                accept="image/png, image/jpeg, image/jpg"
              />
              <mat-hint
                >Formato aceptado: jpg, jpeg, png, Tamaño máximo 1MB</mat-hint
              >
              <mat-error *ngIf="avatarCtrl.hasError('fileMaxSize')"
                >El tamaño máximo es de 1MB, ¡elige un archivo más
                chico!</mat-error
              >
              <mat-error *ngIf="avatarCtrl.hasError('invalidExtension')"
                >Extensión de archivo inválida, solo se permite: jpg, jpeg o
                png</mat-error
              >
            </div>
            <div class="mt-3">
              <label>¿Por qué deseas formar parte del comité?</label>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label
                  >Describe qué es lo que te motiva a postularte</mat-label
                >
                <textarea
                  matInput
                  #reasons_to_apply
                  formControlName="reasons_to_apply"
                  maxlength="250"
                  required
                ></textarea>
                <mat-hint align="end"
                  >{{ reasons_to_apply.value?.length || 0 }}/250</mat-hint
                >
                <mat-error
                  *ngIf="
                    this.mainForm.get('reasons_to_apply').hasError('required')
                  "
                  >Este campo es requerido</mat-error
                >
              </mat-form-field>
            </div>
            <button
              mat-flat-button
              color="primary"
              [disabled]="mainForm.invalid || sendingPostulation"
              (click)="sendPostulation()"
            >
              <div class="submit-button">
                {{ sendingPostulation ? "Enviando" : "Enviar postulación" }}
                <div class="small-loader" *ngIf="sendingPostulation"></div>
              </div>
            </button>
          </form>
        </div>
      </div>
      <div
        id="no-postulation"
        class="notfound-container main-shadow"
        *ngIf="!currentPostulation && !endedPostulation && configs && !applied"
      >
        <img
          src="../../../../assets/images/content/adenpolis/time-elecciones.svg"
          alt=""
        />
        <h2>¡Todavía no inician las postulaciones!</h2>
        <p>
          Podrás postularte desde el
          {{ moment(configs.inicio_postulaciones).locale("es").format("LL") }}
          hasta el
          {{ moment(configs.fin_postulaciones).locale("es").format("LL") }}
        </p>
        <div class="button-container">
          <button
            mat-flat-button
            *ngIf="!isPastEvent"
            color="primary"
            aria-label="añadir a mi calendario"
            matTooltip="Añadir a mi calendario"
            [matMenuTriggerFor]="menu"
            (click)="getCalendarEvent()"
          >
            Añadir a mi calendario
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let item of links">
              <a mat-menu-item [href]="item.url" target="_blank">{{
                item.name
              }}</a>
            </ng-container>
          </mat-menu>
        </div>
      </div>
      <div
        id="no-postulation"
        class="notfound-container main-shadow"
        *ngIf="((endedPostulation && configs) || !configs) && !applied"
      >
        <img
          src="../../../../assets/images/content/adenpolis/time-elecciones.svg"
          alt=""
        />
        <h2>¡Postulaciones cerradas!</h2>
        <p *ngIf="configs">
          El último proceso de postulación cerró el día
          {{ moment(configs.fin_postulaciones).locale("es").format("LL") }}
        </p>
        <p *ngIf="!configs">
          No hay un proceso de postulación habilitado por el momento
        </p>
      </div>
      <div
        id="already-postulated"
        class="notfound-container main-shadow"
        *ngIf="applied && configs"
      >
        <img
          src="../../../../assets/images/content/adenpolis/candidato.svg"
          alt=""
        />
        <h2>¡Ya te postulaste!</h2>
        <p>
          Todas las postulaciones pasarán por un proceso de evaluación de
          requisitos para saber si puedes o no ser candidato. Cuando este
          proceso termine, los candidatos serán publicados en la sección
          <a routerLink="/comite-estudiantil/elecciones"
            >Candidatos y Elecciones</a
          >
        </p>
      </div>
    </div>
  </div>
</section>
