<div class="card-aden">
  <h2 class="title">
    <strong> Formato de Solicitud de Certificaciones Académicas ADENU</strong>
  </h2>

  <h4 class="text-muted">
    Diligenciar correctamente todos los campos para que se realice
  </h4>

  <div class="ml-3 mt-5">
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>A quién esta dirigida esta carta </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].destinatario"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="formulario['controls'].destinatario.hasError('required')"
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Nombre completo</label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].nombre"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="formulario['controls'].nombre.hasError('required')"
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
      <!-- <div class="col">
        <div class="row">
          <label>Apellidos </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].apellido"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="formulario['controls'].apellido.hasError('required')"
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div> -->
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Correo electronico </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].correoElectronico"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="
                  formulario['controls'].correoElectronico.hasError('email') &&
                  !formulario['controls'].correoElectronico.hasError('required')
                "
              >
                Por favor ingrese un correo electronico válido
              </mat-error>
              <mat-error
                *ngIf="
                  formulario['controls'].correoElectronico.hasError('required')
                "
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Año de ingreso </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <mat-label>Elige</mat-label>
              <mat-select [formControl]="formulario['controls'].ingreso">
                <mat-option *ngFor="let i of ingreso" [value]="i">
                  {{ i }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Seleccione carrera que cursa </label>
        </div>
        <div class="row ml-3">
          <form class="example-form">
            <section class="example-section">
              <mat-radio-group [formControl]="formulario['controls'].carrera">
                <div class="row" *ngFor="let carrera of carrera">
                  <mat-radio-button
                    class="example-margin"
                    [value]="carrera.nombre"
                    >{{ carrera.nombre }}</mat-radio-button
                  >
                  <mat-form-field
                    *ngIf="carrera.nombre === 'Otro: '"
                    class="input-mod ml-2"
                  >
                    <input
                      [required]="
                        formulario['controls'].carrera.value === 'Otro: '
                      "
                      matInput
                      [formControl]="formulario['controls'].carreraOtro"
                      [errorStateMatcher]="matcher"
                    />
                    <mat-error
                      *ngIf="
                        formulario['controls'].carreraOtro.hasError('required')
                      "
                    >
                      Campo <strong>obligatorio</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </mat-radio-group>
            </section>
          </form>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Motivo de la solicitud </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].motivo"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="formulario['controls'].motivo.hasError('required')"
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
              <!-- <mat-label>Tu respuesta</mat-label>
                                    <textarea matInput
                                              cdkTextareaAutosize
                                              #autosize="cdkTextareaAutosize"
                                              cdkAutosizeMinRows="1"
                                              cdkAutosizeMaxRows="5"
                                              [formControl]="formulario['controls'].motivo"
                                              [errorStateMatcher]="matcher">
                                    </textarea>
                                    <mat-error *ngIf="formulario['controls'].motivo.hasError('required')">
                                        Esta pregunta es <strong>obligatoria</strong>
                                    </mat-error> -->
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button
      [disabled]="formulario.status === 'INVALID'"
      mat-button
      class="bg-primary-aden text-white"
      (click)="enviarFormulario()"
    >
      Enviar
    </button>
  </div>
</div>
