<!-- FICHA DE INSCRIPCIÓN ALUMNO SLIENTE -->

<div class="file-container" *ngIf="userType === 'alumno-saliente'">
  <div class="user">
    <div class="circle-img">
      <img
        src="{{
          userImage
            ? userImage
            : '../../../../../../../assets/images/logos/avatar.png'
        }}"
        alt=""
      />
    </div>
    <div class="data">
      <div class="name">
        {{ this.formData.personal_data.name }}
        {{ this.formData.personal_data.last_name }}
      </div>
    </div>
  </div>
  <div class="section-header">Datos personales</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Nombre</div>
      <div class="data">{{ this.formData.personal_data.name }}</div>
    </div>
    <div class="data-item">
      <div class="header">Apellido</div>
      <div class="data">{{ this.formData.personal_data.last_name }}</div>
    </div>
    <div class="data-item">
      <div class="header">Fecha de nacimiento</div>
      <div class="data">{{ this.formData.personal_data.date_of_birth }}</div>
    </div>
    <div class="data-item">
      <div class="header">Nacionalidad</div>
      <div class="data">{{ this.formData.personal_data.nationality }}</div>
    </div>
    <div class="data-item">
      <div class="header">Pasaporte</div>
      <div class="data">{{ this.formData.personal_data.passport }}</div>
    </div>
    <div class="data-item">
      <div class="header">Fecha de vencimiento Pasaporte</div>
      <div class="data">
        {{ this.formData.personal_data.passport_expiration }}
      </div>
    </div>
  </div>
  <div class="section-header">Datos de contacto</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">E-mail</div>
      <div class="data">{{ this.formData.contact.email }}</div>
    </div>
    <div class="data-item">
      <div class="header">Teléfono</div>
      <div class="data">{{ this.formData.contact.phone }}</div>
    </div>
    <div class="data-item">
      <div class="header">País</div>
      <div class="data">{{ this.formData.address.country }}</div>
    </div>
    <div class="data-item">
      <div class="header">Localidad</div>
      <div class="data">{{ this.formData.address.location }}</div>
    </div>
    <div class="data-item">
      <div class="header">Domicilio Actual</div>
      <div class="data">{{ this.formData.address.current_address }}</div>
    </div>
  </div>
  <div class="section-header">Contacto de emergencia</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Nombre</div>
      <div class="data">{{ this.formData.emergency_contact.full_name }}</div>
    </div>
    <div class="data-item">
      <div class="header">E-mail</div>
      <div class="data">{{ this.formData.emergency_contact.email }}</div>
    </div>
    <div class="data-item">
      <div class="header">Teléfono</div>
      <div class="data">{{ this.formData.emergency_contact.phone }}</div>
    </div>
    <div class="data-item">
      <div class="header">Parentesco</div>
      <div class="data">{{ this.formData.emergency_contact.relationship }}</div>
    </div>
  </div>

  <div class="section-header">Datos académicos</div>
  <div class="section-subheader">Tu carrera en ADENU</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Carrera</div>
      <div class="data">
        {{ this.formData.academic_data.career }}
      </div>
    </div>
    <div class="data-item">
      <div class="header">Año</div>
      <div class="data">
        {{ this.formData.academic_data.year }}
      </div>
    </div>
  </div>

  <div class="section-subheader">Primera selección</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Universidad de destino</div>
      <div class="data">
        {{ this.formData.academic_data.first_university.name }}
      </div>
    </div>
  </div>

  <div class="section-subheader">Segunda selección</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Universidad de destino</div>
      <div class="data">
        {{ this.formData.academic_data.second_university.name }}
      </div>
    </div>
  </div>

  <div class="section-header">Idiomas</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">¿Posee conocimientos en lenguas extranjeras?</div>
      <div class="data">
        {{ this.formData.languajes.languaje ? "Si" : "No" }}
      </div>
    </div>
  </div>

  <ng-container
    *ngFor="let item of this.formData.languajes.list; let i = index"
  >
    <div class="section-subheader">Idioma {{ i + 1 }}</div>
    <div class="items-row">
      <div class="data-item">
        <div class="header">Idioma</div>
        <div class="data">{{ item.name }}</div>
      </div>
      <div class="data-item">
        <div class="header">Nivel</div>
        <div class="data">{{ item.level }}</div>
      </div>
      <div class="data-item">
        <div class="header">Certificado Nacional</div>
        <div class="data">{{ item.national_certificate ? "Si" : "No" }}</div>
      </div>
      <div class="data-item">
        <div class="header">Certificado Internacional</div>
        <div class="data">
          {{ item.international_certificate ? "Si" : "No" }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="section-header">Documentación</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Archivos cargados</div>
      <div class="data">
        {{ this.filesArray.length }}
      </div>
    </div>
  </div>
</div>

<!-- FICHA DE INSCRIPCIÓN ALUMNO ENTRANTE -->

<div class="file-container" *ngIf="userType === 'alumno-entrante'">
  <div class="user">
    <div class="circle-img">
      <img src="../../../../../../../assets/images/logos/avatar.png" alt="" />
    </div>
    <div class="data">
      <div class="name">
        {{ this.formData.personal_data.name }}
        {{ this.formData.personal_data.last_name }}
      </div>
    </div>
  </div>
  <div class="section-header">Datos personales</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Nombre</div>
      <div class="data">{{ this.formData.personal_data.name }}</div>
    </div>
    <div class="data-item">
      <div class="header">Apellido</div>
      <div class="data">{{ this.formData.personal_data.last_name }}</div>
    </div>
    <div class="data-item">
      <div class="header">Fecha de nacimiento</div>
      <div class="data">{{ this.formData.personal_data.date_of_birth }}</div>
    </div>
    <div class="data-item">
      <div class="header">Nacionalidad</div>
      <div class="data">{{ this.formData.personal_data.nationality }}</div>
    </div>
    <div class="data-item">
      <div class="header">Pasaporte</div>
      <div class="data">{{ this.formData.personal_data.passport }}</div>
    </div>
    <div class="data-item">
      <div class="header">Fecha de vencimiento Pasaporte</div>
      <div class="data">
        {{ this.formData.personal_data.passport_expiration }}
      </div>
    </div>
  </div>
  <div class="section-header">Datos de contacto</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">E-mail</div>
      <div class="data">{{ this.formData.contact.email }}</div>
    </div>
    <div class="data-item">
      <div class="header">Teléfono</div>
      <div class="data">{{ this.formData.contact.phone }}</div>
    </div>
    <div class="data-item">
      <div class="header">País</div>
      <div class="data">{{ this.formData.address.country }}</div>
    </div>
    <div class="data-item">
      <div class="header">Localidad</div>
      <div class="data">{{ this.formData.address.location }}</div>
    </div>
    <div class="data-item">
      <div class="header">Domicilio Actual</div>
      <div class="data">{{ this.formData.address.current_address }}</div>
    </div>
  </div>
  <div class="section-header">Contacto de emergencia</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Nombre</div>
      <div class="data">{{ this.formData.emergency_contact.full_name }}</div>
    </div>
    <div class="data-item">
      <div class="header">E-mail</div>
      <div class="data">{{ this.formData.emergency_contact.email }}</div>
    </div>
    <div class="data-item">
      <div class="header">Teléfono</div>
      <div class="data">{{ this.formData.emergency_contact.phone }}</div>
    </div>
    <div class="data-item">
      <div class="header">Parentesco</div>
      <div class="data">{{ this.formData.emergency_contact.relationship }}</div>
    </div>
  </div>

  <div class="section-header">Datos académicos</div>
  <div class="section-subheader">Carrera que cursas actualmente</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Universidad</div>
      <div class="data">
        {{ this.formData.academic_data.originUniversity }}
      </div>
    </div>
    <div class="data-item">
      <div class="header">Carrera</div>
      <div class="data">
        {{ this.formData.academic_data.career }}
      </div>
    </div>
    <div class="data-item">
      <div class="header">Año</div>
      <div class="data">
        {{ this.formData.academic_data.year }}
      </div>
    </div>
  </div>

  <div class="section-subheader">Carrera que deseas cursar en ADENU</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Carrera</div>
      <div class="data">
        {{ this.formData.academic_data.selectedCareer.name }}
      </div>
    </div>

    <div class="data-item">
      <div class="header">Modalidad</div>
      <div class="data">
        {{ this.formData.academic_data.selectedCareer.mode }}
      </div>
    </div>
  </div>

  <div class="section-header">Idiomas</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">¿Posee conocimientos en lenguas extranjeras?</div>
      <div class="data">
        {{ this.formData.languajes.languaje ? "Si" : "No" }}
      </div>
    </div>
  </div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">
        Deseo realizar el curso de Español Online de ADENU
      </div>
      <div class="data">
        {{ this.formData.languajes.willing_to_learn_spanish ? "Si" : "No" }}
      </div>
    </div>
  </div>

  <ng-container
    *ngFor="let item of this.formData.languajes.list; let i = index"
  >
    <div class="section-subheader">Idioma {{ i + 1 }}</div>
    <div class="items-row">
      <div class="data-item">
        <div class="header">Idioma</div>
        <div class="data">{{ item.name }}</div>
      </div>
      <div class="data-item">
        <div class="header">Nivel</div>
        <div class="data">{{ item.level }}</div>
      </div>
      <div class="data-item">
        <div class="header">Certificado Nacional</div>
        <div class="data">{{ item.national_certificate ? "Si" : "No" }}</div>
      </div>
      <div class="data-item">
        <div class="header">Certificado Internacional</div>
        <div class="data">
          {{ item.international_certificate ? "Si" : "No" }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="section-header">Documentación</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Archivos cargados</div>
      <div class="data">
        {{ this.filesArray.length }}
      </div>
    </div>
  </div>
</div>

<!-- FICHA DE INSCRIPCIÓN DOCENTES -->

<div class="file-container" *ngIf="userType === 'docente'">
  <div class="user">
    <div class="circle-img">
      <img src="../../../../../../../assets/images/logos/avatar.png" alt="" />
    </div>
    <div class="data">
      <div class="name">
        {{ this.formData.personal_data.name }}
        {{ this.formData.personal_data.last_name }}
      </div>
    </div>
  </div>
  <div class="section-header">Datos personales</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Nombre</div>
      <div class="data">{{ this.formData.personal_data.name }}</div>
    </div>
    <div class="data-item">
      <div class="header">Apellido</div>
      <div class="data">{{ this.formData.personal_data.last_name }}</div>
    </div>
    <div class="data-item">
      <div class="header">Fecha de nacimiento</div>
      <div class="data">{{ this.formData.personal_data.date_of_birth }}</div>
    </div>
    <div class="data-item">
      <div class="header">Nacionalidad</div>
      <div class="data">{{ this.formData.personal_data.nationality }}</div>
    </div>
    <div class="data-item">
      <div class="header">Pasaporte</div>
      <div class="data">{{ this.formData.personal_data.passport }}</div>
    </div>
    <div class="data-item">
      <div class="header">Fecha de vencimiento Pasaporte</div>
      <div class="data">
        {{ this.formData.personal_data.passport_expiration }}
      </div>
    </div>
  </div>
  <div class="section-header">Datos de contacto</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">E-mail</div>
      <div class="data">{{ this.formData.contact.email }}</div>
    </div>
    <div class="data-item">
      <div class="header">Teléfono</div>
      <div class="data">{{ this.formData.contact.phone }}</div>
    </div>
    <div class="data-item">
      <div class="header">País</div>
      <div class="data">{{ this.formData.address.country }}</div>
    </div>
    <div class="data-item">
      <div class="header">Localidad</div>
      <div class="data">{{ this.formData.address.location }}</div>
    </div>
    <div class="data-item">
      <div class="header">Domicilio Actual</div>
      <div class="data">{{ this.formData.address.current_address }}</div>
    </div>
  </div>

  <div class="section-header">Datos académicos</div>
  <div class="section-subheader">Formación</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Titulo de grado</div>
      <div class="data">
        {{ this.formData.academic_data.grad_title }}
      </div>
    </div>
    <div class="data-item">
      <div class="header">Área de experiencia</div>
      <div class="data">
        {{ this.formData.academic_data.expertiseArea }}
      </div>
    </div>
    <div class="data-item">
      <div class="header">Especialización</div>
      <div class="data">
        {{ this.formData.academic_data.postgrad_title.specialization }}
      </div>
    </div>
    <div class="data-item">
      <div class="header">Maestria</div>
      <div class="data">
        {{ this.formData.academic_data.postgrad_title.master }}
      </div>
    </div>
    <div class="data-item">
      <div class="header">Doctorado</div>
      <div class="data">
        {{ this.formData.academic_data.postgrad_title.doctorate }}
      </div>
    </div>
  </div>

  <div class="section-subheader">Carrera en la que te desempeñas en ADENU</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Carrera</div>
      <div class="data">
        {{ this.formData.academic_data.dictatedCareer.name }}
      </div>
    </div>
    <div class="data-item">
      <div class="header">Modalidad</div>
      <div class="data">
        {{
          this.formData.academic_data.dictatedCareer.mode === "Ambas"
            ? "Presencial y Online"
            : this.formData.academic_data.dictatedCareer.mode
        }}
      </div>
    </div>
  </div>

  <div class="section-subheader">Asignaturas que dictas en la carrera</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Asignaturas</div>
      <div class="chip-container">
        <ng-container
          *ngFor="let course of this.formData.academic_data.dictatedCourses"
        >
          <div class="chip">
            {{ course.name }} |
            {{ course.mode === "Ambas" ? "Presencial y Online" : course.mode }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="section-subheader">Universidad Destino</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Universidad</div>
      <div class="data">
        {{ this.formData.academic_data.selectedUniversity }}
      </div>
    </div>
  </div>

  <div class="section-header">Documentación</div>
  <div class="items-row">
    <div class="data-item">
      <div class="header">Archivos cargados</div>
      <div class="data">
        {{ this.filesArray.length }}
      </div>
    </div>
  </div>
</div>
