import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserState } from './../../../redux/reducers/user.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CmsService } from './../../../shared/services/cms.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {
  faqsMaestria,
  faqsTesis,
  materiasMaestria,
} from 'src/app/shared/data/graduacion';

@Component({
  selector: 'app-opcion-grado',
  templateUrl: './opcion-grado.component.html',
  styleUrls: ['./opcion-grado.component.scss', '../graduacion.component.scss'],
})
export class OpcionGradoComponent implements OnInit {
  public user$;
  public user;

  public sendingMessage = false;
  messageCtrl = new UntypedFormControl('', Validators.required);
  optionCtrl = new UntypedFormControl('', Validators.required);

  links = [
    { name: 'Descripcion', tag: 'descripcion' },
    { name: 'Tésis', tag: 'tesis' },
    { name: 'Materias de Maestría', tag: 'maestria' },
    { name: 'Consultas', tag: 'consultas' },
  ];

  fragment = null;

  activeLink = this.links[0];

  faqsTesis = faqsTesis;

  faqsMaestria = faqsMaestria;

  materiasMaestria = materiasMaestria;

  constructor(
    private cmsService: CmsService,
    public snackbar: MatSnackBar,
    private userStore: Store<UserState>,
  ) {}

  ngOnInit(): void {
    this.user$ = this.userStore.select('user');
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
      }
    });
  }

  sendMessage(): void {
    if (this.messageCtrl.value !== null || this.messageCtrl.value !== '') {
      this.sendingMessage = true;
      const data = {
        student_name: this.user.nombre,
        student_educat_id: this.user.id_educat,
        student_id: this.user.legajo,
        student_email: this.user.email,
        message: this.messageCtrl.value,
        subject: `Opción de titulación - ${this.optionCtrl.value}`,
        send_to: 'carlos.riquelme@adenuniversity.edu.pa',
      };
      this.cmsService.sendConsult(data).subscribe({
        next: (res) => {
          this.snackbar.open('¡Mensaje enviado con éxito!', 'OK', {
            duration: 2000,
            panelClass: ['success-snackbar'],
          });
          this.sendingMessage = false;
          this.messageCtrl.reset();
        },
        error: (err) => {
          this.snackbar.open(
            'Ocurrió un error, por favor intenta de nuevo más tarde',
            'OK',
            {
              duration: 2000,
              panelClass: ['error-snackbar'],
            }
          );
          this.sendingMessage = false;
        },
      });
    }
  }
}
