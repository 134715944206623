import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { OrderDetailsComponent } from '../components/order-details/order-details.component';
import { DerechosService } from 'src/app/shared/services/derechos.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-ordenes-derechos-pecuniarios',
  templateUrl: './ordenes-derechos-pecuniarios.component.html',
  styleUrls: ['./ordenes-derechos-pecuniarios.component.scss'],
})
export class OrdenesDerechosPecuniariosComponent implements OnInit {
  public searchValue = '';
  public orders = [];

  public loading = false;

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private dercechosService: DerechosService) {}

  ngOnInit(): void {
    this.getOrders();
    this.cancelOrder = this.cancelOrder.bind(this);
    this.openDetailDialog = this.openDetailDialog.bind(this);
  }

  getOrders(): void {
    this.loading = true;
    this.dercechosService.getOrdersDP().subscribe({
      next: (res) => {
        this.orders = res.ordenes_compra;
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  cancelOrder(order_id): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: '¿Estás seguro de cancelar esta orden?',
        description: 'Recuerda que una vez confirmado no podrás volver atrás',
        confirmAction: 'Sí, confirmar',
        cancelAction: 'Cancelar',
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.dercechosService.cancelOrderDP(order_id).subscribe({
          next: (res) => {
            this.alertService.openAlert(
              '¡Orden cancelada con éxito!',
              'La orden fue cancelada con éxito, consulta a tu Academic Advisor para más detalle',
              'Entendido'
            );
            this.orders.map((item) => {
              if (item.id === order_id) {
                item.estado = res.estado;
                return item;
              }
            });
          },
          error: (err) => {
            this.alertService.openAlert(
              'Algo salió mal',
              'No pudimos cancelar tu orden, consulta a tu Academic Advisor para más detalle',
              'Entendido'
            );
          },
        });
      }
    });
  }

  openDetailDialog(order): void {
    const dialogRef = this.dialog.open(OrderDetailsComponent, {
      width: '600px',
      maxWidth: '90vw',
      autoFocus: true,
      data: {
        order: order,
        cancelOrder: this.cancelOrder,
      },
      panelClass: 'generic-alert',
    });
  }

  search(evt) {
    evt.preventDefault();
  }

  cleanSearch(): void {
    this.searchValue = '';
  }
}
