import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cart-item-card',
  templateUrl: './cart-item-card.component.html',
  styleUrls: ['./cart-item-card.component.scss'],
})
export class CartItemCardComponent {
  @Input() product: any;
  @Input() removeProduct: any;
  @Input() updateProduct: any;
  @Input() actions = true;

  constructor() {
  }

  onAdd() {
    this.updateProduct(this.product, this.product.quantity + 1);
  }

  onRemove() {
    this.updateProduct(this.product, this.product.quantity - 1);
  }

  onDelete() {
    this.removeProduct(this.product);
  }
}
