import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { DataService } from 'src/app/shared/services/data.service';
import * as reduxUser from '../../../../redux/actions/user.action';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-cardprofile',
  templateUrl: './cardprofile.component.html',
  styleUrls: ['./cardprofile.component.scss'],
})
export class CardprofileComponent {
  public user = {
    apellido: '',
    nombre: '',
    email_personal: '',
    foto: '',
  };
  public user$;

  constructor(
    private dataService: DataService,
    public dialog: MatDialog,
    private store: Store<UserState>
  ) {
    this.user$ = store.select('user');
  }

  showModalPhoto(): void {
    this.dialog.open(ModalComponent);
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ModalComponent',
  templateUrl: 'modal.component.html',
  styleUrls: ['./cardprofile.component.scss'],
})
export class ModalComponent {
  srcResult;
  selectedFile: ImageSnippet;

  constructor(
    private dataService: DataService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ModalComponent>,
    private _snackBar: MatSnackBar,
    private alumnoService: AlumnoService,
    private authService: AuthService,
    private store: Store
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onFileSelected(): void {
    const inputNode: any = document.querySelector('#file');

    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
      };

      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  changePhotoProfile(imageInput: any): void {
    const file: File = imageInput.files[0];
    const reader = new FileReader();
    const button = document.getElementById('save');
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png'
    ) {
      button.innerText = 'Guardando...';
      reader.addEventListener('load', (event: any) => {
        this.selectedFile = new ImageSnippet(event.target.result, file);

        this.alumnoService.postFoto(this.selectedFile.file).subscribe({
          next: (res) => {
            this.alumnoService.getAlumno().subscribe((alumno) => {
              this.store.dispatch(
                new reduxUser.PostPhotoUser(
                  `data:image/png;base64,${alumno.foto64}`
                )
              );
              button.innerText = 'Guardar cambios';
              this._snackBar.open(
                'Los cambios se realizaron con éxito',
                'Cerrar',
                {
                  duration: 7000,
                }
              );
              this.dialogRef.close();
            });
          },
          error: (err) => {
            this._snackBar.open(
              'Ha ocurrido un error al actualizar la foto de perfil',
              'Cerrar',
              {
                duration: 7000,
              }
            );
            button.innerText = 'Guardar cambios';
            console.log(err);
          },
        });
      });

      reader.readAsDataURL(file);
    } else {
      this._snackBar.open('El formato debe ser JPG, JPEG o PNG', 'Cerrar', {
        duration: 7000,
      });
    }
  }
}
