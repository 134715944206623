import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ISpec } from '../interfaces/ISpec';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class DracmaService {

    specView: ISpec;
    url = environment.baseUrl;

    constructor(
        private http: HttpClient,
        private authService: AuthService) {}

        getDracmas(): Observable<any> {
            const token = this.authService.leerToken();
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
            return this.http.get(this.url + '/educat/student_dracmas', {
            headers,
        });
    }
}


