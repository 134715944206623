<div>
  <app-menu-item
    [toggleMenu]="true"
    [name]="menuName"
    [icon]="menuIcon"
    [showLabel]="showLabel"
    [isActive]="isOpen"
  ></app-menu-item>
  <div
    class="options"
    *ngIf="
      (isOpen && hoverControl.value && !matchMobile && !openSidenav) ||
      (isOpen && matchMobile) || openSidenav && isOpen
    "
  >
    <ng-content></ng-content>
  </div>
</div>
