import { Location } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-image-header',
  templateUrl: './image-header.component.html',
  styleUrls: ['./image-header.component.scss'],
})
export class ImageHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() imgURL: string;
  @Input() titleColor: string;
  @Input() descriptionColor: string;
  @Input() buttonColor: string;
  @Input() logo: string;
  @Input() showTitle: boolean = true;

  scrolled: boolean = false;
  backgroundImage: any = null;

  constructor(private navigation: NavigationService) {}

  ngOnInit(): void {
    this.changeBG();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled =
      window.pageYOffset >=
      document.getElementById('header').offsetHeight + 100;
    this.changeBG();
  }

  changeBG() {
    if (
      window.pageYOffset <=
      document.getElementById('header').offsetHeight + 100
    ) {
      this.backgroundImage = {
        'background-color': '#ffffff',
        'background-image': this.imgURL.includes('http')
          ? 'url(' + this.imgURL + ')'
          : 'url(../../../../assets' + this.imgURL + ')',
        'background-size': 'cover',
        'background-position': 'center',
      };
    } else {
      this.backgroundImage = {
        background:
          'linear-gradient(34deg,$primary-color-dark 0%,$primary-color 50%,$primary-color-light 100%)',
      };
    }
  }

  back(): void {
    this.navigation.goBack();
  }
}
