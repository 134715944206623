import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';

export interface DialogData {
  title: string;
  subtitle: string;
  description: string;
  link: string;
  tipo: string;
}

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss'],
})
export class ShareDialogComponent {
  public disabledTooltip = true;

  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private clipboard: Clipboard
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  copyLink(): void {
    this.clipboard.copy(this.data.link);
    this.disabledTooltip = false;
  }
}
