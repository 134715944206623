<div id="credential">
  <div class="wrapper" *ngIf="loading">
    <div class="card-content">
      <div class="card-header">
        <img
          class="logo"
          src="../../../assets/images/logos/ADENU-Blanco.png"
          alt=""
        />
      </div>
      <div class="card-body">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="circle"
          [theme]="{
            width: '150px',
            height: '150px'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="line"
          [theme]="{
            width: '100px',
            'border-radius': '20px',
            height: '24px',
            'margin-bottom': '0'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="line"
          [theme]="{
            width: '300px',
            'border-radius': '5px',
            height: '36px',
            'margin-bottom': '0'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="line"
          [theme]="{
            width: '150px',
            'border-radius': '5px',
            height: '16px'
          }"
        ></ngx-skeleton-loader>
        <div class="carrera-card">
          <div class="icon-wrapper">
            <ngx-skeleton-loader
              count="1"
              animation="pulse"
              appearance="circle"
              [theme]="{
                width: '24px',
                height: '24px',
                'margin-bottom': '0'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="carrera-detail">
            <ngx-skeleton-loader
              count="1"
              animation="pulse"
              appearance="line"
              [theme]="{
                'min-width': '100%',
                'border-radius': '5px',
                height: '18px',
                'margin-bottom': '0'
              }"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
              count="1"
              animation="pulse"
              appearance="line"
              [theme]="{
                width: '150px',
                'border-radius': '5px',
                height: '12px',
                'margin-bottom': '0'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <ngx-skeleton-loader
          count="1"
          animation="pulse"
          appearance="line"
          [theme]="{
            width: '150px',
            'border-radius': '5px',
            height: '150px',
            'margin-bottom': '0'
          }"
        ></ngx-skeleton-loader>
        <div class="contact-info">
          <ngx-skeleton-loader
            count="1"
            animation="pulse"
            appearance="line"
            [theme]="{
              width: '200px',
              flex: '1',
              'border-radius': '5px',
              height: '36px',
              'margin-bottom': '0'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            count="1"
            animation="pulse"
            appearance="line"
            [theme]="{
              width: '200px',
              flex: '1',
              'border-radius': '5px',
              height: '36px',
              'margin-bottom': '0'
            }"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            count="1"
            animation="pulse"
            appearance="line"
            [theme]="{
              width: '200px',
              flex: '1',
              'border-radius': '5px',
              height: '36px',
              'margin-bottom': '0'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper" *ngIf="student && !loading">
    <div class="card-content">
      <div class="card-header">
        <img
          class="logo"
          src="../../../assets/images/logos/ADENU-Blanco.png"
          alt=""
        />
      </div>
      <div class="card-body">
        <img
          class="avatar"
          [src]="
            student.foto_perfil_base64
              ? 'data:image/png;base64,' + student.foto_perfil_base64
              : student.foto_perfil
          "
          alt="Foto de perfil"
        />
        <div class="chip">ID: {{ student.legajo_alumno }}</div>
        <h2>{{ student.nombre_alumno }}</h2>
        <h4>Alumno en</h4>
        <div
          class="carrera-card"
          *ngFor="let carrera of student.carera_principal"
        >
          <div class="icon-wrapper">
            <mat-icon class="material-icons-round">school</mat-icon>
          </div>

          <div class="carrera-detail">
            <p class="carrera">{{ carrera.nombre_carrera }}</p>
            <p class="modalidad">
              {{
                carrera.modalidad === "o"
                  ? "MODALIDAD ONLINE"
                  : carrera.modalidad === "p"
                  ? "MODALIDAD PRESENCIAL"
                  : null
              }}
            </p>
          </div>
        </div>

        <button
          *ngIf="user && student.legajo_alumno === user.legajo"
          mat-flat-button
          color="primary"
          (click)="openShare()"
        >
          <mat-icon class="material-icons-round" style="margin-right: 5px"
            >share</mat-icon
          >Compartir
        </button>
      </div>
      <div class="card-footer">
        <qrcode
          [qrdata]="webUrl + '/estudiante/' + student.legajo_alumno"
          [width]="150"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
        <div class="contact-info">
          <a
            [href]="'mailto:' + student.correo_alumno"
            target="_blank"
            class="line-contacto"
            ><mat-icon class="material-icons-round">mail</mat-icon
            >{{ student.correo_alumno }}</a
          >
          <a
            [href]="'tel:' + student.telefono_alumno"
            target="_blank"
            class="line-contacto"
            ><mat-icon class="material-icons-round">phone</mat-icon
            >{{ student.telefono_alumno }}</a
          >
          <a
            [href]="'https://wa.me/' + cleanNumber() + '?lang=es'"
            target="_blank"
            class="line-contacto"
            ><mat-icon class="fab fa-whatsapp"></mat-icon
            >{{ student.telefono_alumno }}</a
          >
        </div>
      </div>
    </div>
  </div>

  <div class="notfound-container" style="max-width: 400px" *ngIf="!loading && error">
    <img src="../../../assets/images/content/no-data.svg" alt="" />
    <h2>El alumno con ID {{studentId}} no existe o no pudimos encontrarlo</h2>
    <p>
      Intenta de nuevo más tarde o comunícate con tu Academic Advisor para saber más
    </p>
    <div class="button-container">
      <button
        mat-flat-button
        color="primary"
        aria-label="Reintentar"
        (click)="getStudent()"
      >
        Reintentar
      </button>
    </div>
  </div>
</div>

<div class="footer">
  Copyright © ADEN University Panamá
  <div class="contact">
    <div class="socials">
      <a
        mat-icon-button
        aria-label="¡Sígenos en Facebook!"
        href="https://www.facebook.com/ADENUniversityPanama/"
        target="_blank"
      >
        <mat-icon class="fab fa-facebook"></mat-icon>
      </a>
      <a
        mat-icon-button
        aria-label="¡Sígenos en Twitter!"
        href="https://twitter.com/Adenupa"
        target="_blank"
      >
        <mat-icon class="fab fa-twitter"></mat-icon>
      </a>
      <a
        mat-icon-button
        aria-label="¡Síguenos en Instagram!"
        href="https://www.instagram.com/adenupa/?hl=es-la"
        target="_blank"
      >
        <mat-icon class="fab fa-instagram"></mat-icon>
      </a>
      <a
        mat-icon-button
        aria-label="¡Síguenos en LinkedIn"
        href="https://www.linkedin.com/company/adenupa"
        target="_blank"
      >
        <mat-icon class="fab fa-linkedin"></mat-icon>
      </a>
      <a
        mat-icon-button
        aria-label="Suscríbete a nuestro canal de Youtube"
        href="https://www.youtube.com/channel/UCpTcEFxdU19ZHrkELRNOBmg/videos"
        target="_blank"
      >
        <mat-icon class="fab fa-youtube"></mat-icon>
      </a>
      <a
        mat-icon-button
        aria-label="Síguenos en TikTok"
        href="https://www.tiktok.com/@adenuniversity?"
        target="_blank"
      >
        <mat-icon class="fab fa-tiktok"></mat-icon>
      </a>
    </div>
    <a href="https://adenuniversity.edu.pa/" target="_blank"
      >adenuniversity.edu.pa</a
    >
  </div>
</div>
