<div class="main-container">
  <div class="flex-container">
    <div class="buscador">
      <div class="form-field">
        <mat-icon class="material-icons-round">search</mat-icon>
        <input
          matInput
          type="search"
          placeholder="Buscar"
          (keyup)="search($event)"
          #input
          [(ngModel)]="searchValue"
        />

        <div class="suffix">
          <button
            *ngIf="searchValue"
            mat-icon-button
            color="primary"
            aria-label="Clear"
            (click)="cleanSearch()"
          >
            <mat-icon class="material-icons-round">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button mat-flat-button color="primary" (click)="generatePdfC()">
        Descargar PDF
      </button>
    </div>
  </div>

  <div class="table-container main-radius shadow-200 main-border">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      matSort
      matSortActive="id"
      matSortDirection="desc"
      class="mat-elevation-z1"
      id="payment-table"
    >
      <ng-container
        [matColumnDef]="column.columnDef"
        [ngSwitch]="true"
        *ngFor="let column of tableColumns"
      >
        <ng-container *ngSwitchCase="column.columnDef === 'comprobante'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="nroFactura">
              {{ column.cell(row)[0] }}
            </div>
            <div class="detailFactura">
              {{ column.cell(row)[1] }}
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngSwitchCase="
            column.columnDef === 'Saldo' || column.columnDef === 'total'
          "
        >
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ column.cell(row) | currency: "USD":"USD ":"1.2-2" }}
          </td>
        </ng-container>
        <ng-container
          *ngSwitchCase="
            column.columnDef === 'fecha' || column.columnDef === 'vencimiento'
          "
        >
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ column.cell(row) | date: "dd/MM/yyyy" }}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="column.columnDef === 'estado'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="chip open-bg" *ngIf="column.cell(row) === 'Abierto'">
              {{ column.cell(row) }}
            </div>
            <div class="chip paid-bg" *ngIf="column.cell(row) === 'Pagado'">
              {{ column.cell(row) }}
            </div>
            <div class="chip bg-blocked" *ngIf="column.cell(row) === 'Vencido'">
              {{ column.cell(row) }}
            </div>
          </td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">{{ column.cell(row) }}</td>
        </ng-container>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="columnsToDisplay.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="
              element === expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="custom-grid">
              <ng-container *ngFor="let payment of element.move_line">
                <app-payment-card [payment]="payment"></app-payment-card>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7">
          <div class="no-result" *ngIf="input.value !== ''">
            <img
              src="../../../../assets/images/content/sin-resultados.svg"
              alt=""
            />
            <div class="text">No encontramos resultados para</div>
            <div class="searched-value">"{{ input.value }}"</div>
          </div>
          <div class="no-result" *ngIf="facturas === []">
            <img
              src="../../../../assets/images/content/sin-resultados.svg"
              alt=""
            />
            <div class="text">Aún no tienes comprobantes en el sistema</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="paginator-container">
    <mat-paginator
      [pageSizeOptions]="[10, 15, 25, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
