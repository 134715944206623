<div class="one-page-nav">
 <div class="container">
   <nav mat-tab-nav-bar>
     <a
       mat-tab-link
       *ngFor="let link of links"
       (click)="activeLink = link"
       [routerLink]="link.route"
       [active]="activeLink === link.tag"
     >
       {{ link.displayName }}
     </a>
   </nav>
 </div>
</div>