<div class="container">
  <button
    mat-icon-button
    color="accent"
    aria-label="Remover unidades"
    class="mat-elevation-z0"
    [disabled]="count <= 1"
    (click)="onRemove()"
  >
    <mat-icon class="material-icons-round">remove</mat-icon>
  </button>
  <div class="counter">{{ count }}</div>
  <button
    mat-icon-button
    color="accent"
    aria-label="Añadir unidades"
    [disabled]="count >= max"
    (click)="onAdd()"
  >
    <mat-icon class="material-icons-round">add</mat-icon>
  </button>
</div>
