<div class="dialog">
  <div class="close">
    <button mat-icon-button color="accent">
      <mat-icon class="material-icons-round" (click)="dialogRef.close()"
        >close</mat-icon
      >
    </button>
  </div>
  <app-custom-stepper>
    <cdk-step>
      <div class="step-container">
        <img
          src="../../../../../assets/images/content/dracmas/dracma-scoring.svg"
          alt="Paso"
        />
        <h1>Sistema de puntos Dracma</h1>
        <p>
          Lorem ipsum dolor sit amet. Sit necessitatibus adipisci 33 voluptatem
          odio ut nostrum aperiam ut quia praesentium qui quia impedit et alias
          recusandae hic magnam voluptates.
        </p>
      </div>
      <div class="button-container first">
        <!-- <button mat-icon-button color="primary" cdkStepperPrevious><mat-icon class="material-icons-round">arrow_back</mat-icon></button> -->
        <button mat-icon-button color="primary" cdkStepperNext>
          <mat-icon class="material-icons-round">arrow_forward</mat-icon>
        </button>
      </div>
    </cdk-step>
    <cdk-step>
      <div class="step-container">
        <img
          src="../../../../../assets/images/content/dracmas/lose-points.svg"
          alt="Paso"
        />
        <h1>¡Cuida tu puntuación!</h1>
        <p>
          Lorem ipsum dolor sit amet. Sit necessitatibus adipisci 33 voluptatem
          odio ut nostrum aperiam ut quia praesentium qui quia impedit et alias
          recusandae hic magnam voluptates.
        </p>
      </div>
      <div class="button-container ">
        <button mat-icon-button color="primary" cdkStepperPrevious>
          <mat-icon class="material-icons-round">arrow_back</mat-icon>
        </button>
        <button mat-icon-button color="primary" cdkStepperNext>
          <mat-icon class="material-icons-round">arrow_forward</mat-icon>
        </button>
      </div>
    </cdk-step>
    <cdk-step>
      <div class="step-container">
        <img
          src="../../../../../assets/images/content/dracmas/win-points.svg"
          alt="Paso"
        />
        <h1>¡Recupera el puntaje con buenas acciones!</h1>
        <p>
          Lorem ipsum dolor sit amet. Sit necessitatibus adipisci 33 voluptatem
          odio ut nostrum aperiam ut quia praesentium qui quia impedit et alias
          recusandae hic magnam voluptates.
        </p>
      </div>
      <div class="button-container ">
        <button mat-icon-button color="primary" cdkStepperPrevious>
          <mat-icon class="material-icons-round">arrow_back</mat-icon>
        </button>
        <button mat-icon-button color="primary" cdkStepperNext>
          <mat-icon class="material-icons-round">arrow_forward</mat-icon>
        </button>
      </div>
    </cdk-step>
    <cdk-step>
      <div class="step-container">
        <img
          src="../../../../../assets/images/content/dracmas/intercambio.svg"
          alt="Paso"
        />
        <h1>
          Al finalizar tu carrera, intercambia tus puntos por descuentos para
          seguir tu formación
        </h1>
        <p>
          Lorem ipsum dolor sit amet. Sit necessitatibus adipisci 33 voluptatem
          odio ut nostrum aperiam ut quia praesentium qui quia impedit et alias
          recusandae hic magnam voluptates.
        </p>
      </div>
      <div class="button-container ">
        <button mat-icon-button color="primary" cdkStepperPrevious>
          <mat-icon class="material-icons-round">arrow_back</mat-icon>
        </button>
        <button mat-flat-button color="primary" (click)="dialogRef.close()">
          ¡Entendido!
        </button>
      </div>
    </cdk-step>
  </app-custom-stepper>
</div>
