<div class="sticky-header"><app-header [title]="title"></app-header></div>
<div class="container mt-5">
  <div class="row mb-5">
    <div class="col-sm-12 col-md-4">
      <div>
        <button
          class="bg-primary-aden text-white dropdown-aden"
          mat-button
          [matMenuTriggerFor]="menu"
        >
          <span>{{ titleDropdown }}</span
          ><i class="fas fa-sort-down"></i>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="selectProcedure(0)" mat-menu-item>
            Cambio de Carrera
          </button>
          <!-- <button (click)="selectProcedure(1)" mat-menu-item>
            Retiro de Carrera
          </button> -->
          <button (click)="selectProcedure(2)" mat-menu-item>
            Solicitud de Doble Programa
          </button>
          <button (click)="selectProcedure(3)" mat-menu-item>
            Solicitud de Certificaciones Académicas
          </button>
          <button (click)="selectProcedure(4)" mat-menu-item>
            Solicitud de Créditos
          </button>
          <button (click)="selectProcedure(5)" mat-menu-item>
            Cambio de Modalidad de Cursado
          </button>
        </mat-menu>
      </div>
      <div class="contact-desktop mt-3">
        <mat-card style="height: 230px" class="card-aden-plain">
          <div class="header">
            <i
              style="font-size: 18px"
              class="mt-2 far fa-envelope text-primary-aden"
            ></i>
            <h2 class="text-primary-aden font ml-3">¿Necesitas ayuda?</h2>
          </div>
          <p>
            Si el trámite que necesitas hacer no se encuentra entre las opciones
            listadas, escríbenos a
            <a href="mailto:solicitudes@adenuniversity.edu.pa"
              >solicitudes@adenuniversity.edu.pa</a
            >
            si estás en modalidad Presencial o a
            <a href="mailto:solicitudesonline@adenuniversity.edu.pa"
              >solicitudesonline@adenuniversity.edu.pa</a
            >
            si estás en modalidad Online.
          </p>
          <!-- <button mat-button class="bg-primary-aden text-white ">¡Chatea en directo!</button> -->
        </mat-card>
      </div>
    </div>
    <div class="col-sm-12 col-md-8 form-container" [ngSwitch]="procedure">
      <div *ngSwitchCase="0">
        <app-cambio-carrera></app-cambio-carrera>
      </div>
      <!-- <div *ngSwitchCase="1">
        <app-retiro-carrera></app-retiro-carrera>
      </div> -->
      <div *ngSwitchCase="2">
        <app-doble-titulacion></app-doble-titulacion>
      </div>
      <div *ngSwitchCase="3">
        <app-cartas></app-cartas>
      </div>
      <div *ngSwitchCase="4">
        <app-creditos></app-creditos>
      </div>
      <div *ngSwitchCase="5">
        <app-cambio-modalidad></app-cambio-modalidad>
      </div>
    </div>
    <div class="contact-mobile m-3">
      <mat-card class="card-aden-plain">
        <div class="header">
          <i
            style="font-size: 18px"
            class="mt-2 far fa-envelope text-primary-aden"
          ></i>
          <h2 class="text-primary-aden font ml-3">¿Necesitas ayuda?</h2>
        </div>
        <p>
          Si el trámite que necesitas hacer no se encuentra entre las opciones
          listadas, escríbenos a
          <a href="mailto:solicitudes@adenuniversity.edu.pa"
            >solicitudes@adenuniversity.edu.pa</a
          >
          si estás en modalidad Presencial o a
          <a href="mailto:solicitudesonline@adenuniversity.edu.pa"
            >solicitudesonline@adenuniversity.edu.pa</a
          >
          si estás en modalidad Online.
        </p>
        <!-- <button mat-button class="bg-primary-aden text-white ">¡Chatea en directo!</button> -->
      </mat-card>
    </div>
  </div>
</div>
