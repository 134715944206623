import { Requisito } from './../../../../../shared/interfaces/IRequisito';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-requisito',
  templateUrl: './card-requisito.component.html',
  styleUrls: ['./card-requisito.component.scss'],
})
export class CardRequisitoComponent {
  @Input() requisito: Requisito;

  constructor() {}
}
