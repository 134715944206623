import { AcademicReducer } from '../reducers/academicAd.reducer';
import { CountriesReducer } from '../reducers/countries.reducer';
import { coursesReducer } from '../reducers/courses.reducer';
import { GradesReducer } from '../reducers/grades.reducer';
import { UserReducer } from '../reducers/user.reducer';
import { libraryReducer } from '../reducers/videos.reducer';

export const store = {
  countries: CountriesReducer,
  user: UserReducer,
  academic: AcademicReducer,
  grades: GradesReducer,
  library: libraryReducer,
  courses: coursesReducer
};
