<div class="card-aden">
  <h2 class="title">
    <strong>
      Formato de Solicitud de Cambio de Modalidad de Cursado ADENU
    </strong>
  </h2>

  <h4 class="text-muted">
    Diligenciar correctamente todos los campos para que se realice
  </h4>

  <div class="ml-3 mt-5">
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Nombre completo</label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].nombre"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="formulario['controls'].nombre.hasError('required')"
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Identificación </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].identificacion"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="
                  formulario['controls'].identificacion.hasError('required')
                "
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Correo electronico </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].correoElectronico"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="
                  formulario['controls'].correoElectronico.hasError('email') &&
                  !formulario['controls'].correoElectronico.hasError('required')
                "
              >
                Por favor ingrese un correo electronico válido
              </mat-error>
              <mat-error
                *ngIf="
                  formulario['controls'].correoElectronico.hasError('required')
                "
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Periodo de tu ingreso en ADENU </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <mat-label>Elige</mat-label>
              <mat-select [formControl]="formulario['controls'].periodoIngreso">
                <mat-option *ngFor="let p of periodo" [value]="p.value">
                  {{ p.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Año de ingreso en la Lic. </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <mat-label>Elige</mat-label>
              <mat-select [formControl]="formulario['controls'].anioIngreso">
                <mat-option *ngFor="let i of ingreso" [value]="i">
                  {{ i }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Seleccione su licenciatura </label>
        </div>
        <div class="row ml-3">
          <form class="example-form">
            <section class="example-section">
              <mat-radio-group
                [formControl]="formulario['controls'].licenciatura"
              >
                <div class="row" *ngFor="let primLinc of licenciaturas">
                  <mat-radio-button
                    class="example-margin"
                    [value]="primLinc.nombre"
                    >{{ primLinc.nombre }}</mat-radio-button
                  >
                  <mat-form-field
                    *ngIf="primLinc.nombre === 'Otro: '"
                    class="input-mod ml-2"
                  >
                    <input
                      [required]="
                        formulario['controls'].licenciatura.value === 'Otro: '
                      "
                      matInput
                      [formControl]="formulario['controls'].otraLicenciatura"
                      [errorStateMatcher]="matcher"
                    />
                    <mat-error
                      *ngIf="
                        formulario['controls'].otraLicenciatura.hasError(
                          'required'
                        )
                      "
                    >
                      Campo <strong>obligatorio</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </mat-radio-group>
            </section>
          </form>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Seleccione la Modalidad a la cual se quiere cambiar</label>
        </div>
        <div class="row ml-3">
          <form class="example-form">
            <section class="example-section">
              <mat-radio-group [formControl]="formulario['controls'].modalidad">
                <div class="row" *ngFor="let modalidad of modalidades">
                  <mat-radio-button
                    class="example-margin"
                    [value]="modalidad.value"
                    >{{ modalidad.value }}</mat-radio-button
                  >
                  <mat-form-field
                    *ngIf="modalidad.value === 'Otro: '"
                    class="input-mod ml-2"
                  >
                    <input
                      [required]="
                        formulario['controls'].licenciaturaAIniciar.value ===
                        'Otro: '
                      "
                      matInput
                      [formControl]="
                        formulario['controls'].licenciaturaAIniciarOtro
                      "
                      [errorStateMatcher]="matcher"
                    />
                    <mat-error
                      *ngIf="
                        formulario[
                          'controls'
                        ].licenciaturaAIniciarOtro.hasError('required')
                      "
                    >
                      Campo <strong>obligatorio</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </mat-radio-group>
            </section>
          </form>
        </div>
      </div>
    </div>
    <div class="row padding-row">
      <div class="col">
        <div class="row">
          <label>Motivo de la solicitud del cambio </label>
        </div>
        <div class="row">
          <form class="example-form">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Tu respuesta"
                [formControl]="formulario['controls'].motivoCambio"
                [errorStateMatcher]="matcher"
              />
              <mat-error
                *ngIf="formulario['controls'].motivoCambio.hasError('required')"
              >
                Esta pregunta es <strong>obligatoria</strong>
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button
      [disabled]="!formulario.valid"
      mat-button
      class="bg-primary-aden text-white"
      (click)="enviarFormulario()"
    >
      Enviar
    </button>
  </div>
</div>
