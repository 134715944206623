<div class="one-page-nav">
  <div class="container">
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        *ngFor="let link of links"
        [routerLink]="['./']"
        [fragment]="link.tag"
        [active]="activeLink.name === link.name"
      >
        {{ link.name }}
      </a>
    </nav>
  </div>
</div>
