<div class="sticky-header">
  <app-header [title]="'Puntuación Dracma'"></app-header>
</div>
<div class="container">
  <div class="notfound-container main-shadow mb-5" *ngIf="loading">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
  <div
    class="notfound-container main-shadow mb-5"
    *ngIf="!loading && (!dracmaInfo || error)"
  >
    <img
      src="../../../assets/images/content/dracmas/dracma-scoring.svg"
      alt=""
    />
    <h2>Puntuación no disponible</h2>
    <p>
      Parece que aún no tienes puntos o no pudimos encontrarlos, si crees que es
      un error no dudes en contactar a tu Academic Advisor o vuelve a intentar
      más tarde.
    </p>
    <div class="button-container">
      <button mat-stroked-button color="primary" routerLink="/academic-advisor">
        Contactar Academic Advisor
      </button>
      <button mat-flat-button color="primary" (click)="getDracmaInfo()">
        Reintentar
      </button>
    </div>
  </div>
  <div class="main-grid" *ngIf="!loading && dracmaInfo">
    <div class="sidebar">
      <div class="side-card score">
        <div class="card-header">
          <h2>Tu puntaje</h2>
          <button mat-icon-button color="primary" (click)="openInfo()">
            <mat-icon>help</mat-icon>
          </button>
        </div>
        <h1 class="puntaje">
          {{ dracmaInfo.total }} <span>/ {{ dracmaInfo.limite }}</span>
        </h1>
        <div
          class="pending-value"
          [matTooltip]="
            'Todos los puntos que ganes mientras tu puntuación sea igual al límite de' +
            dracmaInfo.limite +
            ' puntos se guardarán en este saldo a favor. En cuanto tu puntuación disminuya, se podrá reponer con los puntos de tu saldo a favor.'
          "
          matTooltipClass="info-tooltip"
          *ngIf="dracmaInfo.saldo_pendiente > 0"
        >
          <div class="title">Saldo a favor</div>
          <h1 class="puntaje">{{ dracmaInfo.saldo_pendiente }}</h1>
        </div>
      </div>
      <div class="banners-container">
        <div class="icon-banner gain">
          <mat-icon class="material-icons-round">add_circle</mat-icon>
          <h2>¿En qué momento puedo ganar puntos?</h2>
        </div>
        <div class="icon-banner lose">
          <mat-icon class="material-icons-round">do_disturb_on</mat-icon>
          <h2>¿Cuándo me restan puntos?</h2>
        </div>
      </div>
    </div>
    <div class="side-card">
      <app-dracma-table
        [dracmaMovements]="dracmaInfo.dracmas_lines"
      ></app-dracma-table>
    </div>
  </div>
</div>
