import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-centro-emprendimiento',
  templateUrl: './centro-emprendimiento.component.html',
  styleUrls: ['./centro-emprendimiento.component.scss']
})
export class CentroEmprendimientoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

}
