import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  FormGroupDirective,
  NgForm,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { SendFormService } from 'src/app/shared/services/send-form.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-doble-titulacion',
  templateUrl: './doble-titulacion.component.html',
  styleUrls: ['./doble-titulacion.component.scss'],
})
export class DobleTitulacionComponent implements OnInit {
  formulario: UntypedFormGroup;

  periodo = [
    { value: 'Cuatrimestre I' },
    { value: 'Cuatrimestre II' },
    { value: 'Cuatrimestre III' },
  ];

  ingreso = [];

  primerLicenciatura = [
    { nombre: 'Lic. Administración de empresas' },
    { nombre: 'Lic. Marketing y Comunicación Digital' },
    { nombre: 'Lic. Comercio y logística internacional' },
    { nombre: 'Lic. Dirección de Proyecto' },
    { nombre: 'Lic. Ingeniería Industrial' },
    { nombre: 'Lic. Ingeniería Comercial' },
    { nombre: 'Lic. Business Analytics' },
    { nombre: 'Otro: ' },
  ];

  licenciaturaDobleTit = [
    { nombre: 'Lic. Administración de empresas' },
    { nombre: 'Lic. Marketing y Comunicación Digital' },
    { nombre: 'Lic. Comercio y logística internacional' },
    { nombre: 'Lic. Dirección de Proyecto' },
    { nombre: 'Lic. Ingeniería Industrial' },
    { nombre: 'Lic. Ingeniería Comercial' },
    { nombre: 'Lic. Business Analytics' },
    { nombre: 'Otro: ' },
  ];

  condicion = [{ value: 'Si' }, { value: 'No' }, { value: 'Otro: ' }];

  matcher = new MyErrorStateMatcher();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private alumnoService: AlumnoService,
    private sendFormService: SendFormService
  ) {}

  ngOnInit(): void {
    this.formulario = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      identificacion: ['', Validators.required],
      correoElectronico: ['', [Validators.required, Validators.email]],
      periodoIngreso: ['', Validators.required],
      anioIngreso: ['', Validators.required],
      primerLicenciatura: ['', Validators.required],
      primerLicenciaturaOtro: [''],
      licenciaturaDobleTit: ['', Validators.required],
      licenciaturaDobleTitOtro: [''],
      motivoCambio: ['', Validators.required],
      condCambio: ['Si', Validators.required],
      // condCambioOtro: [''],
    });
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    const hoy = new Date().getFullYear();
    for (let index = hoy; index >= hoy - 10; index--) {
      this.ingreso.push(index.toString());
    }
    this.alumnoService.getAlumno().subscribe((res) => {
      this.formulario = this.formBuilder.group({
        nombre: [res.nombre, Validators.required],
        apellido: [res.apellido, Validators.required],
        identificacion: [res.numero_documento, Validators.required],
        correoElectronico: [res.email, [Validators.required, Validators.email]],
        periodoIngreso: ['', Validators.required],
        anioIngreso: ['', Validators.required],
        primerLicenciatura: ['', Validators.required],
        primerLicenciaturaOtro: [''],
        licenciaturaDobleTit: ['', Validators.required],
        licenciaturaDobleTitOtro: [''],
        motivoCambio: ['', Validators.required],
        condCambio: ['Si', Validators.required],
        condCambioOtro: [''],
      });
    });
  }

  enviarFormulario(): void {
    let primerLicenciatura: string;
    let licenciaturaDobleTit: string;
    let condCambio: string;
    if (this.formulario.value.primerLicenciatura !== 'Otro: ') {
      primerLicenciatura = this.formulario.value.primerLicenciatura;
    } else {
      primerLicenciatura = this.formulario.value.primerLicenciaturaOtro;
    }
    if (this.formulario.value.licenciaturaDobleTit !== 'Otro: ') {
      licenciaturaDobleTit = this.formulario.value.licenciaturaDobleTit;
    } else {
      licenciaturaDobleTit = this.formulario.value.licenciaturaDobleTitOtro;
    }
    if (this.formulario.value.condCambio !== 'Otro: ') {
      condCambio = this.formulario.value.condCambio;
    } else {
      condCambio = this.formulario.value.condCambioOtro;
    }
    this.enviarMail(primerLicenciatura, licenciaturaDobleTit, condCambio);
  }

  enviarMail(
    primerLicenciatura: string,
    licenciaturaDobleTit: string,
    condCambio: string
  ): void {
    const data = {
      tipoFormulario: 'doble titulacion',
      nombre: this.formulario.value.nombre,
      apellido: this.formulario.value.apellido,
      identificacion: this.formulario.value.identificacion,
      correo: this.formulario.value.correoElectronico,
      periodo: this.formulario.value.periodoIngreso,
      anioIngreso: this.formulario.value.anioIngreso,
      licenciatura: primerLicenciatura,
      licenciaturaSecundaria: licenciaturaDobleTit,
      motivo: this.formulario.value.motivoCambio,
      condicion: condCambio,
      modalidad: '',
    };
    this.sendFormService.enviarFormulario(data).subscribe({
      next: (res) => {
        this.alertService.openAlert(
          '¡Perfecto!',
          'La solicitud fue enviada con éxito',
          'Ok'
        );

        this.formulario.reset();
      },
      error: (error) => {
        this.alertService.openAlert(
          '¡Ups!',
          'Ha ocurrido un error, por intenta de nuevo más tarde',
          'Ok'
        );
        console.log(error);
      },
    });
  }

  imprimir(): void {
    console.log(this.formulario.valid);
    // console.log(this.formulario);
  }
}
