<div class="main-container">
  <div class="buscador">
    <div class="form-field">
      <mat-icon class="material-icons-round">search</mat-icon>
      <input
        matInput
        type="search"
        placeholder="Buscar"
        (keyup)="search($event)"
        #input
        [(ngModel)]="searchValue"
      />

      <div class="suffix">
        <button
          *ngIf="searchValue"
          mat-icon-button
          color="primary"
          aria-label="Clear"
          (click)="cleanSearch()"
        >
          <mat-icon class="material-icons-round">close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="table-container main-radius shadow-200 main-border">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">
      <ng-container
        [matColumnDef]="column.columnDef"
        [ngSwitch]="true"
        *ngFor="let column of tableColumns"
      >
        <ng-container *ngSwitchCase="column.columnDef === 'pre-requisito'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="chip paid-bg" *ngIf="row['pre-requisito']">
              {{ column.cell(row) }}
            </div>

            <div class="disabled-text" *ngIf="!row['pre-requisito']">
              Sin pre-requisitos
            </div>
          </td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">{{ column.cell(row) }}</td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="example-element-row"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7">
          <div class="no-result" *ngIf="input.value !== ''">
            <img
              src="../../../../../assets/images/content/sin-resultados.svg"
              alt=""
            />
            <div class="text">No encontramos resultados para</div>
            <div class="searched-value">"{{ input.value }}"</div>
          </div>
          <div class="no-result" *ngIf="planEstudio === []">
            <img
              src="../../../../../assets/images/content/sin-resultados.svg"
              alt=""
            />
            <div class="text">
              Ocurrió un problema al intentar cargar la información de tu plan
              de estudio
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="paginator-container">
    <mat-paginator
      [pageSizeOptions]="[10, 15, 25, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
