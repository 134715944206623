<div class="dialog-header">
    <div mat-dialog-title>
      <h1>Resumen de compra #{{ data.oportunidad.id }}</h1>
      <button
        class="close"
        mat-icon-button
        aria-label="Close"
        (click)="onClose()"
      >
        <mat-icon class="material-icons-round">close</mat-icon>
      </button>
    </div>
  </div>

<div class="body b">
  <h2>Este es el resumen de tu ultima compra</h2>
    <h1>Información de la orden</h1>
    <div class="card-detalles">
      <div class="icon">
        <mat-icon class="material-icons-round">event</mat-icon>
      </div>
      <div class="texto">
        <div class="card-title">FECHA CREACIÓN</div>
        <div class="card-description">
          {{
            moment(data.oportunidad.fecha_creacion).format(
              "L hh:ss"
            )
          }}
        </div>
      </div>
    </div>
    <div class="card-detalles">
      <div class="icon">
        <mat-icon class="material-icons-round">info</mat-icon>
      </div>
      <div class="texto">
        <div class="card-title">ESTADO</div>
        <div class="card-description">
          {{
            data.oportunidad.stage === "Inscripto"
              ? "Pendiente"
              : data.oportunidad.stage
          }}
        </div>
      </div>
    </div>
    <div class="card-detalles">
      <div class="icon">
        <mat-icon class="material-icons-round">{{
          data.oportunidad.forma_pago
            .toLowerCase()
            .includes("online")
            ? "credit_card"
            : "account_balance"
        }}</mat-icon>
      </div>
      <div class="texto">
        <div class="card-title">FORMA DE PAGO</div>
        <div class="card-description">
          {{ data.oportunidad.forma_pago }}
        </div>
      </div>
    </div>
    <div
      class="card-detalles"
      *ngIf="data.oportunidad.forma_pago !== 'Transferencia'"
    >
      <div class="icon">
        <mat-icon class="material-icons-round">watch_later</mat-icon>
      </div>
      <div class="texto">
        <div class="card-title">PAGO DIFERIDO</div>
        <div class="card-description">
          {{
            data.oportunidad.diferir_pago_a_mes_proximo &&
            validDiffer
              ? "Si"
              : data.oportunidad.diferir_pago_a_mes_proximo &&
                !validDiffer
              ? "Plazo vencido"
              : "No"
          }}
        </div>
      </div>
    </div>
    <div class="products">
        <h1>Productos adquiridos</h1>
        <div *ngFor="let producto of data.oportunidad.productos" class="card-container shadow-200 main-border main-radius">
            <div class="card-content">
            <div class="product-title">{{ producto.producto}}</div>
              <div class="header">
                <div class="price">
                  <div class="unit">US$</div>
                  <div class="number">{{ producto.price_total }}</div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>