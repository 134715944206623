import { ContestTutor } from './../../../../../shared/interfaces/IContest';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-tutor',
  templateUrl: './card-tutor.component.html',
  styleUrls: ['./card-tutor.component.scss'],
})
export class CardTutorComponent {
  @Input() tutor: ContestTutor;
  @Input() selectedTutors: any[];
  @Input() addTutor: (args: any) => void;
  @Input() removeTutor: (args: any) => void;

  selectedIndex: number;
  isSelected = false;

  constructor() {}

  unselect(): void {
    this.tutor.isSelected = false;
  }
}
