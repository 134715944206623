<div class="container-fluid header-bg">
  <div class="container">
    <div class="body">
      <button mat-icon-button (click)="back()">
        <mat-icon class="material-icons-round">arrow_back</mat-icon>
      </button>
      <div class="text">{{ title }}</div>
    </div>
  </div>
</div>
<div *ngIf="isSpecMode &&  specUserPhoto" class="spec-header">
  <img [src]="'data:image/png;base64,'+ specUserPhoto" alt="">
  <div class="spec-text">Actualmente estás actuando en nombre de <b>{{specUserName}}</b></div>
</div>
