import { Requisito } from '../../../../../shared/interfaces/IRequisito';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-paso-internacional',
  templateUrl: './card-paso-internacional.component.html',
  styleUrls: ['./card-paso-internacional.component.scss'],
})
export class CardPasoInternacionalComponent {
  @Input() requisito: Requisito;

  constructor() {}
}
