<div
  [class]="isSpecMode ? 'nav-container spec-container' : 'nav-container'"
  tourAnchor="sidenav"
  [class.is-mobile]="mobileQuery.matches"
  [class.is-open]="!mobileQuery.matches && openSidenav.value"
  [ngStyle]="{
    visibility:
      (mobileQuery.matches && expandedSidenav.value) || !mobileQuery.matches
        ? 'visible'
        : 'hidden',
    opacity:
      (mobileQuery.matches && expandedSidenav.value) || !mobileQuery.matches
        ? '1'
        : '0',
    left:
      (mobileQuery.matches && expandedSidenav.value) || !mobileQuery.matches
        ? '0px'
        : '-100%'
  }"
>
  <div
     [class]="isSpecMode ? 'heading spec-container' : 'heading'"
    [ngStyle]="{
      paddingLeft: expandedSidenav.value ? '1rem' : 'unset',
      paddingRight: expandedSidenav.value ? '0.5rem' : 'unset'
    }"
  >
    <img
      src="../../../../../assets/images/logos/ADENU-Color.png"
      height="32px"
      *ngIf="openSidenav.value || expandedSidenav.value"
    />
    <!-- <app-menu-item
      [icon]="openSidenav.value || expandedSidenav.value ? 'fa-regular fa-bars' : 'fa-regular fa-bars'"
      (click)="toggleSidenav()"
      tourAnchor="toggleSidenav"
    ></app-menu-item> -->
  </div>

  <div
    class="menu"
    (mouseenter)="showLabels.setValue(true); hoverControl.setValue(true)"
    (mouseleave)="showLabels.setValue(false); hoverControl.setValue(false)"
    [ngStyle]="{
      width:
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
          ? '265px'
          : 'unset',
      padding:
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
          ? '0 0.5rem'
          : 'unset'
    }"
    tourAnchor="menuSecciones"
  >
    <app-menu-item
    *ngIf="!isSpecMode"
      [icon]="'fa-light fa-house'"
      [name]="'Dashboard'"
      [link]="'/home'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item>
      <app-menu-item
      *ngIf="!isSpecMode"
        [iconImage]="true"
        [iconImageUrl]="'canvas-icon.svg'"
        [name]="'Ingresar a CANVAS'"
        [link]="'https://aden.instructure.com/login/openid_connect'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-menu-item
        tourAnchor="cursado"
        [icon]="'fa-light fa-book'"
        [name]="'Mi cursado'"
        [link]="'/my-course/notas'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-menu-item
        tourAnchor="tramitesServicios"
        [icon]="'fa-light fa-memo-circle-check'"
        [name]="'Trámites y servicios'"
        [link]="'/tramites/matriculaciones'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <!-- <app-menu-item
        [icon]="''"
        [isIconify]="true"
        [name]="'Mis cursos'"
        [link]="'/course'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-menu-item
      *ngIf="!isSpecMode"
        [icon]="'history_edu'"
        [name]="'Mi plan de estudio'"
        [link]="'/plan-estudio'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-menu-item
        [icon]="'schedule'"
        [name]="'Horarios'"
        [link]="'/horarios'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-menu-item
        [icon]="'material-symbols:video-library-rounded'"
        [name]="'Biblioteca'"
        [link]="'/library'"
        [isIconify]="true"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
    </div>
    <hr />
    <div class="menu" id="tramites" tourAnchor="tramitesServicios">
      <div class="menu-label" *ngIf="openSidenav.value">
        Trámites y servicios
      </div>
      <app-menu-item
        [icon]="'school'"
        [name]="'Matriculaciones'"
        [link]="'/matriculaciones'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-menu-item
        [icon]="'card_membership'"
        [name]="'Trámites Académicos'"
        [link]="'/tramites-academicos'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item> -->

      <!-- <form
        ngNoForm
        id="job-form"
        action="https://aden.hiringroomcampus.com/check-access"
        target="_blank"
        method="POST"
      >
        <input hidden name="token" value="E9E69T2vyRPNZuTVHaZjK67Y" />
        <app-menu-item
          [icon]="'work'"
          [name]="'Bolsa de empleo'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          (click)="toggleDropdowns(); closeSidenav(); goToEmpleo()"
        ></app-menu-item>
      </form> -->
      <app-menu-item
        [icon]="'fa-light fa-briefcase'"
        [name]="'Ofertas laborales'"
        [link]="'/buscojobs'"
        [canReload]="true"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item>
      <app-drop-down-menu
      *ngIf="!isSpecMode"
      tourAnchor="bienestarEstudiantil"
        [hoverControl]="hoverControl"
        [matchMobile]="mobileQuery.matches"
        [menuName]="'Bienestar Estudiantil'"
        [menuIcon]="'fa-light fa-users'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        [isOpen]="adenpolisToggle.value"
        [openSidenav]="openSidenav.value"
        (click)="adenpolisToggle.setValue(!adenpolisToggle.value)"
      >
        <app-menu-item
        *ngIf="!isSpecMode"
          [icon]="'none'"
          [name]="'Comite estudiantil'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [link]="'/comite'"
          [size]="'small'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
        <!-- <app-menu-item
        *ngIf="!isSpecMode"
          [icon]="'none'"
          [name]="'Postulaciones'"
          [showLabel]=" 
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [size]="'small'"
          [link]="'/comite-estudiantil/postulaciones'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
        <app-menu-item
        *ngIf="!isSpecMode"
          [icon]="'none'"
          [name]="'Candidatos y Elecciones'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [link]="'/comite-estudiantil/elecciones'"
          [size]="'small'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item> -->
        <!-- <app-menu-item
        *ngIf="!isSpecMode"
          [icon]="'none'"
          [name]="'Centro de emprendimiento'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [link]="'/centro-emprendimiento'"
          [size]="'small'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item> -->
        <app-menu-item
        *ngIf="!isSpecMode"
          [icon]="'none'"
          [name]="'ADENU Internacional'"
          [link]="'/adenu-internacional'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
        <app-menu-item
        *ngIf="!isSpecMode"
          [icon]="'none'"
          [name]="'Centro de emprendimiento'"
          [link]="'/centro-emprendimiento'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
      </app-drop-down-menu>
      <!--
        <app-menu-item
          [icon]="'lightbulb'"
          [name]="'Sobre CEADEN'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [size]="'small'"
          [link]="'/ceaden'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
        <app-menu-item
          [icon]="'rocket_launch'"
          [name]="'Programa Impulso'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [size]="'small'"
          [link]="'/ceaden/programa-impulso'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
        <app-menu-item
          [icon]="'tips_and_updates'"
          [name]="'Reto Aprende y Emprende'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [link]="'/ceaden/reto-aprende-y-emprende'"
          [size]="'small'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
      </app-drop-down-menu> -->
      <app-drop-down-menu
      *ngIf="!isSpecMode"
        [hoverControl]="hoverControl"
        [matchMobile]="mobileQuery.matches"
        [menuName]="'Proceso de graduacion'"
        [menuIcon]="'fa-light fa-graduation-cap'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        [isOpen]="gradToggle.value"
        [openSidenav]="openSidenav.value"
        (click)="gradToggle.setValue(!gradToggle.value)"
      >
        <app-menu-item
          [icon]="'none'"
          [name]="'Proceso de graduación'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [size]="'small'"
          [link]="'/graduacion'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
        <app-menu-item
          [name]="'Pasantía de Responsabilidad Social Comunitaria'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [icon]="'none'"
          [size]="'small'"
          [link]="'/graduacion/pasantia-responsabilidad-social'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
        <app-menu-item
          [name]="'Pasantía de Extensión Profesional'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [link]="'/graduacion/pasantia-extension-profesional'"
          [size]="'small'"
          [icon]="'none'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
        <app-menu-item
          [name]="'Opción de Titulación'"
          [showLabel]="
            showLabels.value ||
            openSidenav.value ||
            (mobileQuery.matches && expandedSidenav.value)
          "
          [link]="'/graduacion/opcion-titulacion'"
          [size]="'small'"
          [icon]="'none'"
          (click)="toggleDropdowns(); closeSidenav()"
        ></app-menu-item>
      </app-drop-down-menu>
      <!-- <app-menu-item
        [icon]="'event'"
        [name]="'Eventos ADENU'"
        [link]="'/eventos'"
        [showLabel]="
          showLabels.value ||
          openSidenav.value ||
          (mobileQuery.matches && expandedSidenav.value)
        "
        (click)="toggleDropdowns(); closeSidenav()"
      ></app-menu-item> -->
      <app-menu-item
      [icon]="'fa-light fa-circle-question'"
      [name]="'Centro de ayuda'"
      [showLabel]="
        showLabels.value ||
        openSidenav.value ||
        (mobileQuery.matches && expandedSidenav.value)
      "
      [size]="'small'"
      [link]="'/academic-advisor'"
      (click)="toggleDropdowns(); closeSidenav()"
    ></app-menu-item>
    </div>
    <app-menu-item
      *ngIf="isAdmin" 
      [isIconify]="true"
      [icon]="isSpecMode ? 'mdi:eye-off-outline': 'iconamoon:eye'"
      [name]="'Vista de estudiante'" 
      [link]="isSpecMode ? 'noNavigate' : '/spec-view'"
      (click)="toggleSpec()"
      [showLabel]="
      showLabels.value ||
      openSidenav.value ||
      (mobileQuery.matches && expandedSidenav.value)"
      (click)="toggleDropdowns(); closeSidenav()"
      [class]="miCursadoToggle.value || ceadenToggle.value || adenpolisToggle.value || gradToggle.value ? 'spec-view toggle' : 'spec-view'">
    </app-menu-item>
  </div>
<div
  class="backdrop"
  id="backdrop"
  [ngStyle]="{
    visibility:
      (mobileQuery.matches && expandedSidenav.value) || !mobileQuery.matches
        ? 'visible'
        : 'hidden',
    display: mobileQuery.matches && expandedSidenav.value ? 'flex' : 'none',
    opacity: mobileQuery.matches && expandedSidenav.value ? '1' : '0',
    left:
      (mobileQuery.matches && expandedSidenav.value) || !mobileQuery.matches
        ? '0px'
        : 'inherit'
  }"
  (click)="toggleSidenav()"
></div>
