<div class="container-bg">
  <div class="container">
    <div class="heading">
      <div class="title secondary">Cronograma 2022</div>
    </div>

    <div class="crono-grid">
      <ng-container *ngFor="let item of cronograma">
        <app-card-cronograma-internacional [item]="item"></app-card-cronograma-internacional>
      </ng-container>
    </div>
  </div>
</div>
