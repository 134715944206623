import { Component, OnInit } from '@angular/core';
import { ContestService } from 'src/app/shared/services/contest.service';
import {
  ContestMember,
  ContestTeam,
} from 'src/app/shared/interfaces/IContest';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-adenu-internacional',
  templateUrl: './adenu-internacional.component.html',
  styleUrls: ['./adenu-internacional.component.scss'],
})
export class AdenuInternacionalComponent implements OnInit {
  capitan: ContestMember;
  hasTeam: boolean;
  alumnos: ContestMember[] = [];
  userTeam: ContestTeam;

  constructor(
    private alertService: AlertService,
    private alumnoService: AlumnoService,
    private authService: AuthService,
    private contestService: ContestService
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.alumnoService.getAlumno().subscribe({
      next: (res) => {
        /* CHEQUEAR SESIÓN */

        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.email === '' || !res.email || res.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
        this.capitan = {
          id: res.legajo,
          name: res.apellido,
          mail: res.email,
          img: `data:image/png;base64,${res.foto64}`,
        };

        this.checkUser(res.legajo);
      },
      error: (err) => {
        this.alertService.openAlert(
          '¡Sesión vencida!',
          'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
          'Ok'
        );
        setTimeout(() => {
          this.authService.logout();
        }, 5000);
      },
    });
  }

  checkUser(id): void {
    this.contestService.checkUser(id).subscribe({
      next: (res) => {
        this.userTeam = res.equipaments;
        this.hasTeam = !res.avaliable;
      },
      error: (err) => {
        console.log('error');
      },
    });
  }
}
