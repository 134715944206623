import { ExcelService } from '../../../../shared/services/excel.service';
import { UserState } from '../../../../redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Component } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { FinancialCreditsService } from 'src/app/shared/services/financial-credits.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-creditos-academicos',
  templateUrl: './creditos-academicos.component.html',
  styleUrls: ['./creditos-academicos.component.scss'],
})
export class CreditosAcademicosComponent {
  public dataExcel;
  public isLoading = false;

  dataHeaders = [
    'Código',
    'Nombre de la materia',
    'Cr. Asig.',
    'Nota',
    'Calif.',
    'Condicion',
  ];

  public user;
  public user$: Observable<any>;

  constructor(
    private userStore: Store<UserState>,
    private excelService: ExcelService,
    private financialCreditsService: FinancialCreditsService,
    private alertService: AlertService,
  ) {
    this.user$ = userStore.select('user');
  }

  async getExcelData(carreraId): Promise<any> {
    this.isLoading = true;
    this.financialCreditsService.getCreditosAcademicos(carreraId).subscribe({
      next: (res) => {
        this.dataExcel = res;
        this.generatePdf(res);
      },
      error: (err) => {
        this.isLoading = false;
        this.alertError();
      },
    });
  }

  alertError(): void {
    this.alertService.openAlert(
      '¡Ups!',
      'Ha ocurrido un error al intentar descargar tus créditos, por favor, intenta de nuevo más tarde',
      'Ok'
    );
  }

  normalizeDataTable(object: object): any[] {
    const res = [];

    for (const i in object) {
      if (object.hasOwnProperty(i)) {
        const subArray = [];

        subArray.push(object[i].codigo);
        subArray.push(object[i].nombre);
        subArray.push(object[i].creditos);
        subArray.push(object[i].nota);
        subArray.push(object[i].calificacion);
        subArray.push(object[i].condicion);

        res.push(subArray);
      }
    }

    return res;
  }

  normalizeUserTable(object): any[] {
    return [
      ['Carrera', object.carrera],
      ['Estudiante', object.estudiante],
      ['Identificación', object.identificacion],
      ['Periodo de estudios', object.periodo],
      ['Resolución', object.resolucion],
    ];
  }

  generatePdf(object): void {
    const date = new Date().toLocaleDateString();

    const headNote = [
      ['Código', 'Materia', 'Cr. Asig.', 'Nota', 'Calificación', 'Condición'],
    ];
    const dataNote = this.normalizeDataTable(object.lineas);
    const headUser = [['Estado', 'Valor']];
    const dataUser = this.normalizeUserTable(object);
    const dataProm = [
      [
        'Promedio ponderado',
        object.promedio_ponderado != ''
          ? object.promedio_ponderado.toFixed(2)
          : '-',
      ],
      [
        'Índice Acumulado',
        object.indice_acumulado != ''
          ? object.indice_acumulado.toFixed(2)
          : '-',
      ],
    ];
    const doc = new jsPDF('p', 'mm', 'a4');

    // MARCA AGUA
    const waterMark = new Image();
    waterMark.src = '../../../../assets/images/bg/watermark2.png';
    // LOGO
    const img = new Image();
    img.src = '../../../../assets/images/logos/logo-adenupa.png';
    doc.addImage(img, 'png', 20, 10, 30, 10);
    // ESTILO FONT
    doc.setTextColor(117, 117, 117);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#616161');
    // TITULO
    doc.text('CRÉDITOS ACADÉMICOS NO OFICIALES', 70, 17);
    // FECHA
    doc.text(date, 170, 17);
    // TABLAS
    autoTable(doc, {
      body: dataUser,
      bodyStyles: {
        lineColor: '#000000',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: '#e5e5e5',
          fontStyle: 'bold',
          cellWidth: 30,
        },
      },
      alternateRowStyles: {
        fillColor: false,
      },
      // theme : 'plain',
      tableLineColor: '#616161',
      startY: 25,
      didDrawCell: (data) => {},
    });

    let yCoordNotes;
    autoTable(doc, {
      head: headNote,
      body: dataNote,
      headStyles: {
        fillColor: '#e5e5e5',
        fontStyle: 'bold',
        textColor: '#222222',
        lineColor: '#000000',
        lineWidth: 0.1,
        valign: 'middle',
        halign: 'center',
      },
      columnStyles: {
        0: {
          cellWidth: 25,
        },
        2: {
          halign: 'center',
        },
        3: {
          halign: 'center',
        },
        4: {
          halign: 'center',
        },
        5: {
          halign: 'center',
        },
      },
      alternateRowStyles: {
        fillColor: false,
      },
      bodyStyles: {
        lineColor: '#000000',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      startY: 75,
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordNotes = data.table;
        // console.log(yCoordNotes.finalY);
      },
    });
    doc.setTextColor(31, 31, 31);
    doc.setFontSize(8);
    doc.text(
      'APR = Aprobado; REC = Examen integral de Recuperación; PROM = Promovido',
      53,
      yCoordNotes.finalY + 10
    );
    doc.text(
      'REPR = Reprobado; NSP = No se presento; CONV = Convalidación; INC = Incompleto',
      50,
      yCoordNotes.finalY + 15
    );
    let yCoordProm;
    let page;
    autoTable(doc, {
      body: dataProm,
      // theme : 'plain',
      bodyStyles: {
        lineColor: '#000000',
        lineWidth: 0.1,
        textColor: '#222222',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: '#e5e5e5',
          fontStyle: 'bold',
          cellWidth: 60,
        },
      },
      alternateRowStyles: {
        fillColor: false,
      },
      startY: yCoordNotes.finalY + 23,
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordProm = data.table;
        page = yCoordProm;
      },
    });
    // console.log(yCoordNotes.finalY);

    let yCoordFooter;
    autoTable(doc, {
      head: [[`Reporte generado por ADEN University Panamá el día ${date}`]],
      // theme : 'plain',
      headStyles: {
        fillColor: '#B31D15',
        fontStyle: 'bold',
        textColor: '#ffffff',
        cellPadding: 5,
        halign: 'center',
        valign: 'middle',
      },
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordFooter = data.table;
        page = yCoordFooter;
      },
    });

    doc.setFontSize(10);
    doc.text('Documento no oficial', 90, yCoordFooter.finalY + 10);

    // doc.addImage(waterMark, 'png', 0, 0, 210, (yCoordProm.finalY + 20));
    // doc.addImage(waterMark, 'png', 0, 0, 210, 0);

    for (
      let pageNumber = 1;
      pageNumber <= doc.getNumberOfPages();
      pageNumber++
    ) {
      doc.setPage(pageNumber);
      doc.addImage(waterMark, 'png', 0, 0, 210, 300);
    }

    doc.save(
      'CREDITOS NO OFICIALES ADENUPA - ' +
        `${object.carrera.toUpperCase()}` +
        ' - ' +
        date
    );
    this.isLoading = false;
  }
}
