<div class="wrapper">
  <div class="content" (click)="openDetailsDialog(order)">
    <h2>Orden #{{ order.id }}</h2>
    <div
      class="chip-estado"
      [ngClass]="{
        pending: order.estado === 'pending',
        paid: order.estado === 'paid',
        cancel: order.estado === 'cancel',
        rejected: order.estado === 'rejected',
        processing: order.estado === 'processing'
      }"
    >
      {{
        order.estado === "pending"
          ? "Pendiente"
          : order.estado === "paid"
          ? "Pagado"
          : order.estado === "cancel"
          ? "Cancelado"
          : order.estado === "processing"
          ? "Procesando pago"
          : "Pago rechazado"
      }}
    </div>

    <div class="detail">
      <h4>Fecha emisión</h4>
      <p>
        {{ moment(order.fecha_emision).locale("es-us").format("LL") }}
      </p>
    </div>

    <div class="detail">
      <h4>Total a pagar</h4>
      <p>US$ {{ order.total }}</p>
    </div>
  </div>
  <div class="footer">
    <button
      mat-flat-button
      color="accent"
      class="fill"
      [disabled]="
        !order.link_pago || order.link_pago === '' || order.estado !== 'pending'
      "
      (click)="onCancel()"
    >
      Cancelar orden
    </button>
    <button
      mat-flat-button
      color="primary"
      class="fill"
      [disabled]="
        !order.link_pago || order.link_pago === '' || order.estado !== 'pending'
      "
      (click)="openLink()"
    >
      Pagar
    </button>
  </div>
</div>
