<div class="body" [routerLink]="program.tag">
  <div class="chip" *ngIf="program.open">
    {{
      today >= getDate(program.init) && today <= getDate(program.end)
        ? "¡Inscripciones abiertas!"
        : today < getDate(program.init)
        ? "¡Inscripcripciones en " + getDays(program.init) + " días!"
        : "Inscripciones cerradas"
    }}
  </div>
  <div class="chip finished" *ngIf="!program.open">Programa finalizado</div>
  <div class="heading">
    <img
      [src]="'../../../../../assets/images/logos/' + program.image"
      [alt]="program.name"
    />
  </div>
  <div class="content">
    <h1>{{ program.name }}</h1>
    <p>{{ program.description }}</p>
  </div>
  <div class="footer">
    <div class="button">
      Conocer más
      <mat-icon class="material-icons-round">arrow_forward</mat-icon>
    </div>
  </div>
</div>
