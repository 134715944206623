import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IHomeCard } from 'src/app/shared/interfaces/IHome';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() public item: IHomeCard;

  constructor(private router: Router) {}

  goTo() {
    if (this.item.link.includes('http') || this.item.link.includes('assets')) {
      localStorage.setItem('viewer_file_url', this.item.link)
      this.router.navigate(['file']);
    } else {
      this.router.navigate([this.item.link]);
    }
  }
}
  
