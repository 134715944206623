import { Component } from '@angular/core';

@Component({
  selector: 'app-ganadores-concurso',
  templateUrl: './ganadores-concurso.component.html',
  styleUrls: ['./ganadores-concurso.component.scss'],
})
export class GanadoresConcursoComponent {
  constructor() {}
}
