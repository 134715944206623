<div>
  <!-- <div class="dialog-header">
    <h2>¡Selecciona una modalidad!</h2>
  </div> -->
  <div class="dialog-container">
    <div mat-dialog-content>
      <h2>¡Selecciona una modalidad!</h2>
      <p>
        Tienes carreras de distintas modalidades, por favor, elige una para
        continuar con el proceso de rematrícula
      </p>
      <div class="cards-container">
        <div class="card-modalidad" [mat-dialog-close]="'presencial'">
          <mat-icon class="material-icons-round">place</mat-icon>
          <h4>Presencial</h4>
        </div>
        <div class="card-modalidad" [mat-dialog-close]="'online'">
          <mat-icon class="material-icons-round">laptop</mat-icon>
          <h4>Online</h4>
        </div>
      </div>
    </div>
  </div>
</div>
