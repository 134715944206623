<div class="sticky-header">
  <app-image-header
    [title]="'Pasantía de Extensión Profesional'"
    [imgURL]="'/images/content/graduacion/header-pasantia-profesional.png'"
    [titleColor]="'#b31d15'"
    [descriptionColor]="'#222222'"
    [buttonColor]="'#b31d15'"
  ></app-image-header>
  <app-onepage-nav
    [links]="links"
    [activeLink]="activeLink"
    [urlBase]="'graduacion/pasantia-extension-profesional'"
  ></app-onepage-nav>
</div>
<div class="white-bg">
  <section id="descripcion" class="container two-cols">
    <div class="body">
      <div class="content">
        <h1 class="small-title">¿De qué se trata?</h1>
        <p>
          En ADEN University se formarán profesionales altamente capacitados y
          adaptados al sector productivo del país. El curso Pasantía de
          Extensión Profesional es uno de los instrumentos de vinculación entre
          la Universidad y los sectores productivos, empresarios y
          gubernamentales, y permite que estas organizaciones incorporen
          estudiantes de ADEN University en calidad de practicantes, sumando así
          recursos humanos capacitados para desempeñar distintos roles en la
          organización.<br /><br />
          Esta posibilidad significa para los estudiantes una excelente
          oportunidad para enriquecer la formación académica recibida con
          prácticas educativas que articulan “el saber” con el “saber hacer”,
          incrementando así sus posibilidades de inserción laboral.
        </p>
      </div>
      <div class="image">
        <img
          src="../../../../assets/images/content/graduacion/pasantia-profesional.png"
          alt="Pasantía de Responsabilidad Social Comunitaria"
          class="shadow main-radius"
        />
      </div>
    </div>
  </section>
</div>

<section id="objetivos" class="container two-cols">
  <div class="body">
    <div class="content top">
      <h1 class="small-title">Objetivos generales</h1>
      <p>
        La Práctica de Extensión Profesional tendrá como objetivo que los
        estudiantes puedan poner en práctica los conocimientos teóricos
        adquiridos durante su formación universitaria, tomen contacto con el
        ámbito en que se desenvuelven las actividades propias del organismo o
        empresa donde se desarrollen las prácticas y se integren a un grupo
        laboral. De esta forma, se contribuirá a la transición entre la etapa
        educativa y la laboral de los practicantes, en vista de una mejor
        inserción en el campo laboral.
      </p>
    </div>
    <div class="content top">
      <h1 class="small-title">Objetivos específicos</h1>
      <ul>
        <li>
          Permitir la comparativa entre lo aprendido dentro del aula de clases
          versus lo que se desarrolla en la realidad del mercado laboral.
        </li>
        <li>
          Conocer y adaptarse al funcionamiento de una empresa o institución en
          la que se desarrollen actividades del área de conocimiento de la
          carrera.
        </li>
        <li>Fomentar el acercamiento empresa-universidad.</li>
        <li>
          Desarrollar un informe final que contenga todos los elementos
          necesarios para la presentación de la experiencia vivida en la empresa
          durante el tiempo de práctica.
        </li>
      </ul>
    </div>
  </div>
</section>

<section
  class="parallax"
  [ngStyle]="{
    'background-image':
      'url(https://i.postimg.cc/434WtJy2/bg-pasantia-profesional.jpg)'
  }"
></section>
<div class="white-bg">
  <section id="etapas-y-contenidos" class="container">
    <div class="center-body left small-bottom">
      <h1>Etapas y contenidos</h1>
      <p>
        La materia de práctica profesional sigue el mismo proceso de apertura y
        cierre dentro de un periodo académico. Los recursos de evaluación
        tendrán fechas programadas para sus entregas, por lo que incumplir con
        estas fechas afectará en la nota final de los estudiantes y luego de
        cerrado el periodo de la materia no se podrán hacer modificaciones.
      </p>
      <mat-accordion>
        <mat-expansion-panel class="mat-elevation-z0" expanded>
          <mat-expansion-panel-header>
            <mat-panel-title class="panel-title">
              Presencial y Online <span> | SIN EMPLEO</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <p>
              En las licenciaturas en modalidad presencial y online los
              estudiantes que no poseen empresa donde practicar ni experiencia
              previa en el campo laboral vinculado a su área de estudio, deberán
              desarrollar el proceso siguiente proceso:
            </p>
            <h2><span>FASE I: Inicio proceso de práctica profesional</span></h2>
            <ol>
              <li>
                <strong
                  >Inducción General del proceso de Práctica Profesional: </strong
                ><br />
                En esta etapa, el estudiante recibirá información sobre el
                proceso de práctica profesional que estará cumpliendo,
                relacionada con las empresas donde pueden realizar la actividad,
                la evaluación de la asignatura, el proceso de seguimiento, entre
                otros elementos de importancia para la práctica profesional.
              </li>
              <li>
                <strong
                  >Ubicación de vacantes y empresas de prácticas profesionales: </strong
                ><br />
                Una vez recibida la información del proceso de prácticas, el
                alumno en la fase 2, presenta las opciones de empresa donde
                quisiera desarrollar la actividad para validación por parte de
                la universidad, o se somete a postulaciones por parte de la
                universidad en empresas donde se generen vacantes, que cumplan
                con los requisitos para ser sitios de práctica profesional, en
                estas empresas atenderá las entrevistas necesarias para su
                aceptación.
              </li>
            </ol>
            <h2><span>FASE II: Desarrollo de la práctica profesional</span></h2>
            <ol>
              <li>
                <strong>Inicio de la práctica profesional: </strong><br />
                Una vez que el estudiante ha sido aceptado en una empresa, esta
                recibe una carta de postulación formal por parte de la
                universidad donde se autoriza al estudiante a iniciar su
                práctica profesional, a la misma, la empresa debe dar respuesta
                mediante una carta de aceptación. El alumno entonces inicia su
                proceso de práctica atendiendo el turno completo y modalidad que
                la empresa indique, durante 6 meses calendario para la modalidad
                presencial y 4 meses para la modalidad online.
              </li>
              <li>
                <strong
                  >Desarrollo y culminación de la práctica profesional: </strong
                ><br />
                El alumno desarrollará sus controles de asistencias diarios,
                semanales y mensuales y atenderá todas las instrucciones que
                vengan de parte de su tutor institucional o empresarial, el cual
                será una persona idónea con experiencia en el área de estudio
                del alumno que indicará las funciones a cumplir por parte del
                mismo, así como supervisará estas funciones y evaluará el
                desempeño final del alumno.
              </li>
            </ol>

            <h2>
              <span>FASE III: Evaluación de la práctica profesional</span>
            </h2>
            <ol>
              <li>
                <strong
                  >Evaluación por parte de los tutores institucionales: </strong
                ><br />
                Una vez que culmine la actividad, el tutor estará en la
                obligación de evaluar el rendimiento del estudiante mediante el
                formato que la universidad le designe y brindará la carta de
                culminación correspondiente. El tutor académico por su parte
                recibirá la evaluación y procederá a evaluar el informe final,
                sustentación y desempeño académico del alumno mediante formato
                correspondiente.
              </li>
              <li>
                <strong>Elaboración del informe final: </strong><br />
                El alumno realizará el informe final basado en el documento
                contentivo del formato necesario para tal fin. Deberá seguir
                este formato y estructura para presentar el informe final al
                tutor académico para su revisión y calificación.
              </li>
              <li>
                <strong>Sustentación del informe final: </strong><br />
                Una vez realizado el informe final, el tutor académico indicará
                fecha y hora de entrega del mismo, así como sustentación de su
                contenido, el cual tendrá un tiempo determinado de duración y
                normas específicas para su ejecución.
              </li>
              <li>
                <strong>Evaluación por parte de la universidad: </strong><br />
                Finalmente, una vez entregada a la universidad la evaluación del
                tutor institucional o empresarial, el informe final,
                desarrollada la sustentación y habiendo entregado el alumno sus
                controles de asistencias y carta de culminación, el tutor
                académico tiene la tarea de revisar toda esta documentación y
                calificar finalmente la práctica profesional, la cual resultará
                de un promedio entre la evaluación de la empresa y la
                universidad.
              </li>
            </ol>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title class="panel-title">
              Online <span> | CON EMPLEO</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <p>
              Para las licenciaturas online y cuando se trate de un alumno con
              experiencia previa en el campo laboral y que labora actualmente,
              este alumno pasará al aula denominada “Experiencia Profesional”.
              En esta aula seguirá los siguientes pasos:
            </p>
            <h2><span>FASE I: Inicio proceso de práctica profesional</span></h2>
            <ol>
              <li>
                <strong
                  >Introducción General del proceso de Práctica Profesional: </strong
                ><br />
                En esta etapa, el estudiante recibirá información sobre el
                proceso de práctica profesional que estará cumpliendo.
              </li>
              <li>
                <strong
                  >Entrega de documentación de validación de experiencia: </strong
                ><br />
                Una vez recibida la información del proceso de prácticas, el
                alumno en la fase 2, presenta los documentos necesarios para
                poder validar su experiencia: hoja de vida actualizada, carta de
                trabajo actual, referencias de trabajos anteriores.
              </li>
            </ol>
            <h2><span>FASE II: Desarrollo de la práctica profesional</span></h2>
            <ol>
              <li>
                <strong>Inicio de la práctica profesional: </strong><br />
                Una vez el alumno hace entrega de la documentación laboral, se
                le agrega al aula de “Experiencia Profesional”para que dé inicio
                al desarrollo del informe correspondiente.
              </li>
              <li>
                <strong
                  >Desarrollo y culminación de la práctica profesional: </strong
                ><br />
                El alumno contará con 4 meses para el desarrollo del informe
                final, durante este tiempo puede hacer las consultas
                correspondientes al tutor académico, en cuanto a dudas que
                poseen sobre el mismo.
              </li>
            </ol>
            <h2>
              <span>FASE III: Evaluación de la práctica profesional</span>
            </h2>
            <ol>
              <li>
                <strong>Entrega y sustentación del informe final: </strong
                ><br />
                El alumno al culminar los 4 meses brindados, deberá hacer
                entrega del informe sin prórrogas. Adicionalmente deberá
                entregar junto al informe, un video de 15 minutos, donde resuma
                el contenido del informe y la experiencia profesional. Para
                realizar dicho video, el alumno contará en canvas con los
                elementos que deberá incluir en la presentación.
              </li>
              <li>
                <strong>Evaluación por parte de la universidad: </strong><br />
                El tutor académico revisará el informe y el video enviado, y
                procederá a llenar la evaluación correspondiente a la
                asignatura. Finalizando con la entrega de la nota al alumno vía
                canvas y la entrega de documentos para el archivo general.
              </li>
            </ol>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
  <section id="metodologia" class="container">
    <div class="center-body left small-top small-bottom">
      <h1>Metodología</h1>
      <p>
        La asignatura contará con dos tipos de tutores, el tutor institucional o
        empresarial, responsable de supervisar el cumplimiento de la línea
        programática del estudiante dentro de la empresa y el tutor académico,
        responsable del cumplimiento de las actividades
        pedagógico-profesionales. Les compete a los tutores diseñar de mutuo
        acuerdo la dinámica institucional académica que acompañará la práctica
        profesional, resolver las situaciones que interfieran en la experiencia
        y tomar las decisiones que crean convenientes para garantizar el trabajo
        profesional del estudiante.
      </p>
    </div>
  </section>
  <section id="criterios-evaluacion" class="container">
    <div class="center-body left small-top small-bottom">
      <h1>Criterios de evaluación</h1>
      <p>
        La evaluación de la práctica profesional será un proceso continuo que se
        inicia desde la matrícula del estudiante en la asignatura, consistentes
        en la participación en las reuniones iniciales, entrega de documentos
        solicitados, y comunicaciones iniciales. Posteriormente, y una vez
        ubicado el alumno dentro de la organización donde vaya a realizar su
        práctica profesional, también será evaluado por el tutor institucional o
        empresarial según su desempeño, destrezas y habilidades manifiestas para
        el alcance de los objetivos organizacionales asignados, así como por el
        tutor académico que en todo momento se involucrará en el seguimiento del
        alumno.
      </p>
      <p>
        El tutor académico solicitará avances de la actividad a través del
        formato de control de asistencias, reuniones con el alumno y el tutor
        institucional y correos electrónicos que brinden sustento a la
        actividad. Al finalizar la actividad, el alumno será evaluado por la
        empresa (tutor institucional o empresarial) con un instrumento entregado
        por la universidad para tal fin, así como por el tutor académico a
        través de la entrega del informe de su experiencia y su sustentación,
        además de la conducta académica mantenida durante todo el proceso de
        práctica profesional.
      </p>
    </div>
  </section>
  <section id="alianzas" class="container">
    <div class="center-body left small-top small-bottom">
      <h1>Alianzas</h1>
      <p>
        Actualmente hay alianzas con compañías tanto públicas como privadas que
        han recibido a nuestros estudiantes.
      </p>
      <div class="img-list">
        <ng-container *ngFor="let img of alianzas; let i = index">
          <img [src]="img" alt="alianza-{{ i }}" width="150" />
        </ng-container>
      </div>
    </div>
  </section>
  <section id="testimonios" class="container">
    <div class="center-body left small-top">
      <h1>Testimonios</h1>
      <div class="card-column">
        <ng-container *ngFor="let item of testimonies; let i = index">
          <app-testimony-card [testimony]="item"></app-testimony-card>
        </ng-container>
      </div>
    </div>
  </section>
  <section id="direccion" class="container">
    <div class="center-body left small-top">
      <h1>Directora de práctica profesional</h1>
      <app-testimony-card
        [testimony]="{
          name: 'Lorena Gamboa',
          description:
            'Experiencia de más de 15 años en el área de la Educación Superior, asumiendo diversas funciones y cargos académicos y administrativos en Universidades públicas y privadas (Dentro y fuera de Panamá). Licenciada en Gerencia de Recursos Humanos (Magna Cum Laude), Licenciada en Contaduría Pública, Magíster en Dirección de Empresas, Magister en Docencia Superior, Especialista en Finanzas.',
          image: 'https://i.postimg.cc/FRK8WkW3/lorena-gamboa.jpg',
          company: 'Directora de Práctica Profesional'
        }"
        [inverted]="true"
      ></app-testimony-card>
    </div>
  </section>
</div>

<section id="faq" class="container">
  <div class="center-body left small-bottom">
    <h1>Preguntas Frecuentes</h1>
    <mat-accordion>
      <ng-container *ngFor="let item of faqs">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ item.q }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{ item.a }}</p>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</section>

<section id="consultas">
  <div class="container">
    <div class="grid-container">
      <div class="content">
        <h2>¿Te has quedado con dudas?</h2>
        <p>
          Envíanos tu consulta y en breve será respondida a tu correo
          electrónico
        </p>
        <form class="form-contact">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Escribe tu consulta</mat-label>
            <textarea
              matInput
              #message
              maxlength="800"
              [formControl]="messageCtrl"
              required
            ></textarea>
            <mat-error *ngIf="messageCtrl.hasError('required')">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-hint align="end"
              >{{ message.value?.length || 0 }}/800
            </mat-hint>
          </mat-form-field>

          <button
            mat-flat-button
            color="primary"
            [disabled]="messageCtrl.hasError('required') || sendingMessage"
            (click)="sendMessage()"
          >
            <div class="submit-button">
              {{ sendingMessage ? "Enviando" : "Enviar consulta" }}
              <div class="small-loader" *ngIf="sendingMessage"></div>
            </div>
          </button>
        </form>
      </div>
      <div class="image">
        <img
          src="../../../../assets/images/content/adenpolis/dudas.svg"
          alt="Ayuda"
        />
      </div>
    </div>
  </div>
</section>
