<div class="main-container main-shadow">
  <h1 class="title">Créditos Académicos (no oficiales)</h1>

  <div class="flex-container">
    <div *ngFor="let carrera of (user$ | async)?.carreras">
      <!-- <button
        mat-flat-button
        color="primary"
        (click)="getExcelData(carrera.carrera_estudiante)"
      >
        {{ carrera.carrera.replace("Licenciatura", "Lic.") }}
        <span class="material-icons ml-1"> download </span>
      </button> -->
      <div
        class="download-card"
      >
        <div class="body">
          <p>{{ carrera.carrera.replace("Licenciatura", "Lic.") }}</p>
        </div>
        <div (click)="getExcelData(carrera.carrera_estudiante)" class="download-btn">
          <i class="fa-light fa-arrow-down-to-line"></i>
          <div class="download-title">Descargar créditos académicos</div>
        </div>
      </div>
    </div>
  </div>

  <div id="loading" *ngIf="isLoading">
    <div class="container">
      <mat-spinner></mat-spinner>
      <div class="mt-4">Estamos generando tu reporte...</div>
    </div>
  </div>
</div>
