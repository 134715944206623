<div class="card-body" *ngIf="!isTeam">
  <div class="circle-img">
    <img [src]="img" alt="Foto del usuario" />
  </div>

  <div class="nombre">{{ miembro.name }}</div>
  <div class="email">{{ miembro.mail }}</div>
  <div *ngIf="isCapitan === 'true'" class="badge">CAPITÁN</div>
  <div *ngIf="isCapitan === 'false'">
    <button mat-icon-button color="accent" (click)="removeMember(miembro)">
      <mat-icon class="material-icons-round">delete</mat-icon>
    </button>
  </div>
</div>

<div class="card-body-team" *ngIf="isTeam">
  <div class="circle-img">
    <img [src]="img" alt="Foto del usuario" />
  </div>

  <div class="nombre">{{ miembro.name }}</div>
  <div class="email">{{ miembro.mail }}</div>
  <div *ngIf="isCapitan === 'true'" class="badge">CAPITÁN</div>
  <div *ngIf="isCapitan === 'false'">
    <button mat-icon-button color="accent" (click)="removeMember(miembro)">
      <mat-icon class="material-icons-round">delete</mat-icon>
    </button>
  </div>
</div>
