import { ActivatedRoute, Router } from '@angular/router';
import { CmsService } from './../../../shared/services/cms.service';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-detalle-anuncio',
  templateUrl: './detalle-anuncio.component.html',
  styleUrls: ['./detalle-anuncio.component.scss'],
})
export class DetalleAnuncioComponent implements OnInit {
  public post;
  public slug;
  public moment = moment;

  public loadingOthers = true;
  public errorOthers = false;

  public loadingMain = true;
  public errorMain = false;

  public otherPosts = [];

  constructor(
    private cmsService: CmsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private markdownService: MarkdownService
  ) {}

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params) => {
      try {
        this.slug = params.get('slug');
        this.getAnuncio();
      } catch (error) {
        this.router.navigateByUrl('/anuncios');
      }
    });
  }

  getAnuncio() {
    this.loadingMain = true;
    this.cmsService.getShortPostBySlug(this.slug).subscribe({
      next: (res) => {
        this.post = res.data[0];
        this.loadingMain = false;
        this.errorMain = false;
        this.getOthers();
      },
      error: (err) => {
        this.loadingMain = false;
        this.errorMain = true;
      },
    });
  }

  getOthers() {
    this.loadingOthers = true;
    const query = `filters[slug][$ne]=${this.slug}&pagination[limit]=5`;

    this.cmsService.getShortPosts(query).subscribe({
      next: (res) => {
        this.otherPosts = res.data;
        this.loadingOthers = false;
        this.errorOthers = false;
      },
      error: (err) => {
        this.loadingOthers = false;
        this.errorOthers = true;
      },
    });
  }
}
