<div class="card-container">
  <div class="body-contain">
    <div class="body">
      <div class="tag">{{item.nombre}} ({{item.modalidad}})</div>
      <h2 class="item-certification">{{ item.certificacion }}</h2>
      <p>Selecciona el tipo de certificado que deseas recibir: </p>
          <label *ngFor="let check of certificateItem" class="container">
            <input required type="checkbox" 
            name="checkbox" 
            [checked]="check.checked" 
            (change)="changeCertificate(check.type)"
            >
            <span class="checkmark"></span>
            {{check.label}}
         </label>
         <p *ngIf="showAlert" class="alert">Por favor selecciona una opción</p> 
    </div>
  </div>
  <div class="footer">
    <div class="price">US$ {{ item.valor_usd }}</div>
    <button
      *ngIf="!cartItem || cartItem.quantity <= 0"
      mat-flat-button
      color="primary"
      (click)="addToCart()"
    >
      <mat-icon class="material-icons-round">add_shopping_cart</mat-icon>
      Añadir
    </button>
    <div class="counter" *ngIf="cartItem && cartItem.quantity > 0">
      <button mat-icon-button color="primary" (click)="remove()">
        <mat-icon class="material-icons-round">{{
          cartItem.quantity === 1 ? "delete" : "remove_circle"
        }}</mat-icon>
      </button>
      <p>{{ cartItem.quantity }}</p>
      <button mat-icon-button color="primary" (click)="addToCart()">
        <mat-icon class="material-icons-round">add_circle</mat-icon>
      </button>
    </div>
  </div>
</div>
