import { ContestService } from './../../../shared/services/contest.service';
import {
  ContestMember,
  ContestTeam,
  ContestTutor,
} from './../../../shared/interfaces/IContest';
import { Component, OnInit } from '@angular/core';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-concurso',
  templateUrl: './concurso.component.html',
  styleUrls: ['./concurso.component.scss'],
})
export class ConcursoComponent implements OnInit {
  capitan: ContestMember;
  hasTeam: boolean;
  alumnos: ContestMember[] = [];
  userTeam: ContestTeam;

  constructor(
    private alertService: AlertService,
    private contestService: ContestService,
    private alumnoService: AlumnoService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.alumnoService.getAlumno().subscribe({
      next: (res) => {
        /* CHEQUEAR SESIÓN */

        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.email === '' || !res.email || res.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
        this.capitan = {
          id: res.legajo,
          name: res.apellido,
          mail: res.email,
          img: `data:image/png;base64,${res.foto64}`,
        };

        this.checkUser(res.legajo);
      },
      error: (err) => {
        this.alertService.openAlert(
          '¡Sesión vencida!',
          'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
          'Ok'
        );
        setTimeout(() => {
          this.authService.logout();
        }, 5000);
      },
    });
  }

  checkUser(id): void {
    this.contestService.checkUser(id).subscribe({
      next: (res) => {
        this.userTeam = res.equipaments;
        this.hasTeam = !res.avaliable;

        localStorage.setItem('equipo', JSON.stringify(res.equipaments));
        if (res.equipaments.assigned_tutor_id !== undefined) {
          const assignedTutor: ContestTutor = res.equipaments.tutors.find(
            (tutor) => tutor.id === res.equipaments.assigned_tutor_id
          );
          localStorage.setItem('assignedTutor', JSON.stringify(assignedTutor));
        }
      },
      error: (err) => {
        console.log('error');
      },
    });
  }
}
