import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/tooltip';

export interface DialogData {
  link: string;
  orden: string;
}

export const copyTooltip: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-order-sucess-dialog',
  templateUrl: './order-sucess-dialog.component.html',
  styleUrls: ['./order-sucess-dialog.component.scss'],
})
export class OrderSucessDialogComponent implements OnInit {
  public disabledTooltip = true;
  constructor(
    public dialogRef: MatDialogRef<OrderSucessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    console.log(`Link de pago: ${this.data.link} - Orden: ${this.data.orden}`);
  }
  onClose(): void {
    this.dialogRef.close();
  }

  goToPay(): void {
    window.open(this.data.link, '_blank');
    this.onClose();
  }

  copyLink(): void {
    this._snackBar.open('¡Enlace copiado!', 'Cerrar', {
      duration: 3000,
    });
    this.disabledTooltip = false;
  }
}
