<div class="main">
    <div class="card-container">
        <div class="img-container">
            <img src="../../../assets/images/logos/adenopolis-img.png" alt="">
        </div>
        <div class="title">Sobre ADENPÓLIS</div>
            <div class="text">
                En el comité estudiantil ADENPÓLIS, tienes la oportunidad de desarrollar tus habilidades de liderazgo participando en actividades extracurriculares y proyectos de servicio. Además, podrás contribuir al espíritu universitario mientras enriqueces tu formación integral.
            </div>
        <div class="button-container">
            <button (click)="navigateTo('comite-estudiantil')">Ver más</button>
        </div>
    </div>
    <div class="card-container">
        <div class="img-container">
            <img src="../../../assets/images/logos/postulaciones-img.png" alt="">
        </div>
        <div class="title">Postulaciones</div>
            <div class="text">
                Si posees las cualidades necesarias para formar parte del comité y asumir el liderazgo de tu carrera, revisa los requisitos, completa el formulario y postúlate.
            </div>
        <div class="button-container">
            <button (click)="navigateTo('comite-estudiantil/postulaciones')">Ver más</button>
        </div>
    </div>
    <div class="card-container">
        <div class="img-container">
            <img src="../../../assets/images/logos/elecciones-img.png" alt="">
        </div>
        <div class="title">Candidatos y elecciones</div>
            <div class="text">
                ¡Es tu oportunidad de participar! Elige al candidato que mejor te represente y emite tu voto. Aquí encontrarás todos los postulantes de cada carrera seleccionados para participar en las elecciones de este año. 
            </div>
        <div class="button-container">
            <button (click)="navigateTo('comite-estudiantil/elecciones')">Ver más</button>
        </div>
    </div>
</div>