<div class="container-bg">
  <div class="container">
    <div class="heading">
      <div class="title blue">¿Tienes dudas?</div>
      <div class="description">
        Envíanos tu consulta y en breve será respondida a tu correo electrónico
      </div>
    </div>

    <form class="form-contact">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Escribe tu consulta</mat-label>
        <textarea
          matInput
          #message
          placeholder="Team Adenu"
          maxlength="800"
          [formControl]="messageCtrl"
          [errorStateMatcher]="matcher"
          required
        ></textarea>
        <mat-error *ngIf="messageCtrl.hasError('required')">
          Este campo es <strong>obligatorio</strong>
        </mat-error>
        <mat-hint align="end">{{ message.value?.length || 0 }}/800 </mat-hint>
      </mat-form-field>
      <button
        mat-flat-button
        [disabled]="messageCtrl.hasError('required')"
        id="send-button"
        color="accent"
        (click)="sendMessage()"
      >
        Enviar
      </button>
    </form>
  </div>
</div>
