import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullMonth',
})
export class FullMonthPipe implements PipeTransform {
  transform(month): string {
    const FINAL_MONTH = {
      ENE: 'Enero',
      FEB: 'Febrero',
      MAR: 'Marzo',
      ABI: 'Abril',
      MAY: 'Mayo',
      JUN: 'Junio',
      JUL: 'Julio',
      AGO: 'Agosto',
      SEP: 'Septiembre',
      OCT: 'Octubre',
      NOV: 'Noviembre',
      DIC: 'Diciembre',
    };
    return FINAL_MONTH[month] || month;
  }
}
