export const CreditsData = {
    1: {
      nombre: 'Regular',
      description: 'Utiliza los créditos Regulares para matricular las asignaturas de tu carrera principal',
    },
    7: {
      nombre: 'Dual Degree',
      description: 'Utiliza los créditos Dual Degree para matricular las asignaturas de tu segunda carrera',
    },
    3: {
      nombre: 'Práctica Social',
      description: 'Utiliza este crédito para matricularte a la asignatura de Práctica Social',
    },
    2: {
      nombre: 'Práctica Profesional',
      description: 'Utiliza este crédito para matricularte a la asignatura de Práctica Profesional',
    },
    4: {
      nombre: 'Opción de grado',
      description: 'Utiliza este crédito para matricularte a la Opción de Grado',
    },
    6: {
      nombre: 'Tesis',
      description: 'Utiliza este crédito para matricularte a la Opción de Grado Tesis',
    },
    5: {
      nombre: 'Business',
      description: 'Utiliza este crédito para matricularte a la Opción de Grado Business Plan',
    },
    10: {
      nombre: 'Materia Presencial',
      description: 'Utiliza este crédito para matricularte a las asignaturas que se cursan de forma presencial',
    },
    15: {
      nombre: 'Regular (Presencial)',
      description: 'Utiliza los créditos Regulares para matricular las asignaturas de tu carrera principal',
    },
    19: {
      nombre: 'Dual Degree (Presencial)',
      description: 'Utiliza los créditos Dual Degree para matricular las asignaturas de tu segunda carrera',
    },
    17: {
      nombre: 'Práctica Social (Presencial)',
      description: 'Utiliza este crédito para matricularte a la asignatura de Práctica Social',
    },
    16: {
      nombre: 'Práctica Profesional (Presencial)',
      description: 'Utiliza este crédito para matricularte a la asignatura de Práctica Profesional',
    },
    18: {
      nombre: 'Opción de grado (Presencial)',
      description: 'Utiliza este crédito para matricularte a la Opción de Grado',
    },
    20: {
      nombre: 'Tesis (Presencial)',
      description: 'Utiliza este crédito para matricularte a la Opción de Grado Tesis',
    },
    21: {
      nombre: 'Business (Presencial)',
      description: 'Utiliza este crédito para matricularte a la Opción de Grado Business Plan',
    }
  };