import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-testimony-card',
  templateUrl: './testimony-card.component.html',
  styleUrls: ['./testimony-card.component.scss'],
})
export class TestimonyCardComponent {
  @Input() testimony: any = null;
  @Input() inverted = false;

  constructor() {}
}
