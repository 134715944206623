import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MultiDocsModalComponent } from 'src/app/pages/perfil/components/multi-docs-modal/multi-docs-modal.component';
import { UserDoc } from 'src/app/shared/interfaces/IUserDoc';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { DocumentsService } from 'src/app/shared/services/documents.service';
import { FileDescriptionDialogComponent } from '../file-description-dialog/file-description-dialog.component';
import { DialogData } from '../share-dialog/share-dialog.component';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';
import * as LZString from 'lz-string';


@Component({
  selector: 'app-file-mode',
  templateUrl: './file-mode.component.html',
  styleUrls: ['./file-mode.component.scss']
})
export class FileModeComponent implements OnInit {

  constructor(
    private alertService: AlertService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private documentsService: DocumentsService,
    public dialogRef: MatDialogRef<FileModeComponent>
  ) { }
  @Input() userDoc: UserDoc;
  @Input() isSpecMode: boolean;
  isUploading;
  qrString = '';
  // @Input() changeDocState: (docId, state) => void;




  ngOnInit(): void {
    this.userDoc = this.data.userDoc;
    const url = environment.webUrl;
    const refresh_token = localStorage.getItem('qr_token');
    const userToken = localStorage.getItem('token');
    console.log(userToken)
    console.log(this.userDoc)
    const loginParams = {
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
    }
    this.authService.loginCampusQR(loginParams).subscribe({
      next: (res:any) => {  
        const secondLoginToken = res.token;
        const dataToRefresh = {
          qr_status: 'active',
          access_token: res.token,
          multiple_load: this.userDoc.carga_multiple ? this.userDoc.carga_multiple : false,
          document_id: this.userDoc.category_id,  
          document_status: this.userDoc.estado
        }
        this.authService.updateRefreshToken(dataToRefresh).subscribe({
          next: (res) => {
            console.log(res)
              this.qrString = `${url}/upload/${secondLoginToken}/doc`;
              console.log(this.qrString)
            },
            error: (err) => {
              console.log(err)
          }
        }
        )
      },
      error: (err) => {
        console.log(err)
      }
    })
  }

  refreshData = () => {
    this.dialogRef.close({refresh: true});
  }


  

  onFileSelected(event, isMulti){
    this.isUploading = true;
    if(isMulti){
      this.checkMultiFiles(event.target.files)
    }else{
      const file: File = event.target.files[0];
      this.checkFile(file);
    }
    console.log(event.target.files)
  }

  viewMultiDocs(){
    if(this.userDoc.documentos_adjuntos && this.userDoc.documentos_adjuntos.length > 0){
      console.log(this.userDoc.documentos_adjuntos)
     let dialogRef = this.dialog.open(MultiDocsModalComponent, {
      width: '25rem',
      maxHeight: '40rem',
      panelClass: ['multi-docs-modal'],
      data: {
        userDoc: this.userDoc,
        checkFile: this.checkFile
      }
    })
    dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  checkMultiFiles(filesObj){
    if(filesObj.length > 10){
      this.alertService.openAlert(
        '¡Son demasiados archivos!',
        'Sólo puedes subir hasta 10 archivos a la vez',
        'Aceptar',
        null, 
        'error',
        'error'
      );
    }else{
      const filesArr = Object.values(filesObj);
      const acceptedFiles = ['jpg', 'jpeg', 'png', 'pdf'];
      const validateFileSize = filesArr.find((file: any) => file.size > 10000000);
      if(!validateFileSize){
        this.postMultiDocs(filesObj) 
      }else{
        this.isUploading = false;
        this.alertService.openAlert(
          '¡Tu archivo es muy pesado!',
          'El archivo no debe exceder los 10MB, reduce su peso e intenta nuevamente',
          'Aceptar',
          null,
          'error',
          'error'
        );
      }
    }
  }

  checkFile(file, isMulti = false) {
    const fileSize = file.size / 2024 / 2024
    const fileType = file.name.split('.').pop();
    const acceptedFiles = ['jpg', 'jpeg', 'png', 'pdf'];

    if (file && fileSize <= 10) {
      if (acceptedFiles.includes(fileType)) {
        if(isMulti){
          this.updateDoc(file, isMulti)
        }
        this.userDoc.estado?.toLowerCase() === 'pendiente'
          ? this.postDoc(file)
          : this.updateDoc(file);
      } else {
        this.isUploading = false;
        this.alertService.openAlert(
          'No aceptamos este tipo de archivo',
          'Sólo puedes subir tus documentos como PDF o en formato de imagen: JPG, JPEG o PNG',
          'Aceptar',
          this.refreshData,
          'error',
          'error'
        );
      }
    } else {
      this.isUploading = false;
      this.alertService.openAlert(
        '¡Tu archivo es muy pesado!',
        'El archivo no debe exceder los 20MB, reduce su peso e intenta nuevamente',
        'Aceptar',
        null,
        'error',
        'error'
      );
    }
  }

  postMultiDocs(files){
    this.documentsService.postMultiDocs(files, this.userDoc.category_id).subscribe(
      (res) => {
        this.isUploading = false;
        this.alertService.openAlert(
          '¡Documentos cargados con éxito!',
          'Ya recibimos tus documentos, una vez que sean revisados verás el estado de la entrega aquí.',
          'Aceptar',
          this.refreshData,
          'success',
          'check_circle'
        );
        // this.changeDocState(this.userDoc.category_id, 'En revisión');
        // this.getDocs();
      },
      (error) => {
        console.log('ERROR', error)
        this.isUploading = false;
        this.alertService.openAlert(
          'No pudimos cargar el documento',
          'Intenta de nuevo más tarde',
          'Aceptar',
          this.refreshData,
          'error',
          'error'
        );
      }
    );
  }

  postDoc(file) {
    this.documentsService.postDocument(file, this.userDoc.category_id).subscribe(
      (res) => {
        this.isUploading = false;
        this.alertService.openAlert(
          '¡Documento cargado con éxito!',
          'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí.',
          'Aceptar',
          this.refreshData,
          'success',
          'check_circle'
        );
        // this.changeDocState(this.userDoc.category_id, 'En revisión');
        //this.getDocs();
      },
      (error) => {
        console.log('ERROR', error)
        this.isUploading = false;
        this.alertService.openAlert(
          'No pudimos cargar el documento',
          'Intenta de nuevo más tarde',
          'Aceptar',
          this.refreshData,
          'error',
          'error'
        );
      }
    );
  }

  updateDoc(file, isMulti = false) {
    if(isMulti){
      this.documentsService.updateMultiDocs(file, this.userDoc.id).subscribe(
        (res) => {
          this.isUploading = false;
          this.alertService.openAlert(
            '¡Documento actualizado con éxito!',
            'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí',
            'Aceptar',
            this.refreshData,
            'success',
            'check_circle'
          );
          /* this.getDocs(); */
          // this.changeDocState(this.userDoc.category_id, 'En revisión');
        },
        (error) => {
          this.isUploading = false;
          this.openSnackBar(
            'No pudimos actualizar el documento, intenta de nuevo más tarde',
            'OK',
            'error'
          );
        }
      )
    }else{
      this.documentsService.updateDocument(file, this.userDoc.id).subscribe(
        (res) => {
          this.isUploading = false;
          this.alertService.openAlert(
            '¡Documento actualizado con éxito!',
            'Ya recibimos tu documento, una vez que sea revisado verás el estado de la entrega aquí',
            'Aceptar',
            this.refreshData,
            'success',
            'check_circle'
          );
          /* this.getDocs(); */
          // this.changeDocState(this.userDoc.category_id, 'En revisión');
        },
        (error) => {
          this.isUploading = false;
          this.openSnackBar(
            'No pudimos actualizar el documento, intenta de nuevo más tarde',
            'OK',
            'error'
          );
        }
      );
    }
  }

  openSnackBar(message: string, action: string, type: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: [`${type}-snackbar`],
    });
  }

}
