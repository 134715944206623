<div class="main" *ngIf="actionSelected === 'doc'">
    <div class="container" *ngIf="isLoading">
        <div class="center">
          <mat-spinner></mat-spinner>
        </div>
      </div> 
    <div class="card">
        <div class="upload-header">
            <h3>Sube tus documentos</h3>
            <div class="subtitle">Los archivos aceptados son .jpg, .jpeg, .png, .pdf</div>
        </div>
        <input type="file" accept=".jpg, .jpeg, .png, .pdf" (change)="onFileSelected($event, false)" #fileUpload hidden />
        <div (click)="fileUpload.click()" class="upload-img-container">
           <mat-icon class="material-icons-round">unarchive</mat-icon>
        </div>
    </div>
</div>
<div class="main" *ngIf="actionSelected === 'multi'">
    <div class="card">
        <div class="upload-header">
            <h3>Sube tus documentos</h3>
            <div class="subtitle">Los archivos aceptados son .jpg, .jpeg, .png, .pdf</div>
        </div>
        <input type="file" multiple="multiple" accept=".jpg, .jpeg, .png, .pdf" (change)="onFileSelected($event, true)" #fileUpload hidden />
        <div (click)="fileUpload.click()" class="upload-img-container">
           <mat-icon class="material-icons-round">unarchive</mat-icon>
        </div>
    </div>
</div>