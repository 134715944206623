import {
  ContestMember,
  ContestTeam,
} from './../../../../../shared/interfaces/IContest';
import { ContestService } from './../../../../../shared/services/contest.service';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompletedDialogComponent } from 'src/app/shared/components/dialogs/completed-dialog/completed-dialog.component';

@Component({
  selector: 'app-upload-concurso',
  templateUrl: './upload-concurso.component.html',
  styleUrls: ['./upload-concurso.component.scss'],
})
export class UploadConcursoComponent {
  files: any[] = [];

  finishedUpload = true;
  isWaiting = false;

  @Input() capitan: ContestMember;
  @Input() team: ContestTeam;

  constructor(
    private contestService: ContestService,
    public dialog: MatDialog
  ) {}

  /**
   * on file drop handler
   */
  onFileDropped($event): void {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files): void {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number): void {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number): void {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 100);
      }
    }, 100);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>): void {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  sendFiles(): void {
    this.isWaiting = true;
    const sendBtnText = document.getElementById('send-text');
    document.getElementById('loader').classList.remove('no-display');
    document.getElementById('enviarArchivos').classList.add('transparent');
    document.getElementById('enviarArchivos').setAttribute('disabled', 'true');
    sendBtnText.innerHTML = 'Enviando';

    const formData = new FormData();

    this.files.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    if (this.team.files_folder !== undefined) {
      this.simpleUpload(this.team.files_folder, formData);
    } else {
      this.createFolderUpload(formData);
    }
  }

  simpleUpload(folderId, formData): void {
    const sendBtnText = document.getElementById('send-text');
    this.contestService.uploadFiles(folderId, formData).subscribe({
      next: (res) => {
        this.isWaiting = false;
        this.openCompletedDialog(
          'success',
          '¡Archivos cargados con éxito!',
          'Los archivos fueron enviados para evaluar, recuerda que a partir del 14 Julio conocerán a su tutor asignado',
          '../../../../../assets/images/content/files-sent.svg'
        );
        document.getElementById('loader').classList.add('no-display');
        document
          .getElementById('enviarArchivos')
          .classList.remove('transparent');
        document.getElementById('enviarArchivos').removeAttribute('disabled');
        sendBtnText.innerHTML = 'Enviar archivos';
        this.team.files_folder = folderId;
      },
      error: (err) => {
        this.isWaiting = false;
        this.openCompletedDialog(
          'error',
          '¡Ups!',
          'No pudimos cargar tus archivos, por favor intenta de nuevo más tarde',
          '../../../../../assets/images/content/error.png'
        );
        document.getElementById('loader').classList.add('no-display');
        document
          .getElementById('enviarArchivos')
          .classList.remove('transparent');
        document.getElementById('enviarArchivos').removeAttribute('disabled');
        sendBtnText.innerHTML = 'Enviar archivos';
      },
    });
  }

  createFolderUpload(formData): void {
    const sendBtnText = document.getElementById('send-text');
    this.contestService
      .createFolder(`Equipo ${this.team.name}`, this.team._id)
      .subscribe({
        next: (res) => {
          this.simpleUpload(res.response_drive.id, formData);
        },
        error: (err) => {
          this.isWaiting = false;
          this.openCompletedDialog(
            'error',
            '¡Ups!',
            'Ocurrió un error inesperado, por favor intenta de nuevo más tarde',
            '../../../../../assets/images/content/error.png'
          );
          document.getElementById('loader').classList.add('no-display');
          document
            .getElementById('enviarArchivos')
            .classList.remove('transparent');
          document.getElementById('enviarArchivos').removeAttribute('disabled');
          sendBtnText.innerHTML = 'Enviar archivos';
        },
      });
  }

  openCompletedDialog(type, title, description, img): void {
    const dialogRef = this.dialog.open(CompletedDialogComponent, {
      width: 'auto',
      data: { type, title, description, img, dialogId: 'dialog-contest' },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
