import { Store } from '@ngrx/store';
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ContestService } from 'src/app/shared/services/contest.service';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-programa-impulso',
  templateUrl: './programa-impulso.component.html',
  styleUrls: ['./programa-impulso.component.scss'],
})
export class ProgramaImpulsoComponent implements OnInit {
  messageCtrl = new UntypedFormControl('');

  matcher = new MyErrorStateMatcher();
  public user$;
  public user;

  public isWaiting = false;

  navLinks = [
    { name: 'Sobre Impulso', tag: 'sobre-impulso' },
    { name: 'Requisitos', tag: 'quien-puede-inscribirse' },
    { name: 'Testimonios', tag: 'testimonios' },
    { name: 'Cronograma', tag: 'cronograma' },
    { name: 'Inscripción', tag: 'inscripcion' },
    { name: 'Contacto', tag: 'contacto' },
  ];
  activeLink = this.navLinks[0];

  constructor(
    private contestService: ContestService,
    private store: Store<UserState>,
    public snackbar: MatSnackBar
  ) {
    this.user$ = this.store.select('user');
  }

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      this.user = user;
    });
    this.setWaiting = this.setWaiting.bind(this);
  }

  setWaiting() {
    this.isWaiting = !this.isWaiting;
  }

  beneficios = [
    {
      id: 1,
      nombre: 'Tutorías personalizadas',
      subtitulo: '1 hora por semana',
      descripcion:
        'Acompañamiento de un experto en gestión empresarial para cada uno de los seleccionados en reuniones virtuales o presenciales de seguimiento',
    },
    {
      id: 2,
      nombre: 'Mentorías especializadas',
      subtitulo: '2 horas por mes',
      descripcion:
        'El estudiante indica un área del negocio que quiere mejorar en su emprendimiento y el CEADEN le provee un experto en la materia para asesorarle.',
    },
    {
      id: 3,
      nombre: 'Encuentros con expertos',
      subtitulo: 'Libre',
      descripcion:
        'Los estudiantes seleccionados en el programa IMPULSO podrán participar en los encuentros, conferencias y otras actividades con emprendedores y expertos que organiza el CEADEN',
    },
  ];

  cronograma = [
    {
      id: 1,
      dia: '23',
      mes: 'Septiembre',
      nombre: 'Inicio de las inscripciones',
    },

    {
      id: 2,
      dia: '06',
      mes: 'Octubre',
      nombre: 'Fin de inscripción y envío de propuesta técnica',
    },
    {
      id: 3,
      dia: '21',
      mes: 'Octubre',
      nombre: 'Anuncio de los seleccionados',
    },
    {
      id: 4,
      dia: '25',
      mes: 'Octubre',
      nombre: 'Inicio del programa',
    },
    {
      id: 5,
      dia: '27',
      mes: 'Febrero',
      nombre: 'Fin del programa',
    },
  ];

  criterios = [
    {
      id: 1,
      nombre: 'Viabilidad',
      descripcion:
        'Es la posibilidad de que el proyecto sea implementado con éxito, ateniendo a criterios de oportunidad de mercado, pertinencia de la solución al problema existente y capacidades del equipo de implementar la solución. Se valorarán especialmente aquellos emprendimientos probados, es decir, que ya han generado ventas.',
      calificacion: '40%',
    },
    {
      id: 4,
      nombre: 'Transformación Social',
      descripcion:
        'Potencialidad de impacto social del proyecto en línea con los ODS (Objetivos de Desarrollo Sostenible) y vinculación del proyecto al compromiso de los criterios ASG (Ambientales, Sociales y Gubernamentales).',
      calificacion: '20%',
    },
    {
      id: 2,
      nombre: 'Innovación',
      descripcion:
        'Creatividad de la solución propuesta y de la manera de implementarla.',
      calificacion: '20%',
    },
    {
      id: 3,
      nombre: 'Escalabilidad',
      descripcion:
        'Potencialidad de multiplicar los ingresos sin necesidad de aumentar sus costes de la misma manera.',
      calificacion: '20%',
    },
  ];

  testimonios = [
    {
      student: 'Miguel Gordo',
      project: 'Barustahlhaus',
      video:
        'https://drive.google.com/file/d/19pUSoCGSdEN19KgU2Hd_fbJVoKY7ef3e/preview',
    },
    {
      student: 'Oliver Guillén',
      project: 'Barracuda Fight Club',
      video:
        'https://drive.google.com/file/d/1_2efp_9KnGTAkKwwTPVk4_BgItK9mClu/preview',
    },
    {
      student: 'Layla Bazzi & Alejandra Cora',
      project: "O'Clock Candles",
      video:
        'https://drive.google.com/file/d/1WUTXyOttxe_AP8bZtF4VlOjBP_AvR5Lq/preview',
    },
    {
      student: 'Juliana Etchelecu',
      project: 'YUYI4YOU',
      video:
        'https://drive.google.com/file/d/1hK38_diA4j_X903RNCrZiem7vJI0yE45/preview',
    },
    {
      student: 'Arles Adrian Fernandez',
      project: 'Baked Goods',
      video:
        'https://drive.google.com/file/d/1tl3OpKp0zqeTCclyg4USZsywUHuvo7sJ/preview',
    },
    {
      student: 'Esperanza Villalobos & Thomas Reichelt',
      project: 'IneedNOise',
      video:
        'https://drive.google.com/file/d/1pQ7Y79MtSMfq-vfAWzh37dIK1IZsObLl/preview',
    },
    {
      student: 'Miguel Didier Estrada Luna',
      project: 'Ess-Natural',
      video:
        'https://drive.google.com/file/d/1D1pTI1I0FNhOOd_oVf849EoniUpyJdPq/preview',
    },
  ];

  sendMessage(): void {
    if (this.messageCtrl.value !== null || this.messageCtrl.value !== '') {
      const data = {
        student: this.user.apellido,
        message: this.messageCtrl.value,
        student_email: this.user.email,
        email_api: 'ceaden@adenuniversity.edu.pa',
        subject:
          'Nueva consulta de ' + this.user.apellido + ' sobre Programa Impulso',
        title:
          'Nueva consulta de ' + this.user.apellido + ' sobre Programa Impulso',
      };

      const button = document.getElementById('send-button');
      button.innerHTML = 'Enviando...';
      this.contestService.send_email(data).subscribe({
        next: (res) => {
          button.innerHTML = 'Enviar';
          this.snackbar.open('¡Mensaje enviado con éxito!', 'OK', {
            duration: 2000,
            panelClass: ['success-snackbar'],
          });
        },
        error: (err) => {
          this.snackbar.open(
            'Ocurrió un error, por favor intenta de nuevo más tarde',
            'OK',
            {
              duration: 2000,
              panelClass: ['error-snackbar'],
            }
          );
        },
      });
    }
  }
}
