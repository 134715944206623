import { NavigationService } from './shared/services/navigation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'campus-adenu-panama';
  visible = false;

  constructor(public navigation: NavigationService) {
    this.navigation.startSaveHistory();
  }

  ngOnInit() {
    localStorage.removeItem('estadoBloqueos');
  }

  public user = {
    nombre: 'Pedro',
    apellido: 'Sanchez',
    rol: {
      denominacion: 'admin',
    },
  };

  isHandset = true;
}
