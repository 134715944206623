<div class="backdrop" id="backdrop" (click)="activateTab()"></div>

<div class="alert shake" id="alert">
  <div class="tab" id="tab" (click)="activateTab()">
    PAGOS ATRASADOS
    <button mat-icon-button class="close-btn">
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
  <div class="details" id="details">
    <div class="description">
      <img
        src="../../../../assets/images/content/alert-bloqueo.png"
        alt="Pagos atrasados"
      />
      ¡Tu cuenta tiene pagos con retraso!
      <div class="first">
        <strong>Te encuentras atrasado en el cumplimiento de pagos</strong>
      </div>
      <div class="second">
        Revisa tu <a href="/estado-financiero">estado financiero</a> y ponte en
        contacto con tu <a href="/academic-advisor">Academic Advisor</a>
        para regularizar tu situación y evitar el bloqueo de tu cuenta.
      </div>
    </div>
  </div>
</div>
