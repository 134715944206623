import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
// import * as CryptoJS from 'crypto-js';
import * as LZString from 'lz-string';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class AuthService {


  constructor(private http: HttpClient) { }

  url = environment.baseUrl;


  leerToken(): string {
    if (localStorage.getItem('token')) {
      return localStorage.getItem('token');
    } else {
      return '';
    }
  }

  private tokenExpired(token: string): boolean {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  leerLegajo(): string {
    if (localStorage.getItem('sis_id')) {
      return localStorage.getItem('sis_id');
    } else {
      return '';
    }
  }

  login(params): Observable<any> {
    return this.http
      .post(this.url + '/v2/acropolis/auth/login', params, {})
      .pipe(map((data) => data));
  }

  createEnrollment(){
    const token = this.leerToken();
    return this.http.get(`https://sisapi.aden.org/api/v2/acropolis/auth/<login>`)
  }

  // decryptToken(shortenedToken: string, secretKey: string): string {
  // // Descomprimir el token
  // const decompressedToken = LZString.decompressFromBase64(shortenedToken);

  // // Desencriptar el token
  // const bytes = CryptoJS.AES.decrypt(decompressedToken, secretKey);
  // const originalToken = bytes.toString(CryptoJS.enc.Utf8);

  // return originalToken;
  // }

  updateRefreshToken(dataToRefresh): Observable<any> {
    const fd = new FormData();
    fd.append('qr_status', dataToRefresh.qr_status);
    fd.append('multiple_load', dataToRefresh.multiple_load);
    fd.append('document_id', dataToRefresh.document_id);
    fd.append('document_status', dataToRefresh.document_status);
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + dataToRefresh.access_token });
    return this.http.patch(this.url + '/update_qr_token', fd ,{headers})
  }

  loginCanvas(form): Observable<any> {
    return this.http
      .post('https://fa.aden.org/oauth2/authorize', form, {})
      .pipe(map((data) => data));
  }

  validateQrToken(refresh_token){
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + refresh_token });
    return this.http.get(`${this.url}/validate_qr_token`, { headers })
  }

  getPartner(partnerId): Observable<any> {
    return this.http.get(
      this.url + '/v1/acropolis/users/public-contact/' + partnerId
    );
  }

  getSisIdByToken(token){
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token});
    return this.http.get(this.url + '/qr_document/student_sis_id', { headers });
  }

  refreshExpTime(){
    const hoy = new Date();
    hoy.setHours(hoy.getHours() + 3);
    const expiracion = hoy.toISOString();
    localStorage.setItem('inactivity_exp', expiracion);
  }



  clearToken(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem('exp');
    localStorage.removeItem('rol');
    localStorage.removeItem('temporalToken');
  }

  clearSis_id(): void {
    localStorage.removeItem('sis_id');
  }

  clear_estado_bloqueo(): void {
    localStorage.removeItem('estado_bloqueo');
    localStorage.removeItem('estadoBloqueos');
  }

  logout(): void {
    this.clearToken();
    this.clear_estado_bloqueo();
    this.clearSis_id();
  }

  isAuthorizated(): boolean {
    // if (this.leerToken() && !localStorage.getItem('temporalToken')) {
    if (this.leerToken() && localStorage.getItem('inactivity_exp')) {
      return true;
    } else {
      return false;
    }
  }

  loginCampusQR(params){
    const fd = new FormData();
    fd.append('grant_type', params.grant_type);
    if(params.grant_type === 'refresh_token'){
      fd.append('refresh_token', params.refresh_token);
    }else{
      fd.append('password', params.password);
      fd.append('email', params.user);
    }
    return this.http.post(`${this.url}/login_campus_qr`, fd)
  }

  setTemporalToken(token): void {
    localStorage.setItem('temporalToken', token);
  }

  repoLogin(user): Observable<any>{
    return this.http.post(
      this.url + '/v2/acropolis/auth/login',
      user
    );
  }

  changePassword(user): Observable<any> {
    return this.http.post(
      this.url + '/v1/acropolis/auth/change-password',
      user
    );
  }


}