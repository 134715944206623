import * as reduxGrades from '../actions/grades.action';

export interface GradesState {
  grades: object;
}

export const initialgradesState = null;

export function GradesReducer(
  gradesState: GradesState = initialgradesState,
  action: reduxGrades.GradesActions
): GradesState {
  // console.log(countriesState)
  switch (action.type) {
    case reduxGrades.GET_GRADES:
      return { ...action.payload };

    default:
      break;
  }
  return gradesState;
}
