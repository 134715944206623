    <div class="header">
        <div class="title">Documentos adjuntos</div>
        <mat-icon mat-dialog-close class="close material-icons-round">close</mat-icon>
    </div>
    <div class="documents-list">
        <div class="document-card" *ngFor="let document of data.userDoc.documentos_adjuntos">
            <div class="left-section">
                <div class="doc-title">{{ document.nombre }}</div>
                <div class="doc-description">{{ document.fecha_de_vencimiento ? document.fecha_de_vencimiento : 'Sin fecha de vencimiento' }}</div>
            </div>
            <div class="right-section">
                <app-status-chip [status]="document.estado.toLowerCase()"></app-status-chip>
            </div>
            <input type="file" multiple="multiple" accept=".jpg, .jpeg, .png, .pdf" (change)="onFileSelected($event, document.id)" #fileUpload hidden />
            <button
            mat-icon-button
            color="accent"
            aria-label="Actualiza tu documento"
            matTooltip="Sube multiples documentos"
            #tooltip="matTooltip"
            matTooltipPosition="below"
            (click)="fileUpload.click()"
          >
            <iconify-icon class="multiple-doc-icon" icon="mdi:file-document-multiple"></iconify-icon>
          </button>
        </div>
    </div>
