import { Component, Input } from '@angular/core';
import { Product } from 'src/app/shared/interfaces/IProduct';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent{
  @Input() product: Product;
  @Input() addProduct: any;
  @Input() limitThisProduct: number;
  @Input() isInCart: any;
  @Input() isGradOption: any;
  @Input() isInCartLimit: any;
  @Input() index: number;
  @Input() isGraduando: any;
  @Input() regularOpp: boolean;
  @Input() gradOpp: boolean;
  @Input() cart: Product[];

  public quantity: number = 1;

  constructor() {
  }
  
  countChangeHandler(count: number) {
    this.quantity = count;
  }
  
  public add() {
    this.addProduct(this.product, this.quantity);
    this.quantity = 1;
  }

  public disableDifType(){
    //Deshabilia los productos productos que no son regulares si hay un producto regular en el carrito, Solo si esta graduando
    if (this.cart.length === 0 || this.isInCart(this.product) || !this.isGraduando) {
      return false;
    }
    return this.cart.some(cart => cart.id === 1);
  }

  fixTotal(){
    console.log(this.product.price)
    return (Math.round(this.product.price * 100) / 100).toFixed(2)
  }
}
