<div class="main-navigation" id="main-navigation">
  <app-sidenav
    [expandedSidenav]="expandedSidenav"
    (sidenavIsOpen)="expandedSidenav.setValue($event)"
  ></app-sidenav>
  <div
    class="main-content"
    [class.sidenav-open]="expandedSidenav.value || mobileQuery.matches"
  >
    <mat-toolbar color="primary" tourAnchor="toolbar">
      <app-menu-item
        id="toggleMobileMenu"
        [icon]="'menu'"
        (click)="expandedSidenav.setValue(!expandedSidenav.value)"
      ></app-menu-item>
      <div class="logo" *ngIf="!expandedSidenav.value" routerLink="/">
        <a routerLink="/"><img src="assets/images/logos/ADENU-Color.png" /></a>
      </div>
      <span class="example-spacer"></span>

      <app-notifications></app-notifications>
      <div
        class="toolbar-button"
        tourAnchor="user-menu"
        [matMenuTriggerFor]="menu"
      >
        <div class="avatar">
          <img
            src="{{
              (user$ | async)?.foto64
                ? (user$ | async)?.foto64
                : 'assets/images/logos/avatar.png'
            }}"
          />
          <mat-icon class="material-icons-round arrow" [inline]="true">
            keyboard_arrow_down
          </mat-icon>
        </div>
      </div>

      <mat-menu
        #menu="matMenu"
        backdropClass="navigation-user-menu"
        class="mat-elevation-z0"
        [hasBackdrop]="true"
        xPosition="after"
      >
        <div class="user-profile" tourAnchor="user-menu-id" md-autofocus>
          <img
            [src]="
              (user$ | async)?.foto64
                ? (user$ | async)?.foto64
                : 'assets/images/logos/avatar.png'
            "
            alt="Imagen de perfil"
            class="avatar"
          />
          <div class="text">
            <div class="name">{{ (user$ | async)?.nombre }}</div>
            <div class="legajo">ID: {{ (user$ | async)?.legajo }}</div>
          </div>
        </div>
        <hr />
        <a
          tourAnchor="user-profile-docs"
          [routerLink]="['../perfil']"
          class="quick-link"
          ><mat-icon class="material-icons-round">account_circle</mat-icon
          >Perfil y documentación</a
        >
        <a
          tourAnchor="user-credential"
          [routerLink]="['../estudiante/' + (user$ | async)?.legajo]"
          target="_blank"
          class="quick-link"
          ><mat-icon class="material-icons-round">badge</mat-icon>Mi credencial
          virtual</a
        >
        <!-- <a
          tourAnchor="user-dracmas"
          [routerLink]="['../puntuacion-dracma']"
          class="quick-link"
          ><mat-icon class="material-icons-round">verified</mat-icon>Puntuación
          Dracma</a
        >  -->
        <hr />
        <a
          tourAnchor="user-estado-financiero"
          [routerLink]="['../estado-financiero']"
          class="quick-link"
          ><mat-icon class="material-icons-round">account_balance</mat-icon
          >Estado financiero</a
        >
        <a
          tourAnchor="user-orders"
          [routerLink]="['../mis-ordenes/tramites-academicos']"
          class="quick-link"
          ><mat-icon class="material-icons-round">receipt_long</mat-icon>Mis
          órdenes de compra</a
        > 

        <hr />
        <a
          tourAnchor="user-aa"
          [routerLink]="['../academic-advisor']"
          class="quick-link"
          ><mat-icon class="material-icons-round"
            >supervised_user_circle</mat-icon
          >Academic Advisor</a
        >
        <a
          tourAnchor="user-manual"
          href="https://drive.google.com/file/d/1Wv3Czftsroc5byjKNCLRf4vPSYLYzI3Q/view"
          target="blank"
          class="quick-link"
          ><mat-icon class="material-icons-round">book</mat-icon>Manual del
          estudiante</a
        >
        <hr />
        <div tourAnchor="user-start-tour" class="quick-link" (click)="startTour()">
          <mat-icon class="material-icons-round">tour</mat-icon>Ver tour de
          navegación
        </div>
        <div tourAnchor="user-logout" class="quick-link" (click)="onSignOut()">
          <mat-icon class="material-icons-round">logout</mat-icon>Cerrar sesión
        </div>
      </mat-menu>
    </mat-toolbar>
    <div class="content" id="main-content" tourAnchor="main-content">
      <ng-content></ng-content>
      <!-- <div class="spacer"></div> -->
    </div>

    <app-footer *ngIf="showFooter" class="footer-container"></app-footer>

    <!-- ALERTAS -->

    <app-block-alert
      *ngIf="
        estadoBloqueos.length > 0 &&
        estadoBloqueos.length === (user$ | async)?.carreras.length &&
        (user$ | async)?.estado_bloqueo
      "
      [estadoBloqueos]="estadoBloqueos"
      [alertType]="'aviso_bloqueo'"
      [tabOpen]="(user$ | async)?.estado_bloqueo"
    ></app-block-alert>

    <app-pre-block-alert
      *ngIf="
        !(user$ | async)?.estado_bloqueo && (user$ | async)?.proximo_bloquear
      "
      [tabOpen]="
        !(user$ | async)?.estado_bloqueo && (user$ | async)?.proximo_bloquear
      "
    ></app-pre-block-alert>
    <app-payments-alert
      *ngIf="
        !(user$ | async)?.estado_bloqueo &&
        !(user$ | async)?.proximo_bloquear &&
        (user$ | async)?.pago_atrasado
      "
      [tabOpen]="
        !(user$ | async)?.estado_bloqueo &&
        !(user$ | async)?.proximo_bloquear &&
        (user$ | async)?.pago_atrasado
      "
    ></app-payments-alert>
  </div>
</div>
