<div *ngIf="!item.redirect" class="card-home" (click)="goTo()">
  <div class="header">
    <div class="title">{{ item.title }}</div>
    <div class="icon arrow-icon">
      <mat-icon class="material-icons-round">arrow_forward</mat-icon>
    </div>
  </div>
  <div *ngIf="item.gif" class="body gif-body">
    <div class="gif">
      <img *ngIf="item.gif" [src]="item.gif" alt="gif" />
    </div>
    <div class="description">{{ item.description }}</div>
  </div>
  <div *ngIf="!item.gif" class="body">
    <div class="icon absolute">
      <i [class]="item.icon"></i>
    </div>
    <div class="description">{{ item.description }}</div>
  </div>
</div>
<a *ngIf="item.redirect" [href]="item.link" target="_blank" class="card-home black-font">
  <div class="icon absolute">
    <iconify-icon [icon]="item.icon"></iconify-icon>
  </div>
  <div class="title">{{ item.title }}</div>
  <div class="description">{{ item.description }}</div>
  <div class="icon">
    <mat-icon class="material-icons-round">arrow_forward</mat-icon>
  </div>
</a>

