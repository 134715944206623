import { Store } from '@ngrx/store';
import { UserState } from './../../redux/reducers/user.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';

import { Observable } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  NgForm,
  FormGroupDirective,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(
      control.touched &&
      control?.invalid &&
      control?.parent?.dirty
    );
    const invalidParent = !!(
      control.touched &&
      control?.parent?.invalid &&
      control?.parent?.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public passControl = new UntypedFormControl('', Validators.required);
  public passFormGroup: UntypedFormGroup;
  public partner;
  public hide = true;
  matcher = new MyErrorStateMatcher();

  error = false;
  loading = true;

  user: string;
  password: string;
  hoy: Date;
  $: any;

  public originUrl = localStorage.getItem('origin');

  public user$: Observable<any>;

  constructor(
    private dataService: DataService,
    private alertService: AlertService,
    private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private store: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params) => {
      try {
        let partnerId = atob(params.get('partnerId'));
        this.getPartner(partnerId);
      } catch (error) {
        this.router.navigateByUrl('/login');
      }
    });
    /* console.log('Hola'); */
    this.buildForm();
  }

  buildForm(): void {
    this.passFormGroup = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirm: ['', [Validators.required]],
      },
      { validator: this.checkPasswords }
    );
  }

  getPartner(partnerId) {
    this.authService.getPartner(partnerId).subscribe({
      next: (res) => {
        this.partner = res.response_data;
        this.error = false;
        this.loading = false;
      },
      error: (err) => {
        this.router.navigateByUrl('/login');
      },
    });
  }

  checkPasswords: ValidatorFn = (
    group: AbstractControl
  ): ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('passwordConfirm').value;
    return pass === confirmPass ? null : { notSame: true };
  };

  generatePassword() {
    this.authService
      .changePassword({
        email: this.partner.personal_email,
        password: this.passFormGroup.get('password').value,
      })
      .subscribe({
        next: (res) => {
          this.alertService.openAlert(
            '¡Contraseña guardada',
            'La contraseña de tu cuenta se creó con éxito, ya puedes iniciar sesión con tus credenciales',
            'Iniciar sesión',
            ()=>{this.goToLogin()}
          );
        },
        error: (err) => {
          this._snackBar.open(
            'No pudimos guardar tu contraseña, intenta de nuevo más tarde',
            'Cerrar',
            {
              duration: 5000,
            }
          );
        },
      });
  }

  goToLogin() {
    this.router.navigate(['/login'], {
      queryParams: { mail: this.partner.personal_email },
    });
  }
}
