import { Action } from '@ngrx/store';
import { types } from '../types/types';

export const GET_COUNTRIES = types.GET_COUNTRIES;

export class GetCountries implements Action {
  readonly type = GET_COUNTRIES;
  constructor(public payload: any) {}
}

export type CountriesActions = GetCountries;
