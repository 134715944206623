import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import moment from 'moment-timezone';

@Component({
  selector: 'app-alerts-matricula',
  templateUrl: './alerts-matricula.component.html',
  styleUrls: ['./alerts-matricula.component.scss'],
})
export class AlertsMatriculaComponent implements OnInit {
  @Input() contrato: boolean;
  @Input() matricula: boolean;
  @Input() showMatriculacion: boolean;
  @Input() isLoading: boolean;
  @Input() bloqueo: boolean;
  @Input() isWaiting: boolean;
  @Input() openContractDialog;
  @Input() leftTime: any;
  @Input() dates: any;
  @Input() modalidadSelected: any;
  @Input() modalidadBase: any;

  today = moment();
  moment = moment;

  links = [];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.getConfigs()
  }

  getEvent() {
    const event = {
      title: 'Proceso de Rematriculación | ADENU Panamá',
      description:
        'Realiza la matriculación de tus asignaturas en nuestro <a href="https://campus.adenu.edu.pa/matriculaciones">Campus Virtual</a>',
      start: this.dates.init.toISOString(),
      end: this.dates.end.toISOString(),
      allDay: true,
      url: 'https://campus.adenu.edu.pa/matriculaciones',
    };

    this.links = [
      {
        name: 'Google Calendar',
        url: google(event),
      },
      {
        name: 'Outlook',
        url: outlook(event),
      },
      {
        name: 'Office 365',
        url: office365(event),
      },
      {
        name: 'Yahoo',
        url: yahoo(event),
      },
      {
        name: 'iCal',
        url: ics(event),
      },
    ];
  }

  getConfigs() {
    return {
      leftTime: this.leftTime > 0 ? this.leftTime : 0,
      format:
        this.leftTime < 86400 && this.leftTime >= 3600
          ? 'HH:mm:ss'
          : this.leftTime < 3600
          ? 'mm:ss'
          : 'dd:HH:mm:ss',
    };
  }
  navigate(url: string){
    this.router.navigate([url])
  }
}