<div class="card-container">
  <div class="body">
    <div class="header">
      <h3>{{ userDoc.nombre }}</h3>
      <h5>
        {{
          userDoc.modalidad === "o"
            ? "Modalidad online"
            : userDoc.modalidad === "p"
            ? "Modalidad presencial"
            : "Todas las modalidades"
        }}
      </h5>
    </div>
    <p *ngIf="userDoc.descripcion">
      {{ userDoc.descripcion }}
    </p>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="isUploading"></mat-progress-bar>
  <div class="footer">
    <div class="footer-text">Estado: </div>
    <app-status-chip *ngIf="userDoc.estado" [status]="userDoc.estado?.toLowerCase()"></app-status-chip>
    <div class="button-group">
      <a
        mat-icon-button
        color="accent"
        aria-label="Descargar formulario modelo"
        matTooltip="Descargar formulario modelo"
        *ngIf="userDoc.url_plantilla && userDoc.url_plantilla !== ''"
        [href]="
          'https://drive.google.com/uc?export=download&id=' +
          userDoc.url_plantilla
        "
      >
      <div class="icon-container">
        <i class="download-icon icon fa-light fa-file-arrow-down"></i>
      </div>
      </a>
      <button 
        mat-icon-button
        color="accent"
        aria-label="Carga un documento"
        matTooltip="Cargar documento"
        *ngIf="userDoc.estado.toLowerCase() === 'pendiente'"
        (click)="isSpecMode ? openSpecAlert() : openFileModeSelect()"
      >
      <div class="icon-container upload-icon">
        <i class="fa-light icon fa-arrow-up-to-line"></i>
      </div>
      </button>
      <button
        mat-icon-button
        color="accent"
        aria-label="Actualiza tu documento"
        matTooltip="Actualizar documento"
        #tooltip="matTooltip"
        matTooltipPosition="below"
        *ngIf="userDoc.estado.toLowerCase() === 'rechazado' && userDoc.id !== 0"
        (click)="isSpecMode ? openSpecAlert() : openFileModeSelect()"
      >
        <mat-icon class="material-icons-round">update</mat-icon>
      </button>
      <button
        mat-icon-button
        color="accent"
        [matTooltip]="
          userDoc.estado.toLowerCase() === 'aprobado'
            ? 'Motivo de aprobación'
            : 'Motivo de rechazo'
        "
        aria-label="Más información"
        *ngIf="
          userDoc.motivo &&
          userDoc.motivo !== '' &&
          (userDoc.estado.toLowerCase() === 'aprobado' ||
            userDoc.estado.toLowerCase() === 'rechazado')
        "
        (click)="openMotivo()"
      >
        <mat-icon class="material-icons-round">info</mat-icon>
      </button>
      <button
      *ngIf="userDoc.carga_multiple && userDoc.documentos_adjuntos?.length === 0"
      mat-icon-button
      color="accent"
      aria-label="Actualiza tu documento"
      matTooltip="Sube multiples documentos"
      #tooltip="matTooltip"
      matTooltipPosition="below"
      (click)="fileUpload.click()"
    >
      <iconify-icon class="multiple-doc-icon" icon="mdi:file-document-multiple"></iconify-icon>
    </button>
      <button
      *ngIf="userDoc.carga_multiple && userDoc.documentos_adjuntos?.length > 0"
      mat-icon-button
      color="accent"
      aria-label="Actualiza tu documento"
      matTooltip="Mira tus documentos"
      #tooltip="matTooltip" 
      matTooltipPosition="below"
      (click)="viewMultiDocs()"
    >
      <iconify-icon class="multiple-doc-icon" icon="ph:eye-bold"></iconify-icon>
    </button>
    </div>
  </div>
</div>
