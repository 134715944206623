// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://sisapi-test.aden.org/api',
  /* contestUrl: 'http://localhost:3030/api', */
  contestUrl: 'https://adenupa-api.adenu.edu.pa/api',
  // baseUrl: 'http://localhost:8000/api',
  cmsApiUrl: 'https://cms-test.adenu.edu.pa/api',
  repoUrl: 'https://repositorio.aden.org/api/v1/repo_aden',
  /* cmsApiUrl: 'http://localhost:1337/api', */
  cmsUrl: 'https://cms-test.adenu.edu.pa',
  /* cmsUrl: 'http://localhost:1337', */
  cmsToken:
    '1d25f82facea2428eb5a6f83fe43a4e2565dcd2bf4b9b6af1e835e864c7d035244b5db010dd4e1090470fd47376166f7f9942c9f70d3f28371ef237eb67bbe53df71478965bac42e8137b590505b8da0c5137015abf825d651b644c412e2a1ea47a06e119ff45ed37b12908a6cbf531cb4e3f4da9569eee32267b31b59d4f734',
  hotjar_id: '2942560',
  hash: { useHash: false },
  webUrl: 'http://campus-test.adenu.edu.pa',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.