<div class="container-bg" id="form-inscripcion">
  <div class="heading">
    <div class="title blue">Proceso de inscripción</div>
    <div class="button-container">
      <a
        mat-flat-button
        color="accent"
        target="_blank"
        rel="noopener noreferrer"
        href="../../../../assets/documents/Guia del Reto A&E 2021 - ADENU.pdf"
        >Guía para el estudiante
        <span class="material-icons"> download </span></a
      >
      <a
        mat-flat-button
        color="accent"
        target="_blank"
        rel="noopener noreferrer"
        href="../../../../assets/documents/Compromiso de participación en A&E - Adenu 2021.docx"
        >Compromiso del participante
        <span class="material-icons"> download </span></a
      >
      <a
        mat-flat-button
        color="accent"
        target="_blank"
        rel="noopener noreferrer"
        href="../../../../assets/documents/Propuesta-Tecnica.docx"
      >
        Formato Propuesta Técnica <span class="material-icons"> download </span>
      </a>
    </div>
  </div>

  <div class="container">
    <mat-tab-group mat-stretch-tabs>
      <mat-tab label="Inscripción">
        <div *ngIf="!hasTeam">
          <div class="center">
            <img src="../../../../assets/images/content/wait.svg" alt="" />
            <div class="text blue">¡Inscripciones cerradas!</div>
            <div class="subtext orange mt-3">Muchas gracias por participar</div>
          </div>
          <!-- TEAM NAME -->

          <form class="example-form mt-3">
            <label>Nombre del equipo</label>
            <mat-form-field class="full-width">
              <mat-label>Ingresa el nombre de tu equipo</mat-label>
              <input
                matInput
                placeholder="Team Adenu"
                [formControl]="formulario['controls'].nombre_equipo"
                [errorStateMatcher]="matcher"
                autocomplete="off"
                required
              />
              <mat-error
                *ngIf="
                  formulario['controls'].nombre_equipo.hasError('required')
                "
              >
                Este campo es <strong>obligatorio</strong>
              </mat-error>
            </mat-form-field>
          </form>

          <!-- MEMBERS -->

          <form class="example-form">
            <label>Miembros del equipo</label>
            <ul>
              <li>Puedes añadir hasta 7 miembros además de tí</li>
              <li>El equipo deberá tener al menos 2 miembros</li>
              <li>
                El alumno que realiza la inscripción será el capitán designado
              </li>
            </ul>
            <div class="member-select-container">
              <mat-form-field class="example-full-width">
                <mat-label>Selecciona un miembro</mat-label>
                <input
                  matInput
                  aria-label="State"
                  [matAutocomplete]="auto"
                  [formControl]="alumnoCtrl"
                  [matAutocompleteDisabled]="this.isLoading"
                />
                <mat-hint>Busca a tu compañero por su nombre</mat-hint>
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displayProperty"
                >
                  <mat-option
                    *ngFor="let alumno of filteredAlumnos | async"
                    [value]="alumno"
                  >
                    <img
                      class="example-option-img"
                      aria-hidden
                      [src]="alumno.img"
                      height="25"
                    />
                    <span>{{ alumno.name }}</span> |
                    <small>{{ alumno.mail }}</small>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <button
                mat-flat-button
                color="primary"
                class="full-width"
                (click)="addMember()"
              >
                Añadir
              </button>
            </div>
            <mat-progress-bar
              *ngIf="this.isLoading"
              mode="query"
            ></mat-progress-bar>

            <div class="members-grid">
              <app-card-miembro
                *ngIf="this.capitan"
                [miembro]="this.capitan"
                [isCapitan]="'true'"
              ></app-card-miembro>
              <ng-container *ngFor="let alumno of selectedAlumnos">
                <app-card-miembro
                  [miembro]="alumno"
                  [removeMember]="this.removeMember"
                  [isCapitan]="'false'"
                  [selectedAlumnos]="this.selectedAlumnos"
                ></app-card-miembro>
              </ng-container>
            </div>
          </form>
          <form class="example-form mt-3">
            <label>Idea de negocio</label>
            <mat-form-field class="full-width">
              <mat-label>Ingresa tu idea de negocio</mat-label>
              <textarea
                matInput
                #ideaNegocio
                placeholder="Team Adenu"
                maxlength="800"
                [formControl]="formulario['controls'].idea_negocio"
                [errorStateMatcher]="matcher"
                autocomplete="off"
                required
              ></textarea>
              <mat-error
                *ngIf="formulario['controls'].idea_negocio.hasError('required')"
              >
                Este campo es <strong>obligatorio</strong>
              </mat-error>
              <mat-hint align="end"
                >{{ ideaNegocio.value?.length || 0 }}/800</mat-hint
              >
            </mat-form-field>
          </form>
          <form class="example-form">
            <label>Selecciona tu tutor</label>
            <ul>
              <li>Debes seleccionar hasta 3 tutores en orden de preferencia</li>
              <li>
                Una vez finalizada la inscripción, se asignará un tutor acorde a
                tu proyecto
              </li>
            </ul>
            <div class="tutors-grid">
              <ng-container *ngFor="let tutor of tutores">
                <app-card-tutor
                  [tutor]="tutor"
                  [selectedTutors]="this.selectedTutors"
                  [addTutor]="this.addTutor"
                  [removeTutor]="this.removeTutor"
                ></app-card-tutor>
              </ng-container>
            </div>
          </form>
          <div class="button-container">
            <button
              id="completar"
              mat-flat-button
              color="primary"
              (click)="createTeam()"
              [disabled]="
                this.selectedAlumnos.length <= 0 ||
                this.selectedTutors.length <= 0 ||
                formulario['controls'].idea_negocio.value === '' ||
                formulario['controls'].nombre_equipo.value === ''
              "
            >
              Completar inscripcion
            </button>
          </div>
        </div>
        <div #team *ngIf="hasTeam && userTeam">
          <div class="team-container">
            <div class="title blue">¡Ya estás inscripto!</div>
            <div class="item-title blue">Tu equipo se llama</div>
            <div class="name orange">
              {{ this.userTeam.name }}
            </div>
            <div class="item-title blue">Integrantes</div>
            <div class="team-grid">
              <app-card-miembro
                class="max-width"
                [isCapitan]="'true'"
                [miembro]="this.userTeam.creatorCaptain"
                [isTeam]="true"
              ></app-card-miembro>
              <ng-container *ngFor="let member of this.userTeam.equipament"
                ><app-card-miembro
                  class="max-width"
                  [miembro]="member"
                  [isTeam]="true"
                ></app-card-miembro
              ></ng-container>
            </div>
            <div class="item-title blue">Tutores seleccionados</div>
            <div class="team-grid">
              <ng-container *ngFor="let member of this.userTeam.tutors"
                ><app-card-miembro
                  class="max-width"
                  [miembro]="member"
                  [photo]="member.photo"
                  [isTeam]="true"
                ></app-card-miembro
              ></ng-container>
            </div>
            <div class="item-title blue">Idea de Negocio</div>
            <div class="text">
              {{ this.userTeam.business_idea }}
            </div>
            <br />
            <div class="text bold size-up">
              Recuerda que a partir del 9 de Julio se habilitará la entrega de
              Propuestas Técnicas. El capitán del equipo será el encargado de
              realizar la entrega. En la misma deberán incluir:
            </div>
            <div class="items-list">
              <div class="item">Propuesta Técnica</div>
              <div class="item">Video de presentación (Máximo 1 minuto)</div>
              <div class="item">Carta de postulación (Por qué elegirnos)</div>
              <div class="item">
                Compromiso de participación firmado por todos los integrantes
                del equipo
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Propuesta Técnica">
        <div class="center" *ngIf="!hasTeam && !finishedUpload">
          <img src="../../../../assets/images/content/wait.svg" alt="" />
          <div class="text blue">
            Disponible a partir del 12 de Julio de 2021
          </div>
          <div class="subtext orange mt-3">
            DEBES ESTAR INSCRIPTO Y SER CAPITÁN PARA HABILITAR ESTA SECCIÓN
          </div>
        </div>
        <div
          class="center"
          *ngIf="
            hasTeam &&
            userTeam &&
            this.userTeam.creatorCaptain.id !== this.currentUserID &&
            this.userTeam.files_folder === undefined
          "
        >
          <img src="../../../../assets/images/content/wait.svg" alt="" />
          <div class="text blue">
            El capitán de tu equipo será el encargado de cargar los archivos
          </div>
        </div>

        <div
          class="center"
          *ngIf="
            hasTeam && userTeam && this.userTeam.files_folder !== undefined
          "
        >
          <img src="../../../../assets/images/content/files-sent.svg" alt="" />
          <div class="text blue">
            ¡Los archivos ya fueron entregados por el capitán del equipo!
          </div>
          <div class="subtext orange mt-3">
            Recuerda que desde el 13 de Agosto accediendo a la sección
            "Feedback" podrás observar la devolución del Jurado.
            <br /><br />
            ¡Mucha suerte!
          </div>
        </div>
        <div
          class="custom-container"
          *ngIf="
            hasTeam &&
            userTeam &&
            this.userTeam.creatorCaptain.id === this.currentUserID &&
            this.userTeam.files_folder === undefined
          "
        >
          <app-upload-concurso [team]="userTeam"></app-upload-concurso>
        </div>

        <div class="center" *ngIf="finishedUpload && !hasTeam">
          <img
            src="../../../../../assets/images/content/Calendar-finish.svg"
            alt=""
          />
          <div class="text blue">¡Etapa finalizada!</div>
          <div class="subtext orange mt-3">Muchas gracias por participar</div>
        </div>
      </mat-tab>
      <mat-tab label="Asignación de tutor">
        <div class="center" *ngIf="!hasTeam">
          <img src="../../../../assets/images/content/wait.svg" alt="" />
          <div class="text blue">
            Disponible a partir del 16 de Julio de 2021
          </div>
          <div class="subtext orange mt-3">
            DEBES ESTAR INSCRIPTO PARA HABILITAR ESTA SECCIÓN
          </div>
        </div>
        <div class="center" *ngIf="hasTeam && assignedTutor !== undefined">
          <div class="text blue">Tu tutor asignado es:</div>
          <div class="tutor-card">
            <div class="circle-img">
              <img
                src="{{
                  assignedTutor.photo
                    ? assignedTutor.photo
                    : '../../../../assets/images/logos/avatar.png'
                }}"
                alt="imagen tutor"
              />
            </div>
            <div class="text">
              <div class="title orange">{{ assignedTutor.name }}</div>
              <div class="description">{{ assignedTutor.description }}</div>
            </div>
          </div>
          <div class="subtext orange mt-3">
            Muy pronto tu tutor se pondrá en contacto contigo y tu equipo.
          </div>
          <div class="text blue mt-3">¡Mucha suerte!</div>
        </div>

        <div class="center" *ngIf="hasTeam && assignedTutor === undefined">
          <img src="../../../../assets/images/content/wait.svg" alt="" />
          <div class="text blue">Aún no tienes un tutor asignado</div>
          <div class="subtext orange mt-3">
            Muy pronto podrás ver quién será el tutor de tu equipo aquí
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Feedback">
        <div class="center" *ngIf="!hasTeam || !userTeam.id_rubrica">
          <img src="../../../../assets/images/content/wait.svg" alt="" />
          <div class="text blue">
            Disponible a partir del 13 de Agosto de 2021
          </div>
          <div class="subtext orange mt-3">
            DEBES ESTAR INSCRIPTO PARA HABILITAR ESTA SECCIÓN
          </div>
        </div>

        <div class="feedback-container" *ngIf="hasTeam && userTeam.id_rubrica">
          <div class="button-container">
            <a
              mat-flat-button
              color="accent"
              [href]="getDownloadLink(userTeam.id_rubrica) | safeUrl"
              >Descargar Rúbrica
              <span class="material-icons"> download </span></a
            >
          </div>

          <div class="pdf-container">
            <iframe
              [src]="getPreviewLink(userTeam.id_rubrica) | safeUrl"
              frameborder="0"
              width="640"
              height="480"
            ></iframe>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
