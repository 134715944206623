export const cortePresencial2023 = [
  "33427", "32107", "32166", "26537", "26655", "33960", "28525", "34050", "33636", "33750", "33724", "31839", "32307", "28341", "26590",
  "33868", "34088", "26575", "33609", "33568", "28398", "34213", "33875", "33864", "32175", "28498", "28305", "29026", "33439", "31861",
  "32243", "32301", "32280", "32223", "33442", "33944", "28348", "33741", "25660", "33660", "33791", "34064", "33469", "32295", "32302",
  "32922", "32245", "28404", "34174", "34057", "33748", "24475", "28336", "33928", "32297", "32232", "28340", "33731", "34211", "32154",
  "33769", "33447", "28310", "32898", "33838", "32176", "33744", "33800", "28530", "33820", "34193", "32130", "28349", "25566", "33696",
  "33592", "33450", "27966", "34191", "32111", "26521", "33688", "34184", "33608", "32873", "33383", "32096", "28514", "34086", "34075",
  "33475", "33644", "31821", "28546", "34137", "33809", "32907", "33943", "33471", "33795", "33365", "25295", "28524", "33455", "28411",
  "33842", "33658", "33939", "28413", "28301", "33789", "32248", "33519", "32874", "32868", "25537", "33350", "28544", "30236", "34085",
  "26649", "32161", "33704", "33777", "32148", "34056", "33743", "28531", "33610", "33851", "32103", "33726", "28503", "33942", "32850",
  "31889", "34033", "28445", "33867", "33954", "28561", "33932", "26572", "32102", "31764", "25443", "33292", "32084", "31913", "33372",
  "33878", "33849", "28478", "33473", "25079", "32865", "33818", "34076", "33460", "31822", "32300", "34030", "33336", "33335", "34053",
  "33532", "33834", "34041", "33387", "33720", "33814", "33742", "32219", "28540", "33672", "34096", "33929", "33670", "33766", "33783",
  "33470", "33924", "33848", "28529", "28506", "33857", "29055", "26473", "33847", "32125", "33446", "33797", "32136", "28337", "33917",
  "33493", "32110", "34036", "33853", "33955", "33504", "36145", "32220", "31756", "30128", "36206", "36419", "35840", "36564", "36636",
  "34369", "36351", "36538", "36361", "33672", "34356", "34288", "35866", "35873", "33813", "36587", "36570", "24135", "35897", "34041",
  "36243", "36588", "36209", "35868", "36549", "36341", "36497", "35863", "36232", "34262", "36018", "36616", "35881", "36433", "35794",
  "36369", "36382", "35340", "36678", "36391", "24582", "36451", "35864", "36321", "36427", "36149", "34263", "34324", "36400", "36192",
  "35964", "35869", "36026", "36412", "36571", "34292", "36179", "36622", "36724", "35829", "24138", "35737", "36354", "34357", "36504"
];
