import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { PqrsService } from 'src/app/shared/services/pqrs.service';

@Component({
  selector: 'app-estado-ticket',
  templateUrl: './estado-ticket.component.html',
  styleUrls: ['./estado-ticket.component.scss'],
})
export class EstadoTicketComponent {
  public button = false;
  public ticket = null;
  public info = null;

  constructor(
    private alertService: AlertService,
    private pqrsService: PqrsService
    ) {}

  /*  dataTicket(e): void {
    const ticket = parseInt(e.value, 10);
    if (Number.isNaN(ticket) !== true) {
      this.button = true;
      this.ticket = ticket;
    } else {
      this.button = false;
      this.ticket = null;
    }
  } */

  getStatusTicket(): void {
    if (this.ticket != null) {
      this.pqrsService.getTicketById(this.ticket).subscribe({
        next: (res) => {
          this.info = res?.response_data;
        },
        error: (err) => {
          this.alertService.openAlert(
            'Algo salió mal',
            'El número de seguimiento ingresado no existe o no te pertenece',
            'Entendido'
          );
        },
      });
    }
  }
}
