// videos.action.ts
import { Action } from '@ngrx/store';
import { IVideo } from 'src/app/shared/interfaces/IVideo';

export enum LibraryActionTypes {
  LOAD_VIDEOS = '[Library] Load Videos',
  TOGGLE_FAVORITE = '[Library] Toggle Favorite'
}

export class LoadVideos implements Action {
  readonly type = LibraryActionTypes.LOAD_VIDEOS;
  constructor(public payload: IVideo[]) {}
}

export class ToggleFavorite implements Action {
  readonly type = LibraryActionTypes.TOGGLE_FAVORITE;
  constructor(public payload: { videoId: number, isFavorite: boolean }) {}
}

export type LibraryAction = LoadVideos | ToggleFavorite;
