import { MoveLine } from '../../../../shared/interfaces/IMoveLine';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent implements OnInit {
  @Input() payment: MoveLine;

  public status;

  public currentDate = new Date();
  public fechaPago;
  public fechaVencimiento;
  public diffDays;
  diffMonto = false; // Diferencia entre el monto y la deuda

  constructor() {}

  ngOnInit(): void {
    this.diffMonto = this.payment.monto > this.payment.deuda && this.payment.deuda > 0;
    
    if (this.payment.fecha_pago !== '') {
      this.fechaPago = this.formatVtoDate(this.payment.fecha_pago.split('-'))
    }

    if (this.payment.fecha_vencimiento !== '') {
      this.fechaVencimiento = this.formatVtoDate(this.payment.fecha_vencimiento.split('-'))
    }

    if (
      (this.payment.conciliado === 'SI' &&
        (this.payment.fecha_pago !== '' || this.payment.fecha_pago != null)) ||
      (this.payment.conciliado === 'NO' && this.payment.deuda < 0)
    ) {
      this.status = 'Pagado';
    } else if (
      this.fechaVencimiento < this.currentDate &&
      this.payment.deuda > 0
    ) {
      this.status = 'Vencido';
      this.diffDays = Math.trunc(
        (this.currentDate.getTime() - this.fechaVencimiento.getTime()) /
          (1000 * 60 * 60 * 24)
      );
    } else {
      this.status = 'Pendiente';
    }
  }

  formatDate(date: Date): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  formatVtoDate(fecha){
    return new Date(
      parseInt(fecha[2], 10),
      parseInt(fecha[1], 10) - 1,
      parseInt(fecha[0], 10)
    )
  }
}
