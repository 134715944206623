import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab-nav',
  templateUrl: './tab-nav.component.html',
  styleUrls: ['./tab-nav.component.scss'],
})
export class TabNavComponent {
  @Input() links;
  @Input() activeLink;

  constructor() {}
}
