import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cardcareer',
  templateUrl: './cardcareer.component.html',
  styleUrls: ['./cardcareer.component.scss'],
})
export class CardcareerComponent implements OnInit {
  @Input() buttons;
  @Input() badge;
  @Input() namePdf;
  @Input() title;
  constructor() {}

  ngOnInit(): void {
    this.construcArray();
  }

  construcArray(): void {
    const expReg = /\s*,\s*/;
    const newArray = this.buttons.split(expReg);
    const newArray2 = this.namePdf.split(expReg);
    this.buttons = newArray;
    this.namePdf = newArray2;
  }
}
