import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { SpecViewService } from './spec-view.service';
import { ISpec } from '../interfaces/ISpec';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  url = environment.baseUrl;
  specView: ISpec;
  // url = 'http://localhost:8000/api';

  constructor(
    private specViewService: SpecViewService,
    ) {
      this.initSharedSpecView();
    }

  // AUTORIZACION
  private initSharedSpecView(): void {
    this.specViewService.currentSharedSpecMode.subscribe(data => {
      this.specView = data; 
    })
  }
}
