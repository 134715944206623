import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-dialog',
  templateUrl: './contract-dialog.component.html',
  styleUrls: ['./contract-dialog.component.scss'],
})
export class ContractDialogComponent {
  acceptedContract = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<ContractDialogComponent>
  ) {}

  changeCheck(): void {
    this.acceptedContract = !this.acceptedContract;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
