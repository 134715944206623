<div class="drop-area" appDndInscripcion (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    (change)="fileBrowseHandler($event.target.files)"
  />
  <div class="icon">
    <mat-icon class="material-icons-round"> file_present </mat-icon>
  </div>
  <div class="title">Arrastra tus archivos hasta aquí</div>
  <p>o</p>
  <label for="fileDropRef" hidden>Elige los archivos</label>
  <button for="fileDropRef" mat-flat-button color="primary">
    Elige los archivos
  </button>
  <p>5MB máximo por archivo</p>
</div>

<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="file-icon" style="width: 50px">
      <span class="material-icons-round"> insert_drive_file </span>
    </div>
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <div class="detail">
        <div class="size-progress">
          <p class="size">
            {{ formatBytes(file?.size) }}
          </p>
          <div class="progress-cont">
            <div class="progress" [style.width]="file?.progress + '%'"></div>
          </div>
        </div>
        <div class="delete" (click)="deleteFile(i)">
          <span class="material-icons-round"> delete </span>
        </div>
      </div>
    </div>
  </div>
</div>
