import { Component, OnInit } from '@angular/core';
import { HorariosService } from 'src/app/shared/services/horarios.service';

@Component({
  selector: 'app-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.scss'],
})
export class HorariosComponent implements OnInit {
  public horarios = [];
  public loading = true;
  public error = false;

  constructor(private horariosService: HorariosService) {}

  ngOnInit(): void {
    this.getHorarios();
  }

  getHorarios(): void {
    this.loading = true;
    this.horariosService.getHorarios().subscribe({
      next: (res) => {
        this.horarios = res.clases;
        this.loading = false;
        this.error = false;
      },
      error: (err) => {
        this.loading = false;
        this.error = true;
      },
    });
  }
}
