<div id="main">
    <div class="sticky-header"><app-header *ngIf="videoData" [title]="videoData.categoria != 1 ? 'Simulador 360' : 'Microlearning'"></app-header></div>
    <div *ngIf="!isError && !isLoading" class="main-content">
        <div class="player-card">
            <div class="player-header">
                <div *ngIf="videoData">{{videoData.publication_name}}</div>
            </div>
            <div *ngIf="!isError && !isLoading"  class="player">
                <div #playerContainer></div>
            </div>
            <div class="social">
                <div class="buttons-back">
                    <div *ngIf="videoData" class="buttons-back">
                        <div class="buttons-container">
                          <label class="container">
                            <input [checked]="videoData.favorito" (click)="setFav(); toggleFavorite();" type="checkbox">
                            <div class="checkmark">
                              <svg class="share-icon" viewBox="0 0 256 256">
                              <rect fill="none" height="256" width="256"></rect>
                              <path d="M224.6,51.9a59.5,59.5,0,0,0-43-19.9,60.5,60.5,0,0,0-44,17.6L128,59.1l-7.5-7.4C97.2,28.3,59.2,26.3,35.9,47.4a59.9,59.9,0,0,0-2.3,87l83.1,83.1a15.9,15.9,0,0,0,22.6,0l81-81C243.7,113.2,245.6,75.2,224.6,51.9Z" stroke-width="20px" stroke="#5A5A5A" fill="none"></path></svg>
                            </div>
                          </label>
                          <iconify-icon (click)="openShareDialog()" width="1.7rem" height="1.7rem" class="share-icon" icon="material-symbols:share-outline"></iconify-icon>
                        </div>
                      </div>
                </div>
            </div>
        </div>
        <div *ngIf="videoList && videoData" class="videos-list-card">
            <div class="list-header">Contenido libre que puede interesarte:</div>
            <div class="video-list">
                <div *ngFor="let video of videoList" (click)="goToVideo(video.repo_id)" class="video-card">
                    <div class="portrait">
                        <img class="img-portrait" [src]="video.image_url" alt="">
                    </div>
                    <div class="video-info">
                        <div class="video-title">{{video.publication_name}}</div>
                        <div class="author">Por: {{video.acropolis_author}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isError && !isLoading" class="error-section">
        <div class="nf-container">
            <img class="not-found-img" src="../../../assets/images/content/Error-pana.svg" alt="" />
            <h1 class="">Ups.. no pudimos encontrar el video que buscabas</h1>
          </div>
    </div>
    <div *ngIf="isLoading" class="spinner">
        <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
    </div>
</div>