import { CmsService } from './../../shared/services/cms.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anuncios',
  templateUrl: './anuncios.component.html',
  styleUrls: ['./anuncios.component.scss'],
})
export class AnunciosComponent implements OnInit {
  public anuncios = [];
  public loadingAnuncios = true;

  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {
    this.getAnuncios();
  }

  getAnuncios() {
    this.loadingAnuncios = true;
    const query = `sort=publishedAt:desc`;
    this.cmsService.getShortPosts(query).subscribe({
      next: (res) => {
        this.anuncios = res.data;
        this.loadingAnuncios = false;
      },
      error: (err) => {
        this.loadingAnuncios = false;
      },
    });
  }
}
