import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  universitiesDetails,
  universities,
} from 'src/app/shared/data/universities';

export interface DialogData {
  university: {
    name: string;
    country: string;
    careersTitle?: string;
    careers: string[];
    urlCareers: string;
    logo: string;
    details: string[];
    period?: any[];
    description?: string;
    costos?: string[];
    inscripciones?: boolean;
    web: string;
  };
}

@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.scss'],
})
export class ConveniosComponent {
  public universities = universities;
  constructor(public dialog: MatDialog) {}

  openDetailsDialog(university): void {
    this.dialog.open(DetailDialogComponent, {
      data: {
        university,
      },
      panelClass: 'generic-alert',
    });
  }

  openLink(url): void {
    window.open(url, '_blank');
  }
}

@Component({
  selector: 'app-detail-dialog',
  templateUrl: './detail-dialog.html',
  styleUrls: ['./convenios.component.scss'],
})
export class DetailDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
