import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification.service';

moment.updateLocale('es', {
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1 mes',
    MM: '%d meses',
    y: '1 año',
    yy: '%d años',
  },
});

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {
  @Input() notification;
  selectMode = '';
  public moment = moment;
  @Input() selectedIds: number[];
  @Input() allSelected;
  @Output() notificationDeleted = new EventEmitter<void>();

  constructor(
    private router: Router, 
    private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.updateMode('');
    this.notificationService.sharedMode.subscribe(mode => this.selectMode = mode)
  }

  updateSelectedIds(checked: boolean, id: number) {
    if (checked) {
      this.selectedIds.push(id);
    } else {
      const index = this.selectedIds.indexOf(id);
      if (index !== -1) {
        this.selectedIds.splice(index, 1);
      }
    }
    console.log(this.selectedIds);
  }
  deleteNotification(id:number){
    this.notificationService.deleteNotification(id).subscribe(res => {
      this.notificationDeleted.emit();
    })
  }
}
