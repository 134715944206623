import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public notifications = [];
  public error = false;
  public loading = false;
  selectMode = '';
  selectedIds: number[] = [];
  allSelected = false;
  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.getNotifications();
    this.notificationService.sharedMode.subscribe(mode => this.selectMode = mode)
  }

  selectAllNotifications() {
    for (const notification of this.notifications) {
      if (!this.selectedIds.includes(notification.id) && !notification.is_open) {
        this.selectedIds.push(notification.id);
      }
    }
  }

  getNotifications() {
    this.loading = true;
    this.notificationService.getNotifications().subscribe({
      next: (res) => {
        this.notifications = res.notificaciones.sort(
          (a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()
        );
      },
      error: (err) => {
        this.error = true;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  selectNotification(mode: string){
    this.notificationService.updateMode(mode);
    if(this.selectMode === 'selectAll'){
      this.selectAllNotifications();
      this.allSelected = true;
    }else if(this.selectMode === 'markMode'){
      console.log('aaa', this.selectedIds)
      this.selectedIds = [];
      this.allSelected = false;
    }
    console.log(this.selectedIds)
  }

  selectNotifications(){
    const body = { ids_notificaciones: this.selectedIds };
    this.notificationService.setViewNotification(body).subscribe((res) => {
      for (const notification of this.notifications) {
        if (this.selectedIds.includes(notification.id)) {
          const updatedNotification = res.notifications.find((n) => n.id === notification.id);
          if (updatedNotification) {
            notification.is_open = updatedNotification.is_open;
          }
        }
      }
      this.selectedIds = [];
      this.allSelected = false;
      this.notificationService.updateMode('')
    });
  }
  updateNotifications($event: any){
    $event.stopPropagation();
    this.getNotifications();
  }

  getUnseenNotifications() {
    return this.notifications.filter((item) => !item.is_open).length;
  }
}
