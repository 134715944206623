<div id="matriculaciones">
  <div class="container mt-4">
    <div class="even-grid">
      <div id="mod-banner" class="fill" *ngIf="modalidadSelected">
        <div
          class="banner-container default"
          [ngClass]="{
            green: modalidadSelected === 'online',
            blue: modalidadSelected === 'presencial'
          }"
          [id]="modalidadSelected"
        >
        <div class="icon">
          <i [class]="modalidadSelected === 'online' ? 'greenFont fa-light fa-circle-check check-icon' : 'blueFont fa-light fa-circle-check check-icon'"></i>
        </div>
          <div class="body">
            <div class="header">
              <span [class]="modalidadSelected === 'online' ? 'greenFont header' : ' blueFont header'"
                >REMATRÍCULA DE PROGRAMAS
                {{
                  modalidadSelected === "online" ? "ONLINE" : "PRESENCIALES"
                }} HABILITADA</span                
              >
            </div>
            <div class="description">
              Inicio:
              <span>
                {{
                  moment(this.matricService.dateInit).locale("es").format("L")
                }}
              </span>
            </div>
            <div class="description">
              Finalización:
              <span>
                {{
                  moment(this.matricService.dateEnd).locale("es").format("L")
                }}
              </span>
            </div>
            <div
              class="button-container"
              *ngIf="
                modalidadBase === 'mixta' &&
                ((modalidadSelected === 'online' &&
                  moment().isBetween(
                    moment(
                      this.fechasInscripcion.pr.fecha_inicio_insc + ' 00:00',
                      'YYYY-MM-DD HH:mm'
                    ),
                    moment(
                      this.fechasInscripcion.pr.fecha_fin_insc + ' 23:59',
                      'YYYY-MM-DD HH:mm'
                    )
                  )) ||
                  (modalidadSelected === 'presencial' &&
                    moment().isBetween(
                      moment(
                        this.fechasInscripcion.ol.fecha_inicio_insc + ' 00:00',
                        'YYYY-MM-DD HH:mm'
                      ),
                      moment(
                        this.fechasInscripcion.ol.fecha_fin_insc + ' 23:59',
                        'YYYY-MM-DD HH:mm'
                      )
                    )))
              "
              tourAnchor="cambiarModalidad"
            >
              <button [disabled]="btnSwitchMod" mat-flat-button (click)="goToModalidad()">
                Cambiar a modalidad
                {{ modalidadSelected === "online" ? "presencial" : "online" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-grid">
      <div class="sticky-container">
        <div class="content-aside">
          <div
            class="asignaturas-content main-shadow main-radius"
            tourAnchor="asigDisponibles"
          >
            <div class="content-body">
              <h2>Asignaturas disponibles</h2>
              
              <div class="credits-container">
                <div class="credit-card total-card">
                  <p>TOTAL ASIGNATURAS</p>
                  <h3>{{ totalCredits }}</h3>
                </div>
                <ng-container *ngFor="let credit of userCreditsTypes">
                  <div
                    #tooltip="matTooltip"
                    [matTooltip]="credit.description"
                    matTooltipPosition="below"
                    matTooltipClass="credit-tooltip"
                    [aria-label]="credit.description"
                    class="credit-card"
                    *ngIf="credit.total > 0"
                  >
                    <p>
                      {{ credit.nombre }}
                    </p>
                    <h3>{{ credit.total }}</h3>
                  </div>
                </ng-container>
              </div>
              <div class="buy-button-container" tourAnchor="compraCreditos">
                <ng-container *ngIf="!findOppByStage('Inscripto')">
                  <button
                    class="buy-btn"
                    color="primary"
                    (click)="openCartDialog()"
                    [disabled]="
                      (!this.matricula && this.isLoading) ||
                      !enableBuy ||
                      errorAsignaturas ||
                      isWaiting ||
                      cleanProducts.length === 0
                    "
                  >
                      {{(!this.matricula && this.isLoading) ||
                      !enableBuy ||
                      errorAsignaturas ||
                      isWaiting ||
                      cleanProducts.length === 0 ? 'Cargando' : 'Comprar'}}
                      <mat-spinner *ngIf="(!this.matricula && this.isLoading) ||
                      !enableBuy ||
                      errorAsignaturas ||
                      isWaiting ||
                      cleanProducts.length === 0 " style="width: 1rem!important; height: 1rem!important;" class="buy-spinner"></mat-spinner>
                  </button>

                </ng-container>
 
                <ng-container *ngIf="this.currentOpportunity && findOppByStage('Inscripto')">
                  <div class="open-opp">
                    ¡Tienes una compra de asignaturas pendiente de pago!
                  </div>
                  <button
                    mat-button
                    [disabled]="!this.matricula && this.isLoading"
                    (click)="openEditOpportunityDialog()"
                    class="bg-green text-white"
                  >
                    Editar o
                    {{
                      currentOpportunity.pagos[0].forma_pago === "Efectivo"
                        ? "adjuntar comprobante"
                        : "pagar"
                    }}
                  </button>
                </ng-container>
              </div>
            </div>
            <!-- <div
              class="verificacion-body"
              *ngIf="this.verificacionPago !== null"
            >
              <div class="card">
                <img src="../../../assets/images/content/wallet.svg" />
                <h1>¡Estamos verificando tu pago!</h1>
                <p>
                  Recuerda que una vez terminada la verificación recibirás las
                  asignaturas adquiridas. Este proceso puede demorar entre
                  <b> 1 y 3 días</b> para pagos con transferencia. Ante
                  cualquier duda puedes comunicarte vía mail a
                  <a href="mailto:cobros@adenuniversity.edu.pa"
                    >cobros@adenuniversity.edu.pa</a
                  >
                </p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="column-container">
        <div class="resumen-matriculacion" tourAnchor="resumenMatriculacion">
          <mat-accordion>
            <mat-expansion-panel
              [expanded]="[expandResumen]"
              (click)="openResumen()"
            >
              <mat-expansion-panel-header>
                <div class="panel-container">
                  <div class="panel-text">
                    <div class="title-tuto">
                      <h2>Tu resumen</h2>
                    </div>
                    <div class="resumen">
                      <h2>
                        {{ selectMateriasName.length }}
                        <span tourAnchor="limiteAsignaturas">
                          / {{ limitMateriasByMod }}
                        </span>
                      </h2>
                      <p>agregadas</p>
                    </div>
                  </div>
                  <div class="panel-action">
                    <div class="completion">
                      <button
                        tourAnchor="completarMatriculacion"
                        *ngIf="selectMaterias.length !== 0"
                        mat-flat-button
                        color="primary"
                        [disables]="selectMaterias.length === 0"
                        id="sendInfo"
                        (click)="$event.stopPropagation(); validateMatricula()"
                      >
                        Completar inscripción
                      </button>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <mat-chip-list
                *ngIf="selectMateriasName.length > 0"
                #chipList
                aria-label="Seleccionar Materia"
                ><span tourAnchor="asignaturaCargada"
                  ><mat-chip
                    *ngFor="let materia of selectMateriasName"
                    (removed)="removeMateriaChip(materia.subject_id, materia.carreraId)"
                  >
                    {{
                      materia.tipo === "to_elected"
                        ? materia.nombre + " (ELECTIVA)"
                        : materia.tipo === "to_elect_grade"
                        ? materia.nombre + " (OPCIÓN DE GRADO)"
                        : materia.nombre
                    }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip></span
                >
              </mat-chip-list>
              <div *ngIf="selectMateriasName.length <= 0" class="empty-state">
                Aún no has seleccionado asignaturas
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div class="body card-aden-plain no-margin">
          <app-tabs-carreras
            [filteredCarreras]="filteredCarreras"
            [masterySelected]="masterySelected"
            [thesisSelected]="thesisSelected"
            [periodos]="this.matricService.periodos"
            [disabled]="disabled"
            [gradeSubjects]="gradeSubjects"
            [selectMaterias]="selectMaterias"
            [removeMateria]="removeMateria"
            [addMateria]="addMateria"
            [getUser]="getUser"
            [pensumElectiveIds]="pensum_elective_ids"
            [allEspecialidades]="allEspecialidades"
            [getEspecialidadById]="getEspecialidadById"
            [modalidadSelected]="modalidadSelected"
          ></app-tabs-carreras>
          <div
            #EmptyArray
            *ngIf="
              (filteredCarreras.length <= 0 && !errorAsignaturas) ||
              loadingAsignaturas
            "
            class="container text-center spinner"
          >
            <mat-spinner></mat-spinner>
          </div>
          <div
            class="notfound-container"
            *ngIf="errorAsignaturas && !loadingAsignaturas"
          >
            <img src="../../../assets/images/content/no-data.svg" alt="" />
            <h2>No pudimos cargar tus carreras</h2>
            <p>
              ¡Lo sentimos! El servidor encontró un error interno y no pudo
              cargar tus carreras. Por favor, contacta a tu Academic Advisor
              para más información o intenta de nuevo más tarde.
            </p>
            <div class="button-container">
              <button
                mat-flat-button
                color="primary"
                aria-label="Reintentar"
                (click)="getAsignaturas()"
              >
                Reintentar
              </button>
              <mat-menu #menu="matMenu">
                <ng-container *ngFor="let item of links">
                  <a mat-menu-item [href]="item.url" target="_blank">{{
                    item.name
                  }}</a>
                </ng-container>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-alerts-matricula
  [contrato]="contrato"
  [matricula]="matricula"
  [isLoading]="isLoading"
  [showMatriculacion]="showMatriculacion"
  [bloqueo]="bloqueo"
  [isWaiting]="isWaiting"
  [openContractDialog]="openContractDialog"
  [dates]="{ init: this.matricService.dateInit, end: this.matricService.dateEnd }"
  [leftTime]="this.matricService.timeLeft"
  [modalidadSelected]="modalidadSelected"
  [modalidadBase]="modalidadBase"
></app-alerts-matricula>
