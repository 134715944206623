<!-- <div class="card-container" *ngIf="type === 'icon'">
  <div
    class="icon-container"
    [ngStyle]="{
      'background-color':
        icon === 'done'
          ? 'rgba(53, 208, 165, 1)'
          : icon === 'schedule'
          ? 'rgba(194, 159, 67, 1)'
          : icon === 'close'
          ? 'rgba(179, 29, 21, 1)'
          : '#6181FF'
    }"
  >
    <mat-icon class="material-icons-round">{{ icon }}</mat-icon>
  </div>
  <div class="card-body icon">
    <h3
      [ngStyle]="{
        color:
          icon === 'done'
            ? 'rgba(53, 208, 165, 1)'
            : icon === 'schedule'
            ? 'rgba(194, 159, 67, 1)'
            : icon === 'close'
            ? 'rgba(179, 29, 21, 1)'
            : '#6181FF'
      }"
    >
      {{ label }}
    </h3>
    <p class="value">{{ value }}</p>
    <p *ngIf="description">{{ description }}</p>
  </div>
</div>
<div class="card-container" *ngIf="type === 'progress'">
  <div class="card-body plain">
    <h3>TU PROGRESO</h3>
    
    <mat-progress-spinner
      mode="determinate"
      [diameter]="70"
      [value]="value.replaceAll(',', '.')"
    >
    </mat-progress-spinner>
    <div class="progress-bar">
      <mat-progress-bar
        mode="determinate"
        [value]="value.replaceAll(',', '.')"
      ></mat-progress-bar>
      <div class="progress-value">{{ value | number: "1.2-2" }}%</div>
    </div>
  </div>
</div>
<div class="card-container plain" *ngIf="type === 'plain'">
  <div class="card-body plain">
    <h3>{{ label }}</h3>
    <p class="value">{{ value }}</p>
    <p *ngIf="description">{{ description }}</p>
  </div>
</div> -->

<div class="card-container">
  <div class="icon absolute">
    <mat-icon
      class="material-icons-round"
      [ngStyle]="{
        color:
          icon === 'done'
            ? 'rgba(53, 208, 165, 1)'
            : icon === 'schedule'
            ? 'rgba(194, 159, 67, 1)'
            : icon === 'close'
            ? 'rgba(179, 29, 21, 1)'
            : '#6181FF'
      }"
      >{{ icon }}</mat-icon
    >
  </div>

  <div class="card-body">
    <h3
      [ngStyle]="{
        color:
          icon === 'done'
            ? 'rgba(53, 208, 165, 1)'
            : icon === 'schedule'
            ? 'rgba(194, 159, 67, 1)'
            : icon === 'close'
            ? 'rgba(179, 29, 21, 1)'
            : '#6181FF'
      }"
    >
      {{ label }}
    </h3>
    <p class="value">{{ value }}</p>
    <p *ngIf="description">{{ description }}</p>
  </div>
</div>
