<div class="dialog">
  <div class="close">
    <button mat-icon-button class="close-btn">
      <mat-icon class="material-icons-round" (click)="dialogRef.close()"
        >close</mat-icon
      >
    </button>
  </div>
  <app-custom-stepper>
    <ng-container *ngFor="let news of data.items; let i = index">
      <cdk-step>
        <div class="step-container">
          <div
            class="image"
            [style.background-image]="
              'url(' + cmsUrl + news.attributes.image.data.attributes.url + ')'
            "
          ></div>
          <div class="body">
            <div class="chip">{{ news.attributes.tag }}</div>
            <h1>
              {{ news.attributes.title }}
            </h1>
            <div
              id="post-content"
              [innerHTML]="news.attributes.description | markdown"
            ></div>

            <div class="footer">
              <div class="numbers">
                <p class="counter">
                  {{ i + 1 }} <span>/ {{ data.items.length }}</span>
                </p>
              </div>
              <div class="button-container first">
                <button
                  mat-icon-button
                  color="primary"
                  [disabled]="i === 0"
                  cdkStepperPrevious
                >
                  <mat-icon class="material-icons-round">arrow_back</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="primary"
                  [disabled]="i === data.items.length - 1"
                  cdkStepperNext
                >
                  <mat-icon class="material-icons-round"
                    >arrow_forward</mat-icon
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </cdk-step>
    </ng-container>
  </app-custom-stepper>
</div>
