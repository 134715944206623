<div class="card-advisor main-radius">
  <div class="content-image">
    <div class="header">
      <img
        src="../../../../../assets/images/logos/academic-logo.png"
      />
    </div>
  </div>
  <div class="data">
    <div class="title">Mi academic Advisor</div>
    <div class="name">
      {{
        (academic$ | async)?.nombre
          ? (academic$ | async)?.nombre
          : "No posee datos"
      }}
    </div>
    <div class="button-container">
      <!-- <button
        [ngClass]="{
          'social-button':
            (academic$ | async)?.celular || (academic$ | async)?.celular !== 0,
          'disabled-button':
            !(academic$ | async)?.celular || (academic$ | async)?.celular === 0
        }"
        (click)="sendWhatsapp()"
        [disabled]="
          !(academic$ | async)?.celular || (academic$ | async)?.celular === 0
        "
      >
        <mat-icon class="material-icons-round">whatsapp</mat-icon>
        <div class="text">
          {{
            (academic$ | async)?.celular
              ? (academic$ | async)?.celular
              : "No posee datos"
          }}
        </div>
        <mat-icon class="material-icons-round" id="arrow"
          >arrow_forward</mat-icon
        >
      </button> -->
      <button (click)="sendWhatsapp()">Contactar por Whatsapp<i class="fa-brands fa-whatsapp icon"></i></button>
      <button (click)="sendMail()">Contactar por Correo<i class="fa-light fa-envelope icon"></i></button>
      <!-- 
      <div class="social-button">
        <mat-icon class="fab fa-linkedin icon-social fa-xl"></mat-icon>
        <div class="text">
          {{
            (academic$ | async)?.linkedin
              ? (academic$ | async)?.linkedin
              : "No posee datos"
          }}
        </div>
        <mat-icon class="material-icons-round" id="arrow"
          >arrow_forward</mat-icon
        >
      </div> -->
    </div>
  </div>
</div>
