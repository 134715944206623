// videos.reducer.ts
import { IResource } from 'src/app/shared/interfaces/ICourse';
import { CourseAction, CoursesActionTypes } from '../actions/courses.action';

export interface CourseState {
  coursesList: IResource[];
}

const initialState: CourseState = {
    coursesList: []
};

export function coursesReducer(state = initialState, action: CourseAction): CourseState {
  switch (action.type) {
    case CoursesActionTypes.LOAD_COURSES:
      return {
        ...state,
        coursesList: action.payload
      }
  }
}
