import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { UserState } from './../../redux/reducers/user.reducer';
import { CmsService } from './../../shared/services/cms.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { materias, periodos } from 'src/app/shared/data/graduacion';

@Component({
  selector: 'app-graduacion',
  templateUrl: './graduacion.component.html',
  styleUrls: ['./graduacion.component.scss'],
})
export class GraduacionComponent implements OnInit {
  public user$;
  public user;

  links = [
    { name: 'Inicio', tag: 'inicio' },
    { name: 'Requisitos', tag: 'requisitos' },
    { name: 'Calendario de matriculación', tag: 'calendario' },
    { name: 'Materias', tag: 'materias' },
  ];
  activeLink = this.links[0];

  public moment = moment;

  public sendingMessage = false;
  messageCtrl = new UntypedFormControl('', Validators.required);

  periodos = periodos;

  materias = materias;

  constructor(
    private cmsService: CmsService,
    private store: Store<UserState>,
    public snackbar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    this.user$ = store.select('user');
  }

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
      }
    });
  }
}
