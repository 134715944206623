import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() name: string;
  @Input() description: string;
  @Input() size: string;
  @Input() icon: string = null;
  @Input() iconImage: boolean;
  @Input() iconImageUrl: string;
  @Input() link: string;
  isSpecMode = false;
  @Input() toggleMenu: boolean = false;
  @Input() showLabel: boolean = false;
  @Input() isActive: boolean = false;
  @Input() isIconify: boolean = false;
  @Input() canReload = false;

  constructor(private router: Router,
    private specViewService: SpecViewService) {}

  goTo() {
    if (this.link.includes('http') || this.link.includes('assets')) {
      window.open(this.link, '_blank');
    } else {
      if(this.link !== 'noNavigate'){
        this.router.navigate([this.link]);
      }
    }
  }

  ngOnInit(){
    this.specViewService.currentSharedSpecMode.subscribe(data => {
      this.isSpecMode = data.isSpecMode;
    })
  }

  reloadPage(){
    if(this.canReload){
      this.router.navigateByUrl('/buscojobs').then(()=>window.location.reload())
    }
  }
} 
