import { CompletedDialogComponent } from 'src/app/shared/components/dialogs/completed-dialog/completed-dialog.component';
import { Observable } from 'rxjs';
import { Idioma } from '../../../../shared/interfaces/IIdioma';
import {  ContestMember, ContestTeam} from '../../../../shared/interfaces/IContest'
import { ContestService } from '../../../../shared/services/contest.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { startWith, map, switchMap, debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { universities } from 'src/app/shared/data/universities';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-form-internacional',
  templateUrl: './form-internacional.component.html',
  styleUrls: ['./form-internacional.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class FormInternacionalComponent implements OnInit {
  isLoading = true;
  isWaiting = false;
  abledUser = false;

  currentUserID = parseInt(localStorage.getItem('sis_id'), 10);

  public student;
  public countries;
  public selectedNationality;
  public selectedCountry;
  public firstSelectedUniversity;
  public secondSelectedUniversity;
  public files: any[] = [];
  public formData: any;

  personalDataFormGroup: UntypedFormGroup;
  academicDataFormGroup: UntypedFormGroup;
  languagesDataFormGroup: UntypedFormGroup;
  filesDataFormGroup: UntypedFormGroup;

  languagesData: Idioma[] = [];

  stepperOrientation: Observable<StepperOrientation>;
  @ViewChild('stepper') stepper: MatStepper;

  public universities = universities.filter((uni) => !uni.externalInscription);

  constructor(
    private contestService: ContestService,
    private alertService: AlertService,
    private alumnoService: AlumnoService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    breakpointObserver: BreakpointObserver
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 1000px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit(): void {
    this.checkUser();
    this.personalDataFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      date_of_birth: ['', Validators.required],
      nationality: ['', Validators.required],
      passport: ['', Validators.required],
      passport_expiration: ['', Validators.required],
      contact: this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        phone: new UntypedFormControl({ value: '', disabled: false }, [
          Validators.required,
        ]),
      }),
      emergency_contact: this.formBuilder.group({
        full_name: ['', Validators.required],
        phone: new UntypedFormControl({ value: '', disabled: false }, [
          Validators.required,
        ]),
        email: ['', [Validators.required, Validators.email]],
        relationship: ['', Validators.required],
      }),
      address: this.formBuilder.group({
        current_address: ['', Validators.required],
        location: ['', Validators.required],
        country: ['', Validators.required],
      }),
    });
    this.academicDataFormGroup = this.formBuilder.group({
      career: ['', Validators.required],
      year: ['', [Validators.required, Validators.min(1), Validators.max(6)]],
      first_university: this.formBuilder.group({
        name: ['', Validators.required],
        selected_career: [''],
      }),
      second_university: this.formBuilder.group({
        name: ['', Validators.required],
        selected_career: [''],
      }),
    });
    this.languagesDataFormGroup = this.formBuilder.group({
      languaje: [false, Validators.required],
      list: this.formBuilder.array([]),
    });
    this.filesDataFormGroup = this.formBuilder.group({
      passport: ['', Validators.required],
      cv: [''],
      card: [''],
      languaje: [''],
    });

    this.getUser();
  }

  checkUser(): void {
    this.contestService.checkInscription(this.currentUserID).subscribe({
      next: (res) => {
        this.abledUser = res.avaliable;
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  }

  getUser(): void {
    this.alumnoService.getAlumno().subscribe({
      next: (data) => {
        if (
          (data.nombre === '' || data.nombre === null) &&
          (data.email === '' || !data.email || data.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
        this.student = data;
        this.getCountries(data.escuela_pais, data.pais);
        this.createForm();
      },
      error: (err) => {
        this.alertService.openAlert(
          '¡Sesión vencida!',
          'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
          'Ok'
        );
        setTimeout(() => {
          this.authService.logout();
        }, 5000);
      },
    });
  }

  createForm(): void {
    this.personalDataFormGroup = this.formBuilder.group({
      name: [this.student.nombre, Validators.required],
      last_name: [this.student.apellido, Validators.required],
      date_of_birth: [this.student.fecha_nacimiento, Validators.required],
      nationality: [this.student.pais, Validators.required],
      passport: ['', Validators.required],
      passport_expiration: ['', Validators.required],
      contact: this.formBuilder.group({
        email: [this.student.email, [Validators.required, Validators.email]],
        phone: new UntypedFormControl(
          { value: this.student.movil, disabled: false },
          [Validators.required]
        ),
      }),
      emergency_contact: this.formBuilder.group({
        full_name: [this.student.nombre_emergencia, Validators.required],
        phone: new UntypedFormControl(
          { value: this.student.movil_emergencia, disabled: false },
          [Validators.required]
        ),
        email: ['', [Validators.required, Validators.email]],
        relationship: [this.student.parentesco_emergencia, Validators.required],
      }),
      address: this.formBuilder.group({
        current_address: ['', Validators.required],
        location: ['', Validators.required],
        country: [this.student.pais, Validators.required],
      }),
    });
    this.academicDataFormGroup = this.formBuilder.group({
      career: [this.student.carreras[0].carrera, Validators.required],
      year: ['', [Validators.required, Validators.min(1), Validators.max(6)]],
      first_university: this.formBuilder.group({
        name: ['', Validators.required],
        selected_career: [''],
      }),
      second_university: this.formBuilder.group({
        name: ['', Validators.required],
        selected_career: [''],
      }),
    });
    this.languagesDataFormGroup = this.formBuilder.group({
      languaje: [false, Validators.required],
      list: this.formBuilder.array([]),
    });
    this.filesDataFormGroup = this.formBuilder.group({
      passport: ['', Validators.required],
      cv: [''],
      card: [''],
      languaje: [''],
    });
  }

  getCountries(paisEscuela, paisAlumno): void {
    this.alumnoService.getPaises().subscribe((data) => {
      this.countries = data;

      this.transformValueCountry(
        this.countries.paises,
        paisAlumno,
        paisEscuela
      );
    });
  }

  transformValueCountry(array, nationality, countrySchool): void {
    // Pais escuela
    for (const country of array) {
      if (countrySchool.toLowerCase() === country.name.toLowerCase()) {
        this.selectedCountry = country.id;
        break;
      }
    }
    // Pais alumno
    for (const country of array) {
      if (nationality.toLowerCase() === country.name.toLowerCase()) {
        this.selectedNationality = country.id;
        break;
      }
    }
  }

  compareOptions(o1: any, o2: any): any {
    return o1 && o2 && o1 === o2;
  }

  /* FILES MANAGEMENT */

  setFiles(newFile): void {
    this.files = newFile;
    if (this.files.length >= 3) {
      this.filesDataFormGroup.get('passport').setValue('Cargado');
    } else {
      this.filesDataFormGroup.get('passport').setValue('');
    }
    this.createFormData();
  }

  /* SET UNIVERSITIES */

  setFirstUniversity(): void {
    const name = this.academicDataFormGroup
      .get('first_university')
      .get('name').value;
    this.firstSelectedUniversity = this.universities.find(
      (uni) => uni.name === name
    );
  }

  setSecondUniversity(): void {
    const name = this.academicDataFormGroup
      .get('second_university')
      .get('name').value;
    this.secondSelectedUniversity = this.universities.find(
      (uni) => uni.name === name
    );
  }

  /* LANGUAGES LIST */
  get list(): UntypedFormArray {
    return this.languagesDataFormGroup.get('list') as UntypedFormArray;
  }

  addLanguage(): void {
    const languageForm = this.formBuilder.group({
      name: ['', Validators.required],
      level: ['', Validators.required],
      national_certificate: [false, Validators.required],
      international_certificate: [false, Validators.required],
    });

    this.list.push(languageForm);
  }

  emptyList(): void {
    this.languagesDataFormGroup
      .get('list')
      .setValue(this.formBuilder.array([]));
  }

  deleteLanguage(languageId: number): void {
    this.list.removeAt(languageId);
  }

  /* DIÁLOGO SUCCESS */

  openCompletedDialog(type, title, description, img): void {
    const dialogRef = this.dialog.open(CompletedDialogComponent, {
      width: 'auto',
      data: { type, title, description, img, dialogId: 'dialog-international' },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  /* COMPLETAR INSCRIPCIÓN */

  createFormData(): void {
    this.formData = {
      id: this.currentUserID,
      personal_data: {
        name: this.personalDataFormGroup.get('name').value,
        last_name: this.personalDataFormGroup.get('last_name').value,
        date_of_birth: this.personalDataFormGroup.get('date_of_birth').value,
        nationality: this.personalDataFormGroup.get('nationality').value,
        passport: this.personalDataFormGroup.get('passport').value,
        passport_expiration: this.personalDataFormGroup.get(
          'passport_expiration'
        ).value,
      },
      contact: this.personalDataFormGroup.get('contact').value,
      emergency_contact:
        this.personalDataFormGroup.get('emergency_contact').value,
      address: this.personalDataFormGroup.get('address').value,
      languajes: this.languagesDataFormGroup.value,
      academic_data: this.academicDataFormGroup.value,
      documentation: this.filesDataFormGroup.value,
    };
  }

  completeInscription(): void {
    this.isWaiting = true;
    const sendBtnText = document.getElementById('send-text');
    document.getElementById('loader').classList.remove('no-display');
    document.getElementById('completeInscription').classList.add('transparent');
    document
      .getElementById('completeInscription')
      .setAttribute('disabled', 'true');
    sendBtnText.innerHTML = 'Enviando ';

    const filesData = new FormData();

    filesData.append(
      'student',
      `${this.formData.personal_data.name} ${this.formData.personal_data.last_name}`
    );
    filesData.append('id', `${this.formData.id}`);
    filesData.append('passport', this.files[0]);
    filesData.append('cv', this.files[1]);
    filesData.append('card', this.files[2]);

    if (this.files.length > 3) {
      filesData.append('languaje', this.files[3]);
      this.files.slice(4, this.files.length).forEach((file, index) => {
        filesData.append(`file${index}`, file);
      });
    }

    this.contestService.createInscription(this.formData).subscribe({
      next: (res) => {
        this.contestService.uploadUserDocumentation(filesData).subscribe({
          next: (files) => {
            this.isWaiting = false;
            this.openCompletedDialog(
              'success',
              '¡Inscripción completada con éxito!',
              'Tu postulación fue enviada, recibirás la confirmación en tu mail',
              '../../../../../assets/images/content/world-internacional.png'
            );

            this.abledUser = false;

            document.getElementById('loader').classList.add('no-display');
            document
              .getElementById('completeInscription')
              .classList.remove('transparent');
            document
              .getElementById('completeInscription')
              .removeAttribute('disabled');
            sendBtnText.innerHTML = 'Completar inscripción';
          },
          error: (err) => {
            this.isWaiting = false;
            this.openCompletedDialog(
              'error',
              '¡Ups!',
              'Ocurrió un error inesperado, por favor intenta de nuevo más tarde',
              '../../../../../assets/images/content/error-blue.png'
            );

            document.getElementById('loader').classList.add('no-display');
            document
              .getElementById('completeInscription')
              .classList.remove('transparent');
            document
              .getElementById('completeInscription')
              .removeAttribute('disabled');
            sendBtnText.innerHTML = 'Completar inscripción';
          },
        });
      },
      error: (err) => {
        this.isWaiting = false;
        this.openCompletedDialog(
          'error',
          '¡Ups!',
          'Ocurrió un error inesperado, por favor intenta de nuevo más tarde',
          '../../../../../assets/images/content/error-blue.png'
        );
        document.getElementById('loader').classList.add('no-display');
        document
          .getElementById('completeInscription')
          .classList.remove('transparent');
        document
          .getElementById('completeInscription')
          .removeAttribute('disabled');
        sendBtnText.innerHTML = 'Completar inscripción';
      },
    });
  }
}
