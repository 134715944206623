<div>
  <div class="header">
    <h1>Tus solicitudes</h1>
    <button (click)="getStatusTicket()">
      <i [ngClass]="{ 'rotating': isRefreshing }"  class="refresh-icon fa-regular fa-arrows-rotate"></i>
    </button>
  </div>
  <mat-form-field appearance="standard">
    <mat-label>Filtrar</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ej: Finanzas" #input>
  </mat-form-field>
  <div *ngIf="loading" class="loading-container">
    <mat-spinner ></mat-spinner>
  </div>
  <table *ngIf="!loading" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
    <ng-container  matColumnDef="caseNumber">
      <th mat-header-cell *matHeaderCellDef> Número de Caso </th>
      <td mat-cell class="row-table"  *matCellDef="let element" (click)="openModal(element)"> {{ element.caseNumber }} </td>
    </ng-container>

    <ng-container matColumnDef="caseType">
      <th mat-header-cell *matHeaderCellDef> Tipo de Caso </th>
      <td mat-cell class="row-table" *matCellDef="let element" (click)="openModal(element)"> {{ element.caseType }} </td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> Categoría </th>
      <td mat-cell class="row-table" *matCellDef="let element" (click)="openModal(element)"> {{ element.category }} </td>
    </ng-container>
    <ng-container matColumnDef="createDate">
      <th mat-header-cell *matHeaderCellDef> Fecha de creacion </th>
      <td mat-cell class="row-table" *matCellDef="let element" (click)="openModal(element)"> {{ formatDate(element.createDate) }} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Estado </th>
      <td mat-cell class="row-table" *matCellDef="let element" (click)="openModal(element)">
        <span class="status" [ngClass]="{
          'pass': element.status.toLowerCase() === 'aprobado' || element.status.toLowerCase() === 'completado',
          'pending': element.status.toLowerCase() === 'derivado' || element.status.toLowerCase() === 'en proceso',
          'error': element.status.toLowerCase() === 'cerrado' || element.status.toLowerCase() === 'rechazado'
        }">
          {{ element.status }}
        </span>
      </td>
    </ng-container>
    

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No hay datos que coincidan con "{{ input.value }}"</td>
    </tr>

  </table>
</div>
