<div class="dialog-header">
  <div mat-dialog-title>
    <h1>¡Orden generada con éxito!</h1>
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
</div>
<div class="dialog-container">
  <div class="step-container">
    <div class="cart main-radius main-border">
      <div class="top">
        <div class="title">Detalles de la orden</div>
        <div class="product-list2">
          <ng-container
            style="width: 100%"
            *ngFor="let producto of data.oportunidad.productos"
          >
            <app-simple-product-card
              [product]="producto"
              [actions]="false"
            ></app-simple-product-card>
          </ng-container>
        </div>
      </div>
      <div class="footer">
        <div class="list-item">
          <div class="title-1">TOTAL</div>
          <div class="price">
            <div class="unit">US$</div>
            <div class="number">{{ data.total | number: "1.2-2" }}</div>
          </div>
        </div>
        <div class="list-item">
          <div class="title-2">Cant. cuotas</div>
          <div class="price">
            <div class="number">{{ data.oportunidad.cuotas }}</div>
          </div>
        </div>
        <div class="list-item">
          <div class="title-2">Valor cuota (a transferir)</div>
          <div class="price">
            <div class="unit">US$</div>
            <div class="number">
              {{ data.total / data.oportunidad.cuotas | number: "1.2-2" }}
            </div>
          </div>
        </div>
        <button
          mat-flat-button
          id="btn-confirm-transfer"
          style="width: 100%"
          color="primary"
          (click)="sendEmail()"
        >
          Enviar comprobante de pago
        </button>
      </div>
    </div>
    <div class="body b">
      <h3>1. Realiza la transferencia</h3>

      <p>Deberás transferir el valor correspondiente a la primera cuota.</p>
      <div class="datos-transferencia">
        <div class="bank-data">
          <div class="item">
            <label>Banco</label>
            <p>Banco Banistmo</p>
          </div>
          <div class="item">
            <label>Tipo y Nº de cuenta</label>
            <p>
              Cuenta Corriente Nº
              {{ data.ifharu ? "101087584" : "0114535215" }}
            </p>
          </div>
          <div class="item">
            <label>Nombre</label>
            <p>
              {{
                data.ifharu
                  ? "Alta Dirección S.A"
                  : "Universite de Management de Suisse"
              }}
            </p>
          </div>
        </div>
        <div class="aviso">
          <p class="title">¡IMPORTANTE!</p>
          <p>
            Al realizar la transferencia, recuerda colocar como asunto o
            descripción de la misma tu ID de estudiante
          </p>
          <div class="chip">ID: {{ data.user.legajo }}</div>
        </div>
      </div>

      <h3 class="mt-3">2. Envíanos tu comprobante de pago</h3>
      <div class="button-container">
        <div class="email-button" (click)="sendEmail()">
          <div class="body">
            <p>
              Deberás enviar el comprobante de la operación vía mail a las
              direcciones
              <strong>gestorrecaudo@adenuniversity.edu.pa</strong> y
              <strong>cobros@adenuniversity.edu.pa</strong> indicando tu ID de
              estudiante como asunto del mensaje.
            </p>
            <button mat-flat-button color="primary">
              Adjuntar comprobante ahora
            </button>
          </div>
        </div>
      </div>
      <div class="text-muted p-4">
        Al finalizar la compra se iniciará el proceso de verificación de pago,
        el mismo puede demorar entre <b>1 y 3 días</b> para pagos por
        transferencia. Una vez que hayamos verificado tu pago, se acreditarán en
        el Campus las asignaturas adquiridas.
      </div>
    </div>
  </div>
</div>
