<div *ngIf="!item.redirect" class="card-home" (click)="goTo()">
  <div class="icon absolute">
    <iconify-icon [icon]="item.icon"></iconify-icon>
  </div>
  <div class="title">{{ item.title }}</div>
  <div class="description">{{ item.description }}</div>
  <div class="icon">
    <mat-icon class="material-icons-round">arrow_forward</mat-icon>
  </div>
</div>
<a *ngIf="item.redirect" [href]="item.link" target="_blank" class="card-home black-font">
  <div class="icon absolute">
    <iconify-icon [icon]="item.icon"></iconify-icon>
  </div>
  <div class="title">{{ item.title }}</div>
  <div class="description">{{ item.description }}</div>
  <div class="icon">
    <mat-icon class="material-icons-round">arrow_forward</mat-icon>
  </div>
</a>

