import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-card-orden',
  templateUrl: './card-orden.component.html',
  styleUrls: ['./card-orden.component.scss'],
})
export class CardOrdenComponent implements OnInit {
  @Input() order;
  @Input() cancelOrder: (order_id: any) => void;
  @Input() openDetailsDialog: (order: any) => void;

  public moment = moment;

  constructor() {}

  ngOnInit(): void {
    console.log();
  }

  onCancel() {
    this.cancelOrder(this.order.id);
  }

  openLink() {
    window.open(this.order.link_pago, '_blank');
  }
}
