import { TourService } from 'ngx-ui-tour-md-menu';
import { ActivatedRoute, Router } from '@angular/router';
import { EditPaymentComponent } from './components/edit-payment/edit-payment.component';
import { Oportunidad } from 'src/app/shared/interfaces/IEstadoFinanciero';
import { CarreraMatricula, CarreraPrincipal } from 'src/app/shared/interfaces/ICarrera';
import { ReplaySubject } from 'rxjs';
import { ConfirmDialogComponent } from '../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalConfirm } from './components/modal/modal.component';
import { ContractDialogComponent } from './components/contract-dialog/contract-dialog.component';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import * as reduxUser from '../../redux/actions/user.action';
import { VideoDialogComponent } from 'src/app/shared/components/dialogs/video-dialog/video-dialog.component';
import { DescriptionDialogComponent } from 'src/app/shared/components/dialogs/description-dialog/description-dialog.component';
import { CarritoComponent } from './components/carrito/carrito.component';
import moment from 'moment';
import { ModalidadDialogComponent } from './components/modalidad-dialog/modalidad-dialog.component';
import { matriculacionesTour } from 'src/app/shared/data/tours';
import { MatriculacionesService } from 'src/app/shared/services/matriculaciones.service';
import { CreditStrategyFactory } from 'src/app/shared/credits-strategy';
import { CreditsData } from 'src/app/shared/data/creditsData';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { AlertDialogComponent } from 'src/app/shared/components/alerts/alert-dialog/alert-dialog.component';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AsignaturaService } from 'src/app/shared/services/asignatura.service';
import { FinancialCreditsService } from 'src/app/shared/services/financial-credits.service';
import { DocumentsService } from 'src/app/shared/services/documents.service';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { PaymentResumeComponent } from './components/payment-resume/payment-resume.component';

@Component({
  selector: 'app-matriculaciones',
  templateUrl: './matriculaciones.component.html',
  styleUrls: ['./matriculaciones.component.scss'],
})
export class MatriculacionesComponent implements OnInit, AfterViewInit {
  title = 'Matriculaciones';
  today = moment();
  fechasInscripcion = null;
  moment = moment;

  creditosTotales = 0;

  dualDisponible = 0;

  btnSwitchMod = false;

  materiasPermitidas = 0;
  limitMateriasByMod = 0; 
  selectedPeriodos = [];
  cantidadAsignaturas = 0;
  asignaturasInscriptas = 0;
  allCarreras: CarreraMatricula[] = [];
  filteredCarreras: CarreraMatricula[] = [];
  searchValue: string;
  selectMaterias = [];
  selectMateriasName = [];
  sisId = '';

  carreras: CarreraPrincipal[] = [];;
  primeraMatriculacion = true;
  periodoValido = true;
  disabled = true;
  isSpecMode = false;

  /* MODALIDAD */
  modalidadBase: string = null;
  modalidadSelected: string = null;
  checkAristotles = false;

  /* VALIDACIÓN DOBLE CARRERA */
  selectedDobleCarrera = 0;
  carrera2Id: number;
  hasDobleCarrera = false;
  isDobleCareer = false;
  allPeriodos = [];

  /* COMPRA CRÉDITOS */
  userCreditsTypes = [];
  cleanCredits = [];
  cleanProducts = [];
  totalCredits = 0;
  cantDaysDiffer = 1;
  limitCuotas = 1;

  costoAsignatura = 0;
  costoAsignaturaRegular = 0;
  costoPracticaSocial = 0;
  costoAsignaturaDual = 0;
  verificacionPago = null;
  expandResumen = false;

  /* CONDITIONAL RENDERING */
  contrato = true; // true en prod
  matricula = true; // true en prod
  showMatriculacion = true;
  bloqueo = false;
  enableBuy = true;
  enableEditOpportunity = true;
  isLoading = true;
  minAsigments:any;
  isWaiting = true;
  errorAsignaturas = false;
  ifharu = false;
  dont_pay_fixed_cost = false;
  private disabledSub = new ReplaySubject<boolean>(2); 
  loadingAsignaturas = false;

  /* ELECTIVAS */
  pensum_elective_id = '';
  pensum_elective_ids = [];
  currentDateOpp = [];
  currentEditOpp:any = null;

  credits = CreditsData;

  /* ESPECIALIDADES */
  currentEspecialidadId = '';
  allEspecialidades = [];
  allOportunidades = [];

  periodosWithInscriptions = [];

  public user$: Observable<any>;
  public user;
  public currentOpportunity: Oportunidad = null;
  public openOpportunities: Oportunidad[] = [];

  constructor(
    private alertService: AlertService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private store: Store<UserState>,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private alumnoService: AlumnoService,
    private documentService: DocumentsService,
    private tourService2: TourService,
    public matricService: MatriculacionesService,
    private asignaturaService: AsignaturaService,
    private specViewService: SpecViewService,
    private financialCreditsService: FinancialCreditsService
  ) {}

  ngOnInit(): void {

    this.getSpec();

    this.activeRoute.queryParams.subscribe((params) => {
      this.modalidadSelected =
        params.mod != null && params.mod != undefined ? params.mod : null;
    });

    this.getUser();
    this.initTour();

    /* BINDING */
    this.openDescription = this.openDescription.bind(this);
    this.openContractDialog = this.openContractDialog.bind(this);
    this.removeMateria = this.removeMateria.bind(this);
    this.addMateria = this.addMateria.bind(this);
    this.getAsignaturas = this.getAsignaturas.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getEspecialidadById = this.getEspecialidadById.bind(this);
  }

  ngAfterViewInit(): void {
    if (this.modalidadSelected === 'online') {
      document
        .getElementById(this.modalidadSelected)
        .classList.remove('default');
      document.getElementById(this.modalidadSelected).classList.add('green');
    }
    if (this.modalidadSelected === 'presencial') {
      document
        .getElementById(this.modalidadSelected)
        .classList.remove('default');
      document.getElementById(this.modalidadSelected).classList.add('purple');
    }
  }

  openSelectModalidad(): void {
    const dialogRef = this.dialog.open(ModalidadDialogComponent, {
      width: '400px',
      maxWidth: '90vw',
      panelClass: 'plain-alert',
      disableClose: true,
    });
    document.getElementById('matriculaciones').classList.add('blur');

    dialogRef.afterClosed().subscribe((modalidad) => {
      if (modalidad) {
        this.modalidadSelected = modalidad;
        if (this.modalidadSelected === 'online') {
          this.matricService.dateEnd = this.fechasInscripcion.ol.fecha_fin_insc
            ? moment(
                this.fechasInscripcion.ol.fecha_fin_insc + ' 23:50',
                'YYYY-MM-DD HH:mm'
              )
            : null;
          this.matricService.dateInit = this.fechasInscripcion.ol.fecha_inicio_insc
            ? moment(
                this.fechasInscripcion.ol.fecha_inicio_insc + ' 00:00',
                'YYYY-MM-DD HH:mm'
              )
            : null;
          this.matricService.timeLeft =
            this.matricService.dateInit != null && this.matricService.dateEnd != null
              ? this.matricService.dateInit.valueOf() / 1000 - this.today.valueOf() / 1000 >
                86400
                ? this.matricService.dateInit.valueOf() / 1000 -
                  this.today.valueOf() / 1000 -
                  86400
                : this.matricService.dateInit.valueOf() / 1000 - this.today.valueOf() / 1000
              : 0;
        } else {
          this.matricService.dateEnd = this.fechasInscripcion.pr.fecha_fin_insc
            ? moment(
                this.fechasInscripcion.pr.fecha_fin_insc + ' 23:59',
                'YYYY-MM-DD HH:mm'
              )
            : null;
          this.matricService.dateInit = this.fechasInscripcion.pr.fecha_inicio_insc
            ? moment(
                this.fechasInscripcion.pr.fecha_inicio_insc + ' 00:00',
                'YYYY-MM-DD HH:mm'
              )
            : null;
          this.matricService.timeLeft =
            this.matricService.dateInit != null && this.matricService.dateEnd != null
              ? this.matricService.dateInit.valueOf() / 1000 - this.today.valueOf() / 1000 >
                86400
                ? this.matricService.dateInit.valueOf() / 1000 -
                  this.today.valueOf() / 1000 -
                  86400
                : this.matricService.dateInit.valueOf() / 1000 - this.today.valueOf() / 1000
              : 0;
        }
        this.goToModalidad(modalidad);
      }
    });
  }

  getSpec(){
    this.specViewService.currentSharedSpecMode.subscribe(data => {
      this.isSpecMode = data.isSpecMode;
    })
  }

  getUser(): void {
    this.isLoading = true;
    this.matricService.isLoading.next(true);
    this.alumnoService.getAlumno().subscribe({
      next: (res) => {
        /* CHEQUEAR SESIÓN */
        this.user = res;
        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.email === '' || !res.email || res.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
        /* VALIDACIONES */
        this.matricula = res.matricula;

        let bloqueoEncontrado = res.carreras.find(carrera => carrera?.estado_bloqueo != null && carrera?.estado_bloqueo != "" && carrera?.estado_bloqueo != 'Graduado')
        let carrerasGrade = res.carreras.filter(carrera => carrera.estado_bloqueo === 'Graduado')
        if (bloqueoEncontrado?.length > 0 || carrerasGrade.length === 2) {
          console.log('BLOQUEO');
          this.showMatriculacion = false;
          this.bloqueo = true; 
          this.matricService.isLoading.next(false);
          this.isLoading = false;
          this.isWaiting = false;
          document.getElementById('matriculaciones').classList.add('blur');
        } else {
          this.isLoading = false;
          this.showMatriculacion = true;
        }

        /* FECHAS */
        this.fechasInscripcion = res.fechas_insc;

        /* CREDITOS */
        this.creditosTotales =
          res.creditos_totales !== '' ? res.creditos_totales : 0;
        this.sisId = res.legajo;
        this.asignaturasInscriptas = res.asignatura_inscripta;
        this.cantidadAsignaturas = res.asignatura_cantidad;
        this.contrato = res.contrato;
        this.totalCredits = res.total_credits === null ? 0 : res.total_credits;
        this.userCreditsTypes = res.total_credits_type;
        this.cantDaysDiffer = res.dia_pago_diferido;
        this.limitCuotas =
          res.cantidad_cuotas != undefined && res.cantidad_cuotas != null
            ? res.cantidad_cuotas
            : 4;

        this.formatCredits();

        this.verificacionPago = res.transferencia;

        this.carreras = res.carreras.filter(
          (carrera) => carrera.estado_bloqueo === ''
        );
        this.modalidadBase = this.matricService.modalidadManager(this.fechasInscripcion, this.carreras)
        this.getDates(this.fechasInscripcion, this.modalidadBase, this.today, this.modalidadSelected)
        // this.matricService.timeLeft = Math.abs(this.matricService.timeLeft)
        this.isLoading = false;

        /* COSTOS ASIGNATURAS */

        if (this.carreras.length > 1) {
          this.costoAsignaturaRegular = this.carreras[0].costo_asignatura_full;
          this.costoAsignaturaDual = this.carreras[1].costo_asignatura_full;
          this.costoPracticaSocial = this.carreras[0].costo_pract_social
            ? this.carreras[0].costo_pract_social
            : 0;
          this.costoAsignatura = this.carreras[0].costo_asignatura_full;
        } else if (this.carreras.length === 1) {
          this.costoAsignaturaRegular = this.carreras[0].costo_asignatura_full;
          this.costoAsignatura = this.carreras[0].costo_asignatura_full;
          this.costoPracticaSocial = this.carreras[0].costo_pract_social;
        }
        this.isLoading = false;
        this.matricService.isLoading.next(false)

        if (this.matricula && !res.contrato) {
          document.getElementById('matriculaciones').classList.add('blur');
          this.openContractDialog();
          return;
        } else if (this.showMatriculacion && this.matricula && res.contrato) {
          if (
            this.matricService.timeLeft > 0 ||
            this.matricService.dateInit > this.today ||
            this.matricService.dateEnd < this.today ||
            this.matricService.dateEnd === null ||
            this.matricService.dateInit === null
          ) {
            document.getElementById('matriculaciones').classList.add('blur');
          } else if (
            this.matricService.timeLeft <= 0 &&
            this.matricService.dateEnd !== null &&
            this.matricService.dateInit !== null
          ) {
            if (this.modalidadBase === 'mixta') {
              document
                .getElementById('matriculaciones')
                .classList.remove('blur');
              this.goToModalidad(this.modalidadSelected);
            } else {
              document
                .getElementById('matriculaciones')
                .classList.remove('blur');
              this.goToModalidad(this.modalidadBase);
            }
          }
        } else {
          document.getElementById('matriculaciones').classList.add('blur');
          return;
        }

        localStorage.setItem(
          'userData',
          JSON.stringify({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
            token: null,
          })
        );

        this.store.dispatch(
          new reduxUser.GetUser({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
          })
        );
        this.user = res;
        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.email === '' || !res.email || res.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
    
        // Validaciones y procesamiento de datos del alumno
        this.matricula = res.matricula;
    
        // Verificación de bloqueo de matriculación
        if (res.estado_bloqueo != null && res.estado_bloqueo != "") {
          this.showMatriculacion = false;
          this.bloqueo = true;
          this.matricService.isLoading.next(false);
          this.isLoading = false;
          this.isWaiting = false;
          document.getElementById('matriculaciones').classList.add('blur');
          return;
        } else {
          this.isLoading = false;
          this.showMatriculacion = true;
        }
    
        // Procesamiento de fechas e información relacionada
        this.fechasInscripcion = res.fechas_insc;
    
        // Procesamiento de créditos
        this.creditosTotales = res.creditos_totales !== '' ? res.creditos_totales : 0;
        this.sisId = res.legajo;
        this.asignaturasInscriptas = res.asignatura_inscripta;
        this.cantidadAsignaturas = res.asignatura_cantidad;
        this.contrato = res.contrato;
        this.totalCredits = res.total_credits === null ? 0 : res.total_credits;
        this.userCreditsTypes = res.total_credits_type;
        this.cantDaysDiffer = res.dia_pago_diferido;
        this.limitCuotas =
          res.cantidad_cuotas != undefined && res.cantidad_cuotas != null
            ? res.cantidad_cuotas
            : 4;
        this.cleanProducts = []
        this.formatProducts()
      },
      error: (err: Error) => {
        this.alertService.openAlert(
          '¡Ha ocurrido un error!',
          err.message,
          'Ok'
        );
        setTimeout(() => {
          this.authService.logout();
        }, 5000);
      },
    });

  }

  getAsignaturas() {
    this.loadingAsignaturas = true;
    this.asignaturaService.getAsignaturas().subscribe({
      next: (res) => {
        this.loadingAsignaturas = false;
        if (res.error) {
          this.errorAsignaturas = true;
          return;
        }
        this.minAsigments = {
          limite_ol: res[0].limite_ol,
          limite_pr: res[0].limite_pr
        }
        this.currentEspecialidadId = res[0].carrera.especialidad_id;
        this.allCarreras = res.filter((menuItem) => {
          return menuItem.hasOwnProperty('carrera');
        });
        this.filterAsignaturas();
        this.periodosWithInscriptions.push(...this.filteredCarreras[0].asignaturas.filter(asignatura => asignatura.estado_alumno !== 'No Inscripto').map(asignatura => asignatura.periodo_id))
      },
      error: (err) => {
        console.log(err);
        this.loadingAsignaturas = false;
        this.errorAsignaturas = true;
      },
    });
  }

  getDates(fechasInscripcion, modalidadBase, today, modalidadSelected) {
    switch (modalidadBase) {
      case 'mixta':
        if(!modalidadSelected){
          this.openSelectModalidad();
          return
        }
        return this.matricService.getMixtaDates(fechasInscripcion, modalidadSelected, today);
      case 'presencial':
        return this.matricService.getPresencialDates(fechasInscripcion, today);
      case 'online':
        return this.matricService.getOnlineDates(fechasInscripcion, today);
      default:
        return this.matricService.getDefaultDates();
    }
  }

  filterAsignaturas(): void {
    /* FILTRADO POR MODALIDAD */
    setTimeout(() => this.btnSwitchMod = false, 400)
    if (this.modalidadSelected === 'online') {
      this.filteredCarreras = this.allCarreras.filter((menuItem) => {
        return menuItem.carrera.modalidad === 'o';
      });
      this.ifharu = this.user.carreras.find(
        (carrera) => carrera.modalidad === 'Online'
      ).IFHARU;
    } else {
      this.filteredCarreras = this.allCarreras.filter((menuItem) => {
        return menuItem.carrera.modalidad === 'p';
      });

      const carreraPrincipal = this.user.carreras.find(
        (carrera) => carrera.modalidad === 'Presencial'
      );
      this.ifharu = carreraPrincipal.IFHARU;
      this.dont_pay_fixed_cost = carreraPrincipal.no_paga_costos_fijos;
    }
    this.materiasPermitidas = this.cantidadAsignaturas;
    

    this.checkEspecialidades();

    /* CHECK DE LÍMITES POR MODALIDAD */

    if (
      this.filteredCarreras.length > 1 &&
      this.filteredCarreras[0].carrera.modalidad ===
        this.filteredCarreras[1].carrera.modalidad
    ) {
      this.hasDobleCarrera = true;
      this.carrera2Id = this.filteredCarreras[1].carrera.career_id;
      this.checkInicialDobleCarrera();
      this.dualDisponible = this.filteredCarreras[1].asignaturas.filter(
        (item) => item.estado_alumno === 'No Inscripto'
      ).length;
      
    }

    /* VALIDACIÓN PRODUCTOS HABILITADOS */

    /* SETEO DE PRODUCTOS SEGÚN ASIGNATURAS DISPONIBLES */

    
    /* CHEQUEAR SI HAY CARRERAS HABILITADAS */

    this.showMatriculacion = this.filteredCarreras.some(
      (course) => course.carrera.habilitado
    );
    // Si el array de asignaturas que viene del back es mayor al limite de la carrera se le recorta la longitud
    for(let carrera of this.filteredCarreras){
      if(this.filteredCarreras[0] === carrera){        
        if(carrera.carrera.modalidad === 'o'){
          if(carrera.asignaturas.length > carrera.limite_ol){
            carrera.asignaturas.length = carrera.limite_ol
          }
      }else{
        if(carrera.asignaturas.length > carrera.limite_pr){
          carrera.asignaturas.length = carrera.limite_pr
        }
      }
      }
      if(this.filteredCarreras[1] === carrera){
        for(let asignatura of this.filteredCarreras[1].asignaturas){
          asignatura.isSecond = true;
        }
      if(carrera.carrera.modalidad === 'o'){
          if(carrera.asignaturas.length > carrera.limite_ol_dual){
            carrera.asignaturas.length = carrera.limite_ol_dual
          }
      }else{
        if(carrera.asignaturas.length > carrera.limite_pr_dual){
          carrera.asignaturas.length = carrera.limite_pr_dual
        }
      }
      }
    }
    this.matricService.periodos = [];
    this.matricService.procesarAsignaturas(this.filteredCarreras);

    for(let periodo of this.matricService.firstCarreerPeriods){
      this.allPeriodos.push(periodo.id);
    }
    this.limitMateriasByMod = this.filteredCarreras.length > 1 ? 
    this.filteredCarreras[0].asignaturas.length + this.filteredCarreras[1].asignaturas.length :
    this.filteredCarreras[0].asignaturas.length
    this.formatProducts();
  }

  

  formatProducts() {
    this.cleanProducts = [];
    this.matricService.procesarAsignaturas(this.filteredCarreras)
    //Con este config se pueden declarar casuisticas especiales en los tipos de credito
    const config = {
    regular: {},
    dual: { type: this.filteredCarreras.length > 1 ? true : false },
    socialPractice: { type: this.matricService.socialPractice },
    professionalPractice: { type: this.matricService.professionalPractice},
    matOnlPre: { type: this.matricService.matOnlPre },
    gradeOpMaestria: { type: this.matricService.gradeOpMaestria },
    gradeOpTesis: { type: this.matricService.gradeOpTesis },
    gradeOpBusiness: { type: this.matricService.gradeOpBusiness },
  };   
    this.userCreditsTypes.forEach((prod) => {
      let product = { 
        price: this.getPrice(prod),
        id: prod.tipo_credito_id,
        namesCarreras: this.matricService.namesCarreras,
        limit: this.getLimit(prod),
        show: true,
        total: prod.total,
        tipo_certificado : prod.tipo_certificado,
        credit_type: prod.nombre,
        general_type: prod.general_type
      };
      const strategyFactory = new CreditStrategyFactory();
      const strategy = strategyFactory.createStrategy(product.id, this.modalidadSelected);
      if(strategy?.formatCredit(product, config)){
        if(!this.cleanProducts.some((item) => item.id === product.id) && !this.cleanProducts.some((item) => item.general_type === product.general_type)){
          if(this.matricService.namesCarreras.length)
          this.cleanProducts.push(product)
      }
    }
  });
  console.log(this.cleanProducts)
  }
  private getLimit(product): number {
    return product.tipo_credito_id === 1
      ? this.hasDobleCarrera && this.modalidadSelected === 'online' ? this.limitMateriasByMod - 2 : this.limitMateriasByMod
      : product.tipo_credito_id === 7
      ? this.dualDisponible
      : 1;
  }
  private getPrice(credit): any {
    const { tipo_credito_id, monto } = credit;
    const priceMap = {
      1 : this.costoAsignaturaRegular,
      15 : this.costoAsignaturaRegular,
      19: this.costoAsignaturaDual,
      7: this.costoAsignaturaDual,
      17: this.costoPracticaSocial,
      3: this.costoPracticaSocial,
    };
    return priceMap[tipo_credito_id] !== undefined ? priceMap[tipo_credito_id] : monto;
    
  }

  formatCredits(): void {
    this.userCreditsTypes.forEach((item) => { 
      if (this.credits[item.tipo_credito_id]) {
        const strategy = this.credits[item.tipo_credito_id];
        Object.assign(item, {
          nombre: strategy.nombre,
          description: strategy.description,
          id: item.tipo_credito_id,
        });
      }
    });
  }

  getTotalCredits(id) {
    return this.userCreditsTypes.find((item) => item.tipo_credito_id === id).total;
  }
  
  // esta funcion ya estaba y no entiendo el sentido de la misma pero la dejo porque me parece muy meme
  getTotalAsigByCredit(credit_id) {
    return;
  }

  /* MATRICULACION */

  removeMateriaChip(id, carreraId): void {
    if (this.hasDobleCarrera && carreraId === this.carrera2Id) {
      this.checkRemoveDobleCarrera(id);
    }

    this.selectMaterias.map((materia, i) => {
      if (materia.subject_id === id) {
        this.selectMaterias.splice(i, 1);
        this.selectMateriasName.splice(i, 1);
        this.pensum_elective_ids.splice(
          this.pensum_elective_ids.indexOf(materia.pensum_elective_id),
          1
        );
        this.selectMateria(id, materia.pensum_elective_id);
      }
    });

    if (this.selectMaterias.length <= 0) {
      this.expandResumen = false;
    }
  }

  removeMateria(id, carreraId, periodo_id, isSecond): void {
    if (this.hasDobleCarrera && carreraId === this.carrera2Id) {
      this.checkRemoveDobleCarrera(id);
    }
    const result = this.selectMaterias.map((materia, i) => {
      if (materia.subject_id === id) {
        this.selectMaterias.splice(i, 1);
        this.selectMateriasName.splice(i, 1);
        this.pensum_elective_ids.splice(
          this.pensum_elective_ids.indexOf(materia.pensum_elective_id),
          1
        );
        this.selectMateria(id, materia.pensum_elective_id);
      }
    });

    if (this.selectMaterias.length <= 0) {
      this.expandResumen = false;
    }
    this.checkPeriodos(true, periodo_id, isSecond);
  }

  addMateria(
    result,
    carreraId,
    pensumElectiveId,
  ): void {
    if (this.limitMateriasByMod === this.selectMaterias.length && !this.carreras.find(carrera => carrera.no_limite_minimo === true)) {
      this.alertService.openAlert(
        '¡Has alcanzado tu límite de asignaturas!',
        'En esta instancia de matriculación sólo puedes seleccionar hasta ' +
          this.limitMateriasByMod +
          ' asignaturas. ',
        'Entendido'
      );
      return;
    } else if (this.limitMateriasByMod === 0) {
      this.alertService.openAlert(
        '¡No puedes inscribirte a más materias!',
        'Ya te has inscripto a tu límite de materias asignado',
        'Entendido'
      );
      return;
    }
    this.expandResumen = true;
    if (result.subject_id == null || result.subject_id === undefined) {
      return;
    }

    if (this.hasDobleCarrera && carreraId === this.carrera2Id) {
      const indexCarrera1 = this.filteredCarreras[0].asignaturas.some(
        (asig) => asig.subject_id === result.subject_id && asig.estado_asignatura === 'Abierta'
      );

      const indexCarrera2 = this.filteredCarreras[1].asignaturas.some(
        (asig) => asig.subject_id === result.subject_id
      );
      this.isDobleCareer = true;
      
      this.checkAddDobleCarrera(indexCarrera2, indexCarrera1);
    }
    let creditType = 0;
    if(this.modalidadSelected === 'presencial'){
      if(result.tipo_credito_id === 1){
        creditType = 15;
      }
      if(result.tipo_credito_id === 7){
        creditType = 19;
      }
      if(result.tipo_credito_id === 3){
        creditType = 17;
      }
      if(result.tipo_credito_id === 2){
        creditType = 16;
      }
      if(result.tipo_credito_id === 4){
        creditType = 18;
      }
      if(result.tipo_credito_id === 6){
        creditType = 20;
      }
      if(result.tipo_credito_id === 5){
        creditType = 21;
      }
      if(result.tipo_credito_id === 10){
        creditType = 10;
      }
    }
    if(this.modalidadSelected === 'online'){
      creditType = result.tipo_credito_id;
    }
    const creditsLeft =  this.getTotalCredits(creditType) - this.selectMaterias.filter(item => item.tipo_credito_id === result.tipo_credito_id).length;
    if (this.limitMateriasByMod > this.selectMaterias.length || this.carreras.find(carrera => carrera.no_limite_minimo === true)) {
      const selectedCreditTypeMessage = this.credits[result.tipo_credito_id].nombre;
      if (( creditsLeft <= 0 )) {
        this.expandResumen = false;
        this.isDobleCareer = false;
        this.alertService.openAlert(
          '¡Ups!',
          `No posees créditos de tipo ${selectedCreditTypeMessage} suficientes`,
          'Entendido'
        );
        return;
      }

      if(this.isDobleCareer && !this.selectMaterias.length){
        this.isDobleCareer = false;
        this.checkPeriodos(false, result.periodo_id, result.isSecond);
        if(!this.matricService.checkPeriodosArr(this.allPeriodos, this.selectedPeriodos, this.periodosWithInscriptions) &&  result.isSecond){
          this.alertService.openAlert(
          '¡Ups!',
          'Antes de continuar con tu segunda carrera, primero debes seleccionar las asignaturas de la carrera principal.'
          ,'Entendido',
          null,
          'default',
          '',
          '../../../../../assets/images/content/ups.png'
          )
        }
        return;
      }else{
        if(!this.matricService.checkPeriodosArr(this.allPeriodos, this.selectedPeriodos, this.periodosWithInscriptions) &&  result.isSecond){
          this.alertService.openAlert(
          '¡Ups!',
          'Antes de continuar con tu segunda carrera, primero debes seleccionar las asignaturas de la carrera principal.'
          ,'Entendido',
          null,
          'default',
          '',
          '../../../../../assets/images/content/ups.png'
          )
        }else{
          this.selectMateria(result.subject_id, pensumElectiveId);
          this.selectMaterias.push({
            subject_id: result.subject_id,
            pensum_id: result.pensum_id,
            carrera_id: carreraId,
            pensum_elective_id: pensumElectiveId,
            modalidad: result.modelo,
            especialidad_id: this.currentEspecialidadId,
            periodo_id: result.periodo_id,
            tipo_credito_id: result.tipo_credito_id,
            isSecond: result.isSecond,
            checkAristotles: false
          });
          if (pensumElectiveId != '' && pensumElectiveId != null) {
            this.pensum_elective_ids.push(pensumElectiveId);
          }
          this.selectMateriasName.push({ nombre: result.nombre, subject_id: result.subject_id, carreraId, tipo: result.tipo});
        }
      }

      // Se va a mostrar la alerta de aristoteles cuando el usuario eliga 2 asignaturas de este tipo
      // Esta solo se mostrara una vez por periodo de la asignatura
      
      let isSamePeriod = false;
      let aristotleList = [];
      aristotleList =  this.selectMaterias.filter(element => element.modalidad === 'c');
      const periodoSet = new Set();
      for (let element of aristotleList) {
        const periodoId = element.periodo_id;
        if (periodoSet.has(periodoId)) {
          isSamePeriod = true;
          break; 
        }
        periodoSet.add(periodoId);
      }
      if(aristotleList.length >= 2 && 
        isSamePeriod && 
        result.modelo === 'c' && 
        !this.selectMaterias.filter(materia => materia.checkAristotles === true).length){
          this.showAristotleAlert(result.subject_id, carreraId, result.periodo_id, result.isSecond)
      }
      this.isDobleCareer = false;
      this.checkPeriodos(false, result.periodo_id, result.isSecond);
    } else if (this.creditosTotales <= 0) {
      this.expandResumen = false;
      this.alertService.openAlert(
        '¡Ups!',
        'No posees créditos suficientes',
        'Entendido'
      );
    }
  }

  showAristotleAlert(id, carreraId, periodoId, isSecond){
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      width: '400px',
      data: {
        title: '¿Estás seguro de que quieres cursar dos asignaturas con “dificultad Aristóteles”?',
        heading: '',
        description: '',
        confirmAction: '¡Si, avancemos!',
        cancelAction: 'No, cancelar',
        img: '../../../../../assets/images/content/warning-hand.png'
      },
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result != 'ok') {
        this.removeMateria(id, carreraId, periodoId, isSecond)
      }else{
        for(let materia of this.selectMaterias){
          if(materia.periodo_id === periodoId){
            materia.checkAristotles = true;
          }
        }
      }
    });
  }


  checkPeriodos(isDelete: boolean, periodo_id, isSecond){
    if(!isSecond){ 
      if(!isDelete){
        this.selectedPeriodos.push(periodo_id)
      }else{
        const index = this.selectedPeriodos.indexOf(periodo_id);
        if (index !== -1) {
          this.selectedPeriodos.splice(index, 1);
        }
      }
    }
    if(!this.matricService.checkPeriodosArr(this.allPeriodos, this.selectedPeriodos, this.periodosWithInscriptions) && isSecond){
      this.alertService.openAlert(
      '¡Ups!',
      'Antes de continuar con tu segunda carrera, primero debes seleccionar las asignaturas de la carrera principal.'
      ,'Entendido',
      null,
      'default',
      '',
      '../../../../../assets/images/content/ups.png'
      )
    }
  }

  matriculacion(): void {
    if (this.selectMaterias.length !== 0 && this.sisId !== '') {
      const button = document.getElementById('sendInfo');
      button.innerText = 'Cargando';

      this.selectMaterias.forEach((materia) => {
        if (materia.pensum_elective_id !== '') {
          this.pensum_elective_id = materia.pensum_elective_id;
        }
      });

      // this.selectMaterias.forEach(materia => {
      //   materia.especialidad_id = this.currentEspecialidadId;
      // })
      const asigByCredit = this.selectMaterias.map(materia => {
        let creditType = 0;
        if(this.modalidadSelected === 'presencial'){
          if(materia.tipo_credito_id === 1){
            creditType = 15;
          }
          if(materia.tipo_credito_id === 7){
            creditType = 19;
          }
          if(materia.tipo_credito_id === 3){
            creditType = 17;
          }
          if(materia.tipo_credito_id === 2){
            creditType = 16;
          }
          if(materia.tipo_credito_id === 4){
            creditType = 18;
          }
          if(materia.tipo_credito_id === 6){
            creditType = 20;
          }
          if(materia.tipo_credito_id === 5){
            creditType = 21;
          }
          if(materia.tipo_credito_id === 10){
            creditType = 10;
          }
        }
        if(this.modalidadSelected === 'online'){
          creditType = materia.tipo_credito_id;
        }
        return {...materia, tipo_credito_id: creditType}
      }) 

      const data = {
        sis_id: parseInt(this.sisId, 10),
        asignaturas: asigByCredit,
        pensum_elective_id: this.pensum_elective_id,
      };

      this.alumnoService.matricularAlumno(data).subscribe({
        next: (res) => {
          if (res.error || res.status === 'ERROR') {
            button.innerText = 'Completar inscripción';
            this.alertService.openAlert('Algo salió mal', res.mensaje || res.error, 'Entendido');
            return;
          } else {
            button.innerText = 'Completar inscripción';
            this.alertService.openAlert(
              '¡Felicidades!',
              '<p style="line-height: 140%">Tu matrícula se ha realizado de manera exitosa, recuerda que cualquier cambio que desees realizar lo tendrás que hacer dentro del tiempo estipulado por el <a href="https://drive.google.com/file/d/15xzeCG-6rBTDblFgZrQXU8yA-5BzbEfy/view" target="_blank" rel="noopener noreferrer">reglamento estudiantil</a>.</p> <h2 style="font-size: 1.2rem; margin-bottom: 0;">¡Te deseamos un excelente inicio de clases!</h2>',
              'Entendido'
            );

            this.getUser();
            this.selectMaterias = [];
            this.selectMateriasName = [];
          }
        },
        error: (err) => {
          button.innerText = 'Completar inscripción';
          console.log(err);
        },
      });
    } else if (this.sisId === '') {
      this.alertService.openAlert(
        'Ups',
        'Parece que no tienes un legajo asignado',
        'Cerrar'
      );
    } else {
      this._snackBar.open(
        'No se ha seleccionado ninguna asignatura',
        'Cerrar',
        {
          duration: 5000,
        }
      );
    }
  }

  selectMateria(id, electivePensum = 0): void {
    this.filteredCarreras.map((carrera) => {
      carrera.asignaturas.map((asignatura) => {
        if (asignatura.subject_id === id) {
          asignatura.isSelected = asignatura.isSelected ? false : true;
        }
        if (asignatura.pensum_id === electivePensum) {
          asignatura.isSelected = asignatura.isSelected ? false : true;
        }
        if (
          asignatura.tipo === 'elective' ||
          asignatura.tipo === 'grade_option' ||
          asignatura.tipo === 'elective_english'
        ) {
          asignatura.elegir.map((electiva) => {
            if (electiva.subject_id === id) {
              electiva.isSelected = electiva.isSelected ? false : true;
            }
          });
        }
      });
    });
  }

  /* TOUR */

  initTour() {
    this.tourService2.initialize(matriculacionesTour);
  }

  startTour() {
    this.tourService2.start();
  }

  /* CHEQUEO SEGUNDA CARRERA */

  checkAddDobleCarrera(indexCarrera2, indexCarrera1): void {
    if (indexCarrera2 && !indexCarrera1) {
      this.selectedDobleCarrera++;
    }
  }

  checkRemoveDobleCarrera(asignaturaId): void {
    const indexCarrera2 = this.filteredCarreras[1].asignaturas.some(
      (asig) => asig.subject_id === asignaturaId
    );
    const indexCarrera1 = this.filteredCarreras[0].asignaturas.some(
      (asig) =>
        asig.subject_id === asignaturaId && asig.estado_asignatura === 'Abierta'
    );

    if (indexCarrera2 && !indexCarrera1) {
      this.selectedDobleCarrera--;
    }
  }

  checkInicialDobleCarrera(): void {
    const insciptoPendiente = this.filteredCarreras[1].asignaturas.filter(
      (asig) =>
        asig.estado_alumno === 'Inscripto' ||
        asig.estado_alumno === 'Pre Inscripto'
    );

    if (insciptoPendiente.length > 0) {
      this.filteredCarreras[0].asignaturas.forEach((asig1) => {
        insciptoPendiente.filter(
          (asig2) =>
            asig1.subject_id !== asig2.subject_id &&
            asig1.estado_asignatura === 'Cerrada'
        );
      });
    }

    if (insciptoPendiente.length > 1) {
      this.selectedDobleCarrera = 2;
    }
  }

  openAlert = (title: string, message: string) => {
    this.alertService.openAlert(title, message, 'Cerrar');
  };

  /* CONFIRMAR MATRICULACIÓN */

  validateMatricula(): void {
    console.log(this.matricService.checkPeriodosArr(this.allPeriodos, this.selectedPeriodos, this.periodosWithInscriptions), this.carreras.find(carrera => carrera.no_limite_minimo === true))
    if (!this.matricService.checkPeriodosArr(this.allPeriodos, this.selectedPeriodos, this.periodosWithInscriptions)  && !this.carreras.find(carrera => carrera.no_limite_minimo === true)) {
        this.alertService.openAlert(            
            '¡Atención!',
            'Debes seleccionar al menos 4 (cuatro) asignaturas de la primera carrera de cada periodo para completar la matrícula',
            'Cerrar'
        );
        return;    
      }

    const contPeriodos = [];
    for (let materia of this.selectMaterias) {
        for (let periodo of this.matricService.periodos) {
            if (
                materia.periodo_id === periodo.id &&
                !contPeriodos.includes(periodo.id)
            ) {
                contPeriodos.push(periodo.id);
            }
        }
    }

    for (let menuItem of this.filteredCarreras) {
        for (let materia of menuItem.asignaturas) {
            for (let periodo of this.matricService.periodos) {
                if (
                    materia?.periodo_id === periodo.id &&
                    (materia?.estado_alumno === 'Inscripto' ||
                        materia?.estado_alumno === 'Pre Inscripto') &&
                    !contPeriodos.includes(periodo.id)
                ) {
                    contPeriodos.push(periodo.id);
                }
            }
        }
    }

    this.periodoValido = 
    this.matricService.validatePeriodo(this.modalidadSelected, contPeriodos, this.user, this.selectMaterias)

    if (!this.periodoValido) {
        const errorMessage = this.modalidadSelected === 'online'
            ? 'Debes seleccionar al menos 1 (una) asignatura de cada periodo habilitado para continuar.'
            : 'Debes seleccionar al menos 4 (cuatro) asignaturas para completar la matrícula';

        this.openAlert('¡Atención!', errorMessage);
        return;
    }

    if (this.allCarreras.some((carrera) => carrera.carrera.graduando === true && this.selectMaterias.length < this.filteredCarreras[0].asignaturas.length) && !this.carreras.find(carrera => carrera.no_limite_minimo === true)) {
        this.openAlert('¡Atención!', `Debes seleccionar al menos ${this.filteredCarreras[0].asignaturas.length} asignatura para continuar.`);
        return;
    }

    if (this.limitMateriasByMod <= this.selectMaterias.length) {
        this.openConfirmMatricula();  
        return;
    }

    if (this.filteredCarreras.length > 1 && this.selectMaterias.some(materia => materia.tipo_credito_id === 7 || materia.tipo_credito_id === 1)) {
        this.openConfirmDialog(
            '¿Estás seguro de continuar?',  
            `¡Aún te quedan ${this.limitMateriasByMod - this.selectMaterias.length} asignaturas por seleccionar!`,
            'Recuerda que de no seleccionarlas durante este llamado no podrás hacerlo hasta el próximo llamado de matriculación',
            'Sí, continuar',
            'Volver a seleccionar'
        );
        return;
    }

    if (this.filteredCarreras.length === 1  || this.carreras.find(carrera => carrera.no_limite_minimo === true)) {
        this.openConfirmMatricula();
    } else {
        this.openAlert('¡Atención!', 'Debes seleccionar al menos 1 (una) asignatura de la segunda carrera.');
    }
}
  openConfirmMatricula(): void {
    const modal = this.dialog.open(ModalConfirm, {
      width: '600px',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        materias: this.selectMateriasName,
        materiasService: this.selectMaterias,
        sis_id: this.sisId,
      },
      panelClass: 'plain-alert',
    });

    modal.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        if(!this.isSpecMode){
          this.matriculacion();
        }else{
          this.openSpecStopDialog();
        }
      }
    });
  }

  /* CHECK ELECTIVAS */

  checkElectiva(materiaPensum): boolean {
    return this.selectMaterias.some(
      (selectedMateria) => selectedMateria.pensum_elective_id === materiaPensum
    );
  }

  /* ESPECIALIDADES */

  checkEspecialidades() {
    if (this.carreras.some((carrera) => carrera.especialidad === false)) {
      for (let carrera of this.carreras) {
        if (
          carrera.especialidad === false &&
          ((this.allEspecialidades.length > 0 &&
            !this.allEspecialidades.some(
              (item) => item.carrera_estudiante === carrera.carrera_estudiante
            )) ||
            this.allEspecialidades.length === 0)
        ) {
          this.getEspecialidades(carrera.carrera_estudiante);
        }
      }
    }
  }

  getEspecialidades(career_id) {
    this.matricService.getEspecialidades(career_id).subscribe((res) => {
      this.allEspecialidades.push({
        carrera_estudiante: career_id,
        especialidades: res.especialidades,
      });
    });
  }

  getEspecialidadById(career_id) {
    return this.allEspecialidades.find(
      (item) => item.carrera_estudiante === career_id
    ).especialidades;
  }

  /* CONTRATO */

  updateContrato(): void {
    this.documentService.updateContract(this.sisId, this.contrato).subscribe({
      next: (res) => {
        document.getElementById('matriculaciones').classList.remove('blur');
        this.getUser();
      },
      error: (err) => {
        document.getElementById('matriculaciones').classList.add('blur');
        this.getUser();
      },
    });
  }

  openContractDialog(): void {
    const dialogRef = this.dialog.open(ContractDialogComponent, {
      width: '800px',
      data: {
        title: 'title',
        description: 'description',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        document.getElementById('matriculaciones').classList.add('blur');
        this.showMatriculacion = false;
      } else {
        this.contrato = result;
        this.updateContrato();
      }
    });
  }

  /* CREDITOS */

  openCartDialog(cart = []): void {
    if(this.modalidadBase === 'mixta'){
      this.cleanProducts = this.cleanProducts.map(element => {
        if(this.allCarreras[1].carrera.nombre === element.namesCarreras[0]){
          element.price = this.costoAsignaturaDual;
        }
        return element;
      })
    }
    this.minAsigments = {
      limite_ol: (this.minAsigments.limite_ol - this.userCreditsTypes[0].total) < 0 ? 0 : this.minAsigments.limite_ol - this.userCreditsTypes[0].total,
      limite_pr: (this.minAsigments.limite_pr - this.userCreditsTypes[0].total) < 0 ? 0 : this.minAsigments.limite_pr - this.userCreditsTypes[0].total,
    }
    console.log(this.minAsigments.limite_ol, this.minAsigments.limite_pr)
    console.log( this.currentOpportunity, this.findOppByStage('Matriculado'), this.currentEditOpp)
    const dialogRef = this.dialog.open(CarritoComponent, {
      minWidth: '80vw',
      maxWidth: '100w',
      autoFocus: false,
      minHeight: '90vh',
      maxHeight: '100vh',
      data: {
        title: 'title',
        limit: this.limitMateriasByMod,
        cart: cart,
        minAsigments: this.modalidadSelected === 'online' ? this.minAsigments.limite_ol : this.minAsigments.limite_pr,
        products: this.cleanProducts,
        isGraduando: this.filteredCarreras.some(carrera => carrera.carrera.graduando === true),
        idOportunidad: this.currentOpportunity && this.currentEditOpp
          ? this.currentEditOpp.id
          : null,
        cantDaysDiffer: this.cantDaysDiffer,
        limitCuotas: this.limitCuotas,
        fechaInicio: this.matricService.dateInit,
        ifharu: this.ifharu,
        user: this.user,
        modalidad: this.modalidadSelected,
        fixedCosts: this.userCreditsTypes.filter(
          (item) => item.tipo_credito_id === 8 || item.tipo_credito_id === 14
        ),
        dont_pay_fixed_cost: this.dont_pay_fixed_cost,
        educatId: this.user.id_educat,
        becas: this.user.becas,
      },
      panelClass: 'cart-dialog',
      });
    console.log(this.user.becas)
      dialogRef.afterClosed().subscribe((result) => {
        if (result?.formaPago === 'transferencia/cheque') {
          this.alertService.openAlert(
            '¡Muchas gracias por tu compra!',
            'Una vez que verifiquemos tu pago, se acreditarán las asignaturas adquiridas. Recuerda que esto puede demorar entre 1 y 3 días',
            'OK'
          );
        }
      });
  }

  openSpecStopDialog(){
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '400px',
      data: {
        title: 'No puedes realizar cambios',
        description: 'Recuerda que únicamente puedes ver la plataforma, sin la posibilidad de hacer modificaciones.',
        action: 'Entendido',
        img: '../../../../../assets/images/content/stop.png' 
      },
      panelClass: 'plain-alert',
    });
  }

  /* DIALOGOS */

  openConfirmDialog(
    title,
    heading,
    description,
    confirmAction,
    cancelAction
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: title,
        heading: heading,
        description: description,
        confirmAction: confirmAction,
        cancelAction: cancelAction,
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.openConfirmMatricula();
      }
    });
  }

  openResumen(): void {
    this.expandResumen = !this.expandResumen;
  }

  openTutorial(link, title): void {
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      width: '700px',
      data: {
        title: title,
        videoLink: link,
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDescription(asignatura, carreraId, pensumElectiveId): void {
    const dialogRef = this.dialog.open(DescriptionDialogComponent, {
      width: '450px',
      maxWidth: '90vw',
      data: {
        title: 'Tutorial automatriculación',
        asignatura,
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addMateria(
          result,
          carreraId,
          pensumElectiveId,
        );
      }
    });
  }

  findOppByStage(stage) {
    return this.currentDateOpp.find((item) => item.stage === stage);
  }

  /* OPORTUNIDADES */

  setCurrentEdit(){
    const modEnrollOpps = this.openOpportunities.filter((item) => item.modalidad.toLowerCase() === this.modalidadSelected.toLowerCase());
    const currentOpps = modEnrollOpps.filter(opp => moment(opp.pagos[0].fecha_creacion).isBetween(this.matricService.dateInit, this.matricService.dateEnd))
    this.currentEditOpp = currentOpps.find(opp => opp.stage === 'Inscripto');
  }

  getOpportunities(): void {
    this.isWaiting = true;
    this.financialCreditsService.getOportunidades().subscribe({
      next: (res) => {
        try {
          const payOpp = [];
          this.allOportunidades = [...res];
          this.allOportunidades.forEach((item) => {
            if (item.pagos.length > 0) {
              item.pagos[0].stage = item.stage;
              item.pagos[0].id = item.id;
              item.pagos[0].productos = item.productos;
              payOpp.push(...item.pagos);
            }
          });
          const currentDateOpp = payOpp.filter(opp => moment(opp.fecha_creacion).isBetween(this.matricService.dateInit, this.matricService.dateEnd));
          this.openOpportunities = res.filter((item) => item.pagos.length > 0);
          this.setCurrentOportunity(this.modalidadSelected);
          this.currentDateOpp = currentDateOpp;
          this.setCurrentEdit();
          let lastOpp = null;
          if (currentDateOpp.length > 1) {
            const oppDates = currentDateOpp.map(function(date){
              return moment(date.fecha_creacion);
            });
            const maxDate = moment.max(oppDates);
            lastOpp = currentDateOpp.find(opp => moment(opp.fecha_creacion).isSame(maxDate));
          }
          this.isWaiting = false;
          if (this.showMatriculacion && !this.isLoading) {
            document.getElementById('matriculaciones').classList.remove('blur');
          }
          if (currentDateOpp[0]?.stage === 'Matriculado' && currentDateOpp.length > 0) {
            this.dialog.open(PaymentResumeComponent, {
              data: {oportunidad: currentDateOpp.length > 1 ? lastOpp : currentDateOpp[0]},
              panelClass: 'cart-dialog'
            });
          }
        } catch (error) {
          this.isWaiting = false;
          this.isLoading = false;
          console.error(error);  // Log del error para mayor información
        }
      },
      error: (err) => {
        console.log(err);
        this.currentOpportunity = null;
        this.isWaiting = false;
        if (this.showMatriculacion && !this.isLoading) {
          // document.getElementById('matriculaciones').classList.remove('blur');
        }
      },
    });
  }
  
  setCurrentOportunity(mod) {
    this.currentOpportunity = this.openOpportunities.find(
      (item) => item.modalidad.toLowerCase() === mod.toLowerCase()
    );
  }

  openEditOpportunityDialog(): void {
    const dialogRef = this.dialog.open(EditPaymentComponent, {
      minWidth: '80vw',
      maxWidth: '100vw',
      autoFocus: false,
      minHeight: '90vh',
      maxHeight: '100vh',
      data: {
        oportunidad: this.currentEditOpp,
        costoAsignatura: this.costoAsignatura,
        becas: this.user.becas, 
        products: this.cleanProducts,
        ifharu: this.ifharu,
        user: this.user,
        modalidad: this.modalidadSelected,
      },
      panelClass: 'cart-dialog',
    });

    dialogRef.afterClosed().subscribe((cart) => {
      if (cart) {
        let auxCart = [];
        this.cleanProducts.forEach((product) => {
          auxCart = cart.map((item) => {
            if (item.id === product.id) {
              item.subtotal = product.price * item.quantity;
            }
            return item;
          });
        });
        this.openCartDialog(auxCart);
      }
    });
    // this.alertService.openAlert('¡Algo salio mal!', 'Por favor, contacta a tu Academic Advisor para editar la compra.', 'Entendido');    
  }
  /* MODALIDAD */

  goToModalidad(mod = 'opuesta') {
    this.activeRoute.queryParams.subscribe((params) => {
      this.modalidadSelected =
        params.mod != null && params.mod != undefined ? params.mod : null;
        console.log(this.modalidadSelected)
    });
  
    this.openDescription = this.openDescription.bind(this);
    this.openContractDialog = this.openContractDialog.bind(this);
    this.removeMateria = this.removeMateria.bind(this);
    this.addMateria = this.addMateria.bind(this);
    this.getAsignaturas = this.getAsignaturas.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getEspecialidadById = this.getEspecialidadById.bind(this);
  
    this.btnSwitchMod = true;
  
    document.getElementById('matriculaciones').classList.remove('blur');
  
    if (mod === 'opuesta') {
      this.router.navigate(['/matriculaciones'], {
        queryParams: {
          mod: this.modalidadSelected === 'online' ? 'presencial' : 'online',
        },
      });
    } else {
      this.router.navigate(['/matriculaciones/'], {
        queryParams: {
          mod: mod,
        },
      });
    }
  
    this.selectMaterias = [];
    this.selectMateriasName = [];
  
    if (this.showMatriculacion && this.matricula && this.contrato) {
      this.getAsignaturas();
      this.getOpportunities();
      if (!this.matricService.dateInit || !this.matricService.dateEnd) {
        this.isLoading = false;
        document.getElementById('matriculaciones').classList.add('blur');
      }
    }
  
    this.isLoading = true;
    this.matricService.isLoading.next(true);
  
    // Obtención de datos del alumno
    this.alumnoService.getAlumno().subscribe({
      next: (res) => {
        // Chequeo de sesión
        this.user = res;
        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.email === '' || !res.email || res.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
  
        this.matricula = res.matricula;
        this.currentEspecialidadId = res.especialidad_id;
  
        if (res.estado_bloqueo != null && res.estado_bloqueo != "") {
          this.showMatriculacion = false;
          this.bloqueo = true;
          this.matricService.isLoading.next(false);
          this.isLoading = false;
          this.isWaiting = false;
          document.getElementById('matriculaciones').classList.add('blur');
          return;
        } else {
          this.isLoading = false;
          this.showMatriculacion = true;
        }
  
        this.fechasInscripcion = res.fechas_insc;
  
        this.creditosTotales = res.creditos_totales !== '' ? res.creditos_totales : 0;
        this.sisId = res.legajo;
        this.asignaturasInscriptas = res.asignatura_inscripta;
        this.cantidadAsignaturas = res.asignatura_cantidad;
        this.contrato = res.contrato;
        this.totalCredits = res.total_credits === null ? 0 : res.total_credits;
        this.userCreditsTypes = res.total_credits_type;
        this.cantDaysDiffer = res.dia_pago_diferido;
        this.limitCuotas =
          res.cantidad_cuotas != undefined && res.cantidad_cuotas != null
            ? res.cantidad_cuotas
            : 4;
        this.cleanProducts = []
        this.formatProducts()
      },
      error: (error) => {
        console.error('Error al obtener datos del alumno:', error);
        this.isLoading = false;
        this.matricService.isLoading.next(false);
      },
    });
    // this.user$.subscribe({
    //   next: (res) => {
    //     // Chequeo de sesión
    //   },
    //   // Manejo de errores en la suscripción al servicio
    //   error: (error) => {
    //     this.alertService.openAlert(
    //       '¡Ha ocurrido un error!',
    //       error.message,
    //       'Ok'
    //     );
    //     this.isLoading = false;
    //     this.matricService.isLoading.next(false);
    //   },
    // });
    this.formatProducts();
  }

  /* BÚSQUEDA */
  search(evt) {
    evt.preventDefault();
  }

  cleanSearch(): void {
    this.searchValue = '';
  }
}
