import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-testimonio',
  templateUrl: './card-testimonio.component.html',
  styleUrls: ['./card-testimonio.component.scss'],
})
export class CardTestimonioComponent {
  @Input() video: string;
  @Input() project: string;
  @Input() student: string;

  constructor() {}
}
