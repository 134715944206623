<div class="header">
    <div class="title">{{data.title}}</div>
    <button class="close" mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="body">
    <iframe
    src="https://drive.google.com/file/d/1VVKiJvjTWXCxExt2LBSTV5QBP6UIFF2T/preview"
    frameborder="0"
  ></iframe>
</div>