import { from } from 'rxjs';
import { Action } from '@ngrx/store';
import { DataService } from 'src/app/shared/services/data.service';
import { types } from '../types/types';

export const GET_USER = types.GET_USER;
export const POST_PHOTO_USER = types.POST_PHOTO_USER;

export class GetUser implements Action {
  readonly type = GET_USER;
  constructor(public payload: any) {}
}

export class PostPhotoUser implements Action {
  readonly type = POST_PHOTO_USER;

  constructor(public payload: any) {}
}

export type UserActions = GetUser | PostPhotoUser;
