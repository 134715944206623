import { UserState } from 'src/app/redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CmsService } from 'src/app/shared/services/cms.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cursos-abiertos',
  templateUrl: './cursos-abiertos.component.html',
  styleUrls: ['./cursos-abiertos.component.scss'],
})
export class CursosAbiertosComponent implements OnInit {
  public cursos = [];
  public loading = true;
  public error = false;
  public searchValue = '';
  public user$: Observable<any>;
  public user;

  constructor(private service: CmsService, private store: Store<UserState>,) {
    this.user$ = store.select('user');
  }

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;

        this.getCursos();
      }
    });
  }

  getCursos() {
    this.loading = true;
    const filterMod =
      this.user.carreras.some((item) => item.modalidad === 'Presencial') &&
      this.user.carreras.some((item) => item.modalidad === 'Online')
        ? ''
        : this.user.carreras[0].modalidad === 'Presencial'
        ? 'Online'
        : this.user.carreras[0].modalidad === 'Online'
        ? 'Presencial'
        : '';
    this.service.getOpenCoursesByModality(filterMod).subscribe({
      next: (res) => {
        this.cursos = res.data;
      },
      error: (err) => {
        this.error = true;
      },
      complete: () => (this.loading = false),
    });
  }

  cleanSearch(): void {
    this.searchValue = '';
  }
}
