// videos.reducer.ts
import { LibraryActionTypes, LibraryAction } from '../actions/videos.action';
import { IVideo } from 'src/app/shared/interfaces/IVideo';

interface LibraryState {
  videosList: IVideo[];
}

const initialState: LibraryState = {
  videosList: []
};

export function libraryReducer(state = initialState, action: LibraryAction): LibraryState {
  switch (action.type) {
    case LibraryActionTypes.LOAD_VIDEOS:
      return {
        ...state,
        videosList: action.payload
      };
    case LibraryActionTypes.TOGGLE_FAVORITE:
      const updatedVideosList = state.videosList.map(video => {
        if (video.repo_id === action.payload.videoId) {
          return {
            ...video,
            favorito: action.payload.isFavorite
          };
        }
        return video;
      });
      return {
        ...state,
        videosList: updatedVideosList
      };
    default:
      return state;
  }
}
