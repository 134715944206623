import { Component } from '@angular/core';
import { Criterio } from 'src/app/shared/interfaces/ICriteria';
import { Requisito } from 'src/app/shared/interfaces/IRequisito';

@Component({
  selector: 'app-sobre-concurso',
  templateUrl: './sobre-concurso.component.html',
  styleUrls: ['./sobre-concurso.component.scss'],
})
export class SobreConcursoComponent {
  public requisitos: Requisito[] = [
    {
      img: '../../../../../assets/images/content/Equipo.png',
      title: 'Forma tu equipo y define tu idea',
    },
    {
      img: '../../../../../assets/images/content/Propuesta.png',
      title: 'Realiza la propuesta técnica de tu idea',
    },
    {
      img: '../../../../../assets/images/content/Video.png',
      title: 'Graba tu video de 1 minuto',
    },
    {
      img: '../../../../../assets/images/content/Presentar.png',
      title: 'Sustenta tu idea en el concurso',
    },
    {
      img: '../../../../../assets/images/content/Live.png',
      title: 'Únete a la transmisión y conoce si tu idea es la ganadora',
    },
  ];

  public criterios: Criterio[] = [
    {
      index: 1,
      title: 'Viabilidad',
      description:
        'Posibilidad de ser implementado el proyecto con éxito, ateniendo a criterios de oportunidad de mercado, pertinencia de la solución al problema existente y capacidades del equipo de implementar la solución.',
    },
    {
      index: 2,
      title: 'Escalabilidad',
      description:
        'Potencialidad de multiplicar los ingresos sin necesidad de aumentar sus costes de la misma manera',
    },
    {
      index: 3,
      title: 'Innovación',
      description:
        'Creatividad de la solución propuesta y de la manera de implementarla',
    },
    {
      index: 4,
      title: 'Calidad de la propuesta técnica',
      description:
        'Solidez de los argumentos descritos en la propuesta técnica (documento y presentación al comité evaluador) en términos de fondo (contenido) y forma.',
    },
    {
      index: 5,
      title: 'Inclusión',
      description:
        'Vinculación del proyecto al compromiso de los criterios ASG (Ambientales, Sociales y Gubernamentales)',
    },
    {
      index: 6,
      title: 'Transformación social',
      description:
        'Potencialidad de impacto social del proyecto en línea con los ODS',
    },
  ];

  constructor() {}
}
