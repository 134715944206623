import { Component } from '@angular/core';

@Component({
  selector: 'app-cardcanvas',
  templateUrl: './cardcanvas.component.html',
  styleUrls: ['./cardcanvas.component.scss'],
})
export class CardcanvasComponent {
  constructor() {}
}
