<div [id]="this.data.dialogId">
  <div *ngIf="this.data.type === 'success'; else error">
    <div class="dialog-header">
      <button
        class="close"
        mat-icon-button
        aria-label="Close"
        (click)="onClose()"
      >
        <mat-icon class="material-icons-round">close</mat-icon>
      </button>
    </div>
    <div class="dialog-container">
      <div mat-dialog-content>
        <img [src]="this.data.img" />
        <div class="content">
          <div class="title primary">{{ this.data.title }}</div>
          <div class="description">{{ this.data.description }}</div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button mat-flat-button color="primary" (click)="onClose()">
          Entendido
        </button>
      </div>
    </div>
  </div>

  <ng-template #error>
    <div class="dialog-header">
      <button
        class="close"
        mat-icon-button
        aria-label="Close"
        (click)="onClose()"
      >
        <mat-icon class="material-icons-round">close</mat-icon>
      </button>
    </div>
    <div class="dialog-container">
      <div mat-dialog-content>
        <img [src]="this.data.img" />
        <div class="content">
          <div class="title primary">{{ this.data.title }}</div>
          <div class="description">{{ this.data.description }}</div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button mat-flat-button color="primary" (click)="onClose()">
          Entendido
        </button>
      </div>
    </div>
  </ng-template>
</div>
