<!--Componente para nieve en caso de navidad-->
<!-- <app-snow></app-snow> -->
<img src="../../../assets/images/bg/home-background-img.png" alt="" class="img-bg">
<div class="container center-flex">
  <h1 *ngIf="(user$ | async).nombre" class="welcome-text">
    Hola,
    <strong class="name-format">{{ (user$ | async)?.nombre }}</strong
    >
    <p class="welcome-subtext">¡Que alegría verte de nuevo por aquí! Échale un vistazo a tus pendientes y ten un excelente día.</p>
  </h1>
  <!-- <div class="flex-grid-container">
  </div> -->
  <!-- <app-slide style="width: 100%" *ngIf="this.slides.length > 0" [data]="this.slides[0]"></app-slide> -->

  <swiper
    style="width: 100%"
    class="main-shadow main-radius"
    [config]="swiperConfig"
    (swiper)="onSwiper($event)"
    (slideChange)="onSlideChange()"
    *ngIf="this.slides.length > 0"
  >
    <ng-template swiperSlide *ngFor="let slide of slides">
      <app-slide
        style="width: 100%"
        *ngIf="this.slides.length > 0"
        [data]="slide"
      ></app-slide>
    </ng-template>
  </swiper>

  <div class="flex-grid-container">
    <div id="main-body">
      <div
        class="even-grid"
        *ngIf="
          (user$ | async)?.fechas_insc &&
          ((today >=
            getDate((user$ | async)?.fechas_insc.ol.fecha_inicio_insc) &&
            today <= getDate((user$ | async)?.fechas_insc.ol.fecha_fin_insc) &&
            getModalidadOnline(user$ | async) &&
            (user$ | async)?.matricula) ||
            (today >=
              getDate((user$ | async)?.fechas_insc.pr.fecha_inicio_insc) &&
              today <=
                getDate((user$ | async)?.fechas_insc.pr.fecha_fin_insc) &&
              getModalidadPresencial(user$ | async) &&
              (user$ | async)?.matricula))
        "
      >
        <app-eventos-banner
          *ngIf="
            today >=
              getDate((user$ | async)?.fechas_insc.ol.fecha_inicio_insc) &&
            today <= getDate((user$ | async)?.fechas_insc.ol.fecha_fin_insc) &&
            getModalidadOnline(user$ | async) &&
            (user$ | async)?.matricula
          "
          [chipTitle]="'REMATRÍCULA DE PROGRAMAS ONLINE ABIERTA'"
          [title]="''"
          [description]="
            '<b>Ya puedes realizar la rematrícula a programas online  | </b> Inicio: ' + '<b>' +
            moment((user$ | async)?.fechas_insc.ol.fecha_inicio_insc)
              .locale('es')
              .format('L') +
            '</b> - Finalización: <b>' +
            moment((user$ | async)?.fechas_insc.ol.fecha_fin_insc)
              .locale('es')
              .format('L') + '</b>'
          "
          [icon]="'arrow_forward'"
          [link]="['/tramites/matriculaciones']"
          [queryParams]="{ mod: 'online' }"
          [type]="'rematricula_ol'"
        ></app-eventos-banner>
        <app-eventos-banner
          *ngIf="
            today >=
              getDate((user$ | async)?.fechas_insc.pr.fecha_inicio_insc) &&
            today <= getDate((user$ | async)?.fechas_insc.pr.fecha_fin_insc) &&
            getModalidadPresencial(user$ | async) &&
            (user$ | async)?.matricula
          "
          [chipTitle]="'REMATRÍCULA DE PROGRAMAS PRESENCIAL ABIERTA'"
          [description]="
            '<b>Ya puedes realizar la rematrícula a programas online  | </b> Inicio: ' + '<b>' +
            moment((user$ | async)?.fechas_insc.pr.fecha_inicio_insc)
              .locale('es')
              .format('L') +
            '</b> - Finalización: <b>' +
            moment((user$ | async)?.fechas_insc.pr.fecha_fin_insc)
              .locale('es')
              .format('L') + '</b>'
          "
          [icon]="'arrow_forward'"
          [link]="['/tramites/matriculaciones']"
          [queryParams]="{ mod: 'presencial' }"
          [type]="'rematricula_pr'"
        ></app-eventos-banner>
      </div>
      <div class="even-grid">
        <!-- BOLSA DE EMPLEO -->
        <!-- <app-banner></app-banner> -->
        <!-- <app-eventos-banner
          class="banner evento"
          [chipTitle]="'¡Reserva la fecha!'"
          [title]="'Eventos ADENU'"
          [description]="'Chequea la nueva agenda de eventos'"
          [icon]="'event'"
          [link]="['/eventos']"
        ></app-eventos-banner> -->
      </div>

      <div class="flex-grid">
        <div class="first-column">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://aden.instructure.com/login/openid_connect"
          >
            <app-cardcanvas></app-cardcanvas>
          </a>
          <ng-container *ngFor="let item of firstCardsColumn">
            <app-card
              [item]="item"
            ></app-card>
          </ng-container>
        </div>
        <div class="second-column">
          <app-your-progress *ngIf="(user$ | async)?.carreras" [carreras]="(user$ | async)?.carreras"></app-your-progress>
          <div class="second-card-container">
            <ng-container *ngFor="let item of secondCardsColumn">
              <app-card
                [item]="item"
              ></app-card>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="student-section">
      <div class="student-title">Manuales y reglamento académico</div>
      <div class="student-section-cards">
        <app-student-home-card 
        *ngFor="let item of studentSectionCards" 
        [item]="item">
        </app-student-home-card>
      </div>
    </div>
    <!-- <div id="sidebar"> -->
      <!-- <app-student-status [carreras]="(user$ | async)?.carreras"></app-student-status> -->
      <!-- <div id="anuncios" class="main-shadow main-radius">
        <div class="header">
          <h2>ADENU Blog</h2>
          <button
            mat-button
            color="primary"
            (click)="goToLink('https://adenuniversity.edu.pa/adenu-blog/')"
          >
            Ir al blog
          </button>
        </div>
        <div class="notfound-small-container" *ngIf="loadingAnuncios">
          <mat-spinner class="spinner"></mat-spinner>
        </div>
        <div
          class="notfound-small-container"
          *ngIf="!loadingAnuncios && anuncios.length === 0"
        >
          <img src="../../../assets/images/content/Article.svg" alt="" />
          <h2>¡No encontramos artículos!</h2>
          <div class="button-container">
            <button mat-flat-button color="primary" (click)="getNewsItems()">
              Actualizar
            </button>
          </div>
        </div>
        <div class="cards-grid" *ngIf="!loadingAnuncios && anuncios.length > 0">
          <ng-container *ngFor="let item of anuncios">
            <app-card-anuncio [post]="item"></app-card-anuncio>
          </ng-container>
          <button
            mat-button
            color="primary"
            (click)="goToLink('https://adenuniversity.edu.pa/adenu-blog/')"
          >
            Ver más
          </button>
        </div>
      </div> -->
      <!-- <div class="image-banner">
        <a [routerLink]="['/adenu-internacional']">
          <img
            src="../../../assets/images/slider/promo-internacional.png"
            alt="Promo internacional"
          />
        </a>
      </div> -->
    <!-- </div> -->
  </div>
</div>
