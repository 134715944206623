import { MyErrorStateMatcher } from '../../tramites/components/retiro-carrera/retiro-carrera.component';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContestService } from 'src/app/shared/services/contest.service';

export interface DialogData {
  title: string;
}

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent {
  nameCtrl = new UntypedFormControl('', Validators.required);
  emailCtrl = new UntypedFormControl('', Validators.required);
  messageCtrl = new UntypedFormControl('', Validators.required);
  subjectCtrl = new UntypedFormControl('', Validators.required);

  matcher = new MyErrorStateMatcher();

  sending = false;

  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _snackBar: MatSnackBar,
    private contestService: ContestService
  ) {}

  sendMessage(): void {
    this.sending = true;
    const data = {
      student: this.nameCtrl.value,
      subject: this.subjectCtrl.value,
      message: this.messageCtrl.value,
      student_email: this.emailCtrl.value,
      email_api: 'ori@adenuniversity.edu.pa',
      title: this.data.title,
    };

    /* ori@adenuniversity.edu.pa */

    const button = document.getElementById('send-button');
    button.innerHTML = 'Enviando...';
    this.contestService.send_email(data).subscribe({
      next: (res) => {
        button.innerHTML = 'Enviar';
        this.sending = false;
        this._snackBar.open('¡Mensaje enviado con éxito!', 'OK', {
          duration: 2000,
          panelClass: ['indigo-snackbar'],
        });
      },
      error: (err) => {
        this.sending = false;
        this._snackBar.open(
          'Ocurrió un error, por favor intenta de nuevo más tarde',
          'OK',
          {
            duration: 2000,
            panelClass: ['red-snackbar'],
          }
        );
      },
    });
  }
}
