<div id="content-slide">
  <div
    id="grid-container"
    *ngIf="
      data.slide_type === 'Imagen y contenido' &&
      data.slide_order === 'Contenido a la derecha'
    "
    (click)="goTo()"
  >
    <div
      class="image"
      [style.background-image]="'url(' + data.imageUrl + ')'"
    ></div>
    <div
      class="body"
      [style.background-image]="
        data.content_bg !== null
          ? 'url(' + data.content_bg + ')'
          : 'url(../../../../../assets/images/bg/slider-bg-red.png)'
      "
    >
      <h1>
        {{ data.slide_title }}
      </h1>
      <p>
        {{ data.slide_description }}
      </p>
      <button mat-flat-button (click)="goTo()">
        {{ data.CTA_title ? data.CTA_title : "Ver más" }}
      </button>
    </div>
  </div>

  <div
    id="grid-container"
    class="inverted"
    *ngIf="
      data.slide_type === 'Imagen y contenido' &&
      data.slide_order === 'Contenido a la izquierda'
    "
    (click)="goTo()"
  >
    <div
      class="body"
      [style.background-image]="
        data.content_bg !== null
          ? 'url(' + data.content_bg + ')'
          : 'url(../../../../../assets/images/bg/slider-bg-red.png)'
      "
    >
      <h1>
        {{ data.slide_title }}
      </h1>
      <p>
        {{ data.slide_description }}
      </p>
      <button mat-flat-button (click)="goTo()">
        {{ data.CTA_title !== "" ? data.CTA_title : "Ver más" }}
      </button>
    </div>
    <div
      class="image"
      [style.background-image]="'url(' + data.imageUrl + ')'"
    ></div>
  </div>
  <div id="image-container" *ngIf="data.slide_type === 'Solo imagen'">
    <div
      class="image"
      [style.background-image]="'url(' + data.imageUrl + ')'"
      (click)="goTo()"
    ></div>
  </div>
</div>
