import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class SendFormService {

    url = environment.baseUrl;
    constructor(
        private http: HttpClient,
        private authService: AuthService
        ) { }
    
    enviarFormulario(data): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http
          .post(this.url + '/tramite/formulario', data, { headers })
          .pipe(map((d) => d));
      }
    
      enviarSolicitud(data): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http
          .post(this.url + '/tramite/solicitud', data, { headers })
          .pipe(map((d) => d));
      }
    

}