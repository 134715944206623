import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IVideo } from '../interfaces/IVideo';
import { AuthService } from './auth.service';

@Injectable({providedIn: 'root'})
export class LibraryService {
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        ) {}

  url = environment.repoUrl;

    getLibraryVideos(){
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token});
        return this.http.get(this.url + '/microcontents', { headers });
    }

    getVideoById(videoList: Array<IVideo>, id:number){
        return videoList.find(video => video.repo_id === Number(id))
    }
    
    postFavVideo(partner_id, resource_id){
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token});
        return this.http.post(this.url + `/favorites/save/${partner_id}?type=recurso&resource_id=${resource_id}`, {
            headers
        });
    }
    
    deleteFavVideo(partner_id, resource_id){
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token});
        return this.http.post(this.url + `/favorites/delete/${partner_id}?type=recurso&resource_id=${resource_id}`, {
            headers
        });
    }
}
