import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class FinancialStateService {
    
    url = environment.baseUrl;

    constructor(
        private http: HttpClient,
        private authService: AuthService) {}

    getEstadoFinanciero(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    
        return this.http.get(this.url + '/educat/estado_financiero', {
            headers,
        });
    }
}
