<div class="container-bg">
  <div class="container">
    <div class="heading">
      <div class="title white">Cronograma</div>
    </div>

    <!-- <div class="crono-grid">
      <ng-container *ngFor="let item of cronograma">
        <app-card-cronograma [item]="item"></app-card-cronograma>
      </ng-container>
    </div> -->

    <div class="message">
      ¡Muy pronto comenzaremos con la segunda convocatoria!
    </div>
  </div>
</div>
