<div class="container">
    <app-cardadvisor></app-cardadvisor>
    <div class="university-card">
        <div class="header">
            <img src="../../../assets/images/logos/university-logo.png" alt="">
        </div>
        <div class="title">Universidad ADENU</div>
        <div class="name">Calle Punta Darién Torre de las Américas, Panamá</div>
        <div class="btns-container">
            <button>Contactar por Whatsapp<i class="fa-brands fa-whatsapp icon"></i></button>
            <button>Contactar por Correo<i class="fa-light fa-envelope icon"></i></button>
        </div>
    </div>
</div>

 