<div class="sticky-header">
  <app-image-header
    [title]="'Opción de Titulación'"
    [imgURL]="'/images/content/graduacion/header-opcion-grado.png'"
    [titleColor]="'#b31d15'"
    [descriptionColor]="'#222222'"
    [buttonColor]="'#b31d15'"
  ></app-image-header>
  <app-onepage-nav
    [links]="links"
    [activeLink]="activeLink"
    [urlBase]="'graduacion/opcion-titulacion'"
  ></app-onepage-nav>
</div>
<div class="white-bg">
  <section id="descripcion" class="container two-cols">
    <div class="body">
      <div class="content">
        <h1 class="small-title">¿De qué se trata?</h1>
        <p>
          Para que a un estudiante de Licenciatura se le otorgue el título de
          grado, éste deberá optar por el cursado de alguna de las opciones de
          grado establecidas, ya sea trabajos de investigación o materias de
          maestrías. Vale destacar que los trabajos de investigación deben ser
          desarrollados y sustentados ante un jurado calificador.<br /><br />
          Este es un trabajo personal y autónomo del estudiante cuya elaboración
          podrá ser individual o excepcionalmente conjunta, debiendo presentarse
          y defenderse ante un tribunal universitario, de forma individual y
          pública, que permita al estudiante mostrar los conocimientos y la
          adquisición de competencias asociadas a la titulación.<br /><br />
          En el caso de las materias de Maestría el estudiante tendrá la
          posibilidad de cursar aquellas que se estimen complementarias a su
          formación base, y que contribuyan con el fortalecimiento de sus
          competencias profesionales.
        </p>
      </div>
      <div class="image">
        <img
          src="../../../../assets/images/content/graduacion/opcion-grado.png"
          alt="Pasantía de Responsabilidad Social Comunitaria"
          class="shadow main-radius"
        />
      </div>
    </div>
    <h1 class="small-title">Estas son tus opciones</h1>
    <div class="body smaller-top">
      <app-opcion-grado-link
        [name]="'Tésis (Proyecto de investigación)'"
        [index]="1"
        [description]="'Sólo modalidad presencial'"
        [link]="'tesis'"
      ></app-opcion-grado-link>
      <app-opcion-grado-link
        [name]="'Materias de Maestría'"
        [index]="2"
        [description]="'Todas las modalidades'"
        [link]="'maestria'"
      ></app-opcion-grado-link>
    </div>
  </section>
</div>
<section
  class="parallax"
  [ngStyle]="{
    'background-image':
      'url(https://i.postimg.cc/FHVP7frJ/bg-opcion-titulacion.jpg)'
  }"
></section>
<div class="white-bg">
  <section id="tesis" class="container">
    <div class="center-body left small-bottom">
      <div class="pair-title">
        <h1>Tésis (Proyecto de investigación)</h1>
        <h4>SÓLO APLICA ACTUALMENTE PARA LA MODALIDAD PRESENCIAL</h4>
      </div>

      <p>
        Esta opción de titulación consiste en un trabajo personal y autónomo del
        estudiante cuya elaboración podrá ser individual o excepcionalmente
        conjunta, debiendo presentarse y defenderse ante un tribunal
        universitario, de forma individual y pública, que permita al estudiante
        mostrar los conocimientos y la adquisición de competencias asociadas a
        la titulación. <br /><br />
      </p>
    </div>
  </section>
  <section class="container two-cols">
    <div class="body small-top">
      <div class="content top">
        <h3>Objetivos generales</h3>
        <p>
          Al finalizar el espacio formativo, el estudiante deberá haber
          desarrollado el trabajo final de grado, empleando los conocimientos
          adquiridos en la asignatura Metodología de la Investigación y en las
          asignaturas relacionadas con la temática de investigación, además de
          la aplicación de la norma establecida para tal fin.
        </p>
      </div>
      <div class="content top">
        <h3>Objetivos específicos</h3>
        <ul>
          <li>
            Aplicar los conocimientos adquiridos durante el período de
            formación, de manera tal que se pueda evaluar la integración de
            éstos a lo largo de su carrera y su desarrollo personal y
            profesional.
          </li>
          <li>
            Aplicar técnicas y herramientas de gestión estudiadas a lo largo de
            su formación académica, orientadas al desarrollo de proyectos de
            investigación.
          </li>
          <li>
            Analizar y estructurar los múltiples factores que impactan en el
            desarrollo de investigación.
          </li>
          <li>
            Integrar los conocimientos adquiridos en un proyecto operativo
            empresarial de carácter transversal.
          </li>
          <li>
            Comprender las relaciones e interacción entre las diferentes
            funciones empresariales y ámbitos en los que opera y con los que se
            relaciona la empresa.
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section class="container">
    <div class="center-body left small-top small-bottom">
      <h3>Etapas y contenidos</h3>
      <img
        class="full-width"
        src="https://i.postimg.cc/W3Xhw8w7/Opci-n-de-T-sis.png"
        alt="Cronograma tesis"
      />
    </div>
  </section>
  <section class="container">
    <div class="center-body left small-top">
      <h3>Preguntas frecuentes</h3>
      <mat-accordion>
        <ng-container *ngFor="let item of faqsTesis">
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ item.q }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.a }}</p>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </section>

  <section
    class="parallax"
    [ngStyle]="{
      'background-image':
        'url(https://i.postimg.cc/C5HZjncW/bg-opcion-titulacion-2.jpg)'
    }"
  ></section>

  <section id="maestria" class="container">
    <div class="center-body left small-bottom">
      <h1>Materias de Maestría</h1>
      <p>
        En esta opción de titulación, el estudiante tendrá la posibilidad de
        cursar aquellas materias de Maestría que se estimen complementarias a su
        formación base, y que contribuyan con el fortalecimiento de sus
        competencias profesionales.
      </p>

      <div class="card-grid left">
        <ng-container *ngFor="let item of materiasMaestria">
          <app-course-card [course]="item"></app-course-card>
        </ng-container>
      </div>
    </div>
  </section>
  <section class="container">
    <div class="center-body left small-top small-bottom">
      <h3>Etapas y contenidos</h3>
      <p>
        Cada semana contiene una serie de videos y lecturas obligatorias,
        acompañadas de un control de lectura de opción múltiple (con 2 intentos)
        y una tarea de desarrollo (a calificarse por un profesor tutor dentro de
        las 48hs). Al Finalizar el curso, hay un examen integrador de opción
        múltiple y una tarea final de desarrollo personal.
      </p>
      <img
        class="full-width"
        src="https://i.postimg.cc/1tNx55Y1/Cronograma-materias-maestria.jpg"
        alt="Cronograma maestrías"
      />
    </div>
  </section>
  <section class="container">
    <div class="center-body left small-top">
      <h3>Preguntas frecuentes</h3>
      <mat-accordion>
        <ng-container *ngFor="let item of faqsMaestria">
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ item.q }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.a }}</p>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </section>
</div>

<section id="consultas">
  <div class="container">
    <div class="grid-container">
      <div class="content">
        <h2>¿Te has quedado con dudas?</h2>
        <p>
          Envíanos tu consulta y en breve será respondida a tu correo
          electrónico
        </p>
        <form class="form-contact">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label
              >¿Sobre qué opción de titulación deseas consultar?</mat-label
            >
            <mat-select [formControl]="optionCtrl" required>
              <mat-option value="Tesis">Tesis</mat-option>
              <mat-option value="Maestría">Materias de Maestría</mat-option>
            </mat-select>
            <mat-error *ngIf="optionCtrl.hasError('required')">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Escribe tu consulta</mat-label>
            <textarea
              matInput
              #message
              maxlength="800"
              [formControl]="messageCtrl"
              required
            ></textarea>
            <mat-error *ngIf="messageCtrl.hasError('required')">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-hint align="end"
              >{{ message.value?.length || 0 }}/800
            </mat-hint>
          </mat-form-field>

          <button
            mat-flat-button
            color="primary"
            [disabled]="messageCtrl.hasError('required') || sendingMessage"
            (click)="sendMessage()"
          >
            <div class="submit-button">
              {{ sendingMessage ? "Enviando" : "Enviar consulta" }}
              <div class="small-loader" *ngIf="sendingMessage"></div>
            </div>
          </button>
        </form>
      </div>
      <div class="image">
        <img
          src="../../../../assets/images/content/adenpolis/dudas.svg"
          alt="Ayuda"
        />
      </div>
    </div>
  </div>
</section>
