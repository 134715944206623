import { Component } from '@angular/core';

@Component({
  selector: 'app-premios-concurso',
  templateUrl: './premios-concurso.component.html',
  styleUrls: ['./premios-concurso.component.scss'],
})
export class PremiosConcursoComponent {
  constructor() {}
}
