import { Component } from '@angular/core';

@Component({
  selector: 'app-cronograma-internacional',
  templateUrl: './cronograma-internacional.component.html',
  styleUrls: ['./cronograma-internacional.component.scss'],
})
export class CronogramaInternacionalComponent {
  public cronograma = [
    {
      id: 1,
      date: '1 JUL - 26 AGO',
      descripcion: '¡Postulaciones abiertas!',
    },
    {
      id: 2,
      date: '26 AGO - 11 SEP',
      descripcion: 'Análisis y selección de alumnos',
    },
    {
      id: 3,
      date: '16 SEP',
      descripcion: 'Comunicación de alumnos seleccionados',
    },
    {
      id: 4,
      date: '18 SEP',
      descripcion: 'Entrevista a alumnos seleccionados',
    },
    {
      id: 5,
      date: '25 SEP',
      descripcion: 'Inicio de postulación a universidades extranjeras',
    },
  ];

  constructor() {}
}
