import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getMinute',
})
export class GetMinutePipe implements PipeTransform {
  transform(hour): string {
    return hour.substring(3, 5);
  }
}
