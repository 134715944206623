import { DescriptionDialogComponent } from 'src/app/shared/components/dialogs/description-dialog/description-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

export interface DialogData {
  title: string;
  description?: string;
  status?: string;
  update?: boolean;
}

@Component({
  selector: 'app-file-description-dialog',
  templateUrl: './file-description-dialog.component.html',
  styleUrls: ['./file-description-dialog.component.scss'],
})
export class FileDescriptionDialogComponent {
  updateFile: File;
  constructor(
    public dialogRef: MatDialogRef<DescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onFileSelected() {
    this.dialogRef.close(true);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
