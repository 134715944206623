<div class="card-item">
  <img
    [src]="'../../../assets/images/content/adenpolis/' + house.name + '.png'"
    [alt]="'Casa ' + house.name"
  />
  <h2>{{ house.name }}</h2>
  <ng-container *ngFor="let item of house.careers">
    <h4>{{ item }}</h4>
  </ng-container>
</div>
