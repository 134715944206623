import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PlanEstudio } from 'src/app/shared/interfaces/ICarrera';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-plan-table',
  templateUrl: './plan-table.component.html',
  styleUrls: ['./plan-table.component.scss'],
})
export class PlanTableComponent implements AfterViewInit {
  @Input() planEstudio: PlanEstudio[];

  dataSource: MatTableDataSource<PlanEstudio>;
  public searchValue = '';

  public tableColumns = [
    {
      columnDef: 'asignatura',
      header: 'Asignatura',
      cell: (planEstudio: PlanEstudio) => `${planEstudio.asignatura}`,
    },
    {
      columnDef: 'codigo',
      header: 'Código',
      cell: (planEstudio: PlanEstudio) => `${planEstudio.codigo}`,
    },
    {
      columnDef: 'credito',
      header: 'Créditos',
      cell: (planEstudio: PlanEstudio) => `${planEstudio.credito}`,
    },
    {
      columnDef: 'Prerequisito',
      header: 'Pre-requisito',
      cell: (planEstudio: PlanEstudio) => `${planEstudio.Prerequisito}`,
    },
    {
      columnDef: 'periodo',
      header: 'Periodo en la carrera',
      cell: (planEstudio: PlanEstudio) =>
        `${planEstudio.periodo === '0' ? 'Electiva' : planEstudio.periodo}`,
    },
  ];

  public columnsToDisplay = this.tableColumns.map((c) => c.columnDef);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private cdref: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.renderTable();
    this.cdref.detectChanges();
  }

  renderTable(): void {
    const filteredPlanEstudio = this.planEstudio.filter(
      (asignatura:any) => asignatura.tipo !== 'to_elected_english'
    );
    this.dataSource = new MatTableDataSource(filteredPlanEstudio);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search(e): void {
    const filterValue = (e.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  cleanSearch(): void {
    this.searchValue = '';
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
  }
}

/* const asignaturas: PlanEstudio[] = [
  {
    asinatura: 'Asignatura',
    codigo: '8878sdaw',
    credito: '2',
    'pre-requisito': '',
    periodo: "1",
  },
  {
    asinatura: 'Asignatura',
    codigo: '8878sdaw',
    credito: '2',
    'pre-requisito': '',
    periodo: "1",
  },
  {
    asinatura: 'Asignatura',
    codigo: '8878sdaw',
    credito: '2',
    'pre-requisito': '',
    periodo: "1",
  },
  {
    asinatura: 'Asignatura',
    codigo: '8878sdaw',
    credito: '2',
    'pre-requisito': '',
    periodo: "1",
  },
];
 */
