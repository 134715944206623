import { MatDialog } from '@angular/material/dialog';
import { DracmaInfo } from './../../shared/interfaces/IDracma';
import { Component, OnInit } from '@angular/core';
import { MoreInfoComponent } from './components/more-info/more-info.component';
import { DracmaService } from 'src/app/shared/services/dracma.service';

@Component({
  selector: 'app-dracma-scoring',
  templateUrl: './dracma-scoring.component.html',
  styleUrls: ['./dracma-scoring.component.scss'],
})
export class DracmaScoringComponent implements OnInit {
  dracmaInfo: DracmaInfo = null;
  loading = false;
  error = false;

  constructor(private dracmaService: DracmaService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getDracmaInfo();
  }

  getDracmaInfo() {
    this.loading = true;
    this.dracmaService.getDracmas().subscribe({
      next: (res) => {
        this.loading = false;
        this.dracmaInfo = res;
        this.error = false;
      },
      error: (err) => {
        this.loading = false;
        this.error = true;
      },
    });
  }

  openInfo() {
    const dialogRef = this.dialog.open(MoreInfoComponent, {
      width: '400px',
      autoFocus: false,
      data: {},
      panelClass: 'generic-alert',
    });
  }
}
