<div id="impulso">
  <div class="sticky-header">
    <app-header [title]="'Programa Impulso'"></app-header>
    <app-onepage-nav
      [links]="navLinks"
      [urlBase]="'ceaden/programa-impulso'"
      [activeLink]="activeLink"
    ></app-onepage-nav>
  </div>

  <div class="heading">
    <div class="image"></div>
    <div class="container">
      <div class="body">
        <div class="content">
          <img
            src="../../../../assets/images/logos/logo-impulso-wandy.png"
            alt="Programa Impulso"
          />
          <p>
            Desde el CEADEN queremos ayudarte a dar el siguiente paso en tu
            emprendimiento. Si eres estudiante de ADEN University y eres
            emprendedor, podrás vivir una gran experiencia en la que te
            acompañaremos a escalar tu emprendimiento.
          </p>
          <div class="button-group">
            <button
              mat-flat-button
              color="primary"
              [routerLink]="['./']"
              fragment="inscripcion"
            >
              Inscribirme
            </button>
          </div>
        </div>
        <div class="image">
          <img
            src="../../../../assets/images/bg/bg-impulso-gradient-letras2.png"
            alt="Frase"
          />
        </div>
      </div>
    </div>
  </div>
  <section class="container" id="sobre-impulso">
    <div class="body">
      <div class="content">
        <h1>¿Qué es el Programa Impulso?</h1>
        <p>
          IMPULSO es un programa creado por el Centro de Emprendimiento ADEN
          (CEADEN) para apoyar a los estudiantes de ADEN University (ADENU) en
          el desarrollo de sus emprendimientos. Los estudiantes seleccionados
          podrán planificar o implementar mejoras en su emprendimiento y,
          además, obtendrán un aprendizaje en el proceso que consolide los
          desarrollados en las materias de la Universidad.
        </p>
      </div>
      <div class="image">
        <video controls autoplay autoPlay muted loop>
          <source
            src="../../../../assets/videos/Capsula_2.mp4"
            type="video/mp4"
          />
          <source
            src="../../../../assets/videos/Capsula_2.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </section>
  <section class="container" id="beneficios">
    <div class="center-body">
      <h1>Beneficios</h1>
      <div class="card-grid">
        <ng-container *ngFor="let item of beneficios">
          <div class="card-beneficio">
            <div class="number">{{ item.id }}</div>
            <div class="title">
              <h2>{{ item.nombre }}</h2>
              <h4>{{ item.subtitulo }}</h4>
            </div>
            <p class="description">{{ item.descripcion }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
  <section id="quien-puede-inscribirse">
    <div class="container">
      <div class="center-body">
        <h1>¿Quién puede participar?</h1>
        <p>
          Estudiantes matriculados cursando una de las licenciaturas que ADENU
          imparte en formato presenciales u online, en cualquier año de la
          carrera. Los estudiantes pueden presentarse de manera individual o en
          grupos. En el caso de los grupos, al menos un integrante del Equipo
          debe ser estudiante de ADEN University. <br />
          <br />
          Los emprendimientos que se presenten como candidatos deben ser
          proyectos activos de emprendimiento o en fase avanzada de
          planificación. Se valorarán especialmente aquellos emprendimientos que
          ya han generado ventas.
        </p>
      </div>
    </div>
  </section>

  <section id="criterios-evaluacion">
    <div class="container">
      <div class="center-body">
        <h1>Criterios de evaluación</h1>
        <div class="card-grid">
          <ng-container *ngFor="let item of criterios">
            <div class="card-criterio">
              <div class="title">
                <h1>{{ item.nombre }}</h1>
              </div>
              <div class="body">
                <p>
                  {{ item.descripcion }}
                </p>
                <h4>
                  Constituye el {{ item.calificacion }} de la calificación total
                </h4>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>

  <section id="testimonios">
    <div class="container">
      <div class="center-body">
        <h1>Testimonios</h1>
        <p>¡Conoce a los estudiantes que ya participaron del programa!</p>
        <div class="grid-testimonio">
          <ng-container *ngFor="let item of testimonios">
            <app-card-testimonio
              [student]="item.student"
              [project]="item.project"
              [video]="item.video"
            ></app-card-testimonio>
          </ng-container>
        </div>
      </div>
    </div>
  </section>

  <section id="cronograma">
    <div class="container">
      <div class="center-body">
        <h1>Cronograma</h1>
        <div class="card-grid">
          <ng-container *ngFor="let item of cronograma">
            <div class="card-cronograma">
              <div class="fecha">
                <div class="mes">{{ item.mes }}</div>
                <div class="dia">{{ item.dia }}</div>
              </div>
              <p>{{ item.nombre }}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </section>

  <section id="inscripcion">
    <div class="container">
      <div class="center-body">
        <h1>Inscripción</h1>
        <div class="button-group">
          <a
            mat-flat-button
            color="accent"
            href="https://drive.google.com/uc?export=download&id=1c6C5dkYTLx0FDKtQ-u4UISpJZiRFo276"
          >
            <mat-icon class="material-icons-round">download</mat-icon>Bases y
            condiciones
          </a>
          <a
            mat-flat-button
            color="accent"
            href="https://drive.google.com/uc?export=download&id=1PwWza2V-tES8kzcbLgnHjwe312TVyR4C"
          >
            <mat-icon class="material-icons-round">download</mat-icon>Modelo
            Propuesta Técnica
          </a>
        </div>
        <app-form-impulso
          class="full-width"
          [setWaiting]="setWaiting"
        ></app-form-impulso>
      </div>
    </div>
  </section>

  <section id="contacto">
    <div class="container">
      <div class="center-body">
        <h1>¿Tienes dudas?</h1>
        <p>
          Envíanos tu consulta y en breve será respondida a tu correo
          electrónico
        </p>
        <form class="form-contact">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Escribe tu consulta</mat-label>
            <textarea
              matInput
              #message
              maxlength="800"
              [formControl]="messageCtrl"
              [errorStateMatcher]="matcher"
              required
            ></textarea>
            <mat-error *ngIf="messageCtrl.hasError('required')">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-hint align="end"
              >{{ message.value?.length || 0 }}/800
            </mat-hint>
          </mat-form-field>
          <button
            mat-flat-button
            [disabled]="messageCtrl.hasError('required')"
            id="send-button"
            color="accent"
            (click)="sendMessage()"
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  </section>
</div>

<div class="soonContainer" *ngIf="isWaiting">
  <div class="soonMessage spinner">
    <mat-spinner></mat-spinner>
    <h2>Enviando...</h2>
  </div>
</div>
