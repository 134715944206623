import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { FileValidator } from 'src/app/shared/validators/file-validator';
import { CmsService } from 'src/app/shared/services/cms.service';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Validators,
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { Location } from '@angular/common';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-postulaciones-adenpolis',
  templateUrl: './postulaciones-adenpolis.component.html',
  styleUrls: ['./postulaciones-adenpolis.component.scss'],
})
export class PostulacionesAdenpolisComponent implements OnInit {
  public mainForm: UntypedFormGroup;
  avatarCtrl = new UntypedFormControl(null, [
    Validators.required,
    FileValidator.fileMaxSize(1024),
    FileValidator.extensions(['jpg', 'jpeg', 'png']),
  ]);

  public user$;
  public user;

  public applied = false;
  public currentUserInscription = null;

  public configs = null;
  public currentPostulation = false;
  public endedPostulation = false;
  public sendingPostulation = false;
  public links = [];

  public moment = moment;
  public campusUrl = environment.webUrl;

  constructor(
    private location: Location,
    private formBuilder: UntypedFormBuilder,
    private cmsService: CmsService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private store: Store<UserState>,
    private snackbar: MatSnackBar
  ) {
    this.user$ = store.select('user');
  }

  ngOnInit(): void {
    this.buildEmptyForm();
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
        this.checkUser(user);
        this.patchMainForm();
      }
    });
    this.getConfigs();
  }

  back(): void {
    this.location.back();
  }

  buildEmptyForm() {
    this.mainForm = this.formBuilder.group({
      full_name: ['', Validators.required],
      phone: new UntypedFormControl(
        {
          value: '',
          disabled: false,
        },
        [Validators.required]
      ),
      career: [{ value: '', disabled: true }, Validators.required],
      modality: [{ value: '', disabled: true }, Validators.required],
      sis_id: ['', Validators.required],
      email: [
        { value: '', disabled: false },
        [Validators.required, Validators.email],
      ],
      reasons_to_apply: ['', [Validators.required, Validators.maxLength(250)]],
      votes: [0],
      is_candidate: [false],
      is_winner: [false],
    });
  }

  patchMainForm() {
    this.mainForm.patchValue({
      full_name: this.user.nombre,
      phone: this.user.movil,
      career: this.user?.carreras[0]?.carrera,
      modality: this.user?.carreras[0]?.modalidad,
      sis_id: this.user.legajo,
      email: this.user.email,
      reasons_to_apply: '',
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.avatarCtrl.patchValue(file);
    }
  }

  checkUser(user) {
    this.cmsService.checkInscription(user.legajo).subscribe((res) => {
      if (res.data.length > 0) {
        this.applied = true;
        this.currentUserInscription = res.data[0];
      }
    });
  }

  getConfigs() {
    this.cmsService.getComiteConfig().subscribe((res) => {
      this.configs = res.data.attributes;
      const today = moment();

      if (
        today.isSameOrAfter(this.configs.inicio_postulaciones) &&
        today.isSameOrBefore(this.configs.fin_postulaciones)
      ) {
        this.currentPostulation = true;
      } else if (today.isAfter(this.configs.fin_postulaciones)) {
        this.endedPostulation = true;
        this.currentPostulation = false;
      }
    });
  }

  sendPostulation() {
    this.sendingPostulation = true;
    this.cmsService
      .applyToCommittee(
        {
          ...this.mainForm.value,
          career: this.user.carreras[0].carrera,
          modality: this.user.carreras[0].modalidad,
          adenpolis_house: this.user.carreras[0].casa.toUpperCase(),
          email: this.user.email,
        },
        this.avatarCtrl.value
      )
      .subscribe({
        next: (res) => {
          this.sendingPostulation = false;
          this.applied = true;
        },
        error: (err) => {
          this.sendingPostulation = false;
          this.applied = false;
          this.alertService.openAlert(
            'Ups.. ocurrió un error',
            'No pudimos concretar tu postulación, por favor inténtalo de nuevo más tarde',
            'Cerrar'
          ) ;
        },
      });
  }

  getCalendarEvent() {
    const eventCalendar = {
      title: `[CAMPUS ADENU] ¡Postulaciones Comité Estudiantil ${this.configs.year}!`,
      description: `¡Postúlate para representar a tu carrera en el Comité Estudiantil! Accede a <a href="${this.campusUrl}/comite-estudiantil/elecciones">${this.campusUrl}/comite-estudiantil/postulaciones</a> para chequear los requisitos y completa el formulario de postulación. \n\n Conoce más sobre el Comité Estudiantil ADENPÓLIS de ADEN University Panamá aquí 👉🏼 <a href="${this.campusUrl}/comite-estudiantil">${this.campusUrl}/comite-estudiantil</a>`,
      start: this.configs.inicio_postulaciones,
      end: this.configs.fin_postulaciones,
      url: `${this.campusUrl}/comite-estudiantil/postulaciones`,
      allDay: true,
    };

    this.links = [
      {
        name: 'Google Calendar',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          google(eventCalendar)
        ),
      },
      {
        name: 'Outlook',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          outlook(eventCalendar)
        ),
      },
      {
        name: 'Office 365',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          office365(eventCalendar)
        ),
      },
      {
        name: 'Yahoo',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          yahoo(eventCalendar)
        ),
      },
      {
        name: 'iCal',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(ics(eventCalendar)),
      },
    ];
  }
}
