<div
  id="header"
  class="image-header container-fluid"
  [ngStyle]="backgroundImage"
  [ngClass]="{ scrolled: scrolled }"
>
  <div class="container">
    <div class="go-back" *ngIf="!scrolled">
      <button
        mat-button
        color="primary"
        [ngStyle]="{ color: buttonColor }"
        (click)="back()"
      >
        <mat-icon
          class="material-icons-round"
          [ngStyle]="{ color: buttonColor }"
          >arrow_back</mat-icon
        >
        Volver
      </button>
    </div>

    <div class="body" [ngStyle]="{ color: !scrolled ? titleColor : '#ffffff' }">
      <button mat-icon-button (click)="back()" *ngIf="scrolled">
        <mat-icon class="material-icons-round">arrow_back</mat-icon>
      </button>
      <img
        [src]="imgURL.includes('http') ? logo : '../../../../assets' + logo"
        [alt]="'Logo ' + title"
        [ngClass]="{ small: logo && title }"
        *ngIf="logo"
      />
      <h1
        *ngIf="
          title && showTitle || (title && !showTitle && scrolled)
        "e
      >
        <span class="text-bg">{{ title }}</span>
      </h1>
      <p
        *ngIf="description"
        [ngStyle]="{ color: descriptionColor ? descriptionColor : '#222222' }"
      >
        {{ description }}
      </p>
    </div>
  </div>
</div>
