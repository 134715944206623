import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-opcion-grado-link',
  templateUrl: './opcion-grado-link.component.html',
  styleUrls: ['./opcion-grado-link.component.scss'],
})
export class OpcionGradoLinkComponent {
  @Input() name: string = null;
  @Input() description: string = null;
  @Input() link: string = null;
  @Input() index: number = 0;
}
