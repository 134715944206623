import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { pipe } from 'rxjs';
import { CmsService } from 'src/app/shared/services/cms.service';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { FileValidator } from 'src/app/shared/validators/file-validator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comite-estudiantil',
  templateUrl: './comite-estudiantil.component.html',
  styleUrls: ['./comite-estudiantil.component.scss'],
})
export class ComiteEstudiantilComponent implements OnInit {
  title = 'Centro de Emprendimiento ADEN University';

  links = [
    { name: 'Sobre nosotros', tag: 'sobre-nosotros' },
    { name: 'Estructura', tag: 'estructura' },
    { name: 'Postulaciones y Elecciones', tag: 'postulaciones' },

    { name: 'Cronograma', tag: 'cronograma' },
    { name: 'Contacto', tag: 'contacto' },
  ];
  activeLink = this.links[0];

  public user$;
  public user;

  public configs = null;
  public cronogram = [];
  public documentsCronogram = [];
  public sendingMessage = false;
  videoUrl = null;

  public moment = moment;

  messageCtrl = new UntypedFormControl('', Validators.required);

  postulationBanner = {
    id: 1,
    slide_type: 'Imagen y contenido',
    slide_order: 'Contenido a la derecha',
    imageUrl: 'https://i.postimg.cc/kX9b6xBz/pexels-fauxels-3184419.jpg',
    content_bg: null,
    slide_title: 'Postulaciones',
    slide_description: 'Desde hasta',
    CTA_title: '¡Quiero postularme!',
    CTA_link: '/comite-estudiantil/postulaciones',
  };

  houses = [
    {
      name: 'FINER',
      careers: [
        'Dirección de finanzas',
        'Administración de empresas',
        'Contabilidad y Auditoría',
      ],
      description: `El término finanza proviene del francés finance y se formó sobre el verbo 'finer', variante de 'finir' (terminar), que antes significaba 'pagar'.`,
    },
    {
      name: 'MERX',
      careers: ['Logística y comercio internacional', 'Business analytics'],
      description: `La palabra “comercio” viene del latín commercium (compra y venta de mercancía), formada del prefijo con- (junto) y la palabra merx (mercancía).`,
    },
    {
      name: 'OTIUM',
      careers: [
        'Negocios Digitales, Emprendimiento e Innovación',
        'Marketing Online y Comunicación digital',
        'Recursos Humanos',
      ],
      description: `La palabra "negociar" viene del latín negotiari y significa "ajustar el valor de algo". Sus componentes léxicos son: el prefijo nec- (no, sin), otium (ocio), más el sufijo -ar (usado para nombrar verbos).`,
    },
    {
      name: 'INGENIUM',
      careers: [
        'Ingeniería Comercial',
        'Ingeniería Industrial',
        'Tecnología Digital',
      ],
      description: `La palabra “Ingeniero” proviene del latín ingenium ("ingenio") + sufijo -ero, del latín -arius ("oficio, labor, profesión relacionada con el concepto de origen").`,
    },
  ];

  currentPostulation = false;

  constructor(
    private location: Location,
    private cmsService: CmsService,
    private store: Store<UserState>,
    public snackbar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {
    this.user$ = store.select('user');
  }

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
      }
    });
    this.getConfigs();
  }

  back(): void {
    this.location.back();
  }

  getConfigs() {
    this.cmsService.getComiteConfig().subscribe((res) => {
      this.configs = res.data.attributes;
      this.videoUrl =
        this.configs.link_video_presentacion != null
          ? this.sanitizer.bypassSecurityTrustResourceUrl(
              this.configs.link_video_presentacion
            )
          : this.sanitizer.bypassSecurityTrustResourceUrl('');
      this.postulationBanner.slide_title = `Postulaciones ${this.configs.year}`;

      this.cronogram = this.configs.cronograma.data.sort(this.compareDates);
      this.documentsCronogram = this.cronogram.filter(data => data.attributes.link);
      this.cronogram = this.cronogram.filter(data => !data.attributes.link)
      const today = moment();

      if (
        today.isSameOrAfter(this.configs.inicio_postulaciones) &&
        today.isSameOrBefore(this.configs.fin_postulaciones)
      ) {
        this.currentPostulation = true;
        this.postulationBanner.slide_description = `Desde el ${moment(
          this.configs.inicio_postulaciones
        )
          .locale('es')
          .format('LL')} hasta el ${moment(this.configs.fin_postulaciones)
          .locale('es')
          .format(
            'LL'
          )} tienes tiempo de postularte. ¡No pierdas la oportunidad!`;
      } else {
        this.postulationBanner.slide_description = `Disponibles a partir del ${moment(
          this.configs.inicio_postulaciones
        )
          .locale('es')
          .format('LL')} y hasta el ${moment(this.configs.fin_postulaciones)
          .locale('es')
          .format('LL')}. ¡No pierdas la oportunidad!`;
      }
    });
  }

  compareDates(a, b) {
    return moment(a.attributes.date_from).isBefore(b.attributes.date_from)
      ? -1
      : moment(a.attributes.date_from).isAfter(b.attributes.date_from)
      ? 1
      : 0;
  }

  sendMessage(): void {
    if (this.messageCtrl.value !== null || this.messageCtrl.value !== '') {
      this.sendingMessage = true;
      const data = {
        student_name: this.user.nombre,
        message: this.messageCtrl.value,
        student_email: this.user.email,
        send_to: this.configs.contact_email,
        title:
          'Nueva consulta de ' +
          this.user.apellido +
          ' sobre Comité Estudiantil',
      };
      this.cmsService.sendEmail(data).subscribe({
        next: (res) => {
          this.snackbar.open('¡Mensaje enviado con éxito!', 'OK', {
            duration: 2000,
            panelClass: ['success-snackbar'],
          });
          this.sendingMessage = false;
          this.messageCtrl.reset();
        },
        error: (err) => {
          this.snackbar.open(
            'Ocurrió un error, por favor intenta de nuevo más tarde',
            'OK',
            {
              duration: 2000,
              panelClass: ['error-snackbar'],
            }
          );
          this.sendingMessage = false;
        },
      });
    }
  }
  navigate(link: string){
    localStorage.setItem('viewer_file_url', link)
    this.router.navigate(['file']);
  }
}
