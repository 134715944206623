<div class="card-body">
  <!-- <div class="circle-img">
      <img [src]="requisito.img" alt="title" />
    </div> -->

  <div class="circle-index">
    {{ requisito.id }}
  </div>

  <div class="title">{{ requisito.title }}</div>
  <div class="descripcion">
    <ng-container *ngFor="let line of requisito.description"
      ><div class="line">
        {{ line }}
      </div></ng-container
    >
  </div>
</div>
