export const solicitudData = [
    {
        name: 'Finanzas',
        categories: [
            {
                value: 'Inconsistencia en Pago',
                name: 'Inconsistencia en Pago',
            },
            {
                value: 'Link de Pago',
                name: 'Solicitud de Link de Pago',
            },
            {
                value: 'Pago de MasterCard',
                name: 'Pago de Pago MasterCard',
            },
            {
                value: 'Solicitud de comprobante de pago/ recibos',
                name: 'Solicitud de Comprobante de Pago/ Recibos',
            },
            {
                value: 'Validación estado financiero',
                name: 'Solicitud Estado Financiero',
            },
        ],
    },
    {
        name: 'Servicio y Permanencia',
        categories: [
            {
                value: 'Cambio de carrera',
                name: 'Cambio de Carrera',
            },
            {
                value: 'Cambio de modalidad',
                name: 'Cambio de Modalidad',
            },
            {
                value: 'Doble cursado',
                name: 'Solicitud de Doble Cursado',
            },
            // {
            //     value: 'Cambio Materia',
            //     name: 'Cambio Materia',
            // },
            {
                value: 'Respuesta Oficial',
                name: 'Inconformidad Respuesta',
            },
            {
                value: 'Queja Académica Advisor',
                name: 'Queja Académica Advisor',
            },
        ],
    },
    {
        name: 'Secretaría General',
        categories: [
            {
                value: 'Cartas de IFARHU',
                name: 'Solicitud Cartas IFARHU',
            },
            {
                value: 'Validación de Documentación',
                name: 'Revisión Legajo',
            },
        ],
    },
    {
        name: 'Faculty',
        categories: [
            {
                value: 'Asistencia TSV',
                name: 'Novedad encuentro sincrónico',
            },
            {
                value: 'Demora en el tiempo de calificación',
                name: 'Demora en el tiempo de calificación',
            },
            {
                value: 'Falta de feedback o feedback incompleto',
                name: 'Feedback Docente',
            },
            {
                value: 'Revisión validación de calificación',
                name: 'Revisión de validación de calificación/notas',
            },
            {
                value: 'Sin respuesta por parte del profesor',
                name: 'Sin respuesta por parte del profesor',
            },
            {
                value: 'Reporte de mala carga de actividad',
                name: 'Error en carga de Actividad',
            },
        ],
    },
    {
        name: 'Operaciones',
        categories: [
            {
                value: 'Error canvas',
                name: 'Error en Plataforma',
            },
        ],
    },
    {
        name: 'Operaciones Académicas',
        categories: [
            {
                value: 'Cambio Materia',
                name: 'Cambio Materia',
            },
            {
                value: 'Cambio Materia Online',
                name: 'Cambio Materia a Online',
            },
            {
                value: 'Cursado de asignatura extra',
                name: 'Asignatura Adicional',
            },
            {
                value: 'Modificación de Nota',
                name: 'Corrección de Nota',
            },
            {
                value: 'Pre Convalidaciones',
                name: 'Pre Convalidaciones',
            },
            {
                value: 'Consulta de inconsistencia de MU/Inglés',
                name: 'Examen de nivelación de Inglés',
            },
            {
                value: 'Error en el campus (Rematrícula)',
                name: 'Error en el Campus',
            },
            {
                value: 'Convalidaciones',
                name: 'Convalidaciones',
            },
        ],
    },
];