<div id="dialog">
  <div class="dialog-header">
    <div mat-dialog-title>
      <h1>{{data.university.name}}</h1>
      <button class="close" mat-icon-button aria-label="Close" mat-dialog-close>
        <mat-icon class="material-icons-round">close</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div class="dialog-container">
    <div mat-dialog-content>
      <div class="uni-container">
        <div class="uni-description" *ngIf="data.university.description">
          {{data.university.description}}
        </div>
        <div class="header">Detalles</div>
        <ul *ngFor="let item of data.university.details">
          <li [innerHtml]="item">{{item}}</li>
        </ul>

        <ng-container *ngIf="data.university.costos">
          <div class="header">Costos</div>
          <ul *ngFor="let item of data.university.costos">
            <li [innerHtml]="item">{{item}}</li>
          </ul>
        </ng-container>

        <ng-container
          *ngIf="data.university.period !== undefined && data.university.period.length > 0"
        >
          <div class="header">Periodo</div>
          <ul *ngFor="let periodo of data.university.period">
            <li>{{periodo.title}}</li>
            <ul *ngFor="let item of periodo.list">
              <li>{{item}}</li>
            </ul>
          </ul>
        </ng-container>
        <br />

        <div class="header">{{data.university.careersTitle}}</div>
        <ul *ngFor="let item of data.university.careers">
          <li>{{item}}</li>
        </ul>
        <div *ngIf="data.university.urlCareers">
          {{data.university.inscripciones ? "¡Inscripciones abiertas! Haz click
          ": "Conoce más sobre los cursos y carreras disponibles haciendo
          click"}}
          <a
            [href]="data.university.urlCareers"
            target="_blank"
            rel="noopener noreferrer"
            >aquí</a
          >
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <a
        mat-stroked-button
        target="_blank"
        rel="noopener noreferrer"
        color="primary"
        [href]="data.university.web"
        >Visitar web</a
      >
      <button mat-flat-button color="primary" mat-dialog-close>Cerrar</button>
    </div>
  </div>
</div>
