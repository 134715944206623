import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as $ from 'jquery';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import * as reduxUser from '../../redux/actions/user.action';
import { jwtDecode } from "jwt-decode";
import { DetalleBloqueo } from '../../shared/interfaces/IBloqueo'; 
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginFormGroup: UntypedFormGroup;
  public hide = true;

  user: string;
  password: string;
  hoy: Date;
  $: any;
  isDisabled = false;

  errorAlert = false;

  public queryEmail = '';

  public originUrl = localStorage.getItem('origin');

  public user$: Observable<any>;
  estadoBloqueos: DetalleBloqueo[] = [];

  constructor(
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private _snackBar: MatSnackBar,
    private store: Store<UserState>,
    private alumnoService: AlumnoService,
    private authService: AuthService,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.authService.leerToken() !== '') {
      this.router.navigateByUrl('/home');
    }
    this.activeRoute.queryParams.subscribe((params) => {
      this.queryEmail = params.mail;
    });
    this.buildForm();
  }

  buildForm(): void {
    this.loginFormGroup = this.formBuilder.group({
      email: [this.queryEmail, Validators.required],
      password: ['', Validators.required],
    });
  }

  onLogIn(): void {
    const btn = document.getElementById('btn-login');
    btn.innerHTML = 'Cargando...';
    this.isDisabled = true;
    
    // Establecer la expiración en 3 horas desde el momento actual
    this.hoy = new Date();
    this.hoy.setHours(this.hoy.getHours() + 3);
    const expiracion = this.hoy.toISOString();

    const user = {
      user: this.loginFormGroup.value.email,
      password: this.loginFormGroup.value.password,
    };

    this.authService.login({ ...user }).subscribe({
      next: (res) => {
        const grantType = 'password';
        this.authService.loginCampusQR({ ...user, grant_type: grantType }).subscribe({
          next: (res: any) => {
            localStorage.setItem('qr_token', res.token);
          },
          error: (err) => {
            console.log(err);
          }
        });

        localStorage.setItem('token', res.response_data[`token`]);
        localStorage.setItem('sis_id', res.response_data.educat_data[`sis_id`]);
        localStorage.setItem('rol', res.response_data.educat_data[`campus_role`]);
        localStorage.setItem('exp', expiracion);
        localStorage.setItem('inactivity_exp', expiracion);

        const decode: any = jwtDecode(res.response_data[`token`]);
        localStorage.setItem('repo_id', decode.repositorio_partner_id);

        this.getUser();
      },
      error: (err) => {
        this.isDisabled = false;
        btn.innerHTML = 'Ingresar';
        this.errorAlert = true;
      },
    });
}


  getUser() {
    this.alumnoService.getAlumno().subscribe({
      next: (res) => {
        if (
          (res.nombre === '' || res.nombre === null) &&
          (res.email === '' || res.email === null)
        ) {
          /* setTimeout(() => {
            this.onSignOut();
          }, 5000); */
          this.authService.clearSis_id();
          this.authService.clearToken();
          const btn = document.getElementById('btn-login');
          this.isDisabled = false;
          btn.innerHTML = 'Ingresar';
          return;
        }

        localStorage.setItem('estado_bloqueo', res.estado_bloqueo);
        res.carreras.forEach((carrera) => {
          if (
            carrera.estado_bloqueo != '' &&
            carrera.estado_bloqueo != 'Graduado'
          ) {
            this.estadoBloqueos.push({
              carrera: carrera.carrera,
              estado_bloqueo: carrera.estado_bloqueo,
            });
          }

          if (this.estadoBloqueos.length > 0) {
            localStorage.setItem(
              'estadoBloqueos',
              JSON.stringify(this.estadoBloqueos)
            );
          }
        });

        localStorage.setItem(
          'userData',
          JSON.stringify({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
            token: null,
          })
        );
        this.store.dispatch(
          new reduxUser.GetUser({
            ...res,
            foto64: `data:image/png;base64,${res.foto64}`,
          })
        );

        setTimeout(() => {
          if (this.originUrl != undefined && this.originUrl != null) {
            this.router.navigateByUrl(this.originUrl);
          } else {
            this.router.navigateByUrl('');
          }
        }, 200);
      },
      error: (err) => {
        this.alertService.openAlert(
          '¡Ha ocurrido un error!',
          err.error.Error,
          'Ok'
        );
        this.authService.clearSis_id();
        this.authService.clearToken();
        const btn = document.getElementById('btn-login');
        btn.innerHTML = 'Ingresar';
        this.isDisabled = false;
      },
    });
  }

  onForgotPassword(): void {
    this.popupWindow(
      'https://fa.aden.org/password/forgot?email=' +
        this.loginFormGroup.value.email,
      'Recuperar mi contraseña',
      window,
      500,
      700
    );
  }

  popupWindow(url, windowName, win, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  }

  closeAlert() {
    this.errorAlert = false;
  }
}
