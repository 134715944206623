import * as reduxAcademic from '../actions/academicAd.action';

export interface AcademicState {
  academic: object;
}

export const initialAcademicState = null;

export function AcademicReducer(
  academicState: AcademicState = initialAcademicState,
  action: reduxAcademic.AcademicAdActions
): AcademicState {
  // console.log(countriesState)
  switch (action.type) {
    case reduxAcademic.GET_ACADEMIC:
      return { ...action.payload };

    default:
      break;
  }
  return academicState;
}
