import { UserState } from './../../../redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CmsService } from './../../../shared/services/cms.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  alianzas,
  faqsPasProf,
  testimoniesPasProf,
} from 'src/app/shared/data/graduacion';

@Component({
  selector: 'app-practica-profesional',
  templateUrl: './practica-profesional.component.html',
  styleUrls: [
    './practica-profesional.component.scss',
    '../graduacion.component.scss',
  ],
})
export class PracticaProfesionalComponent implements OnInit {
  public user$;
  public user;

  public sendingMessage = false;
  messageCtrl = new UntypedFormControl('', Validators.required);

  links = [
    { name: 'Descripción', tag: 'descripcion' },
    { name: 'Objetivos', tag: 'objetivos' },
    { name: 'Etapas y contenidos', tag: 'etapas-y-contenidos' },
    { name: 'Metodología', tag: 'metodologia' },
    { name: 'Criterios de evaluación', tag: 'criterios-evaluacion' },
    { name: 'Alianzas', tag: 'alianzas' },
    { name: 'Testimonios', tag: 'testimonios' },
    { name: 'Dirección', tag: 'direccion' },
    { name: 'FAQ', tag: 'faq' },
    { name: 'Consultas', tag: 'consultas' },
  ];
  activeLink = this.links[0];

  alianzas = alianzas;

  faqs = faqsPasProf;

  public testimonies = testimoniesPasProf;

  constructor(
    private cmsService: CmsService,
    public snackbar: MatSnackBar,
    private userStore: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.user$ = this.userStore.select('user');
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
      }
    });
  }

  sendMessage(): void {
    if (this.messageCtrl.value !== null || this.messageCtrl.value !== '') {
      this.sendingMessage = true;
      const data = {
        student_name: this.user.nombre,
        student_educat_id: this.user.id_educat,
        student_id: this.user.legajo,
        student_email: this.user.email,
        message: this.messageCtrl.value,
        subject: 'Práctica Profesional',
        send_to: 'lorena.gamboa@adenuniversity.edu.pa',
      };
      this.cmsService.sendConsult(data).subscribe({
        next: (res) => {
          this.snackbar.open('¡Mensaje enviado con éxito!', 'OK', {
            duration: 2000,
            panelClass: ['success-snackbar'],
          });
          this.sendingMessage = false;
          this.messageCtrl.reset();
        },
        error: (err) => {
          this.snackbar.open(
            'Ocurrió un error, por favor intenta de nuevo más tarde',
            'OK',
            {
              duration: 2000,
              panelClass: ['error-snackbar'],
            }
          );
          this.sendingMessage = false;
        },
      });
    }
  }
}
