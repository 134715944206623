<div class="card-pa">
  <div class="text-center">
    <div class="icon">
      <mat-icon class="material-icons-round">arrow_forward</mat-icon>
    </div>
    <div class="title">
      <h2>Ingresar a CANVAS</h2>
    </div>
  </div>
</div>
