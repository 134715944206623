<div class="wrapper">
  <img [src]="cmsUrl + curso.attributes.cover.data.attributes.url" alt="" />
  <div class="body" (click)="goTo(curso.attributes.course_link)">
    <div class="title">{{ curso.attributes.name }}</div>
    <div class="description">{{ curso.attributes.description }}</div>
  </div>
  <div class="footer">
    <button
      mat-flat-button
      color="primary"
      (click)="goTo(curso.attributes.enrollment_link)"
    >
      Inscribirme
    </button>
  </div>
</div>
