import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class PqrsService {

    url = environment.baseUrl;

    constructor(
        private authService: AuthService,
        private http: HttpClient) { }

    getPqrsOptions(): Observable<any> {
        return this.http.get(
          this.url + '/v1/acropolis/pqrs/options?is_adenupa=true'
        );
      }
    
    getTicketById(id): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.get(this.url + '/v1/acropolis/pqrs/' + id, { headers });
    }

    setPqrs(form): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        return this.http.post(
            this.url + '/v1/acropolis/pqrs?is_adenupa=true',
            form,
            { headers }
        );
    }
}