import { UntypedFormControl } from '@angular/forms';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { SpecViewComponent } from 'src/app/pages/spec-view/spec-view.component';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnDestroy, OnInit {
  miCursadoToggle = new UntypedFormControl(false);
  ceadenToggle = new UntypedFormControl(false);
  adenpolisToggle = new UntypedFormControl(false);
  gradToggle = new UntypedFormControl(false);
  showLabels = new UntypedFormControl(false);
  hoverControl = new UntypedFormControl(false);
  isSpecMode = false;
  isAdmin = false;
  isToggle = false;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  /* openSidenav = new FormControl(false); */
  @Input() expandedSidenav = new UntypedFormControl(false);

  openSidenav = new UntypedFormControl(false);

  @Output() sidenavIsOpen = new EventEmitter<boolean>();

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private specViewService: SpecViewService) {
      this.mobileQuery = media.matchMedia('(max-width: 1024px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    }

  ngOnInit(): void {
    this.closeSidenav = this.closeSidenav.bind(this);
    this.specChange();
    this.isAdmin = localStorage.getItem('rol') === 'admin';
    this.sidenavIsOpen.emit(true);
    this.openSidenav.setValue(true);
  }

  toggleSpec(){
    if(this.isSpecMode){
      this.specViewService.changeSpecMode({})
      this.router.navigate(['/home'])
    }else{
      this.router.navigate(['/spec-view'])
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  toggleSidenav() {
    if (this.expandedSidenav.value || this.openSidenav.value) {
      this.openSidenav.setValue(false);
      this.sidenavIsOpen.emit(false);
    } else {
      this.openSidenav.setValue(true);
      this.sidenavIsOpen.emit(true);
    }
  }

  toggleDropdowns() {
    if (this.miCursadoToggle.value) {
      this.miCursadoToggle.setValue(false);
    }
    if (this.ceadenToggle.value) {
      this.ceadenToggle.setValue(false);
    }
    if (this.adenpolisToggle.value) {
      this.adenpolisToggle.setValue(false);
    }
    if (this.gradToggle.value) {
      this.gradToggle.setValue(false);
    }
  }

  closeSidenav() {
    if (
      this.mobileQuery.matches &&
      (this.expandedSidenav.value || this.openSidenav.value)
    ) {
      this.openSidenav.setValue(false);
      this.sidenavIsOpen.emit(false);
    }
  }

  specChange(){
   this.specViewService.currentSharedSpecMode.subscribe(data => this.isSpecMode = data.isSpecMode)  
  }

  goToEmpleo() {
    document.forms['job-form'].submit();
  }
}
