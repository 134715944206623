import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ISpec } from '../interfaces/ISpec';
import { environment } from 'src/environments/environment';
import { SpecViewService } from './spec-view.service';

@Injectable({providedIn: 'root'})
export class AsignaturaService {

    specView: ISpec;
    url = environment.baseUrl;


    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private specViewService: SpecViewService
        ) { this.getSpecView(); }
        
    getSpecView(){
        this.specViewService.currentSharedSpecMode.subscribe(data => this.specView = data)
    }

    getAsignaturas(): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        if(this.specView.isSpecMode){
          return this.http.get(this.url + '/educat/asignaturas?student_sis_id=' + this.specView.sis_id, { headers });
        }
        return this.http.get(this.url + '/educat/asignaturas', { headers });
    }

    cancelarAsignatura(
        pensum_id,
        career_id,
        periodo_id,
        pensum_replace_id = null,
        tipo_credito_id = null
      ): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    
        const data = new FormData();
        data.append('pensum_id', pensum_id);
        data.append('career_id', career_id);
        data.append('batch_id', periodo_id);
        if (pensum_replace_id) {
          data.append('pensum_replace_id', pensum_replace_id);
        }
        if (tipo_credito_id) {
          data.append('tipo_credito_id', tipo_credito_id);
        }
    
        return this.http.put(this.url + '/educat/cancelar_asignatura', data, {
          headers,
        });
    }

    retirarAsignatura(
        pensum_id,
        career_id,
        periodo_id,
        pensum_replace_id = null,
        tipo_credito_id = null
      ): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    
        const data = new FormData();
        data.append('pensum_id', pensum_id);
        data.append('career_id', career_id);
        data.append('batch_id', periodo_id);
        if (pensum_replace_id) {
          data.append('pensum_replace_id', pensum_replace_id);
        }
        if (tipo_credito_id) {
          data.append('tipo_credito_id', tipo_credito_id);
        }
    
        return this.http.put(this.url + '/educat/retirar_asignatura', data, {
          headers,
        });
    }
    
    getCarreras(): Observable<any> {
      return this.http.get(this.url + '/educat/carrera');
    }
  
    getAsignaturasByCarrera(id): Observable<any> {
      return this.http.get(this.url + `/educat/carrera/${id}/asignaturas`);
    }
}
