import { NavigationService } from './../../../services/navigation.service';
import { Component, Input, OnInit } from '@angular/core';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string;
  isSpecMode = false;
  specUserName = '';
  specUserPhoto = ''; 
  constructor(
    private navigation: NavigationService,
    private specViewService: SpecViewService,
    private dataService: DataService) {}

  back(): void {
    this.navigation.goBack();
  }

  ngOnInit() {
    this.specViewService.currentSharedSpecMode.subscribe(data => {
        this.isSpecMode = data.isSpecMode;
        this.specUserName  = data.name;
        this.specUserPhoto = data.photo;
    })
  }
}
