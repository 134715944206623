import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-product-counter',
  templateUrl: './product-counter.component.html',
  styleUrls: ['./product-counter.component.scss'],
})
export class ProductCounterComponent {
  @Input() count: number;
  @Input() max: number;

  @Output() countChange: EventEmitter<number> = new EventEmitter<number>();

  onAdd() {
    this.count++;
    this.countChange.emit(this.count);
  }

  onRemove() {
    this.count--;
    this.countChange.emit(this.count);
  }
}
