import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card-curso-abierto',
  templateUrl: './card-curso-abierto.component.html',
  styleUrls: ['./card-curso-abierto.component.scss'],
})
export class CardCursoAbiertoComponent {
  @Input() curso;
  cmsUrl = environment.cmsUrl;

  constructor() {}

  goTo(link) {
    window.open(link, '_blank');
  }
}
