import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { MatriculacionesService } from 'src/app/shared/services/matriculaciones.service';

@Component({
  selector: 'app-item-pecuniario-card',
  templateUrl: './item-pecuniario-card.component.html',
  styleUrls: ['./item-pecuniario-card.component.scss'],
})
export class ItemPecuniarioCardComponent {
  @Input() item;
  @Input() addProduct: (product, quantity, certificacion) => void;
  @Input() updateProduct: (product, quantity) => void;
  @Input() removeProduct: (product) => void;
  @Input() carrera;
  @Input() cartItem;
  isSomeSelected = false;
  showAlert = false;
  certificateItem = [
    {
      type: 'f',
      label: 'Certificado Físico',
      checked: false,
    },
    {
      type: 'd',
      label: 'Certificado Digital',
      checked: false,
    },
    {
      type: 'a',
      label: 'Ambos',
      checked: false,
    },
  ];

  constructor(
    private matricService: MatriculacionesService,
    private cdr: ChangeDetectorRef
  ) {
    matricService.updateDisabled(false);
  }

  remove() {
    if (this.cartItem.quantity === 1) {
      this.removeProduct(this.cartItem);
    } else {
      this.updateProduct(this.cartItem, this.cartItem.quantity - 1);
    }
  }

  addToCart(certificationChange) {
    if (this.isSomeSelected) {
      this.addProduct(
        {
          ...this.item,
          carrera: `${this.carrera.carrera} | ${this.carrera.resolucion} | Modalidad ${this.carrera.modalidad}`,
          carrera_id: this.carrera.id,
        },
        1,
        certificationChange
      );
    } else {
      this.showAlert = true;
    }
  }

  changeCertificate(type: string) {
    const selectedItem = this.certificateItem.find((item) => item.type === type);
    if (selectedItem && !selectedItem.checked) {
      this.certificateItem.forEach((item) => {
        item.checked = false;
      });
      selectedItem.checked = true;
      this.cdr.detectChanges();
      this.item.tipo_certificado = type;
      this.addToCart(true);
      this.matricService.updateDisabled(true);
      this.showAlert = false;
    }else{
      selectedItem.checked = false;
      if(this.cartItem){
        this.removeProduct(this.cartItem)
      }
    }
    this.isSomeSelected = this.certificateItem.some((item) => item.checked);
  }
}