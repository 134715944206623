import { Component, Inject, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Oportunidad } from 'src/app/shared/interfaces/IEstadoFinanciero';
import { Product } from 'src/app/shared/interfaces/IProduct';


@Component({
  selector: 'app-payment-resume',
  templateUrl: './payment-resume.component.html',
  styleUrls: ['./payment-resume.component.scss']
})

export class PaymentResumeComponent implements OnInit {

  moment;
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentResumeComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    this.moment = moment;
  }

  onClose(){
    this.dialogRef.close();
  }

    fixTotal(price:number){
    return (Math.round(price * 100) / 100).toFixed(2)
  }

}
