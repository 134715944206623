export const  statusMappings = {
    'present': 'Presente',
    'studying': 'Cursando',
    'n/a': 'No asistió',
    's': 'Examen de suficiencia',
    'h': 'Homologación',
    'c': 'Convalidación',
    'p': 'Pendiente',
    'r': 'Recursar',
    'inc': 'INC',
    'pre': 'PRE',
    'preconv_fallida': 'Preconvalidación fallida',
    'confirmed': 'Inscripto - Confirmado',
    'inactive': 'Traspaso por inactividad',
    'incomplete': 'Traspaso por incompleto',
    'disaproved': 'Traspaso por desaprobado',
    'student-retired': 'Baja estudiantil',
    'academic-retired': 'Baja académica',
    'default': 'Sin datos',
  };