import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserState } from './../../../redux/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { CmsService } from './../../../shared/services/cms.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import moment from 'moment';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-elecciones-adenpolis',
  templateUrl: './elecciones-adenpolis.component.html',
  styleUrls: ['./elecciones-adenpolis.component.scss'],
})
export class EleccionesAdenpolisComponent implements OnInit {
  navLinks = [
    { name: 'Reglas', tag: 'reglas' },
    { name: 'Votación', tag: 'votacion' },
  ];
  activeLink = this.navLinks[0];

  public configs;
  public moment = moment;
  public currentElection;
  public nextElectionMsg;
  public campusUrl = environment.webUrl;

  public sendingVote = false;
  public alreadyVoted = false;
  public endedElections = false;
  public noCareerCandidates = false;

  public candidates;
  public careerCandidates;
  public winnersByHouse = null;
  public groupedWinners;
  selectedCandidate = null;

  links = [];
  public user$;
  public user;

  constructor(
    private location: Location,
    private cmsService: CmsService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    private store: Store<UserState>,
    private dialog: MatDialog
  ) {
    this.user$ = store.select('user');
  }

  ngOnInit(): void {
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
        this.alreadyVoted = user.votacion_comite;
        this.getAllCandidates();
        this.getConfigs();
      }
    });
  }
  back(): void {
    this.location.back();
  }

  getConfigs() {
    this.cmsService.getComiteConfig().subscribe((res) => {
      this.configs = res.data.attributes;
      console.log(this.configs)
      const today = moment();

      if (
        today.isSameOrAfter(this.configs.inicio_votaciones) &&
        today.isSameOrBefore(this.configs.fin_votaciones)
      ) {
        this.currentElection = true;
      } else if (today.isAfter(this.configs.fin_votaciones)) {
        this.currentElection = false;
        this.endedElections = true;
      }

      if (this.configs.mostrar_candidatos) {
        this.navLinks = [
          { name: 'Candidatos', tag: 'candidatos' },
          { name: 'Reglas', tag: 'reglas' },
          { name: 'Votación', tag: 'votacion' },
        ];
        this.activeLink = this.navLinks[0];
      }

      if (this.configs.mostrar_seleccionados) {
        this.getWinners();
        this.navLinks.push({
          name: `Seleccionados ${this.configs.year}`,
          tag: 'seleccionados',
        });
      }
    });
  }

  getAllCandidates() {
    this.sendingVote = true;
    this.cmsService
      .getAllCandidates(this.user.carreras[0].modalidad)
      .subscribe((res) => {
        this.candidates = res.data;

        if (
          res.data.some(
            (item) =>
              item.attributes.career.toLowerCase() ===
              this.user.carreras[0].carrera.toLowerCase()
          )
        ) {
          this.noCareerCandidates = false;
          this.sendingVote = false;
          this.careerCandidates = res.data.filter(
            (item) => 
              (item.attributes.modality.toLowerCase() === this.user.carreras[0].modalidad.toLowerCase()) && 
              (item.attributes.career.toLowerCase() === this.user.carreras[0].carrera.toLowerCase())
          );
          if(!this.careerCandidates.length) {
            this.careerCandidates = res.data.filter(
              (item) => 
                (item.attributes.modality.toLowerCase() === this.user.carreras[0].modalidad.toLowerCase()) && 
                (item.attributes.adenpolis_house.toLowerCase() === this.user.carreras[0].casa.toLowerCase())
            );
          }
        } else {
          this.noCareerCandidates = true;
          this.sendingVote = false;
        }

        this.groupedWinners = res.data.reduce((group, item) => {
          const { career } = item.attributes;
          group[career] = group[career] ?? [];
          group[career].push(item);
          return group;
        }, {});
      });
  }

  getWinners() {
    this.cmsService
      .getWinnersByModality(this.user.carreras[0].modalidad)
      .subscribe((res) => {
        if (res.data.length > 0) {
          this.winnersByHouse = res.data.reduce((group, item) => {
            const { adenpolis_house } = item.attributes;
            group[adenpolis_house] = group[adenpolis_house] ?? [];
            group[adenpolis_house].push(item);
            return group;
          }, {});
        }
        console.log(this.winnersByHouse);
      });
  }

  selectCandidate(candidate) {
    if (
      this.selectedCandidate &&
      candidate.attributes.sis_id === this.selectedCandidate.attributes.sis_id
    ) {
      this.selectedCandidate = null;
    } else {
      this.selectedCandidate = candidate;
    }
  }

  goToSeleccionados() {
    const section = document.getElementById('seleccionados');
    section.scrollIntoView();
  }

  getCalendarEvent() {
    const eventCalendar = {
      title: `[CAMPUS ADENU] ¡Elecciones Comité Estudiantil ${this.configs.year}!`,
      description: `¡Elije al lider de tu carrera! Accede a <a href="${this.campusUrl}/comite-estudiantil/elecciones">${this.campusUrl}/comite-estudiantil/elecciones</a> y realiza la votación`,
      start: this.configs.inicio_votaciones,
      end: this.configs.fin_votaciones,
      url: `${this.campusUrl}/comite-estudiantil/elecciones`,
      allDay: true,
    };

    this.links = [
      {
        name: 'Google Calendar',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          google(eventCalendar)
        ),
      },
      {
        name: 'Outlook',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          outlook(eventCalendar)
        ),
      },
      {
        name: 'Office 365',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          office365(eventCalendar)
        ),
      },
      {
        name: 'Yahoo',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(
          yahoo(eventCalendar)
        ),
      },
      {
        name: 'iCal',
        url: this.sanitizer.bypassSecurityTrustResourceUrl(ics(eventCalendar)),
      },
    ];
  }

  openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: '¡Confirma tu elección!',
        heading: `¿Estás seguro de votar a ${this.selectedCandidate.attributes.full_name} para ser representante de tu carrera?`,
        description:
          'Recuerda que una vez confirmado no podrás modificar ni anular tu voto',
        confirmAction: 'Sí, confirmar',
        cancelAction: 'Cancelar',
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        this.sendVote();
      }
    });
  }

  sendVote() {
    this.sendingVote = true;
    this.cmsService.voteCandidate(this.selectedCandidate.id).subscribe({
      next: (res) => {
        if (!res.hasOwnProperty('error')) {
          this.cmsService.updateVoteState(true).subscribe((res) => {
            this.sendingVote = false;
            this.alreadyVoted = true;
          });
        } else {
          this.throwError();
        }
      },
      error: (err) => {
        this.throwError();
      },
    });
  }

  throwError() {
    this.alertService.openAlert(
      'Ups.. no se registró tu voto',
      'Ocurrió un problema a la hora de registrar tu votación, por favor, inténtalo de nuevo más tarde',
      'Cerrar'
    );
  }
}
