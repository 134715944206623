<div class="dialog-header">
  <img *ngIf="data.img && data.img.length" [src]="data.img" alt="">
  <mat-icon class="material-icons-round bounce success" *ngIf="data.type === 'success'">{{data.icon}}</mat-icon>
  <mat-icon class="material-icons-round shake error" *ngIf="data.type === 'error'">{{data.icon}}</mat-icon>
  <div mat-dialog-title>
    <h1>{{ data.title }}</h1>
  </div>
</div>
<div class="dialog-container">
  <div mat-dialog-content [innerHtml]="data.description | html"></div>
  <div mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      (click)="onClose()"
    >
      {{ data.action }}
    </button>
  </div>
</div>
