<div class="sticky-header">
  <app-image-header
    [title]="'Pasantía de Responsabilidad Social Comunitaria'"
    [imgURL]="'/images/content/graduacion/header-pasantia-social.png'"
    [titleColor]="'#b31d15'"
    [descriptionColor]="'#222222'"
    [buttonColor]="'#b31d15'"
  ></app-image-header>
  <app-onepage-nav
    [links]="links"
    [activeLink]="activeLink"
    [urlBase]="'graduacion/pasantia-responsabilidad-social'"
  ></app-onepage-nav>
</div>
<div class="white-bg">
  <section id="descripcion" class="container two-cols">
    <div class="body">
      <div class="content">
        <h1 class="small-title">¿De qué se trata?</h1>
        <p>
          La Pasantía de Responsabilidad Social Comunitaria es el Servicio
          Comunitario enmarcado en la metodología pedagógica del Aprendizaje
          Servicio donde la fundamentación epistemológica de la Investigación
          Acción Participativa, el método INVEDECOR y la Psicología Comunitaria
          constituyen un paradigma del quehacer educativo de la Universidad que
          nos permiten integrar los conocimientos del aula a las necesidades
          reales de la comunidad a través de Proyectos Sociales, asumidos por
          los estudiantes en sus etapas o fases: Diagnóstico, Planificación,
          Ejecución, Resultados y Evaluación, buscando dar respuestas a la
          comunidad donde se involucren desde los puntos de vista de la
          Investigación Social o Intervención Social: “APRENDER INVESTIGANDO,
          CONOCER TRANSFORMANDO”.
        </p>
      </div>
      <div class="image">
        <img
          src="../../../../assets/images/content/graduacion/pasantia-social.png"
          alt="Pasantía de Responsabilidad Social Comunitaria"
          class="shadow main-radius"
        />
      </div>
    </div>
  </section>
</div>
<section id="objetivos" class="container two-cols">
  <div class="body">
    <div class="content top">
      <h1 class="small-title">Objetivos generales</h1>
      <p>
        Al terminar el período académico los estudiantes habrán realizado el
        Servicio Comunitario, aplicando los conocimientos adquiridos durante su
        formación en la carrera y de la metodología aprendizaje servicio, como
        parte de la solución de los problemas donde realicen su inserción
        comunitaria.
      </p>
    </div>
    <div class="content top">
      <h1 class="small-title">Objetivos específicos</h1>
      <ul>
        <li>
          Definir los principios del servicio comunitario con los conocimientos
          y vivencias, de su proceso formativo en beneficio de las comunidades.
        </li>
        <li>
          Participar con las organizaciones comunitarias en la planificación,
          ejecución, contraloría de los proyectos que impulsen el desarrollo
          endógeno y el establecimiento de redes comunitarias.
        </li>
        <li>
          Utilizar las herramientas metodológicas del Aprendizaje Servicio para
          diagnosticar, sistematizar, analizar, evaluar, comunicar, y diseñar
          proyectos de acuerdo a las necesidades reales de las comunidades
        </li>
      </ul>
    </div>
  </div>
</section>
<section
  class="parallax"
  [ngStyle]="{
    'background-image':
      'url(https://i.postimg.cc/q7mjx9CJ/bg-pasantia-social.jpg)'
  }"
></section>
<div class="white-bg">
  <section id="etapas-y-contenidos" class="container">
    <div class="center-body left small-bottom">
      <h1>Etapas y contenidos</h1>
      <img
        class="full-width"
        src="https://i.postimg.cc/SNwQZTtQ/gantt-pract-social.png"
        alt="Gantt Práctica Social"
      />
    </div>
  </section>

  <section id="faq" class="container">
    <div class="center-body left small-bottom">
      <h1>Preguntas Frecuentes</h1>
      <mat-accordion>
        <ng-container *ngFor="let item of faqs">
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ item.q }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ item.a }}</p>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </section>
</div>
<section id="consultas">
  <div class="container">
    <div class="grid-container">
      <div class="content">
        <h2>¿Te has quedado con dudas?</h2>
        <p>
          Envíanos tu consulta y en breve será respondida a tu correo
          electrónico
        </p>
        <form class="form-contact">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Escribe tu consulta</mat-label>
            <textarea
              matInput
              #message
              maxlength="800"
              [formControl]="messageCtrl"
              required
            ></textarea>
            <mat-error *ngIf="messageCtrl.hasError('required')">
              Este campo es <strong>obligatorio</strong>
            </mat-error>
            <mat-hint align="end"
              >{{ message.value?.length || 0 }}/800
            </mat-hint>
          </mat-form-field>

          <button
            mat-flat-button
            color="primary"
            [disabled]="messageCtrl.hasError('required') || sendingMessage"
            (click)="sendMessage()"
          >
            <div class="submit-button">
              {{ sendingMessage ? "Enviando" : "Enviar consulta" }}
              <div class="small-loader" *ngIf="sendingMessage"></div>
            </div>
          </button>
        </form>
      </div>
      <div class="image">
        <img
          src="../../../../assets/images/content/adenpolis/dudas.svg"
          alt="Ayuda"
        />
      </div>
    </div>
  </div>
</section>
