<div class="dialog-header">
  <div mat-dialog-title>
    <h1>¡Link de pago generado con éxito!</h1>
  </div>
  <mat-divider></mat-divider>
</div>
<div class="dialog-container">
  <div mat-dialog-content>
    <div class="text-muted">
      <b>Pago #{{ data.nro_pago }}</b> <br />
      Recuerda que las asignaturas adquiridas se acreditarán de 5 a 30 minutos
      después de haber efectuado el pago. Ante cualquier duda, puedes
      comunicarte vía mail a
      <a href="mailto:cobros@adenuniversity.edu.pa"
        >cobros@adenuniversity.edu.pa</a
      >
      <b
        >Si no se abrió la ventana emergente, prueba copiar el link y pegarlo en
        una nueva pestaña de tu navegador.</b
      >
    </div>
    <div class="link-wrapper">
      <div class="link-div">
        <input type="text" disabled [value]="data.link" />
        <button
          mat-button
          color="primary"
          [cdkCopyToClipboard]="data.link"
          (click)="copyLink()"
        >
          Copiar
        </button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      class="bg-primary-aden text-white close-btn"
      (click)="onClose()"
    >
      Cerrar
    </button>
  </div>
</div>
