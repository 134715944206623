<div class="sticky-header"><app-header [title]="title"></app-header></div>
<div class="container flex">
  <app-creditos-academicos></app-creditos-academicos>

  <mat-tab-group *ngIf="carreras != []" mat-align-tabs="start" class="main-shadow">
    <mat-tab
      *ngFor="let carrera of carreras; let index = index"
      [label]="carrera.career_name"
    >
      <div class="content">
        <h1>Tu progreso</h1>
        <div class="progress-grid">
          <div class="progress-column">
            <div class="circle-progress">
              <div class="current-value">
                <h1>{{ carrera.career_status.progreso }}%</h1>
                <p>
                  <strong>{{ carrera.career_status.aprobadas }}</strong
                  >/{{ carrera.career_status.total }}
                  ASIGNATURAS
                </p>
              </div>
              <round-progress
                [current]="carrera.career_status.progreso"
                [max]="100"
                [color]="'url(#gradient)'"
                [gradient]="true"
                [background]="'#f1f1f1'"
                [radius]="100"
                [stroke]="20"
                [semicircle]="true"
                [rounded]="true"
                [clockwise]="true"
                [responsive]="true"
                [duration]="800"
                [animation]="'easeInOutQuart'"
                [animationDelay]="0"
              ></round-progress>

              <svg style="position: absolute">
                <!-- <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="0%"  stop-color="#5a0030" />
              <stop offset="20%"  stop-color="#b31d15" />
              <stop offset="100%" stop-color="#fe66ac" />
            </linearGradient> -->
                <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                  <!-- <stop offset="0%" stop-color="#26A682" /> -->
                  <stop offset="0%" stop-color="#35D0A5" />
                  <stop offset="100%" stop-color="#59D9B5" />
                </linearGradient>
              </svg>
            </div>
          </div>

          <div class="progress-container" *ngIf="carrera.career_status">
            <app-data-display-card
              [type]="'icon'"
              [icon]="'menu_book'"
              [value]="carrera.career_status.cursando"
              [label]="'Cursando'"
            ></app-data-display-card>
            <app-data-display-card
              [type]="'icon'"
              [icon]="'schedule'"
              [value]="carrera.career_status.pendientes"
              [label]="'Pendientes'"
            ></app-data-display-card>
            <app-data-display-card
              [type]="'icon'"
              [icon]="'done'"
              [value]="carrera.career_status.aprobadas"
              [label]="'Aprobadas'"
            ></app-data-display-card>
            <app-data-display-card
              [type]="'icon'"
              [icon]="'close'"
              [value]="carrera.career_status.desaprobadas"
              [label]="'Desaprobadas'"
            ></app-data-display-card>
          </div>
        </div>
        <h1>Tus notas</h1>
        <div class="mat-row" *ngIf="isError && !isLoading">
          <div class="mat-cell" colspan="7">
            <div class="no-result">
              <img src="../../../../assets/images/content/sin-resultados.svg" alt="" />
              <div class="searched-value">Ups.. no encontramos tus notas.</div>
              <div class="text">
                ¡Intenta de nuevo más tarde o contacta a tu Academic Advisor para
                verificar el problema!
              </div>
              <button mat-flat-button color="primary" (click)="getNotas()">
                Volver a cargar
              </button>
            </div>
          </div>
        </div>
        <div class="mat-row" *ngIf="isLoading">
          <div class="mat-cell" colspan="7">
            <div class="no-result">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </div>
        <app-notas-table *ngIf="!isLoading && !isError" [notes]="carrera.career_lines"></app-notas-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
