import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ficha-inscripcion',
  templateUrl: './ficha-inscripcion.component.html',
  styleUrls: ['./ficha-inscripcion.component.scss'],
})
export class FichaInscripcionComponent implements OnInit {
  @Input() user: any;
  @Input() formData: any;
  @Input() filesArray: any;
  @Input() userType = 'alumno-saliente';

  userImage: string;

  constructor() {}

  ngOnInit(): void {
    if (this.user) {
      this.userImage = `data:image/png;base64,${this.user.foto64}`;
    }
  }
}
