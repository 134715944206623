<div class="card-body">
  <!-- <div class="graph">
        <img src="../../../../../assets/images/bg/patron-internacionalizacion-yellow.png" alt="">
    </div> -->
  <div class="index">
    {{ requisito.index }}
  </div>
  <div class="content">
    <div class="title">{{ requisito.title }}</div>
    <div class="descripcion">{{ requisito.description }}</div>
  </div>
</div>
