<div id="dialogo-reemplazo">
  <button class="close" mat-icon-button aria-label="Close" (click)="onClose()">
    <mat-icon class="material-icons-round">close</mat-icon>
  </button>
  <div class="dialog-container">
    <div mat-dialog-content>
      <h1>¡Atención!</h1>
      <h2>
        La asignatura <strong>{{ data.materiaCancelar.nombre }} </strong>es la
        única selección del periodo
        <span class="chip-periodo">{{ data.materiaCancelar.periodo }}</span>
      </h2>
      <p>
        {{
          data.modalidadSelected === "online"
            ? "Por esta razón, deberás elegir otra asignatura de este periodo u otro periodo no inscripto. El sistema se encargará de hacer el reemplazo automático."
            : "Por esta razón, deberás elegir otra asignatura de este periodo para poder continuar. El sistema se encargará de hacer el reemplazo automático."
        }}
        <!-- Por esta razón, deberás elegir otra asignatura de este periodo para poder continuar. El sistema se encargará de hacer el reemplazo automático. -->
      </p>

      <br />

      <div class="grid-asignaturas">
        <ng-container *ngFor="let item of data.asignaturas">
          <div
            class="card-asignatura"
            [ngStyle]="{
              'background-color':
                asignaturaSelected?.pensum_id === item.pensum_id
                  ? '#EDFFF9'
                  : '#ffffff',
              'box-shadow':
                asignaturaSelected?.pensum_id === item.pensum_id
                  ? '0px 8px 24px -4px rgba(24, 39, 75, 0.08),0px 6px 12px -6px rgba(24, 39, 75, 0.12)'
                  : 'none'
            }"
            (click)="setAsignaturaSelected(item)"
          >
            <div class="body">
              <p>{{ item.nombre }}</p>
              <div class="periodo">
                {{
                  data.modalidadSelected === "presencial"
                    ? "Cuatrimestre: "
                    : ""
                }}{{ item.periodo }}
              </div>
            </div>
            <mat-icon *ngIf="asignaturaSelected?.pensum_id === item.pensum_id"
              >check_circle</mat-icon
            >
          </div>
        </ng-container>
      </div>
    </div>
    <div mat-dialog-actions>
      <div class="button-container">
        <button mat-stroked-button (click)="onClose()">Cerrar</button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="!asignaturaSelected"
          [mat-dialog-close]="asignaturaSelected"
          cdkFocusInitial
        >
          Confirmar reemplazo
        </button>
      </div>
    </div>
  </div>
</div>
