import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public sharedMode = new ReplaySubject<string>(1);
  url = environment.baseUrl;


  constructor(
    private authService: AuthService,
    private http: HttpClient) {}

  updateMode(disabled: string) {
    this.sharedMode.next(disabled);
  }

  getNotifications(): Observable<any> {
    const token = this.authService.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/notificaciones', {
      headers,
    });
  }

  setViewNotification(notification_id): Observable<any> {
    console.log(notification_id)
    const token = this.authService.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.put(
      this.url + '/educat/notificacion_leida',
      notification_id,
      {
        headers,
      }
    );
  }

  deleteNotification(id: number){
    const token = this.authService.leerToken();
    const headers = new HttpHeaders({Authorization: 'Bearer ' + token})
    return this.http.delete(
      this.url + '/educat/notificacion_eliminada/' + id,
      {headers,}
    )
  }

  getNotificationCategories(): Observable<any> {
    const token = this.authService.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    return this.http.get(this.url + '/educat/categorias_notificaciones', {
      headers,
    });
  }
}
