<div class="sticky-header"><app-header [title]="'Anuncios'"></app-header></div>
<div class="container mt-5">
  <div class="notfound-container main-shadow mb-5" *ngIf="loadingAnuncios">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
  <div
    class="notfound-container main-shadow mb-5"
    *ngIf="!loadingAnuncios && anuncios.length === 0"
  >
    <img src="../../../../assets/images/content/Article.svg" alt="" />
    <h2>No pudimos encontrar este anuncio</h2>
    <p>
      Parece que el aviso que buscas ya no existe o tuvimos problemas para
      encontrarlo.
    </p>
    <div class="button-container">
      <button mat-stroked-button color="primary" routerLink="../">
        Volver
      </button>
      <button mat-flat-button color="primary" (click)="getAnuncios()">
        Reintentar
      </button>
    </div>
  </div>
  <div
    class="main-container main-shadow main-radius"
    *ngIf="!loadingAnuncios && anuncios.length > 0"
  >
    <div class="cards-grid">
      <ng-container *ngFor="let item of anuncios">
        <app-card-anuncio [post]="item"></app-card-anuncio>
      </ng-container>
    </div>
  </div>
</div>
