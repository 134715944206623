import { DataService } from './data.service';
import { Injectable, Output } from '@angular/core';
import { Workbook } from 'exceljs';
import { saveAs, FileSaverOptions, ExportAsFixedFormat } from 'file-saver-es';
import 'convertapi-js';
import { AlertService } from './alerts.service';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  public isLoading = false;
  public file: File;

  constructor(private alertService: AlertService) {}

  setIsLoading(): void {
    this.isLoading = !this.isLoading;
  }

  getIsLoading(): boolean {
    return this.isLoading;
  }

  async exportExcel(excelData): Promise<any> {
    // Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers;
    const carrera = excelData.carrera;
    const estudiante = excelData.estudiante;
    const identificacion = excelData.identificacion;
    const data = excelData.data;
    const periodo = excelData.periodo;
    const promedioPonderado = excelData.promedioPonderado;
    const indiceAcumulado = excelData.indiceAcumulado;
    const resolucion = excelData.resolucion;

    // Create a workbook with a worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Data');

    const res = await fetch('https://i.postimg.cc/25dZrThx/adenupa-logo.png');
    const imgBuffer = await res.arrayBuffer();

    const imageItem = workbook.addImage({
      buffer: imgBuffer,
      extension: 'png',
    });

    worksheet.addImage(imageItem, 'A1:A2');

    // HEADER
    worksheet.mergeCells('B1:D2');
    const titleHeader = worksheet.getCell('B1');
    titleHeader.value = title;
    titleHeader.font = {
      name: 'Calibri',
      size: 14,
      bold: true,
      color: { argb: '616161' },
    };
    titleHeader.alignment = { vertical: 'middle', horizontal: 'center' };

    // Date
    worksheet.mergeCells('E1:F1');
    worksheet.mergeCells('E2:F2');
    const d = new Date();
    const date = d.toISOString().slice(0, 10);
    const cleanDate = date.replace('-', '').replace('-', '');

    const dateCellHeader = worksheet.getCell('E1');
    dateCellHeader.value = 'Fecha';
    dateCellHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      color: { argb: '222222' },
    };
    dateCellHeader.alignment = { vertical: 'middle', horizontal: 'center' };
    dateCellHeader.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const dateCell = worksheet.getCell('E2');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
    };
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' };
    dateCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    // Blank Row
    worksheet.addRow([]);

    // Carrera
    worksheet.mergeCells('B4:F4');
    const carreraHeader = worksheet.getCell('A4');
    carreraHeader.value = 'Carrera';
    carreraHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      color: { argb: '222222' },
    };
    carreraHeader.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e5e5e5' },
      bgColor: { argb: '' },
    };
    carreraHeader.alignment = { vertical: 'middle', horizontal: 'center' };
    carreraHeader.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const carreraRow = worksheet.getCell('B4');
    carreraRow.value = carrera;
    carreraRow.font = {
      name: 'Calibri',
      size: 10,
      color: { argb: '222222' },
    };
    carreraRow.alignment = { vertical: 'middle', horizontal: 'left' };
    carreraRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    // Estudiante
    worksheet.mergeCells('B5:F5');
    const estudianteHeader = worksheet.getCell('A5');
    estudianteHeader.value = 'Estudiante';
    estudianteHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      color: { argb: '222222' },
    };
    estudianteHeader.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e5e5e5' },
      bgColor: { argb: '' },
    };
    estudianteHeader.border = {};
    estudianteHeader.alignment = { vertical: 'middle', horizontal: 'center' };
    estudianteHeader.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const estudianteRow = worksheet.getCell('B5');
    estudianteRow.value = estudiante;
    estudianteRow.font = {
      name: 'Calibri',
      size: 10,
      color: { argb: '222222' },
    };
    estudianteRow.alignment = { vertical: 'middle', horizontal: 'left' };
    estudianteRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    // Identificación
    worksheet.mergeCells('B6:F6');
    const identificacionHeader = worksheet.getCell('A6');
    identificacionHeader.value = 'Identificación No.';
    identificacionHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      color: { argb: '222222' },
    };
    identificacionHeader.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e5e5e5' },
      bgColor: { argb: '' },
    };
    identificacionHeader.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    identificacionHeader.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const identificacionRow = worksheet.getCell('B6');
    identificacionRow.value = identificacion;
    identificacionRow.font = {
      name: 'Calibri',
      size: 10,
      color: { argb: '222222' },
    };
    identificacionRow.alignment = { vertical: 'middle', horizontal: 'left' };
    identificacionRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    // Periodo
    worksheet.mergeCells('B7:F7');
    const periodoHeader = worksheet.getCell('A7');
    periodoHeader.value = 'Periodo de estudios';
    periodoHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      color: { argb: '222222' },
    };
    periodoHeader.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e5e5e5' },
      bgColor: { argb: '' },
    };
    periodoHeader.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    periodoHeader.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const periodoRow = worksheet.getCell('B7');
    periodoRow.value = periodo === null ? '-' : periodo;
    periodoRow.font = {
      name: 'Calibri',
      size: 10,
      color: { argb: '222222' },
    };
    periodoRow.alignment = { vertical: 'middle', horizontal: 'left' };
    periodoRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    worksheet.mergeCells('B8:F8');
    const resolucionHeader = worksheet.getCell('A8');
    resolucionHeader.value = 'Resolución';
    resolucionHeader.font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      color: { argb: '222222' },
    };
    resolucionHeader.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e5e5e5' },
      bgColor: { argb: '' },
    };
    resolucionHeader.alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    resolucionHeader.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const resolucionRow = worksheet.getCell('B8');
    resolucionRow.value = resolucion;
    resolucionRow.font = {
      name: 'Calibri',
      size: 10,
      color: { argb: '222222' },
    };
    resolucionRow.alignment = { vertical: 'middle', horizontal: 'left' };
    resolucionRow.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    worksheet.addRow([]);

    // Adding Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'e5e5e5' },
        bgColor: { argb: '' },
      };
      cell.font = {
        bold: true,
        color: { argb: '222222' },
        size: 10,
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    // Adding Data with Conditional Formatting
    data.forEach((rowData) => {
      const row = worksheet.addRow(rowData);
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.alignment = { vertical: 'middle', wrapText: true };
      });
    });

    // Notas

    const detalle1row = worksheet.addRow([
      'APR = Aprobado;  REC = Examen Integral de Recuperación;  PROM = Promovido',
    ]);
    detalle1row.font = {
      bold: true,
      color: { argb: '222222' },
      size: 9,
    };

    worksheet.mergeCells(`A${detalle1row.number}:F${detalle1row.number}`);
    const detalle2row = worksheet.addRow([
      'REPR = Reprobado;  NSP = No se presento;  CONV = Convalidación;  INC = Incompleto',
    ]);
    detalle2row.font = {
      bold: true,
      color: { argb: '222222' },
      size: 9,
    };

    worksheet.mergeCells(`A${detalle2row.number}:F${detalle2row.number}`);

    worksheet.addRow([]);

    // Promedio Ponderado

    const promedioRow = worksheet.addRow([
      'Promedio ponderado ',
      promedioPonderado.toFixed(2),
    ]);

    worksheet.mergeCells(`B${promedioRow.number}:F${promedioRow.number}`);

    promedioRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e5e5e5' },
      bgColor: { argb: '' },
    };

    promedioRow.getCell(1).font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      color: { argb: '222222' },
    };
    promedioRow.getCell(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    promedioRow.getCell(1).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    promedioRow.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      color: { argb: '222222' },
    };
    promedioRow.getCell(2).alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };
    promedioRow.getCell(2).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    // Indice Acumulado

    const indiceAcumuladoRow = worksheet.addRow([
      'Índice Acumulado ',
      indiceAcumulado.toFixed(2),
    ]);

    worksheet.mergeCells(
      `B${indiceAcumuladoRow.number}:F${indiceAcumuladoRow.number}`
    );

    indiceAcumuladoRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e5e5e5' },
      bgColor: { argb: '' },
    };

    indiceAcumuladoRow.getCell(1).font = {
      name: 'Calibri',
      size: 10,
      bold: true,
      color: { argb: '222222' },
    };
    indiceAcumuladoRow.getCell(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    indiceAcumuladoRow.getCell(1).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    indiceAcumuladoRow.getCell(2).font = {
      name: 'Calibri',
      size: 10,
      color: { argb: '222222' },
    };
    indiceAcumuladoRow.getCell(2).alignment = {
      vertical: 'middle',
      horizontal: 'left',
    };
    indiceAcumuladoRow.getCell(2).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    worksheet.addRow([]);

    // Footer Row
    const footerRow = worksheet.addRow([
      'Reporte generado por ADEN University Panamá el día ' + date,
    ]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '6181FF' },
    };

    footerRow.getCell(1).font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 11,
    };

    footerRow.height = 30;

    footerRow.alignment = { vertical: 'middle', horizontal: 'center' };

    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    // Nota

    const notaValidezRow = worksheet.addRow(['Documento no oficial']);
    worksheet.mergeCells(`A${notaValidezRow.number}:F${notaValidezRow.number}`);
    notaValidezRow.getCell(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
    notaValidezRow.getCell(1).font = {
      bold: true,
      color: { argb: '222222' },
      size: 9,
    };
    notaValidezRow.height = 30;

    worksheet.pageSetup.printArea = `A1:F${notaValidezRow.number}`;
    worksheet.pageSetup.paperSize = 9;
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.6,
      top: 0.5,
      bottom: 0.5,
      header: 0,
      footer: 0,
    };

    // Estilos generales

    worksheet.getColumn(1).width = 25;
    worksheet.getColumn(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    worksheet.getColumn(1).width = 17.86;
    worksheet.getColumn(2).width = 40;
    worksheet.getColumn(3).width = 6;
    worksheet.getColumn(4).width = 7.71;
    worksheet.getColumn(5).width = 6;
    worksheet.getColumn(6).width = 7.71;
    worksheet.getRow(1).height = 20;
    worksheet.getRow(2).height = 20;

    // Watermark

    const res2 = await fetch('https://i.postimg.cc/1ty0SKWw/watermark8.png');
    const watermark = await res2.arrayBuffer();

    const bgItem = workbook.addImage({
      buffer: watermark,
      extension: 'png',
    });

    worksheet.addImage(bgItem, 'A1:F35');

    if (footerRow.number > 35) {
      worksheet.addImage(bgItem, 'A36:F71');
    }

    // Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((blobData) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const file = new File(
        [blob],
        title + ' - ' + resolucion + ' - Adenupa - ' + cleanDate + '.xlsx'
      );

      this.downloadAsPdf(file);
    });

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const file = await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const file = new File(
        [blob],
        title + ' - ' + resolucion + ' - Adenupa - ' + cleanDate + '.xlsx'
      );
      return file;
      /* this.downloadAsPdf(file); */
    });

    return file;
  }

  // eslint-disable-next-line
  async downloadAsPdf(file) {
    const convertApi = ConvertApi.auth({
      secret: 'y9hcYST6ILZHZoEX',
      apiKey: '828682127',
      token: '',
    });
    document.documentElement.style.cursor = 'wait';

    try {
      const params = convertApi.createParams();
      params.add('file', file);
      const result = await convertApi.convert('xlsx', 'pdf', params);

      const url = result.files[0].Url;

      window.location.href = url;
    } catch {
      this.alertService.openAlert(
        '¡Ups!',
        'Ha ocurrido un error al intentar descargar tus créditos, por favor, intenta de nuevo más tarde',
        'Ok'
      );
    } finally {
      document.documentElement.style.cursor = 'default';
    }
  }
}
