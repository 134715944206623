import { Component, Input } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-card-anuncio',
  templateUrl: './card-anuncio.component.html',
  styleUrls: ['./card-anuncio.component.scss'],
})
export class CardAnuncioComponent {
  @Input() post;
  public moment = moment;
  constructor() {}

  /* ngOnInit(): void {
  } */

  goTo(link) {
    window.open(link, '_blank');
  }
}
