<div class="sticky-header"><app-header [title]="'Anuncio'"></app-header></div>
<div class="container mt-5">
  <div class="notfound-container main-shadow mb-5" *ngIf="loadingMain">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
  <div
    class="notfound-container main-shadow mb-5"
    *ngIf="!loadingMain && (!post || errorMain)"
  >
    <img src="../../../../assets/images/content/Article.svg" alt="" />
    <h2>No pudimos encontrar este anuncio</h2>
    <p>
      Parece que el anuncio que buscas ya no existe o tuvimos problemas para
      encontrarlo.
    </p>
    <div class="button-container">
      <button mat-stroked-button color="primary" routerLink="../">
        Volver
      </button>
      <button mat-flat-button color="primary" (click)="getAnuncio()">
        Reintentar
      </button>
    </div>
  </div>
  <div class="main-grid mb-5" *ngIf="!loadingMain && !errorMain && post">
    <div id="post-body" class="main-shadow main-radius">
      <div class="date">
        {{ moment(post.attributes.publishedAt).locale("es").format("LLL") }}
      </div>
      <h1>
        {{ post.attributes.Title }}
      </h1>
      <h3>{{ post.attributes.Caption }}</h3>
      <!-- <markdown style="width: 100%" [data]="post.attributes.content"></markdown> -->
      <div
        id="post-content"
        [innerHTML]="post.attributes.content | markdown"
      ></div>
      <!-- <div id="post-content"></div> -->
    </div>
    <div id="scroll-sidebar">
      <!-- <app-eventos-banner
        class="banner evento"
        [chipTitle]="'¡Reserva la fecha!'"
        [title]="'Eventos ADENU'"
        [description]="'Chequea la nueva agenda de eventos'"
        [icon]="'event'"
        [link]="['/eventos']"
      ></app-eventos-banner> -->
      <div id="anuncios" class="main-shadow main-radius">
        <div class="header">
          <h2>También puede interesarte...</h2>
        </div>
        <div class="cards-grid">
          <ng-container *ngFor="let item of otherPosts">
            <app-card-anuncio [post]="item"></app-card-anuncio>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
