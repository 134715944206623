<div class="card-container shadow-200 main-border main-radius">
  <div class="card-content">
    <div class="header">
      <div class="chip indigo" *ngIf="product.type === 'asignatura'">
        {{ product.type }}
      </div>
      <div class="chip orange" *ngIf="product.type === 'práctica'">
        {{ product.type }}
      </div>
      <div class="chip green" *ngIf="product.type === 'opción de grado'">
        {{ product.type }}
      </div>
      <div class="price">
        <div class="unit">US$</div>
        <div class="number">{{ fixTotal() }}</div>
      </div>
    </div>
    <div class="product-title">{{ product.name }}</div>
  </div>
  <div class="card-actions">
    <app-product-counter
      [tourAnchor]="'seleccionaCantidad' + index"
      [count]="quantity"
      [max]="limitThisProduct"
      (countChange)="countChangeHandler($event)"
    ></app-product-counter>
    <button
      mat-flat-button
      color="primary"
      (click)="add()"
      [tourAnchor]="'agregarCarrito' + index"
      [disabled]="
        disableDifType() ||
        isInCartLimit(product, quantity) ||
        ((product.id === 2 ||
          product.id === 3 ||
          product.id === 4 ||
          product.id === 5 ||
          product.id === 6) &&
          !gradOpp) ||
        isGradOption(product) ||
        ((product.id === 1 || product.id === 7) && !regularOpp)
      "
    >
      Añadir
    </button>
  </div>
</div>
