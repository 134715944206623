<div class="container-bg">
  <div class="graph">
    <img
      src="../../../../../assets/images/bg/Patron-emprende-blanco-md.png"
      alt=""
    />
  </div>
  <div class="container">
    <div class="banner-content">
      <div class="title">RETO</div>
      <img src="../../../../../assets/images/logos/logo-aprende-emprende.png" />
      <div class="text">
        ¿Crees en tu idea? ¡ADENU también! Te presentamos el primer concurso
        donde podrás vivir una experiencia diferenciadora, retadora y divertida
        de aprendizaje.
      </div>

      <div class="button-container">
        <button
          mat-flat-button
          color="accent"
          [routerLink]="['./']"
          fragment="sobre"
        >
          Sobre el concurso
        </button>
        <button
          mat-flat-button
          color="accent"
          [routerLink]="['./']"
          fragment="premios"
        >
          Premios
        </button>
        <!-- <a
          mat-flat-button
          color="accent"
          href="reto-aprende-y-emprende#inscripcion"
          >Inscripción</a
        > -->
        <button
          mat-flat-button
          color="accent"
          [routerLink]="['./']"
          fragment="ganadores"
        >
          Ganadores Septiembre 2021
        </button>
      </div>
    </div>
  </div>
</div>
