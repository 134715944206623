import { CambiarAsignaturaDialogComponent } from './../cambiar-asignatura-dialog/cambiar-asignatura-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Asignatura } from 'src/app/shared/interfaces/IAsignatura';
import { CarreraMatricula } from 'src/app/shared/interfaces/ICarrera';
import { AsignaturaService } from 'src/app/shared/services/asignatura.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

@Component({
  selector: 'app-card-asignatura',
  templateUrl: './card-asignatura.component.html',
  styleUrls: ['./card-asignatura.component.scss'],
})
export class CardAsignaturaComponent implements OnInit{
  @Input() materia: any;
  @Input() course: CarreraMatricula;
  @Input() materiaPeriodo: number;
  @Input() career_id: number;
  @Input() periodos: any;
  @Input() isMastery: boolean;
  @Input() disabledToSelect: boolean;
  @Input() isThesis: boolean;
  @Input() masterySelected:boolean;
  @Input() thesisSelected: boolean;
  @Input() openDescription: (asignatura, carreraId, electivePensum) => void;
  @Input() removeMateria: (id, carreraId, periodo_id, tipo_credito_id) => void;
  @Input() addMateria: (
    result,
    carreraId,
    pensumElectiveId,
  ) => void;
  @Input() electiveSelected: boolean;
  @Input() electivePensum: any = '';
  @Input() getUser: () => void;
  @Input() index: number;
  @Input() modalidadSelected: string;

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private asignaturaService: AsignaturaService) {}

  manageOnlineCancel(): boolean {
   const asignaturasByThisPeriodo = this.course.asignaturas.filter(asignatura => asignatura.periodo_id === this.materiaPeriodo);
   if(asignaturasByThisPeriodo.length > 1 && this.course.carrera.modalidad === 'o'){
    return true
   }
   return false
  }

  ngOnInit(): void {
    console.log('Materia => ', this.materia);
    console.log('ASIGNATURA => ', this.materia.nombre, this.materia);
  }


  manageInscription(): void {
    if (
      this.materia.estado_alumno === 'Pre Inscripto' &&
      this.checkSelectionByPeriod()
    ) {
      this.openConfirmDialog(
        'Cancelar inscripción',
        '¿Estás seguro?',
        'Vas a cancelar tu inscripción a esta asignatura, una vez cancelada se reintegrará el crédito utilizado',
        'Si, continuar',
        'No, volver'
      );
    } else if (
      this.materia.estado_alumno === 'Inscripto' &&
      this.checkSelectionByPeriod()
    ) {
      this.openConfirmDialog(
        'Retirar asignatura',
        '¿Estás seguro?',
        'Vas a retirar esta asignatura de tu matrícula, una vez retirada se reintegrará el crédito utilizado',
        'Si, continuar',
        'No, volver'
      );
    } else if (
      this.materia.estado_alumno === 'Pre Inscripto' ||
      (this.materia.estado_alumno === 'Inscripto' &&
        !this.checkSelectionByPeriod())
    ) {
      this.openReplaceAsignatura();
    }
  }

  cancel(pensum_replace_id = null, tipo_credito_id = null): void {
    this.asignaturaService
      .cancelarAsignatura(
        this.materia.pensum_id,
        this.career_id,
        this.materia.periodo_id,
        pensum_replace_id,
        tipo_credito_id
      )
      .subscribe({
        next: (res) => {
          if (pensum_replace_id) {
            this.alertService.openAlert(
              'Asignatura reemplazada',
              'Las asignatura fue reemplazada con éxito',
              'Ok'
            );
          } else {
            this.alertService.openAlert(
              'Pre-Inscripción cancelada',
              'Tu pre-inscripción a la asignatura fue cancelada con éxito',
              'Ok'
            );
          }
          this.getUser();
        },
        error: (err) => {
          this.alertService.openAlert(
            '¡Ups!',
            'No pudimos cancelar la pre-inscripción a esta asignatura, intenta más tarde',
            'Ok'
          );
        },
      });
  }

  retire(pensum_replace_id = null, tipo_credito_id = null): void {
    this.asignaturaService
      .retirarAsignatura(
        this.materia.pensum_id,
        this.career_id,
        this.materia.periodo_id,
        pensum_replace_id,
        tipo_credito_id
      )
      .subscribe({
        next: (res) => {
          if (pensum_replace_id) {
            this.alertService.openAlert(
              'Asignatura reemplazada',
              'Las asignatura fue reemplazada con éxito',
              'Ok'
            );
          } else {
            this.alertService.openAlert(
              'Asignatura retirada',
              'Se retiró tu inscripción a la asignatura con éxito',
              'Ok'
            );
          }

          this.getUser();
        },
        error: (err) => {
          this.alertService.openAlert(
            '¡Ups!',
            'No pudimos retirar tu inscripción a esta asignatura, intenta más tarde',
            'Ok'
          );
        },
      });
  }

  checkAdd(): void {
    this.addMateria(
      this.materia,
      this.course.carrera.career_id,
      this.materia.tipo === 'to_elected' ||
        this.materia.tipo === 'to_elect_grade' ||
        this.materia.tipo === 'to_elected_english'
        ? this.electivePensum
        : ''
    );
    console.log('Thesis selected', this.thesisSelected);
    console.log('Mastery', this.masterySelected);
  }

  openConfirmDialog(
    title,
    heading,
    description,
    confirmAction,
    cancelAction
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: title,
        heading: heading,
        description: description,
        confirmAction: confirmAction,
        cancelAction: cancelAction,
      },
      panelClass: 'plain-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'ok') {
        if (this.materia.estado_alumno === 'Pre Inscripto') {
          this.cancel(result.pensum_id, result.tipo_credito);
        } else if (this.materia.estado_alumno === 'Inscripto') {
          this.retire();
        }
      }
    });
  }

  openReplaceAsignatura(): void {
    /* const asignaturasPeriodo = this.course.asignaturas.filter(
      (asignatura) =>
        (this.modalidadSelected === 'presencial' &&
          asignatura.periodo_id === this.materia.periodo_id &&
          (asignatura.estado_alumno === 'No Inscripto' ||
            asignatura.estado_alumno === 'Cancelado') &&
          asignatura.pensum_id != this.materia.pensum_id) ||
        (this.modalidadSelected === 'online' &&
          (asignatura.estado_alumno === 'No Inscripto' ||
            asignatura.estado_alumno === 'Cancelado') &&
          asignatura.pensum_id != this.materia.pensum_id)
    ); */

    const asignaturasPeriodo = this.course.asignaturas.filter(
      (asignatura) =>
        asignatura.periodo_id === this.materia.periodo_id &&
        (asignatura.estado_alumno === 'No Inscripto' ||
          asignatura.estado_alumno === 'Cancelado') &&
        asignatura.pensum_id != this.materia.pensum_id
    );

    const dialogRef = this.dialog.open(CambiarAsignaturaDialogComponent, {
      width: '600px',
      maxWidth: '90vw',
      data: {
        asignaturas: asignaturasPeriodo,
        materiaCancelar: this.materia,
        modalidadSelected: this.modalidadSelected,
        periodos: this.periodos,
      },
      panelClass: 'plain-alert',
    });


    dialogRef.afterClosed().subscribe((result: Asignatura) => {
      if (result) {
        console.log('Asignatura para reemplazo', result);
        if (this.materia.estado_alumno === 'Inscripto') {
          this.retire(result.pensum_id, result.tipo_credito_id);
        } else {
          this.cancel(result.pensum_id, result.tipo_credito_id);
        }
      }
    });
  }

  checkSelectionByPeriod(): boolean {
    return (
      this.course.asignaturas.filter(
        (asignatura) =>
          asignatura.periodo_id === this.materia.periodo_id &&
          (asignatura.estado_alumno === 'Inscripto' ||
            asignatura.estado_alumno === 'Pre Inscripto')
      ).length > 1
    );
  }

  checkOpcionesReemplazo(periodo_id): boolean {
    return (
      this.course.asignaturas.some(
        (item) =>
          item.periodo_id === this.materia.periodo_id &&
          (item.estado_alumno === 'No Inscripto' ||
            item.estado_alumno === 'Cancelado') &&
          item.tipo_credito_id === this.materia.tipo_credito_id
      ) ||
      this.course.asignaturas.filter(
        (item) =>
          item.periodo_id === periodo_id &&
          (item.estado_alumno === 'Inscripto' || 'Pre Inscripto')
      ).length > 1
    );
  }
}
