<div
  id="card"
  *ngIf="!this.tutor.isSelected; else selected"
  class="card-body"
  (click)="this.addTutor(this.tutor)"
>
  <div class="circle-img">
    <img [src]="tutor.photo" alt="Foto del usuario" />
  </div>
  <div class="tutor-info">
    <div class="nombre">{{ tutor.name }}</div>

    <div class="descripcion">{{ tutor.description }}</div>
  </div>
</div>

<ng-template #selected>
  <div
    id="card"
    class="card-body selected"
    (click)="this.removeTutor(this.tutor); this.unselect()"
  >
    <div class="circle-img">
      <img [src]="tutor.photo" alt="Foto del usuario" />
    </div>
    <div class="tutor-info">
      <div class="nombre">{{ tutor.name }}</div>

      <div class="descripcion">{{ tutor.description }}</div>
    </div>
    <div class="index">{{ this.selectedTutors.indexOf(this.tutor) + 1 }}</div>
  </div>
</ng-template>
