<div class="card-container main-border main-radius shadow-200">
  <div class="card-content">
    <div class="header">
      <div class="product-title">
        <p class="title">
          {{
            product.certificacion
              ? product.certificacion
              : product.certificado_nombre
          }}
          <span *ngIf="!actions"
            >x{{ product.quantity ? product.quantity : product.cantidad }}</span
          >
        </p>
        <p class="carrera">
          {{
            product.carrera ? product.carrera : product.student_career_nombre
          }}
        </p>
      </div>
    </div>

    <p *ngIf="actions">
      US${{ product.valor_usd ? product.valor_usd : product.valor_unitario }}
      c/u
    </p>
  </div>
  <div class="card-actions">
    <div class="price">
      <p class="unit">
        US$ <span class="number">{{ product.subtotal }}</span>
      </p>
    </div>
    <div class="counter" *ngIf="actions">
      <button
        mat-icon-button
        color="accent"
        aria-label="Remover unidades"
        *ngIf="product.quantity === 1"
        (click)="onDelete()"
      >
        <mat-icon class="material-icons-round">delete</mat-icon>
      </button>
      <button
        mat-icon-button
        color="accent"
        aria-label="Remover unidades"
        *ngIf="product.quantity > 1"
        (click)="onRemove()"
      >
        <mat-icon class="material-icons-round">remove</mat-icon>
      </button>
      <div>{{ product.quantity }}</div>
      <button
        mat-icon-button
        color="accent"
        aria-label="Añadir unidades"
        [disabled]="product.quantity >= product.limit"
        (click)="onAdd()"
      >
        <mat-icon class="material-icons-round">add</mat-icon>
      </button>
    </div>
  </div>
</div>
