import { Factura } from '../../../../shared/interfaces/IEstadoFinanciero';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import jsPDF from 'jspdf';
import autoTable, { CellDef } from 'jspdf-autotable';
import moment from 'moment';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class PaymentTableComponent implements OnInit {
  @Input() facturas: Factura[];
  @Input() userName;
  @Input() totalVencido;
  @Input() totalFacturado;
  @Input() totalAdeudado;

  dataSource;

  public searchValue = '';

  public tableColumns = [
    {
      columnDef: 'fecha',
      header: 'Fecha',
      cell: (factura: Factura) => `${factura.fecha}`,
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      cell: (factura: Factura) =>
        new Date(factura.vencimiento) < new Date() && factura.Saldo > 0
          ? 'Vencido'
          : factura.Saldo > 0
          ? 'Abierto'
          : 'Pagado',
    },
    {
      columnDef: 'comprobante',
      header: 'Comprobante',
      cell: (factura: Factura) => [
        `Nº ${factura.comprobante}`,
        factura.detalle,
      ],
    },
    {
      columnDef: 'cuotas',
      header: 'Cant. Cuotas',
      cell: (factura: Factura) => `${factura.cuotas}`,
    },
    {
      columnDef: 'vencimiento',
      header: 'Vencimiento',
      cell: (factura: Factura) => `${factura.vencimiento}`,
    },
    {
      columnDef: 'total',
      header: 'Total facturado',
      cell: (factura: Factura) => `${factura.total}`,
    },
    {
      columnDef: 'Saldo',
      header: 'Saldo',
      cell: (factura: Factura) => `${factura.Saldo}`,
    },
  ];

  public columnsToDisplay = this.tableColumns.map((c) => c.columnDef);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.renderTable();
  }

  renderTable(): void {
    this.dataSource = new MatTableDataSource(this.facturas);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search(e): void {
    const filterValue = (e.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cleanSearch(): void {
    this.searchValue = '';
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
  }

  normalizeUserTable(): any[] {
    return [
      ['Estudiante', this.userName],
      ['Total facturado', 'USD ' + this.totalFacturado.toFixed(2)],
      ['Total adeudado', 'USD ' + this.totalAdeudado.toFixed(2)],
      ['Total vencido', 'USD ' + this.totalVencido.toFixed(2)],
    ];
  }

  normalizeDataTable(cuotas = null): any[] {
    const res = [];

    for (const i in this.facturas) {
      if (this.facturas.hasOwnProperty(i)) {
        const subArray = [];
        const cuotaArray = [];

        /* INFO PAGO */
        subArray.push(this.facturas[i].fecha);
        subArray.push(
          new Date(this.facturas[i].vencimiento) < new Date() &&
            this.facturas[i].Saldo > 0
            ? 'Vencido'
            : this.facturas[i].Saldo > 0
            ? 'Abierto'
            : 'Pagado'
        );
        subArray.push(
          `Nº ${this.facturas[i].comprobante}: ${this.facturas[i].detalle}`
        );
        subArray.push(this.facturas[i].cuotas);
        subArray.push(this.facturas[i].vencimiento);
        subArray.push(this.facturas[i].total);
        subArray.push(this.facturas[i].Saldo);

        res.push(subArray);

        /* GET CUOTAS */

        if (cuotas != null) {
          let status;
          let currentDate = new Date();
          let fechaPago;
          let fechaVencimiento;
          let diffDays;

          for (const j in this.facturas[i].move_line) {
            const cuotaSubArray = [];
            if (this.facturas[i].move_line[j].fecha_pago !== '') {
              const fechaPagoArray =
                this.facturas[i].move_line[j].fecha_pago.split('-');
              fechaPago = new Date(
                parseInt(fechaPagoArray[2], 10),
                parseInt(fechaPagoArray[1], 10) - 1,
                parseInt(fechaPagoArray[0], 10)
              );
            }

            if (this.facturas[i].move_line[j].fecha_vencimiento !== '') {
              const fechaVencimientoArray =
                this.facturas[i].move_line[j].fecha_vencimiento.split('-');
              fechaVencimiento = new Date(
                parseInt(fechaVencimientoArray[2], 10),
                parseInt(fechaVencimientoArray[1], 10) - 1,
                parseInt(fechaVencimientoArray[0], 10)
              );
            }

            if (
              (this.facturas[i].move_line[j].conciliado === 'SI' &&
                (this.facturas[i].move_line[j].fecha_pago !== '' ||
                  this.facturas[i].move_line[j].fecha_pago != null)) ||
              (this.facturas[i].move_line[j].conciliado === 'NO' &&
                this.facturas[i].move_line[j].deuda < 0)
            ) {
              status = 'Pagado';
            } else if (
              fechaVencimiento < currentDate &&
              this.facturas[i].move_line[j].deuda > 0
            ) {
              status = 'Vencido';
              diffDays = Math.trunc(
                (currentDate.getTime() - fechaVencimiento.getTime()) /
                  (1000 * 60 * 60 * 24)
              );
            } else {
              status = 'Pendiente';
            }

            /* ARREGLO DE CUOTAS */
            cuotaSubArray.push(
              `[ CUOTA #${this.facturas[i].move_line[j].cuota} ]\r\n \r\n ${
                (status === 'Pendiente' || status === 'Vencido') &&
                this.facturas[i].move_line[j].monto ===
                  this.facturas[i].move_line[j].deuda
                  ? '[ MONTO A PAGAR ]\r\n'
                  : '[ MONTO PAGADO ]\r\n'
              } USD ${this.facturas[i].move_line[j].monto.toFixed(2)} ${
                this.facturas[i].move_line[j].conciliado === 'SI' && fechaPago
                  ? ' \r\n(Pagado el ' +
                    this.facturas[i].move_line[j].fecha_pago.replaceAll(
                      '-',
                      '/'
                    ) +
                    ') '
                  : ''
              } ${
                status === 'En deuda' && this.facturas[i].move_line[j].deuda > 0
                  ? ' \r\n [ Saldo ADEUDADO por el alumno ] \r\n USD ' +
                    this.facturas[i].move_line[j].deuda.toFixed(2)
                  : ''
              } \r\n \r\n  [ ESTADO ] \r\n ${status} \r\n ${
                status != 'Pagado'
                  ? ' \r\n[ VENCIMIENTO ] \r\n' +
                    this.facturas[i].move_line[j].fecha_vencimiento.replaceAll(
                      '-',
                      '/'
                    )
                  : ''
              } ${
                status === 'Vencido' ? '(' + diffDays + ' días de retraso)' : ''
              } `
            );
            cuotaArray.push(cuotaSubArray);
          }
        }

        if (cuotas != null) {
          res.push(cuotaArray);
        }
      }
    }
    return res;
  }

  normalizeDataTableB(cuotas = null): any[] {
    const res = [];
    const body: CellDef[][] = [];

    for (const i in this.facturas) {
      if (this.facturas.hasOwnProperty(i)) {
        const subArray = [];
        const cuotaArray = [];
        const estadoPago =
          new Date(this.facturas[i].vencimiento) < new Date() &&
          this.facturas[i].Saldo > 0
            ? 'VENCIDO'
            : this.facturas[i].Saldo > 0
            ? 'ABIERTO'
            : 'PAGADO';

        /* INFO PAGO */
        subArray.push({
          content: this.facturas[i].fecha,
          colSpan: 1,
          styles: {
            fontStyle: 'bold',
          },
        });
        subArray.push({
          content: estadoPago,
          colSpan: 1,
          styles: {
            fontStyle: 'bold',
            textColor: '#ffffff',
            fillColor:
              estadoPago === 'PAGADO'
                ? '#6181ff'
                : estadoPago === 'ABIERTO'
                ? '#47ba60'
                : '#f8423f',
          },
        });
        subArray.push({
          content: `Nº ${this.facturas[i].comprobante}: ${this.facturas[i].detalle}`,
          colSpan: 1,
          styles: {
            halign: 'left',
            fontStyle: 'bold',
          },
        });
        subArray.push({
          content: this.facturas[i].cuotas,
          colSpan: 1,
          styles: {
            fontStyle: 'bold',
          },
        });
        subArray.push({
          content: this.facturas[i].vencimiento,
          colSpan: 1,
          styles: {
            fontStyle: 'bold',
          },
        });
        subArray.push({
          content: 'USD ' + this.facturas[i].total,
          colSpan: 1,
          styles: {
            fontStyle: 'bold',
          },
        });
        subArray.push({
          content: 'USD ' + this.facturas[i].Saldo,
          colSpan: 1,
          styles: {
            fontStyle: 'bold',
          },
        });

        body.push(subArray);

        /* GET CUOTAS */

        if (cuotas != null) {
          let status;
          let currentDate = new Date();
          let fechaPago;
          let fechaVencimiento;
          let diffDays;

          for (const j in this.facturas[i].move_line) {
            const cuotaSubArray = [];
            if (this.facturas[i].move_line[j].fecha_pago !== '') {
              const fechaPagoArray =
                this.facturas[i].move_line[j].fecha_pago.split('-');
              fechaPago = new Date(
                parseInt(fechaPagoArray[2], 10),
                parseInt(fechaPagoArray[1], 10) - 1,
                parseInt(fechaPagoArray[0], 10)
              );
            }

            if (this.facturas[i].move_line[j].fecha_vencimiento !== '') {
              const fechaVencimientoArray =
                this.facturas[i].move_line[j].fecha_vencimiento.split('-');
              fechaVencimiento = new Date(
                parseInt(fechaVencimientoArray[2], 10),
                parseInt(fechaVencimientoArray[1], 10) - 1,
                parseInt(fechaVencimientoArray[0], 10)
              );
            }

            if (
              (this.facturas[i].move_line[j].conciliado === 'SI' &&
                (this.facturas[i].move_line[j].fecha_pago !== '' ||
                  this.facturas[i].move_line[j].fecha_pago != null)) ||
              (this.facturas[i].move_line[j].conciliado === 'NO' &&
                this.facturas[i].move_line[j].deuda < 0)
            ) {
              status = 'Pagado';
            } else if (
              fechaVencimiento < currentDate &&
              this.facturas[i].move_line[j].deuda > 0
            ) {
              status = 'Vencido';
              diffDays = Math.trunc(
                (currentDate.getTime() - fechaVencimiento.getTime()) /
                  (1000 * 60 * 60 * 24)
              );
            } else {
              status = 'Pendiente';
            }

            /* ARREGLO DE CUOTAS */
            cuotaSubArray.push(
              `[ CUOTA #${this.facturas[i].move_line[j].cuota} ] ${
                (status === 'Pendiente' || status === 'Vencido') &&
                this.facturas[i].move_line[j].monto ===
                  this.facturas[i].move_line[j].deuda
                  ? '  [ MONTO A PAGAR ]'
                  : '  [ MONTO PAGADO ]'
              } USD ${this.facturas[i].move_line[j].monto.toFixed(2)} ${
                this.facturas[i].move_line[j].conciliado === 'SI' && fechaPago
                  ? ' (Pagado el ' +
                    this.facturas[i].move_line[j].fecha_pago.replaceAll(
                      '-',
                      '/'
                    ) +
                    ') '
                  : ''
              } ${
                status === 'En deuda' && this.facturas[i].move_line[j].deuda > 0
                  ? '    [ Saldo ADEUDADO por el alumno ]  USD ' +
                    this.facturas[i].move_line[j].deuda.toFixed(2)
                  : ''
              }     [ ESTADO ] ${status}  ${
                status != 'Pagado'
                  ? '   [ VENCIMIENTO ] ' +
                    this.facturas[i].move_line[j].fecha_vencimiento.replaceAll(
                      '-',
                      '/'
                    )
                  : ''
              } ${
                status === 'Vencido' ? '(' + diffDays + ' días de retraso)' : ''
              } `
            );
            cuotaArray.push(cuotaSubArray);
          }
          body.push([
            {
              content: cuotaArray.join('\r\n'),
              colSpan: 7,
              styles: {
                halign: 'left',
                cellPadding: 4,
              },
            },
          ]);
        }
      }
    }
    return body;
  }

  generatePdf(): void {
    const date = new Date().toLocaleDateString();
    const doc = new jsPDF('l', 'mm', 'a4');

    const headNote = [
      [
        'Fecha',
        'Estado',
        'Comprobante',
        'Cant. Cuotas',
        'Vencimiento',
        'Total Facturado',
        'Saldo',
      ],
    ];

    const dataUser = this.normalizeUserTable();
    const pagosTable = this.normalizeDataTable();
    // MARCA AGUA
    const waterMark = new Image();
    waterMark.src = '../../../../assets/images/bg/watermark2.png';
    // LOGO
    const img = new Image();
    img.src = '../../../../assets/images/logos/logo-adenupa.png';
    doc.addImage(img, 'png', 20, 10, 30, 10);
    // ESTILO FONT
    doc.setTextColor(117, 117, 117);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#616161');
    // TITULO
    doc.text('ESTADO FINANCIERO', 170, 17);
    // FECHA
    doc.text(date, 270, 17);

    // TABLAS

    /* Info general */
    autoTable(doc, {
      body: dataUser,
      bodyStyles: {
        lineColor: '#e5e5e5',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: '#e5e5e5',
          fontStyle: 'bold',
          cellWidth: 30,
        },
      },
      alternateRowStyles: {
        fillColor: false,
      },
      // theme : 'plain',
      tableLineColor: '#616161',
      startY: 25,
      didDrawCell: (data) => {},
    });

    let yCoordNotes;

    /* Pagos */
    autoTable(doc, {
      head: headNote,
      body: pagosTable,
      headStyles: {
        fillColor: '#B31D15',
        fontStyle: 'bold',
        textColor: '#ffffff',
        lineColor: '#B31D15',
        lineWidth: 0.1,
        valign: 'middle',
        halign: 'center',
      },
      columnStyles: {
        0: {
          halign: 'center',
        },
        1: {
          halign: 'center',
        },
        2: {
          halign: 'center',
        },
        3: {
          halign: 'center',
        },
        4: {
          halign: 'center',
        },
        5: {
          halign: 'center',
        },
      },
      bodyStyles: {
        lineColor: '#e5e5e5',
        fillColor: '#f9f9f9',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      alternateRowStyles: {
        fillColor: '#f1f1f1',
      },
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordNotes = data.table;
        // console.log(yCoordNotes.finalY);
      },
    });

    // FOOTER
    let page;
    let yCoordFooter;
    autoTable(doc, {
      head: [[`Reporte generado por ADEN University Panamá el día ${date}`]],
      // theme : 'plain',
      headStyles: {
        fillColor: '#B31D15',
        fontStyle: 'bold',
        textColor: '#ffffff',
        cellPadding: 4,
        halign: 'center',
        valign: 'middle',
      },
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordFooter = data.table;
        page = yCoordFooter;
      },
    });

    doc.setFontSize(10);

    // MARCA DE AGUA
    /* for (
      let pageNumber = 1;
      pageNumber <= doc.getNumberOfPages();
      pageNumber++
    ) {
      doc.setPage(pageNumber);
      doc.addImage(waterMark, 'png', 0, 0, 300, 210);
    } */

    // GUARDAR
    doc.save(
      'ESTADO FINANCIERO ADENUPA - ' +
        `${this.userName.toUpperCase()}` +
        ' - ' +
        date
    );
  }

  generatePdfB(): void {
    const date = new Date().toLocaleDateString();
    const doc = new jsPDF('l', 'mm', 'a4');

    const headNote = [
      [
        'Fecha',
        'Estado',
        'Comprobante',
        'Cant. Cuotas',
        'Vencimiento',
        'Total Facturado',
        'Saldo',
      ],
    ];

    const dataUser = this.normalizeUserTable();
    const pagosTable = this.normalizeDataTable('cuotas');
    // MARCA AGUA
    const waterMark = new Image();
    waterMark.src = '../../../../assets/images/bg/watermark2.png';
    // LOGO
    const img = new Image();
    img.src = '../../../../assets/images/logos/logo-adenupa.png';
    doc.addImage(img, 'png', 20, 10, 30, 10);
    // ESTILO FONT
    doc.setTextColor(117, 117, 117);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#616161');
    // TITULO
    doc.text('ESTADO FINANCIERO', 120, 17);
    // FECHA
    doc.text(date, 270, 17);

    // TABLAS

    /* Info general */
    autoTable(doc, {
      body: dataUser,
      bodyStyles: {
        lineColor: '#e5e5e5',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: '#e5e5e5',
          fontStyle: 'bold',
          cellWidth: 30,
        },
      },
      alternateRowStyles: {
        fillColor: false,
      },
      // theme : 'plain',
      tableLineColor: '#616161',
      startY: 25,
      didDrawCell: (data) => {},
    });

    let yCoordNotes;

    /* Pagos */
    autoTable(doc, {
      head: headNote,
      body: pagosTable,
      headStyles: {
        fillColor: '#B31D15',
        fontStyle: 'bold',
        textColor: '#ffffff',
        lineColor: '#B31D15',
        lineWidth: 0.1,
        valign: 'middle',
        halign: 'center',
      },
      columnStyles: {
        0: {
          halign: 'center',
        },
        1: {
          halign: 'center',
        },
        2: {
          halign: 'center',
        },
        3: {
          halign: 'center',
        },
        4: {
          halign: 'center',
        },
        5: {
          halign: 'center',
        },
      },
      bodyStyles: {
        lineColor: '#e5e5e5',
        fillColor: '#f9f9f9',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      alternateRowStyles: {
        fillColor: '#e5e5e5',
        cellPadding: 4,
      },
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordNotes = data.table;
        // console.log(yCoordNotes.finalY);
      },
    });

    // FOOTER
    let page;
    let yCoordFooter;
    autoTable(doc, {
      head: [[`Reporte generado por ADEN University Panamá el día ${date}`]],
      // theme : 'plain',
      headStyles: {
        fillColor: '#B31D15',
        fontStyle: 'bold',
        textColor: '#ffffff',
        cellPadding: 4,
        halign: 'center',
        valign: 'middle',
      },
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordFooter = data.table;
        page = yCoordFooter;
      },
    });

    doc.setFontSize(10);

    // MARCA DE AGUA
    /* for (
      let pageNumber = 1;
      pageNumber <= doc.getNumberOfPages();
      pageNumber++
    ) {
      doc.setPage(pageNumber);
      doc.addImage(waterMark, 'png', 0, 0, 300, 210);
    } */

    // GUARDAR
    doc.save(
      'ESTADO FINANCIERO ADENUPA - ' +
        `${this.userName.toUpperCase()}` +
        ' - ' +
        date
    );
  }

  generatePdfC(): void {
    const date = new Date().toLocaleDateString();
    const doc = new jsPDF('l', 'mm', 'a4');

    const headNote = [
      [
        'Fecha',
        'Estado',
        'Comprobante',
        'Cant. Cuotas',
        'Vencimiento',
        'Total Facturado',
        'Saldo',
      ],
    ];

    const dataUser = this.normalizeUserTable();
    const pagosTable = this.normalizeDataTableB('cuotas');
    // MARCA AGUA
    const waterMark = new Image();
    waterMark.src = '../../../../assets/images/bg/h-watermark.png';
    // LOGO
    const img = new Image();
    img.src = '../../../../assets/images/logos/logo-adenupa.png';
    doc.addImage(img, 'png', 20, 10, 30, 10);
    // ESTILO FONT
    doc.setTextColor(117, 117, 117);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#616161');
    // TITULO
    doc.text('REPORTE DE ESTADO FINANCIERO', 120, 17);
    // FECHA
    doc.text(date, 250, 17);

    // TABLAS

    /* Info general */
    autoTable(doc, {
      body: dataUser,
      bodyStyles: {
        lineColor: '#e5e5e5',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: '#e5e5e5',
          fontStyle: 'bold',
          cellWidth: 30,
        },
      },
      alternateRowStyles: {
        fillColor: false,
      },
      // theme : 'plain',
      tableLineColor: '#616161',
      startY: 25,
      didDrawCell: (data) => {},
    });

    let yCoordNotes;

    /* Pagos */
    autoTable(doc, {
      head: headNote,
      body: pagosTable,
      headStyles: {
        fillColor: '#B31D15',
        fontStyle: 'bold',
        textColor: '#ffffff',
        lineColor: '#B31D15',
        lineWidth: 0.1,
        valign: 'middle',
        halign: 'center',
      },
      columnStyles: {
        0: {
          halign: 'center',
        },
        1: {
          halign: 'center',
        },
        2: {
          halign: 'center',
        },
        3: {
          halign: 'center',
        },
        4: {
          halign: 'center',
        },
        5: {
          halign: 'center',
        },
      },
      bodyStyles: {
        lineColor: '#e5e5e5',
        fillColor: '#f9f9f9',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      alternateRowStyles: {
        fillColor: '#e5e5e5',
        cellPadding: 4,
      },
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordNotes = data.table;
        // console.log(yCoordNotes.finalY);
      },
    });

    // FOOTER
    let page;
    let yCoordFooter;
    autoTable(doc, {
      head: [[`Reporte generado por ADEN University Panamá el día ${date}`]],
      // theme : 'plain',
      headStyles: {
        fillColor: '#B31D15',
        fontStyle: 'bold',
        textColor: '#ffffff',
        cellPadding: 4,
        halign: 'center',
        valign: 'middle',
      },
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordFooter = data.table;
        page = yCoordFooter;
      },
    });

    doc.setFontSize(10);

    // MARCA DE AGUA
    for (
      let pageNumber = 1;
      pageNumber <= doc.getNumberOfPages();
      pageNumber++
    ) {
      doc.setPage(pageNumber);
      doc.addImage(waterMark, 'png', 0, 0, 300, 210);
    }

    // GUARDAR
    doc.save(
      'ESTADO FINANCIERO ADENUPA - ' +
        `${this.userName.toUpperCase()}` +
        ' - ' +
        date
    );
  }
}
