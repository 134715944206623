import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISpec } from '../interfaces/ISpec';

@Injectable({
  providedIn: 'root'
})
export class SpecViewService {

  private sharedSpecMode = new BehaviorSubject<ISpec>({isSpecMode: false,   sis_id: null,   name: null, photo: null});
  currentSharedSpecMode = this.sharedSpecMode.asObservable();
  specView: ISpec;

  constructor() { }

  changeSpecMode(specMode: any){
    this.sharedSpecMode.next(specMode);
  }
  
  getSpecView(){
    this.currentSharedSpecMode.subscribe(data => {
      return data;
    //   this.getAlumno().subscribe(res => {
    //     this.store.dispatch(
    //       new reduxUser.GetUser({
    //         ...res,
    //         foto64: `data:image/png;base64,${res.foto64}`,
    //       })
    //     );
    // }
    // )
  })
  }

}
