import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';

@Component({
  selector: 'app-spec-view',
  templateUrl: './spec-view.component.html',
  styleUrls: ['./spec-view.component.scss']
})
export class SpecViewComponent implements OnInit {

  data:any = [];
  searchValue;

  constructor(
    private alertService: AlertService,
    private specViewService: SpecViewService,
    private alumnoService: AlumnoService,
    private router: Router,
    ) { }

  ngOnInit(): void {}

  search(e): void {
      this.alumnoService.getSisIdByEmail(this.replaceWhiteSpace(e)).subscribe({
        next: (data: any)=>{
          if(data.sis_id){
            this.specViewService.changeSpecMode({isSpecMode: true, sis_id: data.sis_id})
            this.router.navigate(['/matriculaciones'])
          }else{
            console.log('Ha ocurrido un problema')
          }
        },
        error: (data)=>{
          this.alertService.openAlert(
            '¡Ups!',
            'No se encontro el usuario',
            'Entendido'
          )
        }
      })
  }

  replaceWhiteSpace(s) {
    return s.replace(/ /g,'')
  }

}
