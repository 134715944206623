import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Asignatura } from 'src/app/shared/interfaces/IAsignatura';

export interface DialogData {
  asignaturas: Asignatura[];
  materiaCancelar: Asignatura;
  modalidadSelected: string;
  periodos: any;
}

@Component({
  selector: 'app-cambiar-asignatura-dialog',
  templateUrl: './cambiar-asignatura-dialog.component.html',
  styleUrls: ['./cambiar-asignatura-dialog.component.scss'],
})
export class CambiarAsignaturaDialogComponent {
  public disabledTooltip = true;
  public asignaturaSelected: Asignatura = null;

  constructor(
    public dialogRef: MatDialogRef<CambiarAsignaturaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  setAsignaturaSelected(asignatura: Asignatura): void {
    this.asignaturaSelected = asignatura;
  }

  resetAsignaturaSelected(): void {
    this.asignaturaSelected = null;
  }
}
