<div
  class="banner-container default"
  [id]="type"
  [routerLink]="link"
  [queryParams]="queryParams"
>
  <div class="body">
    <div class="tag" *ngIf="chipTitle">{{ chipTitle }}</div>
    <div class="header">
      <span class="title">{{ title }}</span
      >&nbsp;
      <span class="subtitle" *ngIf="subtitle">{{ subtitle }}</span>
    </div>
    <div class="description">{{ description }}</div>
  </div>
  <div class="icon">
    <mat-icon class="material-icons-round"> {{ icon }} </mat-icon>
  </div>
</div>
