<div
  [class]="isIconify ? 'button-container spec-button' : 'button-container'"
  *ngIf="link && !this.link.includes('http') && !this.link.includes('assets')"
  [routerLinkClickEnabled]="
    link && !this.link.includes('http') && !this.link.includes('assets')
  "
  [routerLink]="link"
  (click)="reloadPage()"
  [routerLinkActive]="['active']"
  queryParamsHandling="preserve"
  [routerLinkActiveOptions]="{ exact: true }"
>
  <mat-icon
    [id]="isSpecMode ? 'spec-icon-mode' : 'main-icon'"
    *ngIf="!iconImage && icon !== '' && icon !== null && !isIconify"
    [ngStyle]="{
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
    class="material-icons-round"
    >{{ icon }}</mat-icon
  >
  <iconify-icon
    id="main-icon"
    *ngIf="isIconify"
    [icon]="icon"
    [ngStyle]="{
      width: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
    [class]="isSpecMode ? 'material-icons-round iconify-spec' : 'material-icons-round iconify'"
    ></iconify-icon
  >
  <div
    class="custom-icon"
    *ngIf="iconImage"
    [ngStyle]="{
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
  >
    <img
      [src]="'../../../../../../../assets/images/icons/' + iconImageUrl"
      alt="icon-image"
    />
  </div>
  <div
    [id]="isSpecMode ? 'spec-icon-mode' : 'main-icon'"
    class="letter-icon"
    *ngIf="!icon && !iconImage"
    [ngStyle]="{
      width: size === 'small' ? '20px' : '32px',
      height: size === 'small' ? '20px' : '32px',
      fontSize: size === 'small' ? '0.7rem' : '1rem'
    }"
  >
    {{ name.charAt(0) }}
  </div>
  <div class="text-body" *ngIf="showLabel">
    <div
      [class]="isSpecMode ? 'title specTitle' : 'title'"
      [ngStyle]="{
        fontSize: size === 'small' ? '0.9rem' : '0.9rem'
      }"
    >
      {{ name }}
    </div>
    <div class="description" *ngIf="description && description !== ''">
      {{ description }}
    </div>
  </div>
  <mat-icon
    id="toggleMenu"
    class="material-icons-round"
    [ngStyle]="{
      transform: isActive ? 'rotate(180deg)' : 'unset',
      paddingRight: isActive ? '0' : ' 0.5rem',
      paddingLeft: isActive ? '0.5rem' : ' 0',
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
    *ngIf="toggleMenu && showLabel"
    >expand_more</mat-icon
  >
  </div>

<a
  class="button-container"
  *ngIf="link && (this.link.includes('http') || this.link.includes('assets'))"
  [href]="link"
  rel="noopener noreferrer"
  target="_blank"
>
  <mat-icon
    *ngIf="!iconImage && icon"
     [id]="isSpecMode ? 'spec-icon-mode' : 'main-icon'"
    [ngStyle]="{
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
    class="material-icons-round"
    >{{ icon }}</mat-icon
  >
  <div
    class="custom-icon"
    *ngIf="iconImage"
    [ngStyle]="{
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
  >
    <img
      [src]="'../../../../../../../assets/images/icons/' + iconImageUrl"
      alt="icon-image"
    />
  </div>

  <div class="text-body" *ngIf="showLabel">
    <div
      [class]="isSpecMode ? 'title specTitle' : 'title'"
      [ngStyle]="{
        fontSize: size === 'small' ? '0.7rem' : '0.9rem'
      }"
    >
      {{ name }}
    </div>
    <div class="description" *ngIf="description && description !== ''">
      {{ description }}
    </div>
  </div>
  <mat-icon
    id="toggleMenu"
    class="material-icons-round"
    [ngStyle]="{
      transform: isActive ? 'rotate(180deg)' : 'unset',
      paddingRight: isActive ? '0' : ' 0.5rem',
      paddingLeft: isActive ? '0.5rem' : ' 0',
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
    *ngIf="toggleMenu && showLabel"
    >expand_more</mat-icon
  >
</a>

<div class="button-container" [ngClass]="{ opened: isActive }" *ngIf="!link">
  <mat-icon
    [id]="isSpecMode ? 'spec-icon-mode' : 'main-icon'"
    *ngIf="!iconImage && icon"
    [ngStyle]="{
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
    class="material-icons-round"
    >{{ icon }}</mat-icon
  >
  <div
    class="custom-icon"
    *ngIf="iconImage"
    [ngStyle]="{
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
  >
    <img
      [src]="'../../../../../../../assets/images/icons/' + iconImageUrl"
      alt="icon-image"
    />
  </div>
  <div class="text-body" *ngIf="showLabel">
    <div
      [class]="isSpecMode ? 'title specTitle' : 'title'"
      [ngStyle]="{
        fontSize: size === 'small' ? '0.7rem' : '0.9rem'
      }"
    >
      {{ name }}
    </div>
    <div class="description" *ngIf="description && description !== ''">
      {{ description }}
    </div>
  </div>
  <mat-icon
    id="toggleMenu"
    class="material-icons-round"
    [ngStyle]="{
      transform: isActive ? 'rotate(180deg)' : 'unset',
      paddingRight: isActive ? '0' : ' 0.5rem',
      paddingLeft: isActive ? '0.5rem' : ' 0',
      width: size === 'small' ? '32px' : '40px',
      height: size === 'small' ? '32px' : '40px',
      fontSize: size === 'small' ? '18px' : '20px'
    }"
    *ngIf="toggleMenu && showLabel"
    >expand_more</mat-icon
  >
</div>
