<div class="container-bg">
  <!-- <div class="graph">
    <img
      src="../../../../assets/images/bg/patron-internacional-blanco.png"
      alt=""
    />
  </div> -->
  <div class="container">
    <div class="banner-content">
      <!-- <div class="title">PROGRAMA</div> -->
      <div class="name secondary">ADENU <b>Internacional</b></div>
      <div class="text">
        ADEN University te abre las puertas para que vivas una experiencia
        internacional en universidades extranjeras de gran prestigio.
      </div>

      <div class="button-container">
        <a mat-flat-button color="primary" [routerLink]="['./']" fragment="sobre"
          >Sobre Relaciones Internacionales</a
        >
        <a
          mat-flat-button
          color="primary"
          href="adenu-internacional#programa-movilidad-internacional"
          >Programa Movilidad Internacional</a
        >
        <!-- <a
          mat-flat-button
          color="primary"
          href="adenu-internacional#cronograma"
          >Cronograma 2022</a
        > -->
        <a
          mat-flat-button
          color="primary"
          href="adenu-internacional#inscripcion"
          >Inscripción</a
        >
      </div>
    </div>
  </div>
</div>
