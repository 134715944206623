import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { SpecViewService } from './spec-view.service';
import { ISpec } from '../interfaces/ISpec';

@Injectable({providedIn: 'root'})
export class DocumentsService {

    url = environment.baseUrl;
    specView: ISpec;


    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private specViewService: SpecViewService
        ) { this.getSpecView(); }

    getSpecView(){
      this.specViewService.currentSharedSpecMode.subscribe(data => this.specView = data)
    }

    getDocuments(goSpec = false): Observable<any> {
      const token = this.authService.leerToken();
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
      console.log(this.specView.isSpecMode)
      if(!this.specView.isSpecMode){
        return this.http.get(this.url + '/educat/documentos', { headers });
      }
      return this.http.get(this.url + '/educat/documentos?student_sis_id=' + this.specView.sis_id , { headers });
    }

    updateContract(sisId: string, contrato: boolean): Observable<any> {
        const token = this.authService.leerToken();
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
        const body = {
          sis_id: sisId,
          contrato,
        };
        return this.http.post(this.url + '/educat/actualizar_contrato', body, {
          headers,
        });
      }
    
    postDocument(file, type, isQrUpload = false): Observable<any> {
        const token = isQrUpload ? localStorage.getItem('temporalToken') : this.authService.leerToken();
        console.log('BOOL => ', isQrUpload);
        const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
    
        const data = new FormData();
        data.append('documento', file);
        data.append('tipo_documentacion', type.toString());
    
        return this.http.post(this.url + '/educat/documentos', data, {
        headers,
        });
    }

    postQrDocument(file, type, sis_id){
      const token = localStorage.getItem('userEncryptedToken');
      const headers = new HttpHeaders ({ Authorization: 'Bearer ' + token });
      const data = new FormData();
      data.append('document', file);
      data.append('sis_id', sis_id);
      data.append('document_type', type.toString());
      return this.http.post(this.url + '/qr_document', data, {  headers });
    }

    updateQrDocument(file, docId, sis_id){
      const token = localStorage.getItem('userEncryptedToken');
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
  
      const data = new FormData();
      data.append('document', file);
      data.append('sis_id', sis_id )
  
      return this.http.put(this.url + '/qr_document/' + docId, data, {
      headers,
      });
    }
    
    updateDocument(file, docId, isQrUpload = false): Observable<any> {
      const token = isQrUpload ? localStorage.getItem('temporalToken') : this.authService.leerToken();
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
  
      const data = new FormData();
      data.append('documento', file);
      // data.append('tipo_documentacion', type.toString());
  
      return this.http.put(this.url + '/educat/documentos/' + docId, data, {
      headers,
      });
    }

    
  postMultiDocs(files, type, isQrUpload = false){
    const token = this.authService.leerToken();
    const headers = new HttpHeaders({ Authorization: 'Bearer ' + isQrUpload ? localStorage.getItem('temporalToken') : token });

    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append('documento', files[i]);
    }
    data.append('tipo_documentacion', type.toString());
    data.append('carga_multiple', 'true');
    
    data.forEach((value, key) => {
      console.log(key + ': ' + value);
    });

    return this.http.post(this.url + '/educat/documentos_docente', data, {
      headers,
    });
  }

    updateMultiDocs(file, docId, isQrUpload = false){
      const token = this.authService.leerToken();
      const headers = new HttpHeaders({ Authorization: 'Bearer ' + isQrUpload ? localStorage.getItem('temporalToken') : token });
      const data = new FormData();
      data.append('documento', file);
      data.append('carga_multiple', 'true');
      return this.http.put(
        this.url + '/educat/documentos_docente/' + docId,
        data,
        {
          headers,
        }
      );
    }
}


