<div
  class="banner-container default"
  [id]="type"
  [routerLink]="link"
  [queryParams]="queryParams"
>
  <div class="body">
    <div class="tag" *ngIf="chipTitle">{{ chipTitle }}</div>
    <div class="header">
      <span class="subtitle" *ngIf="subtitle">{{ subtitle }}</span>
    </div>
    <div [innerHTML]="description" class="description"></div>
  </div>
  <div class="icon">
    <i class="fa-light fa-arrow-right material-icons-round arrow-icon"></i>
    <!-- <mat-icon class="material-icons-round"> {{ icon }} </mat-icon> -->
  </div>
</div>
