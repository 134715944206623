import { Injectable } from '@angular/core';
import { AlertDialogComponent } from '../components/alerts/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AvisoDialogComponent } from '../components/dialogs/aviso-dialog/aviso-dialog.component';

@Injectable({providedIn: 'root'})
export class AlertService {
    constructor(private dialog: MatDialog) { }
    
    openAlert(
        title: string,
        description: string,
        action: string,
        onCloseFunction: () => void = null,
        type: string = 'default',
        icon: string = '',
        img: string = ''
      ): void {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '400px',
          data: {
            title,
            description,
            action,
            onCloseFunction,
            type,
            icon,
            img,
          },
          panelClass: 'plain-alert',
        });
      }
    
      openAviso(title: string, description: string): void {
        const dialogRef = this.dialog.open(AvisoDialogComponent, {
          width: '400px',
          data: {
            title,
            description,
          },
          panelClass: 'plain-alert',
        });
      }
}