import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-home-card',
  templateUrl: './student-home-card.component.html',
  styleUrls: ['./student-home-card.component.scss']
})
export class StudentHomeCardComponent implements OnInit {
  @Input() item;

  constructor(private router: Router) {}

  goTo() {
    if (this.item.link.includes('http') || this.item.link.includes('assets')) {
      localStorage.setItem('viewer_file_url', this.item.link)
      this.router.navigate(['file']);
    } else {
      this.router.navigate([this.item.link]);
    }
  }
  ngOnInit(): void {
    console.log('CARD');
  }

}
