import {
  ValidatorFn,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';

export class FileValidator {
  static fileMaxSize(maxSize): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const file = control.value;
      let forbidden = true;

      if (!file) {
        return null;
      } else {
        const fileSizeInMB = Math.round(file.size / 1024);
        if (fileSizeInMB <= maxSize) {
          forbidden = false;
        }
        return forbidden ? { fileMaxSize: true } : null;
      }
    };
  }

  static extensions(fileExtensions): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const file = control.value;
      let forbidden = false;

      if (!file) {
        return null;
      } else {
        const fileExtension = file.name.split('.').pop();
        forbidden = fileExtensions.includes(fileExtension);
        return !forbidden ? { invalidExtension: true } : null;
      }
    };
  }
}
