import { NewsPopupDialogComponent } from './../../shared/components/dialogs/news-popup-dialog/news-popup-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CmsService } from './../../shared/services/cms.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { DataService } from 'src/app/shared/services/data.service';
import moment from 'moment';
import SwiperCore, {
  SwiperOptions,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper';
import { IHomeCard } from 'src/app/shared/interfaces/IHome';

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public cardData: Array<IHomeCard> = [
    // {
    //   id: 11,
    //   title: 'Plan de referidos',
    //   badge: 'PERFIL',
    //   redirect: true,
    //   icon: 'grommet-icons:user-expert',
    //   description: '¡Refiere y obtén beneficios!',
    //   link: 'https://mkt-landings.adenuniversity.edu.pa/referidos/',
    // },
  ];

  studentSectionCards: Array<any> = [
    {
      id: 1,
      title: 'Manual del estudiante',
      badge: 'MANUAL DEL ESTUDIANTE',
      img: '../../../assets/images/content/card-img(1).png',
      description: 'Revisa los términos y requisitos asociados a las licenciaturas.',
      link: 'https://drive.google.com/file/d/1CRJkfwGVZB5yxSyr900CfI7B0EhI0gEA/preview',
    },
    {
      id: 2,
      title: 'Herramientas',
      badge: 'Herramientas',
      img: '../../../assets/images/content/card-img(2).png',
      description:
        'Te sugerimos 6 herramientas que te serán de gran utilidad durante el cursado.',
      link: 'https://drive.google.com/file/d/1Q3wH30JG_eFgwQWdhSG7hOSZ2TBKb44H/preview',
    },
    {
      id: 10,
      title: 'Condiciones de cursado',
      badge: 'PERFIL',
      img: '../../../assets/images/content/card-img(3).png',
      description: 'Conoce los puntos fundamentales que necesitas conocer sobre tu cursado.',
      link: 'https://drive.google.com/file/d/1KagOmkfmGKrBuTYujXkNBE-FwdH7Uy0M/preview',
    },
  ]
  
  firstCardsColumn: Array<IHomeCard> = [
    {
      id: 1,
      title: 'Mis notas',
      badge: 'NOTAS',
      icon: 'fa-light fa-file-circle-check',
      description: 'Revisa tu estado académico online',
      link: '/my-course/notas',
    },

  ]
  secondCardsColumn: Array<IHomeCard> = [
    {
      id: 4,
      title: 'Matriculaciones',
      badge: 'MATRICULACIONES',
      icon: 'fa-light fa-book-bookmark',
      description: 'Accede a tus registros de matrícula, adquiere créditos y realiza tus inscripciones.',
      link: '/tramites/matriculaciones',
    },
    {
      id: 5,
      title: '',
      badge: 'RECOMIENDA',
      gif: '../../../assets/images/content/anim-recomendacion.gif',
      icon: '',
      description: '¡Recomendar ADEN, siempre es una buena idea!',
      link: 'https://mkt-landings.adenuniversity.edu.pa/referidos/',
    }
  ]

  onlineSurvey = {
    id: 2,
    title: 'Encuesta de Caracterización (Online)',
    badge: 'Encuesta',
    icon: 'fa-light fa-star',
    description: '¡Ayúdanos a conocerte respondiendo esta encuesta!',
    link: 'https://xc360.app/arquetipool',
  }
  presencialSurvey = {
    id: 3,
    title: 'Encuesta de Caracterización (Presencial)',
    badge: 'Encuesta',
    icon: 'ic:round-stars',
    description: '¡Ayúdanos a conocerte respondiendo esta encuesta!',
    link: 'https://xc360.app/arquetipopl ', 
  }

  public user$: Observable<any>;
  public user;

  public today = new Date();

  public moment: any = moment;
  BJWidget: any;
  public anuncios = [];
  public slides = [];
  public modality = '';
  public popupNews = [];
  public loadingAnuncios = true;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
  };

  constructor(
    private router: Router,
    private dataService: DataService,
    private store: Store<UserState>,
    private cmsService: CmsService,
    private dialog: MatDialog
  ) {
    this.user$ = store.select('user');
  }

  ngOnInit(): void {
    this.getSlides();
    this.getNewsItems();
    this.user$.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
        this.getPopupNews();
        this.setSurveyByModality(user.carreras);
      }
    });
  }

  setSurveyByModality(careers){
    if(careers.length > 1 && 
      careers.some(carrera => carrera.modalidad === 'Presencial') && 
      careers.some(carrera => carrera.modalidad === 'Online') 
    ){
      this.firstCardsColumn.push(this.presencialSurvey, this.onlineSurvey)
    }else{
       this.firstCardsColumn.push(
        careers.some(carrera => carrera.modalidad === 'Presencial')
        ? this.presencialSurvey 
        : this.onlineSurvey
      )
    }
    this.firstCardsColumn = this.firstCardsColumn.sort((a, b) => a.id - b.id)
  }

  

  getDate(dateString) {
    return moment(dateString, 'YYYY-MM-DD').toDate();
  }

  getModalidadOnline(user) {
    return user.carreras.some((carrera) => carrera.modalidad === 'Online');
  }

  getModalidadPresencial(user) {
    return user.carreras.some((carrera) => carrera.modalidad === 'Presencial');
  }

  getNewsItems() {
    this.loadingAnuncios = true;
    const query = `sort=publishedAt:desc&pagination[limit]=5`;
    this.cmsService.getNewsItems(query).subscribe({
      next: (res) => {
        /* console.log(res.data); */
        this.anuncios = res.data;
        this.loadingAnuncios = false;
      },
      error: (err) => {
        this.loadingAnuncios = false;
      },
    });
  }

  getSlides() {
    this.cmsService.getSlides().subscribe((res) => {
      this.slides = res.data;
    });
  }

  getPopupNews() {
    const userMod =
      this.user.carreras.some((item) => item.modalidad === 'Presencial') &&
      this.user.carreras.some((item) => item.modalidad === 'Online')
        ? ''
        : this.user.carreras[0].modalidad === 'Presencial'
        ? 'Online'
        : this.user.carreras[0].modalidad === 'Online'
        ? 'Presencial'
        : '';
    this.cmsService.getNewsPopupByModality(userMod, 'home').subscribe((res) => {
      this.popupNews = res.data;
      if (
        (!localStorage.getItem('lastSeenPopup') ||
          moment().diff(localStorage.getItem('lastSeenPopup'), 'hours') > 24) &&
        res.data.length > 0 && res.data.image.data != null
      ) {
        this.openNewsPopup();
      }
    });
  }

  openNewsPopup() {
    console.log('Pop Up')
    const dialogRef = this.dialog.open(NewsPopupDialogComponent, {
      width: '700px',
      autoFocus: false,
      data: {
        items: this.popupNews,
      },
      panelClass: 'basic-alert',
    });

    dialogRef.afterClosed().subscribe((result) => {
      localStorage.setItem('lastSeenPopup', moment().format());
    });
  }

  goToEmpleo() {
    document.forms['job-form'].submit();
  }

  goToLink(link) {
    window.open(link, '_blank');
  }

  /* SWIPER */

  onSwiper(swiper) {}
  onSlideChange() {}
}
