import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

export interface DialogData {
  type: string;
  title: string;
  description: string;
  img: string;
  dialogId: string;
}

@Component({
  selector: 'app-completed-dialog',
  templateUrl: './completed-dialog.component.html',
  styleUrls: ['./completed-dialog.component.scss'],
})
export class CompletedDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CompletedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
