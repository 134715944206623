<div class="dialog-header">
  <div mat-dialog-title>
    <h1>¡Link generado con éxito!</h1>
  </div>
</div>
<div class="dialog-container">
  <div mat-dialog-content>
    <h2>Órden #{{ data.orden }}</h2>
    <p>
      Una vez que efectúes el pago y el mismo sea aprobado, el equipo académico
      se comunicará contigo para continuar con la solicitud. Ante cualquier
      duda, puedes comunicarte con tu Academic Advisor.
    </p>
    <p>
      <b
        >Si no se abrió la ventana de pago, prueba copiar el link y pegarlo en
        una nueva pestaña de tu navegador.</b
      >
    </p>
    <div class="link-wrapper">
      <div class="link-div">
        <input type="text" disabled [value]="data.link" />
        <button
          mat-flat-button
          color="primary"
          [cdkCopyToClipboard]="data.link"
          (click)="copyLink()"
        >
          Copiar
        </button>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="primary" [matDialogClose]>Cerrar</button>
    <button
      mat-flat-button
      color="primary"
      routerLink="/mis-ordenes/tramites-academicos"
      [matDialogClose]
    >
      Ver órdenes
    </button>
  </div>
</div>
