import { Component } from '@angular/core';

@Component({
  selector: 'app-mi-progreso',
  templateUrl: './mi-progreso.component.html',
  styleUrls: ['./mi-progreso.component.scss'],
})
export class MiProgresoComponent {
  constructor() {}
}
