import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { TicketDetailComponent } from 'src/app/shared/components/ticket-detail/ticket-detail.component';
import { ITicket } from 'src/app/shared/interfaces/ICase';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { CasesService } from 'src/app/shared/services/cases.service';

@Component({
  selector: 'app-estado-ticket',
  templateUrl: './estado-ticket.component.html',
  styleUrls: ['./estado-ticket.component.scss'],
})
export class EstadoTicketComponent implements OnInit {
  public ticketsList: ITicket[] = [];
  displayedColumns: string[] = ['caseNumber', 'caseType', 'category', 'createDate', 'status'];
  dataSource = new MatTableDataSource<ITicket>([]);
  loading = false;
  private ticketSubscription: Subscription;
  isRefreshing = false;

  constructor(
    private alertService: AlertService,
    private casesService: CasesService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getStatusTicket();
    this.ticketSubscription = this.casesService.ticketUpdated$.subscribe(() => {
      this.getStatusTicket();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  formatDate(date): string {
    return moment(date).format('DD/MM/YYYY')
  }

  getStatusTicket(): void {
    console.log('Getting tickets');
    if (this.isRefreshing) return;
    this.isRefreshing = true; 
    this.loading = true;
    this.casesService.getCases().subscribe(
      (res: ITicket[]) => {
        this.ticketsList = res;
        this.dataSource.data = res;
        this.loading = false;
        this.isRefreshing = false;
      },
      (err) => {
        this.loading = false;
        this.isRefreshing = false;
        this.alertService.openAlert('Error', 'No se pudo obtener la información del ticket', 'error');
      }
    );
  }
  openModal(ticket){
    this.dialog.open(TicketDetailComponent,{
      width: '400px',
      data: ticket
    })
  }
  ngOnDestroy(): void {
    this.ticketSubscription.unsubscribe();
  }
}
