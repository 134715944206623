import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-head-internacional',
  templateUrl: './head-internacional.component.html',
  styleUrls: ['./head-internacional.component.scss'],
})
export class HeadInternacionalComponent {
  constructor(private location: Location) {}

  back(): void {
    this.location.back();
  }
}
