<div class="mt-3 margin-bottom">
  <div class="container" *ngIf="isLoading">
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <mat-vertical-stepper
    *ngIf="!isLoading && student !== undefined"
    [linear]="isLinear"
    #stepper
  >
    <!-- Profile Dates -->
    <mat-step [stepControl]="firstFormGroup" errorMessage="Campos requeridos">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Datos Personales</ng-template>

        <div class="row">
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Nombre completo</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Género según documento</mat-label>
              <mat-select formControlName="gender" required [disabled]="isSpecMode">
                <mat-option value="m">Masculino</mat-option>
                <mat-option value="f">Femenino</mat-option>
              </mat-select>
              <mat-error
                *ngIf="this.firstFormGroup.get('gender').hasError('required')"
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Tipo de documento</mat-label>
              <mat-select [disabled]="isSpecMode" formControlName="typeDocument" required>
                <mat-option value="c">Cédula</mat-option>
                <mat-option value="p">Pasaporte</mat-option>
                <mat-option value="o">Otro</mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  this.firstFormGroup.get('typeDocument').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Grupo sanguíneo</mat-label>
              <mat-select [disabled]="isSpecMode" formControlName="blood" required>
                <mat-option value="A+">A+</mat-option>
                <mat-option value="B+">B+</mat-option>
                <mat-option value="O+">O+</mat-option>
                <mat-option value="AB+">AB+</mat-option>
                <mat-option value="A-">A-</mat-option>
                <mat-option value="B-">B-</mat-option>
                <mat-option value="O-">O-</mat-option>
                <mat-option value="AB-">AB-</mat-option>
              </mat-select>
              <mat-error
                *ngIf="this.firstFormGroup.get('blood').hasError('required')"
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>No. de Documento</mat-label>
              <input
                matInput
                [readonly]="isSpecMode"
                placeholder=""
                formControlName="numberDocument"
                required
              />
              <mat-error
                *ngIf="
                  this.firstFormGroup.get('numberDocument').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Fecha de nacimiento</mat-label>
              <input
                [readonly]="isSpecMode"
                type="date"
                matInput
                placeholder=""
                formControlName="birthDate"
                required
              />
              <mat-error
                *ngIf="
                  this.firstFormGroup.get('birthDate').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field
              *ngIf="countries !== undefined; else disabledNationality"
              class="full-width"
              appearance="outline"
            >
              <mat-label>Nacionalidad</mat-label>
              <mat-select
                [disabled]="isSpecMode"
                required
                matNativeControl
                formControlName="nationality"
                [compareWith]="compareCountry"
              >
                <mat-option
                  *ngFor="let country of countries.paises"
                  [value]="country.name"
                >
                  {{ country.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.firstFormGroup.get('nationality').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>

            <ng-template #disabledNationality>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>Nacionalidad</mat-label>
                <input matInput placeholder="Cargando" disabled />
                <div matSuffix class="sk-fading-circle">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>
                <mat-hint>Cargando países</mat-hint>
              </mat-form-field>
            </ng-template>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Fecha de vencimiento del Documento</mat-label>
              <input
                [readonly]="isSpecMode"
                type="date"
                matInput
                placeholder=""
                formControlName="dateDocument"
                required
              />
            </mat-form-field>
            <mat-error
              *ngIf="
                this.firstFormGroup.get('dateDocument').hasError('required')
              "
              >Este campo es requerido</mat-error
            >
          </div>
        </div>
        <div>
          <button
            mat-raised-button
            class="bg-primary-aden text-white"
            matStepperNext
          >
            Siguiente
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Contact Dates -->
    <mat-step [stepControl]="secondFormGroup" errorMessage="Campos requeridos">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Datos de Contacto</ng-template>
        <div class="row">
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Movil</mat-label>
              <lib-ngx-mat-tel-input
                [defaultCountry]="'PA'"
                formControlName="phone"
                required
              >
              </lib-ngx-mat-tel-input>
              <mat-error
                *ngIf="this.secondFormGroup.get('phone').hasError('required')"
              >
                Este campo es <strong>requerido</strong>
              </mat-error>
              <mat-error
                *ngIf="this.secondFormGroup.get('phone').hasError('format')"
              >
                El formato del número es <strong>invalido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Correo electronico académico</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="mailAcademic"
                required
              />
              <mat-error
                *ngIf="
                  this.secondFormGroup.get('mailAcademic').hasError('email')
                "
                >Ingresa una dirección de correo válida</mat-error
              >
              <mat-error
                *ngIf="
                  this.secondFormGroup.get('mailAcademic').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Correo electronico personal</mat-label>
              <input
                [readonly]="isSpecMode"
                matInput
                placeholder=""
                formControlName="mailPersonal"
                required
              />
              <mat-error
                *ngIf="
                  this.secondFormGroup.get('mailPersonal').hasError('email')
                "
                >Ingresa una dirección de correo válida</mat-error
              >
              <mat-error
                *ngIf="
                  this.secondFormGroup.get('mailPersonal').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div>
          <button
            mat-stroked-button
            class="btn-primary-inline text-primary-aden"
            matStepperPrevious
          >
            Anterior</button
          >&nbsp;
          <button
            mat-raised-button
            class="bg-primary-aden text-white"
            matStepperNext
          >
            Siguiente
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Contact Emergency -->
    <mat-step [stepControl]="threeFormGroup" errorMessage="Campos requeridos">
      <form [formGroup]="threeFormGroup">
        <ng-template matStepLabel>Contacto de Emergencia</ng-template>
        <div class="row">
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Nombre</mat-label>
              <input
                [readonly]="isSpecMode"
                matInput
                placeholder=""
                formControlName="nameRelationship"
                required
              />
              <mat-error
                *ngIf="
                  this.threeFormGroup
                    .get('nameRelationship')
                    .hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Parentesco</mat-label>
              <input
                [readonly]="isSpecMode"
                matInput
                placeholder=""
                formControlName="relationship"
                required
              />
              <mat-error
                *ngIf="
                  this.threeFormGroup.get('relationship').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Teléfono</mat-label>
              <lib-ngx-mat-tel-input
                [defaultCountry]="'PA'"
                formControlName="phoneRelationship"
                required
              >
              </lib-ngx-mat-tel-input>
              <mat-error
                *ngIf="
                  this.threeFormGroup
                    .get('phoneRelationship')
                    .hasError('required')
                "
              >
                Este campo es <strong>requerido</strong>
              </mat-error>
              <mat-error
                *ngIf="
                  this.threeFormGroup
                    .get('phoneRelationship')
                    .hasError('format')
                "
              >
                El formato del número es <strong>invalido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <button
            mat-stroked-button
            class="btn-primary-inline text-primary-aden"
            matStepperPrevious
          >
            Anterior</button
          >&nbsp;
          <button
            mat-raised-button
            class="bg-primary-aden text-white"
            matStepperNext
          >
            Siguiente
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Socials -->
    <mat-step [stepControl]="fourFormGroup" errorMessage="Campos requeridos">
      <form [formGroup]="fourFormGroup">
        <ng-template matStepLabel>Redes Sociales</ng-template>
        <div class="row">
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Skype</mat-label>
              <input [readonly]="isSpecMode" matInput placeholder="" formControlName="skype" />
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>LinkdIn</mat-label>
              <input [readonly]="isSpecMode" matInput placeholder="" formControlName="linkedin" />
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Facebook</mat-label>
              <input matInput [readonly]="isSpecMode" placeholder="" formControlName="facebook" />
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Instagram</mat-label>
              <input matInput [readonly]="isSpecMode" placeholder="" formControlName="instagram" />
            </mat-form-field>
          </div>
        </div>
        <div>
          <button
            mat-stroked-button
            class="btn-primary-inline text-primary-aden"
            matStepperPrevious
          >
            Anterior</button
          >&nbsp;
          <button
            mat-raised-button
            class="bg-primary-aden text-white"
            matStepperNext
          >
            Siguiente
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Academic Dates -->
    <mat-step [stepControl]="fiveFormGroup" errorMessage="Campos requeridos">
      <form [formGroup]="fiveFormGroup">
        <ng-template matStepLabel>Datos Académicos</ng-template>
        <div class="row">
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Escuela o Colegio</mat-label>
              <input [readonly]="isSpecMode" matInput placeholder="" formControlName="school" />
              <mat-error
                *ngIf="this.fiveFormGroup.get('school').hasError('required')"
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Año de Egreso</mat-label>
              <input [readonly]="isSpecMode" matInput placeholder="" formControlName="yearEgress" />
              <mat-error
                *ngIf="
                  this.fiveFormGroup.get('yearEgress').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field
              class="full-width"
              appearance="outline"
              *ngIf="countries !== undefined; else disabledCountry"
            >
              <mat-label>País del colegio</mat-label>
              <mat-select
                required
                formControlName="country"
                [compareWith]="compareCountry"
                [disabled]="isSpecMode"
              >
                <mat-option
                  *ngFor="let country of countries.paises"
                  [value]="country.name"
                >
                  {{ country.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="this.fiveFormGroup.get('country').hasError('required')"
                >Este campo es requerido</mat-error
              >
            </mat-form-field>

            <ng-template #disabledCountry>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>País</mat-label>
                <input matInput placeholder="Cargando" disabled />
                <div matSuffix class="sk-fading-circle">
                  <div class="sk-circle1 sk-circle"></div>
                  <div class="sk-circle2 sk-circle"></div>
                  <div class="sk-circle3 sk-circle"></div>
                  <div class="sk-circle4 sk-circle"></div>
                  <div class="sk-circle5 sk-circle"></div>
                  <div class="sk-circle6 sk-circle"></div>
                  <div class="sk-circle7 sk-circle"></div>
                  <div class="sk-circle8 sk-circle"></div>
                  <div class="sk-circle9 sk-circle"></div>
                  <div class="sk-circle10 sk-circle"></div>
                  <div class="sk-circle11 sk-circle"></div>
                  <div class="sk-circle12 sk-circle"></div>
                </div>
                <mat-hint>Cargando países</mat-hint>
              </mat-form-field>
            </ng-template>
          </div>
        </div>
        <div>
          <button
            mat-stroked-button
            class="btn-primary-inline text-primary-aden"
            matStepperPrevious
          >
            Anterior</button
          >&nbsp;
          <button
            mat-raised-button
            class="bg-primary-aden text-white"
            matStepperNext
          >
            Siguiente
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Work Area -->
    <mat-step [stepControl]="sixFormGroup" errorMessage="Campos requeridos">
      <form [formGroup]="sixFormGroup">
        <ng-template matStepLabel>Datos Laborales</ng-template>
        <div class="row">
          <div class="col-sm-4 mt-2">
            <mat-form-field
              class="full-width"
              appearance="outline"
              *ngIf="laboralSector !== undefined"
            >
              <mat-label>Sector Laboral</mat-label>
              <mat-select
                [disabled]="isSpecMode"
                required
                formControlName="sectorLaboral"
                [compareWith]="compareSector"
              >
                <mat-option
                  *ngFor="let sector of laboralSector"
                  [value]="sector.id"
                >
                  {{ sector.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  this.sixFormGroup.get('sectorLaboral').hasError('required')
                "
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-sm-4 mt-2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Cargo Laboral</mat-label>
              <input [readonly]="isSpecMode" matInput placeholder="" formControlName="workload" />
              <mat-error
                *ngIf="this.sixFormGroup.get('workload').hasError('required')"
                >Este campo es requerido</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div>
          <button
            mat-stroked-button
            class="btn-primary-inline text-primary-aden"
            matStepperPrevious
          >
            Anterior</button
          >&nbsp;
          <button
            mat-raised-button
            class="bg-primary-aden text-white"
            matStepperNext
          >
            Siguiente
          </button>
        </div>
      </form>
    </mat-step>

    <!-- Finish -->
    <mat-step *ngIf="!isSpecMode">
      <ng-template matStepLabel>Guardar cambios</ng-template>
      <p>
        Has terminado con la edición de tu perfil, solo falta guardar los
        cambios.
      </p>
      <div>
        <button
          mat-stroked-button
          class="btn-primary-inline text-primary-aden"
          matStepperPrevious
        >
          Anterior</button
        >&nbsp;
        <button
          mat-raised-button
          class="bg-primary-aden text-white"
          matStepperNext
          type="submit"
          (click)="onSubmit()"
          id="save-button"
        >
          Guardar cambios
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</div>
