<div class="sticky-header">
  <app-image-header
    [title]="'Proceso de Graduación'"
    [imgURL]="'/images/content/graduacion/header-graduacion.png'"
    [titleColor]="'#b31d15'"
    [descriptionColor]="'#222222'"
    [buttonColor]="'#b31d15'"
  ></app-image-header>
  <app-onepage-nav
    [links]="links"
    [activeLink]="activeLink"
    [urlBase]="'graduacion'"
  ></app-onepage-nav>
</div>

<section class="container" id="inicio">
  <div class="body">
    <div class="content">
      <h1>¡Bienvenido al proceso de graduación de ADEN University!</h1>
      <p>
        Este proceso incluye la última etapa de aprendizaje en el plan de
        estudios, con las materias de: Pasantía de Extensión Profesional,
        Pasantía de Responsabilidad Social Comunitaria y la Opción de Grado.
      </p>
    </div>
    <div class="image">
      <img
        class="full-width"
        src="https://i.postimg.cc/50Z5kygj/graduacion.png"
        alt="Alumna Graduacion"
      />
    </div>
  </div>
</section>
<div class="white-bg">
  <section id="requisitos" class="container inicio">
    <div class="body">
      <div class="image">
        <img
          class="full-width"
          src="https://i.postimg.cc/3wknLshS/graduacion-2.png"
          alt=""
        />
      </div>
      <div class="content">
        <h1>Requisitos para ingresar</h1>
        <ul>
          <li>
            Haber culminado satisfactoriamente todas las asignaturas regulares
            del plan de estudios.
          </li>
          <li>
            Tener toda la documentación entregada y aprobada requerida para el
            legajo estudiantil.
          </li>
          <li>
            Estar al día con las obligaciones financieras ante la universidad.
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>
<section id="calendario">
  <div class="container">
    <h1>Calendario de matriculación</h1>
    <p>
      El registro para las materias, seguirá el mismo proceso actual en el menú
      de matriculación en el campus y el inicio del cursado será el mismo
      establecido en el calendario académico por cuatrimestre, es decir:
    </p>

    <div class="card-container">
      <ng-container *ngFor="let item of periodos">
        <div class="card-periodo">
          <div class="tag">{{ item.tag }}</div>
          <h3>{{ item.name }}</h3>
          <p>{{ item.init }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</section>
<section id="materias">
  <div class="container">
    <h1>Materias</h1>
    <div class="card-grid">
      <ng-container *ngFor="let item of materias">
        <app-course-card [course]="item"></app-course-card>
      </ng-container>
    </div>
  </div>
</section>
