export interface ICourse{
    courseList: IResource[];
}

export interface IResource extends ICourseRegister{
    areas: number;
    autor: boolean;
    codigo: string;
    descripcion: string;
    duracion: number;
    duracion_unidad_tiempo: string;
    eje_tematico_ids: any[];
    fecha_actualizacion: string;
    fecha_creacion: string;
    id: number;
    imagen: string;
    name: string;
    nombre_publicacion: string;
    published: boolean;
    recursos: number;
    tag_id: string[];
}

export interface ITag {
    id: number;
    name: string;
}

export interface IDetail {
    id: number;
    publication_name: string;
    tags: ITag[];
    project_100: boolean;
    progress: number;
    image_url: string;
    description: string;
}

export interface ICourseRegister {
    date: string;
    detail: IDetail;
    description: string;
    state: string;
    type: string;
}
