import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CarreraPrincipal, PlanEstudio } from 'src/app/shared/interfaces/ICarrera';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-planes-estudio',
  templateUrl: './planes-estudio.component.html',
  styleUrls: ['./planes-estudio.component.scss'],
})
export class PlanesEstudioComponent implements OnInit {
  title = 'Mi plan de estudio';

  public user$: Observable<any>;

  public carreras: CarreraPrincipal[];

  isLoading = false;
  isError = false;

  selected = new UntypedFormControl(0);

  constructor(private store: Store<UserState>, private service: DataService) {
    this.user$ = store.select('user');
  }

  ngOnInit(): void {
    this.user$.subscribe((data) => {
      this.carreras = data.carreras;
    });
  }

  normalizeDataTable(object: PlanEstudio[]): any[] {
    const res = [];

    const group = object.reduce((r, a) => {
      /* console.log('a', a);
      console.log('r', r); */
      r[a.periodo] = [...(r[a.periodo] || []), a];
      return r;
    }, {});

    let subArrayGoup = [];
    let subArray = [];

    // eslint-disable-next-line guard-for-in
    for (const groupItem in group) {
      subArrayGoup = [];
      // eslint-disable-next-line guard-for-in
      for (const array in group[groupItem]) {
        subArray = [];

        subArray.push(group[groupItem][array].asignatura);
        subArray.push(group[groupItem][array].codigo);
        subArray.push(group[groupItem][array].credito);
        subArray.push(group[groupItem][array].Prerequisito);
        subArray.push(group[groupItem][array].periodo);

        subArrayGoup.push(subArray);
      }
      res.push(subArrayGoup);
    }

    const aux = res[0];
    res.splice(0, 1);
    res.push(aux);

    return res;
  }

  normalizeUserTable(object: CarreraPrincipal): any[] {
    return [
      ['Carrera', object.carrera],
      ['Resolución', object.resolucion],
      ['Modalidad', object.modalidad],
      ['Aprobación', object.aprobacion],
    ];
  }

  generatePdf(object): void {
    this.isLoading = true;
    const date = new Date().toLocaleDateString();

    const headNote = [
      ['Asignatura', 'Código', 'Créditos', 'Pre-requisito', 'Periodo'],
    ];
    const planDataFiltered = object.plan.filter(data => data.tipo !== 'to_elected_english');
    const dataNote = this.normalizeDataTable(planDataFiltered);
    const headUser = [['Estado', 'Valor']];
    const dataUser = this.normalizeUserTable(object);
    /* const dataProm = [
      ['Promedio ponderado', object.promedio_ponderado.toFixed(2)],
      ['Índice Acumulado', object.indice_acumulado.toFixed(2)],
    ]; */
    const doc = new jsPDF('p', 'mm', 'a4');

    // MARCA AGUA
    /* let waterMark = new Image();
    waterMark.src = '../../../assets/images/bg/watermark2.png'; */
    // LOGO
    const img = new Image();
    img.src = '../../../assets/images/logos/logo-adenupa.png';
    doc.addImage(img, 'png', 20, 10, 30, 10);
    // ESTILO FONT
    doc.setTextColor(117, 117, 117);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor('#616161');
    // TITULO
    doc.text('PLAN DE ESTUDIO', 90, 17);
    // FECHA
    doc.text(date, 170, 17);

    // TABLAS HEADER
    autoTable(doc, {
      body: dataUser,
      bodyStyles: {
        lineColor: '#000000',
        lineWidth: 0.1,
        valign: 'middle',
        textColor: '#222222',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: '#e5e5e5',
          fontStyle: 'bold',
          cellWidth: 30,
        },
      },
      alternateRowStyles: {
        fillColor: false,
      },
      // theme : 'plain',
      tableLineColor: '#616161',
      startY: 30,
      didDrawCell: (data) => {},
    });

    // DATA TABLE
    let page;

    dataNote.forEach((data, index) => {
      const header =
        data[0][4] === 'ninguno' || data[0][4] === '0'
          ? 'ELECTIVAS'
          : `CUATRIMESTRE ${index + 1}`;
      let yCoordHeader;
      autoTable(doc, {
        head: [[header]],
        // theme : 'plain',
        headStyles: {
          fillColor: '#B31D15',
          fontStyle: 'bold',
          textColor: '#ffffff',
          cellPadding: 2,
          halign: 'center',
          valign: 'middle',
        },
        tableLineColor: '#00000000',
        didDrawCell: (lineData) => {
          yCoordHeader = lineData.table;
          page = yCoordHeader;
        },
      });

      let yCoordNotes;
      autoTable(doc, {
        head: headNote,
        body: data,
        headStyles: {
          fillColor: '#e5e5e5',
          fontStyle: 'bold',
          textColor: '#222222',
          lineColor: '#000000',
          lineWidth: 0.1,
          valign: 'middle',
        },
        columnStyles: {
          0: {
            halign: 'left',
          },
          1: {
            halign: 'center',
          },
          2: {
            halign: 'center',
          },
          3: {
            halign: 'center',
          },
          4: {
            halign: 'center',
          },
          5: {
            halign: 'center',
          },
        },
        alternateRowStyles: {
          fillColor: false,
        },
        bodyStyles: {
          lineColor: '#000000',
          lineWidth: 0.1,
          valign: 'middle',
          textColor: '#222222',
        },
        tableLineColor: '#00000000',
        didDrawCell: (lineData) => {
          yCoordNotes = lineData.table;
          // console.log(yCoordNotes.finalY);
        },
      });
    });

    // console.log(yCoordNotes.finalY);

    let yCoordFooter;
    autoTable(doc, {
      head: [
        [
          `Reporte generado por ADEN University Panamá el día ${date}\nMás información: solicitudes@adenuniversity.edu.pa`,
        ],
      ],
      // theme : 'plain',
      headStyles: {
        fillColor: '#616161',
        fontStyle: 'bold',
        textColor: '#ffffff',
        cellPadding: 3,
        halign: 'center',
        valign: 'middle',
      },
      tableLineColor: '#00000000',
      didDrawCell: (data) => {
        yCoordFooter = data.table;
        page = yCoordFooter;
      },
    });

    /* doc.setFontSize(10);
    doc.text('Documento no oficial', 90, yCoordFooter.finalY + 10); */

    // doc.addImage(waterMark, 'png', 0, 0, 210, (yCoordProm.finalY + 20));
    // doc.addImage(waterMark, 'png', 0, 0, 210, 0);

    // MARCA DE AGUA
    /* for (
      let pageNumber = 1;
      pageNumber <= doc.getNumberOfPages();
      pageNumber++
    ) {
      doc.setPage(pageNumber);
      doc.addImage(waterMark, 'png', 0, 0, 210, 300);
    } */

    doc.save(
      'PLAN DE ESTUDIO ADENU - ' +
        `${object.carrera.toUpperCase().replaceAll('.', '')} - ` +
        date
    );
    this.isLoading = false;
  }
}
