import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
})
export class SlideComponent {
  @Input() data: any;
  cmsUrl = environment.cmsUrl;

  constructor() {}

  goTo() {
    if (
      this.data.attributes.CTA_link !== null &&
      this.data.attributes.CTA_link !== ''
    ) {
      window.open(this.data.attributes.CTA_link, '_blank');
    }
  }
}
