import { Action } from '@ngrx/store';
import { types } from '../types/types';

export const GET_ACADEMIC = types.GET_ACADEMIC;

export class GetAcademic implements Action {
  readonly type = GET_ACADEMIC;
  constructor(public payload: any) {}
}

export type AcademicAdActions = GetAcademic;
