<div
  class="card-container"
  [ngStyle]="{
    'background-color': color + '1A',
    border: '1px dashed' + color
  }"
>
  <div class="icon absolute">
    <mat-icon
      class="material-icons-round"
      [ngStyle]="{
        color: color
      }"
      >{{ icon }}</mat-icon
    >
  </div>

  <div class="card-body">
    <h3
      [ngStyle]="{
        color: color
      }"
    >
      {{ title }}
    </h3>
    <div class="flex" [innerHtml]="description | html"></div>
  </div>
</div>
