<div class="container-bg">
  <div class="container">
    <div class="heading">
      <div class="title white">Convenios Internacionales</div>
    </div>

    <div class="paises-grid">
      <ng-container *ngFor="let university of universities">
        <!-- <div class="card-pais" (click)="openDetailsDialog(university)">
          <div class="header">
            <div class="circle-img">
              <img [src]="university.logo" [alt]="university.country" />
            </div>
            <div class="title">{{ university.name }}</div>
            <div class="country">{{ university.country }}</div>
          </div>
          <div class="button-container">
            <a
              mat-flat-button
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
              [href]="university.web"
            >
              <span class="material-icons"> language </span> Web
            </a>
            <button
              mat-flat-button
              color="primary"
            >
              <span class="material-icons"> more_vert </span> Detalles
            </button>
          </div>
        </div> -->
        <div class="university-card" (click)="openLink(university.link)">
          <div class="uni-logo">
            <div class="face">
              <img
                [src]="
                  '../../../../assets/images/content/paises/' +
                  university.img +
                  '.png'
                "
                [alt]="university.name"
              />
            </div>

            <div class="face back">
              <img
                [src]="
                  '../../../../assets/images/content/paises/' +
                  university.flag +
                  '.png'
                "
                [alt]="university.name"
              />
            </div>
          </div>
          <div class="text-body">
            <div class="title">{{ university.name }}</div>
            <div class="country">{{ university.country }}</div>
          </div>
          <div class="arrow">
            Más info<mat-icon class="material-icons-round"
              >arrow_forward</mat-icon
            >
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
