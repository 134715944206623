<div class="sticky-header">
  <app-image-header
    [logo]="'/images/content/adenpolis/logo-adenpolis-color.png'"
    [title]="
      configs
        ? 'Candidatos y elecciones ' + configs.year
        : 'Candidatos y elecciones'
    "
    [description]="
      '¡Es tu momento de participar! Selecciona al candidato que más te representa y envía tu voto.'
    "
    [imgURL]="'/images/content/adenpolis/header-adenpolis-votacion.png'"
    [titleColor]="'#b31d15'"
  ></app-image-header>
  <app-onepage-nav
    [links]="navLinks"
    [urlBase]="'comite-estudiantil/elecciones'"
    [activeLink]="activeLink"
  ></app-onepage-nav>
</div>

<section id="candidatos" *ngIf="configs && configs.mostrar_candidatos">
  <div class="container">
    <div class="heading">
      <h1>Candidatos elecciones {{ configs.year }}</h1>
      <h4>Modalidad {{ user.carreras[0].modalidad }}</h4>
    </div>

    <p class="center-text">
      Estos son todos los candidatos de todas las carreras seleccionados para
      participar de las elecciones de este año. ¡Hecha un vistazo a sus
      discursos!
    </p>
    <div class="grid-container">
      <ng-container *ngFor="let item of candidates">
        <app-card-candidato [candidate]="item"></app-card-candidato>
      </ng-container>
    </div>
  </div>
</section>
<section id="reglas">
  <div class="container">
    <div class="body">
      <div class="content">
        <h1>Reglas</h1>
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿En qué consisten las elecciones?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Cada alumno podrá votar por <strong>1 (UN) candidato</strong> a
              ser representante de su carrera. En caso de estar inscripto en más
              de una carrera, sólo votarás por el representante de
              <strong>tu carrera principal</strong>.
            </p>
            <p>
              La votación es <strong>anónima y voluntaria</strong>, y una vez
              confirmada no podrás modificarla ni anularla.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Qué requisitos debo cumplir para poder votar?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul>
              <li>Ser alumno regular de una carrera de grado en ADENU.</li>
              <li>Estar al día con los pagos de cuotas</li>
            </ul>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Cuándo son las próximas elecciones?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p *ngIf="configs">
              Las elecciones se habilitarán desde el
              <strong>{{
                moment(configs.inicio_votaciones).locale("es").format("LL")
              }}</strong>
              hasta el
              <strong
                >{{ moment(configs.fin_votaciones).locale("es").format("LL") }}
              </strong>
              inclusive.
            </p>
            <p *ngIf="!configs">
              Aún no hay un periodo de elecciones definido.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Por qué medio puedo dar mi voto?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ol>
              <li>
                Desde el Campus Académico en la sección
                <a routerLink="/comite-estudiantil/elecciones#votacion"
                  >votacion</a
                >
              </li>
              <li>
                En tu sede de cursado se habilitarán ordenadores desde donde
                podrás realizar tu votación con asistencia del personal
                académico
              </li>
            </ol>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="image">
        <img
          class="full-width"
          src="../../../assets/images/content/adenpolis/reglas-eleccion.png"
          alt=""
        />
      </div>
    </div>
  </div>
</section>
<section id="votacion">
  <div class="container">
    <h1>Votación</h1>
    <!-- SIN ELECCIÓN HABILITADA -->
    <div
      id="no-election"
      class="notfound-container main-shadow mb-5"
      *ngIf="
        !currentElection &&
        configs &&
        !(user$ | async)?.votacion_comite &&
        !alreadyVoted &&
        !endedElections
      "
    >
      <img
        src="../../../../assets/images/content/adenpolis/time-elecciones.svg"
        alt=""
      />
      <h2>¡Todavía no inician las elecciones!</h2>
      <p>
        Las próximas elecciones se realizarán desde el
        {{ moment(configs.inicio_votaciones).locale("es").format("LL") }} hasta
        el {{ moment(configs.fin_votaciones).locale("es").format("LL") }}
      </p>
      <div class="button-container">
        <button
          mat-flat-button
          *ngIf="!isPastEvent"
          color="primary"
          aria-label="añadir a mi calendario"
          matTooltip="Añadir a mi calendario"
          [matMenuTriggerFor]="menu"
          (click)="getCalendarEvent()"
        >
          Añadir a mi calendario
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let item of links">
            <a mat-menu-item [href]="item.url" target="_blank">{{
              item.name
            }}</a>
          </ng-container>
        </mat-menu>
      </div>
    </div>
    <!-- ELECCIÓN TERMINADA -->
    <div
      id="no-election-2"
      class="notfound-container main-shadow"
      *ngIf="
        endedElections &&
        configs &&
        !(user$ | async)?.votacion_comite &&
        !alreadyVoted
      "
    >
      <img
        src="../../../../assets/images/content/adenpolis/time-elecciones.svg"
        alt=""
      />
      <h2>¡El periodo de votación ha terminado!</h2>
      <p>
        El último proceso de elección cerró el día
        {{ moment(configs.fin_votaciones).locale("es").format("LL") }}
      </p>
      <button
        mat-flat-button
        color="primary"
        *ngIf="configs && configs.mostrar_seleccionados"
        (click)="goToSeleccionados()"
      >
        ¡Ver seleccionados!
      </button>
    </div>
    <!-- YA VOTÓ -->
    <div
      id="already-voted"
      class="notfound-container main-shadow mb-5"
      *ngIf="(user$ | async)?.votacion_comite || alreadyVoted"
    >
      <img
        src="../../../../assets/images/content/adenpolis/votacion-ok.svg"
        alt=""
      />
      <h2>¡Ya votaste!</h2>
      <p>
        ¡Gracias por participar en las elecciones del Comité! Muy pronto
        conocerás a los seleccionados.
      </p>
      <button
        mat-flat-button
        color="primary"
        *ngIf="configs && configs.mostrar_seleccionados"
        (click)="goToSeleccionados()"
      >
        ¡Ver seleccionados!
      </button>
    </div>
    <!-- FORMULARIO DE ELECCIÓN -->
    <div
      id="election-form"
      class="card-item"
      *ngIf="
        currentElection &&
        !(user$ | async)?.votacion_comite &&
        !sendingVote &&
        !alreadyVoted
      "
    >
      <div class="heading flex">
        <h3>
          ¡Elige al líder de tu carrera!
          <strong> </strong>
        </h3>
        <h2>
          {{
            (user$ | async)?.carreras && (user$ | async)?.carreras[0].carrera
          }}
        </h2>
        <h4>
          {{
            (user$ | async)?.carreras &&
              "Modalidad " + (user$ | async)?.carreras[0].modalidad
          }}
        </h4>
      </div>

      <p>
        A continuación verás únicamente a los candidatos a líder de tu misma
        carrera y modalidad, selecciona uno y confirma tu voto.
      </p>
      <p>
        ¡Recuerda! una vez confirmada la votación no podrás modificarla ni
        anularla.
      </p>
      <ng-container *ngIf="!noCareerCandidates">
        <div class="grid-container">
          <ng-container *ngFor="let item of careerCandidates"
            ><app-card-candidato-select
              [candidate]="item"
              [selected]="
                selectedCandidate &&
                item.attributes.sis_id === selectedCandidate.attributes.sis_id
              "
              (click)="selectCandidate(item)"
            ></app-card-candidato-select>
          </ng-container>
        </div>

        <button
          mat-flat-button
          [disabled]="!selectedCandidate"
          color="primary"
          (click)="openConfirmDialog()"
        >
          Confirmar mi elección
        </button>
      </ng-container>
      <div class="notfound-container empty" *ngIf="noCareerCandidates">
        <mat-icon class="material-icons-round">error_outline</mat-icon>
        <h2>No pudimos encontrar candidatos</h2>
        <p>
          Al parecer, no se presentó ningún candidato para tu misma carrera y
          modalidad.
        </p>
        <div class="button-container">
          <button mat-flat-button color="primary" (click)="getAllCandidates()">
            Volver a buscar
          </button>
        </div>
      </div>
    </div>
    <!-- ENVIANDO -->
    <div class="notfound-container main-shadow mb-5" *ngIf="sendingVote">
      <div class="spinner">
        <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
      </div>
      Enviando...
    </div>
  </div>
</section>

<section id="seleccionados" *ngIf="configs && configs.mostrar_seleccionados">
  <div class="container">
    <ng-container *ngIf="winnersByHouse">
      <div class="heading">
        <h1>Seleccionados elecciones {{ configs.year }}</h1>
        <h4>Modalidad {{ user.carreras[0].modalidad }}</h4>
      </div>

      <p>¡Felicitaciones!</p>
      <ng-container *ngFor="let item of winnersByHouse | keyvalue">
        <div class="house-container">
          <div class="house">
            <img
              [src]="
                '../../../assets/images/content/adenpolis/' + item.key + '.png'
              "
              [alt]="'Casa ' + item.key"
            />
            <h2>{{ item.key }}</h2>
          </div>
          <div class="grid-container">
            <ng-container *ngFor="let winner of item.value">
              <app-winner-card
                [candidate]="winner"
                [showShadow]="false"
                [showVotes]="false"
              ></app-winner-card>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="groupedWinners">
      <div class="heading">
        <h1 class="mt-5">Resultados de votación por carrera</h1>
        <h4>Modalidad {{ user.carreras[0].modalidad }}</h4>
      </div>

      <p>
        Chequea el recuento de votos de cada uno de los candidatos de todas las
        carreras de tu modalidad
      </p>
      <mat-accordion>
        <ng-container *ngFor="let item of groupedWinners | keyvalue">
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ item.key }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="grid-container">
              <ng-container
                *ngFor="let item of item.value | sort : 'votes' : 'des'"
              >
                <app-winner-card [candidate]="item"></app-winner-card>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </ng-container>
  </div>
</section>
