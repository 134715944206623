<div class="profile-content card-aden height d-flex justify-content-between">
  <div class="profile">
    <div class="profile">
      <div class="image mr-4">
        <img
          src="{{
            (user$ | async)?.foto64
              ? (user$ | async)?.foto64
              : 'assets/images/logos/avatar.png'
          }}"
          alt=""
        />
        <div (click)="showModalPhoto()" class="upload-image bg-primary-aden">
          <i class="fas fa-upload"></i>
        </div>
      </div>
      <div class="data">
        <h2>
          <b> {{ (user$ | async)?.nombre }}</b>
        </h2>
        <div class="legajo" *ngIf="(user$ | async)?.legajo">
          ID: {{ (user$ | async)?.legajo }}
        </div>
      </div>
    </div>
  </div>
  <div class="routes">
    <div class="text-center" title="Mis Notas" [routerLink]="['../notas']">
      <mat-icon class="material-icons-round">import_contacts</mat-icon>
      <p>Mis Notas</p>
    </div>
    <div
      class="text-center"
      title="Estado Financiero"
      [routerLink]="['../estado-financiero']"
    >
      <mat-icon class="material-icons-round">account_balance</mat-icon>
      <p>Estado Financiero</p>
    </div>
    <div
      class="text-center"
      title="Academic Advisor"
      [routerLink]="['../academic-advisor']"
    >
      <mat-icon class="material-icons-round">supervised_user_circle</mat-icon>
      <p>Academic Advisor</p>
    </div>
  </div>
</div>
