<div>
  <h1>¡Bienvenido a nuestro Formulario de Solicitudes!</h1>
  <h4>
    A través de este formulario podrás realizar tus solicitudes de trámites.
    Completa el formulario, envíalo y recibe tu ticket de seguimiento.
  </h4>
  <form *ngIf="options !== null && !error && !loading" class="">
    <div class="row">
      <mat-form-field
      style="width: 100%"
      class="example-full-width"
      appearance="outline"
    >
    <mat-label>Tipo de caso</mat-label>
    <mat-select [formControl]="formulario['controls'].type">
      <mat-option value="Consulta">Consulta</mat-option>
      <mat-option value="Solicitud">Solicitud</mat-option>
      <mat-option value="Reclamo">Reclamo</mat-option>
    </mat-select>
  </mat-form-field>
      <mat-form-field
      style="width: 100%"
      class="example-full-width"
      appearance="outline"
    >
    <mat-label>Categoria</mat-label>
    <mat-select [formControl]="formulario['controls'].category" (selectionChange)="onCategorieSelected($event)">
      <mat-option *ngFor="let peticion of solicitudData" [value]="peticion">
        {{peticion.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field
      style="width: 100%"
      class="example-full-width"
      appearance="outline"
    >
    <mat-label>Subcategoria</mat-label>
    <mat-select [formControl]="formulario['controls'].subCategory" [disabled]="!categorieSelected">
      <mat-option 
      *ngFor="let categorie of categorieSelected?.categories" 
      [value]="categorie.value">
        {{categorie.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
    </div>
    <mat-form-field
      style="width: 100%"
      class="example-full-width"
      appearance="outline"
    >
      <mat-label>Asunto</mat-label>
      <input
        matInput
        placeholder=""
        value=""
        [formControl]="formulario.get('subject')"
        required
      />
      <mat-error *ngIf="formulario.get('subject').hasError('required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Descripción</mat-label>
      <textarea
        matInput
        #descripcion
        placeholder=""
        [formControl]="formulario.get('description')"
        required
      ></textarea>
      <mat-hint align="end">{{ descripcion.value.length }} / 800</mat-hint>
      <mat-error *ngIf="formulario.get('description').hasError('required')">
        Este campo es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
		<mat-form-field style="width: 100%;">
			<mat-label>Cargar archivos</mat-label>
			<button mat-icon-button matPrefix (click)="f_input.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
			<input type="text" readonly matInput [formControl]="formulario.get('caseFiles')" />
			<input
        type="file"
        multiple
        hidden
        #f_input
        (change)="handleFileInputChange(f_input.files)"
      />
			<mat-error>this field is required</mat-error>
		</mat-form-field>
    <div class="footer">
      <button
        mat-stroked-button
        color="primary"
        style="border: #b31d15 solid 1px !important"
        (click)="newForm()"
      >
        Resetear
      </button>
      &nbsp;&nbsp;
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="!formulario.valid || sending === true"
        (click)="sendForm()"
      >
        <span id="send-text">{{
          sending ? "Generando ticket" : "Generar ticket"
        }}</span>
        <div *ngIf="sending" id="loader" class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </button>
    </div>
  </form>
  <div class="charging" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div class="error" *ngIf="error && !loading">
    <mat-icon class="material-icons-round">update</mat-icon>
    <p>
      No pudimos cargar el formulario, inténtalo de nuevo dentro de unos
      segundos
    </p>
    <button mat-flat-button color="primary" (click)="getSelects()">
      Volver a cargar
    </button>
  </div>
</div>
