import { Component, Input, OnInit } from '@angular/core';
import SwiperCore, {
  SwiperOptions,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Virtual,
  Swiper,
} from 'swiper';

SwiperCore.use([Autoplay, Navigation, Pagination, Scrollbar, A11y, Virtual]);

@Component({
  selector: 'app-student-status',
  templateUrl: './student-status.component.html',
  styleUrls: ['./student-status.component.scss'],
})
export class StudentStatusComponent implements OnInit {
  @Input() carreras;

  swiper;

  constructor() {}

  ngOnInit(): void {
    this.swiper = new Swiper('.swiper', this.swiperConfigs);
    console.log(this.carreras);
  }

  swiperConfigs: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    virtual:true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
  };

  onSwiper(swiper) {}
  onSlideChange() {}
}
