import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TabConfigService {
  private tabMappings = {
    'my-course': [
      { label: 'Mis notas', route: '/notas' },
      { label: 'Plan de estudio', route: '/plan-estudio' },
      { label: 'Horarios', route: '/horarios' },
      { label: 'Cursos', route: '/courses' }
    ],
    'formalities': [
      { label: 'Matriculaciones', route: '/matriculaciones' },
      { label: 'Trámites académicos', route: '/tramitesacademicos' },
      { label: 'Documentacion', route: '/perfil' },
      { label: 'Estado financiero', route: '/estado-financiero' },
      { label: 'Órdenes de compra', route: '/mis-ordenes' },
      { label: 'Creacion de solicitud', route: '/peticiones-reclamos' },
      
    ],
  };

  getTabsForContext(context: string) {
    return this.tabMappings[context] || [];
  }
}
