import { environment } from 'src/environments/environment';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-candidato-select',
  templateUrl: './card-candidato-select.component.html',
  styleUrls: ['./card-candidato-select.component.scss'],
})
export class CardCandidatoSelectComponent {
  @Input() candidate;
  @Input() selected: boolean = false;
  cmsUrl = environment.cmsUrl;
  constructor() {}
}
