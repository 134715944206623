<div class="card-container">
  <div class="heading">
    <div class="chip-cuota">CUOTA #{{ payment.cuota }}</div>
    <div class="status-container">
      <div class="status">{{ status }}</div>
      <div class="date">
        VTO. {{ payment.fecha_vencimiento.replaceAll("-", "/") }}
      </div>
    </div>
  </div>
  <div class="card-content">
    <div class="body">
      <div class="dilay grey">
        {{
          (status === "Pendiente" || status === "Vencido") &&
          payment.monto === payment.deuda
            ? "Monto a pagar"
            : "Monto pagado"
        }}
      </div>
      <div class="price">
        <div class="currency">USD</div>
        <div class="number">
          {{ diffMonto ? payment.monto - payment.deuda : this.payment.monto.toFixed(2) }}
        </div>
      </div>
      <div class="dilay green" *ngIf="payment.conciliado === 'SI' && fechaPago">
        PAGADO EL {{ payment.fecha_pago.replaceAll("-", "/") }}
      </div>

      <div
        class="dilay text-blocked"
        *ngIf="status === 'En deuda' && payment.deuda > 0"
      >
        SALDO ADEUDADO POR EL ALUMNO: USD {{ payment.deuda }}
      </div>

      <div class="dilay red" *ngIf="status === 'Vencido'">
        {{ diffDays }} DÍAS DE RETRASO
      </div>
    </div>
    <div class="status-badge green-bg" *ngIf="status === 'Pagado'">
      <span class="material-icons-round"> check </span>
    </div>
    <div class="currency-container">
      <div class="dilay grey" *ngIf="diffMonto">Monto a pagar</div>
      <div *ngIf="diffMonto" class="price diffMonto">
        <div class="currency">USD</div>
        <div class="number">
          {{ payment.deuda }}
        </div>
      </div>
      <div [class]="diffMonto ? 'status-badge yellow-bg diff-monto-badge' : 'status-badge yellow-bg'" *ngIf="status === 'Pendiente'">
        <span class="material-icons-round"> schedule </span>
      </div>
    </div>
    <div
      class="status-badge bg-blocked"
      *ngIf="status === 'Vencido' || status === 'En deuda'"
    >
      <span class="material-icons-round"> close </span>
    </div>
  </div>
</div>
