import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-home-layout',
  template: `
    <app-navigation *ngIf="!isUpload" [showFooter]="true">
      <router-outlet></router-outlet>
    </app-navigation>
    <router-outlet *ngIf="isUpload"></router-outlet>
    <tour-step-template> </tour-step-template>
  `,
  styles: [],
})
export class HomeLayoutComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  isUpload = false;
  ngOnInit(): void {
    this.route.url.subscribe((data) => {
      this.isUpload = data[0].path === 'upload';
    })
    console.log(this.route.url)
  }
}
