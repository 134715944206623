<div class="card-container">
  <img
    [src]="cmsUrl + candidate.attributes.avatar.data.attributes.url"
    alt=""
    *ngIf="candidate.attributes.avatar.data"
  />
  <img
    src="../../../../../assets/images/logos/avatar.png"
    alt=""
    *ngIf="!candidate.attributes.avatar.data"
  />
  <h2>{{ candidate.attributes.full_name }}</h2>
  <h3>{{ candidate.attributes.career }}</h3>
  <p>"{{ candidate.attributes.reasons_to_apply }}"</p>
  <button
    mat-button
    color="primary"
    *ngIf="
      this.candidate.attributes.link_video_campaign &&
      this.candidate.attributes.link_video_campaign !== ''
    "
    (click)="openSpeech()"
  >
    <div class="btn-content">
      <mat-icon class="material-icons-rounded">play_circle</mat-icon> Ver
      discurso
    </div>
  </button>
</div>
