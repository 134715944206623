import { Component } from '@angular/core';

@Component({
  selector: 'app-academic-advisor',
  templateUrl: './academic-advisor.component.html',
  styleUrls: ['./academic-advisor.component.scss'],
})
export class AcademicAdvisorComponent {
  title = 'Academic Advisor';

  constructor() {}
}
