<div class="sticky-header"><app-header [title]="'Biblioteca'"></app-header></div>
<div class="background">
    <div class="main">
        <div id="buscador" class="buscador" *ngIf="!isLoading && !isError">
            <div class="form-field">
              <mat-icon class="material-icons-round">search</mat-icon>
              <input
                matInput
                type="search"
                placeholder="Buscar"
                (keyup)="search($event)"
                #input
                [(ngModel)]="searchValue"
              />
      
              <div class="suffix">
                <button
                  *ngIf="searchValue"
                  mat-icon-button
                  color="primary"
                  aria-label="Clear"
                  (click)="cleanSearch()"
                >
                  <mat-icon class="material-icons-round">close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        <mat-tab-group mat-stretch-tabs animationDuration="0ms">
            <mat-tab label="Microlearning"> 
                <div *ngIf="videosList.length && !isError && !isLoading" class="video-list animate__animated animate__fadeInRight animate__delay-.5s">
                    <app-video-card 
                        *ngFor="let video of videosList | filter : searchValue" 
                        [videoData]="video"
                        [goToVideo]="goToVideo">
                    </app-video-card>
                </div>
            </mat-tab>
            <mat-tab label="Simulador 360">
              <div *ngIf="secondCategoryList.length && !isError && !isLoading" class="video-list animate__animated animate__fadeInRight animate__delay-.5s">
                <app-video-card 
                    *ngFor="let video of secondCategoryList | filter : searchValue" 
                    [videoData]="video"
                    [goToVideo]="goToVideo">
                </app-video-card>
            </div>
            </mat-tab>
            <mat-tab label="Contenido Favorito">
              <div *ngIf="favList.length && !isError && !isLoading" class="video-list animate__animated animate__fadeInRight animate__delay-.5s">
                <app-video-card 
                    *ngFor="let video of favList | filter : searchValue" 
                    [videoData]="video"
                    [goToVideo]="goToVideo">
                </app-video-card>
            </div>
            </mat-tab>
          </mat-tab-group>
        <div *ngIf="isError && !isLoading" class="error-section">
          <div class="nf-container">
            <img class="not-found-img" src="../../../assets/images/content/Error-pana.svg" alt="" />
            <h1 class="">Ups.. ha ocurrido un problema. Intente mas tarde</h1>
            <button mat-flat-button color="primary" routerLink="/home">Volver al inicio</button>
          </div>
        </div>
        <div *ngIf="isLoading" class="spinner">
            <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
        </div>
    </div>
</div>