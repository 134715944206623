<div class="sticky-header">
  <app-header [title]="'Trámites Académicos'"></app-header>
</div>

<div class="container" id="tramites-academicos">
  <div class="main-grid">
    <div class="sidebar">
      <div class="side-card">
        <button (click)="goToModalidad('online')">
          <h1 >Cambiar</h1>
        </button>
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Qué tipo de trámites puedo realizar?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              A través del campus podrás solicitar
              <b>Certificaciones Oficiales</b> y <b>exámenes</b>
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title> ¿Cuándo puedo adquirirlos? </mat-panel-title>
            </mat-expansion-panel-header>
            <h4>Certificaciones:</h4>
            <p>
              En cualquier momento del cursado, siempre y cuando se encuentre al
              día con documentación y en estado Activo / Graduando.
            </p>
            <h4>Exámenes:</h4>
            <p>Se debe realizar durante un periodo académico en curso.</p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title> ¿Tienen costo? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Cada uno de acuerdo a su especialidad varia el costo, se puede
              validar en el momento de adquirirlos en la plataforma.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title> ¿Cómo puedo adquirirlos? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Ingresando al campus, seleccionando el tipo de solicitud a
              tramitar. Cuando se realice este proceso de selección el campus
              habilitara el carrito de compras para proceder con el pago.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Qué medios están habilitados para el pago?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Solo pago en línea (tarjeta debito o crédito)</p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Como obtengo el documento una vez realice el pago?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Una vez realizada la solicitud en el campus se habilitará un
              estado en el se podrá ver el avance de su trámite académico.
              Cuando registre en estado aprobado, a partir del 3er día hábil se
              estará enviado a tu correo electrónico.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="notfound-container main-shadow mb-5" *ngIf="loading">
      <mat-spinner class="spinner"></mat-spinner>
    </div>
    <div
      class="notfound-container main-shadow mb-5"
      *ngIf="!loading && (!productos || error)"
    >
      <img src="../../../assets/images/content/waiting.svg" alt="" />
      <h2>Productos no disponible</h2>
      <p>
        Parece que aún no hay productos disponibles o no pudimos encontrarlos,
        si crees que es un error no dudes en contactar a tu Academic Advisor o
        vuelve a intentar más tarde.
      </p>
      <div class="button-container">
        <button
          mat-stroked-button
          color="primary"
          routerLink="/academic-advisor"
        >
          Contactar Academic Advisor
        </button>
        <button mat-flat-button color="primary" (click)="getProducts()">
          Reintentar
        </button>
      </div>
    </div>
    <div class="content-column" *ngIf="!loading && productos.length > 0 && isDoc">
      <div class="heading main-shadow main-radius">
        <div class="buscador full-width fill">
          <div class="form-field">
            <mat-icon class="material-icons-round search"> search </mat-icon>
            <input
              matInput
              type="text"
              placeholder="Buscar por nombre"
              [(ngModel)]="searchValue"
            />
            <div class="suffix">
              <button
                *ngIf="searchValue"
                mat-icon-button
                color="primary"
                aria-label="Clear"
                (click)="cleanSearch()"
              >
                <mat-icon class="material-icons-round">close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="cart-button mobile">
          <button
            mat-flat-button
            class="full-width"
            color="primary"
            (click)="openCartDialog()"
          >
            <div class="content">
              <mat-icon
                class="material-icons-round"
                [matBadge]="units"
                [matBadgeHidden]="units < 1"
                matBadgeColor="accent"
                >shopping_cart</mat-icon
              >
              <div class="text">Mi carrito</div>
            </div>
          </button>
        </div>
        <div class="cart-button desktop">
          <button
            mat-flat-button
            color="primary"
            (click)="openCartDialog()"
            [mdePopoverTriggerFor]="appPopover"
            mdePopoverTriggerOn="hover"
            #popoverTrigger="mdePopoverTrigger"
          >
            <div class="content">
              <mat-icon
                class="material-icons-round"
                [matBadge]="units"
                [matBadgeHidden]="units < 1"
                matBadgeColor="accent"
                >shopping_cart</mat-icon
              >
              <div class="text">Mi carrito</div>
            </div>
          </button>
          <mde-popover
            id="pop-over"
            #appPopover="mdePopover"
            [mdePopoverOverlapTrigger]="false"
          >
            <div class="cart-preview" *ngIf="cart && cart.length > 0">
              <div class="cart">
                <ng-container *ngFor="let item of cart">
                  <div class="cart-line">
                    <div class="title">
                      <p>{{ item.quantity }}x {{ item.certificacion }}</p>
                      <p class="carrera">{{ item.carrera }}</p>
                    </div>

                    <div class="price">US$ {{ item.subtotal }}</div>
                  </div>
                </ng-container>
              </div>
              <div class="footer">
                <div class="cart-line">
                  <p>TOTAL</p>
                  <div class="price">US$ {{ total }}</div>
                </div>
              </div>
            </div>
            <div class="cart-preview empty" *ngIf="!cart || cart.length === 0">
              <img
                src="../../../assets/images/content/cart-pay.svg"
                alt="Empty cart"
              />
              <h2>Tu carrito está vacío</h2>
            </div>
          </mde-popover>
        </div>
      </div>
      <div class="products-container main-shadow main-radius">
        <mat-tab-group
          *ngIf="user && user.carreras.length > 0"
          class="nav-career pb-5"
          animationDuration="300ms"
          style="min-height: 100px"
          [selectedIndex]="checkIndexDefault()"
        >
          <div *ngFor="let carrera of user.carreras; let i = index">
            <mat-tab
            *ngIf="this.user" 
            [disabled]="
            user.carreras.length > 1 && 
            user.carreras[0].modalidad !== user.carreras[1].modalidad?
             true : false | async">

              <div id="body-asignaturas">
                <ng-template mat-tab-label>
                  <div class="custom-label">
                    <div class="career-label">
                      {{
                        i > 0 && carrera.modalidad === modalidad
                          ? "Segunda carrera " + carrera.modalidad
                          : "Carrera Principal " + carrera.modalidad
                      }}
                    </div>
                    <div>{{ carrera.carrera }}</div>
                  </div>
                </ng-template>
              </div>

              <div class="products-grid" *ngIf="user">
                <ng-container
                  *ngFor="let item of productos | filter : searchValue"
                >
                  <app-item-pecuniario-card
                    [item]="item"
                    [addProduct]="addProduct"
                    [updateProduct]="updateProduct"
                    [removeProduct]="removeProduct"
                    [carrera]="carrera"
                    [cartItem]="
                      getItemInCart(item.certificacion_id, carrera.id)
                    "
                    *ngIf="item.modalidad === carrera.modalidad"
                  ></app-item-pecuniario-card>
                </ng-container>
              </div>
            </mat-tab>
          </div>
        </mat-tab-group>
      </div>

      <div class="notfound-container mb-5" *ngIf="!user">
        <mat-spinner class="spinner"></mat-spinner>
      </div>
    </div>
    <div class="no-doc-main" *ngIf="!isDoc && !loading">
      <div class="card">
        <img class="no-doc-img" src="../../../assets/images/content/no-documentation.png">
        <h1>Lo sentimos, no puedes realizar trámites académicos en este momento</h1>
        <p>Parece que la documentación está incompleta. Si crees que es un error, no dudes en contactar a tu Academic Advisor.</p>
        <a routerLink="/academic-advisor" class="contact-button">Contactar Academic Advisor</a>
      </div>
    </div>
  </div>
</div>

<div class="soonContainer" *ngIf="user && (user.pago_atrasado || isBloq)">
  <div class="soonMessage">
    <img
      src="../../../assets/images/content/alert-bloqueo.png"
      alt="Pagos atrasados"
    />
    <h2>¡Sección bloqueada!</h2>
    <p>
      Para obtener más información contacta a tu Academic Advisor.
    </p>
    <a mat-flat-button color="primary" (click)="navigate('/home')"> Volver al inicio </a>
  </div>
</div>

