<div id="ceaden">
  <app-image-header
    [logo]="'/images/logos/logo-ceaden-white.png'"
    [imgURL]="'/images/bg/bg-ceaden2.png'"
    [titleColor]="'#ffffff'"
    [descriptionColor]="'#ffffff'"
    [buttonColor]="'#ffffff'"
  ></app-image-header>
  <app-onepage-nav
    [links]="links"
    [activeLink]="activeLink"
    [urlBase]="'comite-estudiantil'"
  ></app-onepage-nav>
  <section class="container" id="sobre-nosotros">
    <div class="body">
      <div class="content">
        <h1>¿Quiénes somos?</h1>
        <p>
          El centro de emprendimiento es una unidad del Grupo Educativo ADEN que
          tiene el objetivo de contribuir en la formación de profesionales con
          competencias emprendedoras, garantizando relación con un ecosistema
          emprendedor en Panamá y LATAM.
        </p>
        <p>
          Busca también reforzar la propuesta de valor de ADENU para sus
          estudiantes y para toda la comunidad y contribuir a impulsar el
          ecosistema de emprendimiento en América Latina.
        </p>
      </div>
      <div class="image">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/ErB1h85q7Kk?rel=0"
          title="Conoce nuestro centro de emprendimiento"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="center-body">
      <h1>Nuestro propósito</h1>
      <p>
        Impulsar la innovación y el emprendimiento responsables para ayudar a
        las personas a desarrollar negocios que mejoren su calidad de vida y
        contribuyan a la prosperidad de América Latina. <br /><br />
        Ser un actor en el crecimiento y fortalecimiento del ecosistema
        emprendedor en Panamá y LATAM, siendo parte de la red que lo fomenta y
        respalda a todos los emprendedores desde nuestra experiencia en la
        formación académica a los niveles de pregrado y postgrado.
      </p>
    </div>
    <div class="body">
      <div class="image">
        <img
          class="full-width"
          src="../../../assets/images/bg/ceaden-proposito2.png"
          alt=""
        />
      </div>
      <div class="content">
        <h1>Misión</h1>
        <p>
          Estimular el ecosistema del impulsar la innovación y el emprendimiento
          responsables para ayudar a las personas a desarrollar negocios que
          mejoren su calidad de vida y contribuyan a la prosperidad de América
          Latina.
        </p>
        <h1>Visión</h1>

        <p>
          Lograr que ADENU sea la Universidad más reconocida en América Latina
          por su apoyo al emprendimiento y la formación de emprendedores.
        </p>
        <h1>Valores</h1>
        <p>
          Innovación, integridad, compromiso, responsabilidad social, valentía,
          agradecimiento
        </p>
      </div>
    </div>
  </section>

  <section id="programas">
    <div class="container">
      <h1>Nuestros programas</h1>
      <div class="card-grid">
        <ng-container *ngFor="let item of programs">
          <app-program-card [program]="item"></app-program-card>
        </ng-container>
      </div>
    </div>
  </section>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <linearGradient id="grad1" x1="0%" y1="0%" x2="131%" y2="0%">
      <stop offset="0%" style="stop-color: rgba(231, 142, 16, 1)" />
      <stop offset="50%" style="stop-color: rgba(235, 167, 24, 1)" />
      <stop offset="100%" style="stop-color: rgba(239, 185, 72, 1)" />
    </linearGradient>
    <path
      fill="rgba(231, 142, 16, 1)"
      d="M0,288L80,261.3C160,235,320,181,480,186.7C640,192,800,256,960,277.3C1120,299,1280,277,1360,266.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
    ></path>
  </svg>

  <section id="alianzas">
    <div class="container">
      <h1>Alianzas estratégicas</h1>
      <p>
        Actualmente nos encontramos forjando alianzas estratégicas con
        instituciones que fomentan el emprendimiento, construyendo uniones que
        fortalecen el empuje para crear un escenario próspero para los negocios
        y emprendimientos.
      </p>

      <div class="logo-container">
        <img
          src="../../../assets/images/logos/alianzas/aeiLogo.png"
          alt="Alianza para el emprendimiento e innovación"
        />

        <img
          src="../../../assets/images/logos/alianzas/AmpymeLogo.png"
          alt="Autoridad de la micro, pequeña y mediana empresa. República de Panamá"
          class="big"
        />

        <img
          src="../../../assets/images/logos/alianzas/CiudadDelDaberLogo.png"
          alt="Ciudad del saber"
          class="small"
        />
      </div>
    </div>
  </section>
</div>
