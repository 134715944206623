import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-product-card',
  templateUrl: './simple-product-card.component.html',
  styleUrls: ['./simple-product-card.component.scss'],
})
export class SimpleProductCardComponent {
  @Input() product: any;
  @Input() removeProduct: any;
  @Input() updateProduct: any;
  @Input() actions = true;
  @Input() limitThisProduct: number;

  constructor() {}

  onAdd() {
    this.updateProduct(this.product, this.product.quantity + 1);
  }

  onRemove() {
    this.updateProduct(this.product, this.product.quantity - 1);
  }

  onDelete() {
    this.removeProduct(this.product);
  }
  fixTotal(){
    return (Math.round(this.product.subtotal* 100) / 100).toFixed(2)
  }
}
