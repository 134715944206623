<div class="main-container main-radius">
  <div id="buscador" class="buscador" *ngIf="!isLoading && !isError">
    <div class="form-field">
      <mat-icon class="material-icons-round">search</mat-icon>
      <input
        matInput
        type="search"
        placeholder="Buscar"
        (keyup)="search($event)"
        #input
        [(ngModel)]="searchValue"
      />

      <div class="suffix">
        <button
          *ngIf="searchValue"
          mat-icon-button
          color="primary"
          aria-label="Clear"
          (click)="cleanSearch()"
        >
          <mat-icon class="material-icons-round">close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div
    class="table-container main-radius shadow-200 main-border mt-3"
    *ngIf="!isLoading && !isError"
  >
    <!-- NEW TABLE -->

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z1">
      <ng-container
        [matColumnDef]="column.columnDef"
        [ngSwitch]="true"
        *ngFor="let column of tableColumns"
        [sticky]="column.columnDef === 'dia'"
      >
        <ng-container *ngSwitchCase="column.columnDef === 'dia'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            class="day-cell"
            [attr.rowspan]="getRowSpan('dia', i)"
            [style.display]="getRowSpan('dia', i) ? '' : 'none'"
          >
            <div class="chip">
              {{ column.cell(row) }}
            </div>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="column.columnDef === 'hora'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let i = index"
            [attr.rowspan]="getRowSpan('hora', i)"
            [style.display]="getRowSpan('hora', i) ? '' : 'none'"
          >
            {{ column.cell(row) }}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="column.columnDef === 'zoom'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a
              mat-mini-fab
              class="mat-elevation-z0"
              color="primary"
              aria-label="Entrar a zoom"
              [href]="column.cell(row)"
              target="_blank"
              rel="noopener noreferrer"
              [disabled]="column.cell(row) === ''"
            >
              <mat-icon class="material-icons-round">open_in_new</mat-icon>
            </a>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="column.columnDef === 'aula_canvas'">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <a
              mat-mini-fab
              class="mat-elevation-z0"
              color="primary"
              aria-label="Ver aula en Canvas"
              [href]="column.cell(row)"
              target="_blank"
              rel="noopener noreferrer"
              [disabled]="column.cell(row) === ''"
            >
              <mat-icon class="material-icons-round">open_in_new</mat-icon>
            </a>
          </td>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div *ngIf="column.cell(row) !== 'Sin datos'; else noData">
              {{ column.cell(row) }}
            </div>
            <ng-template #noData>
              <div class="no-data">
                {{ column.cell(row) }}
              </div>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="example-element-row"
      ></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7">
          <div class="no-result" *ngIf="searchValue !== ''">
            <img
              src="../../../assets/images/content/sin-resultados.svg"
              alt=""
            />
            <div class="text">No encontramos resultados para</div>
            <div class="searched-value">"{{ searchValue }}"</div>
          </div>
          <div class="no-result" *ngIf="horarios === []">
            <img
              src="../../../assets/images/content/sin-resultados.svg"
              alt=""
            />
            <div class="text">Aún no hay horarios disponibles</div>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <div class="mat-row" *ngIf="isError && !isLoading">
    <div class="mat-cell" colspan="7">
      <div class="no-result">
        <img src="../../../assets/images/content/sin-resultados.svg" alt="" />
        <div class="searched-value">Ups.. no encontramos tus horarios.</div>
        <div class="text">
          ¡Intenta de nuevo más tarde o contacta a tu Academic Advisor para
          verificar el problema!
        </div>
        <button mat-flat-button color="primary" (click)="console.log('hi')">
          Volver a cargar
        </button>
      </div>
    </div>
  </div>

  <div class="mat-row" *ngIf="isLoading && !isError">
    <div class="mat-cell" colspan="7">
      <div class="no-result">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
  <div class="paginator-container" id="paginator">
    <mat-paginator
      [pageSizeOptions]="[10, 15, 20]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
