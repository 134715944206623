<div id="status-container" class="main-shadow main-radius">
  <div class="header">
    <h2>Tu progreso</h2>
    <button mat-button color="primary" routerLink="/notas">Ver notas</button>
  </div>
  <div class="notfound-small-container" *ngIf="!carreras">
    <mat-spinner class="spinner"></mat-spinner>
  </div>
  <div class="swiper-container">
    <swiper (swiper)="onSwiper($event)" (slideChange)="onSlideChange()">
      <ng-template swiperSlide *ngFor="let carrera of carreras">
        <div class="status-slide">
          <div class="career-card">
            <div class="body">
              <h2>{{ carrera.carrera }}</h2>
              <div class="chip">{{ carrera.modalidad }}</div>
            </div>
            <div class="progress-column">
              <div class="circle-progress">
                <div class="current-value">
                  <h1>{{ carrera.estado_carrera.progreso }}%</h1>
                  <p>
                    <strong>{{ carrera.estado_carrera.aprobadas }}</strong
                    >/{{ carrera.estado_carrera.total }}
                    ASIGNATURAS
                  </p>
                </div>
                <round-progress
                  [current]="carrera.estado_carrera.progreso"
                  [max]="100"
                  [color]="'url(#gradient)'"
                  [gradient]="true"
                  [background]="'#f1f1f1'"
                  [radius]="60"
                  [stroke]="15"
                  [semicircle]="true"
                  [rounded]="true"
                  [clockwise]="true"
                  [responsive]="true"
                  [duration]="800"
                  [animation]="'easeInOutQuart'"
                  [animationDelay]="0"
                ></round-progress>

                <svg>
                  <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0%" stop-color="#35D0A5" />
                    <stop offset="100%" stop-color="#59D9B5" />
                  </linearGradient>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
