  import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CourseSummaryDialogComponent } from '../course-summary-dialog/course-summary-dialog.component';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent {
  @Input() course: any;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  /* ngOnInit(): void {
  } */

  takeAction() {
    if (this.course.hasOwnProperty('tag')) {
      this.router.navigate([this.course.tag], { relativeTo: this.activeRoute });
    } else {
      this.openCourseSummary();
    }
  }

  openCourseSummary(): void {
    const modal = this.dialog.open(CourseSummaryDialogComponent, {
      width: '800px',
      autoFocus: false,
      maxHeight: '90vh',
      data: {
        course: this.course,
      },
      panelClass: 'generic-alert',
    });
  }
}
