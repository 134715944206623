import { UntypedFormControl } from '@angular/forms';
import { UserState } from 'src/app/redux/reducers/user.reducer';
import { CarreraPrincipal, NotasCarreras } from './../../shared/interfaces/ICarrera';
import { Observable } from 'rxjs';
import { ExcelService } from './../../shared/services/excel.service';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from 'src/app/shared/services/data.service';
import { GradesState } from 'src/app/redux/reducers/grades.reducer';
import { Store } from '@ngrx/store';
import creditosAcademicos from './creditosAcademicos.json';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.scss'],
})
export class NotasComponent implements OnInit {
  public notes = [];
  public allNotes: number;
  title = 'Mis notas';
  public user$: Observable<any>;

  public carreras: NotasCarreras[] = [];

  isLoading = false;
  isError = false;

  selected = new UntypedFormControl(0);

  constructor(
    private service: DataService,
    private store: Store<GradesState>,
    private userStore: Store<UserState>,
    private alumnoService: AlumnoService,
    private specViewService: SpecViewService
  ) {
    this.user$ = userStore.select('user');
  }

  ngOnInit(): void {
    this.getNotas();
  }
  getNotas(){
    this.isLoading = true;
    this.alumnoService.getNotas().subscribe(res => {
      this.carreras = res.data;
      this.isLoading = false;
      this.isError = this.carreras[0].career_lines?.length ? false : true;
    })
  }
}
