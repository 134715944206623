<div id="contest">
  <app-head-concurso></app-head-concurso>
  <app-sobre-concurso id="sobre"></app-sobre-concurso>
  <app-premios-concurso id="premios"></app-premios-concurso>
  <app-ganadores-concurso id="ganadores"></app-ganadores-concurso>
  <!-- <app-cronograma-concurso id="cronograma"></app-cronograma-concurso> -->

  <!-- <app-contacto-concurso
    [user]="this.capitan"
    id="contacto"
  ></app-contacto-concurso> -->
  <!-- <app-form-concurso
    [userTeam]="this.userTeam"
    [capitan]="this.capitan"
    [hasTeam]="this.hasTeam"
    [getUser]="this.getUser"
    [checkUser]="this.checkUser"
    id="inscripcion"
  ></app-form-concurso> -->
</div>
