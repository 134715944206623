import { Component } from '@angular/core';

@Component({
  selector: 'app-cronograma-concurso',
  templateUrl: './cronograma-concurso.component.html',
  styleUrls: ['./cronograma-concurso.component.scss'],
})
export class CronogramaConcursoComponent {
  public cronograma = [
    {
      id: 1,
      date: '2 Julio',
      descripcion: 'Lanzamiento e inicio de inscripciones',
    },
    {
      id: 2,
      date: '12 Julio',
      descripcion: 'Inicio entrega de Propuestas Técnicas',
    },
    {
      id: 3,
      date: '16 Julio',
      descripcion: 'Asignación de tutores, fin inscripciones',
    },
    {
      id: 4,
      date: '6 Agosto',
      descripcion: 'Último día de entrega de Propuesta Técnica',
    },
    {
      id: 5,
      date: '13 Agosto',
      descripcion: 'Presentación ante el Comité Evaluador',
    },
    {
      id: 6,
      date: '20 Agosto',
      descripcion: 'Presentación de Ganadores',
    },
    {
      id: 7,
      date: '6 Septiembre',
      descripcion: 'Inicio Cursado',
    },
  ];

  constructor() {}
}
