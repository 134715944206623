import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoadVideos, ToggleFavorite } from 'src/app/redux/actions/videos.action';
import { IMicrocontentsRes, IVideo } from 'src/app/shared/interfaces/IVideo';
import { LibraryService } from 'src/app/shared/services/library.service';
import Player from '@vimeo/player';
import { ShareDialogComponent } from 'src/app/shared/components/dialogs/share-dialog/share-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private libraryService: LibraryService,
    private store: Store<any>,
    private dialog: MatDialog,
    private elRef: ElementRef,
    private router: Router
  ) {}

  videoData: IVideo;
  videoList: Array<IVideo>;
  videoId: number;
  sanitizerUrl;
  isLoading = false;
  isError = false;
  isChecked = false;
  newUrl = '';
  webUrl = environment.webUrl;
  player: Player;
  shouldInitializePlayer = true;
  @ViewChild('playerContainer') playerContainer: ElementRef;

  ngAfterViewInit() {
    this.route.params.subscribe(params => {
      this.videoId = params['id'];
      this.checkVideos();
    });
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  }

  checkVideos() {
    this.store.subscribe(data => {
      if (data.library.videosList.length) {
        this.videoList = data.library.videosList;
        this.videoData = this.libraryService.getVideoById(this.videoList, this.videoId);
        this.initializePlayer();
      } else {
        this.getAllVideos();
      }
    });
  }

  initializePlayer() {
    if(this.shouldInitializePlayer){
      console.log('a')
      if (this.player) {
        this.player.destroy();
      }
      this.player = new Player(this.playerContainer.nativeElement, { url: this.videoData.video_url });
      this.player.play().then(() => {
        console.log('play');
      });
      this.player.ready().catch(err => {
        this.isError = true;
        this.isLoading = false;
      });
      this.player.ready().then(() => {
        const iframe = this.elRef.nativeElement.querySelector('iframe');
        iframe.style.width = '57vw';
        iframe.style.height = '32vw';
      });
    }
  }

  goToVideo(id) {
    this.shouldInitializePlayer = true;
    this.router.navigateByUrl(this.router.url.replace(this.videoId.toString(), id.toString()), { replaceUrl: true });
  }

  getAllVideos() {
    this.isLoading = true;
    this.libraryService.getLibraryVideos().subscribe({
      next: (data: IMicrocontentsRes) => {
        this.store.dispatch(new LoadVideos(data.response_data));
        this.isLoading = false;
      },
      error: err => {
        this.isLoading = false;
        this.isError = true;
      }
    });
  }

  setFav() {
    if (!this.videoData.favorito) {
      this.libraryService.postFavVideo(32940, this.videoData.repo_id).subscribe(res => {
        console.log(res);
      });
    } else {
      this.libraryService.deleteFavVideo(32940, this.videoData.repo_id).subscribe(res => {
        console.log(res);
      });
    }
  }
  
  toggleFavorite() {
    this.shouldInitializePlayer = false;
    const action = new ToggleFavorite({
      videoId: this.videoData.repo_id,
      isFavorite: !this.videoData.favorito
    });
    this.store.dispatch(action);
  }

  openShareDialog() {
    this.dialog.open(ShareDialogComponent, {
      data: {
        title: '¿Te gustó el contenido?',
        subtitle: '¡Compártelo!',
        link: `${this.webUrl}/library/${this.videoData.repo_id}`
      },
      panelClass: 'generic-alert'
    });
  }
}