import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';

export const navigationTour: IMdStepOption[] = [
  {
    anchorId: 'sidenav',
    content:
      'El contenido principal de la página lo encontrarás en este menú aqui podrás ver cada una de las secciones disponibles',
    title: '¡Nuevo menú!',
    disablePageScrolling: true,
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
  },
  {
    anchorId: 'menuSecciones',
    content:
      'Los accesos se ordenan en secciones, expande el menú para ver el nombre de cada una',
    title: 'Nuevo orden',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
  },
  {
    anchorId: 'cursado',
    content: 'En esta sección encontrarás todo los referente a tu cursado',
    title: 'Mi cursado',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
  },
  {
    anchorId: 'tramitesServicios',
    content:
      'En esta sección encontrarás todos los acceso a trámites y servicios autogestionados',
    title: 'Trámites y servicios',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
  },
  {
    anchorId: 'bienestarEstudiantil',
    content:
      'En esta sección encontrarás todas las convocatorias que aportan al bienestar de nuestros alumnos',
    title: 'Bienestar Estudiantil',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
  },
  {
    anchorId: 'user-menu',
    content:
      'Al hacer click en este botón podrás ver las secciones realacionadas con tu cuenta. <br/><br/><strong>Haz click en tu foto para continuar.</strong>',
    title: 'Menú de usuario',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    nextOnAnchorClick: true,
  },
  {
    anchorId: 'user-profile-docs',
    content:
      'Asegúrate de tener los datos de tu perfil actualizados',
    title: 'Perfil',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'user-credential',
    content: 'Será tu credencial de identificación dentro de la universidad',
    title: 'Credencial virtual',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'user-dracmas',
    content:
      'Sistema de puntuación académica, ingresa para chequear tu puntaje',
    title: 'Puntuación Dracma',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'user-estado-financiero',
    content:
      'Conoce tu estado financiero en linea y descarga tu informe de estado financiero',
    title: 'Estado Financiero',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'user-orders',
    content: 'Aquí podrás ver tus órdenes de compra de Derechos Pecuniarios',
    title: 'Mis órdenes de compra',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'user-aa',
    content: 'Accede a los datos de contacto de tu Academic Advisor',
    title: 'Academic Advisor',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'user-manual',
    content:
      'Accede al manual del estudiantes donde encontrarás todo lo que necesitas saber sobre tu cursado en ADENU',
    title: 'Manual del Estudiante',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'user-logout',
    content: 'Cierra sesión cuando ya no utilices el campus',
    title: 'Cerrar Sesión',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'user-start-tour',
    content:
      '¡Vuelve a realizar el tour cuantas veces quieras! <br/><br/>Ahora sí, haz click en <strong>Finalizar</strong> para completar el tour.',
    title: 'Tour de navegación',
    route: '',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
];

export const cartTour: IMdStepOption[] = [
  {
    anchorId: 'productosDisponibles',
    content:
      'Aquí encontrarás el listado de créditos que tengas disponibles para comprar',
    title: 'Créditos disponibles',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'seleccionaCantidad0',
    content: 'Selecciona la cantidad de créditos que quieres adquirir',
    title: 'Seleccionar cantidad',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'agregarCarrito0',
    content: 'Selecciona la cantidad de créditos que quieres adquirir',
    title: 'Añade al carrito',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
    nextOnAnchorClick: true,
  },
  {
    anchorId: 'continuarCompra',
    content:
      'Una vez que hayas seleccionado todos los productos, haz click aquí para continuar con la compra',
    title: 'Continuar',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
    nextOnAnchorClick: true,
  },
  {
    anchorId: 'formaPago',
    content: 'Selecciona la forma en la que quieres pagar',
    title: 'Forma de pago',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'pagoTransferencia',
    content:
      'Al seleccionar esta opción, deberás realizar el pago por transferencia bancaria',
    title: 'Pago con transferencia',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    nextOnAnchorClick: true,
  },
  {
    anchorId: 'seleccionCuotasTransferencia',
    content: 'Selecciona en cuántas cuotas quieres realizar el pago',
    title: 'Selección cuotas',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'realizaTransferencia',
    content:
      'Realiza la transferencia a la cuenta que aparece aquí. Si haces el pago en cuotas sólo deberás transferir el valor de la primera cuota, recuerda colocar tu ID de estudiante como asunto o descripción de la operación',
    title: 'Realiza transferencia',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'numeroOperacion',
    content:
      'Una vez realizada la transferencia, coloca el número de operación aqui',
    title: 'Número de operación',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'envioComprobante',
    content:
      'Una vez realizada la transferencia, recuerda enviar el comprobante haciendo click aquí',
    title: 'Enviar comprobante',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'pagoOnline',
    content:
      'Al seleccionar esta opción, realizarás el pago online a través del checkout de ADENU',
    title: 'Pago online',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
    nextOnAnchorClick: true,
  },
  {
    anchorId: 'seleccionCuotasOnline',
    content: 'Selecciona en cuántas cuotas quieres realizar el pago',
    title: 'Selección cuotas',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'pagoDiferido',
    content:
      'Elige si quieres diferir tu pago y selecciona una fecha a la que lo quieras diferir',
    title: 'Difiere el pago',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'finalizarCompra',
    content:
      'Revisa los totales una última vez y haz click en "Generar link de pago" o "Finalizar compra" para terminar',
    title: '¡Finaliza la compra!',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Terminar tour',
    isOptional: true,
  },
];

export const matriculacionesTour: IMdStepOption[] = [
  {
    anchorId: 'asigDisponibles',
    content:
      'Aquí encontrarás la cantidad de créditos disponibles que tienes para matricular',
    title: 'Asignaturas disponibles',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'compraCreditos',
    content:
      'Si no tienes créditos disponibles para tu matriculación, puedes comprarlos desde aquí',
    title: 'Compra asignaturas',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'tabAsignaturas',
    content:
      'Aquí encontrarás la o las carreras que tengas disponibles para matriculación',
    title: 'Carreras',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'asignaturasRegulares1',
    content: 'Estas son tus asignaturas disponibles para matricular',
    title: 'Asignaturas a matricular',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  /* {
   anchorId: 'masInfo0',
   content: 'Has click aquí para conocer más de la asignatura',
   title: 'Más información',
   prevBtnTitle: 'Anterior',
   nextBtnTitle: 'Siguiente',
   endBtnTitle: 'Finalizar',
   isOptional: true,
 },
 {
   anchorId: 'addAsignaturacompulsory0',
   content:
     'Has click aquí para añadir la asignatura a tu resumen, vuelve a hacer click para quitarla',
   title: 'Añadir asignatura',
   prevBtnTitle: 'Anterior',
   nextBtnTitle: 'Siguiente',
   endBtnTitle: 'Finalizar',
   isOptional: true,
   nextOnAnchorClick: true,
 }, */
  {
    anchorId: 'resumenMatriculacion',
    content: 'Aquí verás las asignaturas que agregues a la matriculación',
    title: 'Resumen Matriculación',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'limiteAsignaturas',
    content:
      'Este es el límite de asignaturas que puedes matricular en este proceso',
    title: 'Límite',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
  {
    anchorId: 'asignaturaCargada',
    content:
      'Aquí verás las asignaturas que hayas agregado al resumen, preciona la X para quitarlas del listado',
    title: 'Asignatura agregada',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isAsync: true,
  },
  {
    anchorId: 'completarMatriculacion',
    content:
      'Una vez que hayas seleccionado todas las asignaturas que quieras matricular, haz click en este botón para completar el proceso',
    title: 'Completar',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isAsync: true,
  },
  {
    anchorId: 'cambiarModalidad',
    content:
      'Cuando tengas carreras en ambas modalidades, deberás presionar aquí para cambiar entre el proceso de matriculación de una y otra',
    title: 'Cambiar modalidad',
    prevBtnTitle: 'Anterior',
    nextBtnTitle: 'Siguiente',
    endBtnTitle: 'Finalizar',
    isOptional: true,
  },
];
