<div class="main" *ngIf="loading">
    <div class="spinner-container">
        <mat-spinner></mat-spinner>
    </div>
</div>
<div class="main"  *ngIf="alumnoError">
    <div class="spinner-container error">
        <img src="../../../../../assets/images/content/Error-pana.svg" alt="" class="error-img">
        <div class="error-message">No se pudo cargar la información del alumno</div>
    </div>
</div>
<div class="main" *ngIf="documentError">
    <div class="spinner-container error">
        <img src="../../../../../assets/images/content/Error-pana.svg" alt="" class="error-img">
        <div class="error-message">No se pudo cargar la información del documento</div>
    </div>
</div>
<div *ngIf="carreras.length && !loading && !alumnoError && !documentError" class="main">
    <div class="careers-selector">
        <div 
            (click)="changeCareer(carreras[0])" 
            class="career"
            [ngClass]="{'inactive-career': careerSelected?.id !== carreras[0].id}">
            <div class="title">Carrera principal</div>
            <div class="name">{{carreras[0].carrera}}</div>
        </div>
        <div 
            *ngIf="carreras[1]" 
            (click)="changeCareer(carreras[1])" 
            class="career"
            [ngClass]="{'inactive-career': careerSelected?.id !== carreras[1].id}">
            <div class="title">Carrera secundaria</div>
            <div class="name">{{carreras[1].carrera}}</div>
        </div>
    </div>
    
    <div class="options-container">
        <div class="document-title">Este es tu documento de las practicas para la carrera de {{careerSelected.carrera}}</div>
        <button class="download-btn" (click)="downloadPDF()" [disabled]="isDownloading">
            <i class="fa-light fa-file-arrow-down"></i>
            {{ isDownloading ? 'Descargando...' : 'Descargar' }}
        </button>
          
    </div>
    <div  *ngIf="documentText" #pdfContent class="documento-container">
        <div class="header">
            <img src="../../../../../assets/images/content/documentacion-img.png" alt="">
        </div>
        <p *ngFor="let paragraph of documentText; let i = index">{{paragraph}}</p>
        <div class="sign-container">
            <img src="../../../../../assets/images/content/firma-documentacion.PNG" alt="" class="sign">
            <div class="tutor-name">Prof. Lorena Gamboa</div>
            <div class="tutor-title">Directora de Prácticas Profesionales</div>
            <div class="footer-section">
                MIAMI - MADRID - MÉXICO D.F. - PANAMÁ - SAN JOSÉ DE COSTA RICA - BOGOTÁ - MEDELLÍN - GUATEMALA - QUITO - BUENOS AIRES - MENDOZA - NEUQUÉN - CHACO
                SAN JUAN - TUCUMÁN - TEGUCIGALPA - SAN PEDRO SULA - SANTO DOMINGO - ASUNCIÓN - SANTIAGO DE CHILE - SANTA CRUZ DE LA SIERRA - LIMA - SAN SALVADOR - MANAGUA
            </div>
            <div class="credits">Torre de las Américas, Mezzanine - Panamá Tel: (507) 204-5742 / 5743 / 5745. www.adenuniversity.edu.pa</div>
        </div>
    </div>
</div>
