<div>
  <div>
    <div class="dialog-header">
      <div class="title">{{ this.data.title }}</div>
      <button
        class="close"
        mat-icon-button
        aria-label="Close"
        (click)="onClose()"
      >
        <mat-icon class="material-icons-round">close</mat-icon>
      </button>
    </div>
    <div class="dialog-container">
      <div mat-dialog-content>
        <div class="video-wrapper">
          <iframe
            [src]="videoLink"
            allowfullscreen
            frameborder="0"
            width="300"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
