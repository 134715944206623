<div class="banner">
  <div class="body">
    <div class="tag">¡Nuevo!</div>
    <div class="title">Bolsa de empleo ADENU</div>
    <div class="description">
      Ofertas de empleo exclusivas para nuestros estudiantes y egresados
    </div>
  </div>
  <div>
    <form
      ngNoForm
      action="https://aden.hiringroomcampus.com/check-access"
      target="_blank"
      method="POST"
    >
      <input hidden name="token" value="E9E69T2vyRPNZuTVHaZjK67Y" />
      <button mat-button type="submit">
        <span>Ingresar</span>
      </button>
    </form>
  </div>
</div>
