<div id="dialogo-reemplazo">
  <button class="close" mat-icon-button aria-label="Close" (click)="onClose()">
    <mat-icon class="material-icons-round">close</mat-icon>
  </button>
  <div class="dialog-container">
    <div mat-dialog-content>
      <h1>{{ data.title }}</h1>
      <div id="aviso-innerhtml" [innerHTML]="data.description | html"></div>
    </div>
  </div>
</div>
