import { Store } from '@ngrx/store';
import { UserState } from './../../../redux/reducers/user.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CmsService } from './../../../shared/services/cms.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { faqsPasSocial } from 'src/app/shared/data/graduacion';
import { DataService } from 'src/app/shared/services/data.service';
import { AcademicState } from 'src/app/redux/reducers/academicAd.reducer';
import * as reduxAcademic from '../../../redux/actions/academicAd.action';
import { AlumnoService } from 'src/app/shared/services/alumno.service';

@Component({
  selector: 'app-practica-social',
  templateUrl: './practica-social.component.html',
  styleUrls: [
    './practica-social.component.scss',
    '../graduacion.component.scss',
  ],
})
export class PracticaSocialComponent implements OnInit {
  public user$;
  public user;

  public academic$;
  public academic;

  public sendingMessage = false;
  messageCtrl = new UntypedFormControl('', Validators.required);

  links = [
    { name: 'Descripción', tag: 'descripcion' },
    { name: 'Objetivos', tag: 'objetivos' },
    { name: 'Etapas y contenidos', tag: 'etapas-y-contenidos' },
    { name: 'FAQ', tag: 'faq' },
    { name: 'Consultas', tag: 'consultas' },
  ];
  activeLink = this.links[0];

  faqs = faqsPasSocial;

  constructor(
    private cmsService: CmsService,
    public snackbar: MatSnackBar,
    private userStore: Store<UserState>,
    private dataService: DataService,
    private alumnoService: AlumnoService,
    private academicStore: Store<AcademicState>
  ) {}

  ngOnInit(): void {
    this.user$ = this.userStore.select('user');
    this.user$?.subscribe((user) => {
      if (!user.hasOwnProperty('user')) {
        this.user = user;
      }
    });

    this.academic$ = this.academicStore.select('academic');
    this.academic$.subscribe((store) => {
      if (store == null) {
        this.getAcademic();
      } else {
        this.academic = store;
      }
    });
  }

  getAcademic(): void {
    this.alumnoService.getAdvisor().subscribe({
      next: (data) => {
        this.academicStore.dispatch(
          new reduxAcademic.GetAcademic({
            ...data,
            foto: `data:image/png;base64,${data.foto}`,
          })
        );
        this.academic = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  sendMessage(): void {
    if (this.messageCtrl.value !== null || this.messageCtrl.value !== '') {
      this.sendingMessage = true;
      const data = {
        student_name: this.user.nombre,
        student_educat_id: this.user.id_educat,
        student_id: this.user.legajo,
        student_email: this.user.email,
        message: this.messageCtrl.value,
        subject: 'Práctica Social',
        send_to: 'graciela.aguilar@adenuniversity.edu.pa',
        copy_to: this.academic.correo,
      };
      this.cmsService.sendConsult(data).subscribe({
        next: (res) => {
          this.snackbar.open('¡Mensaje enviado con éxito!', 'OK', {
            duration: 2000,
            panelClass: ['success-snackbar'],
          });
          this.sendingMessage = false;
          this.messageCtrl.reset();
        },
        error: (err) => {
          this.snackbar.open(
            'Ocurrió un error, por favor intenta de nuevo más tarde',
            'OK',
            {
              duration: 2000,
              panelClass: ['error-snackbar'],
            }
          );
          this.sendingMessage = false;
        },
      });
    }
  }
}
