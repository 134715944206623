<div class="wrapper">
  <div
    class="heading"
    [style.background-image]="
      testimony.image.includes('http')
        ? 'url(' + testimony.image + ')'
        : 'url(../../../../../assets/' + testimony.image + ')'
    "
  ></div>

  <div class="content" [ngClass]="{inverted: inverted}">
    <p>{{ testimony.description }}</p>
    <div class="titles">
      <h3>{{ testimony.name }}</h3>
      <h4>{{ testimony.company }}</h4>
      <h5>{{ testimony.gradYear }}</h5>
    </div>
  </div>
</div>
