import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  FormGroupDirective,
  NgForm,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { DataService } from 'src/app/shared/services/data.service';
import { SendFormService } from 'src/app/shared/services/send-form.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-retiro-carrera',
  templateUrl: './retiro-carrera.component.html',
  styleUrls: ['./retiro-carrera.component.scss'],
})
export class RetiroCarreraComponent implements OnInit {
  formulario: UntypedFormGroup;
  isChecked = false;

  periodo = [
    { value: 'Cuatrimestre I' },
    { value: 'Cuatrimestre II' },
    { value: 'Cuatrimestre III' },
  ];

  formaPago = [
    { value: 'Transferencia' },
    { value: 'Centro de compras / Link de pago / Débito automático' },
  ];

  tipoCuenta = [{ value: 'Ahorros' }, { value: 'Corriente' }];

  ingreso = [];

  licenciatura = [];
  licenciaturaState = false;

  condicion = [{ value: 'Si' }, { value: 'No' }, { value: 'Otro: ' }];

  matcher = new MyErrorStateMatcher();

  constructor(
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private alumnoService: AlumnoService,
    private sendFormService: SendFormService
  ) {}

  ngOnInit(): void {
    this.formulario = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido: ['', Validators.required],
      identificacion: ['', Validators.required],
      correoElectronico: ['', [Validators.required, Validators.email]],
      periodoIngreso: ['', Validators.required],
      anioIngreso: ['', Validators.required],
      licenciatura: ['', Validators.required],
      licenciaturaOtro: [''],
      motivoCambio: ['', Validators.required],
      condCambio: ['Si', Validators.required],
      formaPago: ['', ''],
      entidadFinanciera: ['', ''],
      titularCuenta: ['', ''],
      numeroCuenta: ['', ''],
      tipoCuenta: ['', ''],
      // condCambioOtro: [''],
    });
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    const hoy = new Date().getFullYear();
    for (let index = hoy; index >= hoy - 10; index--) {
      this.ingreso.push(index.toString());
    }
    this.alumnoService.getAlumno().subscribe((res) => {
      this.licenciaturaState = true;
      this.licenciatura = res.carreras;
      /* console.log(this.licenciatura); */

      this.formulario = this.formBuilder.group({
        nombre: [res.nombre, Validators.required],
        apellido: [res.apellido, Validators.required],
        identificacion: [res.numero_documento, Validators.required],
        correoElectronico: [res.email, [Validators.required, Validators.email]],
        periodoIngreso: ['', Validators.required],
        anioIngreso: ['', Validators.required],
        licenciatura: ['', Validators.required],
        licenciaturaOtro: [''],
        motivoCambio: ['', Validators.required],
        condCambio: ['Si', Validators.required],
        formaPago: ['', ''],
        entidadFinanciera: ['', ''],
        titularCuenta: ['', ''],
        numeroCuenta: ['', ''],
        tipoCuenta: ['', ''],
        // condCambioOtro: [''],
      });
    });
  }

  enviarFormulario(): void {
    let primerLicenciatura: string;
    let condCambio: string;
    primerLicenciatura = this.formulario.value.licenciatura;
    if (this.formulario.value.condCambio !== 'Otro: ') {
      condCambio = this.formulario.value.condCambio;
    } else {
      condCambio = this.formulario.value.condCambioOtro;
    }
    this.enviarMail(primerLicenciatura, condCambio);
  }

  enviarMail(primerLicenciatura: string, condCambio: string): void {
    const data = {
      tipoFormulario: 'retiro de carrera',
      nombre: this.formulario.value.nombre,
      apellido: this.formulario.value.apellido,
      identificacion: this.formulario.value.identificacion,
      correo: this.formulario.value.correoElectronico,
      periodo: this.formulario.value.periodoIngreso,
      anioIngreso: this.formulario.value.anioIngreso,
      licenciatura: primerLicenciatura,
      motivo: this.formulario.value.motivoCambio,
      condicion: condCambio,
      modalidad: '',
      formaPago: this.formulario.value.formaPago,
      entidadFinanciera: this.formulario.value.entidadFinanciera,
      titularCuenta: this.formulario.value.titularCuenta,
      numeroCuenta: this.formulario.value.numeroCuenta,
      tipoCuenta: this.formulario.value.tipoCuenta,
    };

    this.sendFormService.enviarFormulario(data).subscribe({
      next: (res) => {
        this.alertService.openAlert(
          '¡Perfecto!',
          'La solicitud fue enviada con éxito',
          'Ok'
        );
        this.formulario.reset();
      },
      error: (error) => {
        this.alertService.openAlert(
          '¡Ups!',
          'Ha ocurrido un error, por favor intenta de nuevo más tarde',
          'Ok'
        );
        console.log(error);
      },
    });
  }

  changeCheck(): void {
    this.isChecked = this.isChecked === false ? true : false;
    if (this.isChecked === true) {
      this.formulario = this.formBuilder.group({
        nombre: [this.formulario.value.nombre, Validators.required],
        apellido: [this.formulario.value.apellido, Validators.required],
        identificacion: [
          this.formulario.value.identificacion,
          Validators.required,
        ],
        correoElectronico: [
          this.formulario.value.correoElectronico,
          [Validators.required, Validators.email],
        ],
        periodoIngreso: [
          this.formulario.value.periodoIngreso,
          Validators.required,
        ],
        anioIngreso: [this.formulario.value.anioIngreso, Validators.required],
        licenciatura: ['', Validators.required],
        licenciaturaOtro: [''],
        motivoCambio: [this.formulario.value.motivoCambio, Validators.required],
        condCambio: ['Si', Validators.required],
        formaPago: ['', Validators.required],
        entidadFinanciera: ['', Validators.required],
        titularCuenta: ['', Validators.required],
        numeroCuenta: ['', Validators.required],
        tipoCuenta: ['', Validators.required],
        // condCambioOtro: [''],
      });
    } else {
      this.formulario = this.formBuilder.group({
        nombre: [this.formulario.value.nombre, Validators.required],
        apellido: [this.formulario.value.apellido, Validators.required],
        identificacion: [
          this.formulario.value.identificacion,
          Validators.required,
        ],
        correoElectronico: [
          this.formulario.value.correoElectronico,
          [Validators.required, Validators.email],
        ],
        periodoIngreso: [
          this.formulario.value.periodoIngreso,
          Validators.required,
        ],
        anioIngreso: [this.formulario.value.anioIngreso, Validators.required],
        licenciatura: ['', Validators.required],
        licenciaturaOtro: [''],
        motivoCambio: [this.formulario.value.motivoCambio, Validators.required],
        condCambio: ['Si', Validators.required],
        formaPago: ['', ''],
        entidadFinanciera: ['', ''],
        titularCuenta: ['', ''],
        numeroCuenta: ['', ''],
        tipoCuenta: ['', ''],
        // condCambioOtro: [''],
      });
    }
  }
}
