import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';

@Component({
  selector: 'app-tabla-horarios',
  templateUrl: './tabla-horarios.component.html',
  styleUrls: ['./tabla-horarios.component.scss'],
})
export class TablaHorariosComponent implements OnInit {
  @Input() tableData = [];

  public dataSource;

  spanningColumns = ['dia', 'hora'];

  public searchValue = '';

  spans = [];

  public tableColumns = [
    { columnDef: 'dia', header: 'Día', cell: (row: any) => `${row.dia}` },
    { columnDef: 'hora', header: 'Hora', cell: (row: any) => `${row.hora}` },
    {
      columnDef: 'docente',
      header: 'Docente',
      cell: (row: any) => `${row.docente}`,
    },
    {
      columnDef: 'asignatura',
      header: 'Asignatura',
      cell: (row: any) => `${row.asignatura}`,
    },
    { columnDef: 'salon', header: 'Salón', cell: (row: any) => `${row.salon}` },
    { columnDef: 'zoom', header: 'Zoom', cell: (row: any) => `${row.zoom}` },
    {
      columnDef: 'aula_canvas',
      header: 'Aula Canvas',
      cell: (row: any) => `${row.aula_canvas}`,
    },
  ];

  public columnsToDisplay = this.tableColumns.map((c) => c.columnDef);

  isLoading = false;
  isError = false;

  selected = new UntypedFormControl(0);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() {}

  sorter = {
    lunes: 1,
    martes: 2,
    miércoles: 3,
    jueves: 4,
    viernes: 5,
    sábado: 6,
    domingo: 7,
  };

  ngOnInit(): void {
    this.tableData.sort((a, b) =>
      this.sorter[a.hora.toLowerCase()] > this.sorter[b.hora.toLowerCase()]
        ? 1
        : -1
    );
    this.tableData.sort((a, b) =>
      this.sorter[a.dia.toLowerCase()] > this.sorter[b.dia.toLowerCase()]
        ? 1
        : -1
    );

    this.cacheSpan('dia', (d) => d.dia);
    this.cacheSpan('hora', (d) => d.dia + ' ' + d.hora);

    this.dataSource = new MatTableDataSource<any>(this.tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Evaluated and store an evaluation of the rowspan for each row.
   * The key determines the column it affects, and the accessor determines the
   * value that should be checked for spanning.
   */
  cacheSpan(key, accessor) {
    for (let i = 0; i < this.tableData.length; ) {
      let currentValue = accessor(this.tableData[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.tableData.length; j++) {
        if (currentValue != accessor(this.tableData[j])) {
          break;
        }
        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.spans[i][key] = count;
      i += count;
    }
  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }

  search(e): void {
    const filterValue = (e.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cleanSearch(): void {
    this.searchValue = '';
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
  }
}
