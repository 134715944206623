<div class="body" (click)="takeAction()">
  <div class="heading">
    <img
      [src]="
        course.image.includes('http')
          ? course.image
          : '../../../../../assets/' + course.image
      "
      [alt]="course.name"
    />
  </div>
  <div class="content">
    <h1>{{ course.name }}</h1>
    <p>{{ course.description }}</p>
  </div>
  <div class="footer">
    <div class="button">
      Conocer más
      <mat-icon class="material-icons-round">arrow_forward</mat-icon>
    </div>
  </div>
</div>
