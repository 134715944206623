<div class="sobre-concurso">
  <div class="container">
    <div class="img">
      <img src="../../../../assets/images/content/remote-team.png" />
    </div>
    <div class="text">
      <div class="title blue">¿Qué es Reto Aprende y Emprende?</div>
      <div class="description">
        Este reto es una oportunidad para que los estudiantes de ADENU con
        vocación por emprender y aprender a administrar empresas opten a
        conformar el grupo de estudiantes de alto desempeño a los que la
        Universidad les ayudará a gestionar sus ideas de negocio como parte del
        programa académico de su licenciatura.<br /><br />
        El objetivo del programa no es crear empresas, sino
        <b>FORMAR PERSONAS</b> a través del trabajo en equipo y la gestión de
        emprendimientos reales.
      </div>
    </div>
  </div>
  <div class="container" id="reverse">
    <div class="text">
      <div class="title blue">
        ¿Por qué te invitamos a participar de este reto?
      </div>
      <div class="description">
        <ul>
          <li>Conocemos tu potencial</li>
          <li>Nos emocionan tus ganas de aprender y aportar</li>
          <li>Has demostrado un gran compromiso con el proyecto</li>
          <li>Sabes trabajar en equipo</li>
          <li>Eres el protagonista de tu aprendizaje</li>
        </ul>
      </div>

      <!-- <a
        mat-flat-button
        color="accent"
        class="mt-3"
        href="reto-aprende-y-emprende#inscripcion"
      >
        ¡Quiero participar!
      </a> -->
    </div>
    <div class="img">
      <img src="../../../../assets/images/content/team-work.png" />
    </div>
  </div>
</div>

<div class="cards">
  <div class="container">
    <div class="heading">
      <div class="title blue">Requisitos para participar</div>
    </div>
    <div class="requisitos-grid">
      <ng-container *ngFor="let requisito of this.requisitos">
        <app-card-requisito
          [requisito]="requisito"
          [item]="'Hola'"
        ></app-card-requisito>
      </ng-container>
    </div>
  </div>

  <div class="container">
    <div class="heading">
      <div class="title blue">Criterios de evaluación</div>
    </div>
    <div class="criterios-grid">
      <ng-container *ngFor="let criterio of this.criterios">
        <app-card-criterio [criterio]="criterio"></app-card-criterio>
      </ng-container>
    </div>
  </div>
</div>
