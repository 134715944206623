<div
  class="card-container"
  [ngClass]="{ 'card-shadow': candidate.attributes.is_winner && showShadow }"
>
  <div class="badge" *ngIf="candidate.attributes.is_winner">
    <img
      src="../../../../../assets/images/content/adenpolis/winner-badge.svg"
      alt="Ganador"
    />
  </div>
  <div class="body">
    <img
      [src]="cmsUrl + candidate.attributes.avatar.data.attributes.url"
      alt=""
      *ngIf="candidate.attributes.avatar.data"
    />
    <img
      src="../../../../../assets/images/logos/avatar.png"
      alt=""
      *ngIf="!candidate.attributes.avatar.data"
    />
    <h2>{{ candidate.attributes.full_name }}</h2>
    <h3>{{ candidate.attributes.career }}</h3>
    <div class="chip" *ngIf="candidate.attributes.is_winner">Seleccionado</div>
  </div>
  <div class="footer" *ngIf="showVotes">
    {{ candidate.attributes.votes }} VOTOS
  </div>
</div>
