import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventos-banner',
  templateUrl: './eventos-banner.component.html',
  styleUrls: ['./eventos-banner.component.scss'],
})
export class EventosBannerComponent implements AfterViewInit {
  @Input() hasChip: boolean;
  @Input() chipTitle: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() link: string;
  @Input() queryParams: any;
  @Input() type: string;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.type === 'rematricula_ol') {
      document.getElementById(this.type).classList.remove('default');
      document.getElementById(this.type).classList.add('green');
    }
    if (this.type === 'rematricula_pr') {
      document.getElementById(this.type).classList.remove('default');
      document.getElementById(this.type).classList.add('blue');
    }
  }

  goToEmpleo() {
    
  }
}
