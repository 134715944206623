<div class="dialog-header">
  <div mat-dialog-title>
    <h1>¡Confirma tu inscripción!</h1>
  </div>
  <mat-divider></mat-divider>
</div>
<div class="dialog-container text-center">
  <div mat-dialog-content>
    <p>
      Vas a consumir <strong>{{ credits }} créditos</strong> en las siguientes
      asignaturas
    </p>
    <div class="card-column">
      <div class="chip-asignatura" *ngFor="let materia of materiasName">
        <mat-icon class="material-icons-round">check</mat-icon
        >{{ materia.name }}
      </div>
    </div>
    <div class="alert-text">
      <div class="alert-title">¡Recuerda!</div>
      <p>
        Permanecerás preinscripto hasta que la asignatura complete el cupo
        necesario para iniciar
      </p>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="button-container">
      <button mat-stroked-button color="primary" (click)="close()">
        Cancelar
      </button>
      <button mat-flat-button color="primary" (click)="send()">
        Confirmar
      </button>
    </div>
  </div>
</div>

<!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
