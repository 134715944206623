import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ordenes-compra',
  templateUrl: './ordenes-compra.component.html',
  styleUrls: ['./ordenes-compra.component.scss'],
})
export class OrdenesCompraComponent implements OnInit {
  public links = [
    {
      route: ['/mis-ordenes/tramites-academicos'],
      tag: 'tramites-academicos',
      displayName: 'Trámites académicos',
    },
  ];

  public activeLink = '';

  constructor(private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params) => {
      this.activeLink = params.get('tag');
    });
  }
}
