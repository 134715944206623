import { Observable } from 'rxjs';
import {
  ContestMember,
  ContestTeam,
} from './../../../../shared/interfaces/IContest';
import { ContestService } from './../../../../shared/services/contest.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { startWith, map, switchMap, debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CompletedDialogComponent } from 'src/app/shared/components/dialogs/completed-dialog/completed-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AlumnoService } from 'src/app/shared/services/alumno.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-form-concurso',
  templateUrl: './form-concurso.component.html',
  styleUrls: ['./form-concurso.component.scss'],
})
export class FormConcursoComponent implements OnInit {
  @Input() capitan: ContestMember;
  @Input() hasTeam: boolean;
  @Input() getUser: (args: any) => void;
  @Input() checkUser: (args: any) => void;
  @Input() userTeam: ContestTeam;

  isWaiting = false;

  finishedUpload = true;

  assignedTutor: any = null;

  isLoading = true;

  currentUserID = parseInt(localStorage.getItem('sis_id'), 10);

  formulario: UntypedFormGroup;
  alumnos: ContestMember[] = [];
  tutores: [];
  selectedTutors: any[] = [];
  selectedAlumnos: ContestMember[] = [];
  selectedMember: ContestMember;

  alumnoCtrl = new UntypedFormControl();
  filteredAlumnos: Observable<ContestMember[]>;

  matcher = new MyErrorStateMatcher();

  constructor(
    private contestService: ContestService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private alumnoService: AlumnoService
  ) {
    this.filteredAlumnos = this.alumnoCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map((alumno) => {
        return alumno && this.alumnos.length > 0
          ? this._filterAlumnos(alumno)
          : this.alumnos.slice();
      })
    );
  }

  ngOnInit(): void {
    this.getAlumnos().then((results) => {
      this.filteredAlumnos = this.alumnoCtrl.valueChanges.pipe(
        startWith(''),
        map((alumno) => {
          if (this.alumnos.length > 0) {
            return alumno ? this._filterAlumnos(alumno) : this.alumnos.slice();
          } else {
            return this.alumnos;
          }
        })
      );
    });
    this.getTutors();
    this.formulario = this.formBuilder.group({
      nombre_equipo: ['', Validators.required],
      idea_negocio: ['', Validators.required],
    });

    this.userTeam = JSON.parse(localStorage.getItem('equipo'));
    this.assignedTutor = JSON.parse(localStorage.getItem('assignedTutor'));
  }

  async getAlumnos(): Promise<any> {
    this.isLoading = true;
    this.alumnoCtrl.disable();
    this.alumnoService.getAllAlumnos().subscribe((res) => {
      res.forEach((alumno) => {
        if (
          typeof alumno !== 'string' &&
          alumno.gr_no !== localStorage.getItem('sis_id')
        ) {
          let image =
            'https://proyectoidis.org/wp-content/uploads/2021/06/avatar-default.png';

          if (alumno.image && alumno.image.includes('data:image')) {
            image = alumno.image;
          } else if (alumno.image && !alumno.image.includes('data:image')) {
            image = `data:image/png;base64,${alumno.image}`;
          }

          this.alumnos.push({
            id: alumno.gr_no,
            name: alumno.name,
            mail: alumno.email,
            img: image,
          });
        }
      });
      this.isLoading = false;
      this.alumnoCtrl.enable();
    });
  }

  getTutors(): void {
    this.contestService.getTutors().subscribe((res) => {
      this.tutores = res.response;
    });
  }

  private _filterAlumnos(value): ContestMember[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      if (this.alumnos.length > 0) {
        return this.alumnos.filter((alumno) =>
          alumno.name.toLowerCase().includes(filterValue)
        );
      }
    } else {
      this.selectedMember = value;
      return this.alumnos;
    }
  }

  public displayProperty(value): void {
    if (value) {
      return value.name;
    }
  }

  addMember(): void {
    const exist = this.selectedAlumnos.some(
      (alumno) => alumno.id === this.selectedMember.id
    );
    if (
      this.selectedMember != null &&
      !exist &&
      this.selectedAlumnos.length < 7
    ) {
      this.selectedAlumnos.push(this.selectedMember);
      this.selectedMember = null;
      this.alumnoCtrl.setValue('');
    }
  }

  removeMember(member): void {
    if (this.selectedAlumnos.length > 0) {
      const index = this.selectedAlumnos.indexOf(member);
      this.selectedAlumnos.splice(index, 1);
    }
  }

  addTutor(tutor): void {
    if (this.selectedTutors.length < 3) {
      tutor.isSelected = true;
      this.selectedTutors.push(tutor);
    }
  }

  removeTutor(tutor): void {
    if (this.selectedTutors.length > 0) {
      const index = this.selectedTutors.indexOf(tutor);
      this.selectedTutors.splice(index, 1);
    }
  }

  createTeam(): void {
    const team: ContestTeam = {
      name: this.formulario.value.nombre_equipo,
      creatorCaptain: this.capitan,
      equipament: this.selectedAlumnos,
      business_idea: this.formulario.value.idea_negocio,
      tutors: this.selectedTutors,
    };

    this.contestService.createTeam(team).subscribe({
      next: (res) => {
        this.openCompletedDialog(
          'success',
          '¡Inscripción completada con éxito!',
          'Tu equipo fue inscripto de manera correcta',
          '../../../../../assets/images/content/team-success.svg'
        );
      },
      error: (err) => {
        this.openCompletedDialog(
          'error',
          '¡Ups!',
          'Ocurrió un error inesperado, por favor intenta de nuevo más tarde',
          '../../../../../assets/images/content/error.png'
        );
      },
    });
  }

  openCompletedDialog(type, title, description, img): void {
    const dialogRef = this.dialog.open(CompletedDialogComponent, {
      width: 'auto',
      data: { type, title, description, img, dialogId: 'dialog-contest' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      location.reload();
    });
  }

  getDownloadLink(idRubrica): string {
    return `https://drive.google.com/uc?export=download&id=${idRubrica}`;
  }

  getPreviewLink(idRubrica): string {
    return `https://drive.google.com/file/d/${idRubrica}/preview`;
  }
}
