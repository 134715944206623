<div id="registerContainer">
  <div id="formContainer">
    <div class="app-logo">
      <img src="../../../assets/images/logos/ADENU-Color.png" />
    </div>
    <div class="wrap-login">
      <div class="user-card">
        <div class="body">
          <h1>Campus Virtual</h1>
        </div>
        <p>INICIAR SESIÓN</p>
      </div>
      <div class="warn-alert" *ngIf="errorAlert">
        <div class="body">
          <h1>Usuario y/o contraseña incorrectos</h1>
          <p>Prueba con otras credeciales</p>
        </div>
        <button
          mat-icon-button
          aria-label="Cerrar alerta"
          (click)="closeAlert()"
        >
          <mat-icon class="material-icons-round">close</mat-icon>
        </button>
      </div>
      <form
        novalidate
        [formGroup]="loginFormGroup"
        id="loginForm"
        action="/home"
        action="https://fa.aden.org/oauth2/authorize"
        method="post"
      >
        <input
          type="hidden"
          name="client_id"
          value="2c54fc21-43b7-4c96-ba29-a4a66fd54342"
        />
        <input
          type="hidden"
          name="redirect_uri"
          value="https://campus.adenu.edu.pa"
        />
        <input type="hidden" name="response_type" value="code" />
        <input
          type="hidden"
          name="state"
          value="iY3A4ZPnWt_MMtEtMIaKDbpjQ5BQFm0M2mDevG3UrVI"
        />

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Correo electrónico</mat-label>
          <input
            matInput
            name="loginId"
            type="email"
            required
            formControlName="email"
            placeholder="Ingrese su correo electrónico."
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="pb-2 w-100">
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            name="password"
            required
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            placeholder="Ingrese su contraseña."
            autocomplete="off"
          />
          <mat-icon
            class="material-icons-round"
            matSuffix
            (click)="hide = !hide"
          >
            {{ hide ? "visibility_off" : "visibility" }}</mat-icon
          >
        </mat-form-field>
        <button
          mat-flat-button
          id="btn-login"
          type="submit"
          color="primary"
          class="w-100 mb-2"
          [disabled]="isDisabled"
          (click)="onLogIn()"
        >
          Ingresar
        </button>
        <button mat-button class="w-100" (click)="onForgotPassword()">
          Olvidé mi contraseña
        </button>
      </form>
    </div>
    <!-- <div class="wrap-login" *ngIf="loading">
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div> -->
    <div class="social-icons">
      <a
        class="mx-1"
        target="_blank"
        href="https://www.facebook.com/ADENUniversityPanama/"
      >
        <img
          src="../../../../assets/images/icons/red/facebook-red.png"
          alt=""
        />
      </a>
      <a class="mx-1" target="_blank" href="https://twitter.com/aden_pa">
        <img src="../../../../assets/images/icons/red/twitter-red.png" alt="" />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.linkedin.com/company/universidadaltadirecci%C3%B3n"
      >
        <img
          src="../../../../assets/images/icons/red/linkedin-red.png"
          alt=""
        />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.instagram.com/adenupa/?hl=es-la"
      >
        <img
          src="../../../../assets/images/icons/red/instagram-red.png"
          alt=""
        />
      </a>

      <a
        class="mx-1"
        target="_blank"
        href="https://www.tiktok.com/@adenuniversity?"
      >
        <img src="../../../../assets/images/icons/red/tiktok-red.png" alt="" />
      </a>
      <a
        class="mx-1"
        target="_blank"
        href="https://www.youtube.com/channel/UCpTcEFxdU19ZHrkELRNOBmg/videos"
      >
        <img src="../../../../assets/images/icons/red/youtube-red.png" alt="" />
      </a>
    </div>
    <div class="mt-1">
      <a class="web-adenupa">www.adenuniversity.edu.pa</a>
    </div>
  </div>
  <div id="image"></div>
</div>
