import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { CarreraPrincipal } from 'src/app/shared/interfaces/ICarrera';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { DocPracticesService } from 'src/app/shared/services/doc-practices.service';

@Component({
  selector: 'app-doc-practices',
  templateUrl: './doc-practices.component.html',
  styleUrls: ['./doc-practices.component.scss']
})
export class DocPracticesComponent implements OnInit {

  carreras: CarreraPrincipal[] = [];
  careerSelected: CarreraPrincipal;
  isDownloading = false;
  loading = false;
  alumnoError = false;
  documentError = false;
  documentText = '';
  @ViewChild('pdfContent', { static: false }) pdfContent!: ElementRef;
  constructor(
    private alumnoService: AlumnoService,
    private docPracticesService: DocPracticesService
  ) { }

  ngOnInit(): void {
    this.getAlumno();
  }

  getAlumno(){
    this.loading = true;
    this.alumnoService.getAlumno().subscribe({
      next: (data ) => {
        this.carreras = data.carreras;
        this.careerSelected = data.carreras[0];
        this.getDocument(data.carreras[0].id);
        this.loading = false;
      },
      error: (error) => {
        this.alumnoError = true;
        this.loading = false;
      }
    })
  }
  getDocument(career_id: number){
    this.loading = true;
    this.docPracticesService.getDocument(career_id).subscribe({
      next: (data: any) => {
        if(data.error || !data.data){
          this.documentError = true;
          this.loading = false;
        }else{
          this.loading = false;
          this.documentText = data.data;
          this.documentText = this.documentText.slice(0, 9);
        }
      },
      error: (error) => {
        this.loading = false;
        this.documentError = true;
      }
    })
  }
  changeCareer(career: CarreraPrincipal){
    if(this.careerSelected.id === career.id){
      return;
    } 
    this.careerSelected = career;
    this.getDocument(career.id);
  }

  downloadPDF() {
    this.isDownloading = true;
  
    setTimeout(() => {
      if (!this.pdfContent || !this.pdfContent.nativeElement && !this.isDownloading) {
        console.error('No se encontró el contenedor del PDF');
        this.isDownloading = false;
        return;
      }
      html2canvas(this.pdfContent.nativeElement, {
        scale: 2,
        useCORS: true,
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pageWidth = 148;
        const pageHeight = 340;
        const pdf = new jsPDF('p', 'mm', [pageWidth, pageHeight]);
        const imgWidth = pageWidth;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let position = 0;
  
        while (position < imgHeight) {
          pdf.addImage(imgData, 'PNG', 0, position * -1, imgWidth, imgHeight);
          position += pageHeight;
          if (position < imgHeight) {
            pdf.addPage();
          }
        }
  
        pdf.save('documento.pdf');
        this.isDownloading = false;
      }).catch(() => {
        this.isDownloading = false;
      });
  
    }, 500);
  }
  
  

} 
