<img *ngIf="data.img && data.img.length" [src]="data.img" alt="">

<div class="dialog-header">
  <div mat-dialog-title>
    <h1>{{ data.title }}</h1>
  </div>
</div>
<div class="dialog-container text-center">
  <div mat-dialog-content>
    <h2>{{ data.heading }}</h2>
    <p>{{ data.description }}</p>
  </div>
  <div mat-dialog-actions>
    <div class="button-container">
      <button mat-stroked-button color="primary" (click)="close()">
        {{ data.cancelAction }}
      </button>
      <button mat-flat-button color="primary" (click)="send()">
        {{ data.confirmAction }}
      </button>
    </div>
  </div>
</div>
