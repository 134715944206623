import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-display-card',
  templateUrl: './data-display-card.component.html',
  styleUrls: ['./data-display-card.component.scss'],
})
export class DataDisplayCardComponent implements OnInit {
  @Input() label: string;
  @Input() description: string;
  @Input() value: any;
  @Input() icon: string;
  @Input() type: string = 'plain';

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
