import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modalidad-dialog',
  templateUrl: './modalidad-dialog.component.html',
  styleUrls: ['./modalidad-dialog.component.scss'],
})
export class ModalidadDialogComponent {
  constructor(public dialogRef: MatDialogRef<ModalidadDialogComponent>) {}

  /* ngOnInit(): void {
  }
 */
}
