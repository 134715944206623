    <div class="header">
        <div>Preguntas frecuentes</div>
        <div (click)="close()" class="icon-container">
            <i class="close-icon fa-light fa-xmark"></i>
        </div>
    </div>
    <div class="sidebar">
      <div class="side-card">
        <!-- <button (click)="goToModalidad('online')">
          <h1 >Cambiar</h1>
        </button> -->
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Qué tipo de trámites puedo realizar?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              A través del campus podrás solicitar
              <b>Certificaciones Oficiales</b> y <b>exámenes</b>
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title> ¿Cuándo puedo adquirirlos? </mat-panel-title>
            </mat-expansion-panel-header>
            <h4>Certificaciones:</h4>
            <p>
              En cualquier momento del cursado, siempre y cuando se encuentre al
              día con documentación y en estado Activo / Graduando.
            </p>
            <h4>Exámenes:</h4>
            <p>Se debe realizar durante un periodo académico en curso.</p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title> ¿Tienen costo? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Cada uno de acuerdo a su especialidad varia el costo, se puede
              validar en el momento de adquirirlos en la plataforma.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title> ¿Cómo puedo adquirirlos? </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Ingresando al campus, seleccionando el tipo de solicitud a
              tramitar. Cuando se realice este proceso de selección el campus
              habilitara el carrito de compras para proceder con el pago.
            </p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Qué medios están habilitados para el pago?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>Solo pago en línea (tarjeta debito o crédito)</p>
          </mat-expansion-panel>
          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                ¿Como obtengo el documento una vez realice el pago?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>
              Una vez realizada la solicitud en el campus se habilitará un
              estado en el se podrá ver el avance de su trámite académico.
              Cuando registre en estado aprobado, a partir del 3er día hábil se
              estará enviado a tu correo electrónico.
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>