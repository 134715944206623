import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { SpecViewService } from './spec-view.service';
import { ISpec } from '../interfaces/ISpec';

@Injectable({providedIn: 'root'})
export class DerechosService {
    specView: ISpec;
    url = environment.baseUrl;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private specViewService: SpecViewService)
        {
            this.getSpecView();
        }

        getSpecView(){
            this.specViewService.currentSharedSpecMode.subscribe(data => this.specView = data)
        }

        getDerechosPecuniarios(): Observable<any> {
            const token = this.authService.leerToken();
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
            if(this.specView.isSpecMode){
              return this.http.get(this.url + '/educat/lista_precios_dp?student_sis_id=' + this.specView.sis_id, {
                headers,
              });
            }
            return this.http.get(this.url + '/educat/lista_precios_dp', {
            headers,
          });
          }
        
        postDerechosPecuniarios(orden): Observable<any> {
            const token = this.authService.leerToken();
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
            return this.http.post(this.url + '/educat/orden_compra_dp', orden, {
              headers,
            });
        }
        
        getOrdersDP(): Observable<any> {
            const token = this.authService.leerToken();
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
            return this.http.get(this.url + '/educat/ordenes_compra_dp', {
                headers,
            });
        }
        
        cancelOrderDP(order_id): Observable<any> {
            const token = this.authService.leerToken();
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });
            return this.http.put(
                this.url + '/educat/cancelar_orden_compra_dp/' + order_id,
                null,
                {
                    headers,
                }
            );
        }
}