<div class="wrapper">
  <div class="video">
    <!-- <iframe class="responsive-iframe" [src]="video" allow="autoplay"></iframe> -->
    <iframe
      class="responsive-iframe"
      [src]="video | safeUrl"
      width="640"
      height="480"
      allow="autoplay; fullscreen"
    ></iframe>
  </div>
  <div class="text-body">
    <h2>{{ project }}</h2>
    <p>{{ student }}</p>
  </div>
</div>
