import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICase } from '../interfaces/ICase';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasesService {

  apiUrl = environment.salesforceApiUrl;
  private ticketUpdatedSource = new Subject<void>();
  ticketUpdated$ = this.ticketUpdatedSource.asObservable();

  constructor(private http: HttpClient) { }

  createCase(ticket: ICase){
    const fd = new FormData();
    fd.append('contactSisId', ticket.contactSisId);
    fd.append('description', ticket.description);
    fd.append('subject', ticket.subject);
    fd.append('type', ticket.type);
    fd.append('status', ticket.status);
    fd.append('caseType', ticket.caseType);
    fd.append('origin', ticket.origin);
    fd.append('category', ticket.category);
    if (ticket.caseFiles && ticket.caseFiles.length > 0) {
      for (let i = 0; i < ticket.caseFiles.length; i++) {
        fd.append('caseFiles', ticket.caseFiles[i]);
      }
    }
    return this.http.post(`${this.apiUrl}/cases/campus`, fd);
  }

  notifyTicketUpdated() {
    this.ticketUpdatedSource.next();
  }

  getCases(){
    return this.http.get(`${this.apiUrl}/cases/campus?contactSisId=${localStorage.getItem('sis_id')}`);
  }

}
