<div id="modal">
  <div class="dialog-header">
    <div class="title">Editar foto de perfil</div>
    <button
      class="close"
      mat-icon-button
      aria-label="Close"
      (click)="onClose()"
    >
      <mat-icon class="material-icons-round">close</mat-icon>
    </button>
  </div>
  <div class="dialog-container">
    <div mat-dialog-content>
      <div class="file-input mt-2">
        <button
          type="button"
          mat-flat-button
          color="accent"
          (click)="fileInput.click()"
        >
          Seleccionar foto
        </button>
        <p>
          {{
            fileInput.files[0] ? fileInput.files[0].name : "Elige un archivo"
          }}
        </p>
      </div>
      <input
        hidden
        (change)="onFileSelected()"
        #fileInput
        type="file"
        id="file"
      />
    </div>
    <div mat-dialog-actions>
      <div class="button-container">
        <button mat-stroked-button mat-dialog-close>Cerrar</button>
        <button
          mat-flat-button
          color="primary"
          id="save"
          [disabled]="fileInput.files.length === 0"
          (click)="changePhotoProfile(fileInput)"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
