import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-onepage-nav',
  templateUrl: './onepage-nav.component.html',
  styleUrls: ['./onepage-nav.component.scss'],
})
export class OnepageNavComponent implements OnInit {
  @Input() links;
  @Input() activeLink;
  @Input() urlBase;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      this.activeLink =
        fragment && this.links.some((item) => item.tag === fragment)
          ? this.links.find((item) => item.tag === fragment)
          : this.links[0];
    });
    window.addEventListener('scroll', (e) => {
      const headings = document.querySelectorAll('section[id]');
      headings.forEach((ha) => {
        const rect = ha.getBoundingClientRect();
        if (
          ((rect.top > 0 && rect.top < 300) ||
            (rect.bottom < 300 && rect.bottom > 0)) &&
          this.links.some((item) => item.tag === ha.id)
        ) {
          const location = window.location.toString().split('#')[0];
          history.replaceState(null, null, location + '#' + ha.id);
          this.activeLink = this.links.find((item) => item.tag === ha.id);
        }
      });
    });
  }

  scrollTo(tag) {
    /* const location = window.location.toString().split('#')[0];
    history.replaceState(null, null, location + '#' + tag); */
    const section = document.getElementById(tag);
    section.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'nearest',
    });
  }
}
