import {
  PQRSCategory,
  PQRSCaseKind,
} from './../../../../shared/interfaces/IPQRS';
import { startWith, map } from 'rxjs/operators';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PqrsService } from 'src/app/shared/services/pqrs.service';
import { AlertService } from 'src/app/shared/services/alerts.service';
import { solicitudData } from 'src/app/shared/data/solicitud.data';
import { ICase } from 'src/app/shared/interfaces/ICase';
import { CasesService } from 'src/app/shared/services/cases.service';
import { Factura } from 'src/app/shared/interfaces/IEstadoFinanciero';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-nuevo-ticket',
  templateUrl: './nuevo-ticket.component.html',
  styleUrls: ['./nuevo-ticket.component.scss'],
})
export class NuevoTicketComponent implements OnInit {
  public formulario: UntypedFormGroup;
  public options = null;
  public kindOfCase: PQRSCaseKind = null; 
  public category = null;
  public loading = true;
  public error = false;
  file_store: FileList = null;
  public sending = false;

  public categoryControl = new UntypedFormControl('', Validators.required);
  solicitudData = solicitudData;
  categorieSelected = null;

  filteredCategories: Observable<PQRSCategory[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private casesService: CasesService,
    private pqrsService: PqrsService,
    public dialog: MatDialog
  ) {}

  onCategorieSelected(event:any){
    this.categorieSelected = event.value;
    console.log(event)
  }

  ngOnInit(): void {
    this.getSelects().then((res) => {
      this.filteredCategories = this.categoryControl.valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name !== ''
            ? this.filterCategories(name)
            : this.kindOfCase.categories.slice()
        )
      );
    });
  }
  async getSelects(): Promise<any> {
    this.loading = true;
    this.pqrsService.getPqrsOptions().subscribe({
      next: (res) => {
        this.options = res.response_data;
        this.kindOfCase = res.response_data.kind_of_cases[0];
        this.newForm();
        this.loading = false;
        this.error = false;
      },
      error: (err) => {
        console.log(err);
        this.loading = false;
        this.error = true;
      },
    });
  }

  private filterCategories(name: string): PQRSCategory[] {
    const filterValue = name.toLowerCase();

    return this.kindOfCase.categories.filter((category) =>
      category.name.toLowerCase().includes(filterValue)
    );
  }

  displayCategory(category): string {
    return category && category.name ? category.name : '';
  }

  newForm(): void {
    this.formulario = this.formBuilder.group({
      type: ['', Validators.required],
      category: ['', Validators.required],
      caseFiles:[[], ],
      subCategory: ['', Validators.required],
      subject: ['', Validators.required],
      description: ['', [Validators.required]],
    });
  }

  normalizeDataForm(form): ICase {
    return {
      type: form.category.name,
      caseFiles: this.file_store,
      caseType: form.type,
      status: 'Derivado',
      category: form.subCategory,
      description: form.description,
      origin: 'Campus',
      subject: form.subject,
      contactSisId: localStorage.getItem('sis_id'),
    };
  }

  handleFileInputChange(l: FileList): void {
    this.file_store = l;
    console.log(this.file_store)
    if (l.length) {
      const f = l[0];
      const count = l.length > 1 ? `(+${l.length - 1} archivos)` : "";
      this.formulario.get('caseFiles').patchValue(`${f.name}${count}`);
    } else {
      this.formulario.get('caseFiles').patchValue("");
    }
  }

  sendForm(): void {
    const form = this.normalizeDataForm(this.formulario.value);
    this.sending = true;
    this.casesService.createCase(form).subscribe({
      next: (res) => {
        this.dialog.open(DialogSuccess, {
          width: '450px',
          data: res,
        });
        this.casesService.notifyTicketUpdated();
        this.sending = false;
        this.newForm();
      },
      error: (err) => {
        this.alertService.openAlert(
          'Algo salió mal',
          'No pudimos crear tu ticket, por favor intenta de nuevo más tarde',
          'Entendido'
        );
        this.sending = false;
      },
    });
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dialog-success',
  templateUrl: './dialog-success.component.html',
  styleUrls: ['./nuevo-ticket.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DialogSuccess {
  constructor(
    public dialogRef: MatDialogRef<DialogSuccess>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}
