<h2 mat-dialog-title>Detalles del Ticket</h2>
<mat-dialog-content>
  <p><strong>Número de Caso:</strong> {{ ticket.caseNumber }}</p>
  <p><strong>Tipo de Caso:</strong> {{ ticket.caseType }}</p>
  <p><strong>Categoría:</strong> {{ ticket.category }}</p>
  <p><strong>Estado:</strong> {{ ticket.status }}</p>
  <p><strong>Asunto:</strong> {{ ticket.subject }}</p>
  <p><strong>Descripcion:</strong> {{ ticket.description }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Cerrar</button>
</mat-dialog-actions>