import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { SpecViewService } from 'src/app/shared/services/spec-view.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlumnoService } from 'src/app/shared/services/alumno.service';
import { AlertService } from 'src/app/shared/services/alerts.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  isLinear = false;
  isLoading = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  threeFormGroup: UntypedFormGroup;
  fourFormGroup: UntypedFormGroup;
  fiveFormGroup: UntypedFormGroup;
  sixFormGroup: UntypedFormGroup;
  student;
  selectedCountry;
  selectedNationality;
  selectedSector;
  nacionalidadId;
  countries;
  laboralSector;
  idLaboralSector;
  isSpecMode = false;

  constructor(
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    private _formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private alumnoService: AlumnoService,
    private authService: AuthService,
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _snackBar: MatSnackBar,
    private specViewService: SpecViewService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.specViewService.currentSharedSpecMode.subscribe(data => this.isSpecMode = data.isSpecMode)
    this.firstFormGroup = this._formBuilder.group({
      name: [{ value: '', disabled: true }, Validators.required],
      lastName: ['', Validators.required],
      birthDate: ['', Validators.required],
      gender: ['', Validators.required],
      typeDocument: ['', Validators.required],
      blood: ['', Validators.required],
      numberDocument: ['', Validators.required],
      nationality: ['', Validators.required],
      dateDocument: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      phone: new UntypedFormControl({ value: '', disabled: false }, [
        Validators.required,
      ]),
      mailPersonal: ['', Validators.required],
      mailAcademic: ['', Validators.required],
    });
    this.threeFormGroup = this._formBuilder.group({
      nameRelationship: ['', Validators.required],
      relationship: ['', Validators.required],
      phoneRelationship: new UntypedFormControl(
        { value: '', disabled: false },
        [Validators.required]
      ),
    });
    this.fourFormGroup = this._formBuilder.group({
      skype: [''],
      linkedin: [''],
      facebook: [''],
      instagram: [''],
    });
    this.fiveFormGroup = this._formBuilder.group({
      school: ['', Validators.required],
      yearEgress: ['', Validators.required],
      country: ['', Validators.required],
    });
    this.sixFormGroup = this._formBuilder.group({
      sectorLaboral: ['', Validators.required],
      workload: ['', Validators.required],
    });
    this.getUser();
  }

  createForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      name: [
        { value: this.student.nombre, disabled: true },
        Validators.required,
      ],
      lastName: [this.student.apellido, Validators.required],
      birthDate: [this.student.fecha_nacimiento, Validators.required],
      gender: [this.student.genero, Validators.required],
      typeDocument: [this.student.tipo_documento, Validators.required],
      blood: [this.student.grupo_sanguineo, Validators.required],
      numberDocument: [this.student.numero_documento, Validators.required],
      nationality: [this.student.pais, Validators.required],
      dateDocument: [this.student.vencimiento_documento, Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      phone: new UntypedFormControl(
        {
          value: this.student.movil.includes('+')
            ? this.student.movil
            : '+' + this.student.movil,
          disabled: false,
        },
        [Validators.required]
      ),
      mailPersonal: [this.student.email_personal, Validators.required],
      mailAcademic: [
        { value: this.student.email, disabled: true },
        Validators.required,
      ],
    });
    this.threeFormGroup = this._formBuilder.group({
      nameRelationship: [this.student.nombre_emergencia, Validators.required],
      relationship: [this.student.parentesco_emergencia, Validators.required],
      phoneRelationship: new UntypedFormControl(
        {
          value: this.student.movil_emergencia.includes('+')
            ? this.student.movil_emergencia
            : '+' + this.student.movil_emergencia,
          disabled: false,
        },
        [Validators.required]
      ),
    });
    this.fourFormGroup = this._formBuilder.group({
      skype: [this.student.skype],
      linkedin: [this.student.linkedin],
      facebook: [this.student.facebook],
      instagram: [this.student.instagram],
    });
    this.fiveFormGroup = this._formBuilder.group({
      school: [this.student.escuela, Validators.required],
      yearEgress: [this.student.escuela_graduacion, Validators.required],
      country: [this.student.escuela_pais, Validators.required],
    });
    this.sixFormGroup = this._formBuilder.group({
      sectorLaboral: [this.student.sector_laboral.id, Validators.required],
      workload: [this.student.cargo_laboral, Validators.required],
    });
  }

  getUser(): void {
    this.alumnoService.getAlumno().subscribe({
      next: (data) => {
        /* CHEQUEAR SESIÓN */

        if (
          (data.nombre === '' || data.nombre === null) &&
          (data.email === '' || !data.email || data.email === undefined)
        ) {
          this.alertService.openAlert(
            '¡Sesión vencida!',
            'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
            'Ok'
          );
          setTimeout(() => {
            this.authService.logout();
          }, 5000);
        }
        this.student = data;
        this.getCountries(data.escuela_pais, data.pais);
        this.getLaboralSector(data.sector_laboral);
        this.createForm();
        this.isLoading = false;
      },
      error: (err) => {
        this.alertService.openAlert(
          '¡Sesión vencida!',
          'Tu sesión ha caducado, por favor inicia sesión nuevamente.',
          'Ok'
        );
        setTimeout(() => {
          this.authService.logout();
        }, 5000);
      },
    });
  }

  onSubmit(): void {
    const saveSubmit = document.getElementById('save-button');
    if (
      this.firstFormGroup.status === 'VALID' &&
      this.secondFormGroup.status === 'VALID' &&
      this.threeFormGroup.status === 'VALID' &&
      this.fiveFormGroup.status === 'VALID'
    ) {
      this.transformValueCountry(
        this.countries.paises,
        this.firstFormGroup.value.nationality,
        this.fiveFormGroup.value.country
      );
      this.transformValueLaboralSector(
        this.laboralSector,
        this.sixFormGroup.value.sectorLaboral
      );
      saveSubmit.innerText = 'Guardando...';
      this.alumnoService
        .postAlumno({
          ...this.firstFormGroup.value,
          ...this.secondFormGroup.value,
          ...this.threeFormGroup.value,
          ...this.fourFormGroup.value,
          ...this.fiveFormGroup.value,
          ...this.sixFormGroup.value,
          country: this.selectedCountry,
          nationality: this.selectedNationality,
          sectorLaboral: this.selectedSector,
        })
        .subscribe({
          next: (data) => {
            saveSubmit.innerText = 'Guardar cambios';
            this.alertService.openAlert(
              '¡Enhorabuena!',
              'Los cambios se realizaron con éxito',
              'Cerrar'
            );
          },
          error: (err) => {
            this._snackBar.open(
              'Ha ocurrido un error al actualizar el perfil',
              'Cerrar',
              {
                duration: 7000,
              }
            );
          },
        });
    } else {
      this._snackBar.open(
        'No se han completado todos los campos obligatorios',
        'Cerrar',
        {
          duration: 7000,
        }
      );
    }
  }

  getCountries(paisEscuela, paisAlumno): void {
    this.alumnoService.getPaises().subscribe((data) => {
      this.countries = data;

      this.transformValueCountry(
        this.countries.paises,
        paisAlumno,
        paisEscuela
      );
    });
  }

  getLaboralSector(sector): void {
    this.alumnoService.getLaboralSector().subscribe({
      next: (res) => {
        this.laboralSector = res.sectores;
        this.transformValueLaboralSector(this.laboralSector, sector);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  transformValueCountry(array, nationality, countrySchool): void {
    // Pais escuela
    for (const country of array) {
      if (countrySchool.toLowerCase() === country.name.toLowerCase()) {
        this.selectedCountry = country.id;
        break;
      }
    }
    // Pais alumno
    for (const country of array) {
      if (nationality.toLowerCase() === country.name.toLowerCase()) {
        this.selectedNationality = country.id;
        break;
      }
    }
  }

  transformValueLaboralSector(array, sector): void {
    for (const arraySector of array) {
      if (sector === arraySector.id) {
        this.selectedSector = arraySector.id;
        break;
      } else {
        this.idLaboralSector = 1;
      }
    }
  }

  compareCountry(o1: any, o2: any): any {
    return o1 && o2 && o1 === o2;
  }

  compareSector(o1: any, o2: any): any {
    return o1 && o2 && o1 === o2;
  }

  printPhone() {
    console.log(this.firstFormGroup.get('phoneNumber').value);
  }
}
