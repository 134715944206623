import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { DracmaMovement } from 'src/app/shared/interfaces/IDracma';

@Component({
  selector: 'app-dracma-table',
  templateUrl: './dracma-table.component.html',
  styleUrls: ['./dracma-table.component.scss'],
})
export class DracmaTableComponent implements OnInit {
  @Input() dracmaMovements: DracmaMovement[];
  @Input() userName;
  @Input() totalVencido;
  @Input() totalFacturado;
  @Input() totalAdeudado;

  dataSource;

  public searchValue = '';

  public tableColumns = [
    {
      columnDef: 'monto',
      header: 'Puntos',
      cell: (item: DracmaMovement) => item.monto,
    },
    {
      columnDef: 'movimiento',
      header: 'Tipo Movimiento',
      cell: (item: DracmaMovement) => item.movimiento,
    },
    {
      columnDef: 'fecha',
      header: 'Fecha',
      cell: (item: DracmaMovement) =>
        moment(item.fecha).locale('es').format('L'),
    },
    {
      columnDef: 'tipo',
      header: 'Origen',
      cell: (item: DracmaMovement) => `${item.tipo}`,
    },

    {
      columnDef: 'motivo',
      header: 'Motivo',
      cell: (item: DracmaMovement) => `${item.motivo}`,
    },
    {
      columnDef: 'observacion',
      header: 'Observación',
      cell: (item: DracmaMovement) => `${item.observacion}`,
    },
  ];

  public columnsToDisplay = this.tableColumns.map((c) => c.columnDef);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() {}

  ngOnInit(): void {
    this.renderTable();
  }

  renderTable(): void {
    this.dataSource = new MatTableDataSource(this.dracmaMovements);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  search(e): void {
    const filterValue = (e.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  cleanSearch(): void {
    this.searchValue = '';
    this.dataSource.filter = this.searchValue.trim().toLowerCase();
  }

  getAbs(value): number {
    return Math.abs(value);
  }
}
